const BrochurePage = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col max-w-4xl">
        <img src={`${process.env.PUBLIC_URL}/img/b1-img.webp`} alt="브로슈어1" />
        <img src={`${process.env.PUBLIC_URL}/img/b2-img.webp`} alt="브로슈어2" />
        <img src={`${process.env.PUBLIC_URL}/img/b3-img.webp`} alt="브로슈어3" />
        <img src={`${process.env.PUBLIC_URL}/img/b4-img.webp`} alt="브로슈어4" />
      </div>
    </div>
  );
};

export default BrochurePage;
