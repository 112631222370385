import { makeAutoObservable, runInAction } from 'mobx';
import { CreateHistoryInput, History } from '../API';
import HistoryRepository from '../repository/HistoryRepository';
import { RootStore } from './RootStore';

export class HistoryStore {
  rootStore: RootStore;
  histories: History[] = [];
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  createHistory = (history: CreateHistoryInput) => {
    HistoryRepository.createHistory(history);
  };

  resetHistories = () => {
    this.histories = [];
  };

  listHistoriesByDate = async (patientID: string) => {
    const data = await HistoryRepository.listHistoriesByDate(patientID);
    runInAction(() => {
      this.histories = data!.items.map((history) => history as History);
    });
  };
}
