import { useState, useMemo } from 'react';

import { Row, Col, TypographyText } from 'components/UI/atoms';
import { treatmentModeMap } from 'constant';

const TreatmentModeSelect = ({ selectedOptionValue, setSelectedOptionValue }) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const InsuranceTag = useMemo(
    () => (
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #DF635C',
          width: '1.85rem',
          height: '1rem',
          borderRadius: '100px',
        }}
      >
        <TypographyText color="#DF635C" fontSize="0.6rem" lineHeight="1em" fontWeight={700}>
          {'급여'}
        </TypographyText>
      </Col>
    ),
    [],
  );
  const text = useMemo(() => {
    if (selectedOptionValue === undefined) {
      return '급여처방인 경우 질환을 선택하세요';
    } else if (selectedOptionValue === 'GENERAL') {
      return '비급여';
    } else {
      return treatmentModeMap.get(selectedOptionValue)?.label;
    }
  }, [selectedOptionValue]);

  return (
    <Col style={{ display: 'flex', position: 'relative' }}>
      <button
        style={{
          display: 'flex',
          columnGap: '0.3rem',
          justifyContent: 'space-between',
          paddingInline: '1rem',
          alignItems: 'center',
          minWidth: '14.3rem',
          height: '2rem',
          border: '1px solid #c4c4c4',
          borderRadius: '3px',
          flexShrink: 0,
          outline: 'none',
        }}
        onClick={() => {
          setIsOpenOptions(!isOpenOptions);
        }}
        onBlur={() => {
          setIsOpenOptions(false);
        }}
      >
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '0.25rem',
          }}
        >
          {selectedOptionValue !== undefined && selectedOptionValue !== 'GENERAL' && InsuranceTag}
          <TypographyText fontSize="0.8rem" color={selectedOptionValue === undefined ? '#AFAFAF' : '#1c1d1c'}>
            {text}
          </TypographyText>
        </Col>
        <Col style={{ display: 'flex', flexShrink: 0 }}>
          <img
            style={{ width: '0.435rem' }}
            src={`${process.env.PUBLIC_URL}/img/select-arrow-icon.webp`}
            alt="arrow-down"
          />
        </Col>
        {isOpenOptions && (
          <Row
            style={{
              position: 'absolute',
              top: 'calc(100% + 0.25rem)',
              left: 0,
              minWidth: '14.3rem',
              display: 'flex',
              flexDirection: 'column',
              paddingBlock: '0.5rem',
              border: '1px solid #AFAFAF',
              borderRadius: '3px',
              background: '#FFFFFF',
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {Array.from(treatmentModeMap.values()).map((option) => {
              return (
                <Row
                  className="treatment-mode-option"
                  style={{
                    paddingInline: '0.75rem',
                    height: '1.8rem',
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.25rem',
                    cursor: 'pointer',
                    flexShrink: 0,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedOptionValue(option.value);
                    setIsOpenOptions(false);
                  }}
                >
                  {option.value !== 'GENERAL' && InsuranceTag}
                  <Col style={{ display: 'flex' }}>
                    <TypographyText fontSize="0.8rem" lineHeight="1em">
                      {option.label}
                    </TypographyText>
                  </Col>
                </Row>
              );
            })}
          </Row>
        )}
      </button>
    </Col>
  );
};

export default TreatmentModeSelect;
