import React, { ReactNode } from 'react';

import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { color } from '../../../../styles/themes';
import { Layout as ALayout, LayoutProps } from 'antd';
import { tuple } from 'antd/lib/_util/type';
import { SiderProps } from 'antd/lib/layout';

const { Header: AHeader, Sider: ASider, Content: AContent, Footer: AFooter } = ALayout;

// LAYOUT
export const tYzTypeLayout = tuple('landing-page');
type TYzTypeLayout = typeof tYzTypeLayout[number] | undefined;
interface ILayoutParams extends LayoutProps {
  yjTypeLayout?: TYzTypeLayout;
}
type THandleLayoutWithTypeParams = {
  yjTypeLayout?: TYzTypeLayout;
};
const handleLayoutWithType = ({ yjTypeLayout }: THandleLayoutWithTypeParams) => {
  switch (yjTypeLayout) {
    case 'landing-page':
      return css`
        padding: 0 1.2rem;
      `;
    default:
      return '';
  }
};
const StyledLayout = styled(ALayout, {
  shouldForwardProp: isPropValid,
})<ILayoutParams>`
  background: ${color.white};
  ${({ yjTypeLayout }) => handleLayoutWithType({ yjTypeLayout })}
`;
export const Layout = ({ children, ...rest }: ILayoutParams) => (
  <StyledLayout {...rest}>{children}</StyledLayout>
);

// HEADER
interface IHeaderParams extends LayoutProps {}
const StyledHead = styled(AHeader)`
  width: 100%;
  height: 6.4rem;
  padding: 0;
  position: relative;
`;
export const Header = ({ children, ...rest }: IHeaderParams) => <StyledHead {...rest}>{children}</StyledHead>;

// SIDER
const StyledSider = styled(ASider)`
  overflow-y: scroll;
  width: 14rem !important;
  max-width: 14rem !important;
  min-width: 14rem !important;
  background: #f9f9f9;
  border-right: 1px solid #f0f0f0;
  height: calc(100vh - 6.4rem); /** header height: 6.4rem */
`;
type TSiderParams = {
  children?: ReactNode;
} & Omit<SiderProps, 'theme'>;
export const Sider = ({ children, ...rest }: TSiderParams) => <StyledSider {...rest}>{children}</StyledSider>;

// CONTENT
export const tYzTypeContent = tuple('MAIN', 'BODY', 'TAIL', 'TOP', 'CONTENT');
type TYzTypeContent = typeof tYzTypeContent[number] | undefined;
interface IContentParams extends LayoutProps {
  yjTypeContent?: TYzTypeContent;
  selectableRowFlag?: boolean;
}
type THandleContentWithTypeParams = {
  yjTypeContent?: TYzTypeContent;
};
const handleContentWithType = ({ yjTypeContent }: THandleContentWithTypeParams) => {
  switch (yjTypeContent) {
    case 'MAIN':
      return css`
        background: ${color.white};
        width: 100%;
        /* padding: 1.2rem; */
        margin: 0;
      `;
    case 'BODY':
      return css`
        background: ${color.white};
        padding: 1.5rem 1.8rem;
        border: 1px solid rgba(186, 185, 197, 0.47);
        margin-top: 1.25rem;
      `;
    case 'TAIL':
      return css`
        margin-top: 2rem;
        margin-bottom: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
      `;
    case 'TOP':
      return css`
        padding: 1.2rem 1.2rem 0 1.2rem;
        margin: 0;
      `;
    case 'CONTENT':
      return css`
        /* padding: 0 1.2rem 1.2rem 1.2rem; */
        margin: 0;
      `;
    default:
      return css``;
  }
};
const StyledContent = styled(AContent, {
  shouldForwardProp: isPropValid,
})<IContentParams>`
  ${({ yjTypeContent }) => handleContentWithType({ yjTypeContent })}
`;
export const Content = ({ children, ...rest }: IContentParams) => (
  <StyledContent {...rest}>{children}</StyledContent>
);

// FOOTER
interface IFooterParams extends LayoutProps {}
const StyledFooter = styled(AFooter)`
  padding: 0;
  width: 100%;
`;
export const Footer = ({ children, ...rest }: IFooterParams) => (
  <StyledFooter {...rest}>{children}</StyledFooter>
);
