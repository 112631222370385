import React from 'react';

import { TypographyText } from 'components/UI/atoms';
import { TableCell } from 'components/UI/atoms/table/TableCell';

const CategoryTd = ({ data, categoryOmitFlag }: { data: any; categoryOmitFlag: boolean }) => {
  return (
    <TableCell
      style={{
        borderLeft: 'none',
        borderRight: 'none',
        paddingLeft: '0',
        paddingRight: '0',
        height: '2.7rem',
        borderBottom: 'none',
        borderTop: categoryOmitFlag ? 'none' : '1px solid var(--amplify-components-table-data-border-color)',
      }}
    >
      {!categoryOmitFlag && (
        <TypographyText
          yjTypeTypographyText="symptom-table-body"
          fontWeight={700}
          letterSpacing={'-0.02rem'}
          whiteSpace={'pre-wrap'}
          wordBreak={'keep-all'}
        >
          {data?.label}
        </TypographyText>
      )}
    </TableCell>
  );
};

export default React.memo(CategoryTd);
