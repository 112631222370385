/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { css } from '@emotion/css';
import { observer } from 'mobx-react';
import moment from 'moment';

import { Row, Col, TypographyText } from 'components/UI/atoms';
import SymptomTable from 'components/UI/molecules/table/commonSymptomTable';
import { useStores } from 'stores/Context';
import { treatmentModeMap } from 'constant';
import { selectSurvey } from 'utils/util';

const TreatmentSymptomLeft = ({ setTreatmentMode, setLoad, updateFootprintsLog }) => {
  const { surveyStore, treatmentStore, feedbackStore } = useStores();

  useEffect(() => {
    if (treatmentStore.treatment?.survey?.symptoms) {
      const surveys = selectSurvey(treatmentStore.treatment?.survey?.symptoms!);
      feedbackStore.setSymtoms(
        (feedbackStore.symptoms ?? []).filter((e) => surveys?.find((s) => s?.code === e?.code)),
      );
      treatmentStore.setCheckedSurvey(
        (Array.from(treatmentStore.checkedSurvey) ?? []).filter((e) => surveys?.find((s) => s?.code === e)),
      );
    }
  }, [treatmentStore?.treatment?.survey]);

  const refreshSurveyData = useCallback(async () => {
    try {
      await setLoad(true);
      const [treatmentRes, surveyRes] = await Promise.all([
        treatmentStore.refreshTreatmentSurvey(treatmentStore.treatment?.id!),
        surveyStore.getSurvey(treatmentStore.treatment?.surveyID!),
      ]);
      if (treatmentModeMap.has(treatmentRes?.survey?.insuranceType)) {
        setTreatmentMode(treatmentRes?.survey?.insuranceType);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoad(false);
    }
  }, []);

  useEffect(() => {
    updateFootprintsLog('S');
    const handleMessage = (event) => {
      if (event.data === 'childClosed') {
        refreshSurveyData();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Row
      style={{
        display: 'flex',
        paddingTop: '2rem',
        paddingBottom: '0.5rem',
        flexDirection: 'column',
        paddingRight: '1rem',
        height: '100%',
        overflow: 'auto',
      }}
      className={css`
        overflow-x: hidden !important;
        &::-webkit-scrollbar {
          background-color: white;
          width: 0.25rem;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #e5e5e5;
          border-radius: 1rem;
          cursor: pointer;
        }
      `}
    >
      <Row
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          height: '3.6rem',
          minHeight: '3.6rem',
        }}
      >
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.5rem',
          }}
        >
          <Col style={{ display: 'flex' }}>
            <TypographyText fontWeight={700} fontSize={'1.2rem'}>
              {`설문`}
            </TypographyText>
          </Col>
          <Col style={{ display: 'flex' }}>
            <TypographyText fontWeight={400} fontSize={'0.8rem'} color={'#AFAFAF'} whiteSpace="pre">
              {moment(treatmentStore.treatment?.survey?.surveyedAt).format('YYYY/MM/DD HH:mm')} 설문입력
            </TypographyText>
          </Col>
        </Col>
        <Col
          onClick={() => {
            window.open(
              `/patient/${treatmentStore?.treatment?.patientID}/survey/${treatmentStore?.treatment?.surveyID}/r/${treatmentStore?.treatment?.id}`,
              '_blank',
            );
          }}
          className="treatment-detail-button"
          style={{
            width: '7.53rem',
            height: '2.25rem',
            cursor: 'pointer',
            borderRadius: '3px',
            background: '#EEEFF3',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            columnGap: '0.4rem',
          }}
        >
          <TypographyText
            fontSize="0.8rem"
            fontWeight={700}
            lineHeight="0.8rem"
            className={css`
              white-spcae: no-wrap;
              letter-spacing: -0.02rem;
            `}
          >
            설문 수정하기
          </TypographyText>
          <Row
            style={{
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/symtom-edit-icon-black.webp`}
              style={{ width: '0.85rem' }}
              alt="edit"
            />
          </Row>
        </Col>
      </Row>
      <SymptomTable
        treatment={treatmentStore.treatment}
        survey={treatmentStore.treatment?.survey ?? {}}
        symptoms={selectSurvey(treatmentStore.treatment?.survey?.symptoms!)}
        mode="treatmentSymptom"
      />
    </Row>
  );
};

export default observer(TreatmentSymptomLeft);
