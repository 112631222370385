/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react';
import _ from 'lodash';

import { Row, Col, TypographyText } from 'components/UI/atoms';

import { activityMessageMap } from 'constant';

const PatientActivityFilter = ({
  options,
  selectedFilter,
  setSelectedFilter,
  initFilterText,
  filterText,
  setFilterText,
  setRefreshFlag,
  setSelectedFilterForSearch,
}) => {
  const [isOpenFilterOptions, setIsOpenFilterOptions] = useState(false);

  const handleClickFilterOption = useCallback(({ selectedFilter: sf, targetFilterKey }) => {
    if (sf[targetFilterKey]) {
      // 이번에 선택 해제되는 경우
      if (targetFilterKey === 'all') {
        // 전체
        setFilterText(initFilterText);
        setSelectedFilter(options.reduce((a, c) => ({ ...a, [c.value]: false }), {}));
        return;
      } else {
        const newValues = { ...sf, [targetFilterKey]: false, all: false };
        const allFalseFlag = Object.entries(newValues).every(([key, value]) => !value);
        setSelectedFilter(newValues);
        setFilterText(
          allFalseFlag
            ? initFilterText
            : _.keys(newValues)
                .reduce((a: string[], c: string) => (newValues[c] ? [...a, c] : [...a]), [])
                .map((key) => activityMessageMap.get(key)?.filterOptionLabel)
                .join(', '),
        );
        return;
      }
    } else {
      // 이번에 선택 되는 경우
      if (targetFilterKey === 'all') {
        // 전체
        setSelectedFilter(options.reduce((a, c) => ({ ...a, [c.value]: true }), {}));
        setFilterText('전체');
        return;
      } else {
        let allFlag = false;
        if (
          !sf.all &&
          Object.entries({ ...sf, [targetFilterKey]: true })
            .filter(([key, value]) => key !== 'all')
            .every(([key, value]) => value)
        ) {
          allFlag = true;
        }
        const newValues = { ...sf, all: allFlag, [targetFilterKey]: true };
        setSelectedFilter(newValues);
        setFilterText(
          allFlag
            ? '전체'
            : _.keys(newValues)
                .reduce((a: string[], c: string) => (newValues[c] ? [...a, c] : [...a]), [])
                .map((key) => activityMessageMap.get(key)?.filterOptionLabel)
                .join(', '),
        );
        return;
      }
    }
  }, []);

  return (
    <Col style={{ display: 'flex', position: 'relative' }}>
      <button
        style={{
          display: 'flex',
          columnGap: '0.3rem',
          justifyContent: 'space-between',
          paddingInline: '1rem',
          alignItems: 'center',
          width: '12rem',
          height: '2.1rem',
          border: '1px solid #afafaf',
          borderRadius: '3px',
          flexShrink: 0,
          outline: 'none',
        }}
        onClick={() => {
          setIsOpenFilterOptions(!isOpenFilterOptions);
        }}
        onBlur={() => {
          setIsOpenFilterOptions(false);
        }}
        onKeyUp={async (e) => {
          if (e.keyCode === 13) {
            await setSelectedFilterForSearch(selectedFilter);
            await setRefreshFlag(true);
          }
        }}
      >
        <Col
          className="text-ellipsis"
          style={{
            verticalAlign: 'middle',
            marginTop: '-0.15rem',
          }}
        >
          <TypographyText fontSize="0.8rem" color={filterText === initFilterText ? '#AFAFAF' : '#1c1d1c'}>
            {filterText}
          </TypographyText>
        </Col>
        <Col style={{ display: 'flex', flexShrink: 0 }}>
          <img
            style={{ width: '0.435rem' }}
            src={`${process.env.PUBLIC_URL}/img/select-arrow-icon.webp`}
            alt="arrow-down"
          />
        </Col>
        {isOpenFilterOptions && (
          <Row
            style={{
              position: 'absolute',
              top: 'calc(100% + 0.25rem)',
              left: 0,
              width: '12rem',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.8rem',
              padding: '0.75rem 1rem',
              border: '1px solid #AFAFAF',
              borderRadius: '3px',
              background: '#FFFFFF',
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {options.map((option) => {
              return (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.5rem',
                    cursor: 'pointer',
                    flexShrink: 0,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickFilterOption({
                      selectedFilter: { ...selectedFilter },
                      targetFilterKey: option.value,
                    });
                  }}
                >
                  <Col style={{ display: 'flex' }}>
                    {selectedFilter[option.value] ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/img/checked-icon-blue.webp`}
                        style={{ width: '1rem' }}
                        alt={`checked`}
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/img/unchecked-icon.webp`}
                        style={{ width: '1rem' }}
                        alt={`unchecked`}
                      />
                    )}
                  </Col>
                  <Col style={{ display: 'flex' }}>
                    <TypographyText fontSize="0.8rem" lineHeight="1em">
                      {option.label}
                    </TypographyText>
                  </Col>
                </Row>
              );
            })}
          </Row>
        )}
      </button>
    </Col>
  );
};

export default PatientActivityFilter;
