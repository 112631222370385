import moment from 'moment';
import _ from 'lodash';

import styled from '@emotion/styled';
import DatePicker from 'react-multi-date-picker';

import { Row, Col, TypographyText } from 'components/UI/atoms';

const ADateRangePicker = ({
  dateRange = [new Date(moment().add(-1, 'M').format('YYYY/MM/DD')), new Date()],
  setDateRange,
}) => {
  return (
    <Row
      style={{
        position: 'relative',
        height: '2.1rem',
        width: '14rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <DatePicker
        calendarPosition="bottom-center"
        value={dateRange}
        numberOfMonths={2}
        range={true}
        onChange={(e) => {
          if (_.isArray(e) && e.length === 2) {
            setDateRange([
              new Date(moment.unix(e[0].unix).startOf('day').format('YYYY/MM/DD')),
              new Date(moment.unix(e[1].unix).endOf('day').format('YYYY/MM/DD')),
            ]);
          }
        }}
      />
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        }}
      >
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.25rem',
            zIndex: 10,
          }}
        >
          <TypographyText fontSize="0.8rem">{moment(dateRange[0]).format('YYYY/MM/DD')}</TypographyText>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/calendar_icon.webp`}
              style={{ width: '0.8rem' }}
              alt="calendar"
            />
          </Col>
        </Row>
        <TypographyText fontSize="0.8rem">{'-'}</TypographyText>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.25rem',
            zIndex: 10,
          }}
        >
          <TypographyText fontSize="0.8rem">{moment(dateRange[1]).format('YYYY/MM/DD')}</TypographyText>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/calendar_icon.webp`}
              style={{ width: '0.8rem' }}
              alt="calendar"
            />
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

const StyledDateRangePicker = styled(ADateRangePicker)``;

export const DateRangePicker = ({
  dateRange,
  setDateRange,
  ...rest
}: {
  dateRange: Date[];
  setDateRange: any;
}) => <StyledDateRangePicker dateRange={dateRange} setDateRange={setDateRange} {...rest} />;
