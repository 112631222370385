/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Loader, Table, TableBody, TableHead, TableRow } from '@aws-amplify/ui-react';
import { css } from '@emotion/css';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import message from 'antd-message';
import SelectSearch from 'react-select-search';
import _ from 'lodash';
import QRCode from 'qrcode';

import { useStores } from 'stores/Context';
import { TableCell } from 'components/UI/atoms/table/TableCell';
import { Row, TypographyText, ModalType2 } from 'components/UI/atoms';
import { Loading } from 'components/UI/molecules';
import { currentEnv } from 'config';
import { API } from 'aws-amplify';

import ClinicRepository from 'repository/ClinicRepository';

import AdminLayout from '../../templates/AdminLayout';

const url = {
  local: 'https://dev.yejj.in/',
  develop: 'https://dev.yejj.in/',
  staging: 'https://stag.yejj.in/',
  production: 'https://yejj.in/',
};

const QRList = () => {
  const { clientStore } = useStores();
  const [QRList, setQRList] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [nextToken, setNextToken] = useState<string>();
  const [target, setTarget] = useState<Element | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCreatQRModal, setIsOpenCreatQRModal] = useState(false);
  const [isOpenQRDetailModal, setIsOpenQRDetailModal] = useState<any>();
  const [targetClinicId, setTargetClinicId] = useState<string>();
  const [expiredDate, setExpiredDate] = useState<string>('99991231');
  const [isGlobalLoading, setGlobalLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCreateQR = useCallback(
    async ({ clinicId, expiredDate }: { clinicId: string; expiredDate?: string }) => {
      await API.post('yejinadminrestapi', '/selfRegistrationQR', {
        headers: {
          'Content-Type': 'application/json',
        },
        response: true,
        body: {
          clinicId,
          expiredDate,
        },
      });
    },
    [],
  );

  const handleSearch = useCallback(
    async ({
      resetParamFlag = false,
      nextToken,
    }: {
      resetParamFlag?: boolean;
      nextToken: string | undefined | null;
    }) => {
      if (resetParamFlag) {
        setNextToken(undefined);
        setQRList([]);
      }
      setIsLoading(true);
      const res = await ClinicRepository.listQRs({}, resetParamFlag ? undefined : nextToken);
      await setNextToken(res?.nextToken);
      await setQRList((prev) => [...prev, ...res?.items]);
      setIsLoading(false);
    },
    [],
  );

  const initFunc = useCallback(async () => {
    try {
      const subs = {
        local: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        develop: ['borooboroo625@gmail.com'],
        staging: ['borooboroo625@gmail.com'],
        production: ['borooboroo625@gmail.com'],
      };

      if (!subs[currentEnv]?.includes(clientStore.metaData?.userRole?.email)) {
        navigate('/patient');
      }
      const clinics = await ClinicRepository.listClinicsByCreatedAt({});
      setClinicList(clinics.items);
      await handleSearch({ nextToken });
    } catch (e) {
      message.error(`QR 목록을 가져오는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 2500);
    }
  }, []);

  useEffect(() => {
    initFunc();
  }, []);

  useEffect(() => {
    const ob = new IntersectionObserver(
      (e) => {
        if (e?.[0]?.isIntersecting) {
          handleSearch({ nextToken });
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );
    target && ob.observe(target);

    return () => {
      ob.disconnect();
    };
  }, [target, nextToken]);

  // 1: 포스터 QR, 2: 침대 옆 QR, 3: 미정
  const QR유입경로 = useMemo(
    () => [
      {
        id: 1,
        name: 'A2 포스터',
        size: 347.71653543,
      },
      {
        id: 2,
        name: 'A4 포스터(A2 포스터 작은버전)',
        size: 170.07874016,
      },
      {
        id: 3,
        name: 'A4 포스터',
        size: 141.73228346,
      },
      {
        id: 4,
        name: 'B6 포스터',
        size: 219.21259843,
      },
      {
        id: 6,
        name: 'X배너(옥내)',
        size: 2100,
      },
    ],
    [],
  );

  useEffect(() => {
    if (!_.isEmpty(isOpenQRDetailModal)) {
      setTargetClinicId(isOpenQRDetailModal?.clinic?.id);
      setExpiredDate(isOpenQRDetailModal?.expiredDate);
      QR유입경로.forEach((item) => {
        const i = item.id;
        const canvas = document.getElementById(`canvas${i}`);
        QRCode.toCanvas(
          canvas,
          `${url[currentEnv]}${isOpenQRDetailModal?.QRID}?p=${i}`,
          { errorCorrectionLevel: 'H', width: item.size, margin: 0 },
          function (error) {
            if (error) console.error(error);
            console.log('success!');
          },
        );
      });
    }
  }, [isOpenQRDetailModal]);

  return (
    <>
      <ModalType2
        isOpen={!_.isEmpty(isOpenQRDetailModal)}
        okMsg="수정"
        okFunction={async () => {
          if (!targetClinicId) return message.error('한의원을 선택해주세요.', 2500);
          if (expiredDate.length !== 8) return message.error('만료일을 입력해주세요.', 2500);

          await setGlobalLoading(true);
          await ClinicRepository.updateQR({
            QRID: isOpenQRDetailModal?.QRID,
            clinicID: targetClinicId,
            expiredDate,
          });
          await setGlobalLoading(false);
          handleSearch({ resetParamFlag: true, nextToken });
          setIsOpenQRDetailModal({});
          message.success('수정이 완료되었습니다.', 2500);
        }}
        cancelMsg="닫기"
        cancelFunction={() => {
          setTargetClinicId(undefined);
          setExpiredDate('99991231');
          setIsOpenQRDetailModal({});
        }}
        contentHeight="460px"
        width={40}
        content={
          <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
            <Row style={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <TypographyText fontSize="1.2rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
                {'만료일'}
              </TypographyText>
              <input
                type="number"
                min={20240101}
                max={99991231}
                onChange={(e) => {
                  setExpiredDate(e.target.value);
                }}
                value={expiredDate}
                className="border border-gray-300 rounded-md w-full p-2"
                placeholder="만료일(YYYYMMDD)"
              />
            </Row>
            <SelectSearch
              options={[
                ...clinicList.map((clinic) => ({
                  name: `${clinic.clinicName} ${clinic.clinicEmail}`,
                  value: clinic.id,
                })),
              ]}
              search
              value={targetClinicId}
              onChange={(value) => setTargetClinicId(value.toString())}
              placeholder="한의원 선택"
            />
            <button
              className="border border-gray-300 rounded-md w-full p-2"
              onClick={() => {
                QR유입경로.forEach((item) => {
                  const link = document.createElement('a');
                  link.download = `${isOpenQRDetailModal?.clinic.clinicName}(${isOpenQRDetailModal?.clinic.clinicEmail})-${item.name}.png`;
                  link.href = (document.getElementById(`canvas${item.id}`) as HTMLCanvasElement).toDataURL();
                  link.click();
                });
              }}
            >
              download
            </button>
            <Row style={{ display: 'flex', flexWrap: 'wrap', columnGap: '0.5rem' }}>
              <canvas id="canvas1" />
              <canvas id="canvas2" />
              <canvas id="canvas3" />
              <canvas id="canvas4" />
              <canvas id="canvas6" />
            </Row>
            <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.3rem' }}>
              <TypographyText>
                {`문자메세지로 환자들에게 '환자등록 링크'를 보내야 하는 경우 아래 링크 복사 버튼 클릭`}
              </TypographyText>
              <button
                style={{
                  paddingInline: '1.12rem',
                  paddingBlock: '0.7rem',
                  background: '#ffffff',
                  border: '1px solid #1c252c',
                  borderRadius: '3px',
                  width: 'fit-content',
                }}
                onClick={() => {
                  try {
                    window.navigator.clipboard.writeText(
                      _.trim(`${url[currentEnv]}${isOpenQRDetailModal?.QRID}?p=5`),
                    );
                    message.success('링크가 복사되었습니다.', 3000);
                  } catch (e) {
                    console.log(e);
                    message.error(`링크 복사에 문제가 발생했습니다. ${e}`, 3000);
                  }
                }}
              >
                <TypographyText fontWeight={700} whiteSpace="pre">
                  {'링크 복사'}
                </TypographyText>
              </button>
            </Row>
          </Row>
        }
      />
      <ModalType2
        title="QR코드 생성"
        isOpen={isOpenCreatQRModal}
        okMsg="생성"
        okFunction={async () => {
          if (!targetClinicId) return message.error('한의원을 선택해주세요.', 2500);
          if (expiredDate.length !== 8) return message.error('만료일을 입력해주세요.', 2500);

          await setGlobalLoading(true);
          setIsOpenCreatQRModal(false);
          setTargetClinicId(undefined);
          setExpiredDate('99991231');
          await handleCreateQR({ clinicId: targetClinicId, expiredDate });
          await setGlobalLoading(false);
          handleSearch({ resetParamFlag: true, nextToken });
          message.success('생성이 완료되었습니다.', 2500);
        }}
        cancelMsg="닫기"
        cancelFunction={() => {
          setTargetClinicId(undefined);
          setExpiredDate('99991231');
          setIsOpenCreatQRModal(false);
        }}
        contentHeight="400px"
        width={40}
        content={
          <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
            <Row style={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <TypographyText fontSize="1.2rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
                {'만료일'}
              </TypographyText>
              <input
                type="number"
                min={20240101}
                max={99991231}
                onChange={(e) => {
                  setExpiredDate(e.target.value);
                }}
                value={expiredDate}
                className="border border-gray-300 rounded-md w-full p-2"
                placeholder="만료일(YYYYMMDD)"
              />
            </Row>
            <SelectSearch
              options={[
                ...clinicList.map((clinic) => ({
                  name: `${clinic.clinicName} ${clinic.clinicEmail}`,
                  value: clinic.id,
                })),
              ]}
              search
              value={targetClinicId}
              onChange={(value) => setTargetClinicId(value.toString())}
              placeholder="한의원 선택"
            />
          </Row>
        }
      />
      <Row
        flex
        flexDirection={'column'}
        style={{
          position: 'relative',
          rowGap: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            style={{
              border: '1px solid #c4c4c4',
              borderRadius: '3px',
              background: '#ffffff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0.45rem 1rem',
              flexShrink: 0,
            }}
            onClick={() => setIsOpenCreatQRModal(true)}
          >
            <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
              {'QR코드 생성'}
            </TypographyText>
          </button>
        </Row>
        <Row
          flex
          className={css`
            border-top: 1px solid #afafaf !important;
            thead > tr > th {
              font-weight: 400 !important;
            }
            tbody > tr:last-child > td {
              border-bottom: 1px solid #afafaf !important;
            }
            tbody > tr > td:last-child {
              border-right: none !important;
            }
            tbody > tr > td:first-child {
              border-left: none !important;
            }
          `}
        >
          <Table highlightOnHover={false} size={undefined} variation={undefined}>
            <TableHead>
              <TableRow backgroundColor={'#F2F2F2'}>
                <TableCell as="th" textAlign={'center'}>
                  QR_ID
                </TableCell>
                <TableCell as="th" textAlign={'center'}>
                  한의원
                </TableCell>
                <TableCell as="th" textAlign={'center'}>
                  한의원 이메일
                </TableCell>
                <TableCell as="th" textAlign={'center'}>
                  한의원 전화번호
                </TableCell>
                <TableCell as="th" textAlign={'center'}>
                  QR 만료일
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {QRList.length > 0 ? (
                QRList.map((qr, i) => (
                  <TableRow
                    key={qr.QRID}
                    ref={QRList.length - 1 === i ? setTarget : null}
                    onClick={() => {
                      setIsOpenQRDetailModal(qr);
                    }}
                    className={css`
                      cursor: pointer !important;
                      :hover {
                        background: #fafafa;
                      }
                    `}
                  >
                    <TableCell textAlign={'center'}>{qr.QRID}</TableCell>
                    <TableCell textAlign={'center'}> {qr.clinic.clinicName}</TableCell>
                    <TableCell textAlign={'center'}> {qr.clinic.clinicEmail}</TableCell>
                    <TableCell textAlign={'center'}>
                      {qr.clinic.phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}
                    </TableCell>
                    <TableCell textAlign={'center'}>
                      {' '}
                      {moment(qr.expiredDate, 'YYYYMMDD').format('YYYY-MM-DD')}
                    </TableCell>
                  </TableRow>
                ))
              ) : isLoading ? (
                <></>
              ) : (
                <TableRow>
                  <TableCell textAlign={'center'} colSpan={20} color={'#AFAFAF'}>
                    등록된 QR코드가 없습니다.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Row>
      </Row>
      {isGlobalLoading && <Loading mode="global" />}
      {isLoading && (
        <Row flex justifyContent={'center'} alignItems={'center'}>
          <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
        </Row>
      )}
    </>
  );
};

const QRPage = () => {
  return <AdminLayout title={'환자등록 QR 목록'} content={<QRList />} sideType={0} />;
};

export default observer(QRPage);
