import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { Row, TypographyText } from 'components/UI/atoms';

const SignUpStep3 = ({ email, signUpType }: { email: string; signUpType: string }) => {
  const navigate = useNavigate();

  const titleText = useMemo(() => {
    switch (signUpType) {
      case 'academy':
        return '회원가입 링크 전송 완료';
      case 'direct':
        return '회원가입 요청 완료';
      default:
        return '회원가입 요청 완료';
    }
  }, [signUpType]);

  const descriptionTextComponents = useMemo(() => {
    if (signUpType === 'academy') {
      return [
        <TypographyText
          color="#346AFF"
          fontSize="1rem"
          lineHeight="1.4em"
          whiteSpace="pre-wrap"
          letterSpacing="0.02em"
        >
          {email}
        </TypographyText>,
        <TypographyText fontSize="1rem" lineHeight="1.4em" whiteSpace="pre-wrap" letterSpacing="0.02em">
          {` 로 회원가입 링크가 전송되었어요.
회원가입 링크 접속 후 비밀번호를 설정하여 회원가입을 완료해 주세요.
입력한 주소로 메일이 오지 않았다면 스팸함을 확인해 주세요.`}
        </TypographyText>,
      ];
    } else {
      return [
        <TypographyText fontSize="1rem" lineHeight="1.4em" whiteSpace="pre-wrap" letterSpacing="0.02em">
          {`회원가입이 승인되면 `}
        </TypographyText>,
        <TypographyText
          color="#346AFF"
          fontSize="1rem"
          lineHeight="1.4em"
          whiteSpace="pre-wrap"
          letterSpacing="0.02em"
        >
          {email}
        </TypographyText>,
        <TypographyText fontSize="1rem" lineHeight="1.4em" whiteSpace="pre-wrap" letterSpacing="0.02em">
          {` 로 회원가입 링크가 전송됩니다.
회원가입 링크 접속 후 비밀번호를 설정하여 회원가입을 완료해 주세요.
입력한 주소로 메일이 오지 않았다면 스팸함을 확인해 주세요.`}
        </TypographyText>,
      ];
    }
  }, [signUpType, email]);

  return (
    <Row style={{ display: 'flex', flexDirection: 'column', paddingTop: '5rem', paddingBottom: '1rem' }}>
      <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
        <img
          src={`${process.env.PUBLIC_URL}/img/signup-complete-icon-blue.webp`}
          style={{ width: '3.7rem' }}
          alt="회원가입 완료"
        />
        <Row>
          <TypographyText fontSize="1.6rem" lineHeight="1.31rem" fontWeight={700}>
            {titleText}
          </TypographyText>
        </Row>
        <Row
          style={{
            width: '115%',
            textAlign: 'center',
          }}
        >
          {descriptionTextComponents}
        </Row>
        <button
          style={{
            width: `100%`,
            maxWidth: '29.2rem',
            height: '3.6rem',
            borderRadius: '3px',
            background: '#346AFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => navigate('/')}
        >
          <TypographyText
            fontWeight={700}
            fontSize="1.2rem"
            lineHeight="1.2em"
            color="#FFFFFF"
          >{`홈으로`}</TypographyText>
        </button>
      </Row>
    </Row>
  );
};

export default SignUpStep3;
