/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useMemo } from 'react';
import { Col, Row, TypographyText, ModalType2 } from 'components/UI/atoms';
import { allCasePhoneValidationCheck } from 'utils/util';
import _, { set } from 'lodash';

import { Loading } from 'components/UI/molecules';
import PhoneInput from 'react-phone-number-input/input';
import message from 'antd-message';
import { observer } from 'mobx-react';
import { useStores } from 'stores/Context';
import us from 'repository/UserRepository';

const NoticeSetting: React.FC<{}> = () => {
  const { clientStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

  // 기타
  const [receiveMarketingNotice, setReceiveMarketingNotice] = useState(0); // 마케팅 알림
  const [receiveSurveyDoneAlimtalk, setReceiveSurveyDoneAlimtalk] = useState(0); // 설문완료 알림
  const [isOpenRequirePhoneModal, setIsOpenRequirePhoneModal] = useState(false); // 설문완료 알림 수신 설정 시 전화번호가 없으면 나오는 모달
  const [requirePhoneModalPhone, setRequirePhoneModalPhone] = useState('');

  const [modalUserValidationFlag, setModalUserValidationFlag] = useState({
    phone: undefined,
  });

  const selectInputStyle = useMemo(
    () => ({
      outline: 'none',
      width: '10rem',
      height: '2.1rem',
      display: 'flex',
      alignItems: 'center',
      paddingInline: '1rem',
      border: '1px solid #afafaf',
      borderRadius: '3px',
      cursor: 'pointer',
      zIndex: 0,
    }),
    [],
  );

  const inputStyle = useMemo(
    () => ({
      height: '2.1rem',
      minWidth: '17rem',
      width: '100%',
      paddingInline: '0.6rem',
      border: '1px solid #afafaf',
      borderRadius: '3px',
      fontSize: '1rem',
      lineHeight: '1em',
      outline: 'none',
    }),
    [],
  );

  const modalUserConfirmFlag = useMemo(() => {
    // 입력 필드의 유효성 검사 통과 여부
    return _.values(modalUserValidationFlag).every((e) => e === false);
  }, [modalUserValidationFlag]);

  const setData = useCallback(async () => {
    // 데이터 세팅을 하기 위함
    setIsLoading(true); // 로딩 시작
    await Promise.all([
      // 개인 정보 가져오기
      clientStore.getUser(clientStore.metaData?.user.id),
    ])
      .then(() => {
        const userData = clientStore.user ?? {};
        const terms = userData.terms;
        const config = JSON.parse(userData.config ?? '{}');
        // 정보 가져오기 성공, 데이터 세팅
        setReceiveSurveyDoneAlimtalk(config?.receiveSurveyDoneAlimtalk ? 1 : 0); // 설문완료 알림
        setReceiveMarketingNotice(terms?.[3] ? 1 : 0); // 마케팅 알림
      })
      .catch((e) => {
        // 정보 가져오기 실패
        console.log(e);
      })
      .finally(() => {
        // 로딩 종료
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setData();
  }, []);

  const updateUserDataHandler = async ({
    phone,
    receiveSurveyDoneAlimtalk,
    receiveMarketingNotice,
    mode,
  }: {
    phone?: string;
    receiveSurveyDoneAlimtalk?: number;
    receiveMarketingNotice?: number;
    mode: '전화번호 및 설문완료 알림' | '마케팅 알림' | '설문완료 알림';
  }) => {
    try {
      setIsGlobalLoading(true);
      const value = {};
      if (phone) {
        value['phone'] = phone?.replace('+82', '0') ?? phone;
      }
      if (receiveSurveyDoneAlimtalk !== undefined) {
        const config = JSON.parse(clientStore.user?.config ?? '{}');
        value['config'] = JSON.stringify({
          ...config,
          receiveSurveyDoneAlimtalk: receiveSurveyDoneAlimtalk ? true : false,
        });
      }
      if (receiveMarketingNotice !== undefined) {
        const validationFlag = _.isArray(clientStore.user?.terms) && clientStore.user.terms.length >= 4;
        if (validationFlag) {
          const terms = _.cloneDeep(clientStore.user?.terms);
          terms[3] = receiveMarketingNotice ? true : false;
          terms[0] = terms.slice(1).every((e) => e === true);
          value['terms'] = terms;
        }
      }
      await us.updateUser({
        id: clientStore.metaData?.user.id!,
        ...value,
      });
      await clientStore.setMetaData(sessionStorage.getItem('sub')).then(() => {
        message.success(`${mode} 설정이 완료되었습니다.`, 2500);
      });
      await setData();
    } catch (e) {
      message.error(`${mode} 설정에 문제가 발생했습니다.`, 2500);
      console.log(e);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  return (
    <>
      {isLoading && !isGlobalLoading ? (
        <Loading />
      ) : (
        <Row flex style={{ flex: 1, paddingTop: '2rem' }}>
          <ModalType2
            isOpen={isOpenRequirePhoneModal}
            okMsg="입력 완료"
            okBtnDisabled={!modalUserConfirmFlag}
            okFunction={async () => {
              await updateUserDataHandler({
                phone: requirePhoneModalPhone,
                receiveSurveyDoneAlimtalk,
                mode: '전화번호 및 설문완료 알림',
              });
              setIsOpenRequirePhoneModal(false);
              setReceiveSurveyDoneAlimtalk(1);
              setRequirePhoneModalPhone('');
            }}
            cancelMsg="닫기"
            cancelFunction={() => {
              setIsOpenRequirePhoneModal(false);
              setReceiveSurveyDoneAlimtalk(0);
              setRequirePhoneModalPhone('');
            }}
            width={33.2}
            content={
              <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', marginTop: '1rem' }}>
                <TypographyText fontSize="1.4rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
                  {'알림 수신을 위한 전화번호를 입력해 주세요.'}
                </TypographyText>
                <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.25rem' }}>
                  <PhoneInput
                    style={inputStyle}
                    country={'KR'}
                    placeholder={'전화번호를 입력해 주세요.'}
                    value={requirePhoneModalPhone}
                    onChange={(e) => {
                      if (allCasePhoneValidationCheck(e?.replace('+82', '0'))) {
                        setModalUserValidationFlag({
                          ...modalUserValidationFlag,
                          phone: false,
                        });
                      } else {
                        setModalUserValidationFlag({
                          ...modalUserValidationFlag,
                          phone: true,
                        });
                      }
                      setRequirePhoneModalPhone(e);
                    }}
                  />
                  {modalUserValidationFlag.phone === true && (
                    <Row flex>
                      <TypographyText
                        style={{
                          color: '#DF635C',
                          fontSize: '0.8rem',
                          lineHeight: '1em',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'keep-all',
                          textIndent: '0.2rem',
                        }}
                      >
                        {requirePhoneModalPhone?.length > 0
                          ? `전화번호 형식이 맞는지 확인해 주세요.`
                          : `전화번호를 입력해 주세요.`}
                      </TypographyText>
                    </Row>
                  )}
                </Col>
                <Row style={{ display: 'flex', marginTop: '-0.5rem' }}>
                  <TypographyText
                    style={{
                      color: '#afafaf',
                      fontSize: '0.8rem',
                      lineHeight: '1.2em',
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'keep-all',
                    }}
                  >
                    {`[기본 설정] > 개인 정보 전화번호로 등록됩니다.`}
                  </TypographyText>
                </Row>
              </Row>
            }
          />
          {isGlobalLoading && <Loading mode="global" />}
          <Row
            style={{
              flex: '1 1 0',
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <table className="basic-setting-table" style={{ width: '100%' }}>
              <colgroup>
                <col style={{ width: '7.8rem', minWidth: '7.8rem' }} />
                <col style={{ width: 'calc(100% - 7.8rem)', minWidth: 'calc(100% - 7.8rem)' }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    <span>{`마케팅 알림`}</span>
                  </td>
                  <td>
                    <Row
                      style={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div style={{ width: 'fit-content' }} className="relative">
                        <select
                          className="select-input"
                          style={selectInputStyle}
                          value={receiveMarketingNotice}
                          onChange={(e) => {
                            setReceiveMarketingNotice(e.target.value === '1' ? 0 : 1);
                            updateUserDataHandler({
                              receiveMarketingNotice: Number(e.target.value),
                              mode: '마케팅 알림',
                            });
                          }}
                        >
                          <option value={1}>수신</option>
                          <option value={0}>수신안함</option>
                        </select>
                        <img
                          className="absolute"
                          src={`${process.env.PUBLIC_URL}/img/select-arrow-icon.webp`}
                          style={{
                            top: '50%',
                            right: '1rem',
                            width: '0.6rem',
                            transform: 'translateY(-50%)',
                          }}
                          alt="select-arrow-icon"
                        />
                      </div>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{`설문완료 알림`}</span>
                  </td>
                  <td>
                    <Row
                      style={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div style={{ width: 'fit-content' }} className="relative">
                        <select
                          className="select-input"
                          style={selectInputStyle}
                          value={receiveSurveyDoneAlimtalk}
                          onChange={(e) => {
                            setReceiveSurveyDoneAlimtalk(e.target.value === '1' ? 0 : 1);
                            // 전화번호가 없으면
                            if (!clientStore.user?.phone) {
                              setIsOpenRequirePhoneModal(true);
                            } else {
                              updateUserDataHandler({
                                receiveSurveyDoneAlimtalk: Number(e.target.value),
                                mode: '설문완료 알림',
                              });
                            }
                          }}
                        >
                          <option value={1}>수신</option>
                          <option value={0}>수신안함</option>
                        </select>
                        <img
                          className="absolute"
                          src={`${process.env.PUBLIC_URL}/img/select-arrow-icon.webp`}
                          style={{
                            top: '50%',
                            right: '1rem',
                            width: '0.6rem',
                            transform: 'translateY(-50%)',
                          }}
                          alt="select-arrow-icon"
                        />
                      </div>
                    </Row>
                  </td>
                </tr>
                <tr style={{ height: 'fit-content' }}>
                  <td></td>
                  <td>
                    <Row
                      style={{
                        display: 'flex',
                        marginTop: '0.1rem',
                      }}
                    >
                      <TypographyText
                        style={{
                          color: '#afafaf',
                          fontSize: '0.8rem',
                          lineHeight: '1.2em',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {`환자가 설문완료 시, 개인 전화번호로 카카오톡 알림이 전송됩니다.`}
                      </TypographyText>
                    </Row>
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
        </Row>
      )}
    </>
  );
};

export default observer(NoticeSetting);
