/* eslint-disable react-hooks/exhaustive-deps */
import { API, Auth, Hub } from 'aws-amplify';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SignUpParams } from '@aws-amplify/auth';
import message from 'antd-message';
import { Row, TypographyText } from 'components/UI/atoms';
import { Loading } from 'components/UI/molecules';
import jwt_decode from 'jwt-decode';
import { observer } from 'mobx-react';
import { passwordValidationCheck } from 'utils/util';
import { useStores } from '../../../../stores/Context';
import LandingLayout from '../../../templates/LandingLayout';
import { Button } from '../../../UI/atoms/inputs/Button';
import { TextField } from '../../../UI/atoms/inputs/TextField';

const signupConfirmApi = async (userSub: string, token: string) => {
  const apiName = 'yejinadminrestapi';
  const path = '/signupConfirm';
  const option = {
    headers: {
      'Content-Type': 'application/json',
    },
    response: true,
    body: {
      userSub: userSub,
      token: token,
    },
  };
  await API.post(apiName, path, option);
};

const passwordConfirmValidationCheck = (password: string, passwordConfirm: string) => {
  if (password === passwordConfirm || !!!passwordConfirm) {
    return true;
  }
  return false;
};

const SignUpConfirmPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [businessType, setBusinessType] = useState('BUSINESS' || 'PERSONAL');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const { token } = useParams();
  const [isExpToken, setIsExpToken] = useState(false);
  const [clinicName, setClinicName] = useState();
  const [clinicPhone, setClinicPhone] = useState();
  const [clinicEmail, setClinicEmail] = useState();
  const { userStore, clientStore } = useStores();
  const [error, setError] = useState(false);
  const [usernameExistsException, setUsernameExistsException] = useState(false);

  const preventExit = useCallback((e) => {
    e.preventDefault();
    return '';
  }, []);

  useEffect(() => {
    sessionStorage.clear();
    const now = Math.floor(new Date().getTime() / 1000);
    const payload = jwt_decode(token);
    setName(payload['name']);
    setEmail(payload['email']);
    setPhone(payload['phone']);
    setClinicName(payload['clinicName']);
    setClinicPhone(payload['clinicPhone']);
    setClinicEmail(payload['clinicEmail']);
    setIsExpToken(payload['exp'] < now);
    setBusinessType(payload['businessType']);
    return () => {
      window.removeEventListener('beforeunload', preventExit);
    };
  }, []);

  const signUpHandler = async ({
    userEmailId,
    userPassword,
    userToken,
  }: {
    userEmailId: string;
    userPassword: string;
    userToken: string;
  }) => {
    try {
      await setIsLoading(true);
      window.addEventListener('beforeunload', preventExit);
      const res = await Auth.signUp({
        username: userEmailId,
        password: userPassword,
        autoSignIn: {
          enabled: true,
        },
      } as SignUpParams);
      await signupConfirmApi(res.userSub, userToken!);
      await userStore.signIn(userEmailId, userPassword);
      await clientStore.setMetaData(res.userSub);
      message.success(`${clientStore.metaData?.user.username} 한의사님, 환영합니다. 😄`, 8000);
      navigate('/setting', {
        state: {
          tutorialFlag: true,
        },
      });
      Hub.listen('auth', async ({ payload }) => {
        const { event } = payload;
        // console.log('event : ', event);
        if (event === 'autoSignIn') {
        } else if (event === 'autoSignIn_failure') {
          navigate('/signin');
          // redirect to sign in page
        }
      });
    } catch (e) {
      console.error(e);
      if (e.name === 'UsernameExistsException') {
        setUsernameExistsException(true);
      } else {
        setError(true);
      }
      message.error(`회원 가입 과정에서 문제가 발생했습니다.`, 2500);
    } finally {
      setIsLoading(false);
      window.removeEventListener('beforeunload', preventExit);
    }
  };

  const confirmFlag =
    passwordValidationCheck(password) &&
    passwordConfirmValidationCheck(password, confirmPassword) &&
    password?.length &&
    confirmPassword?.length;

  let content;
  if (isExpToken) {
    content = (
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '10rem',
          rowGap: '2.4rem',
          textAlign: 'center',
        }}
      >
        <TypographyText fontSize="1.2rem" lineHeight="1.31em" whiteSpace="pre-wrap">
          {`페이지가 만료되었습니다. 회원가입을 다시 해주세요.`}
        </TypographyText>
        <button
          style={{
            height: '3.6rem',
            borderRadius: '3px',
            background: '#1C252C',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem 2rem',
          }}
          onClick={() => navigate('/signup')}
        >
          <TypographyText
            fontWeight={700}
            fontSize="1.2rem"
            lineHeight="1.2em"
            color="#FFFFFF"
          >{`회원가입하기`}</TypographyText>
        </button>
      </Row>
    );
  } else if (usernameExistsException) {
    content = (
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '10rem',
          rowGap: '2.4rem',
          textAlign: 'center',
        }}
      >
        <TypographyText fontSize="1.2rem" lineHeight="1.31em" whiteSpace="pre-wrap">
          {`이미 가입된 계정입니다.  로그인 해주시기 바랍니다.`}
        </TypographyText>
        <button
          style={{
            height: '3.6rem',
            borderRadius: '3px',
            background: '#1C252C',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem 2rem',
          }}
          onClick={() => navigate('/signin')}
        >
          <TypographyText
            fontWeight={700}
            fontSize="1.2rem"
            lineHeight="1.2em"
            color="#FFFFFF"
          >{`로그인 화면으로`}</TypographyText>
        </button>
      </Row>
    );
  } else {
    content = (
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        {isLoading && (
          <Loading
            mode="globalWithMessage"
            circleColor="gray"
            circleWidth="60px"
            circleHeight="60px"
            message={`가입 과정 중에 시간이 소요될 수 있습니다.
잠시 기다려주세요.`}
          />
        )}
        <Row
          style={{
            width: 'calc(100% / 3)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '2rem',
          }}
        >
          <TypographyText fontWeight={400} fontSize={'1.6rem'} lineHeight="1.31em" whiteSpace="pre-wrap">
            {`확인되셨습니다.
비밀번호를 설정해주세요.`}
          </TypographyText>
          <table className="sign-up-confirm-table">
            <tr>
              <td>
                <span>대표자 정보</span>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <span>이메일</span>
              </td>
              <td>
                <span>{email}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>이름</span>
              </td>
              <td>
                <span>{name}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>전화번호</span>
              </td>
              <td>
                <span>{phone}</span>
              </td>
            </tr>
          </table>
          {businessType === 'BUSINESS' && (
            <table className="sign-up-confirm-table">
              <tr>
                <td>
                  <span>의료기관 정보</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>이름</span>
                </td>
                <td>
                  <span>{clinicName}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>전화번호</span>
                </td>
                <td>
                  <span>{clinicPhone}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>이메일</span>
                </td>
                <td>
                  <span>{clinicEmail}</span>
                </td>
              </tr>
            </table>
          )}
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.5rem',
            }}
          >
            <Row style={{ display: 'flex', marginBottom: '0.25rem' }}>
              <TypographyText fontSize="1rem" fontWeight={700} lineHeight={'1.2rem'}>
                비밀번호 설정
              </TypographyText>
            </Row>
            <TextField
              label={'password'}
              autoComplete="off"
              labelHidden={true}
              placeholder={'비밀번호 (영어 소문자, 숫자 포함 10자리 이상)'}
              type={'password'}
              onChange={(e) => setPassword(e.target.value)}
            />
            {!!password?.length && !passwordValidationCheck(password) && (
              <TypographyText yjTypeTypographyText="survey-validation">
                비밀번호는 영어 소문자, 숫자 포함 10자리 이상의 문자로 설정해주세요.
              </TypographyText>
            )}
            <TextField
              label={'passwordConfirm'}
              labelHidden={true}
              placeholder={'비밀번호 확인'}
              type={'password'}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {!passwordConfirmValidationCheck(password, confirmPassword) && (
              <TypographyText yjTypeTypographyText="survey-validation">
                비밀번호가 일치 하지 않습니다.
              </TypographyText>
            )}
            {error && (
              <TypographyText yjTypeTypographyText="survey-validation">
                회원가입에 실패했습니다.
              </TypographyText>
            )}
          </Row>
          <Button
            height={'3.6rem'}
            backgroundColor={confirmFlag ? '#346AFF' : '#c4c4c4'}
            isDisabled={!confirmFlag}
            color={'#ffffff'}
            fontSize={'1.2rem'}
            onClick={() =>
              signUpHandler({
                userEmailId: email,
                userPassword: password,
                userToken: token,
              })
            }
          >
            회원가입 완료
          </Button>
        </Row>
      </Row>
    );
  }

  return <LandingLayout content={content} />;
};

export default observer(SignUpConfirmPage);
