import { Row } from 'components/UI/atoms';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingLayout: FC<{
  content?: ReactNode;
}> = (props) => {
  const navigate = useNavigate();
  return (
    <Row
      id="landing-layout-wrapper"
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
      }}
    >
      <Row
        style={{
          display: 'flex',
          paddingLeft: '6.25%',
          height: '5rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #c4c4c4',
        }}
      >
        <Row style={{ display: 'flex', flexShrink: 0, cursor: 'pointer' }} onClick={() => navigate('/')}>
          <img
            id="landing-page-logo"
            style={{ width: '7.06rem' }}
            src={`${process.env.PUBLIC_URL}/img/logo_black.webp`}
            alt="yejin-logo"
          />
        </Row>
      </Row>
      {props.content}
    </Row>
  );
};

export default LandingLayout;
