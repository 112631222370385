import { TypographyText, Row, Span } from 'components/UI/atoms';
import { map } from 'lodash';
import { byeonjeungDataSample } from 'constant/mockup';

import Palgang from './progress/cure/Palgang';

const AnalysisHistory = ({
  byeonjeungData = byeonjeungDataSample,
  patientName = '',
  isLoading = true,
  paddingInlineNoneFlag = false,
}: {
  byeonjeungData?: {
    팔강: {
      [key: string]: number;
    };
    변증: {
      code: string;
      koreanName: string;
      chineseName: string;
      koreanDesc: string;
      chineseDesc: string;
      weight: number;
      relativeSymptomCode: { code: string; contribution: number }[];
      checkFlag: boolean;
    }[];
  };
  patientName: string;
  isLoading?: boolean;
  paddingInlineNoneFlag?: boolean;
}) => {
  const hiddenHightlightFlag = byeonjeungData.변증?.every((d) => !d.checkFlag);
  return (
    <div className="flex">
      <div
        className={`flex flex-col flex-1 border-r border-e5e5e5 pt-4 pb-4 ${
          paddingInlineNoneFlag ? '' : 'pl-8'
        } pr-4`}
      >
        <Palgang
          patientName={patientName}
          mode="modal"
          byeonjeungData={byeonjeungData}
          isLoading={isLoading}
        />
      </div>
      <div className={`flex flex-col flex-1 pt-4 pb-4 pl-4 ${paddingInlineNoneFlag ? '' : 'pr-6'}`}>
        <Row
          style={{
            display: 'flex',
            marginBottom: '1.25rem',
          }}
        >
          <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2rem">
            변증 辨證
          </TypographyText>
        </Row>
        <Row
          style={{
            height: '2.2rem',
            minHeight: '2.2rem',
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '0.5rem',
          }}
        >
          <TypographyText fontSize={'0.9rem'} lineHeight="1.2em" letterSpacing="-0.02em">
            {`${patientName}님의 증상을 토대로 분석한 한의 변증${
              byeonjeungData?.변증?.length > 1 ? ` 상위 ${byeonjeungData.변증.length}가지` : ''
            }입니다.`}
          </TypographyText>
        </Row>
        <div className="flex flex-col border border-e5e5e5 ">
          <div className="h-8 flex justify-between items-center pl-3 pr-4 bg-F5F5F5">
            <Span className="text-0.8 leading-1.2 whitespace-pre-wrap break-keep">{`변증`}</Span>
            {!hiddenHightlightFlag && (
              <Span className="text-0.8 leading-1.2 whitespace-pre-wrap break-keep">{`중요`}</Span>
            )}
          </div>
          {byeonjeungData.변증?.length > 0 ? (
            map(byeonjeungData.변증, (d, i) => (
              <div
                key={`b-${i}`}
                className="border-b border-e5e5e5 pt-3 pl-3 pr-4 pb-3"
                style={{ border: i === byeonjeungData.변증.length - 1 && 'none' }}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <div className="flex flex-1 items-center justify-between gap-x-3">
                    <div className={'w-16'}>
                      <Span
                        divClassName={`${isLoading && 'blur-sm'}`}
                        className="text-0.9 font-bold leading-1.2 whitespace-pre-wrap break-keep"
                      >{`${d.koreanName} ${d.chineseName}`}</Span>
                    </div>
                    <div className="flex flex-1">
                      <div
                        className="bg-FBE840 h-4.8"
                        style={{ flex: !isLoading && d.weight, transition: 'all 1.5s ease' }}
                      />
                      <div
                        className={`bg-F0F7FA h-4.8  ${isLoading ? 'flex-1' : ''}`}
                        style={{ flex: !isLoading && 100 - d.weight, transition: 'all 1.5s ease' }}
                      />
                    </div>
                    <div className="flex justify-end min-w-8.4">
                      <Span
                        className="text-0.9 leading-1.2"
                        divClassName={`${isLoading && 'blur-sm'}`}
                      >{`${d.weight}%`}</Span>
                    </div>
                  </div>
                  {!hiddenHightlightFlag && (
                    <div className="flex -mr-px">
                      {d.checkFlag ? (
                        <img
                          src={`${process.env.PUBLIC_URL}/img/readonly-check-icon.webp`}
                          style={{ width: '1.2rem' }}
                          alt={`check`}
                        />
                      ) : (
                        <div style={{ width: '1.2rem' }} />
                      )}
                    </div>
                  )}
                </div>
                <Span
                  className="text-0.8 leading-1.2 tracking-m1 whitespace-pre-wrap break-keep"
                  divClassName={`mt-1.6 ${isLoading && 'blur-sm'} ${!hiddenHightlightFlag && 'pr-6'}`}
                >
                  {`${d.koreanDesc}`}
                </Span>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center h-18">
              <Span
                className={` text-0.9 leading-1.2 text-afafaf`}
                divClassName={`w-full justify-center items-center`}
              >{`한의사의 진단이 필요합니다.`}</Span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AnalysisHistory;
