/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Loader } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import message from 'antd-message';
import _ from 'lodash';

import { useStores } from 'stores/Context';
import { Row, TypographyText, ModalType2, Modal } from 'components/UI/atoms';
import { Loading } from 'components/UI/molecules';
import { currentEnv } from 'config';
import ClinicRepository from 'repository/ClinicRepository';
import PatientRepository from 'repository/PatientRepository';

const ClinicList = () => {
  const { clientStore } = useStores();
  const [target, setTarget] = useState<Element | null>(null);
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const isMounted = useRef(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isAdditionalListLoading, setIsAdditionalListLoading] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | null | undefined>(undefined);
  const [clinicList, setClinicList] = useState<any[]>([]);
  const [targetClinicId, setTargetClinicId] = useState<string>();
  const [clinicOption, setClinicOption] = useState([]);

  const [isOpenCreateClinicModal, setIsOpenCreateClinicModal] = useState<boolean>(false);
  const [promotionType, setPromotionType] = useState<string>('SELF_REG_QR_2ND');
  const [promotionTypeFilter, setPromotionTypeFilter] = useState<string>(promotionType);
  const [isOpenDeleteClinicModal, setIsOpenDeleteClinicModal] = useState<any>({});

  const handleSearch = async ({
    initLoadingFlag = false,
    resetParamFlag = false,
    nextToken,
    promotionType,
  }: {
    promotionType: string;
    initLoadingFlag?: boolean;
    resetParamFlag?: boolean;
    nextToken?: string;
  }) => {
    try {
      if (initLoadingFlag) {
        await setIsListLoading(true);
      } else {
        await setIsAdditionalListLoading(true);
      }

      if (resetParamFlag) {
        setNextToken(undefined);
        setIsListLoading(true);
      }

      let res = {
        items: [],
        nextToken: undefined,
      };

      res = await ClinicRepository.listClinicsForPromotionBypromotionType({
        promotionType,
        nextToken: resetParamFlag ? undefined : nextToken,
      });
      // 환자 정보 파싱
      const parsedClinicData = await Promise.all(
        res.items.map(async (clinic) => {
          const patients = await PatientRepository.allPatientsByClinic({
            clinicID: clinic.clinicID,
          });
          const registrationPathObj = {};
          patients.items?.forEach((patient) => {
            const registrationPath = patient.registrationPath || '0';
            registrationPathObj[registrationPath] = registrationPathObj[registrationPath]
              ? registrationPathObj[registrationPath] + 1
              : 1;
          });

          return { ...clinic, registrationPathObj };
        }),
      );

      await setClinicList(
        resetParamFlag || nextToken === undefined ? parsedClinicData : [...clinicList, ...parsedClinicData],
      );

      if (resetParamFlag) {
        setIsListLoading(false);
      }

      setNextToken(res?.nextToken);
    } catch (e) {
      message.error(`한의원 목록을 가져오는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 8000);
    } finally {
      await setIsListLoading(false);
      await setIsAdditionalListLoading(false);
    }
  };

  const initFunc = useCallback(async () => {
    try {
      const subs = {
        local: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        develop: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        staging: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        production: ['borooboroo625@gmail.com'],
      };

      if (!subs[currentEnv]?.includes(clientStore.metaData?.userRole?.email)) {
        navigate('/patient');
      }
      const clinics = await ClinicRepository.listClinicsByCreatedAt({
        params: { isDeleted: false },
      });
      setClinicOption(clinics.items);

      await handleSearch({
        resetParamFlag: true,
        initLoadingFlag: !isMounted.current,
        promotionType: promotionTypeFilter,
      });
      if (!isMounted.current) {
        isMounted.current = true;
      }
    } catch (e) {
      message.error(`한의원 목록을 가져오는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 2500);
    }
  }, []);

  useEffect(() => {
    initFunc();
  }, []);

  useEffect(() => {
    const ob = new IntersectionObserver(
      (e) => {
        if (e?.[0]?.isIntersecting) {
          handleSearch({ nextToken, promotionType: promotionTypeFilter });
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );
    target && ob.observe(target);

    return () => {
      ob.disconnect();
    };
  }, [target]);

  const promotionOptions = useMemo(() => {
    return [
      {
        label: 'QR 1차',
        // @ts-ignore
        value: 'SELF_REG_QR_1ST',
      },
      {
        label: 'QR 2차(강서구 MOU)',
        // @ts-ignore
        value: 'SELF_REG_QR_2ND',
      },
    ];
  }, []);

  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1.4rem',
        paddingBottom: '1rem',
        height: '100%',
        flex: '1 1 0',
      }}
    >
      {isGlobalLoading && <Loading mode="global" />}
      {!_.isEmpty(isOpenDeleteClinicModal) && (
        <Modal
          isOpen
          okMsg="삭제"
          okFunction={async () => {
            await setIsGlobalLoading(true);
            await ClinicRepository.removeClinicForPromotion(isOpenDeleteClinicModal.id);
            await handleSearch({ resetParamFlag: true, nextToken, promotionType: promotionTypeFilter });
            await setIsOpenDeleteClinicModal({});
            await setIsGlobalLoading(false);
            message.success('삭제가 완료되었습니다.', 2500);
          }}
          cancelMsg="취소"
          cancelFunction={() => setIsOpenDeleteClinicModal({})}
          contentMsg={`${isOpenDeleteClinicModal.clinicName} 한의원을 목록에서 삭제하시겠습니까?`}
        />
      )}
      {isOpenCreateClinicModal && (
        <ModalType2
          title="QR 프로모션 진행 한의원 등록"
          isOpen={isOpenCreateClinicModal}
          okMsg="등록"
          okFunction={async () => {
            if (!targetClinicId) return message.error('한의원을 선택해주세요.', 2500);
            try {
              const res = await ClinicRepository.getClinicsForPromotion(`${targetClinicId}_${promotionType}`);
              if (res?.getClinicsForPromotion) {
                return message.error('이미 등록된 한의원입니다.', 2500);
              }
              await ClinicRepository.createClinicForPromotion({
                id: `${targetClinicId}_${promotionType}`,
                clinicID: targetClinicId,
                promotionType,
              });
            } catch (e) {
              console.log(e);
              message.error(`등록 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 8000);
            }

            await setIsGlobalLoading(true);
            setIsOpenCreateClinicModal(false);
            setTargetClinicId(undefined);
            await setIsGlobalLoading(false);
            handleSearch({ resetParamFlag: true, nextToken, promotionType: promotionTypeFilter });
            message.success('등록이 완료되었습니다.', 2500);
          }}
          cancelMsg="닫기"
          cancelFunction={() => {
            setTargetClinicId(undefined);
            setIsOpenCreateClinicModal(false);
          }}
          contentHeight="350px"
          width={30}
          content={
            <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
              <Select
                isSearchable
                placeholder="QR 프로모션 유형"
                options={promotionOptions}
                onChange={(value) => {
                  setPromotionType(value['value']);
                }}
              />
              <Select
                isSearchable
                placeholder="한의원 선택"
                onChange={(value) => {
                  setTargetClinicId(value['value'].toString());
                }}
                // @ts-ignore
                options={[
                  ...clinicOption.map((clinic) => ({
                    label: `${clinic.clinicName} ${clinic.clinicEmail}`,
                    value: clinic.id as string,
                  })),
                ]}
              />
            </Row>
          }
        />
      )}
      <Row style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '0.4rem' }}>
        <Select
          isSearchable
          placeholder="QR 프로모션 유형 필터"
          defaultValue={
            promotionOptions.find((option) => option.value === promotionTypeFilter) ?? promotionOptions[0]
          }
          styles={{
            // @ts-ignore
            control: (styles) => ({ ...styles, width: '15rem' }),
          }}
          options={promotionOptions}
          onChange={(value) => {
            setPromotionTypeFilter(value['value']);
            handleSearch({ resetParamFlag: true, nextToken, promotionType: value['value'] });
          }}
        />
        <button
          style={{
            border: '1px solid #c4c4c4',
            borderRadius: '3px',
            background: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.45rem 1rem',
            flexShrink: 0,
          }}
          onClick={() => setIsOpenCreateClinicModal(true)}
        >
          <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
            {'QR 프로모션 진행 한의원 등록'}
          </TypographyText>
        </button>
      </Row>
      <table
        className={`list-page-common-table qr-clinic-list-table  ${isListLoading && 'height-100 flex-1'}`}
      >
        <thead>
          <tr>
            <th>
              <span>한의원 명</span>
            </th>
            <th>
              <span>총 환자 수</span>
            </th>
            <th>
              <span>어드민</span>
            </th>
            <th>
              <span>A2</span>
            </th>
            {promotionTypeFilter === 'SELF_REG_QR_1ST' ? (
              <>
                <th>
                  <span>A4(A2 축소)</span>
                </th>
                <th>
                  <span>A4(A2와 다른)</span>
                </th>
              </>
            ) : (
              <th>
                <span>A4</span>
              </th>
            )}
            <th>
              <span>B6</span>
            </th>
            <th>
              <span>문자</span>
            </th>
            <th>
              <span>X배너(옥내)</span>
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isListLoading ? (
            <tr>
              <td colSpan={8}>
                <Row
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Row style={{ display: 'flex', marginTop: '-4rem', marginLeft: '-1rem' }}>
                    <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
                  </Row>
                </Row>
              </td>
            </tr>
          ) : clinicList?.length > 0 ? (
            clinicList.map((c, i) => (
              <tr key={c.id} ref={clinicList.length - 1 === i ? setTarget : null}>
                <td>
                  <span>{c.clinic.clinicName}</span>
                </td>
                <td>
                  <span>{_.values(c.registrationPathObj)?.reduce((a, c) => a + c, 0)}</span>
                </td>
                <td>
                  <span>{c.registrationPathObj?.['0'] || 0}</span>
                </td>
                <td>
                  <span>{c.registrationPathObj?.['1'] || 0}</span>
                </td>
                <td>
                  <span>{c.registrationPathObj?.['2'] || 0}</span>
                </td>

                {promotionTypeFilter === 'SELF_REG_QR_1ST' ? (
                  <td>
                    <span>{c.registrationPathObj?.['3'] || 0}</span>
                  </td>
                ) : (
                  <></>
                )}
                <td>
                  <span>{c.registrationPathObj?.['4'] || 0}</span>
                </td>
                <td>
                  <span>{c.registrationPathObj?.['5'] || 0}</span>
                </td>
                <td>
                  <span>{c.registrationPathObj?.['6'] || 0}</span>
                </td>
                <td>
                  <Row
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button
                      style={{
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                        backgroundColor: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '4rem',
                        height: '2rem',
                      }}
                      onClick={() => {
                        // 새 창으로 열기
                        window.open(`/dashboard/${c.clinic.id}`, '_blank');
                      }}
                    >
                      <TypographyText fontWeight={700} fontSize="0.8rem">
                        {'상세'}
                      </TypographyText>
                    </button>
                  </Row>
                </td>
                <td>
                  {/* 삭제 버튼 */}
                  <Row
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button
                      style={{
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                        backgroundColor: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '4rem',
                        height: '2rem',
                      }}
                      onClick={() =>
                        setIsOpenDeleteClinicModal({
                          clinicName: c.clinic.clinicName,
                          id: c.id,
                        })
                      }
                    >
                      <TypographyText fontWeight={700} fontSize="0.8rem">
                        {'삭제'}
                      </TypographyText>
                    </button>
                  </Row>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <span
                  style={{
                    color: '#afafaf',
                  }}
                >
                  한의원 데이터가 없습니다.
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!isListLoading && isAdditionalListLoading && (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
        </Row>
      )}
    </Row>
  );
};

export default observer(ClinicList);
