/* eslint-disable eqeqeq */
import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import { Row } from 'components/UI/atoms';

function Date({
  form,
  k,
  recentChangedField,
}: {
  form: any;
  k: string;
  recentChangedField: React.MutableRefObject<any>;
}): ReactElement {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const fv = form.getFieldValue(k);

  useEffect(() => {
    if (fv == undefined) {
      form.setFieldsValue({ [k]: moment().format('YYYY-MM-DD') });
      forceUpdate();
    }
  });

  return (
    <Row yjTypeRow="survey-answer">
      <input
        autoComplete="off"
        style={{
          outline: 'none',
          width: `9.2rem`,
          height: '3rem',
          border: '1px solid #afafaf',
          borderRadius: '3px',
          textIndent: '0.35rem',
          paddingRight: '0.7rem',
        }}
        value={fv ?? moment().format('YYYY-MM-DD')}
        type={'date'}
        onChange={(e) => {
          form.setFieldsValue({ [k]: e.target.value }); // set current value
          recentChangedField.current = k;
          forceUpdate();
        }}
      />
    </Row>
  );
}

export default Date;
