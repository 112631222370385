import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

export class TreatmentPageStore {
  rootStore: RootStore;
  page: number = 0;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setPage(value: number) {
    this.page = value;
  }
}
