/* eslint-disable react-hooks/exhaustive-deps */
import message from 'antd-message';
import jwt_decode from 'jwt-decode';
import { API, Auth } from 'aws-amplify';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SignUpParams } from '@aws-amplify/auth';
import PhoneInput from 'react-phone-number-input/input';

import LandingLayout from '../../../templates/LandingLayout';
import { Button } from '../../../UI/atoms/inputs/Button';
import { Term } from 'components/UI/molecules';
import { css } from '@emotion/css';
import { Row, Col, TypographyText, Divider, Input } from 'components/UI/atoms';
import { Loading } from 'components/UI/molecules';
import { passwordValidationCheck, phoneValidationCheck } from 'utils/util';

const SignUpStepConfirmPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [all, setAll] = useState(false);
  const [service, setService] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isAble, setIsAble] = useState(false);
  const [isExpToken, setIsExpToken] = useState(false);
  const [userName, setUserName] = useState<string>();
  const [userNameReadonlyFlag, setUserNameReadonlyFlag] = useState(false);
  const [userPhoneReadonlyFlag, setUserPhoneReadonlyFlag] = useState(false);
  const [isExistingOtherClinicMember, setIsExistingOtherClinicMember] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [userExistError, setUserExistError] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams();

  const passwordConfirmValidationCheck = useCallback(
    (password: string, passwordConfirm: string) => password === passwordConfirm,
    [],
  );

  const confirmSignUp = useCallback(
    async ({
      userSub,
      token,
      name,
      terms,
      phone,
    }: {
      userSub: string;
      token: string;
      name: string;
      terms: any;
      phone: string;
    }) => {
      try {
        await API.post('yejinadminrestapi', '/inviteConfirm', {
          headers: {
            'Content-Type': 'application/json',
          },
          response: true,
          body: {
            userSub,
            token,
            name,
            terms,
            phone: phone.replace('+82', '0'),
          },
        });
      } catch (e) {
        message.error(`가입과정에서 문제가 발생했습니다.`, 2500);
        console.log(e);
        setIsLoading(false);
        setError(true);
      }
    },
    [],
  );

  const confirmFlag = isExistingUser
    ? isAble
    : isAble &&
      passwordValidationCheck(password) &&
      passwordConfirmValidationCheck(password, confirmPassword);

  const loadInit = useCallback(async () => {
    await setIsGlobalLoading(true);
    Auth.signOut();
    sessionStorage.clear();
    const now = Math.floor(new Date().getTime() / 1000);
    const payload: any = jwt_decode(token);
    setUserId(payload['userID']);
    setIsExpToken(payload['exp'] < now);
    await API.post('yejinadminrestapi', '/inviteJwtVerify', {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
      body: {
        token,
      },
    }).then((res) => {
      setEmail(res.data.email);
      setClinicName(res.data.clinicName);
      if (res.data.userName) {
        setUserNameReadonlyFlag(true);
        setUserName(res.data.userName);
      }
      if (res.data.phone) {
        setUserPhoneReadonlyFlag(true);
        setPhone(res.data.phone);
      }
      setIsExistingUser(res.data.isExistingUser); // 이미 가입되어 있는 유저인지
      setIsExistingOtherClinicMember(res.data.isExistingOtherClinicMember); // 한의원에 소속되어 있는 유저인지
    });
    setIsGlobalLoading(false);
  }, []);

  useEffect(() => {
    try {
      loadInit();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const phoneValidation = useMemo(() => {
    const p = phone?.replace('+82', '0');
    return p?.length > 0 && phoneValidationCheck(p);
  }, [phone]);

  useEffect(() => {
    setAll(service && privacy && marketing);

    if (phoneValidation && !!email && !!userName && service && privacy) {
      setIsAble(true);
    } else {
      setIsAble(false);
    }
  }, [phoneValidation, email, marketing, userName, privacy, service, token]);

  const stepSignUpHandler = async () => {
    try {
      setIsLoading(true);
      const terms = [all, service, privacy, marketing];
      let userSub = userId;
      if (!isExistingUser) {
        const res = await Auth.signUp({
          username: email,
          password: confirmPassword,
          // autoSignIn: {
          //   enabled: true,
          // },
        } as SignUpParams);
        userSub = res.userSub;
      }
      await confirmSignUp({ userSub, token, name: userName, terms, phone });
      message.success(`초대 승인이 완료되었습니다. 로그인해 주세요.`, 2500);
      navigate('/', {
        state: {
          tutorialFlag: true,
        },
      });
    } catch (error) {
      message.error(`가입과정에서 문제가 발생했습니다.`, 2500);
      setIsLoading(false);
      if (error.code === 'UsernameExistsException') {
        setUserExistError(true);
      }
    }
  };

  let content = <></>;
  if (isExpToken) {
    // 토큰이 만료된 경우
    content = (
      <Row
        style={{
          marginTop: '10rem',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <TypographyText
          fontSize="1.6rem"
          lineHeight="2.1rem"
          letterSpacing="-0.01em"
          fontWeight={400}
          color={'#1d1c1d'}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {`회원가입 기간이 만료되었습니다.
관리자에게 다시 초대 링크를 받아 가입을 진행해주세요.`}
        </TypographyText>
      </Row>
    );
  } else if (isGlobalLoading) {
    content = <Loading mode="global" />;
  } else {
    // 회원이면서 초대 받은 경우
    content = (
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '1.5rem',
        }}
      >
        {isLoading && <Loading mode="global" />}
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Row style={{ display: 'flex', marginTop: '2rem' }}>
            <TypographyText
              fontSize="1.6rem"
              lineHeight="2.1rem"
              letterSpacing="-0.01em"
              fontWeight={400}
              color={'#1d1c1d'}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {
                // 존재하는 계정인 경우
                isExistingUser
                  ? `한의원 구성원으로 초대되었습니다.
초대를 수락하시겠습니까?`
                  : `반갑습니다.
해당 정보 입력 후 회원가입을 완료해주세요.`
              }
            </TypographyText>
          </Row>
          <Row style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', rowGap: '0.8rem' }}>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col style={{ width: '6.25rem' }}>
                <TypographyText
                  style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 700, color: '#000000' }}
                >
                  한의원 이름
                </TypographyText>
              </Col>
              <TypographyText style={{ fontSize: '1rem', lineHeight: '1rem', color: '#1d1c1d' }}>
                {clinicName}
              </TypographyText>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col style={{ width: '6.25rem' }}>
                <TypographyText
                  style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 700, color: '#000000' }}
                >
                  이메일
                </TypographyText>
              </Col>
              <TypographyText style={{ fontSize: '1rem', lineHeight: '1rem', color: '#1d1c1d' }}>
                {email}
              </TypographyText>
            </Row>
            {userNameReadonlyFlag && ( // 유저가 존재하고 이름 정보가 있어 입력받지 않는 경우
              <>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                  <Col style={{ width: '6.25rem' }}>
                    <TypographyText
                      style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 700, color: '#000000' }}
                    >
                      구성원 이름
                    </TypographyText>
                  </Col>
                  <TypographyText style={{ fontSize: '1rem', lineHeight: '1rem', color: '#1d1c1d' }}>
                    {userName}
                  </TypographyText>
                </Row>
              </>
            )}
          </Row>
          {(!userNameReadonlyFlag || !userPhoneReadonlyFlag) && ( // 이름 또는 전화번호 정보가 없는 경우
            <>
              <Row style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
                <TypographyText
                  style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 700, color: '#000000' }}
                >
                  개인 정보 (필수)
                </TypographyText>
                {!userNameReadonlyFlag && (
                  <Input
                    style={{
                      height: '3.2rem',
                      width: '29.2rem',
                      border: '1px solid #afafaf',
                      borderRadius: '3px',
                      fontSize: '1rem',
                      lineHeight: '1rem',
                      outline: 'none',
                    }}
                    value={userName}
                    placeholder={'이름'}
                    onChange={(e) => setUserName(e.target.value)}
                    autoComplete="off"
                  />
                )}
                {!userPhoneReadonlyFlag && (
                  <>
                    <PhoneInput
                      style={{
                        height: '3.2rem',
                        width: '100%',
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                        padding: '0.35rem 0.6rem 0.35rem 0.6rem',
                        fontSize: '1rem',
                        outline: 'none',
                      }}
                      className={css`
                        &::placeholder {
                          color: #afafaf;
                        }
                      `}
                      country={'KR'}
                      value={phone}
                      placeholder="전화번호"
                      onChange={(e) => setPhone(e)}
                      autoComplete="off"
                    />
                    {phone?.length > 0 && !phoneValidation && (
                      <Row flex style={{ marginTop: '-0.2rem' }}>
                        <TypographyText yjTypeTypographyText="survey-validation">
                          {'전화번호 형식이 맞는지 확인해주세요.'}
                        </TypographyText>
                      </Row>
                    )}
                  </>
                )}
              </Row>
            </>
          )}
          {!isExistingUser && ( // 이미 가입된 유저가 아닌 경우에는 비밀번호를 입력받음
            <>
              <Row style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', rowGap: 0 }}>
                <TypographyText
                  style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 700, color: '#000000' }}
                >
                  비밀번호 설정
                </TypographyText>
                <Divider marginRem={0.35} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                <Input
                  style={{
                    height: '3.2rem',
                    width: '29.2rem',
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    outline: 'none',
                  }}
                  type="password"
                  value={password}
                  placeholder={'비밀번호 (영어 소문자, 숫자 포함 10자리 이상)'}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
                {!!password?.length && !passwordValidationCheck(password) && (
                  <>
                    <Divider marginRem={0.15} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                    <TypographyText yjTypeTypographyText="survey-validation">
                      비밀번호는 영어 소문자, 숫자 포함 10자리 이상의 문자로 설정해주세요.
                    </TypographyText>
                  </>
                )}
                <Divider marginRem={0.3} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                <Input
                  style={{
                    height: '3.2rem',
                    width: '29.2rem',
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    outline: 'none',
                  }}
                  type="password"
                  value={confirmPassword}
                  placeholder={'비밀번호 확인'}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="new-password"
                />
                {!!confirmPassword?.length && !passwordConfirmValidationCheck(password, confirmPassword) && (
                  <>
                    <Divider marginRem={0.15} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                    <TypographyText yjTypeTypographyText="survey-validation">
                      비밀번호가 일치 하지 않습니다.
                    </TypographyText>
                  </>
                )}
                {error && (
                  <>
                    <Divider marginRem={0.15} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                    <TypographyText yjTypeTypographyText="survey-validation">
                      회원가입에 실패했습니다.
                    </TypographyText>
                  </>
                )}
                {userExistError && (
                  <>
                    <Divider marginRem={0.15} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                    <TypographyText yjTypeTypographyText="survey-validation">
                      이미 가입된 계정입니다. 회원 가입을 하신 적이 없다면 고객센터로 문의해 주세요.
                    </TypographyText>
                  </>
                )}
              </Row>
            </>
          )}
          {isExistingUser && <Divider marginRem={0.6} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />}
          <div style={{ marginTop: '1.8rem' }} />
          <Term
            isCheckedServiceTerm={service}
            isCheckedPrivacyTerm={privacy}
            isCheckedMarketingTerm={marketing}
            onChangeServiceTerm={(value) => setService(value)}
            onChangePrivacyTerm={(value) => setPrivacy(value)}
            onChangeMarketingTerm={(value) => setMarketing(value)}
          />
          <div style={{ marginTop: '1.5rem' }} />
          <Button
            height={'3.2rem'}
            border={confirmFlag ? '1px solid #346AFF' : '1px solid #c4c4c4'}
            backgroundColor={confirmFlag ? '#346AFF' : 'gray'}
            isDisabled={!confirmFlag}
            color={'white'}
            fontSize={'1.2rem'}
            onClick={stepSignUpHandler}
          >
            {
              // 존재하는 계정이면서 한의원에 소속됐는지 판별
              isExistingUser && isExistingOtherClinicMember ? `한의원 소속 변경하기` : `초대 승인하기`
            }
          </Button>
        </Row>
      </Row>
    );
  }
  return <LandingLayout content={content} />;
};

export default SignUpStepConfirmPage;
