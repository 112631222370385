import { Auth } from 'aws-amplify';
import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

class UserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  async signIn(email: string, password: string) {
    const user = await Auth.signIn(email, password).then((response) => {
      sessionStorage.setItem('sub', response.attributes.sub);
    });
    return user;
  }
}

export default UserStore;
