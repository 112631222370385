import _ from 'lodash';
import { observer } from 'mobx-react';
import message from 'antd-message';

import { Row, Col, TypographyText, PlusButton } from 'components/UI/atoms';

const HerbTd = ({
  herbSearchKeyword: kw,
  processedHerbData: data,
  selectedProcessingCodes,
  setSelectedProcessingCodes,
  prescribedHerbs,
  setPrescribedHerbs,
  setPrescribedHerbTableScrollTrigger,
}) => {
  const checked = selectedProcessingCodes.includes(data.processingCode);
  let knForSearchIncludingherbSearchKeyword;
  if (kw && !data.koreanName.includes(kw)) {
    knForSearchIncludingherbSearchKeyword = data.knForSearch.split('||').find((k) => k.includes(kw));
  }
  return (
    <Row
      key={data.processingCode}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Col
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          columnGap: '0.75rem',
          flex: 1,
          paddingLeft: '0.25rem',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!selectedProcessingCodes.includes(data.processingCode)) {
            setSelectedProcessingCodes([...selectedProcessingCodes, data.processingCode]);
          } else {
            setSelectedProcessingCodes([...selectedProcessingCodes.filter((e) => e !== data.processingCode)]);
          }
        }}
      >
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
          }}
        >
          <img
            src={
              checked
                ? `${process.env.PUBLIC_URL}/img/recommanded-herb-fill-icon.webp`
                : `${process.env.PUBLIC_URL}/img/recommanded-herb-empty-icon.webp`
            }
            style={{ width: '0.75rem' }}
            alt={'recommand-icon'}
          />
        </Col>
        <TypographyText fontSize="0.9rem" lineHeight="1.2em" style={{ whiteSpace: 'nowrap' }}>{`${
          data.koreanName
        }${
          knForSearchIncludingherbSearchKeyword ? `(${knForSearchIncludingherbSearchKeyword})` : ''
        }`}</TypographyText>
      </Col>
      {data.score > 0 && (
        <button
          style={{
            width: '1.7rem',
            height: '1.2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#346AFF20',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            cursor: 'default',
            marginRight: '0.5rem',
          }}
        >
          <TypographyText
            color="#346AFF"
            fontSize="0.7rem"
            lineHeight="1.2em"
            fontWeight={700}
            whiteSpace="pre"
          >
            {_.round(data.score * 5)}
          </TypographyText>
        </button>
      )}
      <PlusButton
        onClick={() => {
          if (!prescribedHerbs[data.processingCode]) {
            setPrescribedHerbs({
              ...prescribedHerbs,
              [data.processingCode]: {
                code: data.processingCode,
                amount: 3750,
              },
            });
            setPrescribedHerbTableScrollTrigger(true);
            message.success(`<${data.koreanName}> 약재가 추가되었습니다.`, 2500);
          } else {
            message.error(`<${data.koreanName}> 약재는 이미 추가되어 있습니다.`, 2500);
          }
        }}
      />
    </Row>
  );
};

export default observer(HerbTd);
