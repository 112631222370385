/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Button, Table, TableBody, TableHead, TableRow, Text } from '@aws-amplify/ui-react';
import message from 'antd-message';
import { css } from '@emotion/css';
import _, { isInteger } from 'lodash';
import { observer } from 'mobx-react';

import { TableCell } from 'components/UI/atoms/table/TableCell';
import {
  Row,
  Col,
  TypographyText,
  ModalType2,
  PlusButton,
  MinusButton,
  SearchInput,
  Spinner,
} from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import CommonPrescriptionTable from 'components/UI/molecules/table/CommonPrescriptionTable';
import PR from 'repository/PrescriptionRepository';
import { treatmentModeMap, 질환_처방구분별_상한금액 } from 'constant';
import { applyComma } from 'utils/util';

import MyPrescriptionSaveModal from './MyPrescriptionSaveModal';
import MyPrescriptionListModal from './MyPrescriptionListModal';

const PrescribedHerbTable = ({
  prescribedHerbs,
  setPrescribedHerbs,
  selectedPrescriptionData,
  needConfirmationHerbIds,
  setNeedConfirmationHerbIds,
  setSelectedPrescriptionData,
  prescriptionCount,
  setPrescriptionCount,
  treatmentMode,
  prescribedHerbTableScrollTrigger,
  setPrescribedHerbTableScrollTrigger,
  setIsOpenAddHerbMethodModal,
  setIsOpenPrescriptionInfoModal,
  setNeedConfirmationHerbsTemp,
  setDontNeedConfirmationHerbsTemp,
  isOpenPrescriptionHistoryModal,
  setIsOpenPrescriptionHistoryModal,
  isOpenMyPrescriptionListModal,
  setIsOpenMyPrescriptionListModal,
  selectedMyPrescriptionData,
  setSelectedMyPrescriptionData,
}) => {
  const { prescriptionStore, treatmentStore, clientStore } = useStores();

  const [isOpenMyPrescriptionSaveModal, setIsOpenMyPrescriptionSaveModal] = useState(false);
  const [myPrescriptionList, setMyPrescriptionList] = useState([]);
  const [isLoadingMyPrescriptionList, setIsLoadingMyPrescriptionList] = useState(false);
  const [refreshGetMyPrescriptionListTrigger, setRefreshGetMyPrescriptionListTrigger] = useState(true);
  const [isOpenProcessOptionDropdown, setIsOpenProcessOptionDropdown] = useState<any>({});
  const scrollContainerRef = useRef(null);

  const insuranceModeFlag = useMemo(
    () => treatmentMode !== 'GENERAL' && treatmentModeMap.has(treatmentMode),
    [treatmentMode],
  );

  const searchHerb = useCallback(({ value, arrForCheck, yejinHerbMap }) => {
    const valueArr = (value?.split(' ') ?? []).filter((e: string) => e?.length);
    if (!_.isEmpty(valueArr)) {
      // 유효한 검색어가 있으면 동작
      const searchSuccessHerbs = []; // 검색결과가 있는 약재
      const alreadyAddedHerbs = []; // 검색 결과가 있고, 처방 목록에 이미 추가된 약재
      const notAddedHerbs = []; // 검색 결과가 있고, 처방 목록에 추가되지 않은 약재
      const searchFailedHerbs = []; // 검색결과가 없는 약재
      let messageString = ''; // 메시지 출력용 문자열
      let messageSuccessFlag = false; // 메시지 출력용 플래그

      let lastParsedValue: {
        processingCode?: string;
        koreanName?: string;
        amount?: number;
      } = {};
      const parsedValueArr = [];
      _.forEach(valueArr, (e: string) => {
        const stringFlag = _.isNaN(_.parseInt(e)); // 숫자가 아닌 문자열인지 확인
        if (_.isEmpty(lastParsedValue)) {
          if (stringFlag) {
            // 문자열에 숫자 포함 여부를 정규식으로 검사
            const regex = /\d/g;
            const match = regex.exec(e);
            if (match) {
              // 숫자가 포함되어 있다면 추출한다.
              const amount = _.parseInt(e.slice(match.index)) * 1000;
              const koreanName = e.slice(0, match.index);
              lastParsedValue = {
                processingCode: undefined,
                koreanName,
                amount,
              };
            } else {
              lastParsedValue = {
                processingCode: undefined,
                koreanName: e,
                amount: undefined,
              };
            }
          }
        } else {
          if (stringFlag) {
            parsedValueArr.push(lastParsedValue);
            // 문자열에 숫자 포함 여부를 정규식으로 검사
            const regex = /\d/g;
            const match = regex.exec(e);
            if (match) {
              // 숫자가 포함되어 있다면 추출한다.
              const amount = _.parseInt(e.slice(match.index)) * 1000;
              const koreanName = e.slice(0, match.index);
              lastParsedValue = {
                processingCode: undefined,
                koreanName,
                amount,
              };
            } else {
              lastParsedValue = {
                processingCode: undefined,
                koreanName: e,
                amount: undefined,
              };
            }
          } else if (lastParsedValue?.amount === undefined) {
            lastParsedValue = {
              ...lastParsedValue,
              amount: _.parseInt(e) * 1000,
            };
          }
        }
      });

      if (!_.isEmpty(lastParsedValue)) {
        parsedValueArr.push(lastParsedValue);
        lastParsedValue = {};
      }

      parsedValueArr.forEach((e) => {
        const herb = yejinHerbMap.get(e.koreanName);
        if (_.isEmpty(herb)) {
          searchFailedHerbs.push(e?.koreanName); // 검색결과가 없으면 추가
        } else {
          // 검색결과가 있으면 추가
          searchSuccessHerbs.push({
            ...e,
            ...herb,
          });
        }
      });
      if (_.isEmpty(searchSuccessHerbs)) {
        // 검색결과가 있는 약재가 없으면 에러메시지 출력
        messageString = `${searchFailedHerbs
          .map((herbName) => `<${herbName}>`)
          .join(' ')} 약재가 없어 추가할 수 없습니다.`;
      } else {
        // 검색결과가 있는 약재가 있으면 추가
        searchSuccessHerbs.forEach((herb) => {
          if (arrForCheck[herb.processingCode]?.code) {
            // 처방 목록에 이미 추가된 약재면 추가하지 않음
            alreadyAddedHerbs.push(herb.koreanName);
          } else {
            // 처방 목록에 추가되지 않은 약재면 추가
            notAddedHerbs.push(herb);
          }
        });

        if (!_.isEmpty(notAddedHerbs)) {
          messageSuccessFlag = true;
          messageString += `${_.uniq(notAddedHerbs.map((herb) => `<${herb.koreanName}>`)).join(
            ', ',
          )} 약재가 추가되었습니다.`;
          const notAddedHerbsObj = {};
          notAddedHerbs.forEach((herb) => {
            notAddedHerbsObj[herb.processingCode] = {
              code: herb.processingCode,
              amount: herb.amount ?? 3750,
            };
          });
          setPrescribedHerbs({
            ...arrForCheck,
            ...notAddedHerbsObj,
          });
          setPrescribedHerbTableScrollTrigger(true);
        }
        if (!_.isEmpty(alreadyAddedHerbs)) {
          if (messageString.length) {
            // 이미 다른 약재가 추가되었으면 줄바꿈
            messageString += `
    `;
          }
          messageString += `${_.uniq(alreadyAddedHerbs)
            .map((herbName) => `<${herbName}>`)
            .join(', ')} 약재는 이미 추가되어 있습니다.`;
        }
        if (!_.isEmpty(searchFailedHerbs)) {
          if (messageString.length) {
            // 이미 다른 약재가 추가되었으면 줄바꿈
            messageString += `
    `;
          }
          messageString += `${_.uniq(searchFailedHerbs)
            .map((herbName) => `<${herbName}>`)
            .join(', ')} 약재가 없어 추가할 수 없습니다.`;
        }
      }
      if (messageSuccessFlag) {
        message.success(messageString, 7500);
      } else {
        message.error(messageString, 7500);
      }
    }
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current && prescribedHerbTableScrollTrigger) {
      // element의 스크롤 높이 계산
      const scrollHeight = scrollContainerRef.current.scrollHeight;

      // element의 스크롤을 맨 밑으로 이동
      scrollContainerRef.current.scrollTop = scrollHeight;
      setPrescribedHerbTableScrollTrigger(false);
    }
  }, [prescribedHerbTableScrollTrigger]);

  const getMyPrescriptionList = useCallback(async (id: string) => {
    setIsLoadingMyPrescriptionList(true);
    const res = await PR.listCustomHerbalFormulas(id);
    setMyPrescriptionList(res?.items || []);
    setIsLoadingMyPrescriptionList(false);
    setRefreshGetMyPrescriptionListTrigger(false);
    return res;
  }, []);

  useEffect(() => {
    if (refreshGetMyPrescriptionListTrigger) {
      getMyPrescriptionList(clientStore.metaData?.user.id);
    }
  }, [refreshGetMyPrescriptionListTrigger]);

  const priceCap = useMemo(
    () =>
      (질환_처방구분별_상한금액.get(`${treatmentMode}${selectedPrescriptionData?.InsrPrscrType}`) / 20) *
      prescriptionCount,
    [selectedPrescriptionData?.id, prescriptionCount, treatmentMode],
  );

  return (
    <>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          height: '4.2rem',
          paddingBottom: '0.75rem',
          columnGap: '0.25rem',
        }}
      >
        <Col style={{ display: 'flex', columnGap: '0.75rem', alignItems: 'flex-end' }}>
          <TypographyText
            fontSize="1.2rem"
            lineHeight="1.2em"
            fontWeight={700}
            style={{ whiteSpace: 'nowrap' }}
          >
            {`처방${
              _.isEmpty(selectedMyPrescriptionData)
                ? _.isEmpty(selectedPrescriptionData)
                  ? ''
                  : ` | ${selectedPrescriptionData.kn}`
                : ` | ${selectedMyPrescriptionData.kn}`
            }`}
          </TypographyText>
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.3rem',
            marginBottom: '-0.25rem',
          }}
        >
          <Col
            className="treatment-detail-button"
            style={{
              paddingLeft: '0.75rem',
              paddingRight: '0.75rem',
              width: '4.55rem',
              height: '2rem',
              cursor: 'pointer',
              borderRadius: '3px',
              background: '#EEEFF3',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              columnGap: '0.4rem',
            }}
            onClick={() =>
              setIsOpenMyPrescriptionListModal({
                isOpen: true,
                hiddenFlag: false,
              })
            }
          >
            {isLoadingMyPrescriptionList ? (
              <Spinner spinnerDivWidth="w-15" />
            ) : (
              <TypographyText fontSize="0.8rem" fontWeight={700} lineHeight="0.8rem" whiteSpace="pre">
                나의 처방
              </TypographyText>
            )}
          </Col>
          <Col
            className="treatment-detail-button"
            style={{
              paddingLeft: '0.75rem',
              paddingRight: '0.75rem',
              height: '2rem',
              cursor: 'pointer',
              borderRadius: '3px',
              background: '#EEEFF3',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              columnGap: '0.4rem',
            }}
            onClick={() =>
              setIsOpenPrescriptionHistoryModal({
                isOpen: true,
                hiddenFlag: false,
              })
            }
          >
            <TypographyText fontSize="0.8rem" fontWeight={700} lineHeight="0.8rem" whiteSpace="pre">
              처방 기록
            </TypographyText>
          </Col>
        </Col>
      </Row>
      <Table
        id="prescription-table"
        style={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 0,
          borderTop: '1px solid #E5E5E5',
        }}
      >
        <TableHead
          style={{
            position: 'sticky',
            display: 'table',
            width: '100%',
            top: 0,
            zIndex: 'auto',
          }}
        >
          <TableRow style={{ background: '#f9f9f9', width: '100%', display: 'flex' }}>
            <TableCell
              as="th"
              style={{
                display: 'flex',
                paddingTop: 0,
                paddingBottom: 0,
                flex: 1,
                justifyContent: 'start',
                alignItems: 'center',
                paddingLeft: '0.75rem',
                height: '1.5rem',
              }}
            >
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TypographyText fontWeight={700} fontSize="0.8rem" lineHeight="1.2em" whiteSpace="pre">
                  약재명
                </TypographyText>
              </Row>
            </TableCell>
            <TableCell
              as="th"
              textAlign={'center'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: '0.25rem',
                height: '1.5rem',
                width: '5.5rem',
              }}
            >
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TypographyText fontWeight={700} fontSize="0.8rem" lineHeight="1.2em" whiteSpace="pre">
                  1첩용량(g)
                </TypographyText>
              </Row>
            </TableCell>
            <TableCell
              as="th"
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: '0.25rem',
                height: '1.5rem',
                width: '5.5rem',
              }}
            >
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TypographyText fontWeight={700} fontSize="0.8rem" lineHeight="1.2em" whiteSpace="pre">
                  총약재량(g)
                </TypographyText>
              </Row>
            </TableCell>
            <TableCell
              as="th"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                width: '4.5rem',
                height: '1.5rem',
              }}
            >
              <Button
                onClick={() => {
                  setPrescribedHerbs({});
                  setNeedConfirmationHerbIds([]);
                  setSelectedPrescriptionData({});
                  setSelectedMyPrescriptionData({});
                  message.success(`처방내역이 초기화되었습니다.`, 2500);
                }}
                style={{
                  border: '1px solid #afafaf',
                  borderRadius: 0,
                  background: 'white',
                  whiteSpace: 'nowrap',
                  width: '3.2rem',
                  height: '1.3rem',
                }}
              >
                <TypographyText fontWeight={700} fontSize="0.8rem">
                  초기화
                </TypographyText>
              </Button>
            </TableCell>
          </TableRow>
          <TableRow
            style={{
              background: '#ffffff',
              width: '100%',
              display: 'flex',
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              borderBottom: _.isEmpty(prescriptionStore.selectedHerbs) ? 'none' : '1px solid #e5e5e5',
            }}
          >
            <SearchInput
              searchFunc={(value) =>
                searchHerb({
                  value,
                  arrForCheck: prescribedHerbs,
                  yejinHerbMap: prescriptionStore.yejinHerbForSearchByName,
                })
              }
              resetSearchValueAfterSearch
              placeholder="약재명 용량 입력 후 엔터(ex. 인삼 4 당귀 5)"
              buttonText="추가"
              columnGap="0rem"
              buttonWidth="4.45rem"
            />
          </TableRow>
        </TableHead>
        <TableBody
          ref={scrollContainerRef}
          id="prescription-table-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 0',
            overflowY: 'auto',
            width: '100%',
            height: '100%',
          }}
          className={css`
            tr > td {
              border-top: none !important;
              height: 2rem !important;
            }
            &::-webkit-scrollbar {
              background-color: white;
              width: 0.35rem;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #ebebeb;
              border-radius: 0;
              cursor: pointer;
            }
          `}
        >
          {Object.values(prescribedHerbs)?.map((herb: { code: string; amount: number }) => {
            const data = prescriptionStore.yejinHerbForSearchByProcessingCode.get(herb.code);
            const koreanName = data.koreanName;
            const options = prescriptionStore.yejinHerbForSearchByHerbCode.get(data.herbCode) ?? [];
            return (
              <TableRow
                key={herb.code}
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  background: needConfirmationHerbIds.includes(herb.code) ? '#f5f5f5' : 'white',
                }}
              >
                <TableCell
                  textAlign={'center'}
                  style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    padding: 0,
                    cursor: options.length > 1 ? 'pointer' : 'default',
                  }}
                >
                  <button
                    style={{
                      position: 'relative',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0.4rem 0.5rem 0.4rem 0.75rem',
                      textAlign: 'left',
                    }}
                    onClick={() => {
                      if (options.length > 1) {
                        if (isOpenProcessOptionDropdown.id === herb.code) {
                          setIsOpenProcessOptionDropdown({});
                        } else {
                          setIsOpenProcessOptionDropdown({
                            id: herb.code,
                            options,
                          });
                        }
                      }
                    }}
                    onBlur={() => {
                      if (isOpenProcessOptionDropdown.id === herb.code) {
                        setIsOpenProcessOptionDropdown({});
                      }
                    }}
                  >
                    {options.length > 1 ? (
                      <>
                        <TypographyText
                          fontSize="0.9rem"
                          lineHeight="1.2em"
                          letterSpacing="0.05em"
                          whiteSpace="pre-wrap"
                          wordBreak="keep-all"
                        >
                          {koreanName}
                        </TypographyText>
                        <img
                          src={`${process.env.PUBLIC_URL}/img/down-arrow.webp`}
                          style={{ width: '0.45rem' }}
                          alt="down-arrow-icon"
                        />
                        {isOpenProcessOptionDropdown.id === herb.code && (
                          <Row
                            style={{
                              position: 'absolute',
                              border: '2px solid #AFAFAF',
                              top: '100%',
                              left: 0,
                              background: '#ffffff',
                              zIndex: 1,
                              minWidth: '100%',
                              width: '100%',
                            }}
                          >
                            <table style={{ width: '100%' }}>
                              <thead>
                                <th
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: '0.75rem',
                                    borderBottom: '1px solid #f2f2f2',
                                  }}
                                >
                                  <TypographyText fontSize="0.8rem" lineHeight="1.2em" whiteSpace="pre">
                                    {`약재명`}
                                  </TypographyText>
                                </th>
                              </thead>
                              <tbody>
                                {isOpenProcessOptionDropdown.options.map((option) => {
                                  const alreadyAddedFlag = _.values(prescribedHerbs).find(
                                    (e) => e.code === option.processingCode,
                                  );
                                  return (
                                    <tr
                                      key={option.processingCode}
                                      style={{
                                        height: '2rem',
                                        borderBottom: '1px solid #f2f2f2',
                                        cursor: alreadyAddedFlag ? 'default' : 'pointer',
                                        background: alreadyAddedFlag ? '#f5f5f5' : '#ffffff',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (!alreadyAddedFlag) {
                                          const newPrescribedHerbs = {};
                                          _.values(prescribedHerbs).forEach((e) => {
                                            if (e.code === herb.code) {
                                              newPrescribedHerbs[option.processingCode] = {
                                                code: option.processingCode,
                                                amount: e.amount,
                                              };
                                            } else {
                                              newPrescribedHerbs[e.code] = {
                                                code: e.code,
                                                amount: e.amount,
                                              };
                                            }
                                          });
                                          setPrescribedHerbs({
                                            ...newPrescribedHerbs,
                                          });
                                          if (needConfirmationHerbIds.includes(herb.code)) {
                                            setNeedConfirmationHerbIds(
                                              needConfirmationHerbIds.filter((e) => e !== herb.code),
                                            );
                                          }
                                          setIsOpenProcessOptionDropdown({});
                                        }
                                      }}
                                    >
                                      <td style={{ textAlign: 'left', paddingLeft: '0.75rem' }}>
                                        <TypographyText
                                          fontSize="0.8rem"
                                          lineHeight="1.2em"
                                          whiteSpace="pre-wrap"
                                          color="#8f8f8f"
                                        >
                                          {option.koreanName}
                                        </TypographyText>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Row>
                        )}
                      </>
                    ) : (
                      <TypographyText
                        fontSize="0.9rem"
                        lineHeight="1.2em"
                        letterSpacing="0.05em"
                        whiteSpace="pre-wrap"
                        wordBreak="keep-all"
                      >
                        {koreanName}
                      </TypographyText>
                    )}
                  </button>
                </TableCell>
                <TableCell
                  textAlign={'center'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,
                    width: '5.5rem',
                    borderLeft: '2px solid #f2f2f2',
                  }}
                >
                  <input
                    id={`${herb.code}-input`}
                    type="number"
                    className={css`
                      &::-webkit-outer-spin-button,
                      &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                      // if value is integer, add .0
                      &[type='number'] {
                        -moz-appearance: textfield;
                      }

                      outline: none;
                      width: 3.85rem;
                      height: 1.5rem;
                      padding-right: 0.5rem;
                      border: 1px solid #c4c4c4;
                      background: #f9f9f9;
                      text-align: right;
                      font-size: 0.9rem;
                      font-weight: 700;
                    `}
                    value={Number(_.round(herb.amount / 1000, 2)).toString()}
                    onChange={(e: any) => {
                      if (isNaN(+e.target.value) || e.target.value < 0.01) {
                        setPrescribedHerbs({
                          ...prescribedHerbs,
                          [herb.code]: {
                            ...prescribedHerbs[herb.code],
                            amount: 0,
                          },
                        });
                      } else {
                        setPrescribedHerbs({
                          ...prescribedHerbs,
                          [herb.code]: {
                            ...prescribedHerbs[herb.code],
                            amount: e.target.value * 1000,
                          },
                        });
                      }
                    }}
                    onBlur={() => {
                      if (needConfirmationHerbIds.includes(herb.code)) {
                        setNeedConfirmationHerbIds(needConfirmationHerbIds.filter((e) => e !== herb.code));
                      }
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    borderLeft: '2px solid #f2f2f2',
                    padding: 0,
                    paddingRight: '0.25rem',
                    width: '5.5rem',
                  }}
                >
                  <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TypographyText fontSize="0.8rem" lineHeight="1.2em">
                      {_.isInteger(_.round((herb.amount * prescriptionCount) / 1000, 2))
                        ? _.round((herb.amount * prescriptionCount) / 1000, 2) + '.0'
                        : _.round((herb.amount * prescriptionCount) / 1000, 2)}
                    </TypographyText>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '4.5rem',
                    borderLeft: '2px solid #f2f2f2',
                  }}
                >
                  <Button
                    onClick={() => {
                      const newPrescribedHerbs = { ...prescribedHerbs };
                      delete newPrescribedHerbs[herb.code];
                      setPrescribedHerbs(newPrescribedHerbs);
                      message.success(`<${koreanName}> 약재가 처방내역에서 삭제되었습니다.`, 2500);
                    }}
                    style={{
                      background: 'white',
                      whiteSpace: 'nowrap',
                      width: '3.2rem',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                    }}
                  >
                    <TypographyText
                      yjTypeTypographyText="symptom-table-head"
                      style={{
                        fontWeight: 700,
                        fontSize: '0.8rem',
                      }}
                    >
                      삭제
                    </TypographyText>
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          {_.isEmpty(prescribedHerbs) && (
            <TableRow>
              <TableCell
                textAlign={'center'}
                colSpan={4}
                style={{
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 'min(15% , 1.75rem)',
                }}
              >
                <Text fontSize={'0.9rem'} lineHeight="1.2em" color={'#AFAFAF'} letterSpacing="-0.02em">
                  약재가 없습니다.
                </Text>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: '0.5rem',
          alignItems: 'center',
          paddingBlock: '0.1rem',
          minHeight: insuranceModeFlag ? '3.1rem' : '2.75rem',
          width: '100%',
          borderTop: '2px solid #E5E5E5',
          paddingTop: 0,
          paddingLeft: '0.75rem',
          paddingRight: '1rem',
          background: '#f9f9f9',
        }}
      >
        <Col
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', rowGap: '0.25rem' }}
        >
          <Col
            style={{
              display: 'flex',
              // flexShrink: 0,
              flexWrap: 'wrap',
              justifyContent: 'start',
              alignItems: 'center',
              columnGap: '0.5rem',
              paddingTop: 0,
            }}
          >
            <Col style={{ minWidth: '5rem', display: 'flex', alignItems: 'center', columnGap: '0.1rem' }}>
              <TypographyText
                fontSize="0.8rem"
                lineHeight="1.2em"
                whiteSpace="pre"
              >{`1첩용량: `}</TypographyText>
              <TypographyText fontSize="0.8rem" lineHeight="1.2em" whiteSpace="pre">{`${_.round(
                _.values(prescribedHerbs).reduce((a, c) => a + c.amount / 1000, 0),
                2,
              )}g`}</TypographyText>
              <TypographyText fontSize="1rem" lineHeight="1em" whiteSpace="pre">{` × `}</TypographyText>
            </Col>
            {insuranceModeFlag ? (
              <Col style={{ display: 'flex', alignItems: 'center', columnGap: '0.25rem' }}>
                <button
                  style={{
                    border: prescriptionCount === 10 ? 'none' : '1px solid #afafaf',
                    background: prescriptionCount === 10 ? '#1c252c' : '#ffffff',
                    height: '1.3rem',
                    width: '2.7rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => setPrescriptionCount(10)}
                >
                  <TypographyText
                    color={prescriptionCount === 10 ? '#ffffff' : '#afafaf'}
                    fontSize="0.8rem"
                    lineHeight="1.2em"
                    whiteSpace="pre"
                  >
                    {'10첩'}
                  </TypographyText>
                </button>
                <button
                  style={{
                    border: prescriptionCount === 20 ? 'none' : '1px solid #afafaf',
                    background: prescriptionCount === 20 ? '#1c252c' : '#ffffff',
                    height: '1.3rem',
                    width: '2.7rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => setPrescriptionCount(20)}
                >
                  <TypographyText
                    color={prescriptionCount === 20 ? '#ffffff' : '#afafaf'}
                    fontSize="0.8rem"
                    lineHeight="1.2em"
                    whiteSpace="pre"
                  >
                    {'20첩'}
                  </TypographyText>
                </button>
              </Col>
            ) : (
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <MinusButton
                  onClick={() => {
                    if (prescriptionCount > 1) {
                      setPrescriptionCount(prescriptionCount - 1);
                    }
                  }}
                />
                <input
                  type="number"
                  min={0}
                  className={css`
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                    }
                    outline: none;
                  `}
                  style={{
                    borderTop: '1px solid #c4c4c4',
                    borderBottom: '1px solid #c4c4c4',
                    width: '2rem',
                    height: '1.2rem',
                    textAlign: 'center',
                    fontSize: '0.9rem',
                    fontWeight: 700,
                  }}
                  value={Number(prescriptionCount).toString()}
                  onChange={(e: any) => {
                    const v = Number(e.target.value);
                    if (isInteger(v)) {
                      setPrescriptionCount(v);
                    }
                  }}
                  onBlur={() => {
                    if (!prescriptionCount) {
                      setPrescriptionCount(1);
                    }
                  }}
                />
                <PlusButton onClick={() => setPrescriptionCount(prescriptionCount + 1)} />
              </Col>
            )}
            <Row style={{ display: 'flex' }}>
              <TypographyText fontSize="0.8rem" lineHeight="1.2em" whiteSpace="pre-wrap">{`${
                insuranceModeFlag ? '' : '첩'
              } =  총용량: ${_.round(
                _.values(prescribedHerbs).reduce((a, c) => a + c.amount / 1000, 0) * prescriptionCount,
                2,
              )}g`}</TypographyText>
            </Row>
          </Col>
          {insuranceModeFlag && selectedPrescriptionData?.id && (
            <Col style={{ display: 'flex', flexDirection: 'column', rowGap: '0.4rem' }}>
              <Row style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <TypographyText color="#DF635C" fontSize="0.8rem" lineHeight="1em" whiteSpace="pre">
                  {`상한 금액: ${applyComma(priceCap)} 원`}
                </TypographyText>
              </Row>
            </Col>
          )}
        </Col>
        <Col
          style={{
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem',
            width: '6.15rem',
            height: '2rem',
            cursor: 'pointer',
            border: '1px solid #c4c4c4',
            borderRadius: '3px',
            background: _.isEmpty(prescribedHerbs) ? '#c4c4c4' : '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            columnGap: '0.4rem',
          }}
          className={_.isEmpty(prescribedHerbs) ? 'disabledButton' : ''}
          onClick={() => setIsOpenMyPrescriptionSaveModal(true)}
        >
          {isLoadingMyPrescriptionList ? (
            <Spinner spinnerDivWidth="w-15" />
          ) : (
            <TypographyText
              color={_.isEmpty(prescribedHerbs) ? '#ffffff' : '#1d1c1d'}
              fontSize="0.8rem"
              fontWeight={700}
              lineHeight="0.8rem"
              whiteSpace="pre"
            >
              나의 처방 저장
            </TypographyText>
          )}
        </Col>
      </Row>

      {isOpenPrescriptionHistoryModal.isOpen && (
        <ModalType2
          isOpen={isOpenPrescriptionHistoryModal.isOpen}
          hiddenFlag={isOpenPrescriptionHistoryModal.hiddenFlag}
          title={'처방 기록'}
          width={34}
          content={
            <Row>
              {_.isEmpty(treatmentStore.treatments?.filter((t) => !_.isEmpty(t?.prescribedRecipe))) ? (
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '2rem',
                    minHeight: '30rem',
                  }}
                >
                  <TypographyText fontSize={'1rem'} color={'#AFAFAF'} whiteSpace={'pre'}>
                    처방 기록이 없습니다.
                  </TypographyText>
                </Row>
              ) : (
                <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
                  {treatmentStore.treatments.reduce((a, treatment) => {
                    if (_.isEmpty(treatment.prescribedRecipe)) {
                      return a;
                    }
                    return [
                      ...a,
                      <CommonPrescriptionTable
                        treatmentMode={treatmentMode}
                        treatment={treatment}
                        titleHiddenFlag
                        topRightButtonText={treatment.prescribedRecipe ? '처방하기' : ''}
                        topRightButtonFunc={async () => {
                          let prescriptionData;
                          let customHerbalFormulaData;
                          if (treatment.prescriptionId) {
                            prescriptionData = await (
                              await PR.getHerbalFormula(treatment.prescriptionId)
                            ).getHerbalFormula;
                          }
                          if (treatment.customHerbalFormulaId) {
                            customHerbalFormulaData = await (
                              await PR.getCustomHerbalFormula(treatment.customHerbalFormulaId)
                            ).getCustomHerbalFormula;
                          }
                          const duplicatedHerbs = {};
                          const newPrescribedHerbs = {};
                          _.sortBy(treatment.prescribedRecipe, (e) => -e.amt).forEach((e) => {
                            if (prescribedHerbs[e.pc]) {
                              duplicatedHerbs[e.pc] = {
                                code: e.pc,
                                name: prescriptionStore.yejinHerbForSearchByProcessingCode.get(e.pc)
                                  .koreanName,
                                amount: e.amt,
                              };
                            } else {
                              newPrescribedHerbs[e.pc] = {
                                code: e.pc,
                                amount: e.amt,
                              };
                            }
                          });

                          if (_.isEmpty(prescribedHerbs)) {
                            setPrescribedHerbs(newPrescribedHerbs);
                            if (prescriptionData) {
                              setSelectedPrescriptionData(prescriptionData);
                            }
                            if (customHerbalFormulaData) {
                              setSelectedMyPrescriptionData(customHerbalFormulaData);
                            }
                            setSelectedMyPrescriptionData({});
                            setPrescribedHerbTableScrollTrigger(true);
                            message.success(`약제가 추가되었습니다.`, 2500);
                            setIsOpenPrescriptionInfoModal({});
                          } else {
                            await setNeedConfirmationHerbsTemp(duplicatedHerbs);
                            await setDontNeedConfirmationHerbsTemp(newPrescribedHerbs);
                            if (prescriptionData) {
                              setIsOpenPrescriptionInfoModal({
                                ...prescriptionData,
                                customHerbalFormulaData,
                                hiddenModalFlag: true,
                                from: 'plusButton',
                              });
                            }
                            setIsOpenAddHerbMethodModal(true);
                          }
                          setIsOpenPrescriptionHistoryModal({
                            isOpen: true,
                            hiddenFlag: true,
                          });
                        }}
                      />,
                    ];
                  }, [])}
                </Row>
              )}
            </Row>
          }
          cancelFunction={() =>
            setIsOpenPrescriptionHistoryModal({
              isOpen: false,
              hiddenFlag: false,
            })
          }
        />
      )}
      {isOpenMyPrescriptionSaveModal && (
        <MyPrescriptionSaveModal
          isOpen={isOpenMyPrescriptionSaveModal}
          setIsOpen={setIsOpenMyPrescriptionSaveModal}
          setRefreshFlag={setRefreshGetMyPrescriptionListTrigger}
          userId={clientStore.metaData?.user.id}
          prescribedHerbs={prescribedHerbs}
          selectedPrescriptionData={selectedPrescriptionData}
          isLoadingMyPrescriptionList={isLoadingMyPrescriptionList}
          myPrescriptionList={myPrescriptionList}
        />
      )}
      {isOpenMyPrescriptionListModal.isOpen && (
        <MyPrescriptionListModal
          treatmentMode={treatmentMode}
          isOpen={isOpenMyPrescriptionListModal.isOpen}
          hiddenFlag={isOpenMyPrescriptionListModal.hiddenFlag}
          setIsOpen={setIsOpenMyPrescriptionListModal}
          setRefreshFlag={setRefreshGetMyPrescriptionListTrigger}
          prescribedHerbs={prescribedHerbs}
          myPrescriptionList={myPrescriptionList}
          setIsOpenAddHerbMethodModal={setIsOpenAddHerbMethodModal}
          setPrescribedHerbs={setPrescribedHerbs}
          setPrescribedHerbTableScrollTrigger={setPrescribedHerbTableScrollTrigger}
          setSelectedPrescriptionData={setSelectedPrescriptionData}
          setSelectedMyPrescriptionData={setSelectedMyPrescriptionData}
          setIsOpenPrescriptionInfoModal={setIsOpenPrescriptionInfoModal}
          setNeedConfirmationHerbsTemp={setNeedConfirmationHerbsTemp}
          setDontNeedConfirmationHerbsTemp={setDontNeedConfirmationHerbsTemp}
        />
      )}
    </>
  );
};

export default observer(PrescribedHerbTable);
