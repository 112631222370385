import React from 'react';
import _ from 'lodash';

import { TypographyText, Col } from 'components/UI/atoms';
import { TableCell } from 'components/UI/atoms/table/TableCell';

const FeedbackResultTd = ({
  surveyCodeArrForfeedback = [],
  feedbackResult = [],
  feedbackResultObject = {},
  symptom,
  feedbackScheduleFlag,
}: {
  surveyCodeArrForfeedback: any[];
  feedbackResult: any[];
  feedbackResultObject: any;
  symptom: any;
  feedbackScheduleFlag: boolean;
}) => {
  if (feedbackScheduleFlag === undefined) {
    throw new Error('feedbackScheduleFlag is undefined');
  }
  return (
    <>
      {
        <TableCell
          style={{
            borderLeft: 'none',
            borderRight: 'none',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              columnGap: '0.5rem',
            }}
          >
            {!_.isEmpty(feedbackResult) ? (
              feedbackResultObject[symptom.code] && (
                <>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/feedback_icon_${
                      feedbackResultObject[symptom.code]?.scale
                    }.webp`}
                    style={{ width: '1.2rem' }}
                    alt={`${symptom.code}${feedbackResultObject[symptom.code]?.scale}`}
                  />
                  <TypographyText yjTypeTypographyText="symptom-table-body" color="#1D1C1D">
                    {feedbackResultObject[symptom.code]?.scale}
                  </TypographyText>
                  {feedbackResultObject[symptom.code]?.scale === symptom.value.scale ? (
                    <TypographyText
                      yjTypeTypographyText="symptom-table-body"
                      color="#FBBB22"
                      fontSize="0.8rem"
                      fontWeight={700}
                      whiteSpace="pre"
                    >
                      동일
                    </TypographyText>
                  ) : feedbackResultObject[symptom.code]?.scale > symptom.value.scale ? (
                    <TypographyText
                      yjTypeTypographyText="symptom-table-body"
                      color="#DF635C"
                      fontWeight={700}
                      fontSize="0.8rem"
                      whiteSpace="pre"
                    >
                      악화
                    </TypographyText>
                  ) : (
                    <TypographyText
                      yjTypeTypographyText="symptom-table-body"
                      color="#4BCD58"
                      fontWeight={700}
                      fontSize="0.8rem"
                      whiteSpace="pre"
                    >
                      개선
                    </TypographyText>
                  )}
                </>
              )
            ) : (
              <>
                {_.includes(surveyCodeArrForfeedback, symptom.code) && (
                  <TypographyText fontSize="0.8rem" lineHeight="1em" whiteSpace="pre">
                    {feedbackScheduleFlag ? '대기중' : ''}
                  </TypographyText>
                )}
              </>
            )}
          </Col>
        </TableCell>
      }
    </>
  );
};

export default React.memo(FeedbackResultTd);
