export interface SymptomCode {
  code: string;
  name: string;
}

export const symptoms: SymptomCode[] = [
  { code: 'S001', name: 'COPD' },
  { code: 'S002', name: '가래' },
  { code: 'S003', name: '각기' },
  { code: 'S004', name: '각혈' },
  { code: 'S005', name: '간염' },
  { code: 'S006', name: '갈증' },
  { code: 'S007', name: '감기' },
  { code: 'S008', name: '감질' },
  { code: 'S009', name: '갑상선비대' },
  { code: 'S010', name: '건망' },
  { code: 'S011', name: '견배산통' },
  { code: 'S012', name: '견비통' },
  { code: 'S013', name: '결유' },
  { code: 'S014', name: '결흉' },
  { code: 'S015', name: '경계' },
  { code: 'S016', name: '경련발작' },
  { code: 'S017', name: '경전복통' },
  { code: 'S018', name: '경전유방통' },
  { code: 'S019', name: '경폐' },
  { code: 'S020', name: '경항강통' },
  { code: 'S021', name: '고열' },
  { code: 'S022', name: '고지혈증' },
  { code: 'S023', name: '고혈압' },
  { code: 'S024', name: '고환통' },
  { code: 'S025', name: '골경' },
  { code: 'S026', name: '골반염' },
  { code: 'S027', name: '골상' },
  { code: 'S028', name: '골위' },
  { code: 'S029', name: '골증노열' },
  { code: 'S030', name: '과민대장증후군' },
  { code: 'S031', name: '관절구축' },
  { code: 'S032', name: '관절비통' },
  { code: 'S033', name: '관절통' },
  { code: 'S034', name: '광견교상' },
  { code: 'S035', name: '광증' },
  { code: 'S036', name: '교심통' },
  { code: 'S037', name: '구고' },
  { code: 'S038', name: '구련' },
  { code: 'S039', name: '구안와사' },
  { code: 'S040', name: '구역' },
  { code: 'S041', name: '구조' },
  { code: 'S042', name: '구창' },
  { code: 'S043', name: '구취' },
  { code: 'S044', name: '구토' },
  { code: 'S045', name: '근무력' },
  { code: 'S046', name: '근통' },
  { code: 'S047', name: '급성간염' },
  { code: 'S048', name: '급성신염증후군' },
  { code: 'S049', name: '기관지염' },
  { code: 'S050', name: '기부습란' },
  { code: 'S051', name: '기아부종' },
  { code: 'S052', name: '기역' },
  { code: 'S053', name: '기체' },
  { code: 'S054', name: '기침' },
  { code: 'S055', name: '기함' },
  { code: 'S056', name: '기허' },
  { code: 'S057', name: '나력' },
  { code: 'S058', name: '나병' },
  { code: 'S059', name: '난산' },
  { code: 'S060', name: '노수' },
  { code: 'S061', name: '노육' },
  { code: 'S062', name: '농포창' },
  { code: 'S063', name: '뇨삽통' },
  { code: 'S064', name: '뇨적' },
  { code: 'S065', name: '뇨탁' },
  { code: 'S066', name: '뇨혈' },
  { code: 'S067', name: '다뇨' },
  { code: 'S068', name: '다루' },
  { code: 'S069', name: '다몽' },
  { code: 'S070', name: '다연' },
  { code: 'S071', name: '다한' },
  { code: 'S072', name: '단독' },
  { code: 'S073', name: '담낭염' },
  { code: 'S074', name: '담벽' },
  { code: 'S075', name: '담석증' },
  { code: 'S076', name: '담핵' },
  { code: 'S077', name: '대하' },
  { code: 'S078', name: '대혈' },
  { code: 'S079', name: '독사교상' },
  { code: 'S080', name: '동상' },
  { code: 'S081', name: '동창' },
  { code: 'S082', name: '두독' },
  { code: 'S083', name: '두드러기' },
  { code: 'S084', name: '두면부종' },
  { code: 'S085', name: '두선' },
  { code: 'S086', name: '두슬' },
  { code: 'S087', name: '두통' },
  { code: 'S088', name: '딸꾹질' },
  { code: 'S089', name: '땀' },
  { code: 'S090', name: '란현풍안' },
  { code: 'S091', name: '란후단사' },
  { code: 'S092', name: '림프절비대' },
  { code: 'S093', name: '마른기침' },
  { code: 'S094', name: '마목' },
  { code: 'S095', name: '마진' },
  { code: 'S096', name: '만성간염' },
  { code: 'S097', name: '만성골수염' },
  { code: 'S098', name: '말라리아' },
  { code: 'S099', name: '매독' },
  { code: 'S100', name: '매핵기' },
  { code: 'S101', name: '맥무력' },
  { code: 'S102', name: '맥부' },
  { code: 'S103', name: '맥삭' },
  { code: 'S104', name: '맥유력' },
  { code: 'S105', name: '면황' },
  { code: 'S106', name: '목부종' },
  { code: 'S107', name: '목암' },
  { code: 'S108', name: '목예' },
  { code: 'S109', name: '목적' },
  { code: 'S110', name: '목통' },
  { code: 'S111', name: '목화' },
  { code: 'S112', name: '무한' },
  { code: 'S113', name: '반신불수' },
  { code: 'S114', name: '반위' },
  { code: 'S115', name: '발열' },
  { code: 'S116', name: '방광염' },
  { code: 'S117', name: '배뇨작열' },
  { code: 'S118', name: '배부옹저' },
  { code: 'S119', name: '백대' },
  { code: 'S120', name: '백반' },
  { code: 'S121', name: '백선' },
  { code: 'S122', name: '백일해' },
  { code: 'S123', name: '백후' },
  { code: 'S124', name: '버거병' },
  { code: 'S125', name: '번조' },
  { code: 'S126', name: '변비' },
  { code: 'S127', name: '변혈' },
  { code: 'S128', name: '복냉' },
  { code: 'S129', name: '복수' },
  { code: 'S130', name: '복중포괴' },
  { code: 'S131', name: '복통' },
  { code: 'S132', name: '부녀빈혈' },
  { code: 'S133', name: '부인장조' },
  { code: 'S134', name: '부정맥' },
  { code: 'S135', name: '부종' },
  { code: 'S136', name: '분노' },
  { code: 'S137', name: '분돈' },
  { code: 'S138', name: '불면' },
  { code: 'S139', name: '불안' },
  { code: 'S140', name: '불어' },
  { code: 'S141', name: '불임' },
  { code: 'S142', name: '붕루' },
  { code: 'S143', name: '비만' },
  { code: 'S144', name: '비식육' },
  { code: 'S145', name: '비염' },
  { code: 'S146', name: '비자' },
  { code: 'S147', name: '빈뇨' },
  { code: 'S148', name: '사마귀' },
  { code: 'S149', name: '사태' },
  { code: 'S150', name: '산기' },
  { code: 'S151', name: '산후발열' },
  { code: 'S152', name: '산후복통' },
  { code: 'S153', name: '산후부종' },
  { code: 'S154', name: '산후소변불리' },
  { code: 'S155', name: '산후유종' },
  { code: 'S156', name: '산후혈훈' },
  { code: 'S157', name: '설강' },
  { code: 'S158', name: '설담' },
  { code: 'S159', name: '설사' },
  { code: 'S160', name: '설조' },
  { code: 'S161', name: '설창' },
  { code: 'S162', name: '설태백' },
  { code: 'S163', name: '설태황' },
  { code: 'S164', name: '설홍' },
  { code: 'S165', name: '섬망' },
  { code: 'S166', name: '세기관지염' },
  { code: 'S167', name: '소갈' },
  { code: 'S168', name: '소곡선기' },
  { code: 'S169', name: '소변불리' },
  { code: 'S170', name: '소아경간' },
  { code: 'S171', name: '소아마비후유증' },
  { code: 'S172', name: '소아발육불량' },
  { code: 'S173', name: '소아야제' },
  { code: 'S174', name: '소아유뇨' },
  { code: 'S175', name: '소아유식정체' },
  { code: 'S176', name: '소아행지' },
  { code: 'S177', name: '소양' },
  { code: 'S178', name: '소화불량' },
  { code: 'S179', name: '소화성궤양' },
  { code: 'S180', name: '속쓰림' },
  { code: 'S181', name: '수명' },
  { code: 'S182', name: '수발조백' },
  { code: 'S183', name: '수은중독' },
  { code: 'S184', name: '수족냉' },
  { code: 'S185', name: '수종' },
  { code: 'S186', name: '슬산' },
  { code: 'S187', name: '슬통' },
  { code: 'S188', name: '습담' },
  { code: 'S189', name: '습진' },
  { code: 'S190', name: '습창' },
  { code: 'S191', name: '식물독' },
  { code: 'S192', name: '식욕부진' },
  { code: 'S193', name: '식은땀' },
  { code: 'S194', name: '식적' },
  { code: 'S195', name: '식중독' },
  { code: 'S196', name: '식체' },
  { code: 'S197', name: '신랭' },
  { code: 'S198', name: '실신혼미' },
  { code: 'S199', name: '심계' },
  { code: 'S200', name: '심하비경' },
  { code: 'S201', name: '아장풍' },
  { code: 'S202', name: '야간다뇨' },
  { code: 'S203', name: '야맹' },
  { code: 'S204', name: '양위' },
  { code: 'S205', name: '양허' },
  { code: 'S206', name: '어해독' },
  { code: 'S207', name: '어혈' },
  { code: 'S208', name: '언어건삽' },
  { code: 'S209', name: '여드름' },
  { code: 'S210', name: '연급' },
  { code: 'S211', name: '열격' },
  { code: 'S212', name: '영류' },
  { code: 'S213', name: '예장' },
  { code: 'S214', name: '오로불하' },
  { code: 'S215', name: '오심열' },
  { code: 'S216', name: '오한' },
  { code: 'S217', name: '옴' },
  { code: 'S218', name: '완복통' },
  { code: 'S219', name: '완비' },
  { code: 'S220', name: '완선' },
  { code: 'S221', name: '외상출혈' },
  { code: 'S222', name: '요도염' },
  { code: 'S223', name: '요로결석' },
  { code: 'S224', name: '요산' },
  { code: 'S225', name: '요실금' },
  { code: 'S226', name: '요통' },
  { code: 'S227', name: '우울' },
  { code: 'S228', name: '우췌' },
  { code: 'S229', name: '원형탈모' },
  { code: 'S230', name: '월경과다' },
  { code: 'S231', name: '월경부조' },
  { code: 'S232', name: '월경색자암다괴' },
  { code: 'S233', name: '월경후기' },
  { code: 'S234', name: '위하수' },
  { code: 'S235', name: '위확장' },
  { code: 'S236', name: '유방통' },
  { code: 'S237', name: '유선증생' },
  { code: 'S238', name: '유옹' },
  { code: 'S239', name: '유정' },
  { code: 'S240', name: '유창' },
  { code: 'S241', name: '유핵' },
  { code: 'S242', name: '융폐' },
  { code: 'S243', name: '음낭소양' },
  { code: 'S244', name: '음부소양' },
  { code: 'S245', name: '음아' },
  { code: 'S246', name: '음저' },
  { code: 'S247', name: '음허' },
  { code: 'S248', name: '이농' },
  { code: 'S249', name: '이롱' },
  { code: 'S250', name: '이명' },
  { code: 'S251', name: '인후염' },
  { code: 'S252', name: '인후종' },
  { code: 'S253', name: '인후통' },
  { code: 'S254', name: '임신오조' },
  { code: 'S255', name: '임신자간' },
  { code: 'S256', name: '임신하혈' },
  { code: 'S257', name: '자궁경부암' },
  { code: 'S258', name: '자궁냉' },
  { code: 'S259', name: '자궁부속기염' },
  { code: 'S260', name: '자궁하수' },
  { code: 'S261', name: '자시' },
  { code: 'S262', name: '자전풍' },
  { code: 'S263', name: '잔뇨' },
  { code: 'S264', name: '잔류태반' },
  { code: 'S265', name: '장명' },
  { code: 'S266', name: '장염' },
  { code: 'S267', name: '장옹' },
  { code: 'S268', name: '적란' },
  { code: 'S269', name: '적충성음도염' },
  { code: 'S270', name: '전간' },
  { code: 'S271', name: '전근' },
  { code: 'S272', name: '전립선비대증' },
  { code: 'S273', name: '전신무력' },
  { code: 'S274', name: '절박뇨' },
  { code: 'S275', name: '정냉' },
  { code: 'S276', name: '정신무력(무력감)' },
  { code: 'S277', name: '조기분만' },
  { code: 'S278', name: '조담' },
  { code: 'S279', name: '조루' },
  { code: 'S280', name: '종기' },
  { code: 'S281', name: '주사비' },
  { code: 'S282', name: '중풍' },
  { code: 'S283', name: '지루성피부염' },
  { code: 'S284', name: '지속발기' },
  { code: 'S285', name: '징가' },
  { code: 'S286', name: '착란' },
  { code: 'S287', name: '창양불렴' },
  { code: 'S288', name: '척강' },
  { code: 'S289', name: '천식' },
  { code: 'S290', name: '체선' },
  { code: 'S291', name: '체슬' },
  { code: 'S292', name: '출혈' },
  { code: 'S293', name: '치아우식' },
  { code: 'S294', name: '치은염' },
  { code: 'S295', name: '치주염' },
  { code: 'S296', name: '치질' },
  { code: 'S297', name: '치통' },
  { code: 'S298', name: '코막힘' },
  { code: 'S299', name: '코피' },
  { code: 'S300', name: '콧물' },
  { code: 'S301', name: '타박상' },
  { code: 'S302', name: '탄산' },
  { code: 'S303', name: '탈모' },
  { code: 'S304', name: '탈항' },
  { code: 'S305', name: '태동불안' },
  { code: 'S306', name: '태동욕추' },
  { code: 'S307', name: '토혈' },
  { code: 'S308', name: '통경' },
  { code: 'S309', name: '통증' },
  { code: 'S310', name: '트림' },
  { code: 'S311', name: '파상풍' },
  { code: 'S312', name: '편두통' },
  { code: 'S313', name: '폐결핵' },
  { code: 'S314', name: '폐렴' },
  { code: 'S315', name: '풍진' },
  { code: 'S316', name: '피부궤양' },
  { code: 'S317', name: '피부군열' },
  { code: 'S318', name: '피부부종' },
  { code: 'S319', name: '피부불인' },
  { code: 'S320', name: '하지무력' },
  { code: 'S321', name: '하지부종' },
  { code: 'S322', name: '한열왕래' },
  { code: 'S323', name: '허열' },
  { code: 'S324', name: '헤르페스' },
  { code: 'S325', name: '현훈' },
  { code: 'S326', name: '혈열' },
  { code: 'S327', name: '혈한' },
  { code: 'S328', name: '혈허' },
  { code: 'S329', name: '협심증' },
  { code: 'S330', name: '협통' },
  { code: 'S331', name: '호흡곤란' },
  { code: 'S332', name: '홍반' },
  { code: 'S333', name: '홍역' },
  { code: 'S334', name: '홍조' },
  { code: 'S335', name: '화상' },
  { code: 'S336', name: '황달' },
  { code: 'S337', name: '황담' },
  { code: 'S338', name: '회충목' },
  { code: 'S339', name: '흉민' },
  { code: 'S340', name: '흉번' },
  { code: 'S341', name: '흉통' },
  { code: 'S342', name: '희담' },
];

export const DIAGNOSIS = [
  {
    label: '망진',
    children: [
      { label: '면황', sympomCode: 'S105' },
      { label: '홍조', sympomCode: 'S334' },
      { label: '면백', sympomCode: 'S818' },
      { label: '면청', sympomCode: 'S819' },
      { label: '면흑', sympomCode: 'S820' },
      { label: '황달', sympomCode: 'S336' },
      { label: '실신혼미', sympomCode: 'S198' },
      { label: '섬망', sympomCode: 'S165' },
      { label: '착란', sympomCode: 'S286' },
    ],
  },
  {
    label: '설진',
    children: [
      { label: '설담', sympomCode: 'S158' },
      { label: '설홍', sympomCode: 'S164' },
      { label: '설태백', sympomCode: 'S162' },
      { label: '설태황', sympomCode: 'S163' },
      { label: '설강', sympomCode: 'S157' },
    ],
  },
  {
    label: '맥진',
    children: [
      { label: '맥부', sympomCode: 'S102' },
      { label: '맥침', sympomCode: 'S822' },
      { label: '맥지', sympomCode: 'S821' },
      { label: '맥삭', sympomCode: 'S103' },
      { label: '맥유력', sympomCode: 'S104' },
      { label: '맥무력', sympomCode: 'S101' },
    ],
  },
  {
    label: '복진',
    children: [
      { label: '심하비만', sympomCode: 'S219' },
      { label: '복만', sympomCode: 'S143' },
      { label: '징가', sympomCode: 'S285' },
      { label: '심하비경', sympomCode: 'S401' },
      { label: '흉협고만', sympomCode: 'S402' },
      { label: '복직근련급', sympomCode: 'S403' },
    ],
  },
];
