import { palgangLabelSet } from 'constant';
import { TypographyText, Row } from 'components/UI/atoms';
import { GraphType1 } from 'components/UI/molecules';

import { convertPalgangData } from 'utils/util';

const Palgang = ({
  patientName,
  mode = 'default',
  byeonjeungData = {},
  isLoading = false,
}: {
  patientName: string;
  mode?: 'modal' | 'default';
  byeonjeungData?: any;
  isLoading?: boolean;
}) => {
  let marginBetweenTitleAndTitleDesc = { marginBottom: '1rem' };
  let TitleDescHeight = { height: '2.7rem', minHeight: '2.7rem' };
  let descFontStyle = {};
  switch (mode) {
    case 'modal':
      marginBetweenTitleAndTitleDesc = { marginBottom: '1.25rem' };
      TitleDescHeight = { height: '2.7rem', minHeight: '2.7rem' };
      descFontStyle = {
        fontSize: '0.9rem',
        lineHeight: '1.2em',
      };
  }

  return (
    <>
      <Row
        style={{
          display: 'flex',
          columnGap: '0.3rem',
          marginBottom: '1.4rem',
          ...marginBetweenTitleAndTitleDesc,
        }}
      >
        <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2rem">
          팔강
        </TypographyText>
        <TypographyText fontSize={'1.2rem'} lineHeight="1.2rem">
          八綱
        </TypographyText>
      </Row>
      <Row
        style={{
          display: 'flex',
          height: '2.7rem',
          minHeight: '2.7rem',
          ...TitleDescHeight,
        }}
      >
        <TypographyText
          fontSize={'0.9rem'}
          lineHeight="1.2em"
          letterSpacing="-0.01em"
          style={{ ...descFontStyle }}
        >
          {convertPalgangData(byeonjeungData, patientName)}
        </TypographyText>
      </Row>
      {palgangLabelSet.map((e, i) => {
        return (
          <GraphType1
            key={i}
            isLoading={isLoading}
            mode="admin-treatment-analysis"
            leftKLabel={`${e.leftKLabel}`}
            leftCLabel={`${e.leftCLabel}`}
            leftValue={byeonjeungData?.팔강?.[e.leftKLabel]}
            rightKLabel={`${e.rightKLabel}`}
            rightCLabel={`${e.rightCLabel}`}
            rightValue={byeonjeungData?.팔강?.[e.rightKLabel]}
            bottomDidiverFlag={i !== palgangLabelSet.length - 1}
          />
        );
      })}
      <Row
        style={{
          display: 'flex',
          marginTop: '2.5rem',
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/img/analysis-img.webp`} width={'100%'} alt={'analysis-img'} />
      </Row>
    </>
  );
};
export default Palgang;
