import React from 'react';

import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { color } from '../../../../styles/themes';
import { Col as ACol, ColProps } from 'antd';
import { tuple } from 'antd/lib/_util/type';

export const tYzTypeCol = tuple(
  'include-pagination',
  'sign-in',
  'filter',
  'verticalDivider',
  'header-logo',
  'center',
  'survey-guide',
  'footer-prev-button-col',
  'footer-next-button-col',
  'selected-onset-button',
  'onset-button',
  'selected-check-button',
  'check-button',
  'selected-scale-button',
  'scale-button',
  'survey-side',
  'survey-title',
  'survey-sub-title',
  'survey-content',
  'survey-checkbox',
  'survey-checkbox-wrapper',
  'selected-survey-checkbox',
  'selected-survey-checkbox-wrapper',
  'survey-checkbox-label',
);

type TYzTypeCol = (typeof tYzTypeCol)[number] | undefined;

export interface IColParmas extends ColProps {
  yjTypeCol?: TYzTypeCol;
  twoColumnsInRowFlag?: boolean;
  flexFlag?: boolean;
  justifyContent?: string;
  alignItems?: string;
}

const handleColStyleWithType = ({ yjTypeCol }: IColParmas) => {
  switch (yjTypeCol) {
    case 'survey-checkbox-label':
      return css`
        height: 3rem;
        margin-right: 1rem;
      `;
    case 'survey-checkbox-wrapper':
      return css`
        margin: 0.8rem 1rem;
        margin-left: 0 !important;
        width: 1.4rem;
        height: 1.4rem;
        border: 1px solid #c4c4c4;
        border-radius: 3px;

        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      `;
    case 'selected-survey-checkbox':
      return css`
        position: absolute;
        left: 0.5rem;
        top: 0.25rem;
        width: 0.35rem;
        height: 0.7rem;
        border: solid #ffffff;
        border-radius: 1px;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      `;
    case 'selected-survey-checkbox-wrapper':
      return css`
        margin: 0.8rem 1rem;
        margin-left: 0 !important;
        width: 1.4rem;
        height: 1.4rem;
        border: 1px solid #346aff;
        border-radius: 3px;
        background: #346aff;

        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      `;
    case 'survey-checkbox':
      return css`
        position: absolute;
        left: 0.5rem;
        top: 0.25rem;
        width: 0.35rem;
        height: 0.7rem;
        border: solid #c4c4c4;
        border-radius: 1px;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      `;
    case 'footer-prev-button-col':
      return css`
        position: inherit;
        right: 10.4rem;
        min-width: 1px;
      `;
    case 'footer-next-button-col':
      return css`
        position: inherit;
        right: 2.4rem;
        min-width: 1px;
      `;
    case 'survey-title':
      return css`
        border-right: 1px solid #1d1c1d;
        display: inline-flex;
        padding-right: 1rem;
      `;
    case 'survey-sub-title':
      return css`
        padding-left: 1rem;
        display: inline-flex;
        vertical-align: bottom;
      `;
    case 'survey-content':
      return css`
        width: calc(100% - 14rem); /** survey-side width: 14rem */
        max-width: calc(100% - 14rem);
        overflow-y: scroll;
        height: calc(100vh - 6.4rem - 7rem); /** header height: 6.4rem, footer height: 7rem */
        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0.3rem;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.4);
        }
      `;
    case 'survey-side':
      return css`
        width: 14rem;
      `;
    case 'selected-scale-button':
      return css`
        text-align: center;
        border: 1px solid #c2d5eb;
        background: #d5e8ff;
        color: ${color.infoText} !important;

        font-size: 0.75rem;
        letter-spacing: 0.01em;
        font-weight: 400;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          border: 1px solid #c2d5eb;
          color: ${color.infoText};
        }
      `;
    case 'scale-button':
      return css`
        text-align: center;
        border: 1px solid #e5e5e5;
        color: #dadada !important;

        font-size: 0.75rem;
        letter-spacing: 0.01em;
        font-weight: 700;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          color: ${color.infoText};
          border: 1px solid #e5e5e5;
        }
      `;
    case 'selected-onset-button':
      return css`
        text-align: center;
        min-width: 12rem;
        height: 3rem;
        border: none;
        background: #d5e8ff;
        color: #1d1c1d;
        border: 1px solid #c2d5eb;
        margin-right: 0.5rem;
        padding: 0 0.5rem;

        font-size: 1.2rem;
        line-height: 3rem;
        letter-spacing: -0.01em;
        font-weight: 400;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          background: #d5e8ff;
          color: #1d1c1d;
          border: 1px solid #c2d5eb;
        }
      `;
    case 'onset-button':
      return css`
        text-align: center;
        min-width: 12rem;
        height: 3rem;
        border: none;
        background: ${color.white};
        color: #1d1c1d;
        border: 1px solid #e5e5e5;
        margin-right: 0.5rem;
        padding: 0 0.5rem;

        font-size: 1.2rem;
        line-height: 3rem;
        letter-spacing: -0.01em;
        font-weight: 400;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          background: ${color.white};
          color: #1d1c1d;
          border: 1px solid #e5e5e5;
        }
      `;
    case 'selected-check-button':
      return css`
        width: fit-content;
        min-width: 24.75rem;
        word-break: keep-all;
        height: 3rem;
        border: none;
        /* background: #d4e8ff; */
        color: ${color.infoText};
        /* border: 1px solid #c2d5eb; */

        font-size: 1.2rem;
        letter-spacing: -0.02em;
        font-weight: 400;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          color: ${color.infoText};
          /* border: 1px solid #c2d5eb !important; */
        }
      `;
    case 'check-button':
      return css`
        width: fit-content;
        min-width: 24.75rem;
        word-break: keep-all;
        height: 3rem;
        background: #ffffff;
        color: #1d1c1d;
        /* border: 1px solid #e5e5e5; */

        font-size: 1.2rem;
        letter-spacing: -0.02em;
        font-weight: 400;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          background: #ffffff;
          color: #1d1c1d;
          /* border: 1px solid #e5e5e5; */
        }
      `;
    case 'center':
      return css`
        margin: 0 auto;
      `;
    case 'survey-guide':
      return css`
        padding: 1.4rem 0.75rem;
        width: 100%;
        background: #f5f5f5;
      `;
    case 'sign-in':
      return css`
        padding-top: 4.55rem;
        padding-bottom: 4rem;
      `;
    case 'filter':
      return css``;
    case 'verticalDivider':
      return css`
        border-right: solid 1px #d9dde1;
      `;
    default:
      return css``;
  }
};

const handleColStyleWithFlag = ({ twoColumnsInRowFlag }: IColParmas) => {
  if (twoColumnsInRowFlag) {
    return css`
      :nth-child(odd) {
        margin-left: -0.125rem;
        margin-right: 0.125rem;
      }

      :nth-child(even) {
        margin-right: -0.125rem;
        margin-left: 0.125rem;
      }
    `;
  }
  return css``;
};

const StyledCol = styled(ACol, { shouldForwardProp: isPropValid })<IColParmas>`
  ${({ yjTypeCol }) => handleColStyleWithType({ yjTypeCol })}
  ${({ twoColumnsInRowFlag }) => handleColStyleWithFlag({ twoColumnsInRowFlag })}
  ${({ flexFlag }) => flexFlag && `display: flex;`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
`;

export const Col = ({ children, ...rest }: IColParmas) => <StyledCol {...rest}>{children}</StyledCol>;
