import { makeAutoObservable } from 'mobx';
import { Patient, UpdatePatientInput } from '../API';
import PatientRepository from '../repository/PatientRepository';

import { RootStore } from './RootStore';

export class PatientStore {
  rootStore;
  patients: Patient[] = [];
  patient?: Patient;
  nextToken?: string;
  prescriptionStatus: number = 0;
  prescriptionStatusStartValue: number = 1;
  prescriptionStatusEndValue: number = 3;
  loading: boolean = false;
  patientsList: Patient[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  // Basic Query
  getPatient = async (patientID: string) => {
    const data = await PatientRepository.getPatient(patientID!);
    const patient = data.getPatient as Patient;
    if (patient?.clinicID === this.rootStore.clientStore?.metaData?.userRole?.clinicID) {
      this.patient = patient;
      return patient;
    } else {
      this.patient = null;
    }
  };
  updatePatient = async (params: UpdatePatientInput) => {
    await PatientRepository.updatePateint(params);
  };

  setPatient = (patient: Patient) => {
    this.patient = patient;
  };

  resetNextToken = () => {
    this.nextToken = undefined;
  };

  resetPatients = () => {
    this.patients = [];
  };

  resetPatientsList = () => {
    this.patientsList = [];
  };

  setPatientsList = (data: Patient[]) => {
    this.patientsList = data;
  };

  resetPatient = () => {
    this.patient = undefined;
  };
}
