import React from 'react';
import { Menu as AMenu, MenuProps } from 'antd';
import { css } from '@emotion/core';
import { tuple } from 'antd/lib/_util/type';
import styled from '@emotion/styled';

const { SubMenu: ASubMenu, Item: AItem, ItemGroup: AItemGroup } = AMenu;

export const tYjTypeMenu = tuple('survey-category');

type TYjTypeMenu = typeof tYjTypeMenu[number] | undefined;

export interface IMenuParams extends Omit<MenuProps, 'theme'> {
  yjtypemenu?: TYjTypeMenu;
}

const handleMenuStyleWithType = ({ yjtypemenu }: IMenuParams) => {
  switch (yjtypemenu) {
    case 'survey-category':
      return css`
        list-style-type: none;
        margin: 0;
        padding: 0;
        > li {
          cursor: pointer;
          height: 2.4rem;
          width: 100%;
          font-weight: 400;
          font-size: 1rem;
          line-height: 2.4rem;
          letter-spacing: -0.02em;
          color: #afafaf;
          text-indent: 2.05rem;
        }
        > li.ant-menu-item-selected {
          cursor: pointer;
          height: 2.4rem;
          width: 100%;
          font-weight: 400;
          font-size: 1rem;
          line-height: 2.4rem;
          letter-spacing: -0.02em;
          color: #afafaf;
          text-indent: 2.05rem;
        }
        > li.current-item {
          color: #1d1c1d;
          font-weight: 700;
          background: #ebebeb;
        }
        .ant-menu-submenu-vertical {
          span {
            transform: translateY(-5%);
          }
        }
      `;
    default:
      return '';
  }
};

const StyledMenu = styled(AMenu)<IMenuParams>`
  ${({ yjtypemenu }) => handleMenuStyleWithType({ yjtypemenu })}
`;

export const Menu = ({ children, ...rest }: IMenuParams) => <StyledMenu {...rest}>{children}</StyledMenu>;

export const SubMenu = styled(ASubMenu)`
  &:not(:nth-child(1)) {
    border-top: 1px solid rgba(186, 185, 197, 0.47);
  }
  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background-image: linear-gradient(to right, rgb(208, 208, 216), rgb(208, 208, 216)) !important;
  }
`;

export const MenuItem = styled(AItem)``;

// Top level menu & no Children
export const MenuItemV2 = styled(AItem)`
  margin-top: 0.5rem;
  border-bottom: 1px solid rgba(186, 185, 197, 0.47);
  font-size: 0.75rem !important;
  a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: '';
  }
  a:active,
  a:hover {
    color: white !important;
    & + & svg {
      color: white !important;
    }
  }
  .ant-menu-item-selected {
    & a {
      color: white !important;
    }
  }
`;

export const ItemGroup = styled(AItemGroup)``;
