import styled from '@emotion/styled';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  isActived?: boolean;
}

export const SideLink = ({ to, children, isActived, ...rest }: Props) => {
  const SyledLink = styled(Link)<Props>`
    position: relative;
    text-decoration: none;
    color: #333333;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    /* &:hover {
      color: #f8b659;
      
      font-weight: 700;
    } */

    &.side-link-actived {
      color: #346aff !important;
      font-weight: 700;
    }
  `;
  // sidenav가 선택 되었을때 로직
  let actived;

  if (isActived) {
    actived = 'side-link-actived';
  }

  return (
    <SyledLink className={actived} to={to} {...rest}>
      {children}
      {actived && (
        <>
          <div
            style={{
              position: 'absolute',
              width: '3px',
              height: '100%',
              backgroundColor: '#346AFF',
              left: '-1.1rem',
              top: '0',
            }}
          />
          <div
            style={{
              position: 'absolute',
              width: 'calc(100% + 2.2rem)',
              height: 'calc(100% + 1.5rem)',
              background: '#E9F1FB',
              top: '-0.75rem',
              left: '-1.1rem',
              borderRadius: '3px',
              zIndex: -1,
            }}
          />
        </>
      )}
    </SyledLink>
  );
};
// export const SideLink = ({ name, color, to }: Props) => {
//   return <SyledLink to={to} style={{color}}>{name}</SyledLink>
// };
