/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { TypographyText, Row, Radio as ARadio, RadioGroup, Divider } from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import { isParentSurveyNegative } from 'utils/util';

function Scale({
  form,
  k,
  v,
  recentChangedField,
}: {
  form: any;
  k: string;
  v: any;
  recentChangedField: React.MutableRefObject<any>;
}): ReactElement {
  const { surveyStore } = useStores();
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    // scale 값이 없다면
    if (form.getFieldValue(k)?.scale == undefined) {
      // 설문 코드를 invalidScaleAdminSurveyCodes에 추가
      surveyStore.addInvalidScaleAdminSurveyCodes(k);
    } else {
      // scale 값이 있다면 invalidScaleAdminSurveyCodes에서 삭제
      surveyStore.deleteInvalidScaleAdminSurveyCodes(k);
    }
    return () => {
      // 부모 설문 코드
      const parentCode = k?.slice(0, -1);
      // 모든 부모 설문의 값이 부정이거나, 부모 설문이 배열이고 부모 설문에 현재 설문이 포함되어 있지 않거나, 부모 설문 값이 문자열인데 현재 설문 코드와 같지 않다면
      if (
        isParentSurveyNegative(form, k) ||
        (_.isArray(form.getFieldValue(parentCode)) && !form.getFieldValue(parentCode)?.includes(k)) ||
        (_.isString(form.getFieldValue(parentCode)) && form.getFieldValue(parentCode) !== k)
      ) {
        surveyStore.deleteInvalidScaleAdminSurveyCodes(k);
      }
    };
  }, []);

  return (
    <Row yjTypeRow="onset">
      <TypographyText yjTypeTypographyText="sub-title">불편한 정도</TypographyText>
      <Divider marginRem={0.3} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
      <RadioGroup buttonStyle="solid">
        {[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }].map(
          ({ value }: { value: string | number }, i: number) => (
            <ARadio
              className={form.getFieldValue(k)?.scale == value ? 'ant-radio-wrapper-checked' : ''}
              yjTypeRadio="survey-scale"
              key={`${k}_${value}`}
              checked={form.getFieldValue(k)?.scale == value}
              value={value}
              onChange={(e) => {
                e.stopPropagation(); //event capturing
                recentChangedField.current = k;
                const pv = form.getFieldValue(k) ?? {}; // previous value
                const cv = { ...pv, scale: value }; // current value
                // 이전 scale 값이 없고 현재 scale 값이 있다면 invalidScaleAdminSurveyCodes에서 삭제
                if (pv?.scale === undefined && cv?.scale !== undefined) {
                  surveyStore.deleteInvalidScaleAdminSurveyCodes(k);
                }
                form.setFieldsValue({ [k]: cv }); // set current value
                forceUpdate();
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/${i + 1}.webp`}
                style={{ width: '3.7rem' }}
                alt="scale"
              />
            </ARadio>
          ),
        )}
      </RadioGroup>
      {form.getFieldValue(k)?.scale === undefined && (
        <Row>
          <Divider marginRem={0.2} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
          <TypographyText yjTypeTypographyText="survey-validation">
            불편한 정도 항목을 선택해주세요.
          </TypographyText>
        </Row>
      )}
      <Divider marginRem={0.3} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
    </Row>
  );
}

export default observer(Scale);
