import React, { useState, useCallback, CSSProperties, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import message from 'antd-message';
import { observer } from 'mobx-react';
import LandingLayout from '../../templates/LandingLayout';
import { useStores } from 'stores/Context';
import { TypographyText, Row, Col, Modal } from 'components/UI/atoms';
import { signOutHandler } from 'utils/util';
import { Loading } from 'components/UI/molecules';
import ur from 'repository/UserRepository';
import { isEmpty } from 'lodash';

import { LicenseRegistrationModal } from 'components/UI/organisms/clinic/LicenseRegistrationModal';

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    rowGap: 0,
  },
  content: {
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {},
  infoSection: {
    marginBlock: '2rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },
  infoHeader: {
    display: 'flex',
  },
  infoRow: {
    marginTop: '0.25rem',
    display: 'flex',
    alignItems: 'center',
  },
  infoLabel: {
    display: 'flex',
    width: '6.25rem',
  },
  infoValue: {
    display: 'flex',
    flex: 1,
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',
  },
  primaryButton: {
    width: '29.2rem',
    height: '3.6rem',
    borderRadius: '3px',
    background: '#346AFF',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  secondaryButton: {
    width: '29.2rem',
    height: '3.6rem',
    border: '1px solid #346AFF',
    borderRadius: '3px',
    background: '#ffffff',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  disabledButton: {
    width: '29.2rem',
    height: '3.6rem',
    borderRadius: '3px',
    background: '#c4c4c4',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  withdrawalSection: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  withdrawalText: {
    width: 'fit-content',
    borderBottom: '1px solid #1C252C',
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 700,
    cursor: 'pointer',
  },
};

const UserInfoRow = ({ label, value }) => (
  <Row style={styles.infoRow}>
    <Col style={styles.infoLabel}>
      <TypographyText lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>
        {label}
      </TypographyText>
    </Col>
    <Col style={styles.infoValue}>
      <TypographyText lineHeight="1.2em" whiteSpace="pre">
        {value}
      </TypographyText>
    </Col>
  </Row>
);

const ActionButton = ({ onClick, text, primary = false, disabled = false }) => (
  <Row
    style={disabled ? styles.disabledButton : primary ? styles.primaryButton : styles.secondaryButton}
    onClick={onClick}
  >
    <TypographyText
      yjTypeTypographyText="symptom-table-body"
      fontWeight={700}
      fontSize="1.2rem"
      lineHeight="1.2rem"
      style={{ color: primary || disabled ? '#ffffff' : '#346AFF' }}
    >
      {text}
    </TypographyText>
  </Row>
);

const SignInWithoutClinic = () => {
  const navigate = useNavigate();
  const { clientStore } = useStores();
  const [isOpenWithdrawalModal, setIsOpenWithdrawalModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenLicenseRegistationModal, setIsOpenLicenseRegistrationModal] = useState(false); // 면허등록 모달 노출 여부

  const deleteUser = useCallback(async () => {
    try {
      await Auth.deleteUser();
    } catch (error) {
      console.log('Error deleting user', error);
    }
  }, []);

  const handleWithdrawal = async () => {
    try {
      setIsOpenWithdrawalModal(false);
      setIsLoading(true);
      await clientStore.setMetaData(sessionStorage.getItem('sub'));
      await ur.updateUser({
        id: clientStore.metaData?.user?.id,
        isDeleted: true,
      });
      await deleteUser();
      message.success(`예진 서비스에서 탈퇴되었습니다.`, 2500);
      await new Promise((resolve) => setTimeout(resolve, 2500));
      await signOutHandler();
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      message.error(`서비스 탈퇴 과정에서 문제가 발생했습니다.`, 2500);
    }
  };

  const user = clientStore?.user;
  const isDoctor = ['DOCTOR', 'MASTER'].includes(user?.roleName);

  // user 데이터가 없으면 로딩
  useEffect(() => {
    if (isEmpty(user)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [user]);

  return (
    <>
      {isOpenLicenseRegistationModal && (
        // 면허등록 모달
        <LicenseRegistrationModal
          setIsOpenLicenseRegistrationModal={setIsOpenLicenseRegistrationModal}
          showConfirmedPageFlag
        />
      )}
      {isOpenWithdrawalModal && (
        <Modal
          contentMsg="예진 서비스를 영구적으로 탈퇴하시겠습니까?"
          cancelMsg="아니오, 괜찮습니다."
          confirmFlag
          confirmMsg="탈퇴 후 되돌릴 수 없음을 확인하였습니다."
          okMsg="네, 탈퇴하겠습니다."
          okFunction={handleWithdrawal}
          cancelFunction={() => setIsOpenWithdrawalModal(false)}
        />
      )}
      <LandingLayout
        content={
          <Row style={styles.container}>
            {isLoading ? (
              <Loading mode="global" />
            ) : (
              <Row style={styles.content}>
                <Row style={styles.header}>
                  <TypographyText style={{ whiteSpace: 'pre-wrap' }} fontSize="1.6rem" lineHeight="1.3125em">
                    {`안녕하세요. ${user?.username ?? ''}님.\n예진에 돌아오신 것을 환영합니다.`}
                  </TypographyText>
                </Row>
                <Row style={styles.infoSection}>
                  <Row style={styles.infoHeader}>
                    <TypographyText fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>
                      {'개인 정보'}
                    </TypographyText>
                  </Row>
                  <UserInfoRow label="이메일" value={user?.email} />
                  <UserInfoRow label="이름" value={user?.username} />
                  {user?.licenseNumber && <UserInfoRow label="면허번호" value={user?.licenseNumber} />}
                  {user?.phone && (
                    <UserInfoRow
                      label="전화번호"
                      value={user?.phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) ?? '-'}
                    />
                  )}
                </Row>
                <Row style={styles.buttonSection}>
                  {isDoctor ? (
                    <ActionButton
                      onClick={() => navigate('/clinic/create')}
                      text="새로운 한의원 생성하기"
                      primary
                    />
                  ) : (
                    <ActionButton
                      onClick={() => setIsOpenLicenseRegistrationModal(true)}
                      text={
                        user?.licensePendingReviewFlag
                          ? '면허 등록 대기중'
                          : '면허 등록(등록 후 한의원 생성 가능)'
                      }
                      primary
                      disabled={user?.licensePendingReviewFlag}
                    />
                  )}
                  <ActionButton onClick={signOutHandler} text="로그아웃" />
                </Row>
                <Row style={styles.withdrawalSection} onClick={() => setIsOpenWithdrawalModal(true)}>
                  <Row style={styles.withdrawalText}>{'예진 탈퇴하기'}</Row>
                </Row>
              </Row>
            )}
          </Row>
        }
      />
    </>
  );
};

export default observer(SignInWithoutClinic);
