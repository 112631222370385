/* eslint-disable array-callback-return */
import { makeAutoObservable, runInAction } from 'mobx';
import { CreateSurveyInput, Survey, UpdateSurveyInput } from '../API';
import SurveyRepository from '../repository/SurveyRepository';
import { codeMap } from '../utils/code';
import { RootStore } from './RootStore';
import moment from 'moment';
var _ = require('lodash');

const codeArray = Array.from(codeMap, ([k]) => k);

export interface Symptom {
  code: string;
  value: string[] | object | number;
  desc: string | undefined;
}

export interface SymptomProps {
  category: string;
  symptom: string;
  symptomCode: string;
  type: string;
  value: { onset: number; scale: number } | { scale: number };
  desc: string | undefined;
}

export class SurveyStore {
  rootStore: RootStore;
  loading?: boolean = false;
  survey: Survey;
  rawSurvey?: string;
  surveys?: Survey[];
  invalidOnsetAdminSurveyCodes?: Set<string> = new Set<string>(); // 설문 직접 입력/수정 과정에서 필수 응답이 누락된 설문 코드 목록
  invalidScaleAdminSurveyCodes?: Set<string> = new Set<string>(); // 설문 직접 입력/수정 과정에서 필수 응답이 누락된 설문 코드 목록
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
  // 보여줄 증상들을 걸러낼 함수
  selectSurvey = (rawSurvey: string) => {
    // console.log(rawSurvey);
    if (rawSurvey === 'none') {
      return [];
    }
    if (!rawSurvey) {
      return [];
    }
    const survey = JSON.parse(rawSurvey);
    const result = survey.reduce((a, res: any) => {
      const surveyData = codeMap.get(res.code);
      if (!surveyData) {
        return a;
      }
      if (res.desc) {
        // 주관식 답이 존재하면 이유 불문 보여줌
        return [...a, res];
      } else if (surveyData.selectType === 'NumberInput') {
        return [...a, res];
      } else if (typeof res.value === 'number' && ![0, -1].includes(res.value)) {
        // 응답값이 숫자인 경우 설문 노출 여부 판단
        //@ts-ignore
        const targetAnswer = surveyData?.answers?.find((e) => e.value === res.value) ?? {};
        if (!_.isEmpty(targetAnswer?.['symptomCode'])) {
          return [...a, res];
        } else {
          return a;
        }
      } else if (_.isArray(res.value)) {
        // 응답 값이 숫자 배열인 경우 설문 노출 여부 판단
        const refinedValue = (res.value as Array<string | number>).filter(
          (e) => typeof e === 'number' && ![-1, 0].includes(e),
        );
        let flag = false;
        refinedValue.forEach((r) => {
          //@ts-ignore
          const targetAnswer = surveyData?.answers?.find((e) => e.value === r);
          if (!_.isEmpty(targetAnswer?.symptomCode)) {
            flag = true;
            return false; // forEach 반복문 종료
          }
        });
        if (flag) {
          return [...a, res];
        } else {
          return a;
        }
      } else if (typeof res.value === 'string') {
        if (moment(res.value).isValid()) {
          // 응답 값이 날짜인 경우 설문 노출 여부 판단
          return [...a, res];
        } else {
          return a;
        }
      } else if (_.isObject(res.value)) {
        // 응답 포맷 상 object 형태의 응답은 scale, onset, onset&scale case만 존재
        return [...a, res];
      } else {
        return a;
      }
    }, []);

    // console.log(codeArray);
    result.sort(
      (a, b) =>
        codeArray.findIndex((e: string) => e === a.code!) - codeArray.findIndex((e: string) => e === b.code!),
    );
    return result;
  };

  surveyReset = () => {
    this.survey = undefined;
    this.surveys = [];
    this.rootStore.feedbackStore.symptoms = [];
  };

  resetSurveys = () => {
    this.surveys = [];
  };

  setSurveyData = (survey: Survey) => {
    const { feedbacks = JSON.stringify([]), isFeedback, sendCount } = survey;
    this.rawSurvey = survey?.symptoms!;
    if (!feedbacks || feedbacks === 'none') {
      this.rootStore.feedbackStore.symptoms = [];
    } else {
      this.rootStore.feedbackStore.symptoms = JSON.parse(feedbacks || JSON.stringify([]));
    }
    if (typeof isFeedback === 'boolean') {
      this.rootStore.feedbackStore.isFeedback = isFeedback;
    }
    if (typeof sendCount === 'number') {
      this.rootStore.feedbackStore.setCount(sendCount);
    }
  };

  // 서버에서 설문 가져옴
  getSurvey = async (surveyID: string) => {
    this.rootStore.feedbackStore.symptoms = [];
    this.rawSurvey = undefined;
    const survey = (await SurveyRepository.getSurvey(surveyID)).getSurvey;
    runInAction(() => {
      this.survey = survey as Survey;
      if (!_.isEmpty(this.survey)) {
        this.setSurveyData(this.survey);
      }
      // console.log(JSON.stringify(survey));
    });
  };

  getCategories = (rawSurveys: any[]) => {
    const categorySet = new Set<string>();
    rawSurveys.map((item) => {
      categorySet.add(codeMap.get(item.code)?.label!);
    });
    return [...categorySet];
  };

  getCategoriesByFeedback = (rawSurveys: any[]) => {
    const categorySet = new Set<string>();
    rawSurveys.map((item) => {
      if (
        codeMap.get(item.code)?.selectType === 'Scale' ||
        codeMap.get(item.code)?.selectType === 'OnsetAndScale'
      ) {
        categorySet.add(codeMap.get(item.code)?.label!);
      }
    });
    return [...categorySet];
  };

  getSelectedSymptom = () => {
    const symptoms = this.selectSurvey(this.rawSurvey!);

    return symptoms;
  };

  getSymptomCode = () => {
    const symptomCode: string[] = [];
    // this.getSelectedSymptom().map((item) => symptomCode.push(item.symptomCode));
    return symptomCode;
  };

  parsingSurvey = (survey: Survey) => {
    const arr: [] = JSON.parse(survey.symptoms!);
    return arr.map((item) => item as Symptom);
  };

  getSelectedSurvey = (survey: Survey) => {
    const symptoms = this.parsingSurvey(survey);

    const selectedSymptom: SymptomProps[] = [];

    symptoms?.map((item) => {
      const temp = codeMap.get(item.code);
      if (temp?.selectType === 'Scale') {
        selectedSymptom.push({
          category: temp.label,
          symptom: temp.desc[0],
          symptomCode: temp.symptomCode![0],
          type: temp.selectType,
          //@ts-ignore
          value: { scale: item.value.scale },
        } as SymptomProps);
      } else if (temp?.selectType === 'OnsetAndScale') {
        selectedSymptom.push({
          category: temp.label,
          symptom: temp.desc[0],
          symptomCode: temp.symptomCode![0],
          type: temp.selectType,
          // @ts-ignore
          value: { onset: item.value.onset, scale: item.value.scale },
        } as SymptomProps);
      }
    });
    return selectedSymptom;
  };

  getSelectedSurveyCategoris = (selectedSymptom: SymptomProps[]) => {
    const categorySet = new Set<string>();
    selectedSymptom.map((symptom) => categorySet.add(symptom.category));
    return [...categorySet];
  };

  createSurvey = async (survey: CreateSurveyInput) => {
    return await SurveyRepository.createSurvey(survey);
  };

  updateSurvey = async (params: UpdateSurveyInput, refreshFlag = false) => {
    await SurveyRepository.updateSurvey(params);
    if (refreshFlag) {
      await this.getSurvey(params.id);
    }
  };

  // invalidOnsetAdminSurveyCodes에 설문 코드를 추가하는 함수
  addInvalidOnsetAdminSurveyCodes = (code: string) => {
    this.invalidOnsetAdminSurveyCodes.add(code);
  };

  // invalidOnsetAdminSurveyCodes에 설문 코드를 삭제하는 함수
  deleteInvalidOnsetAdminSurveyCodes = (code: string) => {
    this.invalidOnsetAdminSurveyCodes.delete(code);
  };

  // invalidScaleAdminSurveyCodes에 설문 코드를 추가하는 함수
  addInvalidScaleAdminSurveyCodes = (code: string) => {
    this.invalidScaleAdminSurveyCodes.add(code);
  };

  // invalidScaleAdminSurveyCodes에 설문 코드를 삭제하는 함수
  deleteInvalidScaleAdminSurveyCodes = (code: string) => {
    this.invalidScaleAdminSurveyCodes.delete(code);
  };

  // invalidAdminSurveyCodes를 초기화하는 함수
  clearInvalidAdminSurveyCodes = () => {
    this.invalidOnsetAdminSurveyCodes.clear();
    this.invalidScaleAdminSurveyCodes.clear();
  };
}
