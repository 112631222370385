import styled from '@emotion/styled';
import { PageHeader as APageHeader } from 'antd';

export const PageHeader = styled(APageHeader)`
  padding: 0.75rem 1.75rem 0 1.75rem;

  &.ant-page-header.has-breadcrumb {
    padding-top: 0.8rem;
  }

  > .ant-page-header-heading {
    height: 1.05rem;
    margin-top: unset;

    > .ant-page-header-heading-extra {
      position: relative;
      top: -1rem;
    }
  }
`;
