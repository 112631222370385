import React, { memo } from 'react';
import { Col, Row, TypographyText } from 'components/UI/atoms';

const CompanyInfo = ({ mode }: { mode: 'LANDING' | 'PRESCRIPTION' | 'MOBILE_LANDING' }) => {
  let marginLogoBetweenText = '2rem';
  let logoWidth = '5rem';
  let textStyle = {};
  switch (mode) {
    case 'PRESCRIPTION':
      // 처방전용 로고는 고정 픽셀값 사용
      marginLogoBetweenText = '16px';
      logoWidth = '70px';
      textStyle = {
        fontSize: '16px',
        color: '#afafaf',
        whiteSpace: 'initial',
        lineHeight: '19.2px',
      };
      break;
    case 'MOBILE_LANDING':
      marginLogoBetweenText = '1rem';
      logoWidth = '3.4rem';
      textStyle = {
        fontSize: '0.75rem',
        color: '#69737D',
        whiteSpace: 'initial',
        lineHeight: '1.05rem',
      };
      break;
  }

  return (
    <>
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Col
          style={{
            display: 'flex',
          }}
        >
          <img
            style={{ width: logoWidth }}
            src={`${process.env.PUBLIC_URL}/img/logo_black.webp`}
            alt="yejin-footer-logo"
          />
        </Col>
        {mode === 'LANDING' && (
          <Col style={{ cursor: 'pointer' }} onClick={() => window.open('/policy', '_blank')}>
            <TypographyText yjTypeTypographyText="landing-page-footer" whiteSpace="pre-wrap" fontWeight={700}>
              {`이용약관 및 개인정보 취급방침`}
            </TypographyText>
          </Col>
        )}
      </Row>
      <Col style={{ marginTop: `${marginLogoBetweenText}` }}>
        <TypographyText
          yjTypeTypographyText="landing-page-footer"
          whiteSpace="pre-wrap"
          wordBreak="keep-all"
          style={{ ...textStyle }}
        >
          {`헬리큐어(주) | 대표이사: 조성옥 | 소재지 : 서울특별시 동대문구 회기로 117-3, 지역열린동 4층 404-15호 | 전자우편: healicure@gmail.com | 연락처: 070-7782-7672 | 고객센터: 카카오톡 @yejinclinic
사업자등록번호: 886-86-02390 | 통신판매번호 : 2023-서울성북-0098 | 전문, 과학 및 기술서비스업 | 개인정보보호책임자: 조성옥 healicure@gmail.com
Copyright 헬리큐어 All Rights Reserved.`}
        </TypographyText>
      </Col>
    </>
  );
};

export default memo(CompanyInfo);
