import LandingLayout from 'components/templates/LandingLayout';
import { useNavigate } from 'react-router-dom';

import { TypographyText, Row, Col, Button } from 'components/UI/atoms';
const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <LandingLayout
      content={
        <Row
          style={{
            paddingTop: '8.65rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1.25rem',
              textAlign: 'center',
            }}
          >
            <Row>
              <TypographyText
                style={{
                  fontSize: '1.6rem',
                  lineHeight: '2.1rem',
                  color: '#000000',
                  whiteSpace: 'pre',
                }}
              >
                {`해당 페이지를 찾을 수 없습니다.`}
              </TypographyText>
            </Row>
            <Row>
              <TypographyText
                style={{ fontSize: '1rem', whiteSpace: 'pre', lineHeight: '1.2rem', color: '#afafaf' }}
              >
                {`찾으려는 페이지의 주소가 정확한지 다시 확인해주세요.
아래 버튼을 클릭하시면 예진 홈페이지로 이동합니다.`}
              </TypographyText>
            </Row>
          </Col>
          <Row style={{ marginTop: '2rem' }}>
            <Button
              style={{
                width: '13.9rem',
                height: '3.2rem',
                border: 'none',
                background: '#1c252c',
                color: '#ffffff',
                fontSize: '1.2rem',
                lineHeight: '1.3rem',
                letterSpacing: '0.005em',
                fontWeight: '700',
                cursor: 'pointer',
                borderRadius: '3px',
              }}
              onClick={() => navigate('/')}
            >
              <Col yjTypeCol="center">예진 홈 바로가기</Col>
            </Button>
          </Row>
        </Row>
      }
    />
  );
};

export default NotFoundPage;
