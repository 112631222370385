import { Table, TableBody, TableCell, TableHead, TableRow, TableFoot, Loader } from '@aws-amplify/ui-react';
import _ from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { css } from '@emotion/css';
import { TypographyText, Row, Col, SearchInput, PlusButton } from 'components/UI/atoms';
import { TooltipCard } from 'components/UI/molecules';
import { useStores } from 'stores/Context';
import { prescriptionItemLimit } from 'constant';

const CommonPrescriptionOneColumnTable = ({
  thName,
  thStyle = {},
  items,
  emptyTextPrefixIcon,
  emptyText,
  height = '100%',
  loading,
  searchComponentFlag,
  searchInputPlaceholder,
  searchComponentFunc = () => {},
  tabList,
  currentTab,
  tabFunc = () => {},
  defaultSearchValue = '',
  herbFilterArr = [],
  setSelectedHerbs = () => {},
  scoringTypeprescription, // 처방 추천 UI에서 사용
  zeroScoringTypeprescription, // 처방 추천 UI에서 사용
}: {
  thName: string;
  thStyle?: any;
  items: any;
  emptyTextPrefixIcon?: string;
  emptyText: string;
  height?: string;
  loading?: boolean;
  searchComponentFlag?: boolean;
  searchInputPlaceholder?: string;
  searchComponentFunc?: any;
  tabList?: string[];
  currentTab?: string;
  tabFunc?: any;
  defaultSearchValue?: string;
  herbFilterArr?: string[];
  setSelectedHerbs?: any;
  scoringTypeprescription?: any[];
  zeroScoringTypeprescription?: any[];
}) => {
  const { prescriptionStore } = useStores();
  const [tooltipMode, setTooltipMode] = useState<string>('');
  const tabFlag = !_.isEmpty(tabList) && currentTab;

  const tooltipMap = useMemo(() => {
    return new Map([
      [
        '약재',
        {
          desc: [
            `네모칸 안의 '추천점수'는 환자 설문과 
한의사의 진단을 반영하여 도출된 점수입니다.

`,
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <Row style={{ display: 'flex', alignItems: 'center', columnGap: '0.25rem' }}>
                <Col>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/recommanded-herb-empty-icon.webp`}
                    style={{ width: '0.55rem' }}
                    alt={'recommand-icon'}
                  />
                </Col>
                <TypographyText
                  fontWeight={400}
                  fontSize="0.8rem"
                  lineHeight="1.2em"
                  whiteSpace="pre"
                  letterSpacing="0.01em"
                  style={{ textIndent: 0 }}
                >
                  {`아이콘 또는 약재명을 클릭하면 해당 약재가`}
                </TypographyText>
              </Row>
              <TypographyText
                fontWeight={400}
                fontSize="0.8rem"
                lineHeight="1.2em"
                whiteSpace="pre"
                letterSpacing="0.01em"
                style={{ textIndent: 0 }}
              >
                {`들어가는 방제사전을 보여드립니다.`}
              </TypographyText>
            </Row>,
          ],
          minWidth: '18.4rem',
        },
      ],
      [
        '처방',
        {
          desc: `환자 증상에 맞춰 처방을 고방, 후세방으로 
나누어 보여드립니다.
          
네모칸 안의 '추천점수'는 환자 설문과 
한의사의 진단을 반영하여 도출된 점수입니다.`,
          minWidth: '18.4rem',
        },
      ],
      [
        '약재추가',
        {
          desc: [`아이콘을 클릭하면 처방 리스트에`, `해당 약재가 추가됩니다.`],
          minWidth: '16rem',
        },
      ],
    ]);
  }, []);

  useEffect(() => {
    const tooltip = document.getElementById('common-prescription-one-column-table-tooltip');
    if (tooltipMode) {
      if (tooltip) tooltip.style.display = 'block';
    } else {
      if (tooltip) tooltip.style.display = 'none';
    }
  }, [tooltipMode]);

  return (
    <Table
      id={`common-prescription-one-column-table-${thName}`}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height,
      }}
    >
      <TooltipCard
        isOpen={tooltipMode ? true : false}
        targetComponentId={`common-prescription-one-column-table-${thName}`}
        topRem={tooltipMode === '약재추가' ? -3.75 : -6.63}
        leftRem={tooltipMode === '약재추가' ? undefined : 0}
        rightRem={tooltipMode === '약재추가' ? 0 : undefined}
        description={tooltipMap.get(tooltipMode)?.desc ?? ''}
        minWidth={tooltipMap.get(tooltipMode)?.minWidth ?? '18.4rem'}
        prefixIcon={
          tooltipMode === '약재추가' ? (
            <PlusButton
              onClick={() => {}}
              style={{
                cursor: 'default',
                width: '0.8rem',
                height: '0.8rem',
                marginRight: '0.2rem',
              }}
            />
          ) : (
            <></>
          )
        }
      />
      <TableHead
        style={{
          position: 'sticky',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          top: 0,
          zIndex: 0,
        }}
      >
        <TableRow style={{ display: 'flex', width: '100%', background: '#f9f9f9' }}>
          <TableCell
            as="th"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: 0,
              paddingRight: '0.7rem',
              height: '1.5rem',
              textIndent: '0.75rem',
              borderTop: '2px solid #E5E5E5',
              ...thStyle,
            }}
          >
            <Col
              style={{
                display: 'flex',
                columnGap: '0.25rem',
                alignItems: 'center',
              }}
            >
              <TypographyText yjTypeTypographyText="symptom-table-head" fontSize="0.8rem" fontWeight={700}>
                {thName}
              </TypographyText>
              {['약재', '처방'].includes(thName) && (
                <Col
                  onMouseOver={() => setTooltipMode(thName)}
                  onMouseOut={() => setTooltipMode('')}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/question-icon-white.webp`}
                    style={{ width: '0.7rem', cursor: 'pointer' }}
                    alt="question-icon"
                  />
                </Col>
              )}
            </Col>
            {thName === '약재' && (
              <Col
                onMouseOver={() => setTooltipMode('약재추가')}
                onMouseOut={() => setTooltipMode('')}
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/question-icon-white.webp`}
                  style={{ width: '0.7rem', cursor: 'pointer' }}
                  alt="question-icon"
                />
              </Col>
            )}
          </TableCell>
        </TableRow>
        {searchComponentFlag && (
          <TableRow>
            <TableCell
              as="th"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                padding: 0,
                paddingTop: '0.25rem',
                paddingBottom: '0.15rem',
                border: 'none',
                background: '#ffffff',
              }}
            >
              <SearchInput
                searchFunc={searchComponentFunc}
                buttonText="검색"
                placeholder={searchInputPlaceholder}
                columnGap="0.25rem"
                buttonWidth="4.2rem"
                defaultSearchValue={defaultSearchValue}
              />
            </TableCell>
          </TableRow>
        )}
        {!_.isEmpty(herbFilterArr) && (
          <TableRow
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: '0.5rem',
              rowGap: '0.25rem',
              width: '100%',
              background: '#ffffff',
              paddingTop: '0.7rem',
              paddingBottom: '0.3rem',
            }}
          >
            {herbFilterArr.map((herbCode) => {
              return (
                <button
                  style={{
                    height: '1.65rem',
                    paddingLeft: '0.75rem',
                    paddingRight: '0.75rem',
                    display: 'flex',
                    columnGap: '0.3rem',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #FFCE21',
                    borderRadius: '100px',
                    color: '#1d1c1d',
                    fontSize: '0.8rem',
                    whiteSpace: 'nowrap',
                    cursor: 'default',
                  }}
                >
                  {prescriptionStore.yejinHerbForSearchByProcessingCode.get(herbCode).koreanName}
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexShrink: 0,
                      cursor: 'pointer',
                    }}
                    onClick={() => setSelectedHerbs([...herbFilterArr.filter((e) => e !== herbCode)])}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/x.svg`}
                      style={{ width: '0.6rem' }}
                      alt="x-icon"
                    />
                  </Col>
                </button>
              );
            })}
          </TableRow>
        )}
        {tabFlag && (
          <TableRow>
            <Row flex style={{ background: '#ffffff', marginBottom: thName === '처방' ? '0.25rem' : 0 }}>
              {_.map(thName === '처방' ? [...tabList, undefined] : tabList, (tabName) => {
                if (tabName === undefined) {
                  return (
                    <Col
                      style={{
                        flex: 1,
                        height: '1.75rem',
                        borderBottom: '2px solid #AFAFAF',
                      }}
                    />
                  );
                }
                const currentTabFlag = tabName === currentTab;
                let parsedTabName = tabName;
                if (thName === '처방') {
                  const currentTabName = `${tabName}${
                    loading
                      ? ''
                      : `(${
                          items?.length > prescriptionItemLimit
                            ? `${prescriptionItemLimit}+`
                            : items?.length ?? 0
                        })`
                  }`;
                  const formularCountForScoring = scoringTypeprescription?.[tabName]?.length ?? 0;
                  const formularCountForNonScoring = zeroScoringTypeprescription.length;
                  const huseCount = formularCountForScoring + formularCountForNonScoring;

                  switch (tabName) {
                    case '고유':
                    case '변증':
                    case '사상':
                      parsedTabName = currentTabFlag
                        ? currentTabName
                        : `${tabName}${
                            loading
                              ? ''
                              : `(${
                                  scoringTypeprescription?.[tabName]?.length > prescriptionItemLimit
                                    ? `${prescriptionItemLimit}+`
                                    : scoringTypeprescription?.[tabName]?.length ?? 0
                                })`
                          }`;
                      break;
                    case '고방':
                      parsedTabName = currentTabFlag
                        ? currentTabName
                        : `${tabName}${
                            loading
                              ? ''
                              : `(${
                                  formularCountForScoring > prescriptionItemLimit
                                    ? `${prescriptionItemLimit}+`
                                    : formularCountForScoring
                                })`
                          }`;
                      break;
                    case '후세방':
                      parsedTabName = currentTabFlag
                        ? currentTabName
                        : `${tabName}${
                            loading
                              ? ''
                              : `(${
                                  huseCount > prescriptionItemLimit
                                    ? `${prescriptionItemLimit}+`
                                    : `${huseCount}`
                                })`
                          }`;
                      break;
                  }
                }
                return (
                  <Col
                    style={{
                      minWidth: '4.5rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: currentTabFlag ? '0 0.7rem' : '0 0.7rem 0.1rem',
                      height: '1.75rem',
                      borderBottom: currentTabFlag ? '4px solid #346AFF' : '2px solid #AFAFAF',
                      cursor: 'pointer',
                    }}
                    onClick={() => tabFunc(tabName)}
                  >
                    <TypographyText
                      whiteSpace="pre"
                      yjTypeTypographyText="symptom-table-head"
                      fontSize="0.8rem"
                      fontWeight={currentTabFlag ? 700 : 400}
                      color={currentTabFlag ? '#346AFF' : '#AFAFAF'}
                    >
                      {parsedTabName}
                    </TypographyText>
                  </Col>
                );
              })}
            </Row>
          </TableRow>
        )}
      </TableHead>
      {loading ? (
        <Row
          style={{
            display: 'flex',
            height: '80%',
            borderTop: 'none',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
        </Row>
      ) : (
        <TableBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 0',
            height: '100%',
            overflowY: 'auto',
            width: '100%',
          }}
          className={css`
            &::-webkit-scrollbar {
              background-color: white;
              width: 0.35rem;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #ebebeb;
              border-radius: 0;
              cursor: pointer;
            }
          `}
        >
          {!_.isEmpty(items) ? (
            <>
              {(thName === '처방' ? items.slice(0, prescriptionItemLimit) : items)?.map((item: any, i) => {
                return (
                  <TableRow style={{ display: 'flex', width: '100%' }} key={`${thName}_${i}`}>
                    <TableCell
                      style={{
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        paddingTop: '0.35rem',
                        paddingBottom: '0.35rem',
                        width: '100%',
                        background: thName === '처방' && item?.data?.recommendFlag ? '#F9FBFC' : '#ffffff',
                      }}
                    >
                      {item?.component}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableFoot
                style={{
                  position: 'sticky',
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  bottom: 0,
                  height: 0,
                }}
              ></TableFoot>
            </>
          ) : (
            <TableRow style={{ display: 'flex', width: '100%', zIndex: -1, height: '100%' }}>
              <TableCell
                colSpan={5}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  border: 'none',
                  paddingTop: 'min(15% , 2rem)',
                  width: '100%',
                  height: '100%',
                }}
              >
                <div className="flex w-fit relative">
                  {emptyTextPrefixIcon && (
                    <div className="flex absolute left-0.5 top-0.6 ">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/${emptyTextPrefixIcon}.webp`}
                        style={{ height: '0.9rem', marginRight: '0.3rem' }}
                        alt="empth-prefix-icon"
                      />
                    </div>
                  )}
                  <TypographyText
                    yjTypeTypographyText="symptom-table-body"
                    color="#afafaf"
                    fontSize="0.8rem"
                    lineHeight="1.2rem"
                    whiteSpace="pre-wrap"
                    wordBreak="normal"
                    letterSpacing="-0.05rem"
                    style={{
                      textIndent: emptyTextPrefixIcon ? '1rem' : 'none',
                    }}
                  >
                    {emptyText}
                  </TypographyText>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      )}
    </Table>
  );
};

export default CommonPrescriptionOneColumnTable;
