/* eslint-disable eqeqeq */
import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { Form } from 'antd';
import _ from 'lodash';

import { TypographyText, Divider, Row } from 'components/UI/atoms';
import { codeMap, insuranceSurveyCodes } from 'utils/code';

import Radio from './Radio';
import Check from './Check';
import Date from './Date';
import NumberInput from './NumberInput';

const { Item: FormItem } = Form;

function SurveyDefaultForm({
  scaleOnsetKeys,
  recentChangedField,
  surveyRef,
  i,
  form,
  k,
  v,
  selectType,
  treatmentMode,
}: {
  scaleOnsetKeys: string[];
  recentChangedField: React.MutableRefObject<any>;
  surveyRef: React.MutableRefObject<any>;
  i: number;
  form: any;
  k: string;
  v: any;
  selectType: string;
  treatmentMode: string;
}): ReactElement {
  const [isDisabled, setIsDisabled] = useState(true);
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (k?.length === 1) {
      setIsDisabled(false);
    } else if (k === 'optionalSurveys') {
      setIsDisabled(true);
    } else {
      // 최상위 설문이 아닌 경우
      const pk = k.slice(0, -1); // parent key
      const pv = form.getFieldValue(pk);
      if (!pv || pv == 0) {
        // 부모 설문 응답 값이 없거나 0인 경우
        setIsDisabled(true);
      } else {
        const targetArr: any = _.filter(codeMap.get(pk)?.answers, (e: any) =>
          typeof e?.next === 'string' ? e?.next === k : e?.next?.includes(k),
        );
        if (!_.isEmpty(targetArr)) {
          let disabledFlag = true;
          for (const target of targetArr) {
            const targetValue = target.value;
            if (_.isArray(pv)) {
              if (pv.includes(targetValue)) {
                disabledFlag = false;
                break;
              }
            } else {
              if (pv == targetValue) {
                disabledFlag = false;
                break;
              }
            }
          }
          setIsDisabled(disabledFlag);
        } else {
          setIsDisabled(true);
        }
      }
    }
  }, [treatmentMode, k]);

  let children = <></>;
  switch (selectType) {
    case 'Radio':
      children = (
        <Radio
          scaleOnsetKeys={scaleOnsetKeys}
          form={form}
          k={k}
          v={v}
          recentChangedField={recentChangedField}
        />
      );
      break;
    case 'Date':
      children = <Date form={form} k={k} recentChangedField={recentChangedField} />;
      break;
    case 'NumberInput':
      children = <NumberInput form={form} k={k} recentChangedField={recentChangedField} />;
      break;
    case 'Check':
      children = (
        <Check
          scaleOnsetKeys={scaleOnsetKeys}
          form={form}
          k={k}
          v={v}
          recentChangedField={recentChangedField}
        />
      );
      break;
  }

  return (
    <FormItem
      noStyle
      name={k}
      shouldUpdate={(p, c) => {
        // const rk = recentChangedField.current; // 최근 변경된 값의 key
        if (k.length === 1) {
          setIsDisabled(false);
        } else if (k === 'optionalSurveys') {
          setIsDisabled(true);
        } else {
          // 최상위 설문이 아닌 경우
          const pk = k.slice(0, -1); // parent key
          const pv = form.getFieldValue(pk);

          if (!c[pk] || c[pk] == 0) {
            // 부모 설문 응답 값이 없거나 0인 경우
            setIsDisabled(true);
          } else {
            const targetArr: any = _.filter(codeMap.get(pk)?.answers, (e: any) =>
              typeof e?.next === 'string' ? e?.next === k : e?.next?.includes(k),
            );
            if (!_.isEmpty(targetArr)) {
              let disabledFlag = true;
              for (const target of targetArr) {
                const targetValue = target.value;
                if (_.isArray(pv)) {
                  if (pv.includes(targetValue)) {
                    disabledFlag = false;
                    break;
                  }
                } else {
                  if (pv == targetValue) {
                    disabledFlag = false;
                    break;
                  }
                }
              }
              setIsDisabled(disabledFlag);
            } else {
              setIsDisabled(true);
            }
          }
        }
        forceUpdate();
        return true;
      }}
    >
      {!isDisabled ? ( // disabled가 아닌 경우만 렌더링
        <div
          className="survey-section"
          ref={(r) => {
            surveyRef.current[i + 1] = r;
          }}
        >
          {!insuranceSurveyCodes.has(k) && ( // 첩건 최상위 설문은 노출하지 않음
            <Row style={{ paddingLeft: 0, marginTop: '3rem' }}>
              <Row style={{ display: 'flex', columnGap: '0.5rem', marginBottom: '1.25rem' }}>
                <TypographyText yjTypeTypographyText="survey-main-question">{`<${v!.label}>`}</TypographyText>
                <TypographyText yjTypeTypographyText="survey-main-question" letterSpacing="0.01em">
                  {`${v!.desc.join(' ').replace(/[<>]/g, '')}`}
                </TypographyText>
              </Row>
              {children}
            </Row>
          )}
        </div>
      ) : (
        <></>
      )}
    </FormItem>
  );
}

export default SurveyDefaultForm;
