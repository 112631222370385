import React, { useEffect } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
// import { useStores } from 'stores/Context';
import MarketingPolicy from './MarketingPolicy';
// import PolicyMenu from './PolicyMenu';
import PrivacyPolicy from './PrivacyPolicy';
import ServicePolicy from './ServicePolicy';

const PolicyDetail = () => {
  const { index } = useParams();
  useEffect(() => {
    switch (index) {
      case '1':
        document.getElementById('privacy-policy-block').scrollIntoView({
          block: 'start',
        });
        break;
      case '2':
        document.getElementById('marketing-policy-block').scrollIntoView({
          block: 'start',
        });
        break;
      default:
        break;
    }
  }, [index]);

  // let menu = <PolicyMenu value={1} />;
  // let content = <></>;
  // let title = '';
  // const { signStore } = useStores();
  // let value = 0;
  // if (!sessionStorage.getItem('policy')) {
  //   value = 1;
  //   signStore.setPolicyDetailValue(value);
  // } else {
  //   value = +sessionStorage.getItem('policy');
  //   signStore.setPolicyDetailValue(value);
  // }
  // switch (signStore.policyDetailValue) {
  //   case 1:
  //     menu = <PolicyMenu value={1} />;
  //     title = '예진 병원 회원 통합 이용약관';
  //     content = <></>;
  //     break;
  //   case 2:
  //     menu = <PolicyMenu value={2} />;
  //     title = '병원 회원 개인정보처리방침';
  //     content = <></>;
  //     break;
  //   case 3:
  //     //   menu = <PolicyMenu value={3} />;
  //     //   title = "제 3자 정보 제공 동의 ";
  //     //   content = <></>;
  //     //   break;
  //     // case 4:
  //     menu = <PolicyMenu value={4} />;
  //     title = '마케팅 정보 활용 동의';
  //     content = <></>;
  //     break;
  //   default:
  //     menu = <PolicyMenu value={1} />;
  //     content = <></>;
  //     break;
  // }

  return (
    <Flex padding={'2rem 12rem'} direction={'column'} width={'100%'} gap={0}>
      <ServicePolicy />
      <PrivacyPolicy />
      <MarketingPolicy />
    </Flex>
  );
};

export default observer(PolicyDetail);
