import React from 'react';

import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Divider as ADivider, DividerProps } from 'antd';
import { tuple } from 'antd/lib/_util/type';

export const tYzTypeDivider = tuple(
  'BLACK',
  'GRAY',
  'GRAY_DETAIL',
  'TRANSPARENT',
  'TRANSPARENT_LITTLE_SPACE',
  'LINE',
);

type TYzTypeDivider = typeof tYzTypeDivider[number] | undefined;

type THandleCheckDividerTypeParams = {
  yjTypeDivider?: TYzTypeDivider;
};

export interface IDividerParams extends DividerProps {
  yjTypeDivider?: TYzTypeDivider;
  margin?: number;
  marginRem?: number;
}

const handleCheckDividerType = ({ yjTypeDivider }: THandleCheckDividerTypeParams) => {
  switch (yjTypeDivider) {
    case 'TRANSPARENT':
      return css`
        border: 0;
        border-color: transparent !important;
        background-color: transparent !important;
        margin: 1rem 0 1rem 0;
      `;
    case 'TRANSPARENT_LITTLE_SPACE':
      return css`
        border: 0;
        border-color: transparent !important;
        background-color: transparent !important;
        margin: 0.5rem 0 0.5rem 0;
      `;
    case 'LINE':
      return css`
        border: 1px solid #e5e5e5;
      `;
    default:
      return css``;
  }
};

const StyledDivider = styled(ADivider, {
  shouldForwardProp: isPropValid,
})<IDividerParams>`
  height: 1px;
  ${({ yjTypeDivider }) => handleCheckDividerType({ yjTypeDivider })}
  ${({ margin }) =>
    margin &&
    css`
      margin-top: ${margin}px !important;
      margin-bottom: ${margin}px !important;
    `}
  ${({ marginRem }) =>
    marginRem &&
    css`
      margin-top: ${marginRem}rem !important;
      margin-bottom: ${marginRem}rem !important;
    `}
`;

export const Divider = ({ ...rest }: IDividerParams) => <StyledDivider {...rest} />;
