import { observer } from 'mobx-react';

import { Row, TypographyText } from 'components/UI/atoms';

const ClinicTab = ({ tabList, selectedTab, setSelectedTab }) => {
  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        flex: '1 1 0',
      }}
    >
      <Row
        style={{
          display: 'flex',
          columnGap: '2rem',
          borderBottom: '1px solid #e5e5e5',
        }}
      >
        {tabList.map((tab, index) => {
          const stringIndex = String(index);

          return (
            <div
              key={stringIndex}
              style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                height: '2.2rem',
                cursor: 'pointer',
                borderBottom: `2px solid ${selectedTab === stringIndex ? '#1c252c' : 'transparent'}`,
              }}
              onClick={() => {
                setSelectedTab(stringIndex);
                sessionStorage.setItem('settingTab', stringIndex);
              }}
            >
              <TypographyText
                color={selectedTab === stringIndex ? '#1c252c' : '#AFAFAF'}
                fontSize="1.2rem"
                lineHeight="1.2em"
                fontWeight={700}
              >
                {tab.tabName}
              </TypographyText>
            </div>
          );
        })}
      </Row>
      {tabList[Number(selectedTab)].content}
    </Row>
  );
};

export default observer(ClinicTab);
