import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Checkbox as ACheckbox, CheckboxProps } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { tuple } from 'antd/lib/_util/type';

const { Group: ACheckboxGroup } = ACheckbox;

export const tYjTypeCheckbox = tuple('survey');

export interface ICheckboxParams extends CheckboxProps {
  yjTypeCheckbox?: typeof tYjTypeCheckbox[number] | undefined;
}

export interface ICheckboxGroupParams extends CheckboxGroupProps {}

const handleCheckboxWithType = ({ yjTypeCheckbox }: ICheckboxParams) => {
  switch (yjTypeCheckbox) {
    case 'survey':
      return css`
        > span > input {
          margin: 0.8rem 1rem;
          width: 1.4rem;
          height: 1.4rem;
          :hover {
            cursor: pointer !important;
          }
        }
      `;
    default:
      return css``;
  }
};

export const StyledCheckbox = styled(ACheckbox)<ICheckboxParams>`
  ${({ yjTypeCheckbox }) => handleCheckboxWithType({ yjTypeCheckbox })}
`;

export const Checkbox = ({ children, ...rest }: ICheckboxParams) => (
  <StyledCheckbox {...rest}>{children}</StyledCheckbox>
);

const StyledCheckboxGroup = styled(ACheckboxGroup)`
  width: 100%;
  border: 0;
`;

export const CheckboxGroup = ({ children, ...rest }: ICheckboxGroupParams) => (
  <StyledCheckboxGroup {...rest}>{children}</StyledCheckboxGroup>
);
