import React, { useMemo, memo } from 'react';
import ProgressItem from '../../molecules/treatment/ProgressItem';

interface ProgressParams {
  progress: number;
}

const TreatmentProgress = ({ progress }: ProgressParams) => {
  const treatmentTabNames = useMemo(() => {
    return ['1. 증상', '2. 변증', '3. 처방'];
  }, []);

  return (
    <>
      {treatmentTabNames.map((tabName, i) => (
        <ProgressItem key={`progress_${i}`} name={tabName} active={progress >= i} />
      ))}
    </>
  );
};

export default memo(TreatmentProgress);
