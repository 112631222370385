/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';

import { TypographyText, Row } from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import { Loading, StatisticCard } from 'components/UI/molecules';

const Subscription = () => {
  const [treatmentCnt, setTreatmentCnt] = useState<number>(0);
  const [kakaoCnt, setKakaoCnt] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { clientStore, clinicStore, roleStore } = useStores();

  const refreshData = useCallback(async () => {
    try {
      setIsLoading(true);
      const statRes = await clinicStore.getStatistics(clientStore.metaData?.userRole.clinicID);
      if (statRes?.totalNumberOfTreatment > -1) {
        setTreatmentCnt(statRes.totalNumberOfTreatment);
      }
      if (statRes?.totalNumberOfKaKaoSent > -1) {
        setKakaoCnt(statRes.totalNumberOfKaKaoSent);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const startDay = useMemo(() => moment().startOf('month').format('YY/MM/DD'), []);
  const endDay = useMemo(() => moment().endOf('month').format('YY/MM/DD'), []);

  useEffect(() => {
    refreshData();
    clinicStore.getClinic(clientStore.metaData?.userRole.clinicID!);
    roleStore.getUserRole(clientStore.metaData?.userRole.id!);
  }, []);

  return (
    <>
      {_.isEmpty(clinicStore.clinic) || isLoading ? (
        <Loading />
      ) : (
        <>
          <Row
            className="line-height-reset"
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 6.7rem)',
              width: 'calc(100% + 4.8rem)',
              marginLeft: '-2.4rem',
              padding: '1rem 2.4rem 1.5rem 2.4rem',
              background: '#FFFFFF',
              position: 'relative',
            }}
          >
            <Row
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderTop: '1px solid #e5e5e5',
                width: '100%',
              }}
            />
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 0,
              }}
            >
              <Row style={{ display: 'flex', marginBottom: '1rem' }}>
                <TypographyText fontSize="1rem" lineHeight="1.2em">
                  {`${startDay} ~ ${endDay}`}
                </TypographyText>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  columnGap: '1.25rem',
                  justifyContent: 'space-between',
                }}
              >
                <StatisticCard
                  title="전체 환자 수"
                  value={`${clinicStore?.clinic?.numberOfPatients || 0}명`}
                />
                <StatisticCard title="진료 수" value={`${treatmentCnt}건`} />
                <StatisticCard title="카카오 링크 전송 수" value={`${kakaoCnt}건`} />
                <StatisticCard
                  title="구성원(스탭) 수"
                  value={`${clinicStore?.clinic?.numberOfSteps || 0}명`}
                />
              </Row>
            </Row>
            <Row style={{ display: 'flex', marginTop: '1.6rem' }}>
              <TypographyText fontSize="0.8rem" lineHeight="1.2em" color={'#afafaf'}>
                {'* 카카오링크 전송 수 일 1,000 건을 초과할 경우, 별도 요금이 청구될 수 있습니다.'}
              </TypographyText>
            </Row>
          </Row>
        </>
      )}
    </>
  );
};

export default observer(Subscription);
