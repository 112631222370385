/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { ReactElement, useState, useCallback } from 'react';
import { Form } from 'antd';
import _ from 'lodash';

import { TypographyText, Row, Col, Divider, TextArea } from 'components/UI/atoms';
import { codeMap } from 'utils/code';

import Onset from './Onset';
import Scale from './Scale';

const { Item: FormItem } = Form;

function Check({
  form,
  k,
  v,
  recentChangedField,
  scaleOnsetKeys,
}: {
  form: any;
  k: string;
  v: any;
  recentChangedField: React.MutableRefObject<any>;
  scaleOnsetKeys: string[];
}): ReactElement {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const handleChange = useCallback(async (checked, fv, value) => {
    recentChangedField.current = k;
    if (checked) {
      // 선택됐던 항목 (해제 해야함)
      if (fv == 0) {
        // 아무 증상 없음 항목
        // 아무것도 눌리지 않은 상태처럼 해당 설문 초기화 해야함
        await form.setFieldsValue({ [k]: undefined });
      } else {
        // 아무 증상 없음 외의 유의미한 항목을 해제 하는 경우
        const exceptArr = fv.filter((e: number) => e !== value);
        await form.setFieldsValue({
          [k]: exceptArr.length ? exceptArr : undefined,
        });
      }
    } else if (value == 0) {
      // 아무 증상 없음 항목
      await form.setFieldsValue({ [k]: 0 });
    } else if (fv == 0 || fv == undefined) {
      // 유의미한 응답이고 기존 설문 응답 값이 0이거나 없는 경우
      await form.setFieldsValue({ [k]: [value] });
    } else {
      // 유의미한 응답이고 기존 설문 응답 값이 유의미한 value들의 배열로 이뤄진 경우
      await form.setFieldsValue({ [k]: [...fv, value].sort() });
    }
    forceUpdate();
  }, []);

  let twoColumnsInRowFlag = false;
  let span = 24;
  const fv = form.getFieldValue(k);

  return (
    <Row yjTypeRow="survey-answer" style={{ marginBottom: '-0.8rem' }}>
      {v.answers?.map(
        (
          { value, label, next }: { value: string | number; label: string; next: string | string[] },
          i: number,
        ) => {
          const checked = _.isArray(fv) ? fv?.includes(value) : fv == value;
          let additionalSurveyFlag = false;
          let additionalSurvey;
          if (
            checked &&
            (typeof next === 'string'
              ? _.includes(scaleOnsetKeys, next)
              : _.some(next, (e) => _.includes(scaleOnsetKeys, e)))
          ) {
            additionalSurveyFlag = true;
            additionalSurvey = codeMap.get(String(value));
          }
          return (
            <Row key={`${k}_${i}`} style={{ marginTop: i === 0 ? '-0.8rem' : 0 }}>
              <Col
                onClick={() => handleChange(checked, fv, value)}
                span={span}
                twoColumnsInRowFlag={twoColumnsInRowFlag}
                yjTypeCol={checked ? 'selected-check-button' : 'check-button'}
              >
                <Row yjTypeRow="flex">
                  <Col yjTypeCol={checked ? 'selected-survey-checkbox-wrapper' : 'survey-checkbox-wrapper'}>
                    <Col yjTypeCol={checked ? 'selected-survey-checkbox' : 'survey-checkbox'}></Col>
                  </Col>
                  <Col yjTypeCol="survey-checkbox-label">
                    <TypographyText yjTypeTypographyText="basic">{label}</TypographyText>
                  </Col>
                </Row>
              </Col>
              {checked && value === -1 && (
                <FormItem noStyle name={`${k}Desc`}>
                  <TextArea
                    yjTypeTextArea="survey-desc"
                    placeholder={'현재 가장 불편하신 증상을 입력해주세요.'}
                    onChangeCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation(); //event capturing
                    }}
                    onChange={(e) => {
                      recentChangedField.current = k + 'Desc';
                      const newKey = k + 'Desc';
                      e.preventDefault();
                      e.stopPropagation(); //event capturing
                      form.setFieldsValue({ [newKey]: e.target.value });
                    }}
                  />
                </FormItem>
              )}
              {additionalSurveyFlag ? (
                <>
                  {_.includes(additionalSurvey?.selectType, 'Scale') && (
                    <FormItem name={String(value)} noStyle>
                      <Scale form={form} k={String(value)} v={v} recentChangedField={recentChangedField} />
                    </FormItem>
                  )}
                  {_.includes(additionalSurvey?.selectType, 'Onset') && (
                    <FormItem name={String(value)} noStyle>
                      <Onset form={form} k={String(value)} v={v} recentChangedField={recentChangedField} />
                    </FormItem>
                  )}
                </>
              ) : (
                <></>
              )}
            </Row>
          );
        },
      )}
    </Row>
  );
}

export default Check;
