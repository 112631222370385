import { useMemo } from 'react';
import moment from 'moment';

import { Row, Col, TypographyText } from 'components/UI/atoms';

const CommonTreatmentHistoryCard = ({
  treatmentData: t,
  selectedFlag,
  setSelectedTreatmentData,
  mode,
}: {
  treatmentData: any;
  selectedFlag: boolean;
  setSelectedTreatmentData: (t: any) => void;
  mode: 'treatmentHistoryList' | 'patientTreatmentHistoryList';
}) => {
  const patientDeletedFlag = t.patientData?.isDeleted ? true : false;
  const {
    name = '',
    birth = '',
    gender = '',
    age = '',
  } = useMemo(() => {
    if (!t) return {};

    const { name, birth, gender } = t ?? {};
    let convertedGender;
    switch (gender) {
      case 'MALE':
        convertedGender = '남';
        break;
      case 'FEMALE':
        convertedGender = '여';
        break;
      default:
        convertedGender = '성별미상';
        break;
    }
    const age = moment().diff(moment(birth), 'years');

    return {
      name,
      birth,
      gender: convertedGender,
      age,
    };
  }, [t]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.6rem',
        padding: '1.9rem 1.6rem',
        border: `1px solid ${selectedFlag ? '#1c252c' : '#e5e5e5'}`,
        boxShadow: '0px 4px 4px 0px #E5E5E580',
        borderRadius: '3px',
        cursor: 'pointer',
        background: '#ffffff',
      }}
      onClick={() => setSelectedTreatmentData(t)}
    >
      {mode === 'treatmentHistoryList' && (
        <>
          <Col
            style={{
              display: 'flex',
              alignItems: 'end',
            }}
          >
            <TypographyText fontSize="1.1rem" lineHeight="1.2em" fontWeight={700}>{`${
              patientDeletedFlag
                ? '삭제된 환자'
                : `${name} ${moment(birth).format('YY-MM-DD')} (${gender}/만 ${age}세)`
            }`}</TypographyText>
          </Col>
          <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.1rem' }}>
            <TypographyText fontSize={'0.9rem'} lineHeight="1.2em" color={'#AFAFAF'} whiteSpace="pre">
              {`${moment(t.treatmentedAt).format('YYYY/MM/DD')} 진료내역`}
            </TypographyText>
          </Row>
        </>
      )}
      {mode === 'patientTreatmentHistoryList' && (
        <TypographyText fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>{`${moment(
          t.treatmentedAt,
        ).format('YYYY/MM/DD')} 진료내역`}</TypographyText>
      )}
    </div>
  );
};

export default CommonTreatmentHistoryCard;
