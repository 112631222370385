/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import { useState, useMemo } from 'react';
import message from 'antd-message';
import { css } from '@emotion/css';
import { observer } from 'mobx-react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import PhoneInput from 'react-phone-number-input/input';
import _ from 'lodash';

import { useStores } from 'stores/Context';
import { Row, Col, TypographyText, Input, Modal, ModalType2 } from 'components/UI/atoms';
import DaumPost from 'components/UI/molecules/DaumPost';
import { emailValidationCheck, allCasePhoneValidationCheck, licenseValidationCheck } from 'utils/util';

const SignUpStep2 = ({
  signUpType,
  service,
  privacy,
  marketing,
  setIsLoading,
  email,
  setEmail,
  setSignupStep,
}: {
  signUpType: 'academy' | 'direct';
  service: boolean;
  privacy: boolean;
  marketing: boolean;
  setIsLoading: any;
  email: string;
  setEmail: any;
  setSignupStep: any;
}) => {
  const [isOpenRemoveImageModal, setIsOpenRemoveImageModal] = useState<any>();
  const [isOpenAddressModal, setIsOpenAddressModal] = useState<any>();

  // 개인 정보
  const [name, setName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [licenseNumber, setLicenseNumber] = useState<string>();
  const [image, setImage] = useState([]);

  // test
  // const [name, setName] = useState<string>('박주원');
  // const [phone, setPhone] = useState<string>('+821035101031');
  // const [licenseNumber, setLicenseNumber] = useState<string>('33333');

  // 한의원 정보
  const [clinicName, setClinicName] = useState<string>();
  const [clinicEmail, setClinicEmail] = useState<string>();
  const [clinicPhone, setClinicPhone] = useState<string>();
  const [clinicAddress, setClinicAddress] = useState(''); // 한의원 주소
  const [clinicDetailAddress, setClinicDetailAddress] = useState(''); // 한의원 상세 주소

  // test
  // const [clinicName, setClinicName] = useState<string>('박주원 한의원');
  // const [clinicEmail, setClinicEmail] = useState<string>('borooboroo625@gmail.com');
  // const [clinicPhone, setClinicPhone] = useState<string>('+821035101031');
  // 사업자, 개인 구분
  const [businessType, setBusinessType] = useState<'BUSINESS' | 'PERSONAL'>('BUSINESS');

  // error
  const [errors, setErrors] = useState<{
    imageMaxFileSize: boolean;
    alreadySignedUp: boolean;
    notAcademyEmail: boolean;
    licenseNumberValidation: boolean;
    phoneValidation: boolean;
    emailValidation: boolean;
    clinicPhoneValidation: boolean;
    clinicEmailValidation: boolean;
  }>({
    imageMaxFileSize: false,
    alreadySignedUp: false,
    notAcademyEmail: false,
    licenseNumberValidation: false,
    phoneValidation: false,
    emailValidation: false,
    clinicPhoneValidation: false,
    clinicEmailValidation: false,
  });

  const signUpHandler = async ({
    name,
    email,
    phone,
    image,
    licenseNumber,
    clinicName,
    clinicEmail,
    clinicPhone,
    service,
    privacy,
    marketing,
    signUpType,
    businessType,
  }: {
    name: string;
    email: string;
    phone: string;
    image: any[];
    licenseNumber: string;
    clinicName: string;
    clinicEmail: string;
    clinicPhone: string;
    service: boolean;
    privacy: boolean;
    marketing: boolean;
    signUpType: 'academy' | 'direct';
    businessType: 'BUSINESS' | 'PERSONAL';
  }) => {
    try {
      await setIsLoading(true);
      // 모든 유효성 검사를 통과 했음을 전제로 body 구성
      const body: any = {
        // 개인 이름, 개인 이메일, 개인 연락처는 기본으로 받는 정보
        name,
        email,
        phone: phone?.replace('+82', '0'),
        businessType, // 사업자, 개인 구분 - 한의원 생성 과정에 필요
        signUpType, // 회원 가입 유형 - 학회 회원, 비회원
        terms: [service && privacy && marketing, service, privacy, marketing],
      };
      // 비회원 가입일 때, 면허 이미지, 면허 번호 필요
      if (signUpType === 'direct') {
        body.licenseImage = image[0].dataURL.split(';base64,')[1];
        body.licenseNumber = licenseNumber;
      }
      // 사업자일 때, 한의원 이름, 한의원 이메일, 한의원 전화번호 필요
      if (businessType === 'BUSINESS') {
        body.clinicName = clinicName;
        body.clinicEmail = clinicEmail;
        body.clinicPhone = clinicPhone?.replace('+82', '0') ?? clinicPhone;
        if (clinicAddress) {
          body.clinicAddress = `${clinicAddress}${clinicDetailAddress ? `||${clinicDetailAddress}` : ''}`;
        }
      } else if (businessType === 'PERSONAL') {
        body.clinicName = name; // 개인 이름은 한의원 이름으로 쓰임
        body.clinicEmail = email; // 개인 이메일은 한의원 이메일로 쓰임
        // 개인 전화번호는 한의원 전화번호로 쓰임
        body.clinicPhone = phone?.replace('+82', '0') ?? phone;
      }

      try {
        await API.post('yejinadminrestapi', '/signup', {
          headers: {
            'Content-Type': 'application/json',
          },
          response: true,
          body,
        });
        setSignupStep('step3');
        setIsLoading(false);
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 400) {
          // 400 일 때 이미 가입된 회원
          setErrors({
            ...errors,
            alreadySignedUp: true,
          });
        } else if (error.response.status === 404) {
          // 404 일 때 학회에 등록되지 않은 이메일
          setErrors({
            ...errors,
            notAcademyEmail: true,
          });
        } else {
          console.log(error);
          message.error(`회원 가입 요청 과정에서 문제가 발생했습니다. E:${error.response.status}`, 10000);
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      message.error(`회원 가입 요청 과정에서 문제가 발생했습니다.`, 2500);
      setIsLoading(false);
    }
  };

  const submitFlag = useMemo(() => {
    let omitErrorArr = [];
    if (businessType === 'PERSONAL') {
      omitErrorArr = [...omitErrorArr, 'clinicPhoneValidation', 'clinicEmailValidation'];
    }
    const targetErrors = _.omit({ ...errors }, omitErrorArr);
    // 유효성 검사에 문제되는 것 없는지 확인
    const validationFlag = _.values(targetErrors).every((e) => e === false);

    // 어떤 경우든 꼭 입력하는 정보 - 개인 이름, 개인 이메일, 개인 번호
    let flagResult = validationFlag && !!name && !!email && !!phone;

    // 비회원 가입일 때, 면허 이미지, 면허 번호 필요
    if (signUpType === 'direct') {
      flagResult = flagResult && !!image?.length && !!licenseNumber;
    }

    // 사업자일 때, 한의원 이름, 한의원 이메일, 한의원 전화번호, 개인 전화번호 필요
    if (businessType === 'BUSINESS') {
      flagResult = flagResult && !!clinicName && !!clinicEmail && !!clinicPhone;
    }

    return flagResult;
  }, [errors, name, email, phone, clinicName, clinicEmail, clinicPhone, image, signUpType, businessType]);

  return (
    <Row style={{ display: 'flex', flexDirection: 'column' }}>
      {isOpenAddressModal && (
        <ModalType2
          isOpen={isOpenAddressModal}
          cancelMsg="닫기"
          cancelFunction={() => setIsOpenAddressModal(false)}
          content={
            <Row style={{ marginBottom: '0' }}>
              <DaumPost setIsOpenAddressModal={setIsOpenAddressModal} setAddress={setClinicAddress} />
            </Row>
          }
          contentNonePaddingFlag={true}
          width={'600px'}
          contentHeight={'445px'}
        />
      )}
      <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1.2rem' }}>
        <Row style={{ display: 'flex' }}>
          <TypographyText fontSize={`1rem`} fontWeight={700}>
            {`가입 유형 선택`}
          </TypographyText>
        </Row>
        <Row
          style={{
            display: 'flex',
            width: '100%',
            columnGap: '0.6rem',
          }}
        >
          <Col
            style={{
              padding: '1.4rem 1rem',
              display: 'flex',
              height: '4.5rem',
              flex: 1,
              border: businessType === 'BUSINESS' ? '1px solid #346AFF' : '1px solid #c4c4c4',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setBusinessType('BUSINESS')}
          >
            <Row
              style={{
                display: 'flex',
                textAlign: 'center',
              }}
            >
              <TypographyText
                color={businessType === 'BUSINESS' ? '#346AFF' : '#c4c4c4'}
                lineHeight={`1.7rem`}
                fontSize={`1.2rem`}
                whiteSpace="pre-wrap"
                wordBreak="keep-all"
              >
                {`사업자(한의원 개설자)`}
              </TypographyText>
            </Row>
          </Col>
          <Col
            style={{
              padding: '1.4rem 1rem',
              display: 'flex',
              height: '4.5rem',
              flex: 1,
              border: businessType === 'PERSONAL' ? '1px solid #346AFF' : '1px solid #c4c4c4',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setBusinessType('PERSONAL')}
          >
            <Row
              style={{
                display: 'flex',
                textAlign: 'center',
              }}
            >
              <TypographyText
                color={businessType === 'PERSONAL' ? '#346AFF' : '#c4c4c4'}
                lineHeight={`1.7rem`}
                fontSize={`1.2rem`}
                whiteSpace="pre-wrap"
                wordBreak="keep-all"
              >
                {`${signUpType === 'academy' ? '개인(일반 한의사/학생)' : '개인(일반 한의사)'}`}
              </TypographyText>
            </Row>
          </Col>
        </Row>
      </Row>
      <Row style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', rowGap: '1.2rem' }}>
        <Row style={{ display: 'flex' }}>
          <TypographyText fontSize={`1rem`} fontWeight={700}>
            {`개인 정보 (필수)`}
          </TypographyText>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.6rem',
          }}
        >
          <Input
            style={{
              height: '3.2rem',
              width: '100%',
              border: '1px solid #afafaf',
              borderRadius: '3px',
              textIndent: '0.4rem',
              fontSize: '1rem',
              outline: 'none',
            }}
            className={css`
              &::placeholder {
                color: #afafaf;
              }
            `}
            value={email}
            placeholder="아이디(이메일)"
            onChange={(e) => {
              if (emailValidationCheck(e.target.value)) {
                setErrors({
                  ...errors,
                  alreadySignedUp: false,
                  notAcademyEmail: false,
                  emailValidation: false,
                });
              } else {
                setErrors({
                  ...errors,
                  alreadySignedUp: false,
                  notAcademyEmail: false,
                  emailValidation: true,
                });
              }
              setEmail(e.target.value);
            }}
            autoComplete="off"
          />
          {!!email?.length && errors.emailValidation && (
            <Row flex style={{ marginTop: '-0.2rem' }}>
              <TypographyText yjTypeTypographyText="survey-validation">
                {'이메일 형식이 맞는지 확인해주세요.'}
              </TypographyText>
            </Row>
          )}
          {errors.alreadySignedUp && (
            <Row flex style={{ marginTop: '-0.2rem' }}>
              <TypographyText yjTypeTypographyText="survey-validation">
                {'이미 가입된 이메일 주소입니다. 회원 가입을 하신 적이 없다면 고객센터로 문의해 주세요.'}
              </TypographyText>
            </Row>
          )}
          {errors.notAcademyEmail && (
            <Row flex style={{ marginTop: '-0.2rem' }}>
              <TypographyText yjTypeTypographyText="survey-validation">
                {'대한한의융합학회에 등록된 이메일이 맞는지 확인해주세요.'}
              </TypographyText>
            </Row>
          )}
          <Input
            style={{
              height: '3.2rem',
              width: '100%',
              border: '1px solid #afafaf',
              borderRadius: '3px',
              textIndent: '0.4rem',
              fontSize: '1rem',
              outline: 'none',
            }}
            className={css`
              &::placeholder {
                color: #afafaf;
              }
            `}
            value={name}
            placeholder="이름"
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
          />
          <>
            <PhoneInput
              style={{
                height: '3.2rem',
                width: '100%',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                padding: '0.35rem 0.6rem 0.35rem 0.6rem',
                textIndent: '0.4rem',
                fontSize: '1rem',
                outline: 'none',
              }}
              className={css`
                &::placeholder {
                  color: #afafaf;
                }
              `}
              country={'KR'}
              value={phone}
              placeholder="전화번호"
              onChange={(e) => {
                if (allCasePhoneValidationCheck(e?.replace('+82', '0'))) {
                  setErrors({
                    ...errors,
                    phoneValidation: false,
                  });
                } else {
                  setErrors({
                    ...errors,
                    phoneValidation: true,
                  });
                }
                setPhone(e);
              }}
              autoComplete="off"
            />
            {!!phone?.length && errors.phoneValidation && (
              <Row flex style={{ marginTop: '-0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {'전화번호 형식이 맞는지 확인해주세요.'}
                </TypographyText>
              </Row>
            )}
          </>
          {signUpType === 'direct' && (
            <>
              <Row>
                <Input
                  style={{
                    height: '3.2rem',
                    width: '100%',
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    textIndent: '0.4rem',
                    fontSize: '1rem',
                    outline: 'none',
                  }}
                  className={css`
                    &::placeholder {
                      color: #afafaf;
                    }
                  `}
                  value={licenseNumber}
                  placeholder="면허번호"
                  onChange={(e) => {
                    if (licenseValidationCheck(e.target.value)) {
                      setErrors({
                        ...errors,
                        licenseNumberValidation: false,
                      });
                    } else {
                      setErrors({
                        ...errors,
                        licenseNumberValidation: true,
                      });
                    }
                    setLicenseNumber(e.target.value);
                  }}
                  autoComplete="off"
                />
                {!!licenseNumber?.length && errors.licenseNumberValidation && (
                  <Row flex style={{ marginTop: '0.5rem' }}>
                    <TypographyText yjTypeTypographyText="survey-validation">
                      {'면허번호를 확인해주세요.'}
                    </TypographyText>
                  </Row>
                )}
              </Row>
              <Row>
                <ImageUploading
                  multiple
                  value={image}
                  onChange={(imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
                    // console.log(imageList, addUpdateIndex);
                    if (imageList?.length > 0) {
                      setErrors({
                        ...errors,
                        imageMaxFileSize: false,
                      });
                      // setImage(imageList[0].dataURL.split(';base64,')[1]);
                    }
                    setImage(imageList as never[]);
                  }}
                  maxNumber={1}
                  maxFileSize={5242880} // 5MB
                  acceptType={['jpg', 'png']}
                  onError={(e) => {
                    if (e.maxFileSize) {
                      setErrors({
                        ...errors,
                        imageMaxFileSize: true,
                      });
                    }
                  }}
                >
                  {({ imageList, onImageUpload, onImageRemove }) => (
                    <Row className="upload__image-wrapper">
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          height: '3.2rem',
                          width: '100%',
                          border: errors.imageMaxFileSize ? '1px solid #DF635C' : '1px solid #afafaf',
                          borderRadius: '3px',
                          fontSize: '1rem',
                          outline: 'none',
                          paddingLeft: '0.8rem',
                          paddingRight: '0.9rem',
                        }}
                      >
                        <Col flexFlag alignItems="center" style={{ columnGap: '0.45rem' }}>
                          {!imageList?.length && (
                            <Col
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: imageList?.length ? '1px solid #c4c4c4' : '1px solid #000000',
                                borderRadius: '3px',
                                background: imageList?.length ? '#f9f9f9' : '#e5e5e5',
                                height: '2rem',
                                paddingLeft: '0.5rem',
                                paddingRight: '0.5rem',
                                cursor: 'pointer',
                                pointerEvents: imageList?.length ? 'none' : 'auto',
                              }}
                              onClick={onImageUpload}
                            >
                              <TypographyText
                                fontSize="1rem"
                                whiteSpace="pre"
                                color={imageList?.length ? '#C4C4C4' : '#1d1c1d'}
                              >{`면허 이미지 첨부`}</TypographyText>
                            </Col>
                          )}
                          <Col flexFlag alignItems="center" justifyContent="center">
                            <TypographyText fontSize="1rem" color="#AFAFAF">
                              {imageList?.length
                                ? imageList.map((e, i) => {
                                    return <TypographyText key={i}>{e.file.name}</TypographyText>;
                                  })
                                : `형식: jpg, png`}
                            </TypographyText>
                          </Col>
                        </Col>
                        {imageList?.length > 0 && (
                          <Col
                            flexFlag
                            style={{ cursor: 'pointer' }}
                            onClick={async () => {
                              await setIsOpenRemoveImageModal(() => {
                                return onImageRemove;
                              });
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                              style={{ width: '1rem' }}
                              alt="serach_initialization_icon"
                            />
                          </Col>
                        )}
                      </Row>
                      {errors.imageMaxFileSize && (
                        <Row flex style={{ marginTop: '0.5rem' }}>
                          <TypographyText yjTypeTypographyText="survey-validation">
                            {'이미지 크기는 5MB 이하로 설정해주세요.'}
                          </TypographyText>
                        </Row>
                      )}
                    </Row>
                  )}
                </ImageUploading>
              </Row>
            </>
          )}
        </Row>
      </Row>
      {signUpType === 'academy' && (
        <>
          <Row style={{ display: 'flex', marginTop: '0.5rem' }}>
            <TypographyText
              color={'#afafaf'}
              lineHeight={`1.3em`}
              fontSize={`0.8rem`}
              whiteSpace="pre-wrap"
              wordBreak="keep-all"
            >
              {`대한융합한의학회 회원은 학회 가입 이메일로 신청 바랍니다. *대한융합한의학회 가입 후 예진 서비스에 즉시 회원가입이 안될 수 있으나, 24시간 이내로 회원가입이 가능하니 참고해주세요.`}
            </TypographyText>
          </Row>
          <Row
            style={{
              display: 'flex',
              cursor: 'pointer',
              marginTop: '0.5rem',
            }}
            onClick={() => window.open('https://ackm.org/', '_blank')}
          >
            <TypographyText
              color={'#1d1c1d'}
              fontWeight={700}
              fontSize={`0.8rem`}
              lineHeight="0.8rem"
              textDecoration="underline"
            >
              {`대한융합한의학회 홈페이지 바로가기`}
            </TypographyText>
          </Row>
        </>
      )}
      {businessType === 'BUSINESS' && (
        <Row style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', rowGap: '1.2rem' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize={`1rem`} fontWeight={700}>
              {`의료기관 정보 (필수)`}
            </TypographyText>
          </Row>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.6rem',
            }}
          >
            <Input
              style={{
                height: '3.2rem',
                width: '100%',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                textIndent: '0.4rem',
                fontSize: '1rem',
                outline: 'none',
              }}
              className={css`
                &::placeholder {
                  color: #afafaf;
                }
              `}
              value={clinicName}
              placeholder="의료기관 이름"
              onChange={(e) => setClinicName(e.target.value)}
              autoComplete="off"
            />
            <PhoneInput
              style={{
                height: '3.2rem',
                padding: '0.35rem 0.6rem 0.35rem 0.6rem',
                width: '100%',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                textIndent: '0.4rem',
                fontSize: '1rem',
                outline: 'none',
              }}
              className={css`
                &::placeholder {
                  color: #afafaf;
                }
              `}
              country={'KR'}
              value={clinicPhone}
              placeholder="의료기관 전화번호"
              onChange={(e) => {
                if (allCasePhoneValidationCheck(e?.replace('+82', '0'))) {
                  setErrors({
                    ...errors,
                    clinicPhoneValidation: false,
                  });
                } else {
                  setErrors({
                    ...errors,
                    clinicPhoneValidation: true,
                  });
                }
                setClinicPhone(e);
              }}
              autoComplete="off"
            />
            {!!clinicPhone?.length && errors.clinicPhoneValidation && (
              <Row flex style={{ marginTop: '-0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {'전화번호 형식이 맞는지 확인해주세요.'}
                </TypographyText>
              </Row>
            )}
            <Input
              style={{
                height: '3.2rem',
                width: '100%',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                textIndent: '0.4rem',
                fontSize: '1rem',
                outline: 'none',
              }}
              className={css`
                &::placeholder {
                  color: #afafaf;
                }
              `}
              value={clinicEmail}
              placeholder="의료기관 이메일"
              onChange={(e) => {
                if (emailValidationCheck(e.target.value)) {
                  setErrors({
                    ...errors,
                    clinicEmailValidation: false,
                  });
                } else {
                  setErrors({
                    ...errors,
                    clinicEmailValidation: true,
                  });
                }
                setClinicEmail(e.target.value);
              }}
              autoComplete="off"
            />
            {!!clinicEmail?.length && errors.clinicEmailValidation && (
              <Row flex style={{ marginTop: '-0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {'이메일 형식이 맞는지 확인해주세요.'}
                </TypographyText>
              </Row>
            )}
            <Row
              style={{
                display: 'flex',
                marginTop: '0.15rem',
              }}
            >
              <TypographyText color={'#afafaf'} lineHeight={`1.3em`} fontSize={`0.8rem`}>
                {`세금계산서 발행용으로, 별도의 한의원 이메일이 없다면 개인 이메일(아이디)과 동일해도 무방합니다. `}
              </TypographyText>
            </Row>
          </Row>
          <Row style={{ marginTop: '-0.45rem', display: 'flex', flexDirection: 'column', rowGap: '0.6rem' }}>
            <Row flex alignItems="center">
              <Col
                style={{
                  flex: '1 1 0',
                  display: 'flex',
                  minWidth: '16rem',
                  alignItems: 'center',
                  height: '3.2rem',
                  border: '1px solid #afafaf',
                  background: 'rgba(175, 175, 175, 0.1)',
                  paddingInline: '0.6rem',
                  borderRadius: '3px',
                  marginRight: '0.5rem',
                }}
              >
                <TypographyText color={`${clinicAddress ? '#1d1c1d' : '#AFAFAF'}`} lineHeight="1.2em">
                  {clinicAddress || '의료기관 주소(선택)'}
                </TypographyText>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  columnGap: '0.25rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '3.2rem',
                  paddingInline: '1.25rem',
                  border: '1px solid #afafaf',
                  borderColor: '#afafaf',
                  borderRadius: '3px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsOpenAddressModal(true);
                }}
              >
                <Col flexFlag>
                  <TypographyText
                    yjTypeTypographyText="sub-title"
                    fontWeight={700}
                    letterSpacing="0.02rem"
                    fontSize={'1rem'}
                    lineHeight="1.2em"
                  >
                    {clinicAddress ? '주소 재검색' : '주소 찾기'}
                  </TypographyText>
                </Col>
                <Col flexFlag>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/search-black-icon.webp`}
                    style={{ width: '1rem' }}
                    alt="search-black-icon"
                  />
                </Col>
              </Col>
            </Row>
            <Input
              disabled={!clinicAddress}
              style={{
                height: '3.2rem',
                minWidth: '17rem',
                width: '100%',
                paddingInline: '0.6rem',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                fontSize: '1rem',
                lineHeight: '1em',
                outline: 'none',
              }}
              className={css`
                &::placeholder {
                  color: #afafaf;
                }
              `}
              value={clinicDetailAddress}
              placeholder="상세 주소"
              onChange={(e) => {
                setClinicDetailAddress(e.target.value);
              }}
              autoComplete="off"
            />
            {/* <Row
              style={{
                display: 'flex',
                marginTop: '0.15rem',
              }}
            >
              <TypographyText color={'#afafaf'} lineHeight={`1.3em`} fontSize={`0.8rem`}>
                {`해당 주소는 의료기관 주소로 약재 주문 시, 조제된 약재가 배송될 주소입니다.`}
              </TypographyText>
            </Row> */}
          </Row>
        </Row>
      )}
      <Row
        style={{
          marginTop: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '3.6rem',
          cursor: 'pointer',
          background: submitFlag ? '#346AFF' : '#c4c4c4',
          borderRadius: '3px',
        }}
        onClick={async () => {
          if (submitFlag) {
            await signUpHandler({
              name,
              email,
              phone,
              image,
              licenseNumber,
              clinicName,
              clinicEmail,
              clinicPhone,
              service,
              privacy,
              marketing,
              signUpType,
              businessType,
            });
          }
        }}
      >
        <TypographyText color="#ffffff" fontWeight={700} fontSize="1.2rem" lineHeight="1.2rem">
          {signUpType === 'academy' ? '이메일로 회원가입 링크 받기' : `회원가입 요청하기`}
        </TypographyText>
      </Row>
      <Modal
        isOpen={isOpenRemoveImageModal !== undefined}
        okMsg="네, 삭제하겠습니다."
        cancelMsg="취소"
        okFunction={async () => {
          await isOpenRemoveImageModal(0);
          await setIsOpenRemoveImageModal(undefined);
        }}
        cancelFunction={() => setIsOpenRemoveImageModal(undefined)}
        contentMsg={`첨부한 이미지를 삭제하시겠습니까?`}
      />
    </Row>
  );
};

export default observer(SignUpStep2);
