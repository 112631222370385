/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import message from 'antd-message';
import { Form } from 'antd';
import moment from 'moment';

import { observer } from 'mobx-react';
import { useStores } from 'stores/Context';
import { Row, Col } from 'components/UI/atoms';
import { Loading } from 'components/UI/molecules';
import { codeMap, insuranceSurveyCodes } from 'utils/code';
import { getRootFontSize } from 'utils/util';

import PatientSurveyHeader from './PatientSurveyHeader';
import PatientSurveySideIndex from './PatientSurveySideIndex';
import PatientSurveyContent from './PatientSurvey';

function PatientSurvey(): ReactElement {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { surveyStore, patientStore, clinicStore, clientStore } = useStores();
  const { id, surveyId } = useParams();
  const [curCategoryIndex, setCurCategoryIndex] = useState(0);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [treatmentMode, setTreatmentMode] = useState<string>();
  const location = useLocation();
  const surveyRef = useRef([]);

  const getClosestItem = useCallback(() => {
    const divs = document.getElementsByClassName('survey-section');
    setCurCategoryIndex(
      _.maxBy(
        _.filter(
          _.map(
            _.filter(divs, (e) => e.id.length <= 1),
            (d: any, i) => ({
              index: i,
              id: d.id,
              offset: d.getBoundingClientRect().top - 7.9 * getRootFontSize(),
            }),
          ),
          (e) => e.offset <= 0,
        ),
        'offset',
      )?.index ?? 0,
    );
  }, []);

  const initSurveys = useMemo(() => {
    // 환자 정보를 토대로 필요없는 설문 항목 필터링
    let surveyKeys = Array.from(
      new Map(
        [...codeMap].filter(
          ([k, v]) =>
            ['Check', 'Radio', 'Date', 'NumberInput'].includes(v.selectType) && v.deprecated !== true,
        ),
      ).keys(),
    );

    // 먼저 모든 첩약건보 설문 제외
    let surveys = _.filter(surveyKeys, (k) => !insuranceSurveyCodes.has(k?.slice(0, 1)));

    if (treatmentMode && treatmentMode !== 'GENERAL') {
      surveys = [...surveyKeys.filter((k) => k.startsWith(treatmentMode)), ...surveys];
    }

    const age = moment().diff(patientStore.patient?.birth, 'years', false);
    // i 남성, j 임신, k 월경, l: 갱년기/유방, m 소아
    // 나이, 성별에 따른 설문 필터링
    // 활성화 조건 참고 (https://docs.google.com/spreadsheets/d/19BEm7UaxXJ5Mf-LJfHiqwF2qE8FQh_e95oGLrNrEXFw/edit#gid=67193016)
    if (age < 13) {
      // 만 13세 미만은 남녀 불문 소아 설문만
      surveys = _.filter(surveys, (e) => !_.includes(['i', 'j', 'k', 'l'], e[0]));
    } else if (age >= 18 && patientStore.patient?.gender === 'MALE') {
      // 만 18세 이상 남자는 남성 설문만
      surveys = _.filter(surveys, (e) => !_.includes(['j', 'k', 'l', 'm'], e[0]));
    } else if (age >= 13 && patientStore.patient?.gender === 'FEMALE') {
      // 만 13세 이상 여자는 임신, 월경, 갱년기/유방 설문만
      surveys = _.filter(surveys, (e) => !_.includes(['i', 'm'], e[0]));
    } else {
      // 그 외에는 모두 삭제
      surveys = _.filter(surveys, (e) => !_.includes(['i', 'j', 'k', 'l', 'm'], e[0]));
    }
    return surveys;
  }, [patientStore.patient, treatmentMode]);

  const init = useCallback(async () => {
    await setIsGlobalLoading(true);
    await Promise.all([
      patientStore.getPatient(id!),
      clinicStore.getClinic(clientStore.metaData?.userRole.clinicID!, false),
    ]);
    if (_.isEmpty(patientStore.patient)) {
      message.error(`환자 정보를 찾을 수 없습니다.`, 2500);
      navigate('/patient');
    } else {
      if (surveyId) {
        await Promise.all([surveyStore.getSurvey(surveyId!)]);
        if (_.isEmpty(surveyStore.survey)) {
          message.error(`증상설문 정보를 찾을 수 없습니다.`, 2500);
          navigate('/patient');
        }
      }
    }
    await setIsGlobalLoading(false);
  }, []);

  useEffect(() => {
    init();
    return () => {
      surveyStore.surveyReset();
      surveyStore.clearInvalidAdminSurveyCodes();
    };
  }, [location.pathname]);

  const initialValue = useMemo(() => {
    if (typeof surveyStore?.survey?.surveyResult === 'string' || !surveyId) {
      let sData;
      if (surveyStore?.survey?.surveyResult === 'none' || !surveyId) {
        sData = JSON.stringify({ mainSymptom: '', survey: [] });
      } else {
        sData = surveyStore?.survey?.surveyResult ?? JSON.stringify({ mainSymptom: '', survey: [] });
      }
      setTreatmentMode(surveyStore?.survey?.insuranceType ?? 'GENERAL');
      if (surveyStore?.survey?.mainSymptomCategory && codeMap.has(surveyStore?.survey?.mainSymptomCategory)) {
        form.setFieldsValue({ mainSymptomCategory: surveyStore.survey.mainSymptomCategory });
      }
      const data = JSON.parse(sData);
      const surveys = data?.survey ?? [];
      const onlyAdminSurveyCodeArr = [
        'd',
        'f',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
      ];
      const surObj = surveys.reduce((a: any, c: any) => {
        const { code, value, desc } = c;
        let v = value;

        if (typeof v === 'number') {
          // antd form이 number값이 string으로 저장되므로, 파싱 단계에서도 string으로 변환
          v = String(v);
        }
        const result = {
          ...a,
          [code]: v,
        };

        if (code?.length === 2 && _.includes(onlyAdminSurveyCodeArr, code?.slice(0, 1))) {
          const pk: string = code?.slice(0, 1);
          if (a[pk] == undefined) result[pk] = '1';
        }

        if (desc) {
          const descField = `${code}Desc`;
          result[descField] = desc;
        }
        return result;
      }, {});
      const insuranceSurObj = {};
      insuranceSurveyCodes.forEach((code) => {
        insuranceSurObj[code] = '1';
      });
      return {
        mainSymptom: data?.mainSymptom,
        additionalSymptoms: surveyStore?.survey?.additionalSymptoms ?? '',
        ...insuranceSurObj,
        ...surObj,
      };
    } else {
      return {};
    }
  }, [surveyStore.survey?.id]);

  const category = useMemo(() => {
    return [
      { key: 'mainSymptom', label: '주소증' },
      ..._.map(
        _.filter(initSurveys, (k) => k.length === 1),
        (k) => ({
          key: k,
          label: codeMap.get(k)?.label,
        }),
      ),
    ];
  }, [initSurveys]);

  return (
    <Row>
      <Col span={24}>
        <PatientSurveyHeader
          patient={patientStore.patient!}
          mode={surveyId ? undefined : 'create'}
          survey={surveyId ? surveyStore.survey : undefined}
        />
        <Row yjTypeRow="content">
          <Col yjTypeCol="survey-side">
            <PatientSurveySideIndex
              surveyRef={surveyRef}
              surveyList={initSurveys}
              curCategoryIndex={curCategoryIndex}
              category={category}
            />
          </Col>
          <div
            style={{ display: 'contents' }}
            onScrollCapture={() => {
              getClosestItem();
            }}
          >
            <Col yjTypeCol="survey-content">
              <PatientSurveyContent
                treatmentMode={treatmentMode}
                mode={surveyId ? 'update' : 'create'}
                form={form}
                surveyRef={surveyRef}
                initSurveys={initSurveys}
                initialValue={initialValue}
                category={category}
                setTreatmentMode={setTreatmentMode}
              />
            </Col>
          </div>
        </Row>
      </Col>
      {isGlobalLoading && <Loading mode="global" />}
    </Row>
  );
}

export default observer(PatientSurvey);
