import { Flex, Text } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import LandingLayout from 'components/templates/LandingLayout';
import { Button } from 'components/UI/atoms/inputs/Button';
import { Input } from 'components/UI/molecules/Input';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import message from 'antd-message';
import { emailValidationCheck, passwordValidationCheck } from 'utils/util';

import { Row } from 'components/UI/atoms';

const codeValidationCheck = (code: string) => {
  if (code.length === 6 || code.length === 0) {
    return true;
  }
  return false;
};

const passwordConfirmValidationCheck = (password: string, passwordConfirm: string) => {
  if (password === passwordConfirm) {
    return true;
  }
  return false;
};

const ConfirmForgotPasswordPage = () => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [valid, setValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      codeValidationCheck(code) &&
      emailValidationCheck(email) &&
      passwordValidationCheck(password) &&
      passwordConfirmValidationCheck(password, confirmPassword)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [code, email, password, confirmPassword]);

  const content = (
    <Flex direction={'column'} alignItems={'center'}>
      <Flex direction={'column'} width={'27rem'} style={{ gap: 0 }}>
        <Text fontSize={'1.6rem'} margin={'3rem 0 2rem 0'}>
          비밀번호를 재설정해주세요.
        </Text>
        <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.4rem', marginBottom: '2rem' }}>
          <Row>
            <Input
              label={'인증번호'}
              autoComplete="code"
              placeholder={'6자리'}
              value={code}
              id={'code'}
              onChange={(e) => {
                setCode(e.target.value);
              }}
              name="code"
            />
            {!codeValidationCheck(code) && (
              <Text marginLeft={'7.3rem'} color={'#DF635C'} fontSize="0.8rem">
                인증번호를 확인해주세요.
              </Text>
            )}
          </Row>
          <Row>
            <Input
              label={'아이디'}
              placeholder={'이메일 주소'}
              autoComplete={'off'}
              onChange={(e) => setEmail(e.target.value)}
            ></Input>
            {!!email?.length && !emailValidationCheck(email) && (
              <Text color={'#DF635C'} fontSize="0.8rem">
                이메일 형식이 맞는지 확인해주세요.
              </Text>
            )}
          </Row>
          <Row>
            <Input
              type={'password'}
              label={'비밀번호'}
              autoComplete="off"
              placeholder={'비밀번호 (영어 소문자, 숫자 포함 10자리 이상)'}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {!!password?.length && !passwordValidationCheck(password) && (
              <Text marginLeft={'7.3rem'} color={'#DF635C'} fontSize="0.8rem">
                비밀번호는 영어 소문자, 숫자 포함 10자리 이상의 문자로 설정해주세요.
              </Text>
            )}
          </Row>
          <Row>
            <Input
              type={'password'}
              label={'비밀번호 확인'}
              autoComplete="new-password"
              placeholder={'비밀번호 확인'}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            {!passwordConfirmValidationCheck(password, confirmPassword) && (
              <Text marginLeft={'7.3rem'} color={'#DF635C'} fontSize="0.8rem">
                비밀번호가 일치 하지 않습니다.
              </Text>
            )}
          </Row>
        </Row>
        <Button
          backgroundColor={valid ? '#1c252c' : '#c4c4c4'}
          borderRadius="3px"
          isDisabled={!valid}
          name={'black'}
          fontSize="1.2rem"
          color={'#ffffff'}
          onClick={async () => {
            try {
              if (valid) {
                await Auth.forgotPasswordSubmit(email, code, password);
                message.success(`비밀번호 재설정이 완료되었습니다.`, 2500);
                navigate('/');
              }
            } catch (error) {
              if (error.code === 'LimitExceededException') {
                message.error(`요청이 너무 많습니다. 잠시후 시도해주십시오.`, 2500);
              } else if (error.code === 'CodeMismatchException') {
                message.error(`올바르지 않은 인증코드입니다.`, 2500);
              } else {
                message.error(`비밀번호 재설정에 실패했습니다. ${error}`, 2500);
              }
            }
          }}
        >
          비밀번호 재설정하기
        </Button>
      </Flex>
    </Flex>
  );
  return <LandingLayout content={content} />;
};
export default ConfirmForgotPasswordPage;
