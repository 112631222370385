/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import LandingLayout from 'components/templates/LandingLayout';
import { Loading } from 'components/UI/molecules';
import { Row, ModalWithJustX, TypographyText } from 'components/UI/atoms';
import { GuideButton } from 'components/UI/molecules';

import SignUpStep1 from './SignUpStep1';
import SignUpStep2 from './SignUpStep2';
import SignUpStep3 from './SignUpStep3';

const SignUpPage = () => {
  const [isOpenMediaModal, setIsOpenMediaModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  // 가입 화면 구분
  const [signupStep, setSignupStep] = useState<'step1' | 'step2' | 'step3'>('step1');
  // 가입 유형
  const [signUpType, setSignUpType] = useState<'academy' | 'direct'>();

  // term
  const [service, setService] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const [email, setEmail] = useState<string>();
  // const [email, setEmail] = useState<string>('borooboroo625@gmail.com');

  useEffect(() => {
    // 회원 가입 화면 로딩 시, 스크롤을 맨 위로 올리기 위함
    document.getElementById('landing-layout-wrapper')?.scrollIntoView({
      block: 'start',
    });
  }, [signupStep]);

  const content = (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <ModalWithJustX
        isOpen={isOpenMediaModal}
        width={`48rem`}
        height={`27rem`}
        content={
          <Row
            style={{
              position: 'relative',
              width: '100%',
              paddingBottom: '56.25%',
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
              }}
              src={`https://www.youtube.com/embed/e5l75OuvL_s?autoplay=1`}
              title="Ye-jin Signup"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
            />
          </Row>
        }
        cancelFunction={() => setIsOpenMediaModal(false)}
      />
      {isLoading && <Loading mode="global" />}
      <Row
        id={'signup-wrapper'}
        style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% / 3)', paddingBottom: '4rem' }}
      >
        {['step1', 'step2'].includes(signupStep) && (
          <Row
            style={{
              marginTop: '3rem',
              marginBottom: '2rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <TypographyText whiteSpace="pre-wrap" fontSize="1.6rem" lineHeight="2.1rem">
              {signupStep === 'step1'
                ? `회원가입`
                : `가입 정보 입력 후 이메일로 
회원가입 링크를 받으세요.`}
            </TypographyText>
            {/* <GuideButton buttonText="동영상 가이드" onClickFunc={() => setIsOpenMediaModal(true)} /> */}
          </Row>
        )}
        {signupStep === 'step1' && (
          <SignUpStep1
            service={service}
            setService={setService}
            privacy={privacy}
            setPrivacy={setPrivacy}
            marketing={marketing}
            setMarketing={setMarketing}
            signUpType={signUpType}
            setSignUpType={setSignUpType}
            setSignupStep={setSignupStep}
          />
        )}
        {signupStep === 'step2' && (
          <SignUpStep2
            service={service}
            privacy={privacy}
            marketing={marketing}
            signUpType={signUpType}
            setIsLoading={setIsLoading}
            email={email}
            setEmail={setEmail}
            setSignupStep={setSignupStep}
          />
        )}
        {signupStep === 'step3' && <SignUpStep3 email={email} signUpType={signUpType} />}
      </Row>
    </Row>
  );
  return <LandingLayout content={content} />;
};
export default observer(SignUpPage);
