import React from 'react';

import { TypographyText } from 'components/UI/atoms';
import { TableCell } from 'components/UI/atoms/table/TableCell';

const SymptomDescTd = ({
  data,
  plainStringValue = '',
  desc = '',
}: {
  data: any;
  plainStringValue?: string;
  desc?: string;
}) => {
  return (
    <TableCell
      style={{
        borderLeft: 'none',
        borderRight: 'none',
        paddingRight: '0',
        minWidth: '9.6rem',
      }}
    >
      <TypographyText
        yjTypeTypographyText="symptom-table-body"
        whiteSpace="pre-wrap"
        wordBreak="keep-all"
        style={desc.length ? { paddingBottom: '0.6rem', display: 'block' } : {}}
      >
        {plainStringValue ? plainStringValue : data?.desc?.join(', ')}
      </TypographyText>
      {!!desc.length && (
        <TypographyText yjTypeTypographyText="symptom-table-body" style={{ whiteSpace: 'pre-wrap' }}>
          {desc}
        </TypographyText>
      )}
    </TableCell>
  );
};

export default React.memo(SymptomDescTd);
