import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import _ from 'lodash';
import { herblist as yejinHerbList } from 'yejin-herb-list';
import yejinPrescriptionRef from 'yejin-prescription-ref';

export interface Herb {
  code: string;
  name: string;
  amount: number;
}

interface IYejinHerb {
  basicHerbFlag: boolean; // 본초 여부
  processingCode: string; // 포제 코드
  herbCode: string; // 본초 코드
  koreanName: string; // 한글 이름
  knForSearch: string; // 검색용 한글 이름 (이명 포함)
  chineseName: string; // 한문명
  price: number; // 가격
}

export class PrescriptionStore {
  rootStore: RootStore;
  yejinHerbCountMap: Map<string, number>; // 포제별 처방에 사용된 횟수
  flatYejinHerbList: IYejinHerb[]; // 평탄화된 약재 리스트
  yejinHerbCodeSet = new Set<string>(); // 약재코드 Set
  yejinHerbForSearchByHerbCode: Map<string, IYejinHerb[]>; // 본초코드로 본초에서 파생된 포제들을 검색하기 위한 Map
  yejinHerbForSearchOnlyBasicHerbByHerbCode: Map<string, IYejinHerb>; // 본초를 허브코드로 검색하기 위한 Map
  yejinHerbForSearchByProcessingCode: Map<string, IYejinHerb>; // 포제코드로 검색용 Map
  yejinHerbForSearchByName: Map<string, IYejinHerb>; // 약재(이명 포함)명으로 검색용 Map
  duplicatedHerbNameArray: Map<string, Set<string>>; // 중복된 약재명을 작업하기 위한 배열
  duplicatedHerbNameMap: Map<string, Set<string>> = new Map(); // 중복된 약재명으로 검색용 Map
  notStandardHerbMap: Map<string, IYejinHerb>; // 정부에서 제시되지 않은 비표준 약재. ex. 적작약. 포제코드 뒷자리 둘째자리가 -로 시작하는 약재
  yejinPrescriptionRef = yejinPrescriptionRef;
  selectedHerbs?: Herb[] = [];
  prescriptionPriceCap: Map<string, number> = new Map();

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.prescriptionPriceCap = new Map();
    this.yejinPrescriptionRef = yejinPrescriptionRef;
    this.flatYejinHerbList = _.flatten(yejinHerbList as IYejinHerb[][]);
    this.yejinHerbForSearchByProcessingCode = new Map();
    this.yejinHerbForSearchByHerbCode = new Map();
    this.yejinHerbForSearchOnlyBasicHerbByHerbCode = new Map();
    this.yejinHerbForSearchByName = new Map();
    this.notStandardHerbMap = new Map();
    this.duplicatedHerbNameMap = new Map();
    this.duplicatedHerbNameArray = new Map();
    this.yejinHerbCodeSet = new Set();
    this.flatYejinHerbList.forEach((e) => {
      // 데이터 가공

      this.yejinHerbCodeSet.add(e.herbCode);
      // 포제단위 약재 모음
      this.yejinHerbForSearchByProcessingCode.set(e.processingCode, e);
      if (this.yejinHerbForSearchByHerbCode.has(e.herbCode)) {
        this.yejinHerbForSearchByHerbCode.set(e.herbCode, [
          ...this.yejinHerbForSearchByHerbCode.get(e.herbCode),
          e,
        ]);
      } else {
        this.yejinHerbForSearchByHerbCode.set(e.herbCode, [e]);
      }
      if (e.basicHerbFlag) {
        this.yejinHerbForSearchOnlyBasicHerbByHerbCode.set(e.herbCode, e);
      }
      if (e.processingCode.slice(-2).includes('-')) {
        // 비표준 약재 모음
        this.notStandardHerbMap.set(e.processingCode, e);
      }
      e.knForSearch.split('||').forEach((name) => {
        const nm = name.split('(')[0];
        if (this.duplicatedHerbNameArray.has(nm)) {
          const prev = this.duplicatedHerbNameArray.get(nm);
          this.duplicatedHerbNameArray.set(nm, prev.add(e.processingCode));
        } else {
          this.duplicatedHerbNameArray.set(nm, new Set([e.processingCode]));
        }
        this.yejinHerbForSearchByName.set(nm, e);
      });
    });
    Array.from(this.duplicatedHerbNameArray)
      .filter((e) => e[1].size > 1)
      .forEach((e) => {
        this.duplicatedHerbNameMap.set(e[0], e[1]);
      });
  }
}
