import { API } from 'aws-amplify';
import { SelectSymptom } from '../API';

class SymptomRepository {
  getSuggestHerb = async ({
    symptoms,
    mode,
    queryParams,
    insuranceMode,
    insuranceDiseaseCode,
    subMode,
    herbCodeArrayforSearch,
    processingCodeArrayforSearch,
  }: {
    symptoms: (SelectSymptom | null)[];
    mode: 'herb' | 'byeonjeung';
    queryParams;
    insuranceMode?: string;
    insuranceDiseaseCode?: string;
    subMode?: string;
    herbCodeArrayforSearch?: string[];
    processingCodeArrayforSearch?: string[];
  }) => {
    let queryString = ``;
    if (mode === 'herb') {
      const {
        prescriptionCount = 30,
        herbCount = 30,
        gunBaseScore = 1.5,
        sinBaseScore = 1.3,
        generalHerbBaseScore = 1,
        formulaSearchKeyword = '',
      } = queryParams;

      if (prescriptionCount)
        queryString += `${queryString.length ? '&' : ''}prescriptionCount=${prescriptionCount}`;
      if (herbCount) queryString += `${queryString.length ? '&' : ''}herbCount=${herbCount}`;
      if (gunBaseScore) queryString += `${queryString.length ? '&' : ''}gunBaseScore=${gunBaseScore}`;
      if (sinBaseScore) queryString += `${queryString.length ? '&' : ''}sinBaseScore=${sinBaseScore}`;
      if (generalHerbBaseScore)
        queryString += `${queryString.length ? '&' : ''}generalHerbBaseScore=${generalHerbBaseScore}`;
      if (formulaSearchKeyword)
        queryString += `${queryString.length ? '&' : ''}formulaSearchKeyword=${formulaSearchKeyword}`;
    }
    const response = await API.post('yejinadminrestapi', `/recommendation?${queryString}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
      body: {
        symptoms,
        mode,
        insuranceMode,
        insuranceDiseaseCode,
        subModeForHerbMode: subMode,
        herbCodeArrayforSearch,
        processingCodeArrayforSearch,
      },
    });
    return response;
  };

  getPatientAnalysis = async (herbCodes: string[]) => {
    const response = await API.post('yejinadminrestapi', '/symptom', {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
      body: {
        herbs: herbCodes,
      },
    });
    return response;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SymptomRepository();
