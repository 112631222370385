import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

import { RootStore } from './RootStore';

export class FeedbackStore {
  rootStore: RootStore;
  isFeedback: boolean = true;
  symptoms: any[] = [];
  count: number;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.isFeedback = true;
    this.count = this.rootStore.clinicStore?.clinic?.feedbackDate || 20;
  }

  setIsFeedback(value: boolean) {
    this.isFeedback = value;
  }

  setCount(value: number) {
    this.count = value;
  }

  addSymptoms = (item: any) => {
    if (
      !_.includes(
        _.map(this.symptoms, (e) => e.code),
        item.code,
      )
    ) {
      this.symptoms.push(item);
    }
  };
  deleteSymptoms = (code: string) => {
    this.symptoms = this.symptoms.filter((e) => e.code !== code);
  };
  resetFeedback = () => {
    this.symptoms = [];
    this.count = this.rootStore.clinicStore?.clinic?.feedbackDate || 20;
    this.isFeedback = true;
  };

  setSymtoms = (symptoms: any[]) => {
    this.symptoms = symptoms;
  };
}
