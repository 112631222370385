export const insuranceSurveyCodes = new Set(['B', 'C', 'D', 'E', 'F', 'G']);

export const codeMap = new Map([
  [
    'a',
    {
      label: '소화',
      desc: ['소화 시 문제가 있으신가요?', '(소화가 잘되지 않음,', '식욕, 갈증, 구역·구토에 문제가 있음)'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['aa', 'ab', 'ac', 'ad'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'aa',
    {
      label: '소화',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '최근에 소화가 잘 안돼요.',
          next: 'aaa',
          value: 'aaa',
          symptomCode: ['S178'],
        },
        {
          label: '늘 소화가 안되고 속이 더부룩해요.',
          next: 'aab',
          value: 'aab',
          symptomCode: ['S194'],
        },
        {
          label: '현재 체해서 속이 답답해요.',
          next: 'aac',
          value: 'aac',
          symptomCode: ['S196'],
        },
      ],
    },
  ],
  [
    'aaa',
    {
      label: '소화',
      desc: ['최근에 소화가 잘 안돼요.'],
      symptomCode: ['S178'],
      selectType: 'Scale',
    },
  ],
  [
    'aab',
    {
      label: '소화',
      desc: ['늘 소화가 안되고 속이 더부룩해요.'],
      symptomCode: ['S194'],
      selectType: 'Scale',
    },
  ],
  [
    'aac',
    {
      label: '소화',
      desc: ['현재 체해서 속이 답답해요.'],
      symptomCode: ['S196'],
      selectType: 'Scale',
    },
  ],
  [
    'ab',
    {
      label: '식욕',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '요즘 입맛이 없어요.',
          next: 'aba',
          value: 'aba',
          symptomCode: ['S192'],
        },
        {
          label: '밥을 먹은 후에도 배가 금방 고파요.',
          next: 'abb',
          value: 'abb',
          symptomCode: ['S168'],
        },
      ],
    },
  ],
  [
    'aba',
    {
      label: '식욕',
      desc: ['요즘 입맛이 없어요.'],
      symptomCode: ['S192'],
      selectType: 'Scale',
    },
  ],
  [
    'abb',
    {
      label: '식욕',
      desc: ['밥을 먹은 후에도 배가 금방 고파요.'],
      symptomCode: ['S168'],
      selectType: 'Scale',
    },
  ],
  [
    'ac',
    {
      label: '갈증',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '갈증이 나서 물을 많이 마셔요.',
          next: ['aca', 'acb'],
          value: 'aca',
          symptomCode: ['S006'],
        },
      ],
    },
  ],
  [
    'aca',
    {
      label: '갈증',
      desc: ['갈증이 나서 물을 많이 마셔요.'],
      symptomCode: ['S006'],
      selectType: 'Scale',
    },
  ],
  [
    'acb',
    {
      label: '물 선호',
      desc: ['갈증이 날 때 어떤 물을 선호하시나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '찬물이 좋아요.',
          value: 1,
          symptomCode: ['S716'],
        },
        {
          label: '따뜻한 물이 좋아요.',
          value: 2,
          symptomCode: ['S717'],
        },
        {
          label: '찬물이든 더운물이든 상관없어요.',
          value: 3,
          symptomCode: ['S718'],
        },
      ],
    },
  ],
  [
    'ad',
    {
      label: '구역/구토',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '자주 메스껍고 구역질이 나요.',
          next: 'ada',
          value: 'ada',
          symptomCode: ['S040'],
        },
        {
          label: '구토(먹은 음식물을 토함)를 자주 해요.',
          next: 'adb',
          value: 'adb',
          symptomCode: ['S044'],
        },
        {
          label: '토혈(피가 섞인 토)을 해요.',
          next: 'adc',
          value: 'adc',
          symptomCode: ['S307'],
        },
        {
          label: '딸꾹질을 자주 해요.',
          next: 'add',
          value: 'add',
          symptomCode: ['S088'],
        },
        {
          label: '트림을 자주 해요.',
          next: 'ade',
          value: 'ade',
          symptomCode: ['S310'],
        },
        {
          label: '신물이 자주 올라와요.',
          next: 'adf',
          value: 'adf',
          symptomCode: ['S302'],
        },
        {
          label: '속이 쓰려요.',
          next: 'adg',
          value: 'adg',
          symptomCode: ['S180'],
        },
      ],
    },
  ],
  [
    'ada',
    {
      label: '구역/구토',
      desc: ['자주 메스껍고 구역질이 나요.'],
      symptomCode: ['S040'],
      selectType: 'Scale',
    },
  ],
  [
    'adb',
    {
      label: '구역/구토',
      desc: ['구토(먹은 음식물을 토함)를 자주 해요.'],
      symptomCode: ['S044'],
      selectType: 'Scale',
    },
  ],
  [
    'adc',
    {
      label: '구역/구토',
      desc: ['토혈(피가 섞인 토)을 해요.'],
      symptomCode: ['S307'],
      selectType: 'Scale',
    },
  ],
  [
    'add',
    {
      label: '구역/구토',
      desc: ['딸꾹질을 자주 해요.'],
      symptomCode: ['S088'],
      selectType: 'Scale',
    },
  ],
  [
    'ade',
    {
      label: '구역/구토',
      desc: ['트림을 자주 해요.'],
      symptomCode: ['S310'],
      selectType: 'Scale',
    },
  ],
  [
    'adf',
    {
      label: '구역/구토',
      desc: ['신물이 자주 올라와요.'],
      symptomCode: ['S302'],
      selectType: 'Scale',
    },
  ],
  [
    'adg',
    {
      label: '구역/구토',
      desc: ['속이 쓰려요.'],
      symptomCode: ['S180'],
      selectType: 'Scale',
    },
  ],
  [
    'b',
    {
      label: '소변',
      desc: ['소변 시 문제가 있으신가요?', '(소변이 너무 잦음·적음, 야뇨증 등)'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ba', 'bb', 'bc', 'bd'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ba',
    {
      label: '소변 빈도',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '소변이 잘 안 나오거나 방울방울 나와요.',
          next: 'baa',
          value: 'baa',
          symptomCode: ['S242'],
        },
        {
          label: '소변을 자주 봐요.(하루 7회 이상)',
          next: 'bab',
          value: 'bab',
          symptomCode: ['S147'],
        },
        {
          label: '평소보다 소변 양이 많은 것 같아요.',
          next: 'bac',
          value: 'bac',
          symptomCode: ['S067'],
        },
        {
          label: '밤 중에 자다 깨서 소변을 봐요.',
          next: 'bad',
          value: 'bad',
          symptomCode: ['S202'],
        },
      ],
    },
  ],
  [
    'baa',
    {
      label: '소변 빈도',
      desc: ['소변이 잘 안 나오거나 방울방울 나와요.'],
      symptomCode: ['S242'],
      selectType: 'Scale',
    },
  ],
  [
    'bab',
    {
      label: '소변 빈도',
      desc: ['소변을 자주 봐요.(하루 7회 이상)'],
      symptomCode: ['S147'],
      selectType: 'Scale',
    },
  ],
  [
    'bac',
    {
      label: '소변 빈도',
      desc: ['평소보다 소변 양이 많은 것 같아요.'],
      symptomCode: ['S067'],
      selectType: 'Scale',
    },
  ],
  [
    'bad',
    {
      label: '소변 빈도',
      desc: ['밤 중에 자다 깨서 소변을 봐요.'],
      symptomCode: ['S202'],
      selectType: 'Scale',
    },
  ],
  [
    'bb',
    {
      label: '배뇨 시 증상',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '소변을 볼 때 통증이 있어요. ',
          next: 'bba',
          value: 'bba',
          symptomCode: ['S063'],
        },
        {
          label: '소변 양이 적고 시원하지 않아요.',
          next: 'bbb',
          value: 'bbb',
          symptomCode: ['S169'],
        },
        {
          label: '소변을 볼 때 저린감, 열감이 있어요.',
          next: 'bbc',
          value: 'bbc',
          symptomCode: ['S117'],
        },
        {
          label: '소변을 보고 난 후에도 개운하지 않아요.',
          next: 'bbd',
          value: 'bbd',
          symptomCode: ['S263'],
        },
        {
          label: '소변을 잘 참기가 힘들어요.',
          next: 'bbe',
          value: 'bbe',
          symptomCode: ['S274'],
        },
        {
          label: '요실금이 있어요.',
          next: 'bbf',
          value: 'bbf',
          symptomCode: ['S225'],
        },
      ],
    },
  ],
  [
    'bba',
    {
      label: '배뇨 시 증상',
      desc: ['소변을 볼 때 통증이 있어요. '],
      symptomCode: ['S063'],
      selectType: 'Scale',
    },
  ],
  [
    'bbb',
    {
      label: '배뇨 시 증상',
      desc: ['소변 양이 적고 시원하지 않아요.'],
      symptomCode: ['S169'],
      selectType: 'Scale',
    },
  ],
  [
    'bbc',
    {
      label: '배뇨 시 증상',
      desc: ['소변을 볼 때 저린감, 열감이 있어요.'],
      symptomCode: ['S117'],
      selectType: 'Scale',
    },
  ],
  [
    'bbd',
    {
      label: '배뇨 시 증상',
      desc: ['소변을 보고 난 후에도 개운하지 않아요.'],
      symptomCode: ['S263'],
      selectType: 'Scale',
    },
  ],
  [
    'bbe',
    {
      label: '배뇨 시 증상',
      desc: ['소변을 잘 참기가 힘들어요.'],
      symptomCode: ['S274'],
      selectType: 'Scale',
    },
  ],
  [
    'bbf',
    {
      label: '배뇨 시 증상',
      desc: ['요실금이 있어요.'],
      symptomCode: ['S225'],
      selectType: 'Scale',
    },
  ],
  [
    'bc',
    {
      label: '소변 색',
      desc: ['관련된 증상을 하나 선택하세요.'],
      selectType: 'Radio',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '소변에 피가 섞여 있어요. ',
          next: 'bca',
          value: 'bca',
          symptomCode: ['S066'],
        },
        {
          label: '소변색이 붉어요.',
          next: 'bcb',
          value: 'bcb',
          symptomCode: ['S064'],
        },
        {
          label: '소변이 뿌예요.',
          next: 'bcc',
          value: 'bcc',
          symptomCode: ['S065'],
        },
        {
          label: '소변이 검어요.',
          next: 'bcd',
          value: 'bcd',
          symptomCode: ['S733'],
        },
      ],
    },
  ],
  [
    'bca',
    {
      label: '소변 색',
      desc: ['소변에 피가 섞여 있어요. '],
      symptomCode: ['S066'],
      selectType: 'Scale',
    },
  ],
  [
    'bcb',
    {
      label: '소변 색',
      desc: ['소변색이 붉어요.'],
      symptomCode: ['S064'],
      selectType: 'Scale',
    },
  ],
  [
    'bcc',
    {
      label: '소변 색',
      desc: ['소변이 뿌예요.'],
      symptomCode: ['S065'],
      selectType: 'Scale',
    },
  ],
  [
    'bcd',
    {
      label: '소변 색',
      desc: ['소변이 검어요.'],
      symptomCode: ['S733'],
      selectType: 'Scale',
    },
  ],
  [
    'bd',
    {
      label: '소변 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '요도염 ',
          next: 'bda',
          value: 'bda',
          symptomCode: ['S222'],
        },
        {
          label: '방광염',
          next: 'bdb',
          value: 'bdb',
          symptomCode: ['S116'],
        },
        {
          label: '요로결석',
          next: 'bdc',
          value: 'bdc',
          symptomCode: ['S223'],
        },
        {
          label: '전립선비대증',
          next: 'bdd',
          value: 'bdd',
          symptomCode: ['S272'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'bda',
    {
      label: '소변 질환',
      desc: ['요도염 '],
      symptomCode: ['S222'],
      selectType: 'Onset',
    },
  ],
  [
    'bdb',
    {
      label: '소변 질환',
      desc: ['방광염'],
      symptomCode: ['S116'],
      selectType: 'Onset',
    },
  ],
  [
    'bdc',
    {
      label: '소변 질환',
      desc: ['요로결석'],
      symptomCode: ['S223'],
      selectType: 'Onset',
    },
  ],
  [
    'bdd',
    {
      label: '소변 질환',
      desc: ['전립선비대증'],
      symptomCode: ['S272'],
      selectType: 'Onset',
    },
  ],
  [
    'c',
    {
      label: '대변',
      desc: ['대변 시 문제가 있으신가요?', '(설사, 변비, 혈변, 치질 등)'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ca', 'cb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ca',
    {
      label: '대변',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '설사 ',
          next: 'caa',
          value: 'caa',
          symptomCode: ['S159'],
        },
        {
          label: '변비',
          next: 'cab',
          value: 'cab',
          symptomCode: ['S126'],
        },
        {
          label: '대변에 피가 섞여있다.',
          next: 'cac',
          value: 'cac',
          symptomCode: ['S127'],
        },
      ],
    },
  ],
  [
    'caa',
    {
      label: '설사 양상',
      desc: ['관련된 증상을 모두 선택하세요.'],
      symptomCode: ['S159'],
      selectType: 'Check',
      answers: [
        {
          label: '하루 3번 이상 설사를 해요.',
          next: 'caaa',
          value: 'caaa',
          symptomCode: ['S734'],
        },
        {
          label: '대변이 묽어요.',
          next: 'caab',
          value: 'caab',
          symptomCode: ['S735'],
        },
        {
          label: '물 같은 설사를 해요.',
          next: 'caac',
          value: 'caac',
          symptomCode: ['S736'],
        },
        {
          label: '대변을 보고난 후에도 시원하지 않아요.',
          next: 'caad',
          value: 'caad',
          symptomCode: ['S737'],
        },
        {
          label: '찬 것을 먹으면 설사를 자주 해요.',
          next: 'caae',
          value: 'caae',
          symptomCode: ['S738'],
        },
        {
          label: '술 마신 후에는 설사를 해요.',
          next: 'caaf',
          value: 'caaf',
          symptomCode: ['S739'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'caaa',
    {
      label: '설사 양상',
      desc: ['하루 3번 이상 설사를 해요.'],
      symptomCode: ['S734'],
      selectType: 'Scale',
    },
  ],
  [
    'caab',
    {
      label: '설사 양상',
      desc: ['대변이 묽어요.'],
      symptomCode: ['S735'],
      selectType: 'Scale',
    },
  ],
  [
    'caac',
    {
      label: '설사 양상',
      desc: ['물 같은 설사를 해요.'],
      symptomCode: ['S736'],
      selectType: 'Scale',
    },
  ],
  [
    'caad',
    {
      label: '설사 양상',
      desc: ['대변을 보고난 후에도 시원하지 않아요.'],
      symptomCode: ['S737'],
      selectType: 'Scale',
    },
  ],
  [
    'caae',
    {
      label: '설사 양상',
      desc: ['찬 것을 먹으면 설사를 자주 해요.'],
      symptomCode: ['S738'],
      selectType: 'Scale',
    },
  ],
  [
    'caaf',
    {
      label: '설사 양상',
      desc: ['술 마신 후에는 설사를 해요.'],
      symptomCode: ['S739'],
      selectType: 'Scale',
    },
  ],
  [
    'cab',
    {
      label: '변비 양상',
      desc: ['관련된 증상을 모두 선택하세요.'],
      symptomCode: ['S126'],
      selectType: 'Check',
      answers: [
        {
          label: '3일 이상 변을 보지 못했어요.',
          next: 'caba',
          value: 'caba',
          symptomCode: ['S740'],
        },
        {
          label: '대변이 딱딱해요.',
          next: 'cabb',
          value: 'cabb',
          symptomCode: ['S741'],
        },
        {
          label: '대변 보기가 어려워요.',
          next: 'cabc',
          value: 'cabc',
          symptomCode: ['S742'],
        },
        {
          label: '대변 보고 난 후에도 시원하지 않아요.',
          next: 'cabd',
          value: 'cabd',
          symptomCode: ['S743'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'caba',
    {
      label: '변비 양상',
      desc: ['3일 이상 변을 보지 못했어요.'],
      symptomCode: ['S740'],
      selectType: 'Scale',
    },
  ],
  [
    'cabb',
    {
      label: '변비 양상',
      desc: ['대변이 딱딱해요.'],
      symptomCode: ['S741'],
      selectType: 'Scale',
    },
  ],
  [
    'cabc',
    {
      label: '변비 양상',
      desc: ['대변 보기가 어려워요.'],
      symptomCode: ['S742'],
      selectType: 'Scale',
    },
  ],
  [
    'cabd',
    {
      label: '변비 양상',
      desc: ['대변 보고 난 후에도 시원하지 않아요.'],
      symptomCode: ['S743'],
      selectType: 'Scale',
    },
  ],
  [
    'cac',
    {
      label: '변혈 양상',
      desc: ['관련된 증상을 모두 선택하세요.'],
      symptomCode: ['S127'],
      selectType: 'Check',
      answers: [
        {
          label: '대변이 전체적으로 빨간색이에요.',
          next: 'caca',
          value: 'caca',
          symptomCode: ['S744'],
        },
        {
          label: '대변이 전체적으로 검은색이에요. ',
          next: 'cacb',
          value: 'cacb',
          symptomCode: ['S745'],
        },
        {
          label: '대변 끝에 피가 나오거나 휴지에 묻어요.',
          next: 'cacc',
          value: 'cacc',
          symptomCode: ['S746'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'caca',
    {
      label: '변혈 양상',
      desc: ['대변이 전체적으로 빨간색이에요.'],
      symptomCode: ['S744'],
      selectType: 'Scale',
    },
  ],
  [
    'cacb',
    {
      label: '변혈 양상',
      desc: ['대변이 전체적으로 검은색이에요. '],
      symptomCode: ['S745'],
      selectType: 'Scale',
    },
  ],
  [
    'cacc',
    {
      label: '변혈 양상',
      desc: ['대변 끝에 피가 나오거나 휴지에 묻어요.'],
      symptomCode: ['S746'],
      selectType: 'Scale',
    },
  ],
  [
    'cb',
    {
      label: '대변 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '과민성대장증후군',
          next: 'cba',
          value: 'cba',
          symptomCode: ['S030'],
        },
        {
          label: '치질',
          next: 'cbb',
          value: 'cbb',
          symptomCode: ['S296'],
        },
        {
          label: '탈항',
          next: 'cbc',
          value: 'cbc',
          symptomCode: ['S304'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'cba',
    {
      label: '대변 질환',
      desc: ['과민성대장증후군'],
      symptomCode: ['S030'],
      selectType: 'Onset',
    },
  ],
  [
    'cbb',
    {
      label: '대변 질환',
      desc: ['치질'],
      symptomCode: ['S296'],
      selectType: 'Onset',
    },
  ],
  [
    'cbc',
    {
      label: '대변 질환',
      desc: ['탈항'],
      symptomCode: ['S304'],
      selectType: 'Onset',
    },
  ],
  [
    'd',
    {
      label: '땀',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['da'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'da',
    {
      label: '땀',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '가만히 있어도 땀이 흥건하게 많이 나요.',
          next: ['daa', 'dae'],
          value: 'daa',
          symptomCode: ['S071'],
        },
        {
          label: '다른 사람들보다 땀이 잘 나는 편이에요.',
          next: ['dab'],
          value: 'dab',
          symptomCode: ['S089'],
        },
        {
          label: '운동을 해도 땀이 잘 나지 않아요.',
          next: ['dac'],
          value: 'dac',
          symptomCode: ['S112'],
        },
        {
          label: '식은땀이 나요.',
          next: ['dad'],
          value: 'dad',
          symptomCode: ['S193'],
        },
      ],
    },
  ],
  [
    'daa',
    {
      label: '땀',
      desc: ['가만히 있어도 땀이 흥건하게 많이 나요.'],
      symptomCode: ['S071'],
      selectType: 'Scale',
    },
  ],
  [
    'dab',
    {
      label: '땀',
      desc: ['다른 사람들보다 땀이 잘 나는 편이에요.'],
      symptomCode: ['S089'],
      selectType: 'Scale',
    },
  ],
  [
    'dac',
    {
      label: '땀',
      desc: ['운동을 해도 땀이 잘 나지 않아요.'],
      symptomCode: ['S112'],
      selectType: 'Scale',
    },
  ],
  [
    'dad',
    {
      label: '땀',
      desc: ['식은땀이 나요.'],
      symptomCode: ['S193'],
      selectType: 'Scale',
    },
  ],
  [
    'dae',
    {
      label: '발한 부위',
      desc: ['주로 땀이 나는 부위를 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '얼굴',
          value: 1,
          symptomCode: ['S747'],
        },
        {
          label: '목',
          value: 2,
          symptomCode: ['S748'],
        },
        {
          label: '가슴',
          value: 3,
          symptomCode: ['S749'],
        },
        {
          label: '등',
          value: 4,
          symptomCode: ['S750'],
        },
        {
          label: '겨드랑이',
          value: 5,
          symptomCode: ['S751'],
        },
        {
          label: '손, 발',
          value: 6,
          symptomCode: ['S752'],
        },
        {
          label: '사타구니',
          value: 7,
          symptomCode: ['S753'],
        },
        {
          label: '전신',
          value: 8,
          symptomCode: ['S754'],
        },
      ],
    },
  ],
  [
    'e',
    {
      label: '한열',
      desc: ['몸의 열감/냉감 또는 더위/추위와 관련하여', '문제가 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ea', 'eb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ea',
    {
      label: '더위/추위',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '현재 고열(38도 이상)이 있어요.',
          next: 'eaa',
          value: 'eaa',
          symptomCode: ['S021'],
        },
        {
          label: '살짝 열이 있어요.(37~38도)',
          next: 'eab',
          value: 'eab',
          symptomCode: ['S115'],
        },
        {
          label: '몸에 열감이 느껴져요.',
          next: 'eac',
          value: 'eac',
          symptomCode: ['S323'],
        },
        {
          label: '오한이 들어요.',
          next: 'ead',
          value: 'ead',
          symptomCode: ['S216'],
        },
        {
          label: '더웠다가 추웠다가 왔다갔다 해요.',
          next: 'eae',
          value: 'eae',
          symptomCode: ['S322'],
        },
        {
          label: '평소 추위를 많이 타요.',
          next: 'eaf',
          value: 'eaf',
          symptomCode: ['S759'],
        },
        {
          label: '평소 더위를 많이 타요.',
          next: 'eag',
          value: 'eag',
          symptomCode: ['S760'],
        },
      ],
    },
  ],
  [
    'eaa',
    {
      label: '더위/추위',
      desc: ['현재 고열(38도 이상)이 있어요.'],
      symptomCode: ['S021'],
      selectType: 'Scale',
    },
  ],
  [
    'eab',
    {
      label: '더위/추위',
      desc: ['살짝 열이 있어요.(37~38도)'],
      symptomCode: ['S115'],
      selectType: 'Scale',
    },
  ],
  [
    'eac',
    {
      label: '더위/추위',
      desc: ['몸에 열감이 느껴져요.'],
      symptomCode: ['S323'],
      selectType: 'Scale',
    },
  ],
  [
    'ead',
    {
      label: '더위/추위',
      desc: ['오한이 들어요.'],
      symptomCode: ['S216'],
      selectType: 'Scale',
    },
  ],
  [
    'eae',
    {
      label: '더위/추위',
      desc: ['더웠다가 추웠다가 왔다갔다 해요.'],
      symptomCode: ['S322'],
      selectType: 'Scale',
    },
  ],
  [
    'eaf',
    {
      label: '더위/추위',
      desc: ['평소 추위를 많이 타요.'],
      symptomCode: ['S759'],
      selectType: 'Scale',
    },
  ],
  [
    'eag',
    {
      label: '더위/추위',
      desc: ['평소 더위를 많이 타요.'],
      symptomCode: ['S760'],
      selectType: 'Scale',
    },
  ],
  [
    'eb',
    {
      label: '신체 한열',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '얼굴에 열이 훅 오를 때가 있어요.',
          next: 'eba',
          value: 'eba',
          symptomCode: ['S125'],
        },
        {
          label: '손발에 열이 나요.',
          next: 'ebb',
          value: 'ebb',
          symptomCode: ['S215'],
        },
        {
          label: '손발이 차요.',
          next: 'ebc',
          value: 'ebc',
          symptomCode: ['S184'],
        },
        {
          label: '온몸이 차요.',
          next: 'ebd',
          value: 'ebd',
          symptomCode: ['S197'],
        },
        {
          label: '배가 차요.',
          next: 'ebe',
          value: 'ebe',
          symptomCode: ['S128'],
        },
      ],
    },
  ],
  [
    'eba',
    {
      label: '신체 한열',
      desc: ['얼굴에 열이 훅 오를 때가 있어요.'],
      symptomCode: ['S125'],
      selectType: 'Scale',
    },
  ],
  [
    'ebb',
    {
      label: '신체 한열',
      desc: ['손발에 열이 나요.'],
      symptomCode: ['S215'],
      selectType: 'Scale',
    },
  ],
  [
    'ebc',
    {
      label: '신체 한열',
      desc: ['손발이 차요.'],
      symptomCode: ['S184'],
      selectType: 'Scale',
    },
  ],
  [
    'ebd',
    {
      label: '신체 한열',
      desc: ['온몸이 차요.'],
      symptomCode: ['S197'],
      selectType: 'Scale',
    },
  ],
  [
    'ebe',
    {
      label: '신체 한열',
      desc: ['배가 차요.'],
      symptomCode: ['S128'],
      selectType: 'Scale',
    },
  ],
  [
    'f',
    {
      label: '부종',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['fa'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'fa',
    {
      label: '부종',
      desc: ['평소에 잘 붓는 부위가 있으신가요?'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '전신',
          next: ['faa', 'fax'],
          value: 'faa',
          symptomCode: ['S135'],
        },
        {
          label: '얼굴',
          next: ['fab', 'fax'],
          value: 'fab',
          symptomCode: ['S084'],
        },
        {
          label: '눈/눈꺼풀',
          next: ['fac', 'fax'],
          value: 'fac',
          symptomCode: ['S106'],
        },
        {
          label: '피부',
          next: ['fad', 'fax'],
          value: 'fad',
          symptomCode: ['S318'],
        },
        {
          label: '다리',
          next: ['fae', 'fax'],
          value: 'fae',
          symptomCode: ['S321'],
        },
        {
          label: '손',
          next: ['fah', 'fax'],
          value: 'fah',
          symptomCode: ['S796'],
        },
        {
          label: '발',
          next: ['fai', 'fax'],
          value: 'fai',
          symptomCode: ['S797'],
        },
      ],
    },
  ],
  [
    'faa',
    {
      label: '부종',
      desc: ['전신'],
      symptomCode: ['S135'],
      selectType: 'Scale',
    },
  ],
  [
    'fab',
    {
      label: '부종',
      desc: ['얼굴'],
      symptomCode: ['S084'],
      selectType: 'Scale',
    },
  ],
  [
    'fac',
    {
      label: '부종',
      desc: ['눈/눈꺼풀'],
      symptomCode: ['S106'],
      selectType: 'Scale',
    },
  ],
  [
    'fad',
    {
      label: '부종',
      desc: ['피부'],
      symptomCode: ['S318'],
      selectType: 'Scale',
    },
  ],
  [
    'fae',
    {
      label: '부종',
      desc: ['다리'],
      symptomCode: ['S321'],
      selectType: 'Scale',
    },
  ],
  [
    'fah',
    {
      label: '부종',
      desc: ['손'],
      symptomCode: ['S796'],
      selectType: 'Scale',
    },
  ],
  [
    'fai',
    {
      label: '부종',
      desc: ['발'],
      symptomCode: ['S797'],
      selectType: 'Scale',
    },
  ],
  [
    'fax',
    {
      label: '붓는 시간',
      desc: ['주로 언제 붓나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '아침(기상 후)',
          value: 1,
          symptomCode: ['S761'],
        },
        {
          label: '저녁(활동 후)',
          value: 2,
          symptomCode: ['S762'],
        },
        {
          label: '종일',
          value: 3,
          symptomCode: ['S763'],
        },
      ],
    },
  ],
  [
    'fag',
    {
      label: '붓는 시간',
      desc: ['주로 언제 붓나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '아침(기상 후)',
          value: 1,
          symptomCode: ['S761'],
        },
        {
          label: '저녁(활동 후)',
          value: 2,
          symptomCode: ['S762'],
        },
        {
          label: '종일',
          value: 3,
          symptomCode: ['S763'],
        },
      ],
      deprecated: true,
    },
  ],
  [
    'g',
    {
      label: '감정/정신',
      desc: ['마음에 힘든 일이 있으신가요?', '(요즘 우울합니다, 화가 납니다,', '스트레스를 많이 받습니다)'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ga', 'gb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ga',
    {
      label: '감정/정신 질환',
      desc: ['진단받거나 앓고 있는 질환이 있으신가요?'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '조울증',
          next: 'gaa',
          value: 'gaa',
          symptomCode: ['S776'],
        },
        {
          label: '우울증',
          next: 'gab',
          value: 'gab',
          symptomCode: ['S777'],
        },
        {
          label: '강박장애',
          next: 'gac',
          value: 'gac',
          symptomCode: ['S778'],
        },
        {
          label: '공황장애',
          next: 'gad',
          value: 'gad',
          symptomCode: ['S779'],
        },
        {
          label: '범불안장애',
          next: 'gae',
          value: 'gae',
          symptomCode: ['S780'],
        },
        {
          label: '히스테리',
          next: 'gaf',
          value: 'gaf',
          symptomCode: ['S133'],
        },
        {
          label: '신경 쇠약',
          next: 'gag',
          value: 'gag',
          symptomCode: ['S276'],
        },
        {
          label: '조현병',
          next: 'gah',
          value: 'gah',
          symptomCode: ['S035'],
        },
        {
          label: '뇌전증',
          next: 'gai',
          value: 'gai',
          symptomCode: ['S270'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'gaa',
    {
      label: '감정/정신 질환',
      desc: ['조울증'],
      symptomCode: ['S776'],
      selectType: 'Onset',
    },
  ],
  [
    'gab',
    {
      label: '감정/정신 질환',
      desc: ['우울증'],
      symptomCode: ['S777'],
      selectType: 'Onset',
    },
  ],
  [
    'gac',
    {
      label: '감정/정신 질환',
      desc: ['강박장애'],
      symptomCode: ['S778'],
      selectType: 'Onset',
    },
  ],
  [
    'gad',
    {
      label: '감정/정신 질환',
      desc: ['공황장애'],
      symptomCode: ['S779'],
      selectType: 'Onset',
    },
  ],
  [
    'gae',
    {
      label: '감정/정신 질환',
      desc: ['범불안장애'],
      symptomCode: ['S780'],
      selectType: 'Onset',
    },
  ],
  [
    'gaf',
    {
      label: '감정/정신 질환',
      desc: ['히스테리'],
      symptomCode: ['S133'],
      selectType: 'Onset',
    },
  ],
  [
    'gag',
    {
      label: '감정/정신 질환',
      desc: ['신경 쇠약'],
      symptomCode: ['S276'],
      selectType: 'Onset',
    },
  ],
  [
    'gah',
    {
      label: '감정/정신 질환',
      desc: ['조현병'],
      symptomCode: ['S035'],
      selectType: 'Onset',
    },
  ],
  [
    'gai',
    {
      label: '감정/정신 질환',
      desc: ['뇌전증'],
      symptomCode: ['S270'],
      selectType: 'Onset',
    },
  ],
  [
    'gb',
    {
      label: '감정/정신',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '자주 심장이 두근거려요.',
          next: 'gba',
          value: 'gba',
          symptomCode: ['S199'],
        },
        {
          label: '잘 놀라요.',
          next: 'gbb',
          value: 'gbb',
          symptomCode: ['S015'],
        },
        {
          label: '화가 잘 나요.',
          next: 'gbc',
          value: 'gbc',
          symptomCode: ['S136'],
        },
        {
          label: '불안감을 자주 느껴요.',
          next: 'gbd',
          value: 'gbd',
          symptomCode: ['S139'],
        },
        {
          label: '자주 쉽게 우울해진다.',
          next: 'gbe',
          value: 'gbe',
          symptomCode: ['S227'],
        },
        {
          label: '잘 잊어버린다.',
          next: 'gbf',
          value: 'gbf',
          symptomCode: ['S010'],
        },
        {
          label: '정신이 무력하고 의욕이 없다.',
          next: 'gbg',
          value: 'gbg',
          symptomCode: ['S276'],
        },
      ],
    },
  ],
  [
    'gba',
    {
      label: '감정/정신',
      desc: ['자주 심장이 두근거려요.'],
      symptomCode: ['S199'],
      selectType: 'Scale',
    },
  ],
  [
    'gbb',
    {
      label: '감정/정신',
      desc: ['잘 놀라요.'],
      symptomCode: ['S015'],
      selectType: 'Scale',
    },
  ],
  [
    'gbc',
    {
      label: '감정/정신',
      desc: ['화가 잘 나요.'],
      symptomCode: ['S136'],
      selectType: 'Scale',
    },
  ],
  [
    'gbd',
    {
      label: '감정/정신',
      desc: ['불안감을 자주 느껴요.'],
      symptomCode: ['S139'],
      selectType: 'Scale',
    },
  ],
  [
    'gbe',
    {
      label: '감정/정신',
      desc: ['자주 쉽게 우울해진다.'],
      symptomCode: ['S227'],
      selectType: 'Scale',
    },
  ],
  [
    'gbf',
    {
      label: '감정/정신',
      desc: ['잘 잊어버린다.'],
      symptomCode: ['S010'],
      selectType: 'Scale',
    },
  ],
  [
    'gbg',
    {
      label: '감정/정신',
      desc: ['정신이 무력하고 의욕이 없다.'],
      symptomCode: ['S276'],
      selectType: 'Scale',
    },
  ],
  [
    'h',
    {
      label: '수면',
      desc: [
        '수면 시 문제가 있으신가요?',
        '(잠을 못 자서 피곤합니다,',
        '잠에 잘 들지 못합니다, 중간에 깹니다)',
      ],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ha'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ha',
    {
      label: '수면',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '잠을 잘 못자요.',
          next: 'haa',
          value: 'haa',
          symptomCode: ['S138'],
        },
        {
          label: '꿈을 많이 꿔요.',
          next: 'hab',
          value: 'hab',
          symptomCode: ['S069'],
        },
        {
          label: '자고 일어나도 피곤해요.',
          next: 'hac',
          value: 'hac',
          symptomCode: ['S273'],
        },
        {
          label: '잠에 잘 들지 못해요.',
          next: 'had',
          value: 'had',
          symptomCode: ['S781'],
        },
        {
          label: '잠을 자다가 중간에 잘 깨요.',
          next: 'hae',
          value: 'hae',
          symptomCode: ['S782'],
        },
        {
          label: '더 자고 싶은데 이른 시간에 깨요.',
          next: 'haf',
          value: 'haf',
          symptomCode: ['S783'],
        },
      ],
    },
  ],
  [
    'haa',
    {
      label: '수면',
      desc: ['잠을 잘 못자요.'],
      symptomCode: ['S138'],
      selectType: 'Scale',
    },
  ],
  [
    'hab',
    {
      label: '수면',
      desc: ['꿈을 많이 꿔요.'],
      symptomCode: ['S069'],
      selectType: 'Scale',
    },
  ],
  [
    'hac',
    {
      label: '수면',
      desc: ['자고 일어나도 피곤해요.'],
      symptomCode: ['S273'],
      selectType: 'Scale',
    },
  ],
  [
    'had',
    {
      label: '수면',
      desc: ['잠에 잘 들지 못해요.'],
      symptomCode: ['S781'],
      selectType: 'Scale',
    },
  ],
  [
    'hae',
    {
      label: '수면',
      desc: ['잠을 자다가 중간에 잘 깨요.'],
      symptomCode: ['S782'],
      selectType: 'Scale',
    },
  ],
  [
    'haf',
    {
      label: '수면',
      desc: ['더 자고 싶은데 이른 시간에 깨요.'],
      symptomCode: ['S783'],
      selectType: 'Scale',
    },
  ],
  [
    'i',
    {
      label: '남성',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '조루',
          next: 'ia',
          value: 'ia',
          symptomCode: ['S279'],
        },
        {
          label: '정액이 새어나와요.',
          next: 'ib',
          value: 'ib',
          symptomCode: ['S239'],
        },
        {
          label: '발기부전',
          next: 'ic',
          value: 'ic',
          symptomCode: ['S204', 'S275'],
        },
        {
          label: '발기지속',
          next: 'id',
          value: 'id',
          symptomCode: ['S284'],
        },
        {
          label: '고환이 붓거나 통증이 있어요.',
          next: 'ie',
          value: 'ie',
          symptomCode: ['S024'],
        },
        {
          label: '생식기와 아랫배가 당기고 아파요.',
          next: 'if',
          value: 'if',
          symptomCode: ['S150'],
        },
        {
          label: '사타구니가 자주 가려워요.',
          next: 'ig',
          value: 'ig',
          symptomCode: ['S243'],
        },
      ],
    },
  ],
  [
    'ia',
    {
      label: '남성',
      desc: ['조루'],
      symptomCode: ['S279'],
      selectType: 'Scale',
    },
  ],
  [
    'ib',
    {
      label: '남성',
      desc: ['정액이 새어나와요.'],
      symptomCode: ['S239'],
      selectType: 'Scale',
    },
  ],
  [
    'ic',
    {
      label: '남성',
      desc: ['발기부전'],
      symptomCode: ['S204', 'S275'],
      selectType: 'Scale',
    },
  ],
  [
    'id',
    {
      label: '남성',
      desc: ['발기지속'],
      symptomCode: ['S284'],
      selectType: 'Scale',
    },
  ],
  [
    'ie',
    {
      label: '남성',
      desc: ['고환이 붓거나 통증이 있어요.'],
      symptomCode: ['S024'],
      selectType: 'Scale',
    },
  ],
  [
    'if',
    {
      label: '남성',
      desc: ['생식기와 아랫배가 당기고 아파요.'],
      symptomCode: ['S150'],
      selectType: 'Scale',
    },
  ],
  [
    'ig',
    {
      label: '남성',
      desc: ['사타구니가 자주 가려워요.'],
      symptomCode: ['S243'],
      selectType: 'Scale',
    },
  ],
  [
    'j',
    {
      label: '임신/출산',
      desc: ['임신 중이시거나', '혹은 임신/출산 경험이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '예, 임신/출산 경험이 있습니다.',
          next: ['ja', 'jb', 'jc', 'jd'],
          value: 1,
        },
        {
          label: '아니요, 임신/출산 경험이 없습니다.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ja',
    {
      label: '산과력',
      desc: ['출산 경험이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '출산 경험 0회',
          value: 0,
          symptomCode: ['S784'],
        },
        {
          label: '출산 경험 1회',
          value: 1,
          symptomCode: ['S785'],
        },
        {
          label: '출산 경험 2회',
          value: 2,
          symptomCode: ['S786'],
        },
        {
          label: '출산 경험 3회 이상',
          value: 3,
          symptomCode: ['S787'],
        },
      ],
    },
  ],
  [
    'jb',
    {
      label: '산과력',
      desc: ['조산(37주 미만) 경험이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '조산 경험 0회',
          value: 0,
          symptomCode: ['S788'],
        },
        {
          label: '조산 경험 1회',
          value: 1,
          symptomCode: ['S789'],
        },
        {
          label: '조산 경험 2회',
          value: 2,
          symptomCode: ['S790'],
        },
        {
          label: '조산 경험 3회 이상',
          value: 3,
          symptomCode: ['S791'],
        },
      ],
    },
  ],
  [
    'jc',
    {
      label: '산과력',
      desc: ['유산 경험이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '유산 경험 0회',
          value: 0,
          symptomCode: ['S792'],
        },
        {
          label: '유산 경험 1회',
          value: 1,
          symptomCode: ['S793'],
        },
        {
          label: '유산 경험 2회',
          value: 2,
          symptomCode: ['S794'],
        },
        {
          label: '유산 경험 3회 이상',
          value: 3,
          symptomCode: ['S795'],
        },
      ],
    },
  ],
  [
    'jd',
    {
      label: '임신/출산',
      desc: ['관련된 증상을 하나 선택하세요.'],
      selectType: 'Radio',
      answers: [
        {
          label: '해당되지 않아요.',
          value: 0,
        },
        {
          label: '현재 임산부에요.',
          next: 'jda',
          value: 'jda',
        },
        {
          label: '출산한 지 3개월 이내에요.',
          next: 'jdb',
          value: 'jdb',
        },
      ],
    },
  ],
  [
    'jda',
    {
      label: '임산부',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '입덧',
          next: 'jdaa',
          value: 'jdaa',
          symptomCode: ['S254'],
        },
        {
          label: '하혈',
          next: 'jdab',
          value: 'jdab',
          symptomCode: ['S256'],
        },
        {
          label: '임신중독증',
          next: 'jdac',
          value: 'jdac',
          symptomCode: ['S255'],
        },
        {
          label: '태동불안',
          next: 'jdad',
          value: 'jdad',
          symptomCode: ['S305'],
        },
        {
          label: '조기 진통',
          next: 'jdae',
          value: 'jdae',
          symptomCode: ['S306'],
        },
      ],
    },
  ],
  [
    'jdaa',
    {
      label: '임산부',
      desc: ['입덧'],
      symptomCode: ['S254'],
      selectType: 'Scale',
    },
  ],
  [
    'jdab',
    {
      label: '임산부',
      desc: ['하혈'],
      symptomCode: ['S256'],
      selectType: 'Scale',
    },
  ],
  [
    'jdac',
    {
      label: '임산부',
      desc: ['임신중독증'],
      symptomCode: ['S255'],
      selectType: 'Scale',
    },
  ],
  [
    'jdad',
    {
      label: '임산부',
      desc: ['태동불안'],
      symptomCode: ['S305'],
      selectType: 'Scale',
    },
  ],
  [
    'jdae',
    {
      label: '임산부',
      desc: ['조기 진통'],
      symptomCode: ['S306'],
      selectType: 'Scale',
    },
  ],
  [
    'jdb',
    {
      label: '산후',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '잔류 태반',
          next: 'jdba',
          value: 'jdba',
          symptomCode: ['S264'],
        },
        {
          label: '오로',
          next: 'jdbb',
          value: 'jdbb',
          symptomCode: ['S214'],
        },
        {
          label: '출산 후 복통',
          next: 'jdbc',
          value: 'jdbc',
          symptomCode: ['S152'],
        },
        {
          label: '소변 이상',
          next: 'jdbd',
          value: 'jdbd',
          symptomCode: ['S154'],
        },
        {
          label: '출산 후 부종',
          next: 'jdbe',
          value: 'jdbe',
          symptomCode: ['S153'],
        },
        {
          label: '출산 후 유방통',
          next: 'jdbf',
          value: 'jdbf',
          symptomCode: ['S155', 'S013'],
        },
        {
          label: '출산 후 발열',
          next: 'jdbg',
          value: 'jdbg',
          symptomCode: ['S151'],
        },
        {
          label: '출산 후 빈혈',
          next: 'jdbh',
          value: 'jdbh',
          symptomCode: ['S156'],
        },
        {
          label: '계류 유산',
          next: 'jdbi',
          value: 'jdbi',
          symptomCode: ['S149'],
        },
        {
          label: '조기 출산',
          next: 'jdbj',
          value: 'jdbj',
          symptomCode: ['S277'],
        },
      ],
    },
  ],
  [
    'jdba',
    {
      label: '산후',
      desc: ['잔류 태반'],
      symptomCode: ['S264'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbb',
    {
      label: '산후',
      desc: ['오로'],
      symptomCode: ['S214'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbc',
    {
      label: '산후',
      desc: ['출산 후 복통'],
      symptomCode: ['S152'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbd',
    {
      label: '산후',
      desc: ['소변 이상'],
      symptomCode: ['S154'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbe',
    {
      label: '산후',
      desc: ['출산 후 부종'],
      symptomCode: ['S153'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbf',
    {
      label: '산후',
      desc: ['출산 후 유방통'],
      symptomCode: ['S155', 'S013'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbg',
    {
      label: '산후',
      desc: ['출산 후 발열'],
      symptomCode: ['S151'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbh',
    {
      label: '산후',
      desc: ['출산 후 빈혈'],
      symptomCode: ['S156'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbi',
    {
      label: '산후',
      desc: ['계류 유산'],
      symptomCode: ['S149'],
      selectType: 'Scale',
    },
  ],
  [
    'jdbj',
    {
      label: '산후',
      desc: ['조기 출산'],
      symptomCode: ['S277'],
      selectType: 'Scale',
    },
  ],
  [
    'k',
    {
      label: '월경/불임',
      desc: ['현재 월경, 불임 관련 불편한 증상이 있으신가요?'],
      selectType: 'Check',
      answers: [
        {
          label: '월경 관련하여 불편함이 있어요.',
          next: ['ka', 'kb'],
          value: 1,
          symptomCode: ['S231'],
        },
        {
          label: '불임 증상이 있어요.',
          next: ['ka', 'kb', 'kc'],
          value: 2,
          symptomCode: ['S141'],
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ka',
    {
      label: '월경 주기',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '월경 주기가 평소보다 길어졌어요.',
          next: ['kaa'],
          value: 'kaa',
          symptomCode: ['S233'],
        },
        {
          label: '월경 주기가 평소보다 짧아졌어요.',
          next: ['kab'],
          value: 'kab',
          symptomCode: ['S770'],
        },
        {
          label: '최근 월경을 아예 안하거나, 매우 드물게 해요.',
          next: ['kac', 'kad'],
          value: 'kac',
          symptomCode: ['S019'],
        },
      ],
    },
  ],
  [
    'kaa',
    {
      label: '월경 주기',
      desc: ['월경 주기가 평소보다 길어졌어요.'],
      symptomCode: ['S233'],
      selectType: 'Scale',
    },
  ],
  [
    'kab',
    {
      label: '월경 주기',
      desc: ['월경 주기가 평소보다 짧아졌어요.'],
      symptomCode: ['S770'],
      selectType: 'Scale',
    },
  ],
  [
    'kac',
    {
      label: '월경 주기',
      desc: ['최근 월경을 아예 안하거나, 매우 드물게 해요.'],
      symptomCode: ['S019'],
      selectType: 'Scale',
    },
  ],
  [
    'kad',
    {
      label: '마지막 월경 일',
      desc: ['마지막 월경 일은 언제인가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '2~3개월 전',
          value: 1,
          symptomCode: ['S814'],
        },
        {
          label: '3~6개월 전',
          value: 2,
          symptomCode: ['S815'],
        },
        {
          label: '6~12개월 전',
          value: 3,
          symptomCode: ['S816'],
        },
        {
          label: '1년 이상',
          value: 4,
          symptomCode: ['S817'],
        },
      ],
    },
  ],
  [
    'kb',
    {
      label: '월경',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '월경통이 심해요.',
          next: 'kba',
          value: 'kba',
          symptomCode: ['S308'],
        },
        {
          label: '월경 전 복통이 있어요.',
          next: 'kbb',
          value: 'kbb',
          symptomCode: ['S017'],
        },
        {
          label: '월경 전 유방통증이 있어요.',
          next: 'kbc',
          value: 'kbc',
          symptomCode: ['S018'],
        },
        {
          label: '월경 시 빈혈이 있어요.',
          next: 'kbd',
          value: 'kbd',
          symptomCode: ['S132'],
        },
        {
          label: '월경 양이 많아졌어요.',
          next: 'kbe',
          value: 'kbe',
          symptomCode: ['S230'],
        },
        {
          label: '월경 양이 적어졌어요.',
          next: 'kbf',
          value: 'kbf',
          symptomCode: ['S800'],
        },
        {
          label: '월경 색이 진하고 덩어리가 많아요.',
          next: 'kbg',
          value: 'kbg',
          symptomCode: ['S232'],
        },
      ],
    },
  ],
  [
    'kba',
    {
      label: '월경',
      desc: ['월경통이 심해요.'],
      symptomCode: ['S308'],
      selectType: 'Scale',
    },
  ],
  [
    'kbb',
    {
      label: '월경',
      desc: ['월경 전 복통이 있어요.'],
      symptomCode: ['S017'],
      selectType: 'Scale',
    },
  ],
  [
    'kbc',
    {
      label: '월경',
      desc: ['월경 전 유방통증이 있어요.'],
      symptomCode: ['S018'],
      selectType: 'Scale',
    },
  ],
  [
    'kbd',
    {
      label: '월경',
      desc: ['월경 시 빈혈이 있어요.'],
      symptomCode: ['S132'],
      selectType: 'Scale',
    },
  ],
  [
    'kbe',
    {
      label: '월경',
      desc: ['월경 양이 많아졌어요.'],
      symptomCode: ['S230'],
      selectType: 'Scale',
    },
  ],
  [
    'kbf',
    {
      label: '월경',
      desc: ['월경 양이 적어졌어요.'],
      symptomCode: ['S800'],
      selectType: 'Scale',
    },
  ],
  [
    'kbg',
    {
      label: '월경',
      desc: ['월경 색이 진하고 덩어리가 많아요.'],
      symptomCode: ['S232'],
      selectType: 'Scale',
    },
  ],
  [
    'kc',
    {
      label: '자궁/생식기',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '부정 자궁 출혈',
          next: 'kca',
          value: 'kca',
          symptomCode: ['S142'],
        },
        {
          label: '자궁하수',
          next: 'kcb',
          value: 'kcb',
          symptomCode: ['S260'],
        },
        {
          label: '자궁근종, 신생물, 물혹 등',
          next: 'kcc',
          value: 'kcc',
          symptomCode: ['S130'],
        },
        {
          label: '냉이 많다.',
          next: 'kcd',
          value: 'kcd',
          symptomCode: ['S077'],
        },
        {
          label: '냉이 뿌예요.',
          next: 'kce',
          value: 'kce',
          symptomCode: ['S119'],
        },
        {
          label: '냉에 피가 비쳐요.',
          next: 'kcf',
          value: 'kcf',
          symptomCode: ['S078'],
        },
        {
          label: '아랫배가 차요.',
          next: 'kcg',
          value: 'kcg',
          symptomCode: ['S258'],
        },
        {
          label: '생식기가 가려워요.',
          next: 'kch',
          value: 'kch',
          symptomCode: ['S244'],
        },
      ],
    },
  ],
  [
    'kca',
    {
      label: '자궁/생식기',
      desc: ['부정 자궁 출혈'],
      symptomCode: ['S142'],
      selectType: 'Scale',
    },
  ],
  [
    'kcb',
    {
      label: '자궁/생식기',
      desc: ['자궁하수'],
      symptomCode: ['S260'],
      selectType: 'Scale',
    },
  ],
  [
    'kcc',
    {
      label: '자궁/생식기',
      desc: ['자궁근종, 신생물, 물혹 등'],
      symptomCode: ['S130'],
      selectType: 'Scale',
    },
  ],
  [
    'kcd',
    {
      label: '자궁/생식기',
      desc: ['냉이 많다.'],
      symptomCode: ['S077'],
      selectType: 'Scale',
    },
  ],
  [
    'kce',
    {
      label: '자궁/생식기',
      desc: ['냉이 뿌예요.'],
      symptomCode: ['S119'],
      selectType: 'Scale',
    },
  ],
  [
    'kcf',
    {
      label: '자궁/생식기',
      desc: ['냉에 피가 비쳐요.'],
      symptomCode: ['S078'],
      selectType: 'Scale',
    },
  ],
  [
    'kcg',
    {
      label: '자궁/생식기',
      desc: ['아랫배가 차요.'],
      symptomCode: ['S258'],
      selectType: 'Scale',
    },
  ],
  [
    'kch',
    {
      label: '자궁/생식기',
      desc: ['생식기가 가려워요.'],
      symptomCode: ['S244'],
      selectType: 'Scale',
    },
  ],
  [
    'l',
    {
      label: '갱년기/유방',
      desc: ['해당 되는 증상을 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '갱년기',
          next: 'la',
          value: 'la',
        },
        {
          label: '유방통',
          next: 'lb',
          value: 'lb',
          symptomCode: ['S236'],
        },
        {
          label: '유방의 멍울/덩어리',
          next: 'lc',
          value: 'lc',
          symptomCode: ['S238', 'S240', 'S241'],
        },
      ],
    },
  ],
  [
    'la',
    {
      label: '갱년기',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '안면홍조',
          next: 'laa',
          value: 'laa',
          symptomCode: ['S335'],
        },
        {
          label: '등 뒤 땀',
          next: 'lab',
          value: 'lab',
          symptomCode: ['S824'],
        },
        {
          label: '우울감',
          next: 'lac',
          value: 'lac',
          symptomCode: ['S227'],
        },
        {
          label: '가슴 두근거림',
          next: 'lad',
          value: 'lad',
          symptomCode: ['S199'],
        },
        {
          label: '질 건조',
          next: 'lae',
          value: 'lae',
          symptomCode: ['S827'],
        },
      ],
    },
  ],
  [
    'laa',
    {
      label: '갱년기',
      desc: ['안면홍조'],
      symptomCode: ['S335'],
      selectType: 'Scale',
    },
  ],
  [
    'lab',
    {
      label: '갱년기',
      desc: ['등 뒤 땀'],
      symptomCode: ['S824'],
      selectType: 'Scale',
    },
  ],
  [
    'lac',
    {
      label: '갱년기',
      desc: ['우울감'],
      symptomCode: ['S227'],
      selectType: 'Scale',
    },
  ],
  [
    'lad',
    {
      label: '갱년기',
      desc: ['가슴 두근거림'],
      symptomCode: ['S199'],
      selectType: 'Scale',
    },
  ],
  [
    'lae',
    {
      label: '갱년기',
      desc: ['질 건조'],
      symptomCode: ['S827'],
      selectType: 'Scale',
    },
  ],
  [
    'lb',
    {
      label: '갱년기/유방',
      desc: ['유방통'],
      symptomCode: ['S236'],
      selectType: 'Scale',
    },
  ],
  [
    'lc',
    {
      label: '갱년기/유방',
      desc: ['유방의 멍울/덩어리'],
      symptomCode: ['S238', 'S240', 'S241'],
      selectType: 'Scale',
    },
  ],
  [
    'm',
    {
      label: '소아',
      desc: ['해당 되는 증상을 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '감기에 잘 걸려요.(허약)',
          next: 'ma',
          value: 'ma',
          symptomCode: ['S172'],
        },
        {
          label: '밥을 잘 안먹어요.',
          next: 'mb',
          value: 'mb',
          symptomCode: ['S175'],
        },
        {
          label: '또래에 비해 키가 작아요.',
          next: 'mc',
          value: 'mc',
          symptomCode: ['S172'],
        },
        {
          label: '영양실조',
          next: 'md',
          value: 'md',
          symptomCode: ['S008'],
        },
        {
          label: '볼거리',
          next: 'me',
          value: 'me',
          symptomCode: ['S261'],
        },
        {
          label: '소아마비',
          value: 1,
          symptomCode: ['S171'],
        },
        {
          label: '경련',
          next: 'mf',
          value: 'mf',
          symptomCode: ['S170'],
        },
      ],
    },
  ],
  [
    'ma',
    {
      label: '소아',
      desc: ['감기에 잘 걸려요.(허약)'],
      symptomCode: ['S172'],
      selectType: 'Scale',
    },
  ],
  [
    'mb',
    {
      label: '소아',
      desc: ['밥을 잘 안먹어요.'],
      symptomCode: ['S175'],
      selectType: 'Scale',
    },
  ],
  [
    'mc',
    {
      label: '소아',
      desc: ['또래에 비해 키가 작아요.'],
      symptomCode: ['S172'],
      selectType: 'Scale',
    },
  ],
  [
    'md',
    {
      label: '소아',
      desc: ['영양실조'],
      symptomCode: ['S008'],
      selectType: 'Scale',
    },
  ],
  [
    'me',
    {
      label: '소아',
      desc: ['볼거리'],
      symptomCode: ['S261'],
      selectType: 'Scale',
    },
  ],
  [
    'mf',
    {
      label: '소아',
      desc: ['경련'],
      symptomCode: ['S170'],
      selectType: 'Scale',
    },
  ],
  [
    'A',
    {
      label: '피로/면역력',
      desc: ['피로/면역력 관련하여 불편함이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['Aa', 'Ab', 'Ac', 'Ad', 'Ae', 'Af', 'Ag', 'Ah', 'Ai', 'Aj', 'Ak'],
          value: 1,
          symptomCode: ['S056', 'S273'],
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'Aa',
    {
      label: '수면 시간',
      desc: ['하루 평균 수면 시간을 선택해주세요.'],
      selectType: 'Radio',
      answers: [
        {
          label: '6시간 미만',
          value: 1,
          symptomCode: ['S453', 'S138'],
        },
        {
          label: '6 ~ 7시간',
          value: 2,
          symptomCode: ['S454'],
        },
        {
          label: '7 ~ 8시간',
          value: 3,
          symptomCode: ['S455'],
        },
        {
          label: '8 ~ 9시간',
          value: 4,
          symptomCode: ['S456'],
        },
        {
          label: '9시간 이상',
          value: 5,
          symptomCode: ['S457'],
        },
      ],
    },
  ],
  [
    'Ab',
    {
      label: '식사 횟수',
      desc: ['하루 식사 횟수는 어떻게 되시나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '1회',
          value: 1,
          symptomCode: ['S406'],
        },
        {
          label: '2회',
          value: 2,
          symptomCode: ['S407'],
        },
        {
          label: '3회',
          value: 3,
          symptomCode: ['S408'],
        },
        {
          label: '4회 이상',
          value: 4,
          symptomCode: ['S409'],
        },
      ],
    },
  ],
  [
    'Ac',
    {
      label: '식사간격',
      desc: ['평소 식사간격은 규척적이신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '매일 규칙적이에요.',
          value: 1,
          symptomCode: ['S410'],
        },
        {
          label: '가끔 불규칙적이에요.',
          value: 2,
          symptomCode: ['S411'],
        },
        {
          label: '불규칙적이에요.',
          value: 3,
          symptomCode: ['S412'],
        },
      ],
    },
  ],
  [
    'Ad',
    {
      label: '식사시간',
      desc: ['한 끼 식사를 하는데', '어느 정도 시간이 드시나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '10분 미만',
          value: 1,
          symptomCode: ['S413'],
        },
        {
          label: '10~30분',
          value: 2,
          symptomCode: ['S414'],
        },
        {
          label: '30분 이상',
          value: 3,
          symptomCode: ['S415'],
        },
      ],
    },
  ],
  [
    'Ae',
    {
      label: '과식',
      desc: ['평소 과식을 얼마나 자주 하시나요?', '(평소 식사보다 많은 양을 섭취한 경우)'],
      selectType: 'Radio',
      answers: [
        {
          label: '거의 하지 않아요.',
          value: 1,
          symptomCode: ['S416'],
        },
        {
          label: '주 1~3회',
          value: 2,
          symptomCode: ['S417'],
        },
        {
          label: '주 4~6회',
          value: 3,
          symptomCode: ['S418'],
        },
        {
          label: '매일 과식을 해요.',
          value: 4,
          symptomCode: ['S419'],
        },
      ],
    },
  ],
  [
    'Af',
    {
      label: '야식',
      desc: ['평소 야식은 얼마나 자주 드시나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '거의 먹지 않는다.',
          value: 1,
          symptomCode: ['S420'],
        },
        {
          label: '주 1~3회',
          value: 2,
          symptomCode: ['S421'],
        },
        {
          label: '주 4~6회',
          value: 3,
          symptomCode: ['S422'],
        },
        {
          label: '매일 야식을 먹어요.',
          value: 4,
          symptomCode: ['S423'],
        },
      ],
    },
  ],
  [
    'Ag',
    {
      label: '중강도 운동',
      desc: [
        '숨이 약간 차거나 심장이 약간 빠르게 뛰는',
        '중강도 운동(빠르게 걷기, 요가)을',
        '얼마나 하시나요?',
      ],
      selectType: 'Radio',
      answers: [
        {
          label: '거의 하지 않는다.',
          value: 1,
          symptomCode: ['S424'],
        },
        {
          label: '주 1~2회',
          value: 2,
          symptomCode: ['S425'],
        },
        {
          label: '주 3~4회',
          value: 3,
          symptomCode: ['S426'],
        },
        {
          label: '주 5회 이상',
          value: 4,
          symptomCode: ['S427'],
        },
      ],
    },
  ],
  [
    'Ah',
    {
      label: '고강도 운동',
      desc: [
        '숨이 많이 차거나 심장이 많이 빠르게 뛰는',
        '고강도 운동(빠르게 자전거 타기, 에어로빅)을',
        '얼마나 하시나요?',
      ],
      selectType: 'Radio',
      answers: [
        {
          label: '거의 하지 않는다.',
          value: 1,
          symptomCode: ['S428'],
        },
        {
          label: '주 1~2회',
          value: 2,
          symptomCode: ['S429'],
        },
        {
          label: '주 3~4회',
          value: 3,
          symptomCode: ['S430'],
        },
        {
          label: '주 5회 이상',
          value: 4,
          symptomCode: ['S431'],
        },
      ],
    },
  ],
  [
    'Ai',
    {
      label: '흡연',
      desc: ['흡연을 하시나요?', '(연초, 전자담배 포함)'],
      selectType: 'Radio',
      answers: [
        {
          label: '아니요.',
          value: 0,
        },
        {
          label: '이전에 피우다 현재는 금연 중이에요.',
          next: ['Aia', 'Aib'],
          value: 1,
          symptomCode: ['S432'],
        },
        {
          label: '예, 흡연을 하고 있어요.',
          next: ['Aia', 'Aib'],
          value: 2,
          symptomCode: ['S433'],
        },
      ],
    },
  ],
  [
    'Aia',
    {
      label: '흡연 기간',
      desc: ['흡연 기간은 어떻게 되시나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '1년 미만',
          value: 1,
          symptomCode: ['S434'],
        },
        {
          label: '1~5년',
          value: 2,
          symptomCode: ['S435'],
        },
        {
          label: '6~10년',
          value: 3,
          symptomCode: ['S436'],
        },
        {
          label: '10년 이상',
          value: 4,
          symptomCode: ['S437'],
        },
      ],
    },
  ],
  [
    'Aib',
    {
      label: '흡연량',
      desc: ['하루평균 흡연량이 어떻게 되시나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '10개피 미만',
          value: 1,
          symptomCode: ['S438'],
        },
        {
          label: '10개피 ~ 1갑',
          value: 2,
          symptomCode: ['S439'],
        },
        {
          label: '1~2갑',
          value: 3,
          symptomCode: ['S440'],
        },
        {
          label: '2갑 이상',
          value: 4,
          symptomCode: ['S441'],
        },
      ],
    },
  ],
  [
    'Aj',
    {
      label: '음주',
      desc: ['음주를 얼마나 자주 하시나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '거의 마시지 않아요.',
          value: 0,
        },
        {
          label: '주 1~3회',
          next: ['Aja'],
          value: 1,
          symptomCode: ['S442'],
        },
        {
          label: '주 4~6회',
          next: ['Aja'],
          value: 2,
          symptomCode: ['S443'],
        },
        {
          label: '매일 마셔요.',
          next: ['Aja'],
          value: 3,
          symptomCode: ['S444'],
        },
      ],
    },
  ],
  [
    'Aja',
    {
      label: '음주량',
      desc: [
        '평균 음주량은 어떻게 되시나요?',
        '(소주 1병(330ml) = 7잔,',
        ' 맥주 1캔(335ml) = 2잔,',
        ' 맥주 1병(500ml) = 3잔)',
      ],
      selectType: 'Radio',
      answers: [
        {
          label: '1~2잔',
          value: 1,
          symptomCode: ['S445'],
        },
        {
          label: '3~4잔',
          value: 2,
          symptomCode: ['S446'],
        },
        {
          label: '5~6잔',
          value: 3,
          symptomCode: ['S447'],
        },
        {
          label: '7~9잔',
          value: 4,
          symptomCode: ['S448'],
        },
        {
          label: '10잔 이상',
          value: 5,
          symptomCode: ['S449'],
        },
      ],
    },
  ],
  [
    'Ak',
    {
      label: '커피',
      desc: ['하루 평균 커피를 얼마나 드시나요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '커피를 마시지 않아요.',
          value: 0,
        },
        {
          label: '1~2잔',
          value: 1,
          symptomCode: ['S450'],
        },
        {
          label: '3~4잔',
          value: 2,
          symptomCode: ['S451'],
        },
        {
          label: '5잔 이상',
          value: 3,
          symptomCode: ['S452'],
        },
      ],
    },
  ],
  [
    'n',
    {
      label: '머리/뒷목',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['na', 'nb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'na',
    {
      label: '머리/뒷목',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '두통',
          next: 'naa',
          value: 'naa',
        },
        {
          label: '어지럼증',
          next: 'nab',
          value: 'nab',
          symptomCode: ['S325'],
        },
      ],
    },
  ],
  [
    'naa',
    {
      label: '두통 부위',
      desc: ['두통이 있는 부위는 어디인가요?'],
      selectType: 'Check',
      answers: [
        {
          label: '눈 주위',
          next: 'naaa',
          value: 'naaa',
          symptomCode: ['S312'],
        },
        {
          label: '귀 주위',
          next: 'naab',
          value: 'naab',
          symptomCode: ['S312'],
        },
        {
          label: '이마',
          next: 'naac',
          value: 'naac',
          symptomCode: ['S087'],
        },
        {
          label: '정수리',
          next: 'naad',
          value: 'naad',
          symptomCode: ['S087'],
        },
        {
          label: '뒷 목',
          next: 'naae',
          value: 'naae',
          symptomCode: ['S087'],
        },
        {
          label: '전체',
          next: 'naaf',
          value: 'naaf',
          symptomCode: ['S087'],
        },
      ],
    },
  ],
  [
    'naaa',
    {
      label: '두통 부위',
      desc: ['눈 주위'],
      symptomCode: ['S312'],
      selectType: 'Scale',
    },
  ],
  [
    'naab',
    {
      label: '두통 부위',
      desc: ['귀 주위'],
      symptomCode: ['S312'],
      selectType: 'Scale',
    },
  ],
  [
    'naac',
    {
      label: '두통 부위',
      desc: ['이마'],
      symptomCode: ['S087'],
      selectType: 'Scale',
    },
  ],
  [
    'naad',
    {
      label: '두통 부위',
      desc: ['정수리'],
      symptomCode: ['S087'],
      selectType: 'Scale',
    },
  ],
  [
    'naae',
    {
      label: '두통 부위',
      desc: ['뒷 목'],
      symptomCode: ['S087'],
      selectType: 'Scale',
    },
  ],
  [
    'naaf',
    {
      label: '두통 부위',
      desc: ['전체'],
      symptomCode: ['S087'],
      selectType: 'Scale',
    },
  ],
  [
    'nab',
    {
      label: '어지럼증 양상',
      desc: ['어지럼증 양상은 어떠한가요?'],
      symptomCode: ['S325'],
      selectType: 'Check',
      answers: [
        {
          label: '가만히 있어도 어지러워요.',
          next: 'naba',
          value: 'naba',
          symptomCode: ['S709'],
        },
        {
          label: '앉았다 일어서면 어지러워요.',
          next: 'nabb',
          value: 'nabb',
          symptomCode: ['S710'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'naba',
    {
      label: '어지럼증 양상',
      desc: ['가만히 있어도 어지러워요.'],
      symptomCode: ['S709'],
      selectType: 'Scale',
    },
  ],
  [
    'nabb',
    {
      label: '어지럼증 양상',
      desc: ['앉았다 일어서면 어지러워요.'],
      symptomCode: ['S710'],
      selectType: 'Scale',
    },
  ],
  [
    'nb',
    {
      label: '머리/뒷목 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '군발성 두통',
          next: 'nba',
          value: 'naa',
          symptomCode: ['S701'],
        },
        {
          label: '긴장성 두통',
          next: 'nbb',
          value: 'nab',
          symptomCode: ['S702'],
        },
        {
          label: '편두통',
          next: 'nbc',
          value: 'nac',
          symptomCode: ['S703', 'S312'],
        },
        {
          label: '빈혈',
          next: 'nbd',
          value: 'nad',
          symptomCode: ['S704'],
        },
        {
          label: '이석증',
          next: 'nbe',
          value: 'nae',
          symptomCode: ['S705'],
        },
        {
          label: '메니에르병',
          next: 'nbf',
          value: 'naf',
          symptomCode: ['S706'],
        },
        {
          label: '중풍후유증',
          next: 'nbg',
          value: 'nag',
          symptomCode: ['S707'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'nba',
    {
      label: '머리/뒷목 질환',
      desc: ['군발성 두통'],
      symptomCode: ['S701'],
      selectType: 'Onset',
    },
  ],
  [
    'nbb',
    {
      label: '머리/뒷목 질환',
      desc: ['긴장성 두통'],
      symptomCode: ['S702'],
      selectType: 'Onset',
    },
  ],
  [
    'nbc',
    {
      label: '머리/뒷목 질환',
      desc: ['편두통'],
      symptomCode: ['S703', 'S312'],
      selectType: 'Onset',
    },
  ],
  [
    'nbd',
    {
      label: '머리/뒷목 질환',
      desc: ['빈혈'],
      symptomCode: ['S704'],
      selectType: 'Onset',
    },
  ],
  [
    'nbe',
    {
      label: '머리/뒷목 질환',
      desc: ['이석증'],
      symptomCode: ['S705'],
      selectType: 'Onset',
    },
  ],
  [
    'nbf',
    {
      label: '머리/뒷목 질환',
      desc: ['메니에르병'],
      symptomCode: ['S706'],
      selectType: 'Onset',
    },
  ],
  [
    'nbg',
    {
      label: '머리/뒷목 질환',
      desc: ['중풍후유증'],
      symptomCode: ['S707'],
      selectType: 'Onset',
    },
  ],
  [
    'o',
    {
      label: '귀',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['oa', 'ob'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'oa',
    {
      label: '귀',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '귀에서 진물이 나와요.',
          next: 'oaa',
          value: 'oaa',
          symptomCode: ['S248'],
        },
        {
          label: '잘 들리지 않아요.(난청)',
          next: 'oab',
          value: 'oab',
          symptomCode: ['S249'],
        },
        {
          label: '이명이 있어요.',
          next: 'oac',
          value: 'oac',
          symptomCode: ['S250'],
        },
      ],
    },
  ],
  [
    'oaa',
    {
      label: '귀',
      desc: ['귀에서 진물이 나와요.'],
      symptomCode: ['S248'],
      selectType: 'Scale',
    },
  ],
  [
    'oab',
    {
      label: '귀',
      desc: ['잘 들리지 않아요.(난청)'],
      symptomCode: ['S249'],
      selectType: 'Scale',
    },
  ],
  [
    'oac',
    {
      label: '이명 양상',
      desc: ['이명의 양상은 어떠한가요?'],
      symptomCode: ['S250'],
      selectType: 'Check',
      answers: [
        {
          label: '시냇물 흐르는 소리가 들려요.',
          next: 'oaca',
          value: 'oaca',
          symptomCode: ['S711'],
        },
        {
          label: '삐 하는 소리가 들려요.',
          next: 'oacb',
          value: 'oacb',
          symptomCode: ['S712'],
        },
        {
          label: '바람소리가 들려요.',
          next: 'oacc',
          value: 'oacc',
          symptomCode: ['S713'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'oaca',
    {
      label: '이명 양상',
      desc: ['시냇물 흐르는 소리가 들려요.'],
      symptomCode: ['S711'],
      selectType: 'Scale',
    },
  ],
  [
    'oacb',
    {
      label: '이명 양상',
      desc: ['삐 하는 소리가 들려요.'],
      symptomCode: ['S712'],
      selectType: 'Scale',
    },
  ],
  [
    'oacc',
    {
      label: '이명 양상',
      desc: ['바람소리가 들려요.'],
      symptomCode: ['S713'],
      selectType: 'Scale',
    },
  ],
  [
    'ob',
    {
      label: '귀 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '중이염',
          next: 'oba',
          value: 'oba',
          symptomCode: ['S714'],
        },
        {
          label: '외이도염',
          next: 'obb',
          value: 'obb',
          symptomCode: ['S715'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'oba',
    {
      label: '귀 질환',
      desc: ['중이염'],
      symptomCode: ['S714'],
      selectType: 'Onset',
    },
  ],
  [
    'obb',
    {
      label: '귀 질환',
      desc: ['외이도염'],
      symptomCode: ['S715'],
      selectType: 'Onset',
    },
  ],
  [
    'p',
    {
      label: '눈',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['pa', 'pb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'pa',
    {
      label: '눈',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '눈이 침침해요.',
          next: 'paa',
          value: 'paa',
          symptomCode: ['S107'],
        },
        {
          label: '눈부심',
          next: 'pab',
          value: 'pab',
          symptomCode: ['S181'],
        },
        {
          label: '눈물이 많이 나요.',
          next: 'pac',
          value: 'pac',
          symptomCode: ['S068'],
        },
        {
          label: '눈 충혈',
          next: 'pad',
          value: 'pad',
          symptomCode: ['S109'],
        },
        {
          label: '눈에 통증이 있어요.',
          next: 'pae',
          value: 'pae',
          symptomCode: ['S110'],
        },
        {
          label: '눈꺼풀에 염증이 있어요.(안검연염 등)',
          next: 'paf',
          value: 'paf',
          symptomCode: ['S090', 'S268'],
        },
      ],
    },
  ],
  [
    'paa',
    {
      label: '눈',
      desc: ['눈이 침침해요.'],
      symptomCode: ['S107'],
      selectType: 'Scale',
    },
  ],
  [
    'pab',
    {
      label: '눈',
      desc: ['눈부심'],
      symptomCode: ['S181'],
      selectType: 'Scale',
    },
  ],
  [
    'pac',
    {
      label: '눈',
      desc: ['눈물이 많이 나요.'],
      symptomCode: ['S068'],
      selectType: 'Scale',
    },
  ],
  [
    'pad',
    {
      label: '눈',
      desc: ['눈 충혈'],
      symptomCode: ['S109'],
      selectType: 'Scale',
    },
  ],
  [
    'pae',
    {
      label: '눈',
      desc: ['눈에 통증이 있어요.'],
      symptomCode: ['S110'],
      selectType: 'Scale',
    },
  ],
  [
    'paf',
    {
      label: '눈',
      desc: ['눈꺼풀에 염증이 있어요.(안검연염 등)'],
      symptomCode: ['S090', 'S268'],
      selectType: 'Scale',
    },
  ],
  [
    'pb',
    {
      label: '눈 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '녹내장',
          next: 'pba',
          value: 'pba',
          symptomCode: ['S719'],
        },
        {
          label: '백내장',
          next: 'pbb',
          value: 'pbb',
          symptomCode: ['S108'],
        },
        {
          label: '비문증(눈 앞에 물체가 떠다님)',
          next: 'pbc',
          value: 'pbc',
          symptomCode: ['S111'],
        },
        {
          label: '야맹증',
          next: 'pbd',
          value: 'pbd',
          symptomCode: ['S203'],
        },
        {
          label: '안구건조증',
          next: 'pbe',
          value: 'pbe',
          symptomCode: ['S720'],
        },
        {
          label: '노안',
          next: 'pbf',
          value: 'pbf',
          symptomCode: ['S721'],
        },
        {
          label: '결막염',
          next: 'pbg',
          value: 'pbg',
          symptomCode: ['S109'],
        },
        {
          label: '황반변성',
          next: 'pbh',
          value: 'pbh',
          symptomCode: ['S722'],
        },
        {
          label: '눈 다래끼',
          next: 'pbi',
          value: 'pbi',
          symptomCode: ['S061'],
        },
        {
          label: '포도막염',
          next: 'pbj',
          value: 'pbj',
          symptomCode: ['S723'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'pba',
    {
      label: '눈 질환',
      desc: ['녹내장'],
      symptomCode: ['S719'],
      selectType: 'Onset',
    },
  ],
  [
    'pbb',
    {
      label: '눈 질환',
      desc: ['백내장'],
      symptomCode: ['S108'],
      selectType: 'Onset',
    },
  ],
  [
    'pbc',
    {
      label: '눈 질환',
      desc: ['비문증(눈 앞에 물체가 떠다님)'],
      symptomCode: ['S111'],
      selectType: 'Onset',
    },
  ],
  [
    'pbd',
    {
      label: '눈 질환',
      desc: ['야맹증'],
      symptomCode: ['S203'],
      selectType: 'Onset',
    },
  ],
  [
    'pbe',
    {
      label: '눈 질환',
      desc: ['안구건조증'],
      symptomCode: ['S720'],
      selectType: 'Onset',
    },
  ],
  [
    'pbf',
    {
      label: '눈 질환',
      desc: ['노안'],
      symptomCode: ['S721'],
      selectType: 'Onset',
    },
  ],
  [
    'pbg',
    {
      label: '눈 질환',
      desc: ['결막염'],
      symptomCode: ['S109'],
      selectType: 'Onset',
    },
  ],
  [
    'pbh',
    {
      label: '눈 질환',
      desc: ['황반변성'],
      symptomCode: ['S722'],
      selectType: 'Onset',
    },
  ],
  [
    'pbi',
    {
      label: '눈 질환',
      desc: ['눈 다래끼'],
      symptomCode: ['S061'],
      selectType: 'Onset',
    },
  ],
  [
    'pbj',
    {
      label: '눈 질환',
      desc: ['포도막염'],
      symptomCode: ['S723'],
      selectType: 'Onset',
    },
  ],
  [
    'q',
    {
      label: '코',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['qa', 'qb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'qa',
    {
      label: '코',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '코피가 나요.(외상 제외)',
          next: 'qaa',
          value: 'qaa',
          symptomCode: ['S299'],
        },
        {
          label: '콧물이 나요.',
          next: 'qab',
          value: 'qab',
          symptomCode: ['S300'],
        },
        {
          label: '코가 막혀요.',
          next: 'qac',
          value: 'qac',
          symptomCode: ['S298'],
        },
        {
          label: '코에 폴립이 있어요.',
          next: 'qad',
          value: 'qad',
          symptomCode: ['S144'],
        },
      ],
    },
  ],
  [
    'qaa',
    {
      label: '코',
      desc: ['코피가 나요.(외상 제외)'],
      symptomCode: ['S299'],
      selectType: 'Scale',
    },
  ],
  [
    'qab',
    {
      label: '코',
      desc: ['콧물이 나요.'],
      symptomCode: ['S300'],
      selectType: 'Scale',
    },
  ],
  [
    'qac',
    {
      label: '코',
      desc: ['코가 막혀요.'],
      symptomCode: ['S298'],
      selectType: 'Scale',
    },
  ],
  [
    'qad',
    {
      label: '코',
      desc: ['코에 폴립이 있어요.'],
      symptomCode: ['S144'],
      selectType: 'Scale',
    },
  ],
  [
    'qb',
    {
      label: '코 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '비염',
          next: 'qba',
          value: 'qba',
          symptomCode: ['S298', 'S300'],
        },
        {
          label: '축농증',
          next: 'qbb',
          value: 'qbb',
          symptomCode: ['S298', 'S300'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'qba',
    {
      label: '코 질환',
      desc: ['비염'],
      symptomCode: ['S298', 'S300'],
      selectType: 'Onset',
    },
  ],
  [
    'qbb',
    {
      label: '코 질환',
      desc: ['축농증'],
      symptomCode: ['S298', 'S300'],
      selectType: 'Onset',
    },
  ],
  [
    'r',
    {
      label: '입/혀',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ra'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ra',
    {
      label: '입/혀',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '입이 써요.',
          next: 'raa',
          value: 'raa',
          symptomCode: ['S037'],
        },
        {
          label: '입이 바짝바짝 말라요.',
          next: 'rab',
          value: 'rab',
          symptomCode: ['S041'],
        },
        {
          label: '혀가 마르고 갈라져요.',
          next: 'rac',
          value: 'rac',
          symptomCode: ['S160'],
        },
        {
          label: '입, 입술이 잘 헐어요.(구내염, 헤르페스 등)',
          next: 'rad',
          value: 'rad',
          symptomCode: ['S042'],
        },
        {
          label: '혀가 잘 헐어요.',
          next: 'rae',
          value: 'rae',
          symptomCode: ['S161'],
        },
        {
          label: '침이 많아요.',
          next: 'raf',
          value: 'raf',
          symptomCode: ['S070'],
        },
        {
          label: '구안와사(안면신경마비)',
          next: 'rag',
          value: 'rag',
          symptomCode: ['S039'],
        },
      ],
    },
  ],
  [
    'raa',
    {
      label: '입/혀',
      desc: ['입이 써요.'],
      symptomCode: ['S037'],
      selectType: 'Scale',
    },
  ],
  [
    'rab',
    {
      label: '입/혀',
      desc: ['입이 바짝바짝 말라요.'],
      symptomCode: ['S041'],
      selectType: 'Scale',
    },
  ],
  [
    'rac',
    {
      label: '입/혀',
      desc: ['혀가 마르고 갈라져요.'],
      symptomCode: ['S160'],
      selectType: 'Scale',
    },
  ],
  [
    'rad',
    {
      label: '입/혀',
      desc: ['입, 입술이 잘 헐어요.(구내염, 헤르페스 등)'],
      symptomCode: ['S042'],
      selectType: 'Scale',
    },
  ],
  [
    'rae',
    {
      label: '입/혀',
      desc: ['혀가 잘 헐어요.'],
      symptomCode: ['S161'],
      selectType: 'Scale',
    },
  ],
  [
    'raf',
    {
      label: '입/혀',
      desc: ['침이 많아요.'],
      symptomCode: ['S070'],
      selectType: 'Scale',
    },
  ],
  [
    'rag',
    {
      label: '입/혀',
      desc: ['구안와사(안면신경마비)'],
      symptomCode: ['S039'],
      selectType: 'Scale',
    },
  ],
  [
    's',
    {
      label: '치아/잇몸',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['sa'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'sa',
    {
      label: '치아/잇몸',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '잇몸이 붓고 아파요.',
          next: 'saa',
          value: 'saa',
          symptomCode: ['S294'],
        },
        {
          label: '이가 아프고 흔들려요.',
          next: 'sab',
          value: 'sab',
          symptomCode: ['S295'],
        },
        {
          label: '충치가 있어요.',
          next: 'sac',
          value: 'sac',
          symptomCode: ['S293'],
        },
        {
          label: '치통이 있어요.',
          next: 'sad',
          value: 'sad',
          symptomCode: ['S297'],
        },
        {
          label: '입냄새가 나요.',
          next: 'sae',
          value: 'sae',
          symptomCode: ['S043'],
        },
      ],
    },
  ],
  [
    'saa',
    {
      label: '치아/잇몸',
      desc: ['잇몸이 붓고 아파요.'],
      symptomCode: ['S294'],
      selectType: 'Scale',
    },
  ],
  [
    'sab',
    {
      label: '치아/잇몸',
      desc: ['이가 아프고 흔들려요.'],
      symptomCode: ['S295'],
      selectType: 'Scale',
    },
  ],
  [
    'sac',
    {
      label: '치아/잇몸',
      desc: ['충치가 있어요.'],
      symptomCode: ['S293'],
      selectType: 'Scale',
    },
  ],
  [
    'sad',
    {
      label: '치아/잇몸',
      desc: ['치통이 있어요.'],
      symptomCode: ['S297'],
      selectType: 'Scale',
    },
  ],
  [
    'sae',
    {
      label: '치아/잇몸',
      desc: ['입냄새가 나요.'],
      symptomCode: ['S043'],
      selectType: 'Scale',
    },
  ],
  [
    't',
    {
      label: '목/인후',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ta', 'tb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ta',
    {
      label: '목/인후',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '목에 무언가 걸린 듯 이물감이 있어요.(매핵기)',
          next: 'taa',
          value: 'taa',
          symptomCode: ['S100'],
        },
        {
          label: '목 안이 붓고 침 삼키기가 어려워요.',
          next: 'tab',
          value: 'tab',
          symptomCode: ['S252'],
        },
        {
          label: '목이 쉬었거나 목소리가 잘 나지 않아요.',
          next: 'tac',
          value: 'tac',
          symptomCode: ['S245'],
        },
      ],
    },
  ],
  [
    'taa',
    {
      label: '목/인후',
      desc: ['목에 무언가 걸린 듯 이물감이 있어요.(매핵기)'],
      symptomCode: ['S100'],
      selectType: 'Scale',
    },
  ],
  [
    'tab',
    {
      label: '목/인후',
      desc: ['목 안이 붓고 침 삼키기가 어려워요.'],
      symptomCode: ['S252'],
      selectType: 'Scale',
    },
  ],
  [
    'tac',
    {
      label: '목/인후',
      desc: ['목이 쉬었거나 목소리가 잘 나지 않아요.'],
      symptomCode: ['S245'],
      selectType: 'Scale',
    },
  ],
  [
    'tb',
    {
      label: '목/인후 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '림프절 비대',
          next: 'tba',
          value: 'tba',
          symptomCode: ['S092', 'S076'],
        },
        {
          label: '갑상선 비대',
          next: 'tbb',
          value: 'tbb',
          symptomCode: ['S009', 'S057'],
        },
        {
          label: '인후염',
          next: 'tbc',
          value: 'tbc',
          symptomCode: ['S251', 'S253'],
        },
        {
          label: '식도염',
          next: 'tbd',
          value: 'tbd',
          symptomCode: ['S708'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'tba',
    {
      label: '목/인후 질환',
      desc: ['림프절 비대'],
      symptomCode: ['S092', 'S076'],
      selectType: 'Onset',
    },
  ],
  [
    'tbb',
    {
      label: '목/인후 질환',
      desc: ['갑상선 비대'],
      symptomCode: ['S009', 'S057'],
      selectType: 'Onset',
    },
  ],
  [
    'tbc',
    {
      label: '목/인후 질환',
      desc: ['인후염'],
      symptomCode: ['S251', 'S253'],
      selectType: 'Onset',
    },
  ],
  [
    'tbd',
    {
      label: '목/인후 질환',
      desc: ['식도염'],
      symptomCode: ['S708'],
      selectType: 'Onset',
    },
  ],
  [
    'u',
    {
      label: '기침/가래',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ua', 'ub'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ua',
    {
      label: '기침/가래',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '기침',
          next: 'uaa',
          value: 'uaa',
        },
        {
          label: '가래',
          next: 'uab',
          value: 'uab',
        },
      ],
    },
  ],
  [
    'uaa',
    {
      label: '기침 양상',
      desc: ['기침의 양상은 어떠한가요?'],
      selectType: 'Check',
      answers: [
        {
          label: '콧물, 가래를 동반한 기침을 해요.',
          next: 'uaaa',
          value: 'uaaa',
          symptomCode: ['S054'],
        },
        {
          label: '최근에 마른 기침을 자주 해요.',
          next: 'uaab',
          value: 'uaab',
          symptomCode: ['S093'],
        },
        {
          label: '기침을 오랜 기간(6개월 이상)했고 몸에 힘이 없어요',
          next: 'uaac',
          value: 'uaac',
          symptomCode: ['S060'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'uaaa',
    {
      label: '기침 양상',
      desc: ['콧물, 가래를 동반한 기침을 해요.'],
      symptomCode: ['S054'],
      selectType: 'Scale',
    },
  ],
  [
    'uaab',
    {
      label: '기침 양상',
      desc: ['최근에 마른 기침을 자주 해요.'],
      symptomCode: ['S093'],
      selectType: 'Scale',
    },
  ],
  [
    'uaac',
    {
      label: '기침 양상',
      desc: ['기침을 오랜 기간(6개월 이상)했고 몸에 힘이 없어요'],
      symptomCode: ['S060'],
      selectType: 'Scale',
    },
  ],
  [
    'uab',
    {
      label: '가래 양상',
      desc: ['가래의 양상은 어떠한가요?'],
      selectType: 'Check',
      answers: [
        {
          label: '가래가 많아요.',
          next: 'uaba',
          value: 'uaba',
          symptomCode: ['S002'],
        },
        {
          label: '가래가 누런색이에요.',
          next: 'uabb',
          value: 'uabb',
          symptomCode: ['S337'],
        },
        {
          label: '가래가 끈끈하고 잘 안뱉어져요.',
          next: 'uabc',
          value: 'uabc',
          symptomCode: ['S278'],
        },
        {
          label: '가래가 묽고 거품이 있어요.',
          next: 'uabd',
          value: 'uabd',
          symptomCode: ['S342'],
        },
        {
          label: '가래에 피가 섞여있어요.',
          next: 'uabe',
          value: 'uabe',
          symptomCode: ['S004'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'uaba',
    {
      label: '가래 양상',
      desc: ['가래가 많아요.'],
      symptomCode: ['S002'],
      selectType: 'Scale',
    },
  ],
  [
    'uabb',
    {
      label: '가래 양상',
      desc: ['가래가 누런색이에요.'],
      symptomCode: ['S337'],
      selectType: 'Scale',
    },
  ],
  [
    'uabc',
    {
      label: '가래 양상',
      desc: ['가래가 끈끈하고 잘 안뱉어져요.'],
      symptomCode: ['S278'],
      selectType: 'Scale',
    },
  ],
  [
    'uabd',
    {
      label: '가래 양상',
      desc: ['가래가 묽고 거품이 있어요.'],
      symptomCode: ['S342'],
      selectType: 'Scale',
    },
  ],
  [
    'uabe',
    {
      label: '가래 양상',
      desc: ['가래에 피가 섞여있어요.'],
      symptomCode: ['S004'],
      selectType: 'Scale',
    },
  ],
  [
    'ub',
    {
      label: '기침/가래 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '감기',
          next: 'uba',
          value: 'uba',
          symptomCode: ['S007'],
        },
        {
          label: '기관지염',
          next: 'ubb',
          value: 'ubb',
          symptomCode: ['S049'],
        },
        {
          label: '폐렴',
          next: 'ubc',
          value: 'ubc',
          symptomCode: ['S314'],
        },
        {
          label: '폐결핵',
          next: 'ubd',
          value: 'ubd',
          symptomCode: ['S313', 'S029'],
        },
        {
          label: 'COPD(만성폐쇄성)',
          next: 'ube',
          value: 'ube',
          symptomCode: ['S001'],
        },
        {
          label: '백일해',
          next: 'ubf',
          value: 'ubf',
          symptomCode: ['S112'],
        },
        {
          label: '천식',
          next: 'ubg',
          value: 'ubg',
          symptomCode: ['S289'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'uba',
    {
      label: '기침/가래 질환',
      desc: ['감기'],
      symptomCode: ['S007'],
      selectType: 'Onset',
    },
  ],
  [
    'ubb',
    {
      label: '기침/가래 질환',
      desc: ['기관지염'],
      symptomCode: ['S049'],
      selectType: 'Onset',
    },
  ],
  [
    'ubc',
    {
      label: '기침/가래 질환',
      desc: ['폐렴'],
      symptomCode: ['S314'],
      selectType: 'Onset',
    },
  ],
  [
    'ubd',
    {
      label: '기침/가래 질환',
      desc: ['폐결핵'],
      symptomCode: ['S313', 'S029'],
      selectType: 'Onset',
    },
  ],
  [
    'ube',
    {
      label: '기침/가래 질환',
      desc: ['COPD(만성폐쇄성)'],
      symptomCode: ['S001'],
      selectType: 'Onset',
    },
  ],
  [
    'ubf',
    {
      label: '기침/가래 질환',
      desc: ['백일해'],
      symptomCode: ['S112'],
      selectType: 'Onset',
    },
  ],
  [
    'ubg',
    {
      label: '기침/가래 질환',
      desc: ['천식'],
      symptomCode: ['S289'],
      selectType: 'Onset',
    },
  ],
  [
    'v',
    {
      label: '흉/호흡',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['va', 'vb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'va',
    {
      label: '흉/호흡',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '가슴이 답답해요.',
          next: 'vaa',
          value: 'vaa',
          symptomCode: ['S339'],
        },
        {
          label: '가슴 쪽에 열감이 있어요.',
          next: 'vab',
          value: 'vab',
          symptomCode: ['S340'],
        },
        {
          label: '가슴이 뻐근하거나 통증이 있어요.',
          next: 'vac',
          value: 'vac',
          symptomCode: ['S341'],
        },
        {
          label: '숨이 잘 안쉬어져요.(숨쉬기가 불편해요.)',
          next: 'vad',
          value: 'vad',
          symptomCode: ['S331'],
        },
      ],
    },
  ],
  [
    'vaa',
    {
      label: '흉/호흡',
      desc: ['가슴이 답답해요.'],
      symptomCode: ['S339'],
      selectType: 'Scale',
    },
  ],
  [
    'vab',
    {
      label: '흉/호흡',
      desc: ['가슴 쪽에 열감이 있어요.'],
      symptomCode: ['S340'],
      selectType: 'Scale',
    },
  ],
  [
    'vac',
    {
      label: '흉/호흡',
      desc: ['가슴이 뻐근하거나 통증이 있어요.'],
      symptomCode: ['S341'],
      selectType: 'Scale',
    },
  ],
  [
    'vad',
    {
      label: '흉/호흡',
      desc: ['숨이 잘 안쉬어져요.(숨쉬기가 불편해요.)'],
      symptomCode: ['S331'],
      selectType: 'Scale',
    },
  ],
  [
    'vb',
    {
      label: '흉/호흡 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '협심증',
          next: 'vba',
          value: 'vba',
          symptomCode: ['S329', 'S036'],
        },
        {
          label: '심근경색',
          next: 'vbb',
          value: 'vbb',
          symptomCode: ['S036'],
        },
        {
          label: '부정맥',
          next: 'vbc',
          value: 'vbc',
          symptomCode: ['S134'],
        },
        {
          label: '저혈압',
          next: 'vbd',
          value: 'vbd',
          symptomCode: ['S724'],
        },
        {
          label: '고혈압',
          next: 'vbe',
          value: 'vbe',
          symptomCode: ['S023'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'vba',
    {
      label: '흉/호흡 질환',
      desc: ['협심증'],
      symptomCode: ['S329', 'S036'],
      selectType: 'Onset',
    },
  ],
  [
    'vbb',
    {
      label: '흉/호흡 질환',
      desc: ['심근경색'],
      symptomCode: ['S036'],
      selectType: 'Onset',
    },
  ],
  [
    'vbc',
    {
      label: '흉/호흡 질환',
      desc: ['부정맥'],
      symptomCode: ['S134'],
      selectType: 'Onset',
    },
  ],
  [
    'vbd',
    {
      label: '흉/호흡 질환',
      desc: ['저혈압'],
      symptomCode: ['S724'],
      selectType: 'Onset',
    },
  ],
  [
    'vbe',
    {
      label: '흉/호흡 질환',
      desc: ['고혈압'],
      symptomCode: ['S023'],
      selectType: 'Onset',
    },
  ],
  [
    'w',
    {
      label: '복부',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['wa', 'wb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'wa',
    {
      label: '복부',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '윗배(배꼽 위)가 아파요.',
          next: 'waa',
          value: 'waa',
          symptomCode: ['S218'],
        },
        {
          label: '윗배가 답답하고 빵빵해요.',
          next: 'wab',
          value: 'wab',
          symptomCode: ['S219'],
        },
        {
          label: '옆구리가 결리고 아파요.',
          next: 'wac',
          value: 'wac',
          symptomCode: ['S330'],
        },
        {
          label: '아랫배(배꼽 아래)가 아파요.',
          next: 'wad',
          value: 'wad',
          symptomCode: ['S131'],
        },
        {
          label: '배에 가스가 차고 빵빵해요.',
          next: 'wae',
          value: 'wae',
          symptomCode: ['S143'],
        },
        {
          label: '배에서 꾸르륵 소리가 나요.',
          next: 'waf',
          value: 'waf',
          symptomCode: ['S265'],
        },
        {
          label: '아랫배에 덩어리가 만져져요.',
          next: 'wag',
          value: 'wag',
          symptomCode: ['S285'],
        },
      ],
    },
  ],
  [
    'waa',
    {
      label: '복부',
      desc: ['윗배(배꼽 위)가 아파요.'],
      symptomCode: ['S218'],
      selectType: 'Scale',
    },
  ],
  [
    'wab',
    {
      label: '복부',
      desc: ['윗배가 답답하고 빵빵해요.'],
      symptomCode: ['S219'],
      selectType: 'Scale',
    },
  ],
  [
    'wac',
    {
      label: '복부',
      desc: ['옆구리가 결리고 아파요.'],
      symptomCode: ['S330'],
      selectType: 'Scale',
    },
  ],
  [
    'wad',
    {
      label: '복부',
      desc: ['아랫배(배꼽 아래)가 아파요.'],
      symptomCode: ['S131'],
      selectType: 'Scale',
    },
  ],
  [
    'wae',
    {
      label: '복부',
      desc: ['배에 가스가 차고 빵빵해요.'],
      symptomCode: ['S143'],
      selectType: 'Scale',
    },
  ],
  [
    'waf',
    {
      label: '복부',
      desc: ['배에서 꾸르륵 소리가 나요.'],
      symptomCode: ['S265'],
      selectType: 'Scale',
    },
  ],
  [
    'wag',
    {
      label: '복부',
      desc: ['아랫배에 덩어리가 만져져요.'],
      symptomCode: ['S285'],
      selectType: 'Scale',
    },
  ],
  [
    'wb',
    {
      label: '복부 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '역류성식도염',
          next: 'wba',
          value: 'wba',
          symptomCode: ['S726'],
        },
        {
          label: '위염',
          next: 'wbb',
          value: 'wbb',
          symptomCode: ['S727'],
        },
        {
          label: '위십이지장궤양',
          next: 'wbc',
          value: 'wbc',
          symptomCode: ['S179'],
        },
        {
          label: '위하수',
          next: 'wbd',
          value: 'wbd',
          symptomCode: ['S234'],
        },
        {
          label: '위확장',
          next: 'wbe',
          value: 'wbe',
          symptomCode: ['S235'],
        },
        {
          label: '간염',
          next: 'wbf',
          value: 'wbf',
          symptomCode: ['S728'],
        },
        {
          label: '지방간',
          next: 'wbg',
          value: 'wbg',
          symptomCode: ['S729'],
        },
        {
          label: '간경화',
          next: 'wbh',
          value: 'wbh',
          symptomCode: ['S730'],
        },
        {
          label: '담낭염',
          next: 'wbi',
          value: 'wbi',
          symptomCode: ['S731'],
        },
        {
          label: '담석증',
          next: 'wbj',
          value: 'wbj',
          symptomCode: ['S732'],
        },
        {
          label: '복막염',
          next: 'wbk',
          value: 'wbk',
          symptomCode: ['S267'],
        },
        {
          label: '복수',
          next: 'wbl',
          value: 'wbl',
          symptomCode: ['S129'],
        },
        {
          label: '충수염',
          next: 'wbm',
          value: 'wbm',
          symptomCode: ['S267'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'wba',
    {
      label: '복부 질환',
      desc: ['역류성식도염'],
      symptomCode: ['S726'],
      selectType: 'Onset',
    },
  ],
  [
    'wbb',
    {
      label: '복부 질환',
      desc: ['위염'],
      symptomCode: ['S727'],
      selectType: 'Onset',
    },
  ],
  [
    'wbc',
    {
      label: '복부 질환',
      desc: ['위십이지장궤양'],
      symptomCode: ['S179'],
      selectType: 'Onset',
    },
  ],
  [
    'wbd',
    {
      label: '복부 질환',
      desc: ['위하수'],
      symptomCode: ['S234'],
      selectType: 'Onset',
    },
  ],
  [
    'wbe',
    {
      label: '복부 질환',
      desc: ['위확장'],
      symptomCode: ['S235'],
      selectType: 'Onset',
    },
  ],
  [
    'wbf',
    {
      label: '복부 질환',
      desc: ['간염'],
      symptomCode: ['S728'],
      selectType: 'Onset',
    },
  ],
  [
    'wbg',
    {
      label: '복부 질환',
      desc: ['지방간'],
      symptomCode: ['S729'],
      selectType: 'Onset',
    },
  ],
  [
    'wbh',
    {
      label: '복부 질환',
      desc: ['간경화'],
      symptomCode: ['S730'],
      selectType: 'Onset',
    },
  ],
  [
    'wbi',
    {
      label: '복부 질환',
      desc: ['담낭염'],
      symptomCode: ['S731'],
      selectType: 'Onset',
    },
  ],
  [
    'wbj',
    {
      label: '복부 질환',
      desc: ['담석증'],
      symptomCode: ['S732'],
      selectType: 'Onset',
    },
  ],
  [
    'wbk',
    {
      label: '복부 질환',
      desc: ['복막염'],
      symptomCode: ['S267'],
      selectType: 'Onset',
    },
  ],
  [
    'wbl',
    {
      label: '복부 질환',
      desc: ['복수'],
      symptomCode: ['S129'],
      selectType: 'Onset',
    },
  ],
  [
    'wbm',
    {
      label: '복부 질환',
      desc: ['충수염'],
      symptomCode: ['S267'],
      selectType: 'Onset',
    },
  ],
  [
    'x',
    {
      label: '피부',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['xa', 'xb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'xa',
    {
      label: '피부',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '아토피/습진',
          next: 'xaa',
          value: 'xaa',
          symptomCode: ['S189', 'S190', 'S050'],
        },
        {
          label: '여드름/농포',
          next: 'xab',
          value: 'xab',
          symptomCode: ['S209', 'S280', 'S062', 'S118'],
        },
        {
          label: '버짐/각질/백선',
          next: 'xac',
          value: 'xac',
          symptomCode: ['S121'],
        },
        {
          label: '두드러기',
          next: 'xad',
          value: 'xad',
          symptomCode: ['S083'],
        },
        {
          label: '적색 반점(홍반)',
          next: 'xae',
          value: 'xae',
          symptomCode: ['S332', 'S072'],
        },
        {
          label: '백색 반점(백반)',
          next: 'xaf',
          value: 'xaf',
          symptomCode: ['S120'],
        },
        {
          label: '건선',
          next: 'xag',
          value: 'xag',
          symptomCode: ['S760'],
        },
        {
          label: '피부 궤양',
          next: 'xah',
          value: 'xah',
          symptomCode: ['S287', 'S316'],
        },
        {
          label: '주사비(딸기코)',
          next: 'xai',
          value: 'xai',
          symptomCode: ['S281'],
        },
        {
          label: '가려움증(소양감)',
          next: 'xaj',
          value: 'xaj',
          symptomCode: ['S177'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'xaa',
    {
      label: '피부',
      desc: ['아토피/습진'],
      symptomCode: ['S189', 'S190', 'S050'],
      selectType: 'Scale',
    },
  ],
  [
    'xab',
    {
      label: '피부',
      desc: ['여드름/농포'],
      symptomCode: ['S209', 'S280', 'S062', 'S118'],
      selectType: 'Scale',
    },
  ],
  [
    'xac',
    {
      label: '버짐/각질/백선 부위',
      desc: ['버짐/각질/백선 부위를 골라주세요.'],
      symptomCode: ['S121'],
      selectType: 'Check',
      answers: [
        {
          label: '머리/두피',
          next: 'xaca',
          value: 'xaca',
          symptomCode: ['S085', 'S283'],
        },
        {
          label: '몸(체부)',
          next: 'xacb',
          value: 'xacb',
          symptomCode: ['S290'],
        },
        {
          label: '손발',
          next: 'xacc',
          value: 'xacc',
          symptomCode: ['S201'],
        },
        {
          label: '사타구니',
          next: 'xacd',
          value: 'xacd',
          symptomCode: ['S220'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'xaca',
    {
      label: '버짐/각질/백선 부위',
      desc: ['머리/두피'],
      symptomCode: ['S085', 'S283'],
      selectType: 'Scale',
    },
  ],
  [
    'xacb',
    {
      label: '버짐/각질/백선 부위',
      desc: ['몸(체부)'],
      symptomCode: ['S290'],
      selectType: 'Scale',
    },
  ],
  [
    'xacc',
    {
      label: '버짐/각질/백선 부위',
      desc: ['손발'],
      symptomCode: ['S201'],
      selectType: 'Scale',
    },
  ],
  [
    'xacd',
    {
      label: '버짐/각질/백선 부위',
      desc: ['사타구니'],
      symptomCode: ['S220'],
      selectType: 'Scale',
    },
  ],
  [
    'xad',
    {
      label: '피부',
      desc: ['두드러기'],
      symptomCode: ['S083'],
      selectType: 'Scale',
    },
  ],
  [
    'xae',
    {
      label: '피부',
      desc: ['적색 반점(홍반)'],
      symptomCode: ['S332', 'S072'],
      selectType: 'Scale',
    },
  ],
  [
    'xaf',
    {
      label: '피부',
      desc: ['백색 반점(백반)'],
      symptomCode: ['S120'],
      selectType: 'Scale',
    },
  ],
  [
    'xag',
    {
      label: '피부',
      desc: ['건선'],
      symptomCode: ['S760'],
      selectType: 'Scale',
    },
  ],
  [
    'xah',
    {
      label: '피부',
      desc: ['피부 궤양'],
      symptomCode: ['S287', 'S316'],
      selectType: 'Scale',
    },
  ],
  [
    'xai',
    {
      label: '피부',
      desc: ['주사비(딸기코)'],
      symptomCode: ['S281'],
      selectType: 'Scale',
    },
  ],
  [
    'xaj',
    {
      label: '피부',
      desc: ['가려움증(소양감)'],
      symptomCode: ['S177'],
      selectType: 'Scale',
    },
  ],
  [
    'xb',
    {
      label: '피부 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '화상',
          next: 'xba',
          value: 'xba',
          symptomCode: ['S335'],
        },
        {
          label: '동상',
          next: 'xbb',
          value: 'xbb',
          symptomCode: ['S081'],
        },
        {
          label: '매독',
          next: 'xbc',
          value: 'xbc',
          symptomCode: ['S099'],
        },
        {
          label: '풍진',
          next: 'xbd',
          value: 'xbd',
          symptomCode: ['S315'],
        },
        {
          label: '파상풍',
          next: 'xbe',
          value: 'xbe',
          symptomCode: ['S311'],
        },
        {
          label: '버거씨병',
          next: 'xbf',
          value: 'xbf',
          symptomCode: ['S124'],
        },
        {
          label: '한센병',
          next: 'xbg',
          value: 'xbg',
          symptomCode: ['S058'],
        },
        {
          label: '무좀',
          next: 'xbh',
          value: 'xbh',
          symptomCode: ['S262'],
        },
        {
          label: '사마귀',
          next: 'xbi',
          value: 'xbi',
          symptomCode: ['S148'],
        },
        {
          label: '홍역',
          next: 'xbj',
          value: 'xbj',
          symptomCode: ['S095'],
        },
        {
          label: '땀띠',
          next: 'xbk',
          value: 'xbk',
          symptomCode: ['S146'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'xba',
    {
      label: '피부 질환',
      desc: ['화상'],
      symptomCode: ['S335'],
      selectType: 'Onset',
    },
  ],
  [
    'xbb',
    {
      label: '피부 질환',
      desc: ['동상'],
      symptomCode: ['S081'],
      selectType: 'Onset',
    },
  ],
  [
    'xbc',
    {
      label: '피부 질환',
      desc: ['매독'],
      symptomCode: ['S099'],
      selectType: 'Onset',
    },
  ],
  [
    'xbd',
    {
      label: '피부 질환',
      desc: ['풍진'],
      symptomCode: ['S315'],
      selectType: 'Onset',
    },
  ],
  [
    'xbe',
    {
      label: '피부 질환',
      desc: ['파상풍'],
      symptomCode: ['S311'],
      selectType: 'Onset',
    },
  ],
  [
    'xbf',
    {
      label: '피부 질환',
      desc: ['버거씨병'],
      symptomCode: ['S124'],
      selectType: 'Onset',
    },
  ],
  [
    'xbg',
    {
      label: '피부 질환',
      desc: ['한센병'],
      symptomCode: ['S058'],
      selectType: 'Onset',
    },
  ],
  [
    'xbh',
    {
      label: '피부 질환',
      desc: ['무좀'],
      symptomCode: ['S262'],
      selectType: 'Onset',
    },
  ],
  [
    'xbi',
    {
      label: '피부 질환',
      desc: ['사마귀'],
      symptomCode: ['S148'],
      selectType: 'Onset',
    },
  ],
  [
    'xbj',
    {
      label: '피부 질환',
      desc: ['홍역'],
      symptomCode: ['S095'],
      selectType: 'Onset',
    },
  ],
  [
    'xbk',
    {
      label: '피부 질환',
      desc: ['땀띠'],
      symptomCode: ['S146'],
      selectType: 'Onset',
    },
  ],
  [
    'y',
    {
      label: '모발',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['ya'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'ya',
    {
      label: '모발',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '원형탈모',
          next: 'yaa',
          value: 'yaa',
          symptomCode: ['S229'],
        },
        {
          label: '탈모(원형탈모 외)',
          next: 'yab',
          value: 'yab',
          symptomCode: ['S303'],
        },
        {
          label: '새치/흰머리',
          next: 'yac',
          value: 'yac',
          symptomCode: ['S182'],
        },
      ],
    },
  ],
  [
    'yaa',
    {
      label: '모발',
      desc: ['원형탈모'],
      symptomCode: ['S229'],
      selectType: 'Scale',
    },
  ],
  [
    'yab',
    {
      label: '모발',
      desc: ['탈모(원형탈모 외)'],
      symptomCode: ['S303'],
      selectType: 'Scale',
    },
  ],
  [
    'yac',
    {
      label: '모발',
      desc: ['새치/흰머리'],
      symptomCode: ['S182'],
      selectType: 'Scale',
    },
  ],
  [
    'z',
    {
      label: '근골',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['za', 'zb'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'za',
    {
      label: '근골',
      desc: ['평소 아픈 부위를 선택해주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '목/어깨/등',
          next: 'zaa',
          value: 'zaa',
        },
        {
          label: '허리/골반',
          next: 'zab',
          value: 'zab',
        },
        {
          label: '손가락/손목/팔꿈치(관절)',
          next: 'zac',
          value: 'zac',
        },
        {
          label: '무릎/발목(관절)',
          next: 'zad',
          value: 'zad',
        },
        {
          label: '팔/다리(근육)',
          next: 'zae',
          value: 'zae',
        },
      ],
    },
  ],
  [
    'zaa',
    {
      label: '목/어깨/등',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '팔, 어깨가 쑤시고 아파요.',
          next: 'zaaa',
          value: 'zaaa',
          symptomCode: ['S012'],
        },
        {
          label: '뒷목이 아프고 뻣뻣해요.',
          next: 'zaab',
          value: 'zaab',
          symptomCode: ['S020'],
        },
        {
          label: '등, 어깨(승모근)가 시큰거리고 아파요.',
          next: 'zaac',
          value: 'zaac',
          symptomCode: ['S011'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'zaaa',
    {
      label: '목/어깨/등',
      desc: ['팔, 어깨가 쑤시고 아파요.'],
      symptomCode: ['S012'],
      selectType: 'Scale',
    },
  ],
  [
    'zaab',
    {
      label: '목/어깨/등',
      desc: ['뒷목이 아프고 뻣뻣해요.'],
      symptomCode: ['S020'],
      selectType: 'Scale',
    },
  ],
  [
    'zaac',
    {
      label: '목/어깨/등',
      desc: ['등, 어깨(승모근)가 시큰거리고 아파요.'],
      symptomCode: ['S011'],
      selectType: 'Scale',
    },
  ],
  [
    'zab',
    {
      label: '허리/골반',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '허리/골반이 시리고 시큰거려요.',
          next: 'zaba',
          value: 'zaba',
          symptomCode: ['S224'],
        },
        {
          label: '허리/골반이 쑤시고 아파요.',
          next: 'zabb',
          value: 'zabb',
          symptomCode: ['S226'],
        },
        {
          label: '뻣뻣하고 잘 움직여지지 않아요.',
          next: 'zabc',
          value: 'zabc',
          symptomCode: ['S031'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'zaba',
    {
      label: '허리/골반',
      desc: ['허리/골반이 시리고 시큰거려요.'],
      symptomCode: ['S224'],
      selectType: 'Scale',
    },
  ],
  [
    'zabb',
    {
      label: '허리/골반',
      desc: ['허리/골반이 쑤시고 아파요.'],
      symptomCode: ['S226'],
      selectType: 'Scale',
    },
  ],
  [
    'zabc',
    {
      label: '허리/골반',
      desc: ['뻣뻣하고 잘 움직여지지 않아요.'],
      symptomCode: ['S031'],
      selectType: 'Scale',
    },
  ],
  [
    'zac',
    {
      label: '손가락/손목/팔꿈치',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '시리고 시큰거려요.',
          next: 'zaca',
          value: 'zaca',
          symptomCode: ['S774'],
        },
        {
          label: '관절이 쑤시고 아파요.',
          next: 'zacb',
          value: 'zacb',
          symptomCode: ['S033'],
        },
        {
          label: '뻣뻣하고 잘 움직여지지 않아요.',
          next: 'zacc',
          value: 'zacc',
          symptomCode: ['S031'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'zaca',
    {
      label: '손가락/손목/팔꿈치',
      desc: ['시리고 시큰거려요.'],
      symptomCode: ['S774'],
      selectType: 'Scale',
    },
  ],
  [
    'zacb',
    {
      label: '손가락/손목/팔꿈치',
      desc: ['관절이 쑤시고 아파요.'],
      symptomCode: ['S033'],
      selectType: 'Scale',
    },
  ],
  [
    'zacc',
    {
      label: '손가락/손목/팔꿈치',
      desc: ['뻣뻣하고 잘 움직여지지 않아요.'],
      symptomCode: ['S031'],
      selectType: 'Scale',
    },
  ],
  [
    'zad',
    {
      label: '무릎/발목',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '무릎/발목이 시리고 시큰거려요.',
          next: 'zada',
          value: 'zada',
          symptomCode: ['S186'],
        },
        {
          label: '무릎/발목이 쑤시고 아파요.',
          next: 'zadb',
          value: 'zadb',
          symptomCode: ['S187'],
        },
        {
          label: '뻣뻣하고 잘 움직여지지 않아요.',
          next: 'zadc',
          value: 'zadc',
          symptomCode: ['S031'],
        },
        {
          label: '다리에 힘이 없어요.',
          next: 'zadd',
          value: 'zadd',
          symptomCode: ['S320'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'zada',
    {
      label: '무릎/발목',
      desc: ['무릎/발목이 시리고 시큰거려요.'],
      symptomCode: ['S186'],
      selectType: 'Scale',
    },
  ],
  [
    'zadb',
    {
      label: '무릎/발목',
      desc: ['무릎/발목이 쑤시고 아파요.'],
      symptomCode: ['S187'],
      selectType: 'Scale',
    },
  ],
  [
    'zadc',
    {
      label: '무릎/발목',
      desc: ['뻣뻣하고 잘 움직여지지 않아요.'],
      symptomCode: ['S031'],
      selectType: 'Scale',
    },
  ],
  [
    'zadd',
    {
      label: '무릎/발목',
      desc: ['다리에 힘이 없어요.'],
      symptomCode: ['S320'],
      selectType: 'Scale',
    },
  ],
  [
    'zae',
    {
      label: '팔/다리',
      desc: ['관련된 증상을 모두 선택하세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '근육에 힘이 없어요.',
          next: 'zaea',
          value: 'zaea',
          symptomCode: ['S045'],
        },
        {
          label: '근육이 떨려요.',
          next: 'zaeb',
          value: 'zaeb',
          symptomCode: ['S210'],
        },
        {
          label: '근육이 당기고 오그라들어요.',
          next: 'zaec',
          value: 'zaec',
          symptomCode: ['S038'],
        },
        {
          label: '근육이 굳고 저려요.',
          next: 'zaed',
          value: 'zaed',
          symptomCode: ['S094'],
        },
        {
          label: '쥐가 잘 나요.',
          next: 'zaee',
          value: 'zaee',
          symptomCode: ['S271'],
        },
        {
          label: '일정한 곳이 아파요.',
          next: 'zaef',
          value: 'zaef',
          symptomCode: ['S309'],
        },
        {
          label: '여러군데가 돌아가며 아파요.',
          next: 'zaeg',
          value: 'zaeg',
          symptomCode: ['S775'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'zaea',
    {
      label: '팔/다리',
      desc: ['근육에 힘이 없어요.'],
      symptomCode: ['S045'],
      selectType: 'Scale',
    },
  ],
  [
    'zaeb',
    {
      label: '팔/다리',
      desc: ['근육이 떨려요.'],
      symptomCode: ['S210'],
      selectType: 'Scale',
    },
  ],
  [
    'zaec',
    {
      label: '팔/다리',
      desc: ['근육이 당기고 오그라들어요.'],
      symptomCode: ['S038'],
      selectType: 'Scale',
    },
  ],
  [
    'zaed',
    {
      label: '팔/다리',
      desc: ['근육이 굳고 저려요.'],
      symptomCode: ['S094'],
      selectType: 'Scale',
    },
  ],
  [
    'zaee',
    {
      label: '팔/다리',
      desc: ['쥐가 잘 나요.'],
      symptomCode: ['S271'],
      selectType: 'Scale',
    },
  ],
  [
    'zaef',
    {
      label: '팔/다리',
      desc: ['일정한 곳이 아파요.'],
      symptomCode: ['S309'],
      selectType: 'Scale',
    },
  ],
  [
    'zaeg',
    {
      label: '팔/다리',
      desc: ['여러군데가 돌아가며 아파요.'],
      symptomCode: ['S775'],
      selectType: 'Scale',
    },
  ],
  [
    'zb',
    {
      label: '근골 질환',
      desc: ['현재 앓고 계신 질환을 모두 골라주세요.'],
      selectType: 'Check',
      answers: [
        {
          label: '앓고 있는 질환이 없어요.',
          value: 0,
        },
        {
          label: '골다공증(골연화증)',
          next: 'zba',
          value: 'zba',
          symptomCode: ['S028'],
        },
        {
          label: '골절',
          next: 'zbb',
          value: 'zbb',
          symptomCode: ['S027'],
        },
        {
          label: '류마티스 관절염',
          next: 'zbc',
          value: 'zbc',
          symptomCode: ['S032'],
        },
        {
          label: '퇴행성 관절염',
          next: 'zbd',
          value: 'zbd',
          symptomCode: ['S764'],
        },
        {
          label: '경추 추간판질환(목 디스크)',
          next: 'zbe',
          value: 'zbe',
          symptomCode: ['S765'],
        },
        {
          label: '요추 추간판질환(허리 디스크)',
          next: 'zbf',
          value: 'zbf',
          symptomCode: ['S766'],
        },
        {
          label: '척추협착증',
          next: 'zbg',
          value: 'zbg',
          symptomCode: ['S767'],
        },
        {
          label: '강직성 척추염',
          next: 'zbh',
          value: 'zbh',
          symptomCode: ['S768'],
        },
        {
          label: '염좌(타박상)',
          next: 'zbi',
          value: 'zbi',
          symptomCode: ['S301'],
        },
        {
          label: '통풍',
          next: 'zbj',
          value: 'zbj',
          symptomCode: ['S769'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'zba',
    {
      label: '근골 질환',
      desc: ['골다공증(골연화증)'],
      symptomCode: ['S028'],
      selectType: 'Onset',
    },
  ],
  [
    'zbb',
    {
      label: '근골 질환',
      desc: ['골절'],
      symptomCode: ['S027'],
      selectType: 'Onset',
    },
  ],
  [
    'zbc',
    {
      label: '근골 질환',
      desc: ['류마티스 관절염'],
      symptomCode: ['S032'],
      selectType: 'Onset',
    },
  ],
  [
    'zbd',
    {
      label: '근골 질환',
      desc: ['퇴행성 관절염'],
      symptomCode: ['S764'],
      selectType: 'Onset',
    },
  ],
  [
    'zbe',
    {
      label: '근골 질환',
      desc: ['경추 추간판질환(목 디스크)'],
      symptomCode: ['S765'],
      selectType: 'Onset',
    },
  ],
  [
    'zbf',
    {
      label: '근골 질환',
      desc: ['요추 추간판질환(허리 디스크)'],
      symptomCode: ['S766'],
      selectType: 'Onset',
    },
  ],
  [
    'zbg',
    {
      label: '근골 질환',
      desc: ['척추협착증'],
      symptomCode: ['S767'],
      selectType: 'Onset',
    },
  ],
  [
    'zbh',
    {
      label: '근골 질환',
      desc: ['강직성 척추염'],
      symptomCode: ['S768'],
      selectType: 'Onset',
    },
  ],
  [
    'zbi',
    {
      label: '근골 질환',
      desc: ['염좌(타박상)'],
      symptomCode: ['S301'],
      selectType: 'Onset',
    },
  ],
  [
    'zbj',
    {
      label: '근골 질환',
      desc: ['통풍'],
      symptomCode: ['S769'],
      selectType: 'Onset',
    },
  ],
  [
    'B',
    {
      label: '월경통',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['Be', 'Bi', 'Bk', 'Ba', 'Bj', 'Bb', 'Bc', 'Bf', 'Bh'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'Be',
    {
      label: '월경통 정도',
      desc: ['통증 지속시간은 어느정도인가요?'],
      selectType: 'Radio',
      chartType: 'CC',
      answers: [
        {
          label: '생리 시작 후 2-3일 이내 지속',
          value: 'Bea',
          next: 'Bea',
          symptomCode: ['S308'],
        },
        {
          label: '생리 시작 후 4일 이상 지속',
          value: 'Beb',
          next: 'Bea',
          symptomCode: ['S308'],
        },
      ],
    },
  ],
  [
    'Bea',
    {
      label: '월경통 정도',
      desc: ['생리 시작 후 2-3일 이내 지속'],
      symptomCode: ['S308'],
      selectType: 'Scale',
    },
  ],
  [
    'Bi',
    {
      label: '월경통 발병일',
      desc: ['현재와 같은 정도의', '월경통 발병시기는 언제인가요?'],
      selectType: 'Radio',
      chartType: 'OS',
      answers: [
        {
          label: '1개월 이내(최근 월경)',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '1-2년 이내',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '수년 전',
          value: 3,
          symptomCode: ['S207'],
        },
        {
          label: '출산 후',
          value: 4,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Bk',
    {
      label: '최근 증상 발현일',
      desc: ['최근 월경통 증상이 나타난 날은 언제인가요?'],
      selectType: 'Date',
      chartType: 'OS',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'Ba',
    {
      label: '최근 월경 시작일',
      desc: ['마지막 월경 시작일은 언제인가요?'],
      selectType: 'Date',
      chartType: 'PI',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'Bj',
    {
      label: '월경 주기',
      desc: ['월경 평균 주기를 입력하세요.'],
      selectType: 'NumberInput',
      chartType: 'PI',
      postfix: '일',
      defaultValue: 28,
    },
  ],
  [
    'Bb',
    {
      label: '월경 기간',
      desc: ['월경 평균 기간을 입력하세요.'],
      selectType: 'NumberInput',
      chartType: 'PI',
      postfix: '일',
      defaultValue: 5,
    },
  ],
  [
    'Bc',
    {
      label: '월경 동반 증상',
      desc: ['월경 관련하여 불편한 증상을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'CC',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '두통',
          value: 'Bca',
          next: 'Bca',
          symptomCode: ['S087'],
        },
        {
          label: '메슥거림, 구역감',
          value: 'Bcb',
          next: 'Bcb',
          symptomCode: ['S040'],
        },
        {
          label: '구토(먹은 음식물을 토함)',
          value: 'Bcc',
          next: 'Bcc',
          symptomCode: ['S044'],
        },
        {
          label: '피로',
          value: 'Bcd',
          next: 'Bcd',
          symptomCode: ['S056'],
        },
        {
          label: '설사',
          value: 'Bce',
          next: 'Bce',
          symptomCode: ['S159'],
        },
        {
          label: '변비',
          value: 'Bcf',
          next: 'Bcf',
          symptomCode: ['S126'],
        },
        {
          label: '실신',
          value: 'Bcg',
          next: 'Bcg',
          symptomCode: ['S198'],
        },
        {
          label: '우울',
          value: 'Bch',
          next: 'Bch',
          symptomCode: ['S227'],
        },
        {
          label: '부종',
          value: 'Bci',
          next: 'Bci',
          symptomCode: ['S135'],
        },
        {
          label: '월경 주기가 평소보다 길어졌어요.',
          value: 10,
          symptomCode: ['S233'],
        },
        {
          label: '월경 주기가 평소보다 짧아졌어요.',
          value: 11,
          symptomCode: ['S770'],
        },
        {
          label: '월경 전 복통이 있어요.',
          value: 13,
          symptomCode: ['S017'],
        },
        {
          label: '월경 전 유방통증이 있어요.',
          value: 14,
          symptomCode: ['S018'],
        },
        {
          label: '월경 양이 많아졌어요.',
          value: 15,
          symptomCode: ['S230'],
        },
        {
          label: '월경 양이 적어졌어요.',
          value: 16,
          symptomCode: ['S800'],
        },
        {
          label: '월경 색이 진하고 덩어리가 많아요.',
          value: 17,
          symptomCode: ['S232'],
        },
        {
          label: '부정출혈(월경기간이 아닐 때 발생하는 출혈)이 있어요.',
          value: 18,
          symptomCode: ['S142'],
        },
      ],
    },
  ],
  [
    'Bca',
    {
      label: '월경 동반 증상',
      desc: ['두통'],
      symptomCode: ['S087'],
      selectType: 'Scale',
    },
  ],
  [
    'Bcb',
    {
      label: '월경 동반 증상',
      desc: ['메슥거림, 구역감'],
      symptomCode: ['S040'],
      selectType: 'Scale',
    },
  ],
  [
    'Bcc',
    {
      label: '월경 동반 증상',
      desc: ['구토(먹은 음식물을 토함)'],
      symptomCode: ['S044'],
      selectType: 'Scale',
    },
  ],
  [
    'Bcd',
    {
      label: '월경 동반 증상',
      desc: ['피로'],
      symptomCode: ['S056'],
      selectType: 'Scale',
    },
  ],
  [
    'Bce',
    {
      label: '월경 동반 증상',
      desc: ['설사'],
      symptomCode: ['S159'],
      selectType: 'Scale',
    },
  ],
  [
    'Bcf',
    {
      label: '월경 동반 증상',
      desc: ['변비'],
      symptomCode: ['S126'],
      selectType: 'Scale',
    },
  ],
  [
    'Bcg',
    {
      label: '월경 동반 증상',
      desc: ['실신'],
      symptomCode: ['S198'],
      selectType: 'Scale',
    },
  ],
  [
    'Bch',
    {
      label: '월경 동반 증상',
      desc: ['우울'],
      symptomCode: ['S227'],
      selectType: 'Scale',
    },
  ],
  [
    'Bci',
    {
      label: '월경 동반 증상',
      desc: ['부종'],
      symptomCode: ['S135'],
      selectType: 'Scale',
    },
  ],
  [
    'Bf',
    {
      label: '복용약',
      desc: ['증상 완화를 위해 복용 중인 약이 있으신가요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '약을 복용하지 않아요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '진통제(NSAIDs, 타이레놀 등)',
          value: 'Bfa',
          next: 'Bfa',
          symptomCode: ['S000'],
        },
        {
          label: '경구피임약',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Bfa',
    {
      label: '진통제 효과 여부',
      desc: ['진통제(NSAIDs, 타이레놀 등)', '진통제를 복용하면 증상이 호전되시나요?'],
      symptomCode: ['S000'],
      selectType: 'Radio',
      answers: [
        {
          label: '호전돼요.',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '효과가 없어요.',
          value: 2,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Bh',
    {
      label: '여성 질환',
      desc: ['진단 받거나 앓고 있는', '여성 질환이 있으신가요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '진단 받은 여성 질환이 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '다낭성난소증후군',
          value: 'Bha',
          next: 'Bha',
          symptomCode: ['S000'],
        },
        {
          label: '자궁근종',
          value: 'Bhb',
          next: 'Bhb',
          symptomCode: ['S130'],
        },
        {
          label: '자궁내막증',
          value: 'Bhc',
          next: 'Bhc',
          symptomCode: ['S130'],
        },
        {
          label: '자궁선근증',
          value: 'Bhd',
          next: 'Bhd',
          symptomCode: ['S130'],
        },
        {
          label: '자궁 내 신생물, 물혹 등',
          value: 'Bhe',
          next: 'Bhe',
          symptomCode: ['S130'],
        },
        {
          label: '자궁하수',
          value: 'Bhf',
          next: 'Bhf',
          symptomCode: ['S260'],
        },
        {
          label: '난소낭종',
          value: 'Bhg',
          next: 'Bhg',
          symptomCode: ['S130'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Bha',
    {
      label: '여성 질환',
      desc: ['다낭성난소증후군'],
      symptomCode: ['S000'],
      selectType: 'Onset',
    },
  ],
  [
    'Bhb',
    {
      label: '여성 질환',
      desc: ['자궁근종'],
      symptomCode: ['S130'],
      selectType: 'Onset',
    },
  ],
  [
    'Bhc',
    {
      label: '여성 질환',
      desc: ['자궁내막증'],
      symptomCode: ['S130'],
      selectType: 'Onset',
    },
  ],
  [
    'Bhd',
    {
      label: '여성 질환',
      desc: ['자궁선근증'],
      symptomCode: ['S130'],
      selectType: 'Onset',
    },
  ],
  [
    'Bhe',
    {
      label: '여성 질환',
      desc: ['자궁 내 신생물, 물혹 등'],
      symptomCode: ['S130'],
      selectType: 'Onset',
    },
  ],
  [
    'Bhf',
    {
      label: '여성 질환',
      desc: ['자궁하수'],
      symptomCode: ['S260'],
      selectType: 'Onset',
    },
  ],
  [
    'Bhg',
    {
      label: '여성 질환',
      desc: ['난소낭종'],
      symptomCode: ['S130'],
      selectType: 'Onset',
    },
  ],
  [
    'C',
    {
      label: '안면마비',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['Ca', 'Cb', 'Cc', 'Cd', 'Ce'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'Ca',
    {
      label: '안면마비 부위',
      desc: ['어느 쪽 얼굴에 마비감이 느껴지시나요?'],
      selectType: 'Radio',
      chartType: 'CC',
      answers: [
        {
          label: '왼쪽',
          value: 1,
          symptomCode: ['S039'],
        },
        {
          label: '오른쪽',
          value: 2,
          symptomCode: ['S039'],
        },
        {
          label: '양쪽',
          value: 3,
          symptomCode: ['S039'],
        },
      ],
    },
  ],
  [
    'Cb',
    {
      label: '안면마비 발병일',
      desc: ['증상이 나타나기 시작한 날짜는 언제인가요?'],
      selectType: 'Date',
      chartType: 'OS',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'Cc',
    {
      label: '안면마비 동반 증상',
      desc: ['안면 근육 마비감 외에', '불편한 증상을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'CC',
      answers: [
        {
          label: '해당 증상이 없어요.',
          value: 0,
        },
        {
          label: '귀 뒤에 통증이 있어요.',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '귀에서 소리가 나요.',
          value: 2,
          symptomCode: ['S250'],
        },
        {
          label: '귀 주위에 수포가 있어요.',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '외부의 큰소리가 통증으로 느껴져요.',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '내 말소리가 크게 울려서 들려요.',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '맛이 잘 안느껴져요.',
          value: 6,
          symptomCode: ['S000'],
        },
        {
          label: '눈이 건조해요.',
          value: 7,
          symptomCode: ['S000'],
        },
        {
          label: '눈물이 흘러요.',
          value: 8,
          symptomCode: ['S068'],
        },
        {
          label: '두통',
          value: 9,
          symptomCode: ['S087'],
        },
        {
          label: '어지럼증',
          value: 10,
          symptomCode: ['S325'],
        },
        {
          label: '음식을 먹을 때 눈물이 나요.',
          value: 11,
          symptomCode: ['S000'],
        },
        {
          label: '입과 눈 근육이 함께 움직여요.',
          value: 12,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Cd',
    {
      label: '치료 내역',
      desc: ['안면마비 증상으로', '병/의원을 방문한 적이 있나요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '치료받은 적 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '병/의원에서 치료 받았어요.',
          value: 'Cda',
          next: 'Cda',
          symptomCode: ['S000'],
        },
        {
          label: '한방병/의원에서 치료 받았어요.',
          value: 2,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Cda',
    {
      label: '치료 내역',
      desc: ['처방받은 약이나 검사를 모두 선택하세요.'],
      symptomCode: ['S000'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '자세한 내역은 모르겠어요.',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '스테로이드',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '항바이러스제',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '안연고',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '주사, 수액',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '뇌 CT',
          value: 6,
          symptomCode: ['S000'],
        },
        {
          label: '뇌 MRI',
          value: 7,
          symptomCode: ['S000'],
        },
        {
          label: '근전도검사/신경전도검사',
          value: 8,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Ce',
    {
      label: '안면마비 발병 요인',
      desc: ['다음 중 최근 2주간 해당되는 사항을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '특별한 요인이 없었어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '피로, 과로',
          value: 1,
          symptomCode: ['S056'],
        },
        {
          label: '수면 불량',
          value: 2,
          symptomCode: ['S138'],
        },
        {
          label: '스트레스',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '소화불량',
          value: 4,
          symptomCode: ['S178'],
        },
        {
          label: '식욕부진',
          value: 5,
          symptomCode: ['S192'],
        },
        {
          label: '체중 감소',
          value: 6,
          symptomCode: ['S000'],
        },
        {
          label: '감기, 장염 등 바이러스 질환',
          value: 7,
          symptomCode: ['S000'],
        },
        {
          label: '추운 곳에 장시간 노출',
          value: 8,
          symptomCode: ['S000'],
        },
        {
          label: '수술',
          value: 9,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'D',
    {
      label: '뇌혈관질환 후유증',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['Da', 'Db', 'Dh', 'De', 'Df', 'Dg'],
          value: 1,
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'Da',
    {
      label: '뇌혈관질환',
      desc: ['진단받은 뇌혈관질환은 무엇인가요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '뇌경색',
          value: 2,
          symptomCode: ['S707'],
        },
        {
          label: '뇌출혈',
          value: 3,
          symptomCode: ['S707'],
        },
        {
          label: '진단명을 모르겠어요.',
          value: 1,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Db',
    {
      label: '뇌혈관질환 발병일',
      desc: ['최초 증상 발생일은 언제인가요?'],
      selectType: 'Date',
      chartType: 'OS',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'Dh',
    {
      label: '재발/수술 이력',
      desc: ['해당되는 사항을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '해당 사항이 없어요.',
          value: 0,
        },
        {
          label: '재발한 적이 있어요.',
          value: 'Dha',
          next: 'Dha',
          symptomCode: ['S707'],
        },
        {
          label: '수술받은 적이 있어요.',
          value: 'Dhb',
          next: 'Dhb',
          symptomCode: ['S707'],
        },
      ],
    },
  ],
  [
    'Dha',
    {
      label: '최근 재발일',
      desc: ['재발한 적이 있어요.', '최근 재발일은 언제인가요?'],
      symptomCode: ['S707'],
      selectType: 'Date',
      chartType: 'OS',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'Dhb',
    {
      label: '최근 수술일',
      desc: ['수술받은 적이 있어요.', '최근 수술일은 언제인가요?'],
      symptomCode: ['S707'],
      selectType: 'Date',
      chartType: 'OS',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'De',
    {
      label: '후유증 증상',
      desc: ['뇌혈관질환 후유증 증상을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'CC',
      answers: [
        {
          label: '팔다리 마비',
          value: 1,
          symptomCode: ['S045'],
        },
        {
          label: '감각이 둔해요.',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '감각이 과민해요(통증)',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '삼킴 곤란(혹은 콧줄)',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '배뇨 곤란(혹은 소변줄)',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '호흡 곤란(혹은 목관)',
          value: 6,
          symptomCode: ['S000'],
        },
        {
          label: '변비',
          value: 7,
          symptomCode: ['S126'],
        },
        {
          label: '소화불량',
          value: 8,
          symptomCode: ['S178'],
        },
        {
          label: '설사',
          value: 9,
          symptomCode: ['S159'],
        },
        {
          label: '우울증',
          value: 10,
          symptomCode: ['S227'],
        },
        {
          label: '섬망',
          value: 11,
          symptomCode: ['S615'],
        },
        {
          label: '불면',
          value: 12,
          symptomCode: ['S000'],
        },
        {
          label: '인지장애',
          value: 13,
          symptomCode: ['S000'],
        },
        {
          label: '두통',
          value: 14,
          symptomCode: ['S087'],
        },
        {
          label: '어지럼증',
          value: 15,
          symptomCode: ['S325'],
        },
        {
          label: '피로',
          value: 16,
          symptomCode: ['S056'],
        },
        {
          label: '의식저하',
          value: 17,
          symptomCode: ['S000'],
        },
        {
          label: '발음이 어눌해요.',
          value: 18,
          symptomCode: ['S000'],
        },
        {
          label: '말을 이해하지 못해요.',
          value: 19,
          symptomCode: ['S000'],
        },
        {
          label: '말을 하지 못해요.',
          value: 20,
          symptomCode: ['S000'],
        },
        {
          label: '시야장애',
          value: 21,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Df',
    {
      label: '기저질환',
      desc: ['기저질환을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '해당 질환이 없어요.',
          value: 0,
        },
        {
          label: '고혈압',
          value: 1,
          symptomCode: ['S023'],
        },
        {
          label: '당뇨',
          value: 2,
          symptomCode: ['S167'],
        },
        {
          label: '고지혈증',
          value: 3,
          symptomCode: ['S022'],
        },
        {
          label: '심근경색',
          value: 4,
          symptomCode: ['S036'],
        },
        {
          label: '부정맥',
          value: 5,
          symptomCode: ['S134'],
        },
        {
          label: '협심증',
          value: 6,
          symptomCode: ['S329', 'S036'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Dg',
    {
      label: '복용약',
      desc: ['복용 중인 약물을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '복용 중인 약이 없어요.',
          value: 0,
        },
        {
          label: '고혈압약',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '당뇨약',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '고지혈증약',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '항혈전제(아스피린 등)',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '항우울제',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'E',
    {
      label: '요추추간판탈출증',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['Ea', 'Eb', 'Ec', 'Ed', 'Ee', 'Ef'],
          value: 1,
          symptomCode: ['S766'],
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'Ea',
    {
      label: '요하지 증상',
      desc: ['요추추간판탈출증 관련하여', '불편한 증상을 모두 고르세요.'],
      selectType: 'Check',
      chartType: 'CC',
      answers: [
        {
          label: '허리가 쑤시고 아파요.',
          value: 'Eaa',
          next: 'Eaa',
          symptomCode: ['S226'],
        },
        {
          label: '허리가 시리고 시큰거려요.',
          value: 'Eab',
          next: 'Eab',
          symptomCode: ['S224'],
        },
        {
          label: '다리가 저려요.',
          value: 'Eac',
          next: 'Eac',
          symptomCode: ['S094'],
        },
        {
          label: '다리가 당겨요.',
          value: 'Ead',
          next: 'Ead',
          symptomCode: ['S000'],
        },
        {
          label: '다리가 욱신거려요.',
          value: 'Eae',
          next: 'Eae',
          symptomCode: ['S309'],
        },
        {
          label: '다리가 따끔따끔거려요.',
          value: 'Eaf',
          next: 'Eaf',
          symptomCode: ['S310'],
        },
        {
          label: '다리에 힘이 없어요.',
          value: 'Eag',
          next: 'Eag',
          symptomCode: ['S320'],
        },
        {
          label: '다리 피부의 감각이 둔해요.',
          value: 'Eah',
          next: 'Eah',
          symptomCode: ['S319'],
        },
        {
          label: '회음부 감각이 둔해요.',
          value: 'Eai',
          next: 'Eai',
          symptomCode: ['S319'],
        },
        {
          label: '대소변 실금이 있어요.',
          value: 'Eaj',
          next: 'Eaj',
          symptomCode: ['S000'],
        },
        {
          label: '대소변이 잘 안 나와요.',
          value: 'Eak',
          next: 'Eak',
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Eaa',
    {
      label: '요하지 증상',
      desc: ['허리가 쑤시고 아파요.'],
      symptomCode: ['S226'],
      selectType: 'Scale',
    },
  ],
  [
    'Eab',
    {
      label: '요하지 증상',
      desc: ['허리가 시리고 시큰거려요.'],
      symptomCode: ['S224'],
      selectType: 'Scale',
    },
  ],
  [
    'Eac',
    {
      label: '요하지 증상',
      desc: ['다리가 저려요.'],
      symptomCode: ['S094'],
      selectType: 'Scale',
    },
  ],
  [
    'Ead',
    {
      label: '요하지 증상',
      desc: ['다리가 당겨요.'],
      symptomCode: ['S000'],
      selectType: 'Scale',
    },
  ],
  [
    'Eae',
    {
      label: '요하지 증상',
      desc: ['다리가 욱신거려요.'],
      symptomCode: ['S309'],
      selectType: 'Scale',
    },
  ],
  [
    'Eaf',
    {
      label: '요하지 증상',
      desc: ['다리가 따끔따끔거려요.'],
      symptomCode: ['S310'],
      selectType: 'Scale',
    },
  ],
  [
    'Eag',
    {
      label: '요하지 증상',
      desc: ['다리에 힘이 없어요.'],
      symptomCode: ['S320'],
      selectType: 'Scale',
    },
  ],
  [
    'Eah',
    {
      label: '요하지 증상',
      desc: ['다리 피부의 감각이 둔해요.'],
      symptomCode: ['S319'],
      selectType: 'Scale',
    },
  ],
  [
    'Eai',
    {
      label: '요하지 증상',
      desc: ['회음부 감각이 둔해요.'],
      symptomCode: ['S319'],
      selectType: 'Scale',
    },
  ],
  [
    'Eaj',
    {
      label: '요하지 증상',
      desc: ['대소변 실금이 있어요.'],
      symptomCode: ['S000'],
      selectType: 'Scale',
    },
  ],
  [
    'Eak',
    {
      label: '요하지 증상',
      desc: ['대소변이 잘 안 나와요.'],
      symptomCode: ['S000'],
      selectType: 'Scale',
    },
  ],
  [
    'Eb',
    {
      label: '증상 악화 요인',
      desc: ['언제 증상이 악화되나요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '특별히 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '기침이나 재채기할 때',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '허리 굽힐 때',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '허리 펼 때',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '걸을 때',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '앉아있을 때',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '서있을 때',
          value: 6,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Ec',
    {
      label: '최근 증상발현일',
      desc: ['최근 요추추간판탈출증 증상이 나타난 날은 언제인가요?'],
      selectType: 'Date',
      chartType: 'OS',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'Ed',
    {
      label: '치료 내역',
      desc: ['요추추간판탈출증 증상으로', '병/의원을 방문한 적이 있나요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '치료받은 적 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '병/의원에서 치료 받았어요.',
          value: 'Eda',
          next: 'Eda',
          symptomCode: ['S000'],
        },
        {
          label: '한방병/의원에서 치료 받았어요.',
          value: 2,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Eda',
    {
      label: '치료 내역',
      desc: ['어떤 치료를 받으셨나요?'],
      symptomCode: ['S000'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '수술',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '시술',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '물리 치료',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '주사 치료',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '먹는 약 처방',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Ee',
    {
      label: '검사 내역',
      desc: ['어떤 검사를 받으셨나요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '검사 받은 적 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '엑스레이',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: 'CT',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: 'MRI',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '근전도 검사',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Ef',
    {
      label: '근골격계 질환',
      desc: ['진단 받은 근골격계 질환이 있으신가요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '진단받은 근골격계 질환이 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '허리 염좌',
          value: 'Efa',
          next: 'Efa',
          symptomCode: ['S301'],
        },
        {
          label: '경추추간판탈출증(목디스크)',
          value: 'Efb',
          next: 'Efb',
          symptomCode: ['S765'],
        },
        {
          label: '척추관 협착증',
          value: 'Efc',
          next: 'Efc',
          symptomCode: ['S767'],
        },
        {
          label: '척추전방전위증',
          value: 'Efd',
          next: 'Efd',
          symptomCode: ['S000'],
        },
        {
          label: '강직성 척추염',
          value: 'Efe',
          next: 'Efe',
          symptomCode: ['S768'],
        },
        {
          label: '골다공증',
          value: 'Eff',
          next: 'Eff',
          symptomCode: ['S028'],
        },
        {
          label: '척추측만증',
          value: 'Efg',
          next: 'Efg',
          symptomCode: ['S000'],
        },
        {
          label: '류마티스 관절염',
          value: 'Efh',
          next: 'Efh',
          symptomCode: ['S032'],
        },
        {
          label: '퇴행성 관절염',
          value: 'Efi',
          next: 'Efi',
          symptomCode: ['S764'],
        },
        {
          label: '척추 골절',
          value: 'Efj',
          next: 'Efj',
          symptomCode: ['S027'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Efa',
    {
      label: '근골격계 질환',
      desc: ['허리 염좌'],
      symptomCode: ['S301'],
      selectType: 'Onset',
    },
  ],
  [
    'Efb',
    {
      label: '근골격계 질환',
      desc: ['경추추간판탈출증(목디스크)'],
      symptomCode: ['S765'],
      selectType: 'Onset',
    },
  ],
  [
    'Efc',
    {
      label: '근골격계 질환',
      desc: ['척추관 협착증'],
      symptomCode: ['S767'],
      selectType: 'Onset',
    },
  ],
  [
    'Efd',
    {
      label: '근골격계 질환',
      desc: ['척추전방전위증'],
      symptomCode: ['S000'],
      selectType: 'Onset',
    },
  ],
  [
    'Efe',
    {
      label: '근골격계 질환',
      desc: ['강직성 척추염'],
      symptomCode: ['S768'],
      selectType: 'Onset',
    },
  ],
  [
    'Eff',
    {
      label: '근골격계 질환',
      desc: ['골다공증'],
      symptomCode: ['S028'],
      selectType: 'Onset',
    },
  ],
  [
    'Efg',
    {
      label: '근골격계 질환',
      desc: ['척추측만증'],
      symptomCode: ['S000'],
      selectType: 'Onset',
    },
  ],
  [
    'Efh',
    {
      label: '근골격계 질환',
      desc: ['류마티스 관절염'],
      symptomCode: ['S032'],
      selectType: 'Onset',
    },
  ],
  [
    'Efi',
    {
      label: '근골격계 질환',
      desc: ['퇴행성 관절염'],
      symptomCode: ['S764'],
      selectType: 'Onset',
    },
  ],
  [
    'Efj',
    {
      label: '근골격계 질환',
      desc: ['척추 골절'],
      symptomCode: ['S027'],
      selectType: 'Onset',
    },
  ],
  [
    'F',
    {
      label: '알레르기 비염',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['Fa', 'Fb', 'Fc', 'Fd', 'Fe', 'Ff', 'Fg'],
          value: 1,
          symptomCode: ['S145'],
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'Fa',
    {
      label: '비염 증상',
      desc: ['알레르기 비염 관련하여', '불편한 증상을 모두 고르세요.'],
      selectType: 'Check',
      chartType: 'CC',
      answers: [
        {
          label: '맑은 콧물',
          value: 'Faa',
          next: 'Faa',
          symptomCode: ['S300'],
        },
        {
          label: '끈적이는 누런 콧물',
          value: 'Fab',
          next: 'Fab',
          symptomCode: ['S300'],
        },
        {
          label: '재채기',
          value: 'Fac',
          next: 'Fac',
          symptomCode: ['S000'],
        },
        {
          label: '코막힘',
          value: 'Fad',
          next: 'Fad',
          symptomCode: ['S298'],
        },
        {
          label: '눈이나 코가 가려움',
          value: 'Fae',
          next: 'Fae',
          symptomCode: ['S177'],
        },
        {
          label: '후각감퇴',
          value: 'Faf',
          next: 'Faf',
          symptomCode: ['S000'],
        },
        {
          label: '코 통증',
          value: 'Fag',
          next: 'Fag',
          symptomCode: ['S000'],
        },
        {
          label: '두통',
          value: 'Fah',
          next: 'Fah',
          symptomCode: ['S087'],
        },
        {
          label: '청력장애',
          value: 'Fai',
          next: 'Fai',
          symptomCode: ['S249'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Faa',
    {
      label: '비염 증상',
      desc: ['맑은 콧물'],
      symptomCode: ['S300'],
      selectType: 'Scale',
    },
  ],
  [
    'Fab',
    {
      label: '비염 증상',
      desc: ['끈적이는 누런 콧물'],
      symptomCode: ['S300'],
      selectType: 'Scale',
    },
  ],
  [
    'Fac',
    {
      label: '비염 증상',
      desc: ['재채기'],
      symptomCode: ['S000'],
      selectType: 'Scale',
    },
  ],
  [
    'Fad',
    {
      label: '비염 증상',
      desc: ['코막힘'],
      symptomCode: ['S298'],
      selectType: 'Scale',
    },
  ],
  [
    'Fae',
    {
      label: '비염 증상',
      desc: ['눈이나 코가 가려움'],
      symptomCode: ['S177'],
      selectType: 'Scale',
    },
  ],
  [
    'Faf',
    {
      label: '비염 증상',
      desc: ['후각감퇴'],
      symptomCode: ['S000'],
      selectType: 'Scale',
    },
  ],
  [
    'Fag',
    {
      label: '비염 증상',
      desc: ['코 통증'],
      symptomCode: ['S000'],
      selectType: 'Scale',
    },
  ],
  [
    'Fah',
    {
      label: '비염 증상',
      desc: ['두통'],
      symptomCode: ['S087'],
      selectType: 'Scale',
    },
  ],
  [
    'Fai',
    {
      label: '비염 증상',
      desc: ['청력장애'],
      symptomCode: ['S249'],
      selectType: 'Scale',
    },
  ],
  [
    'Fb',
    {
      label: '최근 증상발현일',
      desc: ['최근 알레르기 비염 증상이 나타난 날은 언제인가요?'],
      selectType: 'Date',
      chartType: 'OS',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'Fc',
    {
      label: '증상 정도',
      desc: ['증상 정도와 관련해서', '해당되는 사항을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '일주일에 4일 이상',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '일주일에 4일 미만',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '계절에 상관 있음',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '계절에 상관 없음',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '일이나 수면 등 일상 생활 지장이 있음',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '일이나 수면 등 일상 생활 지장이 없음',
          value: 6,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Fd',
    {
      label: '치료 내역',
      desc: ['알레르기 비염 증상으로', '병/의원을 방문한 적이 있나요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '치료받은 적 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '병/의원에서 치료 받았어요.',
          value: 1,
          next: 'Fda',
          symptomCode: ['S000'],
        },
        {
          label: '한방병/의원에서 치료 받았어요.',
          value: 2,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Fda',
    {
      label: '치료 내역',
      desc: ['어떤 치료를 받으셨나요?'],
      symptomCode: ['S000'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '수술',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '시술',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '주사 치료',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '약 처방',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Fe',
    {
      label: '약물 치료',
      desc: ['알레르기 비염과 관련해서', '사용 중인 약을 모두 선택하세요.'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '관련해서 사용 중인 약이 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '항히스타민제',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '먹는 스테로이드제',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '뿌리는 스테로이드제',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '먹는 비점막수축제',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '뿌리는 비점막수축제',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '항류코트리엔제',
          value: 6,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Ff',
    {
      label: '관련 질환',
      desc: ['다음 중 진단받은 질환이 있으신가요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '진단받은 관련 질환이 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '비부비동염',
          value: 'Ffa',
          next: 'Ffa',
          symptomCode: ['S300', 'S298'],
        },
        {
          label: '코 속에 용종',
          value: 'Ffb',
          next: 'Ffb',
          symptomCode: ['S144'],
        },
        {
          label: '아토피피부염',
          value: 'Ffc',
          next: 'Ffc',
          symptomCode: ['S189', 'S190', 'S050'],
        },
        {
          label: '알레르기성 결막염',
          value: 'Ffd',
          next: 'Ffd',
          symptomCode: ['S109'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Ffa',
    {
      label: '관련 질환',
      desc: ['비부비동염'],
      symptomCode: ['S300', 'S298'],
      selectType: 'Onset',
    },
  ],
  [
    'Ffb',
    {
      label: '관련 질환',
      desc: ['코 속에 용종'],
      symptomCode: ['S144'],
      selectType: 'Onset',
    },
  ],
  [
    'Ffc',
    {
      label: '관련 질환',
      desc: ['아토피피부염'],
      symptomCode: ['S189', 'S190', 'S050'],
      selectType: 'Onset',
    },
  ],
  [
    'Ffd',
    {
      label: '관련 질환',
      desc: ['알레르기성 결막염'],
      symptomCode: ['S109'],
      selectType: 'Onset',
    },
  ],
  [
    'Fg',
    {
      label: '알레르기 가족력',
      desc: [
        '부모형제 중에 알레르기 비염, 아토피피부염,',
        '알레르기성 결막염 등의',
        '알레르기 질환이 있는 분이 있나요?',
      ],
      selectType: 'Radio',
      chartType: 'FH',
      answers: [
        {
          label: '있어요.',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'G',
    {
      label: '기능성 소화불량',
      desc: ['불편한 점이 있으신가요?'],
      selectType: 'Radio',
      answers: [
        {
          label: '네, 있어요.',
          next: ['Ga', 'Gb', 'Gc', 'Gd', 'Ge', 'Gf', 'Gg'],
          value: 1,
          symptomCode: ['S178'],
        },
        {
          label: '아니요, 없어요.',
          value: 0,
        },
      ],
    },
  ],
  [
    'Ga',
    {
      label: '소화불량 증상',
      desc: ['소화불량 관련하여 불편한 증상을 모두 고르세요.'],
      selectType: 'Check',
      chartType: 'CC',
      answers: [
        {
          label: '윗배가 갑갑해요.',
          value: 'Gaa',
          next: 'Gaa',
          symptomCode: ['S219'],
        },
        {
          label: '배에 가스가 차고 빵빵해요.',
          value: 'Gab',
          next: 'Gab',
          symptomCode: ['S143'],
        },
        {
          label: '속이 더부룩해요.',
          value: 'Gac',
          next: 'Gac',
          symptomCode: ['S194'],
        },
        {
          label: '현재 체해서 속이 답답해요.',
          value: 'Gad',
          next: 'Gad',
          symptomCode: ['S196'],
        },
        {
          label: '식사량이 적고 조금만 먹어도 쉽게 배가 불러요.',
          value: 'Gae',
          next: 'Gae',
          symptomCode: ['S000'],
        },
        {
          label: '입맛이 없어요.',
          value: 'Gaf',
          next: 'Gaf',
          symptomCode: ['S192'],
        },
        {
          label: '속이 메스껍고 구역질이 나요.',
          value: 'Gag',
          next: 'Gag',
          symptomCode: ['S040'],
        },
        {
          label: '신물이 자주 올라와요.',
          value: 'Gah',
          next: 'Gah',
          symptomCode: ['S302'],
        },
        {
          label: '구토를 자주 해요.',
          value: 'Gai',
          next: 'Gai',
          symptomCode: ['S044'],
        },
        {
          label: '딸꾹질을 자주 해요.',
          value: 'Gaj',
          next: 'Gaj',
          symptomCode: ['S088'],
        },
        {
          label: '트림을 자주 해요.',
          value: 'Gak',
          next: 'Gak',
          symptomCode: ['S310'],
        },
        {
          label: '명치 밑(윗배)이 아파요.',
          value: 'Gal',
          next: 'Gal',
          symptomCode: ['S218'],
        },
        {
          label: '아랫배(배꼽 아래)가 아파요.',
          value: 'Gam',
          next: 'Gam',
          symptomCode: ['S131'],
        },
        {
          label: '윗배(배꼽 위)가 아파요.',
          value: 'Gan',
          next: 'Gan',
          symptomCode: ['S218'],
        },
        {
          label: '옆구리가 결리고 아파요.',
          value: 'Gao',
          next: 'Gao',
          symptomCode: ['S330'],
        },
        {
          label: '속이 쓰려요.',
          value: 'Gap',
          next: 'Gap',
          symptomCode: ['S180'],
        },
        {
          label: '배에서 꾸르륵 소리가 나요.',
          value: 'Gaq',
          next: 'Gaq',
          symptomCode: ['S265'],
        },
        {
          label: '입이 써요.',
          value: 'Gar',
          next: 'Gar',
          symptomCode: ['S037'],
        },
        {
          label: '입이 바짝바짝 말라요.',
          value: 'Gas',
          next: 'Gas',
          symptomCode: ['S041'],
        },
        {
          label: '갈증이 나서 물을 많이 마셔요.',
          value: 'Gat',
          next: 'Gat',
          symptomCode: ['S006'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Gaa',
    {
      label: '소화불량 증상',
      desc: ['윗배가 갑갑해요.'],
      symptomCode: ['S219'],
      selectType: 'Scale',
    },
  ],
  [
    'Gab',
    {
      label: '소화불량 증상',
      desc: ['배에 가스가 차고 빵빵해요.'],
      symptomCode: ['S143'],
      selectType: 'Scale',
    },
  ],
  [
    'Gac',
    {
      label: '소화불량 증상',
      desc: ['속이 더부룩해요.'],
      symptomCode: ['S194'],
      selectType: 'Scale',
    },
  ],
  [
    'Gad',
    {
      label: '소화불량 증상',
      desc: ['현재 체해서 속이 답답해요.'],
      symptomCode: ['S196'],
      selectType: 'Scale',
    },
  ],
  [
    'Gae',
    {
      label: '소화불량 증상',
      desc: ['식사량이 적고 조금만 먹어도 쉽게 배가 불러요.'],
      symptomCode: ['S000'],
      selectType: 'Scale',
    },
  ],
  [
    'Gaf',
    {
      label: '소화불량 증상',
      desc: ['입맛이 없어요.'],
      symptomCode: ['S192'],
      selectType: 'Scale',
    },
  ],
  [
    'Gag',
    {
      label: '소화불량 증상',
      desc: ['속이 메스껍고 구역질이 나요.'],
      symptomCode: ['S040'],
      selectType: 'Scale',
    },
  ],
  [
    'Gah',
    {
      label: '소화불량 증상',
      desc: ['신물이 자주 올라와요.'],
      symptomCode: ['S302'],
      selectType: 'Scale',
    },
  ],
  [
    'Gai',
    {
      label: '소화불량 증상',
      desc: ['구토를 자주 해요.'],
      symptomCode: ['S044'],
      selectType: 'Scale',
    },
  ],
  [
    'Gaj',
    {
      label: '소화불량 증상',
      desc: ['딸꾹질을 자주 해요.'],
      symptomCode: ['S088'],
      selectType: 'Scale',
    },
  ],
  [
    'Gak',
    {
      label: '소화불량 증상',
      desc: ['트림을 자주 해요.'],
      symptomCode: ['S310'],
      selectType: 'Scale',
    },
  ],
  [
    'Gal',
    {
      label: '소화불량 증상',
      desc: ['명치 밑(윗배)이 아파요.'],
      symptomCode: ['S218'],
      selectType: 'Scale',
    },
  ],
  [
    'Gam',
    {
      label: '소화불량 증상',
      desc: ['아랫배(배꼽 아래)가 아파요.'],
      symptomCode: ['S131'],
      selectType: 'Scale',
    },
  ],
  [
    'Gan',
    {
      label: '소화불량 증상',
      desc: ['윗배(배꼽 위)가 아파요.'],
      symptomCode: ['S218'],
      selectType: 'Scale',
    },
  ],
  [
    'Gao',
    {
      label: '소화불량 증상',
      desc: ['옆구리가 결리고 아파요.'],
      symptomCode: ['S330'],
      selectType: 'Scale',
    },
  ],
  [
    'Gap',
    {
      label: '소화불량 증상',
      desc: ['속이 쓰려요.'],
      symptomCode: ['S180'],
      selectType: 'Scale',
    },
  ],
  [
    'Gaq',
    {
      label: '소화불량 증상',
      desc: ['배에서 꾸르륵 소리가 나요.'],
      symptomCode: ['S265'],
      selectType: 'Scale',
    },
  ],
  [
    'Gar',
    {
      label: '소화불량 증상',
      desc: ['입이 써요.'],
      symptomCode: ['S037'],
      selectType: 'Scale',
    },
  ],
  [
    'Gas',
    {
      label: '소화불량 증상',
      desc: ['입이 바짝바짝 말라요.'],
      symptomCode: ['S041'],
      selectType: 'Scale',
    },
  ],
  [
    'Gat',
    {
      label: '소화불량 증상',
      desc: ['갈증이 나서 물을 많이 마셔요.'],
      symptomCode: ['S006'],
      selectType: 'Scale',
    },
  ],
  [
    'Gb',
    {
      label: '소화불량 발병일',
      desc: ['소화불량 증상이 처음 발생한 시점을 알려주세요.'],
      selectType: 'Radio',
      chartType: 'OS',
      answers: [
        {
          label: '3개월 이내',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '6개월 이내',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '6개월 이상',
          value: 3,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Gc',
    {
      label: '최근 증상발현일',
      desc: ['최근 소화불량 증상이 나타난 날은 언제인가요?'],
      selectType: 'Date',
      chartType: 'OS',
      defaultDate: '2024-07-16',
    },
  ],
  [
    'Gd',
    {
      label: '증상 빈도',
      desc: ['소화불량 증상은 얼마나 자주 있으신가요?'],
      selectType: 'Radio',
      chartType: 'PI',
      answers: [
        {
          label: '가끔',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '일주일에 1일 이상',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '일주일에 3일 이상',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '거의 매일',
          value: 4,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Ge',
    {
      label: '치료 내역',
      desc: ['소화불량 증상으로', '병/의원을 방문한 적이 있나요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '치료받은 적 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '병/의원에서 치료 받았어요.',
          value: 'Gea',
          next: 'Gea',
          symptomCode: ['S000'],
        },
        {
          label: '한방병/의원에서 치료 받았어요.',
          value: 2,
          symptomCode: ['S000'],
        },
      ],
    },
  ],
  [
    'Gea',
    {
      label: '치료 내역',
      desc: ['어떤 치료를 받으셨나요?'],
      symptomCode: ['S000'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '수술',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '시술',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '먹는 약 처방',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Gf',
    {
      label: '검사 내역',
      desc: ['어떤 검사를 받으셨나요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '검사 받은 적 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '위내시경',
          value: 1,
          symptomCode: ['S000'],
        },
        {
          label: '복부초음파',
          value: 2,
          symptomCode: ['S000'],
        },
        {
          label: '위전도',
          value: 3,
          symptomCode: ['S000'],
        },
        {
          label: '복부 CT',
          value: 4,
          symptomCode: ['S000'],
        },
        {
          label: '복부 MRI',
          value: 5,
          symptomCode: ['S000'],
        },
        {
          label: '헬리코박터균 검사',
          value: 6,
          symptomCode: ['S000'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Gg',
    {
      label: '소화기 질환',
      desc: ['진단 받은 소화기 질환이 있으신가요?'],
      selectType: 'Check',
      chartType: 'PI',
      answers: [
        {
          label: '진단받은 소화기 질환이 없어요.',
          value: 0,
          mustShowFlagInYejinSymptomTable: true,
        },
        {
          label: '식도 악성종양',
          value: 'Gga',
          next: 'Gga',
          symptomCode: ['S000'],
        },
        {
          label: '위 악성종양',
          value: 'Ggb',
          next: 'Ggb',
          symptomCode: ['S000'],
        },
        {
          label: '십이지장 악성종양',
          value: 'Ggc',
          next: 'Ggc',
          symptomCode: ['S000'],
        },
        {
          label: '소화성궤양',
          value: 'Ggd',
          next: 'Ggd',
          symptomCode: ['S179'],
        },
        {
          label: '역류성 식도염',
          value: 'Gge',
          next: 'Gge',
          symptomCode: ['S726'],
        },
        {
          label: '위염',
          value: 'Ggf',
          next: 'Ggf',
          symptomCode: ['S727'],
        },
        {
          label: '기타(주관식)',
          value: -1,
        },
      ],
    },
  ],
  [
    'Gga',
    {
      label: '소화기 질환',
      desc: ['식도 악성종양'],
      symptomCode: ['S000'],
      selectType: 'Onset',
    },
  ],
  [
    'Ggb',
    {
      label: '소화기 질환',
      desc: ['위 악성종양'],
      symptomCode: ['S000'],
      selectType: 'Onset',
    },
  ],
  [
    'Ggc',
    {
      label: '소화기 질환',
      desc: ['십이지장 악성종양'],
      symptomCode: ['S000'],
      selectType: 'Onset',
    },
  ],
  [
    'Ggd',
    {
      label: '소화기 질환',
      desc: ['소화성궤양'],
      symptomCode: ['S179'],
      selectType: 'Onset',
    },
  ],
  [
    'Gge',
    {
      label: '소화기 질환',
      desc: ['역류성 식도염'],
      symptomCode: ['S726'],
      selectType: 'Onset',
    },
  ],
  [
    'Ggf',
    {
      label: '소화기 질환',
      desc: ['위염'],
      symptomCode: ['S727'],
      selectType: 'Onset',
    },
  ],
]);
