import { BrowserRouter, Route, Routes } from 'react-router-dom';

import SettingPage from '../components/pages/clinic/SettingPage';
import MainPage from '../components/pages/landing/MainPage';
import TreatmentDetailPage from '../components/pages/treatment/TreatmentDetailPage';
import PatientDetailPage from '../components/pages/patient/PatientDetailPage';
import PatientSurveyPage from '../components/pages/patient/PatientSurveyPage';
import DashboardPage from '../components/pages/etc/DashboardPage';
import ClinicListPage from '../components/pages/etc/ClinicList';
import SignupUserCountPage from '../components/pages/etc/SignupUserCountPage';
import PatientCountPage from '../components/pages/etc/PatientCountPage';
import SurveyAndTreatmentCountPage from '../components/pages/etc/SurveyAndTreatmentCountPage';
import QRDashboardPage from '../components/pages/etc/QRDashboardPage';
import QRList from '../components/pages/qr/QRList';
import DashboardClinicDetailPage from '../components/pages/etc/DashboardClinicDetailPage';
import PatientListPage from '../components/pages/patient/PatientListPage';
import SignUpConfirmPage from '../components/pages/sign/signup/SignUpConfirmPage';
import SignInWithoutClinic from '../components/pages/sign/SignInWithoutClinicPage';
import SignInPage from '../components/pages/sign/SignInPage';
import SignUpPage from '../components/pages/sign/signup/SignUpPage';
import SubscriptionPage from '../components/pages/subscription/SubscriptionPage';
import PrescriptionDispensationRecord from 'components/UI/organisms/treatment/PrescriptionDispensationRecord.jsx';
import PrescriptionRecord from 'components/UI/organisms/treatment/PrescriptionRecord.jsx';

import RequireAuth from './RequireAuth';
import TreatmentPage from '../components/pages/treatment/TreatmentPage';
import TreatmentHistoryPage from '../components/pages/treatment/TreatmentHistoryPage';
import SignUpStepConfirmPage from '../components/pages/sign/signup/SignUpStepConfirmPage';
import CreateClinicPage from 'components/pages/landing/CreateClinicPage';
import PolicyDetailPage from 'components/pages/landing/PolicyDetailPage';
import BrochurePage from 'components/pages/landing/BrochurePage';
import NotFoundPage from 'components/pages/landing/NotFoundPage';
import PasswordResetPage from 'components/pages/sign/PasswordResetPage';
import ConfirmForgotPasswordPage from 'components/pages/sign/ConfirmForgotPasswordPage';
import ScrollTop from 'components/ScrollTop';

export const MyRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signup/:token" element={<SignUpConfirmPage />} />
        <Route
          path="/signinWithoutClinic"
          element={
            <RequireAuth>
              <SignInWithoutClinic />
            </RequireAuth>
          }
        />
        <Route path="/forgotpassword" element={<PasswordResetPage />} />
        <Route path="/confirmforgotpassword" element={<ConfirmForgotPasswordPage />} />
        <Route path="/policy" element={<PolicyDetailPage />} />
        <Route path="/brochure" element={<BrochurePage />} />
        <Route path="/policy/:index" element={<PolicyDetailPage />} />
        {/* Treatment Router */}
        <Route
          path="/treatment"
          element={
            <RequireAuth>
              <TreatmentPage />
            </RequireAuth>
          }
        />
        <Route
          path="/treatmentHistory"
          element={
            <RequireAuth>
              <TreatmentHistoryPage />
            </RequireAuth>
          }
        />
        <Route
          path="/treatment/:id"
          element={
            <RequireAuth>
              <TreatmentDetailPage />
            </RequireAuth>
          }
        />
        {/* Patient Router */}
        <Route
          path="/patient"
          element={
            <RequireAuth>
              <PatientListPage />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/:id"
          element={
            <RequireAuth>
              <PatientDetailPage />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/:id/survey"
          element={
            <RequireAuth>
              <PatientSurveyPage />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/:id/survey/:surveyId"
          element={
            <RequireAuth>
              <PatientSurveyPage />
            </RequireAuth>
          }
        />
        <Route
          path="/patient/:id/survey/:surveyId/r/:treatmentId"
          element={
            <RequireAuth>
              <PatientSurveyPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/setting'}
          element={
            <RequireAuth>
              <SettingPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/clinic/create'}
          element={
            <RequireAuth>
              <CreateClinicPage />
            </RequireAuth>
          }
        />
        {/* 구독 요금제 */}
        <Route
          path={'/subscription'}
          element={
            <RequireAuth>
              <SubscriptionPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/dashboard-daily-signup-user-count'}
          element={
            <RequireAuth>
              <SignupUserCountPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/dashboard-daily-patient-count'}
          element={
            <RequireAuth>
              <PatientCountPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/dashboard-daily-survey-treatment-count'}
          element={
            <RequireAuth>
              <SurveyAndTreatmentCountPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/dashboard'}
          element={
            <RequireAuth>
              <DashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/clinicList'}
          element={
            <RequireAuth>
              <ClinicListPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/qr-dashboard'}
          element={
            <RequireAuth>
              <QRDashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/selfqr'}
          element={
            <RequireAuth>
              <QRList />
            </RequireAuth>
          }
        />
        <Route
          path={'/dashboard/:clinicId'}
          element={
            <RequireAuth>
              <DashboardClinicDetailPage />
            </RequireAuth>
          }
        />
        <Route
          path={'/pdr/:treatmentId'}
          element={
            <RequireAuth>
              <PrescriptionDispensationRecord />
            </RequireAuth>
          }
        />
        <Route
          path={'/pr/:treatmentId'}
          element={
            <RequireAuth>
              <PrescriptionRecord />
            </RequireAuth>
          }
        />
        <Route path={'/invite/:token'} element={<SignUpStepConfirmPage />} />
        <Route path={'*'} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
