import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  GetUserQuery,
  UpdateUserInput,
  UpdateUserMutation,
  RoleByClinicQuery,
  UpdateDailySignupCountInput,
  UpdateDailySignupCountMutation,
} from '../API';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

import { repeatCallingListApiToLimit } from 'utils/util';

class UserRepository {
  getUser = async (userID: string) => {
    const response = (await API.graphql(
      graphqlOperation(
        queries.getUser,
        { id: userID },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: GetUserQuery };
    return response.data;
  };

  listDailySignupCount = async (nextToken?: string) => {
    const res = await repeatCallingListApiToLimit({
      limit: Infinity,
      queryLimitBeforeFilter: 3000,
      nextToken,
      targetGraphqlListQuery: queries.listDailySignupCounts,
      targetGraphqlListFunctionDataFieldName: 'listDailySignupCounts',
      targetGraphqlListQueryParam: { nextToken },
    });
    return res;
  };

  updateDailySignupCount = async (input: UpdateDailySignupCountInput) => {
    const response = (await API.graphql(
      graphqlOperation(
        mutations.updateDailySignupCount,
        { input },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: UpdateDailySignupCountMutation };
    return response.data;
  };

  updateUser = async (input: UpdateUserInput) => {
    const response = (await API.graphql(
      graphqlOperation(
        mutations.updateUser,
        { input },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: UpdateUserMutation };
    return response.data;
  };

  listRolesByClinicId = async (clinicId: string) => {
    const roles = (await API.graphql(
      graphqlOperation(
        queries.roleByClinic,
        { clinicID: clinicId, filter: { isDeleted: { ne: true } } },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: RoleByClinicQuery };

    return Promise.all(
      roles.data.roleByClinic.items.map(async (item) => {
        const user = (await this.getUser(item.userID)).getUser;
        return {
          ...item,
          user,
        };
      }),
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserRepository();
