import { makeAutoObservable } from 'mobx';
import { User, UserRole } from '../API';
import RoleRepository from '../repository/RoleRepository';
import UserRepository from '../repository/UserRepository';

import { RootStore } from './RootStore';

export type MetaData = {
  clinicID: string;
  clinicName: string;
  user: User;
  userRole?: UserRole;
  userRoles?: (UserRole | null)[];
};

export class ClientStore {
  rootStore: RootStore;
  user: User;
  metaData?: MetaData;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    if (!!sessionStorage.getItem('MetaData')) {
      this.getMetaData();
    }
  }

  getMetaData() {
    this.metaData = JSON.parse(sessionStorage.getItem('MetaData')!);
    // console.log(this.metaData);
  }

  getUser = async (userId: string) => {
    const userResponse = await UserRepository.getUser(userId);
    this.user = userResponse?.getUser;
    return userResponse?.getUser;
  };

  setMetaData = async (userId: string) => {
    const userResponse = await this.getUser(userId);
    const userRoleResponse: any = await RoleRepository.roleByUser(userId);

    let metaData: MetaData;
    const acl: any[] = userRoleResponse.roleByUser?.items.filter((e) => e?.isDeleted !== true);
    if (acl?.length === 0) {
      metaData = {
        user: userResponse,
      } as MetaData;
    } else {
      metaData = {
        user: userResponse,
        userRole: acl[0],
        userRoles: acl,
      } as MetaData;
    }

    sessionStorage.setItem('MetaData', JSON.stringify(metaData));
    this.getMetaData();
  };
}
