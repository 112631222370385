import React, { useState, useRef, useEffect } from 'react';
import {
  format,
  addDays,
  subYears,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  isWithinInterval,
} from 'date-fns';
import { Calendar as CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';

interface DateRange {
  start: Date | null;
  end: Date | null;
}

export const RangeDatePicker = ({
  initDateRange,
  initDateTrigger,
  defaultStartDate = subYears(new Date(), 5),
  defaultEndDate = new Date(),
  setDateRangeFunc,
  onEnterPress,
}: {
  initDateRange?: Date[];
  initDateTrigger?: boolean;
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  setDateRangeFunc?: React.Dispatch<React.SetStateAction<Date[]>>;
  onEnterPress?: (dateRange) => void;
}) => {
  const [dateRange, setDateRange] = useState<DateRange>({ start: defaultStartDate, end: defaultEndDate });
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<Date>(defaultEndDate);
  const [inputValue, setInputValue] = useState<string>('');
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current && cursorPosition !== null) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [inputValue, cursorPosition]);

  useEffect(() => {
    setInputValue(formatDateRangeForDisplay(dateRange));
  }, [dateRange]);

  useEffect(() => {
    if (showCalendar && dateRange.end) {
      setCurrentMonth(dateRange.end);
    }
  }, [showCalendar, dateRange.end]);

  useEffect(() => {
    if (initDateTrigger && initDateRange) {
      const [start, end] = initDateRange;
      setDateRange({ start, end });
    }
  }, [initDateTrigger]);

  const formatDateForDisplay = (date: Date | null): string => {
    if (!date) return '';
    return format(date, 'yyyy.MM.dd');
  };

  const formatDateRangeForDisplay = (range: DateRange): string => {
    return `${formatDateForDisplay(range.start)} - ${formatDateForDisplay(range.end)}`;
  };

  const correctDate = (year: number, month: number, day: number): Date => {
    year = Math.max(1970, Math.min(9999, year));
    month = Math.max(1, Math.min(12, month));
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    day = Math.max(1, Math.min(lastDayOfMonth, day));
    return new Date(year, month - 1, day);
  };

  const parseAndCorrectDate = (dateString: string): Date | null => {
    const parts = dateString.replace(/[^\d]/g, '').padEnd(8, '0');
    if (parts.length !== 8) return null;

    let year = parseInt(parts.slice(0, 4), 10);
    let month = parseInt(parts.slice(4, 6), 10);
    let day = parseInt(parts.slice(6, 8), 10);

    year = Math.max(1970, Math.min(9999, year));
    month = Math.max(1, Math.min(12, month));
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    day = Math.max(1, Math.min(lastDayOfMonth, day));

    return new Date(year, month - 1, day);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setCursorPosition(e.target.selectionStart);
  };

  const handleInputBlur = ({ additionalAction }: { additionalAction?: any }) => {
    const dateStrings = inputValue.split('-').map((d) => d.trim());
    let start = dateRange.start;
    let end = dateRange.end;

    if (dateStrings.length >= 2) {
      const newStart = parseAndCorrectDate(dateStrings[0]);
      const newEnd = parseAndCorrectDate(dateStrings[1]);
      if (newStart) start = newStart;
      if (newEnd) end = newEnd;
    } else if (dateStrings.length === 1) {
      const newDate = parseAndCorrectDate(dateStrings[0]);
      if (newDate) {
        start = newDate;
        end = newDate;
      }
    }

    if (start && end && start > end) {
      [start, end] = [end, start];
    }

    const newDateRange = {
      start: start || defaultStartDate,
      end: end || defaultEndDate,
    };
    setDateRange(newDateRange);
    setInputValue(formatDateRangeForDisplay(newDateRange));
    if (setDateRangeFunc) {
      setDateRangeFunc([newDateRange.start, newDateRange.end]);
    }
    if (additionalAction) {
      additionalAction([newDateRange.start, newDateRange.end]);
    }
  };

  const handleDateSelect = (day: Date) => {
    setDateRange((prev) => {
      if (!prev.start || (prev.start && prev.end)) {
        if (setDateRangeFunc) {
          setDateRangeFunc([day, null]);
        }
        return { start: day, end: null };
      } else {
        const newStart = day < prev.start ? day : prev.start;
        const newEnd = day < prev.start ? prev.start : day;
        if (setDateRangeFunc) {
          setDateRangeFunc([newStart, newEnd]);
        }

        return day < prev.start ? { start: day, end: prev.start } : { start: prev.start, end: day };
      }
    });
  };

  const renderHeader = () => {
    const dateFormat = 'MMMM yyyy';
    return (
      <div className="flex items-center justify-between p-2">
        <button onClick={prevMonth} className="p-1 hover:bg-gray-200 rounded">
          <ChevronLeft size={20} />
        </button>
        <span className="font-semibold whitespace-nowrap ">{format(currentMonth, dateFormat)}</span>
        <button onClick={nextMonth} className="p-1 hover:bg-gray-200 rounded">
          <ChevronRight size={20} />
        </button>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = 'EEEEE';
    const days = [];
    let startDate = startOfWeek(currentMonth);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div key={i} className="text-center text-xs font-medium text-gray-500">
          {format(addDays(startDate, i), dateFormat)}
        </div>,
      );
    }
    return <div className="grid grid-cols-7 gap-1 mb-2">{days}</div>;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const isSelected =
          (dateRange.start && isSameDay(day, dateRange.start)) ||
          (dateRange.end && isSameDay(day, dateRange.end));
        const isInRange =
          dateRange.start &&
          dateRange.end &&
          isWithinInterval(day, { start: dateRange.start, end: dateRange.end });
        days.push(
          <div
            key={day.toISOString()}
            className={`flex justify-center text-0.9 px-2 mt-1 text-center cursor-pointer rounded hover:bg-blue-100 ${
              !isSameMonth(day, monthStart)
                ? 'text-gray-400'
                : isSelected
                ? 'bg-blue-500 text-white'
                : isInRange
                ? 'bg-blue-100'
                : ''
            }`}
            onClick={() => handleDateSelect(cloneDay)}
          >
            {formattedDate}
          </div>,
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div key={day.toISOString()} className="grid grid-cols-7 gap-1">
          {days}
        </div>,
      );
      days = [];
    }
    return <div className="mb-2">{rows}</div>;
  };

  const nextMonth = () => {
    setCurrentMonth(addDays(currentMonth, 30));
  };

  const prevMonth = () => {
    setCurrentMonth(addDays(currentMonth, -30));
  };

  return (
    <div className="relative" ref={calendarRef}>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          // @ts-ignore
          onBlur={handleInputBlur}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && onEnterPress) {
              handleInputBlur({ additionalAction: onEnterPress });
            }
          }}
          placeholder="날짜를 입력해 주세요."
          className="w-48 border border-afafaf rounded-3px h-8.4 pl-3 pr-6 focus:outline-none text-0.8"
        />
        <button
          onClick={() => setShowCalendar(!showCalendar)}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
        >
          <CalendarIcon
            style={{
              width: '1.1rem',
            }}
          />
        </button>
      </div>
      {showCalendar && (
        <div className="absolute top-full left-0 mt-2 bg-white border rounded-md shadow-lg p-4 z-10 text-0.8">
          {renderHeader()}
          {renderDays()}
          {renderCells()}
        </div>
      )}
    </div>
  );
};
