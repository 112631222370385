import { Flex, Heading } from '@aws-amplify/ui-react';
import { FC } from 'react';

interface PolicyDetailLayoutProps {
  title;
  content;
}

const PolicyDetailLayout: FC<PolicyDetailLayoutProps> = (props) => {
  return (
    <Flex direction={'column'} gap={0}>
      <Heading marginTop={'2rem'} level={2} fontWeight={700} fontSize={'1.2rem'}>
        {props.title}
      </Heading>
      <Flex direction={'column'} marginTop={'1rem'} gap={0} fontWeight={400} fontSize={'1rem'}>
        {props.content}
      </Flex>
    </Flex>
  );
};

export default PolicyDetailLayout;
