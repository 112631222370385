/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { css } from '@emotion/css';
import _ from 'lodash';

import { useStores } from 'stores/Context';
import { TypographyText, Row, Col, Divider, TextArea, MinusButton, PlusButton } from 'components/UI/atoms';
import { DIAGNOSIS } from 'utils/SymptomCode';

import PulseMeterModal from './PulseMeterModal';

const TreatmentSymptomRight = ({ memo, setMemo }) => {
  const [checklist, setChecklist] = useState({});
  const [isOpenKakaoPreview, setIsOpenKakaoPreview] = useState(false);
  const [isPulseMeterUsed, setIsPulseMeterUsed] = useState(false); // 대요메디 맥진기 사용 여부
  const [isOpenPulseMeterModal, setIsOpenPulseMeterModal] = useState(false); // 대요메디 맥진기 모달 오픈 여부

  const { treatmentStore, clinicStore, feedbackStore, clientStore } = useStores();
  useEffect(() => {
    if (treatmentStore?.treatment) {
      const clinicConfig = JSON.parse(clinicStore.clinic?.config ?? '{}');
      setIsPulseMeterUsed(!!clinicConfig?.pulseMeterUseFlag); // 대요메디 맥진기 사용 여부
      const symptomCodes = Array.from(treatmentStore?.checkedSymptom)?.reduce(
        (a: any, c: any) => ({
          ...a,
          [c]: treatmentStore.checkedSymptom?.has(c),
        }),
        {},
      );
      setChecklist(symptomCodes ?? {});
      const memoComp = document.getElementById('memo');
      memoComp.style.height =
        memoComp['scrollHeight'] > memoComp['clientHeight'] ? memoComp['scrollHeight'] + 'px' : 'fit-content';
    }
  }, [treatmentStore?.treatment]);

  return (
    <Row
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '2rem',
        paddingRight: '0.25rem',
      }}
    >
      <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1.2rem' }}>
        {isPulseMeterUsed && (
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className="treatment-detail-button"
              style={{
                paddingInline: '1rem',
                height: '2.25rem',
                width: 'fit-content',
                borderRadius: '3px',
                background: '#EEEFF3',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => setIsOpenPulseMeterModal(true)}
            >
              <TypographyText
                fontSize="0.8rem"
                fontWeight={700}
                lineHeight="0.8rem"
                className={css`
                  white-spcae: no-wrap;
                  letter-spacing: -0.02rem;
                `}
              >
                맥진 측정 기록 불러오기
              </TypographyText>
            </button>
          </Col>
        )}
        <Row
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {DIAGNOSIS.map((e, i) => {
            return (
              <Col
                style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', rowGap: '0.75rem' }}
                key={`diagnosis_depth_${i}`}
              >
                <Row style={{ display: 'flex' }}>
                  <TypographyText fontSize="1rem" lineHeight="1.2em" fontWeight={700}>
                    {e.label}
                  </TypographyText>
                </Row>
                <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  {e.children.map((c) => {
                    const checked = checklist[c.sympomCode] ? true : false;
                    return (
                      <Col
                        key={c.sympomCode}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '0.8rem',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (!checked) {
                            treatmentStore.addCheckedSymptom(c.sympomCode);
                            setChecklist({
                              ...checklist,
                              [c.sympomCode]: true,
                            });
                          } else {
                            treatmentStore.deleteCheckedSymptom(c.sympomCode);
                            setChecklist({
                              ...checklist,
                              [c.sympomCode]: false,
                            });
                          }
                        }}
                      >
                        {checked ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/img/checked-blue.webp`}
                            style={{ width: '1.2rem' }}
                            alt={`checked`}
                          />
                        ) : (
                          <img
                            src={`${process.env.PUBLIC_URL}/img/not-checked.webp`}
                            style={{ width: '1.2rem' }}
                            alt={`checked`}
                          />
                        )}
                        <TypographyText fontSize="1rem" lineHeight="1.2em">
                          {c.label}
                        </TypographyText>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            );
          })}
        </Row>
        <Row flex flexDirection="column">
          <Row style={{ display: 'flex' }}>
            <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight={'1.2rem'}>
              한의사 메모
            </TypographyText>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <TextArea
              id="memo"
              className={css`
                overflow-x: hidden !important;
                position: relative;
                &::-webkit-scrollbar {
                  position: absolute;
                  right: 10px;
                  background-color: white;
                  width: 0.2rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #e5e5e5;
                  margin-right: 4px !important;
                  border-radius: 1rem;
                  cursor: pointer !important;
                }
              `}
              style={{
                resize: 'none',
                padding: '1rem',
                width: '100%',
                fontSize: '1rem',
                lineHeight: '1.2rem',
                border: '1px solid #e5e5e5',
                minHeight: '6.5rem',
                height: '6.5rem',
                maxHeight: '6.5rem',
                overflowY: 'auto',
              }}
              autoComplete="off"
              placeholder="진료 및 치료관련 메모를 남겨주세요."
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </Row>
        </Row>
      </Row>
      <Row
        style={{
          position: 'relative',
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '2.75rem',
          width: '100%',
          borderTop: '1px solid #E5E5E5',
          paddingLeft: '0.75rem',
          paddingRight: '1rem',
          background: '#f9f9f9',
        }}
      >
        <Col
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            columnGap: '0.5rem',
          }}
        >
          <Col
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              columnGap: '0.75rem',
              minWidth: '5rem',
            }}
          >
            <Col
              onClick={() => {
                feedbackStore.setIsFeedback(!feedbackStore.isFeedback);
              }}
              style={{
                display: 'flex',
              }}
            >
              {feedbackStore.isFeedback ? (
                <img
                  src={`${process.env.PUBLIC_URL}/img/checked-icon.webp`}
                  style={{ width: '1.25rem', cursor: 'pointer' }}
                  alt={`checked`}
                />
              ) : (
                <img
                  src={`${process.env.PUBLIC_URL}/img/unchecked-icon.webp`}
                  style={{ width: '1.25rem', cursor: 'pointer' }}
                  alt={`unchecked`}
                />
              )}
            </Col>
            <Col
              style={{
                display: 'flex',
              }}
            >
              <TypographyText
                fontSize="0.8rem"
                letterSpacing="-0.01em"
                whiteSpace="pre"
              >{`진료완료`}</TypographyText>
            </Col>
          </Col>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <MinusButton
              onClick={() => {
                if (feedbackStore.isFeedback) {
                  if (feedbackStore.count !== 1) {
                    feedbackStore.setCount(feedbackStore.count - 1);
                  }
                }
              }}
            />
            <input
              type="number"
              step={1}
              min={1}
              className={css`
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }
                outline: none;
              `}
              style={{
                borderTop: '1px solid #c4c4c4',
                borderBottom: '1px solid #c4c4c4',
                width: '2rem',
                height: '1.2rem',
                textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: 700,
              }}
              value={feedbackStore.count}
              onChange={(e: any) => {
                const v = Number(e.target.value);
                if (_.isNumber(v) && v > 0 && _.isInteger(v)) {
                  feedbackStore.setCount(v);
                }
              }}
            />
            <PlusButton
              onClick={() => {
                if (feedbackStore.isFeedback) {
                  feedbackStore.setCount(feedbackStore.count + 1);
                }
              }}
            />
          </Col>
          <Col
            style={{
              display: 'flex',
            }}
          >
            <TypographyText
              fontSize="0.8rem"
              whiteSpace="pre-wrap"
              lineHeight="1.2em"
            >{`일 후 피드백 전송`}</TypographyText>
          </Col>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            columnGap: '0.45rem',
          }}
        >
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/kakao-talk-black-img.webp`}
              style={{ width: '1.25rem' }}
              alt="kakao-talk-icon"
            />
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onMouseOver={() => setIsOpenKakaoPreview(true)}
            onMouseOut={() => setIsOpenKakaoPreview(false)}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/tooltip-question-icon.webp`}
              style={{ width: '0.8rem' }}
              alt="question-icon"
            />
          </Col>
        </Col>
        {isOpenKakaoPreview && (
          <Row
            style={{
              position: 'absolute',
              bottom: 'calc(2.7rem + 0.6rem)',
              right: 0,
              height: '16rem',
              width: '19rem',
              zIndex: 99,
              border: '1px solid #d9d9d9',
              justifyContent: 'center',
            }}
          >
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '2.7rem',
                padding: '1rem',
                borderBottom: '1px solid #d9d9d9',
                background: '#ffffff',
              }}
            >
              <TypographyText yjTypeTypographyText="sub-title" fontWeight={700} fontSize="0.8rem">
                {`카카오톡 미리보기`}
              </TypographyText>
            </Row>
            <Row
              style={{
                display: 'flex',
                height: '13.3rem',
                flexDirection: 'column',
                paddingLeft: '1.9rem',
                paddingRight: '1.9rem',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#E4EBF2',
              }}
            >
              <Row>
                <Row
                  style={{
                    backgroundColor: '#FDE333',
                    padding: '0.4rem 0.55rem',
                  }}
                >
                  <TypographyText fontSize="0.8rem">알림톡 도착</TypographyText>
                </Row>
                <Row style={{ padding: '0.6rem', background: '#ffffff' }}>
                  <Row
                    style={{
                      display: 'flex',
                    }}
                  >
                    <TypographyText
                      yjTypeTypographyText="sub-title"
                      fontWeight={400}
                      fontSize="0.75rem"
                      lineHeight="1rem"
                      whiteSpace="pre-line"
                      wordBreak="keep-all"
                    >
                      {`안녕하세요. ${treatmentStore?.treatment?.name ?? ``}님, ${
                        // 개인 유형의 한의원인 경우 한의사 postfix 붙여줌
                        clinicStore.clinic?.businessType === 'PERSONAL'
                          ? clinicStore?.clinic?.clinicName
                            ? `${clientStore.user.username} 한의사`
                            : ``
                          : clinicStore?.clinic?.clinicName ?? ``
                      }입니다. 

한의원에 방문 하신지 ${
                        feedbackStore.count
                      }일이 지났습니다. 증상 개선 정도와 진료 만족도에 대해 답변해주시면 더 나은 진료를 위한 자료로 사용하겠습니다.`}
                    </TypographyText>
                  </Row>
                  <Divider marginRem={0.35} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                  <Row>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#f5f5f5',
                        textAlign: 'center',
                        height: '1.8rem',
                      }}
                    >
                      <TypographyText yjTypeTypographyText="sub-title" fontWeight={400} fontSize="0.75rem">
                        답변하기
                      </TypographyText>
                    </Row>
                  </Row>
                </Row>
              </Row>
            </Row>
          </Row>
        )}
      </Row>
      {isOpenPulseMeterModal && (
        <PulseMeterModal
          setIsOpenPulseMeterModal={setIsOpenPulseMeterModal}
          medicalInstitutionNumber={clinicStore.clinic?.medicalInstitutionNumber}
          patientName={treatmentStore.treatment?.patientData.patientName}
          patientGender={treatmentStore.treatment?.patientData.gender}
          patientBirth={treatmentStore.treatment?.patientData.birth}
          addCheckedSymptom={treatmentStore.addCheckedSymptom}
          deleteCheckedSymptom={treatmentStore.deleteCheckedSymptom}
          setChecklist={setChecklist}
          checklist={checklist}
        />
      )}
    </Row>
  );
};

export default observer(TreatmentSymptomRight);
