/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';

import { Sider, Menu, MenuItem, Row } from 'components/UI/atoms';
import { useStores } from 'stores/Context';

function PatientSurveySideIndex({
  surveyRef,
  surveyList,
  curCategoryIndex,
  category,
}: {
  surveyRef: React.MutableRefObject<any>;
  surveyList: string[];
  curCategoryIndex: number;
  category: Array<{
    key: string;
    label: string;
  }>;
}): ReactElement {
  const { surveyStore } = useStores();
  const prevCategoryIndex = useRef(curCategoryIndex);

  useEffect(() => {
    const sider = document.getElementById('survey-sider')?.getBoundingClientRect();
    const [div] = document.getElementsByClassName('current-item');
    if (prevCategoryIndex.current < curCategoryIndex) {
      // 스크롤을 내리는 상황
      const underBound = (sider?.y ?? 0) + (sider?.height ?? 0);
      const divBottom = div.getBoundingClientRect()?.bottom ?? 0;
      if (divBottom > underBound) {
        const menu = document.getElementById(`survey-category-${curCategoryIndex}`);
        menu?.scrollIntoView({
          block: 'end',
        });
      }
    } else {
      const upperBound = sider?.y ?? 0;
      const divTop = div.getBoundingClientRect()?.top ?? 0;
      if (divTop < upperBound) {
        const menu = document.getElementById(`survey-category-${curCategoryIndex}`);
        menu?.scrollIntoView({
          block: 'start',
        });
      }
    }
    prevCategoryIndex.current = curCategoryIndex;
  }, [curCategoryIndex]);

  return (
    <Sider id={'survey-sider'}>
      <Menu yjtypemenu="survey-category" defaultSelectedKeys={['mainSymptom']} id="survey-menu">
        {category.map((e, i) => {
          const redFlag =
            (e.key !== 'mainSymptom' &&
              _.findIndex(Array.from(surveyStore.invalidOnsetAdminSurveyCodes), (j: string) =>
                j.startsWith(e.key),
              ) > -1) ||
            _.findIndex(Array.from(surveyStore.invalidScaleAdminSurveyCodes), (j: string) =>
              j.startsWith(e.key),
            ) > -1;
          return (
            <MenuItem
              className={curCategoryIndex == i ? 'current-item' : ''}
              id={`survey-category-${i}`}
              key={e.key}
              onClick={(e) => {
                const index = surveyList.findIndex((key) => key === e.key) + 1;
                if (index > -1 || e.key === 'mainSymptom') {
                  const i = e.key === 'mainSymptom' ? 0 : index;
                  surveyRef.current[i]?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }}
            >
              <Row
                style={{
                  position: 'relative',
                  display: 'flex',
                  width: 'fit-content',
                }}
              >
                {e.label}
                {redFlag && (
                  <Row
                    style={{
                      position: 'absolute',
                      top: '0.5rem',
                      right: '-0.4rem',
                      width: '0.05rem',
                      height: '0.05rem',
                      border: '0.15rem solid #df635c',
                      background: '#df635c',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </Row>
            </MenuItem>
          );
        })}
      </Menu>
    </Sider>
  );
}

export default observer(PatientSurveySideIndex);
