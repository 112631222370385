/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { css } from '@emotion/css';
import _ from 'lodash';
import moment from 'moment';
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

import { Row, Button, TypographyText } from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import { isEnableTillStaging } from 'config';
import tr from 'repository/TreatmentRepository';
import pr from 'repository/PatientRepository';
import cr from 'repository/ClinicRepository';
import ur from 'repository/UserRepository';
import { Loading } from 'components/UI/molecules';
import { pc2prescriptionHerbMap, isValidRRN } from 'utils/util';

const PrescriptionDispensationRecord = () => {
  const { treatmentId } = useParams();
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState('view');
  const [patientName, setPatientName] = useState('');
  const [patientBirth, setPatientBirth] = useState('');
  const [patientGender, setPatientGender] = useState('');
  const [diseaseClassificationCode, setDiseaseClassificationCode] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [clinicPhone, setClinicPhone] = useState('');
  const [packCountPerDay, setPackCountPerDay] = useState(1);
  const [dailyDosageFrequency, setDailyDosageFrequency] = useState(2);
  const [totalDosageDays, setTotalDosageDays] = useState(10);
  const [dosagePerPack, setDosagePerPack] = useState(120);
  const [administrationMethod, setAdministrationMethod] = useState('후');
  const [administrationTimingHour, setAdministrationTimingHour] = useState(0);
  const [administrationTimingMinute, setAdministrationTimingMinute] = useState(30);

  const [c, setClinicData] = useState({});
  const [p, setPatientData] = useState({});
  const [t, setTreatmentData] = useState({});
  const [tpd, setTreatmentPrescriptionDocument] = useState({});
  const [u, setUserData] = useState({});

  const { prescriptionStore: ps } = useStores();

  const insuranceFlag = useMemo(() => t?.prescriptionMode !== 'GENERAL', [t]);

  const commonInputStyle = useMemo(
    () => ({
      fontSize: '15px',
      border: '1px solid #1d1c1d',
      outline: 'none',
      textIndent: '5px',
      width: '100%',
    }),
    [],
  );

  const herbTds = useCallback(
    ({ herbName, herbCode, origin }) => (
      <>
        <td
          style={{
            borderTop: '1px solid black',
            borderBottom: '1px dotted black',
            borderInline: '1px solid black',
          }}
          colSpan={5}
          height={22}
          align="center"
          valign="middle"
          bgcolor="#FFFFFF"
        >
          <span>{`${herbName}`}</span>
        </td>
        <td
          style={{
            borderTop: '1px solid black',
            borderBottom: '1px dotted black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
          colSpan={3}
          align="center"
          valign="middle"
          bgcolor="#FFFFFF"
        >
          <span>{`${herbCode}`}</span>
        </td>
        <td
          style={{
            border: '1px solid black',
          }}
          colSpan={2}
          align="center"
          valign="middle"
          bgcolor="#FFFAE6"
        >
          <span>{origin}</span>
        </td>
      </>
    ),
    [],
  );

  const prescribedRecipe = useMemo(() => {
    if (!_.isEmpty(t)) {
      const result = [];
      _.sortBy(t?.prescribedRecipe ?? [], (e) => -e.amt).forEach((r, i) => {
        const 예외허브 = pc2prescriptionHerbMap.get(r.pc);
        const hd = ps.yejinHerbForSearchByProcessingCode.get(r.pc);
        result.push(
          <tr key={`p-h-${i}`}>
            {herbTds({
              herbName: _.isEmpty(예외허브) ? hd.koreanName : 예외허브.name,
              herbCode: hd.processingCode,
              origin: _.isEmpty(예외허브) ? '' : 예외허브.origin,
            })}
          </tr>,
        );
      });
      return result;
    } else {
      return [];
    }
  }, [t]);

  const parseData = useCallback(({ cd, pd, td, ud, tpd }) => {
    const {
      patientResidentRegistrationNumber,
      diseaseClassificationCode,
      doctorName,
      clinicName,
      clinicPhone,
      packCountPerDay,
      dailyDosageFrequency,
      totalDosageDays,
      dosagePerPack,
      administrationMethod,
      administrationTimingHour,
      administrationTimingMinute,
    } = tpd ?? {};

    let convertedGender;
    switch (pd.gender) {
      case 'MALE':
        convertedGender = '남';
        break;
      case 'FEMALE':
        convertedGender = '여';
        break;
      default:
        convertedGender = '성별미상';
        break;
    }

    setPatientName(pd.patientName ?? '');
    setPatientBirth(
      patientResidentRegistrationNumber ??
        pd.residentRegistrationNumber ??
        `${pd.birth?.replace(/-/g, '')?.slice(2)}-${
          pd.gender === 'MALE' ? (pd.birth?.startsWith('1') ? 1 : 3) : pd.birth?.startsWith('1') ? 2 : 4
        }******` ??
        '',
    );
    setPatientGender(convertedGender);
    setDiseaseClassificationCode(diseaseClassificationCode ?? '');
    setDoctorName(doctorName ?? ud.username ?? '');
    setClinicName(clinicName ?? cd.clinicName ?? '');
    setClinicPhone(clinicPhone ?? cd.phone ?? '');
    setPackCountPerDay(packCountPerDay ?? 1);
    setDailyDosageFrequency(dailyDosageFrequency ?? tpd?.dailyDosageFrequency ?? 2);
    setTotalDosageDays(totalDosageDays ?? _.ceil(td?.amount / 2));
    setDosagePerPack(dosagePerPack ?? tpd?.dosagePerPack ?? 120);
    setAdministrationMethod(administrationMethod ?? '후');
    setAdministrationTimingHour(administrationTimingHour ?? 0);
    setAdministrationTimingMinute(administrationTimingMinute ?? 30);
  }, []);

  const getData = useCallback(async () => {
    try {
      await setIsLoading(true);
      const tRes = (await tr.getTreatment(treatmentId))?.getTreatment ?? {};
      const [cRes, pRes, uRes] = await Promise.all([
        cr.getClinic(tRes?.clinicID),
        pr.getPatient(tRes?.patientID),
        ur.getUser(tRes?.userID),
      ]);
      setTreatmentData(tRes);
      const prescriptionDocument = JSON.parse(tRes.prescriptionDocument ?? '{}');
      setTreatmentPrescriptionDocument(prescriptionDocument);
      setClinicData(cRes?.getClinic ?? {});
      setPatientData(pRes?.getPatient ?? {});
      setUserData(uRes?.getUser ?? {});
      if (isEnableTillStaging) {
        console.log('진료 데이터', tRes);
        console.log('한의원 데이터', cRes);
        console.log('환자 데이터', pRes);
        console.log('의사 데이터', uRes);
        console.log('처방전 데이터', prescriptionDocument);
      }
      parseData({
        cd: cRes?.getClinic ?? {},
        pd: pRes?.getPatient ?? {},
        td: tRes ?? {},
        ud: uRes?.getUser ?? {},
        tpd: prescriptionDocument,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSave = useCallback(
    async ({
      originalPrescriptionDocumentData,
      patientResidentRegistrationNumber,
      diseaseClassificationCode,
      doctorName,
      clinicName,
      clinicPhone,
      packCountPerDay,
      dailyDosageFrequency,
      totalDosageDays,
      dosagePerPack,
      administrationMethod,
      administrationTimingHour,
      administrationTimingMinute,
    }) => {
      try {
        const param = {
          id: treatmentId,
          prescriptionDocument: JSON.stringify({
            ...originalPrescriptionDocumentData,
            patientResidentRegistrationNumber,
            diseaseClassificationCode,
            doctorName,
            clinicName,
            clinicPhone,
            packCountPerDay,
            dailyDosageFrequency,
            totalDosageDays,
            dosagePerPack,
            administrationMethod,
            administrationTimingHour,
            administrationTimingMinute,
          }),
        };
        await tr.updateTreatment(param);
        await getData();
        setMode('view');
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading mode="global" />
      ) : (
        <div
          style={{
            width: '100%',
            paddingBlock: '1.1rem',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.75rem',
            alignItems: 'center',
          }}
        >
          <div
            ref={(r) => {
              if (r !== null) {
                componentRef.current = r;
              }
            }}
            id="pr-print-area"
          >
            <table
              cellSpacing={0}
              border={0}
              className={css`
                td {
                  vertical-align: middle;
                }
                span {
                  font-size: 15px;
                }
              `}
            >
              <colgroup width={70} />
              <colgroup width={48} />
              <colgroup width={56} />
              <colgroup width={19} />
              <colgroup width={56} />
              <colgroup width={103} />
              <colgroup width={94} />
              <colgroup width={51} />
              <colgroup width={33} />
              <colgroup width={179} />
              <tbody>
                <tr className="no-print">
                  <td colSpan={100}>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        paddingBottom: '0.75rem',
                      }}
                    >
                      <Row style={{ display: 'flex', columnGap: '5px' }}>
                        {mode === 'view' && (
                          <>
                            <Button
                              yjTypeButton="footer-white-button"
                              style={{
                                width: '5.2rem',
                                height: '2.5rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              onClick={() => window.close()}
                            >
                              <TypographyText style={{ whiteSpace: 'pre', fontSize: '1rem' }}>
                                닫기
                              </TypographyText>
                            </Button>
                            <Button
                              yjTypeButton="footer-white-button"
                              style={{
                                width: '5.2rem',
                                height: '2.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={() => setMode('edit')}
                            >
                              <TypographyText style={{ whiteSpace: 'pre', fontSize: '1rem' }}>
                                수정
                              </TypographyText>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  style={{
                                    width: '5.2rem',
                                    height: '2.5rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    whiteSpace: 'pre',
                                    background: '#346AFF',
                                  }}
                                >
                                  <TypographyText
                                    color="#ffffff"
                                    style={{ whiteSpace: 'pre', fontSize: '1rem' }}
                                  >
                                    인쇄
                                  </TypographyText>
                                </Button>
                              )}
                              content={() => componentRef?.current}
                            />
                          </>
                        )}
                        {mode === 'edit' && (
                          <>
                            <Button
                              yjTypeButton="footer-white-button"
                              style={{
                                width: '5.2rem',
                                height: '2.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={() => setMode('view')}
                            >
                              <TypographyText style={{ whiteSpace: 'pre', fontSize: '1rem' }}>
                                취소
                              </TypographyText>
                            </Button>
                            <Button
                              style={{
                                width: '5.2rem',
                                height: '2.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                whiteSpace: 'pre',
                                background: '#346AFF',
                              }}
                              onClick={() =>
                                handleSave({
                                  originalPrescriptionDocumentData: tpd,
                                  diseaseClassificationCode,
                                  doctorName,
                                  clinicName,
                                  clinicPhone,
                                  packCountPerDay,
                                  dailyDosageFrequency,
                                  totalDosageDays,
                                  dosagePerPack,
                                  administrationMethod,
                                  administrationTimingHour,
                                  administrationTimingMinute,
                                })
                              }
                            >
                              <TypographyText color="#ffffff" style={{ whiteSpace: 'pre', fontSize: '1rem' }}>
                                저장
                              </TypographyText>
                            </Button>
                          </>
                        )}
                      </Row>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={10}
                    height={46}
                    align="center"
                    valign="middle"
                  >
                    <b>
                      <font face="Source Han Sans CN" size={4} color="black">
                        첩약 처방·조제내역 안내
                      </font>
                    </b>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={4}
                    height={88}
                    align="center"
                    valign="middle"
                  >
                    <span>환자</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={2}
                    rowSpan={2}
                    height={52}
                    align="center"
                    valign="middle"
                  >
                    <span>성 명</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      textIndent: '5px',
                    }}
                    colSpan={3}
                    rowSpan={2}
                    align="left"
                    valign="middle"
                  >
                    <span>{patientName}</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={4}
                    align="center"
                    valign="middle"
                  >
                    <span>처방기관</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={2}
                    align="center"
                    valign="middle"
                  >
                    <span>기관명칭</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      textIndent: '5px',
                    }}
                    align="justify"
                    valign="middle"
                  >
                    {mode === 'view' ? (
                      <span>{clinicName}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={clinicName}
                          onChange={(e) => setClinicName(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={2}
                    rowSpan={2}
                    align="center"
                    valign="middle"
                  >
                    <span>전화번호</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      textIndent: '5px',
                    }}
                    rowSpan={2}
                    align="justify"
                    valign="left"
                  >
                    {mode === 'view' ? (
                      <span>
                        {clinicPhone
                          ? isValidPhoneNumber('+82' + clinicPhone)
                            ? formatPhoneNumber('+82' + clinicPhone)
                            : clinicPhone
                          : ''}
                      </span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={clinicPhone}
                          onChange={(e) => setClinicPhone(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={2}
                    rowSpan={2}
                    align="center"
                    valign="middle"
                  >
                    <span>생년월일/성별</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      textIndent: '5px',
                    }}
                    colSpan={3}
                    rowSpan={2}
                    align="left"
                    valign="middle"
                  >
                    <span>{`${isValidRRN(patientBirth) ? `${patientBirth.slice(0, -6)}******` : ''}`}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={2}
                    align="center"
                    valign="middle"
                  >
                    <span>처방의 성명</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      textIndent: '5px',
                    }}
                    align="left"
                    valign="middle"
                  >
                    {mode === 'view' ? (
                      <span>{`${doctorName}`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={doctorName}
                          onChange={(e) => setDoctorName(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderBottom: '3px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={3}
                    height={34}
                    align="center"
                    valign="middle"
                  >
                    <span>발급 연월일(처방일) </span>
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderBottom: '3px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                      textIndent: '5px',
                    }}
                    colSpan={3}
                    align="left"
                    valign="middle"
                  >
                    <Row style={{ display: 'flex' }}>
                      <span>{`${moment().format('YYYY년 MM월 DD일')}`}</span>
                    </Row>
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderBottom: '3px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                    valign="middle"
                  >
                    <span>질병분류기호</span>
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderBottom: '3px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                      textIndent: '5px',
                    }}
                    colSpan={3}
                    align="left"
                    valign="middle"
                  >
                    {mode === 'view' ? (
                      <span>{diseaseClassificationCode}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={diseaseClassificationCode}
                          onChange={(e) => setDiseaseClassificationCode(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderLeft: '3px solid black',
                      borderRight: '1px solid black',
                    }}
                    rowSpan={4}
                    colSpan={3}
                    height={88}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>조제탕전기관</span>
                  </td>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={3}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>기관명</span>
                  </td>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>
                      <br />
                    </span>
                  </td>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={2}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>전화번호</span>
                  </td>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '3px solid black',
                    }}
                    align="justify"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  ></td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={3}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>조제자 성명</span>
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '3px solid black',
                    }}
                    colSpan={4}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>
                      <br />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={3}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>조제일자</span>
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '3px solid black',
                    }}
                    colSpan={4}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>
                      <br />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderBottom: '3px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={3}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>소재지</span>
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderBottom: '3px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '3px solid black',
                    }}
                    colSpan={4}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>
                      <br />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={4}
                    height={23}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    <b>
                      <span>1회 복용 팩수</span>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={2}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    <b>
                      <span>1일 복용 팩수</span>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={3}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    <b>
                      <span>총 투약일수</span>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    <span>용 법</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={4}
                    rowSpan={2}
                    height={44}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    {mode === 'view' ? (
                      <span>{`${packCountPerDay}`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '50px',
                          }}
                          value={packCountPerDay}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => setPackCountPerDay(parseInt(e.target.value))}
                        />
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={2}
                    rowSpan={2}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    {mode === 'view' ? (
                      <span>{`${dailyDosageFrequency}`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '50px',
                          }}
                          value={dailyDosageFrequency}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => setDailyDosageFrequency(parseInt(e.target.value))}
                        />
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={3}
                    rowSpan={2}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    {mode === 'view' || insuranceFlag ? (
                      <span>{`${totalDosageDays}`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '50px',
                          }}
                          value={totalDosageDays}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => setTotalDosageDays(parseInt(e.target.value))}
                        />
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    {mode === 'view' ? (
                      <span>{`매 식${administrationMethod}`}</span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textIndent: 0,
                          justifyContent: 'center',
                        }}
                      >
                        <span>{`매 식`}</span>
                        <select
                          style={{
                            ...commonInputStyle,
                            width: '40px',
                          }}
                          value={administrationMethod}
                          onChange={(e) => setAdministrationMethod(e.target.value)}
                        >
                          <option value={'전'}>전</option>
                          <option value={'간'}>간</option>
                          <option value={'후'}>후</option>
                        </select>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    {mode === 'view' ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textIndent: 0,
                          columnGap: '4px',
                        }}
                      >
                        {administrationTimingHour > 0 && <span>{`${administrationTimingHour}시`}</span>}
                        <span>{`${
                          administrationTimingMinute > 0 ? `${administrationTimingMinute}분` : ''
                        } 복용`}</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textIndent: 0,
                          columnGap: '4px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            textIndent: 0,
                            justifyContent: 'center',
                          }}
                        >
                          <input
                            style={{
                              ...commonInputStyle,
                              width: '40px',
                            }}
                            value={administrationTimingHour}
                            type="number"
                            min={0}
                            step={1}
                            onChange={(e) => setAdministrationTimingHour(parseInt(e.target.value))}
                          />
                          <span>{`시`}</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            textIndent: 0,
                            justifyContent: 'center',
                          }}
                        >
                          <input
                            style={{
                              ...commonInputStyle,
                              width: '40px',
                            }}
                            value={administrationTimingMinute}
                            type="number"
                            min={1}
                            max={59}
                            step={1}
                            onChange={(e) => setAdministrationTimingMinute(parseInt(e.target.value))}
                          />
                          <span>{`분`}</span>
                        </div>
                        <span>{`복용`}</span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={8}
                    height={22}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    <b>
                      <span>한약재</span>
                    </b>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={2}
                    rowSpan={2}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <b>
                      <span>원산지</span>
                    </b>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={5}
                    height={22}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    <span>명칭</span>
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    colSpan={3}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFFFF"
                  >
                    <span>코드</span>
                  </td>
                </tr>
                {prescribedRecipe}
                <tr>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '3px solid black',
                      borderLeft: '3px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={2}
                    height={32}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span>보관방법</span>
                  </td>
                  <td
                    style={{
                      borderTop: '3px solid black',
                      borderBottom: '3px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '3px solid black',
                    }}
                    colSpan={8}
                    align="center"
                    valign="middle"
                    bgcolor="#FFFAE6"
                  >
                    <span face="돋움체" color="#FF0000">
                      <br />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(PrescriptionDispensationRecord);
