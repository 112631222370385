/**
 * 원하는 텍스트 굵게 표시
 */
export const BoldText = ({ text, boldText }) => {
  const parts = text.split(new RegExp(`(${boldText})`, 'gi'));

  return parts.map((part, i) =>
    part.toLowerCase() === boldText.toLowerCase() ? <b key={i}>{part}</b> : part,
  );
};
