export const currentEnv = process.env.REACT_APP_ENV ?? 'local';
export const url = {
  local: {
    feedbackLink: `https://develop.doja88omixdfd.amplifyapp.com/`,
  },
  develop: {
    feedbackLink: `https://develop.doja88omixdfd.amplifyapp.com/`,
  },
  staging: {
    feedbackLink: `https://staging.doja88omixdfd.amplifyapp.com/`,
  },
  production: {
    feedbackLink: `https://prod.doja88omixdfd.amplifyapp.com/`,
  },
}[currentEnv];

export const isEnableTillStaging = ['local', 'develop', 'staging'].includes(currentEnv);
