/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form } from 'antd';
import message from 'antd-message';
import moment from 'moment';
import { observer } from 'mobx-react';

import { codeMap, insuranceSurveyCodes } from 'utils/code';
import { useStores } from 'stores/Context';
import { Loading } from 'components/UI/molecules';
import { TypographyText, Row, Col, Divider, Button, TextArea } from 'components/UI/atoms';
import tr from 'repository/TreatmentRepository';
import _ from 'lodash';

import SurveyDefaultForm from './SurveyDefaultForm';
import { API, Auth } from 'aws-amplify';

const { Item: FormItem } = Form;

function PatientSurvey({
  surveyRef,
  initSurveys,
  initialValue,
  form,
  mode,
  treatmentMode,
  setTreatmentMode,
  category,
}: {
  surveyRef: React.MutableRefObject<any>;
  initSurveys: string[];
  initialValue: any;
  form: any;
  mode?: 'create' | 'update';
  treatmentMode: string;
  setTreatmentMode: React.Dispatch<React.SetStateAction<string>>;
  category: Array<{
    key: string;
    label: string;
  }>;
}): ReactElement {
  const { clientStore, patientStore, surveyStore, clinicStore } = useStores();
  const navigate = useNavigate();

  const recentChangedField = useRef();
  const location = useLocation();
  const { id, surveyId, treatmentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [isShowSurveyInsuranceMode, setIsShowSurveyInsuranceMode] = useState<boolean>(false);
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const scaleOnsetKeys = useMemo(() => {
    return Array.from(
      new Map(
        [...codeMap].filter(([k, v]) => ['Scale', 'Onset', 'OnsetAndScale'].includes(v.selectType)),
      ).keys(),
    );
  }, []);

  useEffect(() => {
    setSurveys([
      ..._.map(initSurveys, (k, i) => {
        const v = codeMap.get(k);
        return (
          <SurveyDefaultForm
            treatmentMode={treatmentMode}
            key={`survey-${i}`}
            scaleOnsetKeys={scaleOnsetKeys}
            recentChangedField={recentChangedField}
            surveyRef={surveyRef}
            i={i}
            form={form}
            k={k}
            v={v}
            selectType={v!.selectType}
          />
        );
      }),
    ]);
  }, [initSurveys, treatmentMode]);

  useEffect(() => {
    if (!_.isEmpty(initialValue)) {
      form.setFieldsValue({
        ...initialValue,
      });
      setTimeout(() => {
        setIsLoading(false);
        form.setFieldsValue({
          ...initialValue,
        });
      }, 0);
    }
  }, [initialValue]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (mode === 'update' && treatmentId) {
      // 진료실에서 새탭으로 열어서 수정하는 경우
      const handleUnload = () => {
        window.opener.postMessage('childClosed', '*');
      };

      window.addEventListener('unload', handleUnload);

      return () => {
        window.removeEventListener('unload', handleUnload);
      };
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(clinicStore.clinic)) {
      const clinicConfig = JSON.parse(clinicStore.clinic?.config ?? '{}');
      if (clinicConfig.insuranceBusinessJoinFlag) {
        setIsShowSurveyInsuranceMode(true);
      }
    }
  }, [clinicStore.clinic]);

  const handleOnFinish = useCallback(async () => {
    setIsGlobalLoading(true);
    const d = form.getFieldsValue() ?? {};
    const respondedScaleOnsetSurveys = _.filter(scaleOnsetKeys, (k: string) => _.includes(_.keys(d), k)); // Scale, Onset, OnsetAndScale
    let validationFlag = false;
    _.forEach(respondedScaleOnsetSurveys, (k: string) => {
      const { selectType } = codeMap.get(k)!;
      // selectType: Scale, Onset, OnsetAndScale
      if (selectType === 'Scale') {
        if (d[k]?.scale === undefined) {
          validationFlag = true;
        }
      } else if (selectType === 'Onset') {
        if (d[k]?.onset === undefined) {
          validationFlag = true;
        }
      } else {
        // OnsetAndScale
        if (d[k]?.scale === undefined || d[k]?.onset === undefined) {
          validationFlag = true;
        }
      }
    });
    if (validationFlag) {
      // Scale, Onset, OnsetAndScale 중 하나라도 응답하지 않은 경우
      // console.log(Array.from(surveyStore.invalidOnsetAdminSurveyCodes));
      // console.log(Array.from(surveyStore.invalidScaleAdminSurveyCodes));

      // codeMap에 세팅된 설문 순서대로 invalidOnsetAdminSurveyCodes, invalidScaleAdminSurveyCodes의 최상위 설문코드를 가져옴
      const invalidSurveyCodes = _.uniq(
        Array.from(new Map([...codeMap]).keys()).filter((e: string) =>
          [
            ..._.map(Array.from(surveyStore.invalidOnsetAdminSurveyCodes), (e: string) => e.slice(0, 1)),
            ..._.map(Array.from(surveyStore.invalidScaleAdminSurveyCodes), (e: string) => e.slice(0, 1)),
          ].includes(e),
        ),
      );
      const invalidSurveyLabels = _.join(
        _.map(invalidSurveyCodes, (e: string) => `<${codeMap.get(e)?.label}>`),
        ', ',
      );
      message.error(`${invalidSurveyLabels} 설문을 확인해주세요.`, 2500);
      const index = initSurveys.findIndex((key) => invalidSurveyCodes.includes(key)) + 1;
      surveyRef.current?.[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setIsGlobalLoading(false);
      return false;
    }
    const result: any[] = [];
    let mainSymptom = '';
    let additionalSymptoms = '';
    let mainSymptomCategory = null;

    if (form.getFieldValue('mainSymptomCategory')) {
      mainSymptomCategory = form.getFieldValue('mainSymptomCategory');
      delete d.mainSymptomCategory;
    }

    if (d.mainSymptom) {
      mainSymptom = d.mainSymptom;
      delete d.mainSymptom;
    }
    if (d.additionalSymptoms) {
      additionalSymptoms = d.additionalSymptoms;
      delete d.additionalSymptoms;
    }

    _.forEach(_.keys(d), (key) => {
      let k = key;
      if (key.includes('Desc')) {
        k = key.replace('Desc', '');
      }
      // 값이 없는 key 삭제
      if (d[k] === undefined) {
        delete d[k];
      } else {
        const categoryCode = k.slice(0, 1);
        const parentsCode = k.slice(0, -1);
        if (insuranceSurveyCodes.has(categoryCode) && treatmentMode !== categoryCode) {
          delete d[k];
        } else if (parentsCode.length > 1) {
          if ([undefined, '0', 0].includes(d[parentsCode])) {
            // 부모 설문 응답 값이 없는 경우
            delete d[k];
          } else {
            // 부모 설문의 응답이 현재 설문과 관계 없는 경우
            const pv = d[parentsCode];
            const targetArr: any = _.filter(codeMap.get(parentsCode)?.answers, (e: any) =>
              typeof e?.next === 'string' ? e?.next === k : e?.next?.includes(k),
            );
            if (!_.isEmpty(targetArr)) {
              let deleteFlag = true;
              for (const target of targetArr) {
                const targetValue = target.value;
                if (_.isArray(pv)) {
                  if (pv.includes(targetValue)) {
                    deleteFlag = false;
                    break;
                  }
                } else {
                  if (pv == targetValue) {
                    deleteFlag = false;
                    break;
                  }
                }
              }
              if (deleteFlag) {
                delete d[k];
              }
            }
          }
        }
      }
    });

    _.forEach(_.keys(d), (k) => {
      const format: any = { code: k, value: d[k] };
      if (_.isArray(d[k]) && d[k].includes(-1)) {
        // 주관식 응답값 -1 을 가지고 있다면
        format.desc = d[`${k}Desc`] ?? '';
      }
      if (codeMap.get(k)?.selectType === 'Date' && moment(d[k]).isValid()) {
        result.push(format);
      } else if (typeof d[k] === 'string') {
        const parsedValue = parseFloat(d[k]);
        if (!isNaN(parsedValue)) {
          result.push({ ...format, value: parsedValue });
        } else {
          result.push(format);
        }
      } else {
        result.push(format);
      }
    });
    if (mode === 'update') {
      const apiName = 'yejinadminrestapi';
      const path = '/survey/direct/' + surveyId!;
      const option = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        response: true,
        body: {
          clinicID: clientStore.metaData?.userRole.clinic?.id!,
          patientID: id,
          mainSymptom,
          insuranceType: treatmentMode,
          additionalSymptoms: additionalSymptoms,
          symptoms: JSON.stringify(result),
          surveyResult: JSON.stringify({
            mainSymptom,
            survey: result,
          }),
          feedbacks: JSON.stringify(result.filter((survey) => survey?.value?.scale >= 0)),
          mainSymptomCategory,
        },
      };

      if (treatmentId) {
        // treatmentId가 있는 경우는 진료실에서 새탭으로 열어서 수정하는 경우, 설문을 수정했으면 변증 기록을 초기화함
        try {
          await tr.deleteReport(treatmentId);
        } catch (error) {
          // field validation error가 발생하는데 무시
        }
      }

      await API.put(apiName, path, option)
        .then(() => {
          message.success('증상 수정이 완료되었습니다.', 2500);
          if (treatmentId) {
            // treatmentId가 있는 경우는 진료실에서 새탭으로 열어서 수정하는 경우
            window.close();
          } else {
            navigate(-1);
          }
        })
        .catch((error: any) => {
          message.error(`알 수 없는 에러 발생`, 2500);
          setIsGlobalLoading(false);
          console.log(error);
        });
    } else {
      const apiName = 'yejinadminrestapi';
      const path = '/survey/direct';
      const option = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        response: true,
        body: {
          clinicID: clientStore.metaData?.userRole.clinic?.id!,
          patientID: id,
          symptoms: JSON.stringify(result),
          insuranceType: treatmentMode,
          surveyResult: JSON.stringify({
            mainSymptom,
            survey: result,
          }),
          mainSymptom,
          mainSymptomCategory,
        },
      };

      await API.post(apiName, path, option)
        .then(() => {
          patientStore.getPatient(id).then(() => {
            message.success('증상 등록이 완료되었습니다.', 2500);
            navigate(-1);
          });
        })
        .catch((error: any) => {
          message.error(`알 수 없는 에러 발생`, 2500);
          setIsGlobalLoading(false);
          console.log(error);
        });
    }
    setIsGlobalLoading(false);
  }, [initSurveys, treatmentMode]);

  return (
    <>
      {!isLoading ? (
        <Form form={form} onFinish={handleOnFinish}>
          {isGlobalLoading && <Loading mode="global" />}
          <Row yjTypeRow="survey-content" style={{ paddingRight: '2.4rem' }}>
            <div
              className="survey-section"
              ref={(r) => {
                surveyRef.current[0] = r;
              }}
            >
              <Divider marginRem={1.5} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
              <Col span={24}>
                <TypographyText yjTypeTypographyText="survey-main-question">
                  {`가장 불편하신 증상이 무엇이신가요?`}
                </TypographyText>
              </Col>
              <Row
                style={{
                  width: '100%',
                  maxWidth: '65.5rem',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '0.5rem',
                  marginBlock: '1rem',
                }}
              >
                {category.slice(insuranceSurveyCodes.has(treatmentMode) ? 2 : 1).map((c) => {
                  const isChecked = form.getFieldValue('mainSymptomCategory') === c.key;
                  return (
                    <Col
                      key={`mainSymptom-${c.key}`}
                      style={{
                        flex: '1 1 0 ',
                        minWidth: '6.1rem',
                        maxWidth: '6.1rem',
                        height: '3rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: isChecked ? '1px solid #C2D5EB' : '1px solid #e5e5e5',
                        background: isChecked ? '#D4E8FF' : '#ffffff',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        form.setFieldsValue({
                          mainSymptomCategory: c.key,
                        });
                        setTreatmentMode('GENERAL');
                        forceUpdate();
                      }}
                    >
                      <TypographyText
                        fontSize="1rem"
                        lineHeight="1.2em"
                        letterSpacing="-0.01em"
                        whiteSpace="pre"
                      >
                        {c.label}
                      </TypographyText>
                    </Col>
                  );
                })}
              </Row>
              {isShowSurveyInsuranceMode && (
                <Row
                  style={{
                    width: '100%',
                    maxWidth: '65.5rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                    marginTop: '-0.5rem',
                    marginBottom: '1rem',
                  }}
                >
                  {Array.from(insuranceSurveyCodes)
                    .map((e) => ({
                      key: e,
                      label: codeMap.get(e)?.label,
                    }))
                    .map((c) => {
                      const isChecked = form.getFieldValue('mainSymptomCategory') === c.key;
                      return (
                        <Col
                          key={`mainSymptom-${c.key}`}
                          style={{
                            flex: '1 1 0',
                            minWidth: '7rem',
                            padding: '0.5rem 1rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: isChecked ? '1px solid #C2D5EB' : '1px solid #e5e5e5',
                            background: isChecked ? '#D4E8FF' : '#ffffff',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            form.setFieldsValue({
                              mainSymptomCategory: c.key,
                            });
                            setTreatmentMode(c.key);
                            forceUpdate();
                          }}
                        >
                          <Row
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              rowGap: '0.25rem',
                            }}
                          >
                            <Col
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #DF635C',
                                width: '1.85rem',
                                height: '1rem',
                                borderRadius: '100px',
                              }}
                            >
                              <TypographyText
                                color="#DF635C"
                                fontSize="0.6rem"
                                lineHeight="1em"
                                fontWeight={700}
                              >
                                {'급여'}
                              </TypographyText>
                            </Col>
                            <TypographyText
                              fontSize="1rem"
                              lineHeight="1.2em"
                              letterSpacing="-0.01em"
                              whiteSpace="pre"
                            >
                              {c.label}
                            </TypographyText>
                          </Row>
                        </Col>
                      );
                    })}
                </Row>
              )}
              <Col span={24}>
                <FormItem noStyle name={'mainSymptom'}>
                  <TextArea yjTypeTextArea="form" placeholder={'현재 가장 불편하신 증상을 입력해주세요.'} />
                </FormItem>
              </Col>
              <FormItem noStyle name={'additionalSymptoms'} />
            </div>
            {surveys}
          </Row>
          <Row yjTypeRow="bottom" justify="end">
            <Col yjTypeCol="footer-prev-button-col">
              <Button
                style={{
                  borderRadius: '3px',
                }}
                onClick={() => {
                  if (mode === 'update' && treatmentId) {
                    // 진료실에서 새탭으로 열어서 수정하는 경우
                    window.close();
                  } else {
                    navigate(-1);
                  }
                }}
                yjTypeButton="footer-white-button"
              >
                취소
              </Button>
            </Col>
            <Col yjTypeCol="footer-next-button-col">
              <FormItem>
                <button
                  // antd submit button 버그로 임시로 직접 구현 todo
                  style={{
                    width: '100%',
                    height: '3.2rem',
                    border: 'none',
                    background: '#346AFF',
                    borderRadius: '3px',
                    color: '#ffffff',
                    padding: '1rem 2rem',
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    letterSpacing: '0.005em',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                  type="submit"
                >
                  <Col yjTypeCol="center">저장하기</Col>
                </button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      ) : (
        <></>
      )}
    </>
  );
}

export default observer(PatientSurvey);
