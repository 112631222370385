import React, { MouseEventHandler, useState, useMemo } from 'react';
import { css } from '@emotion/css';

import { Row, Col, TypographyText, Button, Spinner } from 'components/UI/atoms';
import { getRootFontSize } from 'utils/util';

// default 모달, content는 string이며 단순하게 예, 아니오 선택 용도로만 사용하는 대화상자
const Modal = ({
  isOpen = true,
  contentMsg = '',
  additionalContent = <></>,
  contentHeight = 5,
  okButtonWidthRatio = 50,
  okMsg = '예',
  okBtnLoading = false,
  okBtnDisabled = false,
  confirmFlag = false,
  confirmMsg,
  cancelMsg = '아니오',
  okFunction = () => {},
  cancelFunction = () => {},
}: {
  isOpen?: boolean;
  contentMsg: string;
  contentHeight?: number;
  additionalContent?: React.ReactElement;
  okButtonWidthRatio?: number;
  okBtnLoading?: boolean;
  okBtnDisabled?: boolean;
  okMsg?: string;
  confirmFlag?: boolean; // 별도 체크박스 확인이 필요한 모달인 경우
  confirmMsg?: string;
  cancelMsg?: string;
  okFunction?: MouseEventHandler;
  cancelFunction?: MouseEventHandler;
}) => {
  const [confirm, setConfirm] = useState(false);
  const disableFlag = useMemo(() => {
    return (confirmFlag && !confirm) || okBtnDisabled;
  }, [confirmFlag, confirm, okBtnDisabled]);
  return (
    <>
      {isOpen && (
        <Row
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            background: 'rgba(28,37,44, 0.5)',
          }}
        >
          <Row
            style={{
              width: '24rem',
              height: `${contentHeight + 4}rem`,
              position: 'absolute',
              background: '#fff',
            }}
          >
            <Row
              style={{
                padding: '1.3rem 1.2rem',
                height: `${contentHeight}rem`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <TypographyText
                yjTypeTypographyText="symptom-table-body"
                letterSpacing="-0.02em"
                lineHeight="1.4rem"
                fontWeight={confirmFlag ? 700 : 400}
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {contentMsg}
              </TypographyText>
              {confirmFlag && (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.6rem',
                    columnGap: '0.5rem',
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/${confirm ? 'checked-blue' : 'not-checked'}.webp`}
                    style={{ width: '1.2rem', cursor: 'pointer' }}
                    alt={`checked`}
                    onClick={() => setConfirm(!confirm)}
                  />
                  <TypographyText fontSize="0.9rem" lineHeight="1.2em">
                    {confirmMsg}
                  </TypographyText>
                </Row>
              )}
              {additionalContent}
            </Row>
            <Row style={{ display: 'flex', textAlign: 'center' }}>
              {okButtonWidthRatio === 100 ? (
                <></>
              ) : (
                <Col
                  style={{
                    width: `${100 - okButtonWidthRatio}%`,
                    height: '4rem',
                    borderTop: '1px solid #e5e5e5',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  onClick={cancelFunction}
                >
                  <TypographyText yjTypeTypographyText="symptom-table-body">{cancelMsg}</TypographyText>
                </Col>
              )}
              {okButtonWidthRatio === 0 ? (
                <></>
              ) : (
                <Col
                  style={{
                    width: `${okButtonWidthRatio}%`,
                    height: '4rem',
                    backgroundColor: disableFlag ? '#c4c4c4' : '#346AFF',
                    border: disableFlag ? '1px solid #c4c4c4' : '1px solid #346AFF',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  onClick={disableFlag || okBtnLoading ? () => {} : okFunction}
                >
                  {okBtnLoading ? (
                    <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Spinner spinnerDivWidth="w-15" />
                    </Row>
                  ) : (
                    <TypographyText yjTypeTypographyText="symptom-table-body" style={{ color: '#ffffff' }}>
                      {okMsg}
                    </TypographyText>
                  )}
                </Col>
              )}
            </Row>
          </Row>
        </Row>
      )}
    </>
  );
};

type TbuttonColor = 'blue' | 'white';

// default 모달에서 버튼이 3개가 된 형태
const ModalWith3Button = ({
  isOpen = true,
  contentMsg = '',
  additionalContent = <></>,
  contentHeight = 5,
  button1WidthRatio = 33,
  button2WidthRatio = 33,
  button3WidthRatio = 33,
  button1Color,
  button2Color,
  button3Color,
  button1Msg,
  button2Msg,
  button3Msg,
  button1Function = () => {},
  button2Function = () => {},
  button3Function = () => {},
}: {
  isOpen?: boolean;
  contentMsg: string;
  contentHeight?: number;
  additionalContent?: React.ReactElement;
  button1WidthRatio: number;
  button1Msg: string;
  button1Function: MouseEventHandler;
  button2WidthRatio: number;
  button2Msg: string;
  button2Function: MouseEventHandler;
  button3WidthRatio: number;
  button3Msg: string;
  button3Function: MouseEventHandler;
  button1Color: TbuttonColor;
  button2Color: TbuttonColor;
  button3Color: TbuttonColor;
}) => {
  const totalButtonWidthRatio = button1WidthRatio + button2WidthRatio + button3WidthRatio;
  const buttonArr = [
    {
      width: button1WidthRatio,
      msg: button1Msg,
      color: button1Color,
      func: button1Function,
    },
    {
      width: button2WidthRatio,
      msg: button2Msg,
      color: button2Color,
      func: button2Function,
    },
    {
      width: button3WidthRatio,
      msg: button3Msg,
      color: button3Color,
      func: button3Function,
    },
  ];
  return (
    <>
      {isOpen && (
        <Row
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            background: 'rgba(28,37,44, 0.5)',
          }}
        >
          <Row
            style={{
              width: '24rem',
              height: `${contentHeight + 4}rem`,
              position: 'absolute',
              background: '#fff',
            }}
          >
            <Row
              style={{
                padding: '1.3rem 1.2rem',
                height: `${contentHeight}rem`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <TypographyText
                yjTypeTypographyText="symptom-table-body"
                letterSpacing="-0.02em"
                lineHeight="1.4rem"
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {contentMsg}
              </TypographyText>
              {additionalContent}
            </Row>
            <Row style={{ display: 'flex', textAlign: 'center' }}>
              {buttonArr.map((button, index) => {
                let borderTopStyle = '1px solid #346AFF';
                let backgroundColor = '#ffffff';
                let msgColor = '#1C252C';
                if (button.color === 'white') {
                  borderTopStyle = '1px solid #e5e5e5';
                } else if (button.color === 'blue') {
                  msgColor = '#ffffff';
                  backgroundColor = '#346AFF';
                }
                return (
                  <Col
                    key={`button-${index}`}
                    style={{
                      width: `calc(${(button.width / totalButtonWidthRatio) * 100}%)`,
                      height: '4rem',
                      borderTop: borderTopStyle,
                      borderRight: index === 2 ? '1px solid #346AFF' : '1px solid #e5e5e5',
                      background: backgroundColor,
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                    onClick={button.func}
                  >
                    <TypographyText style={{ color: msgColor }} yjTypeTypographyText="symptom-table-body">
                      {button.msg}
                    </TypographyText>
                  </Col>
                );
              })}
            </Row>
          </Row>
        </Row>
      )}
    </>
  );
};

// 타이틀을 표시할 수 있고, 컨텐츠를 자유롭게 작성가능하며, 예, 아니오가 나란히 나열된 형태의 모달,
const ModalType2 = ({
  isOpen = true,
  title,
  content,
  okMsg,
  okBtnLoading = false,
  okBtnDisabled = false,
  cancelMsg = '닫기',
  okFunction = () => {},
  cancelFunction = () => {},
  contentHeight,
  contentScrollHiddenFlag = false,
  width = 24,
  contentNonePaddingFlag = false,
  hiddenFlag = false,
}: {
  isOpen: boolean;
  content: JSX.Element;
  title?: string;
  okMsg?: string;
  okBtnLoading?: boolean;
  okBtnDisabled?: boolean;
  okFunction?: MouseEventHandler;
  cancelMsg?: string;
  contentHeight?: string;
  cancelFunction?: MouseEventHandler;
  contentScrollHiddenFlag?: boolean;
  width?: number | string;
  contentNonePaddingFlag?: boolean;
  hiddenFlag?: boolean;
}) => {
  let contentMaxHeight = 40 - 3.2; // 높이상한 800 - 닫기 버튼 64
  if (okMsg) contentMaxHeight -= 3.6; // ok 버튼 72
  if (title) contentMaxHeight -= 4.8; // title padding60 + 글자크기 36

  return (
    <>
      {isOpen ? (
        <Row
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: hiddenFlag ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            background: 'rgba(28,37,44, 0.5)',
          }}
        >
          <Row
            style={{
              width: typeof width === 'string' ? width : `${width}rem`,
              position: 'absolute',
              background: '#fff',
              overflow: 'auto',
            }}
          >
            {title && (
              <Row
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 999,
                  background: '#ffffff',
                  padding: '2rem 1rem 1rem 2rem',
                  borderBottom: '1px solid #e5e5e5',
                }}
              >
                <TypographyText
                  fontSize="1.8rem"
                  letterSpacing="-0.02rem"
                  lineHeight="1.8rem"
                  fontWeight={700}
                >
                  {title}
                </TypographyText>
              </Row>
            )}
            <Row
              style={{
                position: 'relative',
                padding: contentNonePaddingFlag ? 0 : '1rem 2rem 1rem 2rem',
                height: contentHeight ? contentHeight : `calc(100%${okMsg ? ` - 3.6rem` : ``})`,
                maxHeight: `${contentMaxHeight}rem`,
                overflowY: contentScrollHiddenFlag ? 'hidden' : 'scroll',
              }}
              className={css`
                overflow-x: hidden !important;
                &::-webkit-scrollbar {
                  background-color: white;
                  width: 0.25rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #c1c1c1;
                  border-radius: 1rem;
                }
              `}
            >
              {content}
            </Row>
            <Row
              style={{
                position: 'sticky',
                bottom: 0,
                width: '100%',
                display: 'flex',
                flexDirection: okMsg ? 'row' : 'column',
                borderTop: okMsg ? '1px solid #f2f2f2' : 'none',
                columnGap: '0.4rem',
                textAlign: 'center',
                padding: okMsg ? '1rem 2rem' : '0',
              }}
            >
              {okMsg ? (
                <>
                  <Button
                    style={{
                      flex: '1 1 0',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      border: '1px solid #afafaf',
                      color: '#1C252C',
                      height: '3.6rem',
                      padding: '1rem 2rem',
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                    }}
                    onClick={cancelFunction}
                  >
                    <TypographyText
                      style={{
                        fontSize: '1.2rem',
                        lineHeight: '1em',
                        fontWeight: 700,
                      }}
                    >
                      {cancelMsg}
                    </TypographyText>
                  </Button>
                  <Button
                    style={{
                      flex: '1 1 0',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '1rem 2rem',
                      backgroundColor: okBtnDisabled ? '#c4c4c4' : '#346AFF',
                      border: okBtnDisabled ? '1px solid #c4c4c4' : '1px solid #346AFF',
                      borderRadius: '3px',
                      color: '#ffffff',
                      height: '3.6rem',
                      cursor: okBtnDisabled ? 'cursor' : 'pointer',
                      // width: 'calc(100% - 4rem)',
                    }}
                    onClick={okBtnDisabled || okBtnLoading ? () => {} : okFunction}
                  >
                    {okBtnLoading ? (
                      <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Spinner spinnerDivWidth="w-15" />
                      </Row>
                    ) : (
                      <Row
                        style={{
                          fontSize: '1.2rem',
                          lineHeight: '1em',
                          fontWeight: 700,
                          cursor: okBtnDisabled ? 'cursor' : 'pointer',
                        }}
                      >
                        {okMsg}
                      </Row>
                    )}
                  </Button>
                </>
              ) : (
                <Row
                  style={{
                    cursor: 'pointer',
                    borderTop: okMsg ? 'none' : '1px solid #e5e5e5',
                    zIndex: 999,
                    backgroundColor: '#ffffff',
                  }}
                  onClick={cancelFunction}
                >
                  <Button
                    style={{
                      fontWeight: 'bold',
                      border: 'none',
                      color: '#1C252C',
                      height: '3.2rem',
                      padding: '1rem 0',
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                    }}
                  >
                    <Row
                      style={{
                        borderBottom: '1px solid #1C252C',
                        fontSize: '1rem',
                        lineHeight: '1rem',
                        fontWeight: 700,
                        cursor: 'pointer',
                      }}
                    >
                      {cancelMsg}
                    </Row>
                  </Button>
                </Row>
              )}
            </Row>
          </Row>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

// 타이틀을 표시할 수 있고, 컨텐츠를 자유롭게 작성가능하며, 예, 아니오가 나란히 존재하는 형태의 모달,
const ModalType3 = ({
  isOpen = true,
  title,
  titleFontStyle = {},
  titleDivStyle = {},
  content,
  okMsg,
  okButtonWidthRatio = 50,
  cancelMsg = '닫기',
  okFunction = () => {},
  cancelFunction = () => {},
  contentHeight,
  contentStyle = {},
  contentScrollHiddenFlag = false,
  width = 24,
  contentNonePaddingFlag = false,
}: {
  isOpen: boolean;
  content: JSX.Element;
  title?: string;
  titleFontStyle?: any;
  titleDivStyle?: any;
  okMsg?: string;
  okButtonWidthRatio?: number;
  okFunction?: MouseEventHandler;
  cancelMsg?: string;
  contentStyle?: any;
  contentHeight?: string;
  cancelFunction?: MouseEventHandler;
  contentScrollHiddenFlag?: boolean;
  width?: number | string;
  contentNonePaddingFlag?: boolean;
}) => {
  let contentMaxHeight = 40 - 3.2; // 높이상한 800 - 닫기 버튼 64
  if (okMsg) contentMaxHeight -= 3.6; // ok 버튼 72
  if (title) contentMaxHeight -= 4.8; // title padding60 + 글자크기 36

  return (
    <>
      {isOpen ? (
        <Row
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            background: 'rgba(28,37,44, 0.5)',
          }}
        >
          <Row
            style={{
              width: typeof width === 'string' ? width : `${width}rem`,
              position: 'absolute',
              background: '#fff',
            }}
          >
            {title && (
              <Row
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 999,
                  background: '#ffffff',
                  padding: '1rem 2rem 1rem 2rem',
                  borderBottom: '1px solid #e5e5e5',
                  ...titleDivStyle,
                }}
              >
                <TypographyText
                  fontSize="1.8rem"
                  letterSpacing="-0.02rem"
                  lineHeight="1em"
                  fontWeight={700}
                  style={titleFontStyle}
                >
                  {title}
                </TypographyText>
              </Row>
            )}
            <Row
              style={{
                position: 'relative',
                padding: contentNonePaddingFlag ? 0 : '1rem 2rem 1rem 2rem',
                height: contentHeight ? contentHeight : `calc(100%${okMsg ? ` - 3.6rem` : ``})`,
                maxHeight: `${contentMaxHeight}rem`,
                overflowY: contentScrollHiddenFlag ? 'hidden' : 'scroll',
                ...contentStyle,
              }}
              className={css`
                overflow-x: hidden !important;
                &::-webkit-scrollbar {
                  background-color: white;
                  width: 0.25rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #c1c1c1;
                  border-radius: 1rem;
                }
              `}
            >
              {content}
            </Row>
            <Row style={{ display: 'flex', textAlign: 'center' }}>
              {okButtonWidthRatio === 100 ? (
                <></>
              ) : (
                <Col
                  style={{
                    width: `${100 - okButtonWidthRatio}%`,
                    height: '4rem',
                    borderTop: '1px solid #e5e5e5',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  onClick={cancelFunction}
                >
                  <TypographyText yjTypeTypographyText="symptom-table-body">{cancelMsg}</TypographyText>
                </Col>
              )}
              {okButtonWidthRatio === 0 ? (
                <></>
              ) : (
                <Col
                  style={{
                    width: `${okButtonWidthRatio}%`,
                    height: '4rem',
                    background: '#346AFF',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  onClick={okFunction}
                >
                  <TypographyText yjTypeTypographyText="symptom-table-body" style={{ color: '#ffffff' }}>
                    {okMsg}
                  </TypographyText>
                </Col>
              )}
            </Row>
          </Row>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

// 하단에 닫기버튼만 존재하는 모달
const ModalWithJustBottomCloseBtn = ({
  isOpen = true,
  width,
  height,
  content,
  cancelMsg = '닫기',
  cancelFunction = () => {},
}: {
  isOpen: boolean;
  width: number;
  height: number;
  content?: React.ReactElement;
  cancelMsg?: string;
  cancelFunction?: any;
}) => {
  return (
    <>
      {isOpen && (
        <Row
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            background: 'rgba(28,37,44, 0.5)',
          }}
        >
          <Row
            style={{
              width: `${width / getRootFontSize()}rem`,
              height: `${height / getRootFontSize()}rem`,
            }}
          >
            {content}
          </Row>
          <Row
            style={{
              width: `${width / getRootFontSize()}rem`,
              cursor: 'pointer',
              zIndex: 999,
              backgroundColor: '#ffffff',
            }}
            onClick={cancelFunction}
          >
            <Button
              style={{
                padding: '1rem 2rem',
                backgroundColor: '#1C252C',
                border: 'none',
                color: '#ffffff',
                height: '3.6rem',
                cursor: 'pointer',
                width: `100%`,
              }}
              onClick={cancelFunction}
            >
              <Row
                style={{
                  fontSize: '1.2rem',
                  lineHeight: '1.2rem',
                  letterSpacing: '0.05em',
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
              >
                {cancelMsg}
              </Row>
            </Button>
          </Row>
        </Row>
      )}
    </>
  );
};

// 우상단에 X버튼만 존재하는 모달
const ModalWithJustX = ({
  isOpen = true,
  width,
  height,
  content,
  cancelFunction = () => {},
}: {
  isOpen: boolean;
  width: number | string;
  height: number | string;
  content?: React.ReactElement;
  cancelFunction?: any;
}) => {
  return (
    <>
      {isOpen && (
        <Row
          onClick={cancelFunction}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            background: 'rgba(28,37,44, 0.5)',
          }}
        >
          <Row
            className="modal-with-just-x"
            style={{
              position: 'relative',
              width: typeof width === 'string' ? `${width}` : `${width}px`,
              height: typeof height === 'string' ? `${height}` : `${height}px`,
            }}
          >
            <Row
              style={{
                position: 'absolute',
                top: '-2.5rem',
                right: '0.25rem',
                display: 'flex',
                cursor: 'pointer',
              }}
              onClick={cancelFunction}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/sample-close-x.webp`}
                style={{ width: '2rem' }}
                alt={'close-icon'}
              />
            </Row>
            {content}
          </Row>
        </Row>
      )}
    </>
  );
};

// 공지 모달
const NoticeModal = ({
  isOpen = true,
  cancelFunction = () => {},
}: {
  isOpen?: boolean;
  cancelFunction?: any;
}) => {
  const [page, setPage] = useState(1);

  const categoryMap = new Map([
    [1, { title: '첩약 건강보험' }],
    [2, { title: '나의 처방' }],
    [3, { title: '변증 UI 변경' }],
    [4, { title: '약재 추가 방식 변경' }],
  ]);

  const contentsList: {
    category: number;
    contents: React.ReactElement | null;
    imgWidth?: string;
    imgHeight?: string;
    imgFormat?: string;
  }[] = [
    {
      category: 1,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontWeight={700} fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre">
              {`Step 1. 첩약건보 참여 설정`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '1.25rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`첩약건보 2차 사업에 참여하는 한의원은
설정 화면에서 `}
              <strong>{`[첩약건보 참여]`}</strong>
              {` 설정을 합니다. `}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '0.75rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#ED7169" whiteSpace="pre-wrap">
              {`* 첩약건보 참여를 사전에 설정해야만 
환자가 첩약건보 설문을 진행할 수 있습니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 1,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontWeight={700} fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre">
              {`Step 2. 첩약건보 설문 전송`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '1.25rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              <strong>{`[첩약건보 참여]`}</strong>
              {` 설정 후 환자에게 설문 전송 시, 
첩약건보 대상 질환을 선택할 수 있는 설문이 전송됩니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 1,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontWeight={700} fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre">
              {`Step 3. 첩약건보 질환 변경`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '1.25rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`필요 시, 진료실의 `}
              <strong>{`[1. 증상]`}</strong>
              {` 또는 `}
              <strong>{`[3. 처방]`}</strong>
              {` 단계에서 
질환을 변경하거나 비급여로 변경할 수 있습니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 1,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontWeight={700} fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre">
              {`Step 4. 첩약건보 처방 추천`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '1.25rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              <strong>{`[3. 처방]`}</strong>
              {` 단계에서 질환별 기준 처방 내에서 
고유/사상/변증 별로 처방을 추천해 드립니다.`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '0.75rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`또한, 각 기준 처방에 대한 상한 금액 확인이 가능합니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 1,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontWeight={700} fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre">
              {`Step 5. 처방전/첩약 조제내역`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '1.25rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`진료완료 후 `}
              <strong>{`[진료내역]`}</strong>
              {` 메뉴에서 `}
              <strong>{`“처방전”`}</strong>
              {`, 
`}
              <strong>{`“첩약처방 조제내역 안내”`}</strong>
              {` 인쇄가 가능합니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 1,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontWeight={700} fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre">
              {`Step 6. 차트복사`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '1.25rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`진료완료 후 `}
              <strong>{`[진료내역]`}</strong>
              {` 메뉴에서 차트 복사가 가능합니다. 
(비급여 진료에서도 가능합니다.)`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 2,
      contents: (
        <Row style={{ display: 'flex' }}>
          <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
            {`자주 사용하는 처방을 저장하는 기능이 추가되었어요. 
나만의 처방을 손쉽게 저장하고 불러오세요.`}
          </TypographyText>
        </Row>
      ),
    },
    {
      category: 3,
      contents: (
        <Row style={{ display: 'flex' }}>
          <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
            {`팔강 결과 그래프에 균형 영역이 추가되었습니다.
한-열, 표-리, 허-실 중 어느 항목이 균형을 이루고 
있는지 확인 가능합니다.`}
          </TypographyText>
        </Row>
      ),
      imgFormat: 'webp',
    },
    {
      category: 4,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
            {`약재 추가 방식을 선택할 수 있어요.`}
          </TypographyText>
          <Row style={{ display: 'flex', marginTop: '0.75rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`* 합방: 중복되지 않은 약재만 추가
* 대체: 초기화 후, 새로운 처방으로 변경`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
  ];

  return (
    <>
      {isOpen ? (
        <Row
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            background: 'rgba(28,37,44, 0.5)',
          }}
        >
          <Row
            style={{
              width: `64rem`,
              height: `39rem`,
              position: 'absolute',
              background: '#fff',
            }}
          >
            <Row
              style={{
                position: 'relative',
                height: `100%`,
                maxHeight: `39rem`,
                overflowY: 'auto',
                width: '100%',
              }}
              className={css`
                overflow-x: hidden !important;
                &::-webkit-scrollbar {
                  background-color: white;
                  width: 0.25rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #c1c1c1;
                  border-radius: 1rem;
                }
              `}
            >
              <Row style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Col // left side
                  style={{
                    flex: 4375,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    padding: '3rem 1.25rem 2.4rem 3rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
                    <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <TypographyText
                        style={{
                          fontSize: '1.4rem',
                          lineHeight: '1.2em',
                          fontWeight: 700,
                          whiteSpace: 'pre',
                          color: '#F8B659',
                        }}
                      >
                        {'예진 새로운 기능 안내'}
                      </TypographyText>
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '3rem',
                          height: '1.6rem',
                          borderRadius: '100px',
                          background: '#d9d9d9',
                        }}
                      >
                        <TypographyText
                          style={{
                            fontSize: '0.9rem',
                            lineHeight: '1em',
                            fontWeight: 700,
                            whiteSpace: 'pre',
                            color: '#ffffff',
                            letterSpacing: '0.05em',
                          }}
                        >
                          {`${page}/${contentsList.length}`}
                        </TypographyText>
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}>
                        {Array.from(categoryMap).map((value) => {
                          const k = value[0];
                          const v = value[1];
                          const currentCategoryFlag = k === contentsList[page - 1].category;
                          return (
                            <Row
                              key={k}
                              style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}
                            >
                              <Row style={{ display: 'flex', columnGap: '0.75rem', alignItems: 'center' }}>
                                <Col
                                  style={{
                                    background: currentCategoryFlag ? '#F8B659' : '#ffffff',
                                    border: '1px solid #F8B659',
                                    width: '2.25rem',
                                    height: '2.25rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <TypographyText
                                    style={{
                                      fontSize: '1.6rem',
                                      lineHeight: '1.2em',
                                      fontWeight: 700,
                                      whiteSpace: 'pre',
                                      color: currentCategoryFlag ? '#FFFFFF' : '#F8B659',
                                    }}
                                  >
                                    {k}
                                  </TypographyText>
                                </Col>
                                <TypographyText
                                  style={{
                                    fontSize: '1.5rem',
                                    lineHeight: '1.2em',
                                    fontWeight: 700,
                                    whiteSpace: 'pre',
                                    color: currentCategoryFlag ? '#1D1C1D' : '#AFAFAF',
                                  }}
                                >
                                  {v.title}
                                </TypographyText>
                              </Row>
                              {currentCategoryFlag && (
                                <Row style={{ minHeight: '9.5rem' }}>{contentsList[page - 1].contents}</Row>
                              )}
                            </Row>
                          );
                        })}
                      </Row>
                    </Row>
                  </Row>
                  <button
                    style={{
                      width: 'fit-content',
                    }}
                    onClick={() => {
                      cancelFunction(true);
                    }}
                  >
                    <TypographyText
                      style={{
                        fontSize: '1rem',
                        fontWeight: 700,
                        lineHeight: '1.2em',
                        whiteSpace: 'pre',
                        color: '#AFAFAF',
                        textDecoration: 'underline',
                      }}
                    >
                      {'다시 보지 않기'}
                    </TypographyText>
                  </button>
                </Col>
                <Col
                  style={{
                    flex: 5625, // right side
                    display: 'flex',
                    flexDirection: 'column',
                    background: '#F9F6F1',
                    width: '100%',
                    height: '100%',
                    padding: '3rem 3rem 2.4rem 3rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/notice-${page}.${
                        contentsList[page - 1].imgFormat ?? 'avif'
                      }`}
                      style={{
                        width: contentsList[page - 1]['imgWidth'] ?? '30rem',
                      }}
                      alt={`공지이미지-${page}`}
                    />
                  </Row>
                  <Row style={{ display: 'flex', columnGap: '0.4rem' }}>
                    {page - 1 > 0 && (
                      <button
                        style={{
                          width: `8rem`,
                          height: '3rem',
                          background: '#ffffff',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid #1c252c',
                        }}
                        onClick={() => setPage(page - 1)}
                      >
                        <TypographyText
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2em',
                            whiteSpace: 'pre',
                            fontWeight: 700,
                          }}
                        >
                          {'이전'}
                        </TypographyText>
                      </button>
                    )}
                    {page <= contentsList.length && (
                      <button
                        style={{
                          flex: 1,
                          height: '3rem',
                          background: '#1c252c',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          if (page === contentsList.length) {
                            // 닫기
                            cancelFunction();
                          } else {
                            setPage(page + 1);
                          }
                        }}
                      >
                        <TypographyText
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2em',
                            whiteSpace: 'pre',
                            fontWeight: 700,
                            color: '#ffffff',
                          }}
                        >
                          {page === contentsList.length ? '닫기' : '다음'}
                        </TypographyText>
                      </button>
                    )}
                  </Row>
                </Col>
              </Row>
            </Row>
          </Row>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

// 튜토리얼 모달
const TutorialModal = ({
  isOpen = true,
  cancelFunction = () => {},
}: {
  isOpen?: boolean;
  cancelFunction?: any;
}) => {
  const [page, setPage] = useState(1);

  const categoryMap = new Map([
    [1, { title: '첩약건보 설정하는 방법' }],
    [2, { title: '환자 등록' }],
    [3, { title: '설문 발송' }],
    [4, { title: '진료 시작' }],
    [5, { title: '진료 완료' }],
    [6, { title: '진료 내역 확인' }],
  ]);

  const contentsList: {
    category: number;
    contents: React.ReactElement | null;
    imgWidth?: string;
    imgHeight?: string;
    imgFormat?: string;
  }[] = [
    {
      category: 1,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`첩약건보 2차 사업에 참여하는 한의원은
설정 화면에서 `}
              <strong>{`[첩약건보 참여]`}</strong>
              {` 설정을 합니다. `}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '0.75rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#ED7169" whiteSpace="pre-wrap">
              {`* 첩약건보 참여를 사전에 설정해야만 
환자가 첩약건보 설문을 진행할 수 있습니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 2,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`환자관리 화면에서 `}
              <strong>{`[환자등록]`}</strong>
              {` 버튼 클릭 후
환자 정보를 입력하면 환자가 등록됩니다.`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '0.75rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#ED7169" whiteSpace="pre-wrap">
              {`* `}
              <strong>{`등록 후 바로 카카오 링크 전송하기`}</strong>
              {`를 선택할 경우
환자 등록과 동시에 증상 설문 링크가 전송됩니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 3,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`환자관리 화면에서 `}
              <strong>{`[상세]`}</strong>
              {` 버튼 클릭 후
환자정보 탭에서 `}
              <strong>{`[카카오 링크 전송]`}</strong>
              {` 버튼을 클릭하면 
환자 전화번호(카카오톡)로 증상 설문 링크가 전송됩니다.`}
            </TypographyText>
          </Row>
          <Row style={{ display: 'flex', marginTop: '0.75rem' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#ED7169" whiteSpace="pre-wrap">
              {`* 설정 - 알림 설정 탭에서 [설문완료 알림]에 수신 동의하면,
환자가 설문 완료했을 때, 카카오톡 알림을 받을 수 있어요.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 4,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`환자가 증상 설문을 입력하면 진료실에 환자가 등록됩니다.
`}
              <strong>{`[진료시작]`}</strong>
              {` 버튼을 클릭하여 진료를 시작하세요!`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 5,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`증상 및 변증 확인 후 처방까지 마무리했다면
`}
              <strong>{`[진료완료]`}</strong>
              {` 버튼을 클릭하여 진료를 완료하세요.
*증상, 변증 단계에서도 진료 완료가 가능합니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
    {
      category: 6,
      contents: (
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize="1rem" lineHeight="1.35em" color="#1C252C" whiteSpace="pre-wrap">
              {`진료 완료 이후 진료내역 화면에서 이전 진료내역을 
날짜별로 확인할 수 있습니다. `}
              <strong>{`차트복사`}</strong>
              {` 및 `}
              <strong>{`처방전,
`}</strong>
              <strong>{`첩약처방/조제내역 안내`}</strong>
              {` 확인이 가능합니다.`}
            </TypographyText>
          </Row>
        </Row>
      ),
    },
  ];

  return (
    <>
      {isOpen ? (
        <Row
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            background: 'rgba(28,37,44, 0.5)',
          }}
        >
          <Row
            style={{
              width: `64rem`,
              height: `39rem`,
              position: 'absolute',
              background: '#fff',
            }}
          >
            <Row
              style={{
                position: 'relative',
                height: `100%`,
                maxHeight: `39rem`,
                overflowY: 'auto',
                width: '100%',
              }}
              className={css`
                overflow-x: hidden !important;
                &::-webkit-scrollbar {
                  background-color: white;
                  width: 0.25rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #c1c1c1;
                  border-radius: 1rem;
                }
              `}
            >
              <Row style={{ display: 'flex', width: '100%', height: '100%' }}>
                <Col // left side
                  style={{
                    flex: 4375,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    padding: '3rem 1.25rem 2.4rem 3rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
                    <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <TypographyText
                        style={{
                          fontSize: '1.4rem',
                          lineHeight: '1.2em',
                          fontWeight: 700,
                          whiteSpace: 'pre',
                          color: '#F8B659',
                        }}
                      >
                        {'예진 튜토리얼'}
                      </TypographyText>
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '3rem',
                          height: '1.6rem',
                          borderRadius: '100px',
                          background: '#d9d9d9',
                        }}
                      >
                        <TypographyText
                          style={{
                            fontSize: '0.9rem',
                            lineHeight: '1em',
                            fontWeight: 700,
                            whiteSpace: 'pre',
                            color: '#ffffff',
                            letterSpacing: '0.05em',
                          }}
                        >
                          {`${page}/${contentsList.length}`}
                        </TypographyText>
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}>
                        {Array.from(categoryMap).map((value, index) => {
                          const k = value[0];
                          const v = value[1];
                          const currentCategoryFlag = k === contentsList[page - 1].category;
                          return (
                            <Row
                              key={k}
                              style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}
                            >
                              <Row
                                style={{
                                  display: 'flex',
                                  columnGap: '0.75rem',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  width: 'fit-content',
                                }}
                                onClick={() => setPage(index + 1)}
                              >
                                <Col
                                  style={{
                                    background: currentCategoryFlag ? '#F8B659' : '#ffffff',
                                    border: '1px solid #F8B659',
                                    width: '2.25rem',
                                    height: '2.25rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <TypographyText
                                    style={{
                                      fontSize: '1.6rem',
                                      lineHeight: '1.2em',
                                      fontWeight: 700,
                                      whiteSpace: 'pre',
                                      color: currentCategoryFlag ? '#FFFFFF' : '#F8B659',
                                    }}
                                  >
                                    {k}
                                  </TypographyText>
                                </Col>
                                <TypographyText
                                  style={{
                                    fontSize: '1.5rem',
                                    lineHeight: '1.2em',
                                    fontWeight: 700,
                                    whiteSpace: 'pre',
                                    color: currentCategoryFlag ? '#1D1C1D' : '#AFAFAF',
                                  }}
                                >
                                  {v.title}
                                </TypographyText>
                              </Row>
                              {currentCategoryFlag && (
                                <Row style={{ minHeight: '7.5rem' }}>{contentsList[page - 1].contents}</Row>
                              )}
                            </Row>
                          );
                        })}
                      </Row>
                    </Row>
                  </Row>
                </Col>
                <Col
                  style={{
                    flex: 5625, // right side
                    display: 'flex',
                    flexDirection: 'column',
                    background: '#F9F6F1',
                    width: '100%',
                    height: '100%',
                    padding: '3rem 3rem 2.4rem 3rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/tutorial-${page}.${
                        contentsList[page - 1].imgFormat ?? 'avif'
                      }`}
                      style={{
                        width: contentsList[page - 1]['imgWidth'] ?? '30rem',
                      }}
                      alt={`공지이미지-${page}`}
                    />
                  </Row>
                  <Row style={{ display: 'flex', columnGap: '0.4rem' }}>
                    {page - 1 > 0 && (
                      <button
                        style={{
                          width: `8rem`,
                          height: '3rem',
                          background: '#ffffff',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid #1c252c',
                        }}
                        onClick={() => setPage(page - 1)}
                      >
                        <TypographyText
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2em',
                            whiteSpace: 'pre',
                            fontWeight: 700,
                          }}
                        >
                          {'이전'}
                        </TypographyText>
                      </button>
                    )}
                    {page <= contentsList.length && (
                      <button
                        style={{
                          flex: 1,
                          height: '3rem',
                          background: '#1c252c',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          if (page === contentsList.length) {
                            // 닫기
                            cancelFunction();
                          } else {
                            setPage(page + 1);
                          }
                        }}
                      >
                        <TypographyText
                          style={{
                            fontSize: '1.2rem',
                            lineHeight: '1.2em',
                            whiteSpace: 'pre',
                            fontWeight: 700,
                            color: '#ffffff',
                          }}
                        >
                          {page === contentsList.length ? '닫기' : '다음'}
                        </TypographyText>
                      </button>
                    )}
                  </Row>
                </Col>
              </Row>
            </Row>
          </Row>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export {
  Modal,
  ModalType2,
  ModalWithJustBottomCloseBtn,
  ModalWithJustX,
  ModalType3,
  NoticeModal,
  ModalWith3Button,
  TutorialModal,
};
