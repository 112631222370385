/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { TypographyText, Row, Radio as ARadio, RadioGroup, Divider } from 'components/UI/atoms';
import _ from 'lodash';
import { useStores } from 'stores/Context';
import { isParentSurveyNegative } from 'utils/util';

function Onset({
  form,
  k,
  v,
  recentChangedField,
}: {
  form: any;
  k: string;
  v: any;
  recentChangedField: React.MutableRefObject<any>;
}): ReactElement {
  const { surveyStore } = useStores();
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    // onset 값이 없다면
    if (form.getFieldValue(k)?.onset == undefined) {
      // 설문 코드를 invalidOnsetAdminSurveyCodes에 추가
      surveyStore.addInvalidOnsetAdminSurveyCodes(k);
    } else {
      // onset 값이 있다면 invalidOnsetAdminSurveyCodes에서 삭제
      surveyStore.deleteInvalidOnsetAdminSurveyCodes(k);
    }
    return () => {
      // 부모 설문 코드
      const parentCode = k?.slice(0, -1);
      // console.log('parentCode:', parentCode, form.getFieldValue(parentCode));
      // console.log(isParentSurveyNegative(form, k));
      // console.log(_.isArray(form.getFieldValue(parentCode)));
      // console.log(form.getFieldValue(parentCode)?.includes(k));

      // 모든 부모 설문의 값이 부정이거나, 부모 설문이 배열이고 부모 설문에 현재 설문이 포함되어 있지 않거나, 부모 설문 값이 문자열인데 현재 설문 코드와 같지 않다면
      if (
        isParentSurveyNegative(form, k) ||
        (_.isArray(form.getFieldValue(parentCode)) && !form.getFieldValue(parentCode)?.includes(k)) ||
        (_.isString(form.getFieldValue(parentCode)) && form.getFieldValue(parentCode) !== k)
      ) {
        surveyStore.deleteInvalidOnsetAdminSurveyCodes(k);
      }
    };
  }, []);

  return (
    <Row yjTypeRow="onset">
      <Divider marginRem={0.375} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
      <TypographyText yjTypeTypographyText="sub-title">발현된 시점</TypographyText>
      <Divider marginRem={0.5} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
      <RadioGroup buttonStyle="solid">
        {[
          { label: '일주일 이내', value: 0 },
          { label: '1개월 이내', value: 1 },
          { label: '3개월 이내', value: 2 },
          { label: '6개월 이내', value: 3 },
          { label: '6개월 이상', value: 4 },
        ].map(({ value, label }: { value: string | number; label: string }) => (
          <ARadio
            className={form.getFieldValue(k)?.onset == value ? 'ant-radio-wrapper-checked' : ''}
            yjTypeRadio="survey-onset"
            key={`${k}_${value}`}
            checked={form.getFieldValue(k)?.onset == value}
            value={value}
            onChange={(e) => {
              e.stopPropagation(); //event capturing
              recentChangedField.current = k;
              const pv = form.getFieldValue(k) ?? {}; // previous value
              const cv = { ...pv, onset: value }; // current value
              // 이전 onset 값이 없고 현재 onset 값이 있다면 invalidOnsetAdminSurveyCodes에서 삭제
              if (pv?.onset === undefined && cv?.onset !== undefined) {
                surveyStore.deleteInvalidOnsetAdminSurveyCodes(k);
              }
              form.setFieldsValue({ [k]: cv }); // set current value
              forceUpdate();
            }}
          >
            {label}
          </ARadio>
        ))}
      </RadioGroup>
      {form.getFieldValue(k)?.onset === undefined && (
        <Row>
          <Divider marginRem={0.5} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
          <TypographyText yjTypeTypographyText="survey-validation">
            발현된 시점 항목을 선택해주세요.
          </TypographyText>
        </Row>
      )}
      <Divider marginRem={0.75} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
    </Row>
  );
}

export default observer(Onset);
