import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

export class SignStore {
  rootStore: RootStore;
  isPolicy: boolean;
  isEmail: boolean;
  isName: boolean;
  isClinicName: boolean;
  isClinicPhone: boolean;
  isPasswordConfirm: boolean;
  password?: string;
  confirmedPassword?: string;
  policyDetailValue: number = 0;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.isPolicy = false;
    this.isEmail = false;
    this.isName = false;
    this.isClinicName = false;
    this.isClinicPhone = false;
    this.isPasswordConfirm = false;
  }
  setPolicyDetailValue = (value: number) => {
    sessionStorage.setItem('policy', value.toString());
    this.policyDetailValue = value;
  };

  setPolicy = (value: boolean) => {
    this.isPolicy = value;
  };
}
