/* eslint-disable react-hooks/exhaustive-deps */
import { API, Auth, graphqlOperation, Hub } from 'aws-amplify';
import { useEffect, useRef, useCallback } from 'react';
import { Observable } from 'zen-observable-ts';
import 'react-phone-number-input/style.css';
import 'react-select-search/style.css';

import { MyRoutes } from './router/MyRoutes';
import * as subscriptions from './graphql/subscriptions';
import { useStores } from 'stores/Context';
import { observer } from 'mobx-react';
import jwt_decode from 'jwt-decode';
import { isEnableTillStaging } from 'config';

const refreshToken = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
    console.log('session', err, session);
  });
};

const App = () => {
  const { roleStore, clientStore } = useStores();
  const clinicSubscription = useRef<any>({
    closed: true,
  });

  const setUserRole = useCallback(async (id) => {
    // userRole 세팅을 위한 코드
    try {
      await clientStore.setMetaData(id);
    } catch (e) {
      if (e === 'No current user') {
        sessionStorage.clear(); // cognito에서 받아온 로그인 정보가 없는 상태임에도 세션 스토리지에 로그인 정보가 남아있는 문제 있는 상황. 현재 탭 세션스토리지 제거
      } else {
        console.log(e);
      }
    }
  }, []);

  const listenerFunc = useCallback((e) => {
    if (isEnableTillStaging) console.log(e);
    // 다른 탭에서 유저가 로그인한 경우 대응하기 위한 코드
    if (e?.key?.includes('LastAuthUser')) {
      // 현재 탭에서 로그인되어있던 유저 uuid
      const currentTabUserId = sessionStorage.getItem('sub');
      // 다른 탭에서 로그인해도 로컬스토리지에 최근 로그인한 유저 uuid가 기록됨
      let currentAuthUserId = localStorage.getItem(e.key);
      if (currentTabUserId && currentAuthUserId && currentTabUserId !== currentAuthUserId) {
        // 현재 탭에서 로그인한 유저와 최근 로그인한 유저의 정보가 다름 => 현재 탭 로그인 유저가 유효하지 않은 것이므로 랜딩페이지로 강제 이동
        sessionStorage.clear(); // 현재 탭 세션스토리지 제거
        window.open('/', '_self'); // 로그인 화면으로 이동 & 강제 새로고침
      }
    }

    if (e?.key === 'sub') {
      // 현재 탭에서 로그인되어있던 유저 uuid
      const currentTabUserId = sessionStorage.getItem('sub');
      if (!currentTabUserId) {
        // 현재 탭에서 로그인되어있던 유저 uuid 정보가 없는 경우
        sessionStorage.clear(); // 현재 탭 세션스토리지 제거
        window.open('/', '_self'); // 로그인 화면으로 이동 & 강제 새로고침
      }
    }
  }, []);

  const registClinicSubscription = useCallback(async ({ token, userRole, clinicID }) => {
    clinicSubscription.current = (
      API.graphql(graphqlOperation(subscriptions.onClinicByID, { id: clinicID }, token)) as Observable<any>
    ).subscribe({
      next: async () => {
        // 서브스크립션중 자신의 clinic데이터가 변경이 감지되면 실행할 로직
        if (userRole?.[clinicID]?.id) {
          // 클리닉 데이터 중 스탭 수 필드 변경 감지로 이미 로그인되어있는 소속해제된 스탭 계정을 대응할 수 있음
          const userRoleRes = await roleStore.getUserRole(userRole?.[clinicID]?.id);
          if (!userRoleRes) {
            /**
             * 현재 탭에서 로그인할 당시에는 userRole 데이터가 존재했으나, 이후에 제거된 상태를 의미.
             * 세션스토리지를 비운 후 랜딩페이지로 강제이동 시킨다.
             */
            sessionStorage.clear(); // 현재 탭 세션스토리지 제거
            window.open('/', '_self'); // 로그인 화면으로 이동 & 강제 새로고침
          }
        }
        if (isEnableTillStaging) {
          console.log(userRole);
          console.log('clinic data updated');
        }
        refreshToken();
      },
      error: (error) => console.log(error),
    });
  }, []);

  const setSubscription = useCallback(() => {
    Auth.currentSession().then((idToken) => {
      const token = idToken.getIdToken().getJwtToken();
      if (token) {
        const payload: any = jwt_decode(token);
        if (!!payload?.userRole && payload?.userRole !== '{}') {
          const userRole = JSON.parse(payload.userRole);
          // todo 계정이 N개의 한의원에 소속되는 기획이 나오게되면, 선택한 한의원과 userRole의 clinicID가 일치하는 데이터로 세팅해야할 것
          const [clinicID] = Object.keys(userRole);
          /**
           * graphQL의 서브스크립션 호출
           * graphqlOperation(쿼리, 변수(자신의 클리닉ID), graphQL 인증을 받기위한 토큰)
           */
          registClinicSubscription({
            token,
            userRole,
            clinicID,
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    const authListener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          if (isEnableTillStaging) console.log('signIn');
          if (clinicSubscription.current?.closed) {
            if (isEnableTillStaging) console.log('구독시작');
            setSubscription();
          }
          break;
        case 'signOut':
          if (isEnableTillStaging) console.log('signOut');
          if (!clinicSubscription.current?.closed) {
            if (isEnableTillStaging) console.log('구독종료');
            clinicSubscription.current?.unsubscribe();
          }
          break;
      }
    };
    if (isEnableTillStaging) {
      console.log('clinic subscription connection is closed?:', clinicSubscription.current?.closed);
    }
    Auth.currentSession()
      .then(() => {
        if (clinicSubscription.current?.closed) {
          if (isEnableTillStaging) console.log('구독시작');
          setSubscription();
        }
      })
      .catch(() => {});

    Hub.listen('auth', authListener);

    const userUuid = sessionStorage.getItem('sub');
    if (userUuid) {
      setUserRole(userUuid);
    }

    if (isEnableTillStaging) console.log('storage listener start;');
    window.addEventListener('storage', (e) => listenerFunc(e));
    return () => {
      if (isEnableTillStaging) console.log('storage listener end;');
      window.removeEventListener('storage', (e) => listenerFunc(e));
      if (!clinicSubscription.current?.closed) {
        if (isEnableTillStaging) console.log('구독종료');
        clinicSubscription.current?.unsubscribe();
      }
    };
  }, []);

  return <MyRoutes />;
};

export default observer(App);
