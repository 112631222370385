import * as AmplifyUI from '@aws-amplify/ui-react';
import styled from '@emotion/styled';

export interface ButtonParams extends AmplifyUI.ButtonProps {
  children?: any;
  name?: String;
}

const StyledButton = styled(AmplifyUI.Button)<ButtonParams>`
  border: 1px solid #afafaf;
  /* pointer-events: none; */
  &.amplify-button:hover {
    background-color: initial;
    border: 1px solid #afafaf;
    color: initial;
  }
  &.amplify-button:focus {
    background-color: initial;
    border: 1px solid #afafaf;
    color: initial;
  }
  &.yejin-button--white {
    border: 1px solid #afafaf;
    font-size: 1rem;
    padding: 0.8rem 1.6rem;
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
  }

  &.yejin-button--white:hover {
    border: 1px solid #afafaf;
    background-color: #ffffff;
    color: #000000;
  }
  &.yejin-button--white:focus {
    border: 1px solid #afafaf;
    background-color: #ffffff;
    color: #000000;
  }

  &.yejin-button--black {
    border: 0;
    font-size: 1rem;
    padding: 0.8rem 1.6rem;
    background-color: #1c252c;
    color: white;
    font-weight: bold;
  }

  &.yejin-button--black:hover {
    border: 0;
    background-color: #1c252c;
    color: white;
  }
  &.yejin-button--black:focus {
    border: 0;
    background-color: #1c252c;
    color: white;
  }

  &.yejin-button--gold {
    border: 0;
    font-size: 1rem;
    padding: 0.8rem 1.6rem;
    background-color: #f2a549;
    color: white;
    font-weight: bold;
  }

  &.yejin-button--gold:hover {
    border: 0;
    background-color: #f2a549;
    color: white;
  }

  &.yejin-button--gold:focus {
    border: 0;
    background-color: #f2a549;
    color: white;
  }

  &.yejin-button--search {
    border: 0;
    background-color: #1c252c;
    color: white;
    font-weight: bold;
  }

  &.yejin-button--search:hover {
    border: 0;
    background-color: #1c252c;
    color: white;
  }

  &.yejin-button--search:focus {
    border: 0;
    background-color: #1c252c;
    color: white;
  }
`;

export const Button = ({ name, ...rest }: ButtonParams) => {
  return (
    <StyledButton className={`yejin-button--${name}`} {...rest}>
      {rest.children}
    </StyledButton>
  );
};
