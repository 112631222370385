import { Divider, Flex, Heading, Table, Text } from '@aws-amplify/ui-react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import PolicyDetailLayout from 'components/templates/PolicyDetailLayout';

const ServicePolicy = () => {
  const StyledOLTag = styled.ol`
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1rem;
  `;

  const StyledTD = styled.td`
    padding: 1rem;
  `;

  const StyledTHeader = styled.tr`
    border-top: 1px solid #afafaf;
    background-color: #f5f5f5;
    font-size: 1.2rem;
    font-weight: 700;
  `;
  const StyledTR = styled.tr`
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    font-size: 1rem;
    font-weight: 400;
    vertical-align: top;
  `;

  return (
    <Flex direction={'column'} gap={0} marginBottom={'5rem'}>
      <Heading level={1} fontWeight={700} fontSize={'1.6rem'}>
        서비스 이용약관
      </Heading>
      <Divider marginTop={'1rem'} border={'1px solid #AFAFAF'} />
      <PolicyDetailLayout
        title={'제 1 조 (목적)'}
        content={
          '이 약관은 주식회사 헬리큐어(이하 “회사”라 함)가 제공하는 예진 서비스 이용과 관련하여 회사와 서비스 이용자(이하 “회원”이라 함)간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.'
        }
      />
      <PolicyDetailLayout
        title={'제 2 조 (정의)'}
        content={
          <>
            <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
            <StyledOLTag>
              <li>
                “예진”이라 함은 병·의원 편의를 위해 모바일과 웹을 통해 환자등록/관리, 환자증상입력, 진료/처방
                서비스 등 "회사"가 한의사 및 방문환자 등의 "회원"에게 제공하는 서비스를 말합니다.
              </li>
              <li>
                “서비스”라 함은 구현되는 단말기(PC, TV, 휴대형 단말기 등의 각종 유무선 장치를 포함)에 상관없이
                “회원”이 이용할 수 있는 예진과 관련 제반 서비스를 의미합니다.
                <Flex direction={'column'} gap={0}>
                  <Text>1) 편의 서비스</Text>
                  <Text>2) 병원 정보 제공 서비스</Text>
                  <Text>3) 기타 회사가 정하는 서비스</Text>
                </Flex>
              </li>
              <li>
                ”편의 서비스”라 함은 예진 모바일 접수, 증상설문, 처방조회 등 “회사”가 ”회원”에게 제공하는 편의
                서비스를 말합니다
              </li>
              <li>
                ”정보 제공 서비스”라 함은 "회사"가 제공하는 페이지에 "회원"이 한의원/병원정보, 의사정보,
                진료정보 등을 입력하여, "회사"가 제공하는 서비스에 등록할 수 있는 서비스를 말합니다.
              </li>
              <li>
                “단말기”’라 함은 서비스에 접속하기 위해 “회원”이 이용하는 개인용 컴퓨터, PDA, 휴대전화,
                태블릿PC 등의 전산장치를 말합니다.
              </li>
              <li>
                “게시물“이라 함은 ”회원“이 ”서비스“를 이용함에 있어 ”서비스상”에 게시한
                부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크
                등을 의미합니다.'
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 3 조 (약관의 게시와 개정)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 이 약관의 내용을 “회원”이 쉽게 알 수 있도록 서비스 내 페이지 화면에 게시합니다.
              </li>
              <li>
                “회사”는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
                ”정보통신망법“)” 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
              </li>
              <li>
                “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 본 조 제 1 항
                규정에 따라 그 개정약관의 적용일자 14일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게
                중요한 약관을 개정하는 경우에는 개정약관의 적용일자 30일전부터 적용일자까지 공지합니다.
              </li>
              <li>
                “회사”가 전항에 따라 개정약관을 공지 또는 통지 하면서 “회원”에게 30일 기간 내에 의사표시를
                하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지 하였음에도 “회원”이
                명시적으로 거부의 의사표시를 하지 아니한 경우 “회원”이 개정약관에 동의한 것으로 봅니다.
              </li>
              <li>
                “회원”이 개정약관의 적용에 동의하지 않는 경우 “회사”는 개정 약관의 내용을 적용할 수 없으며, 이
                경우 “회원”은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이
                있는 경우에는 “회사”는 이용계약을 해지할 수 있습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 4 조 (약관의 해석)'}
        content={
          <>
            “회사”는 필요한 경우, 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하 “서비스 별 안내 등”라
            합니다)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 ”서비스 별 안내 등”이 우선하여
            적용됩니다.
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 5 조 (이용계약 체결)'}
        content={
          <>
            <StyledOLTag>
              <li>
                이용계약은 “회원”이 되고자 하는 자가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고
                “회사”가 이러한 신청에 대하여 승낙함으로써 체결됩니다. 2.” 회사”는 다음 각 호에 해당하는
                신청에 대해서는 승낙을 하지 않거나 사후에 이용 계약을 해지할 수 있습니다. 1. 이 약관에 의하여
                이전에 회원자격을 상실한 적이 있는 경우, 단 “회사”의 회원 재가입 승낙을 얻은 경우에는 예외로
                함. 2. 실명이 아니거나 타인의 명의를 이용한 경우 3. 허위의 정보를 기재하거나, “회사”가
                제시하는 내용을 기재하지 않은 경우 4. “회원”의 귀책사유로 인하여 승인이 불가능하거나 기타
                규정한 제반 사항을 위반하며 신청하는 경우
              </li>
              <li>
                제1항에 따른 신청에 있어 “회사”는 전문기관을 통한 “회원”의 실명확인 및 본인인증을 요청할 수
                있습니다.
              </li>
              <li>
                “회사”는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을
                유보할 수 있습니다.
              </li>
              <li>
                제2항과 제4항에 따라 “회원”의 가입신청을 승낙을 하지 아니하거나 유보한 경우, “회사”는
                원칙적으로 이를 ”회원”에게 알리도록 합니다.
              </li>
              <li>이용계약의 성립 시기는 “회사”가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</li>
              <li>
                회사”는 “회원”에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을
                세분하여 이용에 차등을 둘 수 있습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 6 조 (서비스 제공 및 중지)'}
        content={
          <>
            <StyledOLTag>
              <li>
                ”회원”은 “회사”가 제공하는 서비스에 대해 언제든지 사용 여부를 결정할 수 있으며, “회사”는
                “회원”의 서비스 사용 의사가 없을 경우 서비스 제공을 중지 합니다.
              </li>
              <li>
                “회사”가 제공하는 서비스는 다음과 같습니다.
                <Table
                  margin={'1rem 0'}
                  highlightOnHover={false}
                  size={undefined}
                  variation={undefined}
                  className={css`
                    padding: 0.5rem !important;
                  `}
                >
                  <StyledTHeader>
                    <StyledTD width={'15rem'}>서비스</StyledTD>
                    <StyledTD width={'25rem'}>항목</StyledTD>
                    <StyledTD>서비스 내용</StyledTD>
                  </StyledTHeader>
                  <StyledTR>
                    <StyledTD>환자 증상 설문 입력 서비스</StyledTD>
                    <StyledTD></StyledTD>
                    <StyledTD>환자가 증상에 대해 상세하게 입력할 수 있는 서비스</StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD rowSpan={3}>환자 진료/처방 서비스</StyledTD>
                    <StyledTD>환자 증상 설문 제공 서비스</StyledTD>
                    <StyledTD>
                      환자가 입력한 증상을 한의사가 조회하고 진료에 활용할 수 있도록 하는 서비스
                    </StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>약재 추천 서비스</StyledTD>
                    <StyledTD>
                      환자가 입력한 증상과 한의사의 진료 결과를 기반으로 약재를 추천하는 서비스
                    </StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>처방 입력 서비스</StyledTD>
                    <StyledTD>한의사가 최종적으로 환자에게 처방을 입력하는 서비스</StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>처방 피드백 수집 서비스</StyledTD>
                    <StyledTD></StyledTD>
                    <StyledTD>
                      한의사가 내린 처방에 대해 일정 기간 후 환자에게 피드백을 수집하는 서비스
                    </StyledTD>
                  </StyledTR>
                </Table>
              </li>
              <li>
                ”회사”는 다음 각 호에 해당하는 경우 “회원”에 대한 서비스 제공을 중지할 수 있습니다. 이 경우
                “회사”는 제14조 “회원”에 대한 통지에 정한 방법으로 “회원”에게 통지합니다. 다만, “회사”가
                사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                <Flex direction={'column'} gap={0}>
                  <Text>1) 설비의 보수 등을 위하여 부득이한 경우</Text>
                  <Text>2) 전기통신 사업법에 규정된 기간통신 사업자가 전기 통신서비스를 중지하는 경우</Text>
                  <Text>
                    3) 천재지변 등으로 인해 “회사”가 불가피하게 서비스를 제공할 수 없는 사유가 발생한 경우
                  </Text>
                  <Text>4) “회원”이 탈퇴 등 이용 해지를 요청한 경우</Text>
                  <Text>
                    5) “회원”의 폐업/휴업 등을 포함하여, “회원”의 사유로 인해 1개월 이상 서비스 이용이 중지된
                    경우
                  </Text>
                </Flex>
              </li>
              <li>
                ”회사”는 “회사”의 경영, 정책, 운영 그리고 기술상의 이유로 무료로 제공되는 서비스의 전부 또는
                일부를 변경(유료화 포함) 또는 중단할 수 있으며, 이에 대하여 관련 법에 특별한 규정이 없는 한
                “회원”에게 별도의 보상을 하지 않습니다.
              </li>
              <li>
                본 조에 의해 서비스가 변경 또는 중단되는 경우에는 그 변경 또는 중단 사실을 서비스 페이지 내에
                적용일 14일전부터 게시하여 공지합니다.
              </li>
              <li>
                “회사”는 “서비스”를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다.
                다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
              </li>
              <li>“서비스”는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
              <li>
                “회사”는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은
                서비스제공화면에 공지한 바에 따릅니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 7 조 (업무 위탁의 계약)'}
        content={
          <>
            <StyledOLTag>
              <li>
                업무 위탁의 목적 및 책임 범위
                <Flex direction={'column'} gap={0}>
                  <Text>
                    1) ”회원”은 “회사”가 제공하는 모바일 편의 서비스 중 아래 일부 서비스를 이용하기 위해
                    “회사”에게 “회원”의 업무를 위탁함에 있어 다음과 같은 내용으로 업무위탁계약을 체결합니다.
                  </Text>
                  <Text>2) “회원”이 위탁하는 업무의 목적 및 처리항목은 다음과 같습니다.</Text>
                  <Table
                    margin={'1rem 0'}
                    highlightOnHover={false}
                    size={undefined}
                    variation={undefined}
                    className={css`
                      padding: 0.5rem !important;
                    `}
                  >
                    <StyledTHeader>
                      <StyledTD width={'15rem'}>서비스 항목</StyledTD>
                      <StyledTD width={'25rem'}>서비스 목적</StyledTD>
                      <StyledTD>처리 항목</StyledTD>
                    </StyledTHeader>
                    <StyledTR>
                      <StyledTD>증상 설문 서비스</StyledTD>
                      <StyledTD>환자 증상에 대한 상세 설문 결과 수집</StyledTD>
                      <StyledTD>
                        1) 환자정보
                        <br />
                        - 환자 이름, 생년월일, 성별, 휴대전화번호
                        <br />
                        2) 증상 정보
                        <br />- 증상 설문 입력 정보
                      </StyledTD>
                    </StyledTR>
                    <StyledTR>
                      <StyledTD>진료 서비스</StyledTD>
                      <StyledTD>환자 증상 설문 데이터 기반의 진료</StyledTD>
                      <StyledTD>
                        1) 환자정보
                        <br />
                        - 환자 이름, 생년월일, 성별, 휴대전화번호, 이메일
                        <br />
                        2) 증상정보
                        <br />
                        - 증상 설문 입력 정보
                        <br />
                        3) 한의사정보
                        <br />
                        - 한의원, 한의사 이름, 이메일
                        <br />
                        4) 처방정보
                        <br />- 증상/변증, 처방, 치료
                      </StyledTD>
                    </StyledTR>
                    <StyledTR>
                      <StyledTD>처방 피드백 서비스</StyledTD>
                      <StyledTD>환자 처방에 대한 피드백 수집</StyledTD>
                      <StyledTD>
                        1) 환자정보
                        <br />
                        - 환자 이름, 생년월일, 성별, 휴대전화번호
                        <br />
                        2) 처방 피드백 정보
                        <br />
                        - 처방에 대한 환자 피드백 입력 정보
                        <br />
                      </StyledTD>
                    </StyledTR>
                  </Table>
                  <Text>
                    {' '}
                    3) “회사”와 “회원”은 본 약관상의 의무를 이행함에 있어 관련 법령을 준수하여야 합니다.
                  </Text>
                </Flex>
              </li>
              <li>
                “회원”은 “회사”에 대하여 다음 각 호의 사항을 관리하도록 요구할 수 있으며, “회사”는 특별한
                사유가 없는 한 이에 응하여야 합니다.
                <Flex direction={'column'} gap={0}>
                  <Text>1) 개인정보의 처리 현황</Text>
                  <Text>2) 개인정보의 접근 또는 접속현황</Text>
                  <Text>3) 개인정보 접근 또는 접속 대상자</Text>
                  <Text>4) 목적 외 이용, 제공 및 재위탁 금지 준수여부</Text>
                  <Text>5) 암호화 등 안전성 확보조치 이행여부</Text>
                  <Text>6) 그 밖에 개인정보의 보호를 위하여 필요한 사항</Text>
                </Flex>
              </li>
              <li>
                “회원”은 “회사”에 대하여 제1항 각 호의 사항에 대한 실태를 점검하여 시정을 요구할 수 있으며,
                “회사”은 특별한 사유가 없는 한 이행하여야 합니다.
              </li>
              <li>
                ”회원”은 처리 위탁으로 인하여 정보주체의 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지
                아니하도록 1년에 1회 “회사”를 교육할 수 있으며, “회사”는 이에 응하여야 합니다.
              </li>
              <li>제1항에 따른 교육의 시기와 방법 등에 대해서는 “회원”은 “회사”와 협의하여 시행합니다.</li>
            </StyledOLTag>
          </>
        }
      />

      <PolicyDetailLayout
        title={'제 8 조 (“결제 서비스”의 제공)'}
        content={
          <>
            <StyledOLTag>
              <li>"회사"는 "회원"의 업무 편의를 위해 결제 서비스를 제공합니다.</li>
              <li>
                "회원"은 서비스 이용을 위하여 "회사"와 제휴된 중계결제시스템업자(이하 "결제시스템업자")의
                가맹계약이 되어야 합니다.
              </li>
              <li>
                "회사"는 "결제시스템업자"의 가맹점 모집인으로 "회원"이 결제 서비스를 이용하도록
                "결제시스템업자"와의 가맹점 계약을 중계합니다.
              </li>
              <li>
                "회사"는 보다 향상된 서비스 제공을 위하여 "결제시스템업자"를 변경할 수 있으며 "회원"은 이에
                동의하여야 서비스를 이용할 수 있습니다.
              </li>
              <li>
                "회사"는 "회원"이 "결제시스템업자"와의 가맹점 계약을 통하여 발급된 CAT-ID를 통하여 "회원"에게
                결제 중계서비스를 제공합니다.
              </li>
              <li>"회사"는 "회원"의 전표 매입처리를 "결제시스템업자"에게 위탁 받아 처리합니다.</li>
              <li>
                "회원"은 "회사"가 원활한 서비스 제공을 위하여 "회원"의 매출 실적 등 데이터를
                "결제시스템업자"에게 요구할 수 있습니다.
              </li>
              <li>"회원"은 환자의 모바일 결제 요청을 거부할 수 있으며 현장 수납을 요청할 수 있습니다.</li>
              <li>
                "회원"은 카드사의 결제 승인된 수납건을 최초 1회 취소 요청할 수 있으며, 취소 후에는 결제 승인을
                요청할 수 없습니다.
              </li>
              <li>
                "회사"는 중계서비스 제공사로 "회원"의 귀책으로 인하여 발생하는 수납금액의 차이는 "회원"이
                해결하여야 합니다.
              </li>
              <li>
                시스템 장애로 인하여 결제 실패가 된 경우에는 시스템 정상화가 된 시점에 결제를 진행할 수
                있습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 9 조 (“서비스”의 변경)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부
                “서비스”를 본 계약에서 정한 범위 내에서 변경할 수 있습니다.
              </li>
              <li>
                “서비스”의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의
                내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.
              </li>
              <li>
                “회사”는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단,
                변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 “회원”에게 별도의 보상을 하지
                않습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제10조 (회원탈퇴 및 자격상실 등)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”은 언제든지 고객센터를 통하여 이용계약 탈퇴 및 신청을 할 수 있으며, “회사”는 관련법 등이
                정하는 바에 따라 이를 즉시 처리하여야 합니다
              </li>
              <li>
                “회원”이 다음 각 호의 사유에 해당하는 경우, “회사”는 회원자격을 제한 및/또는 정지시킬 수
                있습니다.
              </li>
              <li>
                “회원”이 제15조 제1항 각 호의 행위를 한 경우
                <Flex direction={'column'} gap={0}>
                  <Text>
                    {' '}
                    1) 다른 “회원”의 “서비스” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를
                    위협하는 경우
                  </Text>
                  <Text>
                    2) “회원”이 “회사”를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는
                    경우
                  </Text>
                  <Text>
                    3) “회원”이 본조 2항을 위반하여 “회사”가 회원 자격을 제한∙정지시킨 후에 동일한 행위가 2회
                    이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우, “회사”는 해당 “회원”의
                    회원자격을 상실시킬 수 있습니다.
                  </Text>
                </Flex>
              </li>
              <li>
                “회사”가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 “회원”에게 이를
                통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다. 만약
                “회원”이 통지를 받은 날로부터 30일 내에 소명하고, 그 내용이 정당하다고 “회사”가 인정하는 경우
                “회사”는 “회원”의 회원자격을 원상 회복할 수 있습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 11 조 (회원정보의 변경)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”은 서비스 내 페이지 화면을 통하여 언제든지 본인의 정보를 열람하고 수정할 수 있습니다.
                다만, 서비스 관리를 위해 필요한 실명, 아이디 등은 수정이 불가능합니다.
              </li>
              <li>
                “회원”은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타
                방법으로 “회사”에 대하여 그 변경사항을 알려야 합니다.
              </li>

              <li>
                제2항의 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 12 조 (“회원”의 “계정” 및 “비밀번호”의 관리에 대한 의무)'}
        content={
          <>
            <StyledOLTag>
              <li>
                ”회원”의 계정은 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는
                고객센터를 통하여 변경하여야 합니다. “회원”의 계정과 비밀번호에 관한 관리책임은 “회원”에게
                있습니다.
              </li>
              <li>
                “회사”는 “회원”의 계정이 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나
                “회사” 및 “회사”의 운영자로 오인할 우려가 있는 경우, 해당 회원 계정 이용을 제한할 수 있습니다.
              </li>

              <li>
                “회원”은 계정 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시
                “회사”에 통지하고 “회사”의 안내에 따라야 합니다.
              </li>
              <li>
                제3항의 경우에 해당 “회원”이 “회사”에 그 사실을 통지하지 않거나, 통지한 경우에도 “회사”의
                안내에 따르지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />

      <PolicyDetailLayout
        title={'제 13 조 (개인정보보호 의무)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 “개인정보보호법” 및 관련 시행령 및 “정보통신망 이용촉진 및 정보보호 등에 관한 법률”
                등 관계 법령이 정하는 바에 따라 “회원”의 개인정보를 보호하기 위해 노력합니다.
              </li>
              <li>개인정보의 보호 및 사용에 대해서는 관련법 및 “회사”의 개인정보처리방침이 적용됩니다.</li>

              <li>
                “회사”의 공식 사이트 이외의 링크된 사이트에서는 “회사”의 개인정보처리방침이 적용되지 않습니다.
              </li>
              <li>
                “회사”는 “회원”의 정보 수집 시 반드시 당해 “회원”의 동의를 얻어 필요한 최소한의 정보만을
                수집하며, “회원”은 온라인을 통해서 수시로 본인의 신상정보를 열람, 정정 또는 삭제할 수
                있습니다.
              </li>
              <li>
                “회사”가 제3자에게 “회원”의 개인정보를 제공할 필요가 있는 경우 다음 각호의 내용을 알리고
                동의를 받습니다.
                <Flex direction={'column'} gap={0}>
                  <Text> 1) 개인정보를 제공받는 자</Text>
                  <Text>2) 개인정보를 제공받는 자의 개인정보 이용 목적</Text>
                  <Text>3) 제공하는 개인정보의 항목</Text>
                  <Text>4) 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간</Text>
                  <Text>
                    5) 동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는 경우에는 그 불이익의
                    내용
                  </Text>
                </Flex>
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 14 조 (“회원”에 대한 통지)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”가 “회원”에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 페이지,
                전자우편주소, 전자쪽지 등으로 할 수 있습니다.
              </li>
              <li>
                “회사”는 “회원” 전체에 대한 통지의 경우 7일 이상 “회사”의 게시판에 게시함으로써 제1항의 통지에
                갈음할 수 있습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 15 조 (“회사”의 의무)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고
                안정적으로 “서비스”를 제공하기 위하여 최선을 다하여 노력합니다.
              </li>
              <li>
                “회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해
                보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
              </li>
              <li>
                “회사”는 “서비스” 이용과 관련하여 발생하는 “회원”의 불만 또는 피해구제요청을 적절하게 처리할
                수 있도록 필요한 인력 및 시스템을 구비합니다.
              </li>
              <li>
                “회사”는 “서비스” 이용과 관련하여 “회원”으로부터 제기된 의견이나 불만이 정당하다고 인정할
                경우에는 이를 처리하여야 합니다. “회원”이 제기한 의견이나 불만사항에 대해서는 게시판을
                활용하거나 전자우편 등을 통하여 “회원”에게 처리과정 및 결과를 전달합니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 16 조 (“회원”의 의무)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”은 다음 행위를 하여서는 안 됩니다.
                <Flex direction={'column'} gap={0}>
                  <Text> 1) 청 또는 변경 시 허위내용의 등록</Text>
                  <Text>2) 3자의 정보 도용</Text>
                  <Text> 3) “회사”가 게시한 정보의 변경</Text>
                  <Text>4) “회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</Text>
                  <Text>5) “회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해</Text>
                  <Text>6) “회사” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</Text>
                  <Text>
                    7) 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “서비스”에 공개
                    또는 게시하는 행위
                  </Text>
                  <Text>8) 회사의 동의 없이 영리를 목적으로 “서비스”를 사용하는 행위</Text>
                  <Text>9) 기타 불법적이거나 부당한 행위</Text>
                </Flex>
              </li>
              <li>
                “회원”은 관계법, 이 약관의 규정, 이용안내 및 “서비스”와 관련하여 공지한 주의사항, “회사”가
                통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다.
                또한, 회원은 연락처, 전자우편 주소 등 이용계약사항이 변경된 경우에 해당 절차를 거쳐 이를
                회사에 즉시 알려야 합니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 17 조 (통계정보의 가공과 제공)'}
        content={
          <>
            “회사”는 “회원”으로부터 수집한 병원 정보 및 서비스 이용기록을 통계학적 방법으로 정리, 분석하여
            “통계 자료”로만 가공하며, 가공한 ‘통계 자료’의 저작권 및 소유권, 기타 모든 지적재산권은 모두
            ‘회사’가 소유합니다.
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 18 조 (통계 분석 자료의 제공)'}
        content={
          <>
            <StyledOLTag>
              <li>
                제 17조 각 호에 해당하는 “통계 자료”는 “회원” 또는 다음 각 호에 해당하는 자에게 제공되어
                활용될 수 있습니다.
                <Flex direction={'column'} gap={0}>
                  <Text> 1) 요양기관</Text>
                  <Text> 2) 제약사/원외탕전원</Text>
                  <Text> 3) 학계</Text>
                  <Text> 4) 공공기관</Text>
                  <Text> 5) 기타 일반 기업</Text>
                </Flex>
              </li>
              <li>
                “회사”는 가공된 ‘통계 자료’를 사용한 암호화, IP암호화, 서비스 접속 암호화를 통해 보호합니다.
              </li>
              <li>
                “회사”는 “통계 자료”를 사업의 목적으로 제 3자에게 제공할 수 있습니다. 단, 인위적 방법을 통한
                가공 이전의 정보는 “회원”들의 동의 없이 제 3자에게 제공하지 아니합니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 19 조 (정보의 제공 및 광고의 게재)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편
                등의 방법으로 “회원”에게 제공할 수 있습니다. 다만, “회원”은 관련법에 따른 거래관련 정보 및
                고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
              </li>
              <li>
                제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 “회원”의 사전 동의를
                받아서 전송합니다. 다만, “회원”의 서비스 관련된 내용은 제외됩니다.
              </li>
              <li>
                “회사”는 “서비스”의 운영과 관련하여 서비스 화면, 홈페이지, 어플리케이션, 전자우편 등에 광고를
                게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 “회원”은 수신거절을 “회사”에게 할 수
                있습니다.
              </li>
              <li>
                ”회원”은 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의
                조치를 취하지 않습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 20 조 (“게시물”의 저작권)'}
        content={
          <>
            <StyledOLTag>
              <li>“회원”이 “서비스” 내에 게시한 “게시물”의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</li>
              <li>
                “회원”이 “서비스” 내에 게시하는 “게시물”은 검색결과 내지 “서비스” 및 관련 프로모션 등에 노출될
                수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다.
                이 경우, 회사는 저작권법 규정을 준수하며, “회원”은 언제든지 고객센터 또는 “서비스” 내
                관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
              </li>
              <li>
                “회사”는 제2항 이외의 방법으로 “회원”의 “게시물”을 이용하고자 하는 경우에는 사전에 “회원”에게
                고지합니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 21 조 (“게시물”의 관리)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”의 “게시물”이 “정보통신망법”, “저작권법”, ”의료법” 등 관련법에 위반되는 내용을 포함하는
                경우, ”회사”는 관련법이 정한 절차에 따라 해당 “게시물”의 게시중단 및 삭제 등을 요청할 수
                있으며, “회사”는 관련법에 따라 조치를 취하여야 합니다.
              </li>
              <li>
                “회사”는 전항에 따른 권리자의 요청이 없는 경우라도 아래 규정과 같이 권리침해가 인정될 만한
                사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 “게시물”에 대해
                임시조치 등을 취할 수 있습니다.
                <Flex direction={'column'} gap={0}>
                  <Text> 1) 다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우</Text>
                  <Text> 2) 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크 시키는 경우</Text>
                  <Text> 3) 불법복제 또는 해킹을 조장하는 내용인 경우</Text>
                  <Text> 4) 영리를 목적으로 하는 광고일 경우</Text>
                  <Text> 5) 범죄와 결부된다고 객관적으로 인정되는 내용일 경우</Text>
                  <Text> 6) 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우</Text>
                  <Text>
                    {' '}
                    7) 사적인 정치적 판단이나 종교적 견해의 내용으로 회사가 서비스 성격에 부합하지 않는다고
                    판단하는 경우
                  </Text>
                  <Text> 8) 타인의 건강을 위해 하는 검증되지 않은 허위정보로 판단될 경우</Text>
                  <Text> 9) 의료인 및 국내 의료기관 간의 비교 광고 성격으로 판단될 경우</Text>
                  <Text> 10) 허가 받지 않은 의료행위 또는 의료행위를 위한 선전으로 게시물을 게재할 경우</Text>
                  <Text> 11) 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우</Text>
                  <Text> 12) 기타 관계법령에 위배된다고 판단되는 경우 </Text>
                </Flex>
              </li>
              <li>
                본 조에 따른 세부절차는 “정보통신망법” 및 “저작권법”이 규정한 범위 내에서 “회사”가 정한 “게시
                중단 요청 서비스”에 따릅니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 22 조 (권리의 귀속)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “서비스”에 대한 저작권 및 지적재산권은 “회사”에 귀속됩니다. 단, “회원”의 “게시물” 및
                제휴계약에 따라 제공된 저작물 등은 제외합니다.
              </li>
              <li>
                “회사”는 서비스와 관련하여 “회원”에게 “회사”가 정한 이용조건에 따라 계정, “아이디”, 콘텐츠
                등을 이용할 수 있는 이용권만을 부여하며, “회원”은 이를 양도, 판매, 담보제공 등의 처분행위를 할
                수 없습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 23 조 (계약해제, 해지 등)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”은 언제든지 “서비스” 초기화면의 고객센터 또는 내 정보 관리 메뉴 등을 통하여 이용계약
                해지 신청을 할 수 있으며, “회사”는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
              </li>
              <li>
                “회원”이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 “회사”가 회원정보를 보유하는
                경우를 제외하고는 해지 즉시 “회원”의 모든 데이터는 소멸됩니다. 단, 비식별화 된 데이터는 통계의
                목적으로 이용할 수 있습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 24 조 (손해배상)'}
        content={
          <>
            “회사” 또는 “회원”이 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우에는 이를
            배상하여야 합니다. 단, “회사”는 무료로 제공하는 서비스의 이용과 관련하여 개인정보보호정책에서
            정하는 내용이나 기타 관련 법령에 위반하지 않는 한 손해 배상 책임을 지지 않습니다.
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 25 조 (이용제한 등)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 “회원”이 이 약관의 의무를 위반하거나 “서비스”의 정상적인 운영을 방해한 경우, 경고,
                일시정지, 영구이용정지 등으로 “서비스” 이용을 단계적으로 제한할 수 있습니다.
              </li>
              <li>
                “회사”는 전항에도 불구하고, “주민등록법”을 위반한 명의도용 및 결제도용, “저작권법” 및 “컴퓨터
                프로그램 보호법”을 위반한 불법프로그램의 제공 및 운영방해, “정보통신망법”을 위반한 불법통신 및
                해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시
                영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 “서비스” 이용을 통해 획득한 혜택
                등도 모두 소멸되며, “회사”는 이에 대해 별도로 보상하지 않습니다.
              </li>
              <li>
                “회사”는 “회원”이 계속해서 특별한 사유 없이 3개월 이상 로그인하지 않는 경우, 회원정보의 보호
                및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
              </li>
              <li>
                “회사”는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별
                서비스상의 운영정책에서 정하는 바에 의합니다.
              </li>
              <li>
                본 조에 따라 “서비스” 이용을 제한하거나 계약을 해지하는 경우에는 “회사”는 제9조 “회원”에 대한
                통지에 따라 통지합니다.
              </li>
              <li>
                “회원”은 본 조에 따른 이용제한 등에 대해 “회사”가 정한 절차에 따라 이의신청을 할 수 있습니다.
                이 때 이의가 정당하다고 “회사”가 인정하는 경우 “회사”는 즉시 “서비스”의 이용을 재개합니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 26 조 (책임제한)'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는
                “서비스” 제공에 관한 책임이 면제됩니다.
              </li>
              <li>“회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여는 책임을 지지 않습니다.</li>
              <li>
                “회사”는 “회원”이 “서비스”와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에
                관하여는 책임을 지지 않습니다.
              </li>
              <li>
                “회사”는 “회원” 간 또는 “회원”과 제3자 상호간에 “서비스”를 매개로 하여 거래 등을 한 경우에는
                책임이 면제됩니다.
              </li>
              <li>
                “회사”는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지
                않습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'제 27 조 (준거법 및 재판관할)'}
        content={
          <>
            <StyledOLTag>
              <li>“회사”와 “회원” 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
              <li>
                “회사”와 “회원” 간 발생한 분쟁에 관한 소송은 제소 당시의 “회원”의 주소에 의하고, 주소가 없는
                경우 거소를 관할하는 지방법원의 전속관할로 합니다.
                <br /> 단, 제소 당시 “회원”의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에
                따라 정합니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'부칙'}
        content={
          <>
            <StyledOLTag>
              <li>이 약관은 2022년 10월 14일부터 적용됩니다.</li>
              <li>
                “회사”는 약관을 변경하는 경우에 “회원”이 그 변경 여부, 변경된 사항의 시행시기와 변경된 내용을
                언제든지 쉽게 알 수 있도록 지속적으로 “서비스”를 통하여 공개합니다. 이 경우에 변경된 내용은
                변경 전과 후를 비교하여 공개합니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
    </Flex>
  );
};

export default ServicePolicy;
