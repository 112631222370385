import React from 'react';
import { TypographyText, Col } from 'components/UI/atoms';
import { TableCell } from 'components/UI/atoms/table/TableCell';

const ScaleTd = ({ symptom }: { symptom: any }) => {
  return (
    <TableCell
      style={{
        borderLeft: 'none',
        borderRight: 'none',
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      {symptom?.value?.scale >= 0 ? (
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: '0.5rem',
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/survey_icon_${symptom.value.scale}.webp`}
            style={{ width: '1.2rem' }}
            alt={`${symptom.code}${symptom.value.scale}`}
          />
          <TypographyText yjTypeTypographyText="symptom-table-body">{symptom.value.scale}</TypographyText>
        </Col>
      ) : (
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></Col>
      )}
    </TableCell>
  );
};

export default React.memo(ScaleTd);
