import { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { css } from '@emotion/css';
import message from 'antd-message';
import PhoneInput from 'react-phone-number-input/input';

import { Row, Col, TypographyText, Input, ModalType2, Modal } from 'components/UI/atoms';
import { Gender } from 'API';
import { useStores } from 'stores/Context';
import { Loading } from 'components/UI/molecules';
import { API, Auth } from 'aws-amplify';
import { phoneValidationCheck } from 'utils/util';
import DaumPost from 'components/UI/molecules/DaumPost';

const CreatePatientModal = ({ setIsOpenCreatePatientModal, setRefreshFlag }) => {
  const { clientStore } = useStores();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isSendKaKaoBtnClicked, setIsSendKaKaoBtnClicked] = useState(true);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [address, setAddress] = useState<string>('');
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [isOpenDetailAddressConfirmModal, setIsOpenDetailAddressConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [birth, setBirth] = useState('1990-01-01');
  const [gender, setGender] = useState<string>(Gender.MALE);
  const [validationFlag, setValidationFlag] = useState({
    name: undefined,
    birth: true,
    phone: undefined,
  });
  const [phone, setPhone] = useState('');

  const createPatient = useCallback(
    async (
      clinicID: string,
      patientName: string,
      birth: string,
      gender: string,
      phone: string,
      address: string,
    ) => {
      const response = await API.post('yejinadminrestapi', '/patient', {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        response: true,
        body: {
          clinicID: clinicID,
          patientName: patientName,
          birth: birth,
          gender: gender,
          phone: phone?.replace('+82', '0') ?? phone,
          address,
        },
      });
      return response;
    },
    [],
  );

  const sendSurveyApi = useCallback(async (patientID: string, clinicID: string) => {
    await API.post('yejinadminrestapi', '/sendSurvey', {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
      body: {
        clinicID: clinicID,
        patientID: patientID,
      },
    });
  }, []);

  const createPatientHandler = async ({ kakaoSendFlag }) => {
    try {
      setIsLoading(true);
      const addPatientResult = await createPatient(
        clientStore.metaData?.userRole.clinic?.id!,
        name,
        birth,
        gender,
        phone,
        `${address}${detailAddress ? `||${detailAddress}` : ''}`,
      );
      if (kakaoSendFlag) {
        await sendSurveyApi(addPatientResult?.data?.result?.id, clientStore.metaData?.userRole.clinic?.id!);
      }
      setIsOpenCreatePatientModal(false);
      message.success(`${name}님의 환자 등록이 완료되었습니다.`, 7500);
      setRefreshFlag(true);
    } catch (error) {
      message.error(`알 수 없는 에러 발생`, 2500);
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const labelWidth = useMemo(() => 9, []);
  const inputWidth = useMemo(() => 20, []);

  return (
    <ModalType2
      title="환자등록"
      isOpen={true}
      okMsg="등록하기"
      okFunction={() => {
        setIsSubmitClicked(true);
        if (validationFlag.name && validationFlag.birth && validationFlag.phone) {
          if (address && !detailAddress) {
            // 상세주소가 없을 때
            setIsOpenDetailAddressConfirmModal(true);
          } else {
            createPatientHandler({ kakaoSendFlag: isSendKaKaoBtnClicked });
          }
        }
      }}
      cancelMsg="닫기"
      cancelFunction={() => {
        setIsOpenCreatePatientModal(false);
      }}
      width={42}
      contentHeight="510px"
      content={
        <Row>
          {isLoading && <Loading mode="global" />}
          <Row flex flexDirection={'column'} style={{ rowGap: '0.95rem' }}>
            <Row flex alignItems="center">
              <Col flexFlag style={{ width: `${labelWidth}rem`, position: 'relative' }}>
                <TypographyText fontWeight={700} letterSpacing="0.02rem">{`환자 이름*`}</TypographyText>
              </Col>
              <Col>
                <Input
                  style={{
                    height: '2.8rem',
                    width: `${inputWidth}rem`,
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    textIndent: '0.4rem',
                    fontSize: '1rem',
                    outline: 'none',
                  }}
                  className={css`
                    &::placeholder {
                      color: #afafaf;
                    }
                  `}
                  value={name}
                  placeholder="환자 이름"
                  onChange={(e) => {
                    if (e.target.value?.length) {
                      setValidationFlag({
                        ...validationFlag,
                        name: true,
                      });
                    } else {
                      setValidationFlag({
                        ...validationFlag,
                        name: undefined,
                      });
                    }
                    setName(e.target.value);
                  }}
                  autoComplete="off"
                />
              </Col>
            </Row>
            {isSubmitClicked && !validationFlag.name && (
              <Row style={{ marginLeft: `${labelWidth}rem`, marginTop: '-1.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  환자 이름을 입력해주세요.
                </TypographyText>
              </Row>
            )}
            <Row flex alignItems="center">
              <Col flexFlag style={{ width: `${labelWidth}rem` }}>
                <TypographyText fontWeight={700} letterSpacing="0.02rem">{`생년월일*`}</TypographyText>
              </Col>
              <Col>
                <input
                  autoComplete="off"
                  style={{
                    outline: 'none',
                    width: `${inputWidth}rem`,
                    height: '2.8rem',
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    textIndent: '0.35rem',
                    paddingRight: '0.7rem',
                  }}
                  value={birth}
                  max={moment().format('YYYY-MM-DD')}
                  type={'date'}
                  onChange={(e) => {
                    if (e.target.value?.length) {
                      if (e.target.value > moment().format('YYYY-MM-DD')) {
                        setValidationFlag({
                          ...validationFlag,
                          birth: false,
                        });
                      } else {
                        setValidationFlag({
                          ...validationFlag,
                          birth: true,
                        });
                      }
                    } else {
                      setValidationFlag({
                        ...validationFlag,
                        birth: undefined,
                      });
                    }
                    setBirth(e.target.value);
                  }}
                />
              </Col>
            </Row>
            {isSubmitClicked && !validationFlag.birth && (
              <Row style={{ marginLeft: `${labelWidth}rem`, marginTop: '-1.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {validationFlag.birth === false
                    ? `${moment().format('YYYY-MM-DD')} 이전의 생년월일을 입력해주세요.`
                    : `생년월일을 입력해주세요.`}
                </TypographyText>
              </Row>
            )}
            <Row flex alignItems="center">
              <Col flexFlag style={{ width: `${labelWidth}rem` }}>
                <TypographyText fontWeight={700} letterSpacing="0.02rem">{`성별*`}</TypographyText>
              </Col>
              <Col>
                <select
                  style={{
                    height: '2.8rem',
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    textIndent: '1rem',
                    outline: 'none',
                    width: `${inputWidth}rem`,
                    cursor: 'pointer',
                  }}
                  className={css`
                    appearance: none;
                    background: url(${process.env.PUBLIC_URL}/img/select-arrow-icon.webp) no-repeat right 1rem
                      center;
                    background-size: 0.6rem;
                  `}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value={Gender.MALE}>남성</option>
                  <option value={Gender.FEMALE}>여성</option>
                </select>
              </Col>
            </Row>
            <Row flex alignItems="center">
              <Col flexFlag style={{ width: `${labelWidth}rem` }}>
                <TypographyText fontWeight={700} letterSpacing="0.02rem">{`전화번호*`}</TypographyText>
              </Col>
              <Col>
                <PhoneInput
                  style={{
                    height: '2.8rem',
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    textIndent: '1rem',
                    outline: 'none',
                    width: `${inputWidth}rem`,
                  }}
                  country={'KR'}
                  placeholder={'휴대 전화번호 (숫자만 입력)'}
                  value={phone}
                  onChange={(e) => {
                    if (!phoneValidationCheck(e?.replace('+82', '0'))) {
                      setValidationFlag({
                        ...validationFlag,
                        phone: false,
                      });
                    } else {
                      setValidationFlag({
                        ...validationFlag,
                        phone: true,
                      });
                    }
                    setPhone(e);
                  }}
                />
              </Col>
            </Row>
            {isSubmitClicked && !validationFlag.phone && (
              <Row style={{ marginLeft: `${labelWidth}rem`, marginTop: '-1.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {validationFlag.phone === false
                    ? `휴대 전화번호 양식으로 입력해주세요.`
                    : `전화번호를 입력해주세요.`}
                </TypographyText>
              </Row>
            )}
            <Row flex alignItems="center">
              <Col flexFlag style={{ width: `${labelWidth}rem` }}>
                <TypographyText fontWeight={700} letterSpacing="0.02rem">{`주소(선택)`}</TypographyText>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '20rem',
                  height: '2.8rem',
                  border: '1px solid #afafaf',
                  background: '#f2f2f2',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  borderRadius: '3px',
                  marginRight: '0.5rem',
                }}
              >
                <TypographyText color={`${address ? '#1d1c1d' : '#AFAFAF'}`} lineHeight="1.2em">
                  {address || '주소 찾기 버튼을 클릭해 주세요.'}
                </TypographyText>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  columnGap: '0.25rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '8rem',
                  height: '2.8rem',
                  border: '1px solid #afafaf',
                  borderColor: '#afafaf',
                  borderRadius: '3px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsOpenAddressModal(true);
                }}
              >
                <Col flexFlag>
                  <TypographyText
                    yjTypeTypographyText="sub-title"
                    fontWeight={700}
                    letterSpacing="0.02rem"
                    fontSize={'1rem'}
                    lineHeight="1.2em"
                  >
                    {address ? '주소 재검색' : '주소 찾기'}
                  </TypographyText>
                </Col>
                <Col flexFlag>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/search-black-icon.webp`}
                    style={{ width: '1rem' }}
                    alt="search-black-icon"
                  />
                </Col>
              </Col>
            </Row>
            <Col
              style={{
                marginLeft: `${labelWidth}rem`,
                marginTop: '-0.7rem',
              }}
            >
              <Input
                disabled={!address}
                style={{
                  height: '2.8rem',
                  width: `${inputWidth}rem`,
                  border: '1px solid #afafaf',
                  borderRadius: '3px',
                  textIndent: '0.4rem',
                  fontSize: '1rem',
                  outline: 'none',
                }}
                className={css`
                  &::placeholder {
                    color: #afafaf;
                  }
                `}
                value={detailAddress}
                placeholder="상세 주소"
                onChange={(e) => {
                  setDetailAddress(e.target.value);
                }}
                autoComplete="off"
              />
            </Col>
            {/* <Row flex style={{ marginLeft: `${labelWidth}rem`, marginTop: '-0.7rem' }}>
              <TypographyText fontSize={'0.8rem'} lineHeight="1.2em" color="#afafaf">
                {'해당 주소는 환자 주소로 약재 주문 시, 조제된 약재가 배송될 주소입니다. '}
              </TypographyText>
            </Row> */}
            <Row flex style={{ width: 'fit-content', height: '1.7rem', marginTop: '-0.5rem' }}>
              <Col flexFlag style={{ width: `${labelWidth}rem` }} />
              <Col
                onClick={() => {
                  setIsSendKaKaoBtnClicked(!isSendKaKaoBtnClicked);
                }}
                yjTypeCol={isSendKaKaoBtnClicked ? 'selected-check-button' : 'check-button'}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Row yjTypeRow="flex">
                  <Col
                    yjTypeCol={
                      isSendKaKaoBtnClicked ? 'selected-survey-checkbox-wrapper' : 'survey-checkbox-wrapper'
                    }
                    style={{
                      margin: '0.3rem 0.5rem 0 0',
                      cursor: 'pointer',
                    }}
                  >
                    <Col
                      yjTypeCol={isSendKaKaoBtnClicked ? 'selected-survey-checkbox' : 'survey-checkbox'}
                    ></Col>
                  </Col>
                  <Col yjTypeCol="survey-checkbox-label" style={{ height: '1.2rem', cursor: 'pointer' }}>
                    <TypographyText fontWeight={700}>
                      {'(선택) 등록 후 바로 카카오 링크 전송하기'}
                    </TypographyText>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
          <ModalType2
            isOpen={isOpenAddressModal}
            cancelMsg="닫기"
            cancelFunction={() => setIsOpenAddressModal(false)}
            content={
              <Row style={{ marginBottom: '0' }}>
                <DaumPost setIsOpenAddressModal={setIsOpenAddressModal} setAddress={setAddress} />
              </Row>
            }
            contentNonePaddingFlag={true}
            width={'600px'}
            contentHeight={'445px'}
          />
          <Modal
            isOpen={isOpenDetailAddressConfirmModal}
            okMsg="네, 등록하겠습니다."
            cancelMsg="취소"
            okFunction={async () => {
              setIsOpenDetailAddressConfirmModal(false);
              createPatientHandler({ kakaoSendFlag: isSendKaKaoBtnClicked });
            }}
            cancelFunction={() => setIsOpenDetailAddressConfirmModal(false)}
            contentMsg={`상세주소가 입력되지 않았습니다. 
상세주소없이 환자등록을 완료하시겠습니까?`}
          />
        </Row>
      }
    />
  );
};

export default CreatePatientModal;
