import * as AmplifyUI from '@aws-amplify/ui-react';
import styled from '@emotion/styled';

export interface TextFieldParams extends AmplifyUI.TextFieldProps {
  onChange?: (e: any) => void;
}

const StyledTextField = styled(AmplifyUI.TextField)<TextFieldParams>`
  input {
    font-size: 0.9rem;
    border-radius: 3px;
    /* border-color: #afafaf; */
    border-color: #afafaf;
    height: 3.2rem;
  }
  input:focus {
    border-color: #1d1c1d;
  }
`;

export const TextField = ({ ...rest }: TextFieldParams) => {
  return <StyledTextField {...rest} />;
};
