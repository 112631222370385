import { Row, Col, TypographyText } from 'components/UI/atoms';

export const Term = ({
  isCheckedServiceTerm = false,
  isCheckedPrivacyTerm = false,
  isCheckedMarketingTerm = false,
  onChangeServiceTerm = () => {},
  onChangePrivacyTerm = () => {},
  onChangeMarketingTerm = () => {},
}: {
  isCheckedServiceTerm: boolean;
  isCheckedPrivacyTerm: boolean;
  isCheckedMarketingTerm: boolean;
  onChangeServiceTerm?: any;
  onChangePrivacyTerm?: any;
  onChangeMarketingTerm?: any;
}) => {
  return (
    <Row>
      <Row
        style={{
          display: 'flex',
          marginBottom: '1rem',
        }}
      >
        <Col
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.75rem',
          }}
          onClick={() => {
            if (isCheckedServiceTerm && isCheckedPrivacyTerm && isCheckedMarketingTerm) {
              onChangeServiceTerm(false);
              onChangePrivacyTerm(false);
              onChangeMarketingTerm(false);
            } else {
              onChangeServiceTerm(true);
              onChangePrivacyTerm(true);
              onChangeMarketingTerm(true);
            }
          }}
        >
          <Col
            style={{
              display: 'flex',
            }}
          >
            {isCheckedServiceTerm && isCheckedPrivacyTerm && isCheckedMarketingTerm ? (
              <img
                src={`${process.env.PUBLIC_URL}/img/checked-icon-blue.webp`}
                style={{ width: '1rem', cursor: 'pointer' }}
                alt={`checked`}
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/img/unchecked-icon.webp`}
                style={{ width: '1rem', cursor: 'pointer' }}
                alt={`unchecked`}
              />
            )}
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TypographyText fontWeight={700} fontSize="1rem" lineHeight="1rem">
              {'약관 전체동의'}
            </TypographyText>
          </Col>
        </Col>
      </Row>
      <Row
        style={{
          height: '1rem',
          borderTop: '1px solid #c4c4c4',
        }}
      />
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.9rem',
        }}
      >
        {[
          {
            label: '서비스 이용약관 (필수)',
            checkFlag: isCheckedServiceTerm,
            onChange: onChangeServiceTerm,
            termLink: '/policy',
          },
          {
            label: '개인정보 처리방침 (필수)',
            checkFlag: isCheckedPrivacyTerm,
            onChange: onChangePrivacyTerm,
            termLink: '/policy/1',
          },
          {
            label: '마케팅 활용 동의 (선택)',
            checkFlag: isCheckedMarketingTerm,
            onChange: onChangeMarketingTerm,
            termLink: '/policy/2',
          },
        ].map((term, i) => (
          <Row
            key={i}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '0.75rem',
                cursor: 'pointer',
              }}
              onClick={() => term.onChange(!term.checkFlag)}
            >
              <Col
                style={{
                  display: 'flex',
                }}
              >
                {term.checkFlag ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/img/checked-icon-blue.webp`}
                    style={{ width: '1rem', cursor: 'pointer' }}
                    alt={`checked`}
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/img/unchecked-icon.webp`}
                    style={{ width: '1rem', cursor: 'pointer' }}
                    alt={`unchecked`}
                  />
                )}
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TypographyText fontWeight={700} fontSize="0.8rem" lineHeight="0.8rem">
                  {term.label}
                </TypographyText>
              </Col>
            </Col>
            <Col
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                columnGap: '0.4rem',
              }}
              onClick={() => window.open(term.termLink, '_blank')}
            >
              <TypographyText fontSize="0.8rem" lineHeight="1.1rem">{`전체보기`}</TypographyText>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={`${process.env.PUBLIC_URL}/img/>.webp`}
                  style={{ width: '0.35rem', cursor: 'pointer' }}
                  alt={`>`}
                />
              </Col>
            </Col>
          </Row>
        ))}
      </Row>
    </Row>
  );
};
