/* eslint-disable react-hooks/exhaustive-deps */
import { API, Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Loading } from 'components/UI/molecules';
import { TypographyText, Row, Modal } from 'components/UI/atoms';
import message from 'antd-message';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores/Context';

const ClinicManagement = ({ role }) => {
  const [isOpenWithdrawalModal, setIsOpenWithdrawalModal] = useState(false);
  const [isOpenWithdeleteModal, setIsOpenWithdeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(true);
  const { clinicStore, clientStore, roleStore } = useStores();
  const navigate = useNavigate();

  const refeshData = useCallback(async () => {
    try {
      await setIsGlobalLoading(true);
      await Promise.all([
        clinicStore.getClinic(clientStore.metaData?.userRole.clinicID, false),
        roleStore.getUserRole(clientStore.metaData?.userRole?.id),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsGlobalLoading(false);
    }
  }, []);

  const masterFlag = useMemo(() => role === 'MASTER', [role]);

  useEffect(() => {
    refeshData();
  }, []);

  const deleteClinic = useCallback(async () => {
    try {
      await API.del('yejinadminrestapi', `/clinic`, {
        headers: {
          authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      return {};
    } catch (e) {
      if (e?.message?.includes('512')) {
        message.error(
          `현재 서버 오류로 한의원 삭제가 불가합니다. 잠시 후 다시 시도해주세요.
※ 문제가 해결되지 않는 경우 고객센터(070-7782-7672)로 문의바랍니다.`,
          8000,
        );
      } else {
        message.error(
          `한의원 삭제 과정에서 문제가 발생했습니다. 잠시 후 다시 시도해주세요.
※ 문제가 해결되지 않는 경우 고객센터(070-7782-7672)로 문의바랍니다.`,
          8000,
        );
        console.log('Error deleting clinic', e);
      }
      return { error: e };
    }
  }, []);

  return (
    <>
      {isGlobalLoading ? (
        <Loading />
      ) : (
        <>
          <Row flex flexDirection="column" style={{ paddingTop: '2rem' }}>
            <Row flex flexDirection="column">
              <Row flex>
                <TypographyText fontSize="1.2rem" lineHeight="1.2em" fontWeight={700}>
                  {`${masterFlag ? `한의원 삭제하기` : `소속된 한의원 나가기`}`}
                </TypographyText>
              </Row>
              <Row
                flex
                style={{
                  marginTop: '1.4rem',
                  minWidth: '36rem',
                  width: 'fit-content',
                  padding: '1.4rem 1.2rem',
                  background: '#F2F2F2',
                }}
              >
                <TypographyText fontSize="0.8rem" lineHeight="1.12rem" whiteSpace="pre-wrap">
                  {masterFlag
                    ? `- 예진 서비스 내에 생성된 환자 리스트 및 진료 기록이 구성원 프로필이 모두 삭제됩니다.
- 처리가 완료되어도 등록된 개인 정보(이름/이메일)는 예진에 남아 있습니다. 
- 한의원 삭제 후 복구가 불가합니다.`
                    : `- 소속된 한의원에서 나가게 되어도 다시 초대 받은 후 로그인하면 서비스 접속이 가능합니다.
- 한의원 소속된 상태에서 다른 한의원에 초대를 받고 수락하면 한의원 소속이 변경됩니다.
- 한의원 나가기 후, 새로운 한의원 생성이 가능합니다.
- 예진 서비스 탈퇴를 원하시면 소속된 한의원 나가기 후 [예진 탈퇴하기] 버튼을 클릭해 주세요.`}
                </TypographyText>
              </Row>
              <Row
                style={{
                  marginTop: '1.25rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '2.2rem',
                  cursor: 'pointer',
                  borderRadius: '3px',
                  background: '#346AFF',
                  padding: '0.75rem 1rem',
                  width: 'fit-content',
                }}
                onClick={() => (masterFlag ? setIsOpenWithdeleteModal(true) : setIsOpenWithdrawalModal(true))}
              >
                <TypographyText color="#ffffff" fontWeight={700} fontSize="1rem" lineHeight="1rem">
                  {masterFlag ? `삭제하기` : `나가기`}
                </TypographyText>
              </Row>
            </Row>
          </Row>
          {isOpenWithdrawalModal && (
            <Modal
              contentMsg="소속된 한의원에서 나가시겠습니까?"
              cancelMsg="취소"
              okMsg="네, 나가겠습니다."
              confirmMsg="나가기 후 되돌릴 수 없음을 확인하였습니다."
              confirmFlag
              okFunction={async () => {
                try {
                  // userRole 삭제
                  await setIsOpenWithdrawalModal(false);
                  await setIsLoading(true);
                  await API.del('yejinadminrestapi', `/step/${clientStore.metaData?.userRole?.id}`, {
                    headers: {
                      authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
                    },
                    body: {
                      withdrawlFlag: true,
                    },
                  });
                  message.success(`한의원 나가기가 완료되었습니다.`, 8000);
                  navigate('/');
                } catch (e) {
                  console.log(e);
                  await setIsLoading(false);
                  message.error(`서비스 탈퇴 과정에서 문제가 발생했습니다.`, 2500);
                }
              }}
              cancelFunction={() => setIsOpenWithdrawalModal(false)}
            />
          )}
          {isOpenWithdeleteModal && (
            <Modal
              contentMsg={`한의원을 정말 삭제하시겠습니까?`}
              cancelMsg="취소"
              okMsg="네, 삭제하겠습니다."
              confirmMsg="삭제된 한의원은 되돌릴 수 없음을 확인하였습니다."
              confirmFlag
              okFunction={async () => {
                try {
                  await setIsOpenWithdeleteModal(false);
                  await setIsLoading(true);
                  const res = await deleteClinic();
                  if (res.error) {
                    throw new Error('Unable to delete clinic');
                  }
                  message.success(`한의원 삭제를 완료했습니다.`, 8000);
                  navigate('/');
                } catch (e) {
                  await setIsLoading(false);
                  console.log('Unable to refresh Token', e);
                }
              }}
              cancelFunction={() => setIsOpenWithdeleteModal(false)}
            />
          )}
          {isLoading && <Loading mode="global" />}
        </>
      )}
    </>
  );
};

export default observer(ClinicManagement);
