/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from 'react';
import { Text } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react';
import message from 'antd-message';
import { css } from '@emotion/css';
import _ from 'lodash';

import { Row, Col, TypographyText, ModalType2, PlusButton } from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import CommonPrescriptionOneColumnTable from 'components/UI/molecules/table/CommonPrescriptionOneColumnTable';

const PrescriptionPart = ({
  treatmentMode,
  formulaSearchKeyword,
  setFormulaSearchKeyword,
  selectedProcessingCodes,
  setSelectedProcessingCodes,
  zeroScoringTypeprescription,
  prescribedHerbs,
  setPrescribedHerbs,
  selectedPrescriptionData,
  setSelectedPrescriptionData,
  recommandedPrescriptionArr,
  isLoadingPresciptionList,
  isLoadingPresciptionListForZeroScoring,
  recommendedFormulaTab,
  setRecommendedFormulaTab,
  setIsOpenAddHerbMethodModal,
  pmData,
  setIsOpenPrescriptionInfoModal,
  setNeedConfirmationHerbsTemp,
  setDontNeedConfirmationHerbsTemp,
  setPrescribedHerbTableScrollTrigger,
  setSelectedMyPrescriptionData,
}) => {
  const { prescriptionStore } = useStores();
  const tabList = useMemo(() => {
    if (['GENERAL', undefined].includes(treatmentMode)) {
      return ['고방', '후세방'];
    } else {
      return ['고유', '사상', '변증'];
    }
  }, [treatmentMode]);

  return (
    <>
      <ModalType2
        isOpen={!_.isEmpty(pmData) && pmData?.hiddenModalFlag !== true}
        width={40}
        title={`${pmData.kn}${pmData.cn ? ` (${pmData.cn})` : ''}`}
        content={
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              rowGap: '1.25rem',
            }}
          >
            {pmData.summary && (
              <TypographyText fontSize="1rem" lineHeight="1.2em" whiteSpace="pre-wrap" wordBreak="keep-all">
                {pmData.summary}
              </TypographyText>
            )}
            {pmData?.source && (
              <table
                className="prescription-info-modal-table source-info-table"
                style={{ marginBottom: '-0.5rem' }}
              >
                <colgroup>
                  <col style={{ width: '50%' }} />
                  <col style={{ width: '50%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <TypographyText fontSize="0.8rem">{'출전'}</TypographyText>
                    </th>
                    <th>
                      <TypographyText fontSize="0.8rem">{'편명'}</TypographyText>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <TypographyText fontSize="0.9rem" whiteSpace="pre">
                        {pmData.source}
                      </TypographyText>
                    </td>
                    <td>
                      <TypographyText fontSize="0.9rem" whiteSpace="pre">
                        {pmData.part ?? '-'}
                      </TypographyText>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <table className="prescription-info-modal-table herb-info-table">
              <colgroup>
                <col style={{ width: '50%' }} />
                <col style={{ width: '50%' }} />
              </colgroup>
              <tbody>
                {
                  _.sortBy(pmData?.recipe, (e) => -e.amt)?.reduce(
                    (acc, e) => {
                      const herb = prescriptionStore.yejinHerbForSearchByProcessingCode.get(e.pc);
                      acc.components.push(
                        <tr key={e.pc}>
                          <td>
                            <TypographyText fontSize="0.9rem" whiteSpace="pre">
                              {herb.koreanName}
                            </TypographyText>
                          </td>
                          <td>
                            <TypographyText fontSize="0.9rem" whiteSpace="pre">
                              {e.amt / 1000}
                            </TypographyText>
                          </td>
                        </tr>,
                      );
                      return acc;
                    },
                    {
                      tdCount: 0,
                      components: [
                        <tr>
                          <th>
                            <TypographyText fontSize="0.8rem">{`약재명`}</TypographyText>
                          </th>
                          <th>
                            <TypographyText fontSize="0.8rem">{`1첩용량(g)`}</TypographyText>
                          </th>
                        </tr>,
                      ],
                    },
                  ).components
                }
              </tbody>
            </table>
          </Row>
        }
        okMsg={'처방하기'}
        okFunction={async () => {
          const duplicatedHerbs = {};
          const newPrescribedHerbs = {};
          pmData.recipe.forEach((e) => {
            if (prescribedHerbs[e.pc]) {
              duplicatedHerbs[e.pc] = {
                code: e.pc,
                name: prescriptionStore.yejinHerbForSearchByProcessingCode.get(e.pc).koreanName,
                amount: e.amt,
              };
            } else {
              newPrescribedHerbs[e.pc] = {
                code: e.pc,
                amount: e.amt,
              };
            }
          });
          if (_.isEmpty(prescribedHerbs)) {
            setSelectedPrescriptionData(pmData);
            setSelectedMyPrescriptionData({});
            setPrescribedHerbs(newPrescribedHerbs);
            setPrescribedHerbTableScrollTrigger(true);
            message.success(`<${pmData.kn}> 약재가 추가되었습니다.`, 2500);
            setIsOpenPrescriptionInfoModal({});
          } else {
            await setNeedConfirmationHerbsTemp(duplicatedHerbs);
            await setDontNeedConfirmationHerbsTemp(newPrescribedHerbs);
            await setIsOpenPrescriptionInfoModal({
              ...pmData,
              customHerbalFormulaData: undefined,
              hiddenModalFlag: true,
            });
            setIsOpenAddHerbMethodModal(true);
          }
        }}
        cancelFunction={() => setIsOpenPrescriptionInfoModal({})}
      />
      <CommonPrescriptionOneColumnTable
        searchComponentFlag
        defaultSearchValue={formulaSearchKeyword}
        searchComponentFunc={(value) => setFormulaSearchKeyword(value)}
        herbFilterArr={selectedProcessingCodes}
        setSelectedHerbs={setSelectedProcessingCodes}
        searchInputPlaceholder="방제사전명으로 검색 후 엔터를 눌러주세요."
        thName={`처방`}
        loading={isLoadingPresciptionList || isLoadingPresciptionListForZeroScoring}
        height="100%"
        tabList={tabList}
        currentTab={recommendedFormulaTab}
        tabFunc={setRecommendedFormulaTab}
        emptyText={`관련된 처방이 없습니다.`}
        scoringTypeprescription={recommandedPrescriptionArr}
        zeroScoringTypeprescription={zeroScoringTypeprescription} // 후세방 개수 파악용
        items={(recommendedFormulaTab === '후세방'
          ? [...recommandedPrescriptionArr?.[recommendedFormulaTab], ...zeroScoringTypeprescription]
          : recommandedPrescriptionArr?.[recommendedFormulaTab]
        )?.map((item) => {
          return {
            data: item,
            component: (
              <Row
                key={item.id}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  columnGap: '0.5rem',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.5rem',
                    flexShrink: 0,
                  }}
                >
                  {item.recommendFlag && (
                    <button
                      style={{
                        flexShrink: 0,
                        width: '1.85rem',
                        height: '0.95rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #52B4D2',
                        borderRadius: '100px',
                        color: '#52B4D2',
                        fontWeight: 700,
                        lineHeight: '0.95rem',
                        fontSize: '0.6rem',
                        whiteSpace: 'nowrap',
                        cursor: 'default',
                      }}
                    >
                      추천
                    </button>
                  )}
                  <Text
                    fontSize={'0.9rem'}
                    fontWeight={700}
                    lineHeight="1.2em"
                    whiteSpace={'pre-wrap'}
                    style={{ wordBreak: 'keep-all' }}
                    onClick={() => {
                      setIsOpenPrescriptionInfoModal({
                        ...item,
                        ...(prescriptionStore.yejinPrescriptionRef.get(item.id) ?? {}),
                      });
                    }}
                    className={css`
                      cursor: pointer !important;
                    `}
                  >
                    {`${item.kn}${item.cn ? ` ${item.cn}` : ''}${item.postfix ? ` ${item.postfix}` : ''}`}
                  </Text>
                </div>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.5rem',
                    flexShrink: 0,
                  }}
                >
                  {item.recommendFlag && item?.score > 0 && (
                    <button
                      style={{
                        width: '1.8rem',
                        height: '1.2rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#346AFF20',
                        borderRadius: '4px',
                        cursor: 'default',
                      }}
                    >
                      <TypographyText
                        color="#346AFF"
                        fontSize="0.7rem"
                        lineHeight="1.2em"
                        fontWeight={700}
                        whiteSpace="pre"
                      >
                        {_.round(item.score * 5)}
                      </TypographyText>
                    </button>
                  )}
                  <div className="w-1.2">
                    <PlusButton
                      onClick={async () => {
                        const duplicatedHerbs = {};
                        const newPrescribedHerbs = {};
                        item.recipe.forEach((e) => {
                          if (prescribedHerbs[e.pc]) {
                            duplicatedHerbs[e.pc] = {
                              code: e.pc,
                              name: prescriptionStore.yejinHerbForSearchByProcessingCode.get(e.pc).koreanName,
                              amount: e.amt,
                            };
                          } else {
                            newPrescribedHerbs[e.pc] = {
                              code: e.pc,
                              amount: e.amt,
                            };
                          }
                        });
                        if (_.isEmpty(prescribedHerbs)) {
                          setSelectedPrescriptionData(item);
                          setSelectedMyPrescriptionData({});
                          setPrescribedHerbs(newPrescribedHerbs);
                          setPrescribedHerbTableScrollTrigger(true);
                          message.success(`<${item.kn}> 약재가 추가되었습니다.`, 2500);
                          setIsOpenPrescriptionInfoModal({});
                        } else {
                          await setNeedConfirmationHerbsTemp(duplicatedHerbs);
                          setDontNeedConfirmationHerbsTemp(newPrescribedHerbs);
                          await setIsOpenPrescriptionInfoModal({
                            ...item,
                            hiddenModalFlag: true,
                            from: 'plusButton',
                          });
                          setIsOpenAddHerbMethodModal(true);
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ),
          };
        })}
      />
    </>
  );
};

export default observer(PrescriptionPart);
