/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Loader } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react';
import moment from 'moment';
import message from 'antd-message';

import { Row, TypographyText } from 'components/UI/atoms';
import ClinicRepository from 'repository/ClinicRepository';

const ClinicList = () => {
  const [target, setTarget] = useState<Element | null>(null);

  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isAdditionalListLoading, setIsAdditionalListLoading] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | null | undefined>(undefined);
  const [clinicList, setClinicList] = useState<any[]>([]);

  const handleSearch = async ({
    initLoadingFlag = false,
    resetParamFlag = false,
    isDeleted,
    nextToken,
  }: {
    initLoadingFlag?: boolean;
    resetParamFlag?: boolean;
    isDeleted?: boolean;
    nextToken?: string;
  }) => {
    try {
      if (initLoadingFlag) {
        await setIsListLoading(true);
      } else {
        await setIsAdditionalListLoading(true);
      }

      if (resetParamFlag) {
        setNextToken(undefined);
        setIsListLoading(true);
      }

      let res = {
        items: [],
        nextToken: undefined,
      };

      res = await ClinicRepository.listClinicsByCreatedAt({
        params: { isDeleted },
        nextToken: resetParamFlag ? undefined : nextToken,
      });

      await setClinicList(
        resetParamFlag || nextToken === undefined ? res?.items : [...clinicList, ...res?.items],
      );

      if (resetParamFlag) {
        setIsListLoading(false);
      }

      setNextToken(res?.nextToken);
    } catch (e) {
      message.error(`한의원 목록을 가져오는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 8000);
    } finally {
      await setIsListLoading(false);
      await setIsAdditionalListLoading(false);
    }
  };

  useEffect(() => {
    handleSearch({ initLoadingFlag: true, resetParamFlag: true });
  }, []);

  useEffect(() => {
    const ob = new IntersectionObserver(
      (e) => {
        if (e?.[0]?.isIntersecting) {
          handleSearch({ nextToken });
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );
    target && ob.observe(target);

    return () => {
      ob.disconnect();
    };
  }, [target]);

  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1.4rem',
        paddingBottom: '1rem',
        height: '100%',
        flex: '1 1 0',
      }}
    >
      <Row style={{ display: 'flex', columnGap: '0.5rem' }}>
        <TypographyText style={{ fontSize: '1.2rem' }}>
          {`한의원 수: ${clinicList?.length ?? 0}곳`}
        </TypographyText>
      </Row>
      <table className={`list-page-common-table  ${isListLoading && 'height-100 flex-1'}`}>
        <thead>
          <tr>
            <th>
              <span>한의원 명</span>
            </th>
            <th>
              <span>한의원 유형</span>
            </th>
            <th>
              <span>한의원 이메일</span>
            </th>
            <th>
              <span>한의원 전화번호</span>
            </th>
            <th>
              <span>환자 수</span>
            </th>
            <th>
              <span>스탭 수</span>
            </th>
            <th>
              <span>생성일</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isListLoading ? (
            <tr>
              <td colSpan={7}>
                <Row
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Row style={{ display: 'flex', marginTop: '-4rem', marginLeft: '-1rem' }}>
                    <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
                  </Row>
                </Row>
              </td>
            </tr>
          ) : clinicList?.length > 0 ? (
            clinicList.map((clinic, i) => (
              <tr key={clinic.id} ref={clinicList.length - 1 === i ? setTarget : null}>
                <td>
                  <span>{clinic.clinicName}</span>
                </td>
                <td>
                  <span>{clinic.businessType === 'PERSONAL' ? '개인' : '사업자'}</span>
                </td>
                <td>
                  <span>{clinic.clinicEmail}</span>
                </td>
                <td>
                  <span>{clinic.phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}</span>
                </td>
                <td>
                  <span>{clinic.numberOfPatients || 0}</span>
                </td>
                <td>
                  <span>{clinic.numberOfSteps || 0}</span>
                </td>
                <td>
                  <span>{moment(clinic.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YY/MM/DD')}</span>
                </td>
                <td>
                  <Row
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button
                      style={{
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                        backgroundColor: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '5rem',
                        height: '2rem',
                      }}
                      onClick={() => {
                        // 새 창으로 열기
                        window.open(`/dashboard/${clinic.id}`, '_blank');
                      }}
                    >
                      <TypographyText fontWeight={700} fontSize="0.8rem">
                        {'상세'}
                      </TypographyText>
                    </button>
                  </Row>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <span
                  style={{
                    color: '#afafaf',
                  }}
                >
                  등록된 환자가 없습니다.
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!isListLoading && isAdditionalListLoading && (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
        </Row>
      )}
    </Row>
  );
};

export default observer(ClinicList);
