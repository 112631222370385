export interface Herb {
  code: string;
  name: string;
  weight?: number;
  relativeSymptomCode?: { code: string; contribution: number }[];
}

export interface HerbCode {
  code: string;
  name: string;
  nameForSearching: string;
}

export const getHerb = (herbCode: string): HerbCode => {
  return herbMap.get(herbCode);
};

export const herbMap = new Map([
  [
    'H001',
    {
      name: '가자',
      nameForSearching: '가자',
      code: 'H001',
    },
  ],
  [
    'H002',
    {
      name: '갈근',
      nameForSearching: '갈근',
      code: 'H002',
    },
  ],
  [
    'H003',
    {
      name: '감송향',
      nameForSearching: '감송향',
      code: 'H003',
    },
  ],
  [
    'H004',
    {
      name: '감수',
      nameForSearching: '감수',
      code: 'H004',
    },
  ],
  [
    'H005',
    {
      name: '감초',
      nameForSearching: '감초',
      code: 'H005',
    },
  ],
  [
    'H006',
    {
      name: '강활',
      nameForSearching: '강활',
      code: 'H006',
    },
  ],
  [
    'H007',
    {
      name: '강황',
      nameForSearching: '강황',
      code: 'H007',
    },
  ],
  [
    'H008',
    {
      name: '건강',
      nameForSearching: '건강||건생강',
      code: 'H008',
    },
  ],
  [
    'H009',
    {
      name: '건칠',
      nameForSearching: '건칠||생칠',
      code: 'H009',
    },
  ],
  [
    'H010',
    {
      name: '검실',
      nameForSearching: '검실||검인',
      code: 'H010',
    },
  ],
  [
    'H011',
    {
      name: '견우자',
      nameForSearching: '견우자',
      code: 'H011',
    },
  ],
  [
    'H012',
    {
      name: '결명자',
      nameForSearching: '결명자',
      code: 'H012',
    },
  ],
  [
    'H013',
    {
      name: '경분',
      nameForSearching: '경분',
      code: 'H013',
    },
  ],
  [
    'H014',
    {
      name: '계내금',
      nameForSearching: '계내금',
      code: 'H014',
    },
  ],
  [
    'H015',
    {
      name: '계지',
      nameForSearching: '계지',
      code: 'H015',
    },
  ],
  [
    'H016',
    {
      name: '계혈등',
      nameForSearching: '계혈등||홍등근',
      code: 'H016',
    },
  ],
  [
    'H017',
    {
      name: '고량강',
      nameForSearching: '고량강',
      code: 'H017',
    },
  ],
  [
    'H018',
    {
      name: '고련피',
      nameForSearching: '고련피',
      code: 'H018',
    },
  ],
  [
    'H019',
    {
      name: '고본',
      nameForSearching: '고본',
      code: 'H019',
    },
  ],
  [
    'H020',
    {
      name: '고삼',
      nameForSearching: '고삼',
      code: 'H020',
    },
  ],
  [
    'H021',
    {
      name: '곡기생',
      nameForSearching: '곡기생||동청',
      code: 'H021',
    },
  ],
  [
    'H022',
    {
      name: '곡아',
      nameForSearching: '곡아',
      code: 'H022',
    },
  ],
  [
    'H023',
    {
      name: '곤포',
      nameForSearching: '곤포',
      code: 'H023',
    },
  ],
  [
    'H024',
    {
      name: '골쇄보',
      nameForSearching: '골쇄보',
      code: 'H024',
    },
  ],
  [
    'H025',
    {
      name: '과루',
      nameForSearching: '과루||괄루인||괄루실',
      code: 'H025',
    },
  ],
  [
    'H026',
    {
      name: '과루근',
      nameForSearching: '과루근||괄루근',
      code: 'H026',
    },
  ],
  [
    'H027',
    {
      name: '과체',
      nameForSearching: '과체',
      code: 'H027',
    },
  ],
  [
    'H028',
    {
      name: '곽향',
      nameForSearching: '곽향',
      code: 'H028',
    },
  ],
  [
    'H029',
    {
      name: '관동화',
      nameForSearching: '관동화',
      code: 'H029',
    },
  ],
  [
    'H030',
    {
      name: '관중',
      nameForSearching: '관중',
      code: 'H030',
    },
  ],
  [
    'H031',
    {
      name: '괴각',
      nameForSearching: '괴각',
      code: 'H031',
    },
  ],
  [
    'H032',
    {
      name: '괴화',
      nameForSearching: '괴화',
      code: 'H032',
    },
  ],
  [
    'H033',
    {
      name: '구기자',
      nameForSearching: '구기자',
      code: 'H033',
    },
  ],
  [
    'H034',
    {
      name: '구맥',
      nameForSearching: '구맥',
      code: 'H034',
    },
  ],
  [
    'H035',
    {
      name: '구인',
      nameForSearching: '구인||구인시',
      code: 'H035',
    },
  ],
  [
    'H036',
    {
      name: '구자',
      nameForSearching: '구자',
      code: 'H036',
    },
  ],
  [
    'H037',
    {
      name: '구척',
      nameForSearching: '구척',
      code: 'H037',
    },
  ],
  [
    'H038',
    {
      name: '구판',
      nameForSearching: '구판||귀갑||귀판',
      code: 'H038',
    },
  ],
  [
    'H039',
    {
      name: '국화',
      nameForSearching: '국화||감국||백국화||국화예',
      code: 'H039',
    },
  ],
  [
    'H040',
    {
      name: '권백',
      nameForSearching: '권백',
      code: 'H040',
    },
  ],
  [
    'H041',
    {
      name: '금앵자',
      nameForSearching: '금앵자',
      code: 'H041',
    },
  ],
  [
    'H042',
    {
      name: '금은화',
      nameForSearching: '금은화',
      code: 'H042',
    },
  ],
  [
    'H043',
    {
      name: '금전백화사',
      nameForSearching: '금전백화사||백화사',
      code: 'H043',
    },
  ],
  [
    'H044',
    {
      name: '금전초',
      nameForSearching: '금전초',
      code: 'H044',
    },
  ],
  [
    'H045',
    {
      name: '기사',
      nameForSearching: '기사',
      code: 'H045',
    },
  ],
  [
    'H046',
    {
      name: '길경',
      nameForSearching: '길경',
      code: 'H046',
    },
  ],
  [
    'H047',
    {
      name: '나도근',
      nameForSearching: '나도근||나도간',
      code: 'H047',
    },
  ],
  [
    'H048',
    {
      name: '나복자',
      nameForSearching: '나복자||내복자',
      code: 'H048',
    },
  ],
  [
    'H049',
    {
      name: '낙석등',
      nameForSearching: '낙석등||석룡자',
      code: 'H049',
    },
  ],
  [
    'H050',
    {
      name: '노감석',
      nameForSearching: '노감석',
      code: 'H050',
    },
  ],
  [
    'H051',
    {
      name: '노근',
      nameForSearching: '노근',
      code: 'H051',
    },
  ],
  [
    'H052',
    {
      name: '노봉방',
      nameForSearching: '노봉방',
      code: 'H052',
    },
  ],
  [
    'H053',
    {
      name: '노회',
      nameForSearching: '노회',
      code: 'H053',
    },
  ],
  [
    'H054',
    {
      name: '녹각',
      nameForSearching: '녹각',
      code: 'H054',
    },
  ],
  [
    'H055',
    {
      name: '녹각교',
      nameForSearching: '녹각교',
      code: 'H055',
    },
  ],
  [
    'H056',
    {
      name: '녹각상',
      nameForSearching: '녹각상',
      code: 'H056',
    },
  ],
  [
    'H057',
    {
      name: '녹두',
      nameForSearching: '녹두',
      code: 'H057',
    },
  ],
  [
    'H058',
    {
      name: '녹용',
      nameForSearching: '녹용',
      code: 'H058',
    },
  ],
  [
    'H059',
    {
      name: '뇌환',
      nameForSearching: '뇌환',
      code: 'H059',
    },
  ],
  [
    'H060',
    {
      name: '누로',
      nameForSearching: '누로',
      code: 'H060',
    },
  ],
  [
    'H061',
    {
      name: '능소화',
      nameForSearching: '능소화',
      code: 'H061',
    },
  ],
  [
    'H062',
    {
      name: '단삼',
      nameForSearching: '단삼',
      code: 'H062',
    },
  ],
  [
    'H063',
    {
      name: '단향',
      nameForSearching: '단향||백단향',
      code: 'H063',
    },
  ],
  [
    'H064',
    {
      name: '담두시',
      nameForSearching: '담두시||두시',
      code: 'H064',
    },
  ],
  [
    'H065',
    {
      name: '담반',
      nameForSearching: '담반||석담',
      code: 'H065',
    },
  ],
  [
    'H066',
    {
      name: '담죽엽',
      nameForSearching: '담죽엽',
      code: 'H066',
    },
  ],
  [
    'H067',
    {
      name: '당귀',
      nameForSearching: '당귀',
      code: 'H067',
    },
  ],
  [
    'H068',
    {
      name: '당삼',
      nameForSearching: '당삼',
      code: 'H068',
    },
  ],
  [
    'H069',
    {
      name: '대계',
      nameForSearching: '대계',
      code: 'H069',
    },
  ],
  [
    'H070',
    {
      name: '대극',
      nameForSearching: '대극',
      code: 'H070',
    },
  ],
  [
    'H071',
    {
      name: '대두황권',
      nameForSearching: '대두황권||대두',
      code: 'H071',
    },
  ],
  [
    'H072',
    {
      name: '대모',
      nameForSearching: '대모',
      code: 'H072',
    },
  ],
  [
    'H073',
    {
      name: '대복피',
      nameForSearching: '대복피',
      code: 'H073',
    },
  ],
  [
    'H074',
    {
      name: '대자석',
      nameForSearching: '대자석',
      code: 'H074',
    },
  ],
  [
    'H075',
    {
      name: '대조',
      nameForSearching: '대조||대추',
      code: 'H075',
    },
  ],
  [
    'H076',
    {
      name: '대청엽',
      nameForSearching: '대청엽',
      code: 'H076',
    },
  ],
  [
    'H077',
    {
      name: '대풍자',
      nameForSearching: '대풍자',
      code: 'H077',
    },
  ],
  [
    'H078',
    {
      name: '대황',
      nameForSearching: '대황',
      code: 'H078',
    },
  ],
  [
    'H079',
    {
      name: '도인',
      nameForSearching: '도인',
      code: 'H079',
    },
  ],
  [
    'H080',
    {
      name: '독활',
      nameForSearching: '독활',
      code: 'H080',
    },
  ],
  [
    'H081',
    {
      name: '동과자',
      nameForSearching: '동과자',
      code: 'H081',
    },
  ],
  [
    'H082',
    {
      name: '동과피',
      nameForSearching: '동과피',
      code: 'H082',
    },
  ],
  [
    'H083',
    {
      name: '동규자',
      nameForSearching: '동규자',
      code: 'H083',
    },
  ],
  [
    'H084',
    {
      name: '동충하초',
      nameForSearching: '동충하초',
      code: 'H084',
    },
  ],
  [
    'H085',
    {
      name: '두충',
      nameForSearching: '두충',
      code: 'H085',
    },
  ],
  [
    'H086',
    {
      name: '등심초',
      nameForSearching: '등심초',
      code: 'H086',
    },
  ],
  [
    'H087',
    {
      name: '마두령',
      nameForSearching: '마두령',
      code: 'H087',
    },
  ],
  [
    'H088',
    {
      name: '마전자',
      nameForSearching: '마전자',
      code: 'H088',
    },
  ],
  [
    'H089',
    {
      name: '마치현',
      nameForSearching: '마치현',
      code: 'H089',
    },
  ],
  [
    'H090',
    {
      name: '마편초',
      nameForSearching: '마편초',
      code: 'H090',
    },
  ],
  [
    'H091',
    {
      name: '마황',
      nameForSearching: '마황',
      code: 'H091',
    },
  ],
  [
    'H092',
    {
      name: '마황근',
      nameForSearching: '마황근',
      code: 'H092',
    },
  ],
  [
    'H093',
    {
      name: '만형자',
      nameForSearching: '만형자',
      code: 'H093',
    },
  ],
  [
    'H094',
    {
      name: '망초',
      nameForSearching: '망초||박초||현명분||마아초',
      code: 'H094',
    },
  ],
  [
    'H095',
    {
      name: '매괴화',
      nameForSearching: '매괴화',
      code: 'H095',
    },
  ],
  [
    'H096',
    {
      name: '맥문동',
      nameForSearching: '맥문동',
      code: 'H096',
    },
  ],
  [
    'H097',
    {
      name: '맥아',
      nameForSearching: '맥아',
      code: 'H097',
    },
  ],
  [
    'H098',
    {
      name: '모과',
      nameForSearching: '모과',
      code: 'H098',
    },
  ],
  [
    'H099',
    {
      name: '모려',
      nameForSearching: '모려',
      code: 'H099',
    },
  ],
  [
    'H100',
    {
      name: '목근피',
      nameForSearching: '목근피||목과피',
      code: 'H100',
    },
  ],
  [
    'H101',
    {
      name: '목단피',
      nameForSearching: '목단피',
      code: 'H101',
    },
  ],
  [
    'H102',
    {
      name: '목적',
      nameForSearching: '목적',
      code: 'H102',
    },
  ],
  [
    'H103',
    {
      name: '목통',
      nameForSearching: '목통',
      code: 'H103',
    },
  ],
  [
    'H104',
    {
      name: '목향',
      nameForSearching: '목향',
      code: 'H104',
    },
  ],
  [
    'H105',
    {
      name: '몰약',
      nameForSearching: '몰약',
      code: 'H105',
    },
  ],
  [
    'H106',
    {
      name: '무이',
      nameForSearching: '무이',
      code: 'H106',
    },
  ],
  [
    'H107',
    {
      name: '문합',
      nameForSearching: '문합',
      code: 'H107',
    },
  ],
  [
    'H108',
    {
      name: '밀몽화',
      nameForSearching: '밀몽화',
      code: 'H108',
    },
  ],
  [
    'H109',
    {
      name: '박하',
      nameForSearching: '박하',
      code: 'H109',
    },
  ],
  [
    'H110',
    {
      name: '반대해',
      nameForSearching: '반대해',
      code: 'H110',
    },
  ],
  [
    'H111',
    {
      name: '반묘',
      nameForSearching: '반묘',
      code: 'H111',
    },
  ],
  [
    'H112',
    {
      name: '반변련',
      nameForSearching: '반변련',
      code: 'H112',
    },
  ],
  [
    'H113',
    {
      name: '반하',
      nameForSearching: '반하||반하국',
      code: 'H113',
    },
  ],
  [
    'H114',
    {
      name: '방기',
      nameForSearching: '방기',
      code: 'H114',
    },
  ],
  [
    'H115',
    {
      name: '방풍',
      nameForSearching: '방풍',
      code: 'H115',
    },
  ],
  [
    'H116',
    {
      name: '백강잠',
      nameForSearching: '백강잠',
      code: 'H116',
    },
  ],
  [
    'H117',
    {
      name: '백개자',
      nameForSearching: '백개자||개자',
      code: 'H117',
    },
  ],
  [
    'H118',
    {
      name: '백과',
      nameForSearching: '백과',
      code: 'H118',
    },
  ],
  [
    'H119',
    {
      name: '백급',
      nameForSearching: '백급',
      code: 'H119',
    },
  ],
  [
    'H120',
    {
      name: '백두구',
      nameForSearching: '백두구||두구',
      code: 'H120',
    },
  ],
  [
    'H121',
    {
      name: '백두옹',
      nameForSearching: '백두옹',
      code: 'H121',
    },
  ],
  [
    'H122',
    {
      name: '백렴',
      nameForSearching: '백렴',
      code: 'H122',
    },
  ],
  [
    'H123',
    {
      name: '백모근',
      nameForSearching: '백모근||모근',
      code: 'H123',
    },
  ],
  [
    'H124',
    {
      name: '백반',
      nameForSearching: '백반',
      code: 'H124',
    },
  ],
  [
    'H125',
    {
      name: '백복령',
      nameForSearching: '백복령||복령||복신',
      code: 'H125',
    },
  ],
  [
    'H126',
    {
      name: '백부근',
      nameForSearching: '백부근',
      code: 'H126',
    },
  ],
  [
    'H127',
    {
      name: '백부자',
      nameForSearching: '백부자',
      code: 'H127',
    },
  ],
  [
    'H128',
    {
      name: '백선피',
      nameForSearching: '백선피',
      code: 'H128',
    },
  ],
  [
    'H129',
    {
      name: '백자인',
      nameForSearching: '백자인',
      code: 'H129',
    },
  ],
  [
    'H130',
    {
      name: '백작약',
      nameForSearching: '백작약||작약',
      code: 'H130',
    },
  ],
  [
    'H131',
    {
      name: '백전',
      nameForSearching: '백전',
      code: 'H131',
    },
  ],
  [
    'H132',
    {
      name: '백지',
      nameForSearching: '백지',
      code: 'H132',
    },
  ],
  [
    'H133',
    {
      name: '백질려',
      nameForSearching: '백질려||질려자',
      code: 'H133',
    },
  ],
  [
    'H134',
    {
      name: '백출',
      nameForSearching: '백출',
      code: 'H134',
    },
  ],
  [
    'H135',
    {
      name: '백편두',
      nameForSearching: '백편두',
      code: 'H135',
    },
  ],
  [
    'H136',
    {
      name: '백합',
      nameForSearching: '백합',
      code: 'H136',
    },
  ],
  [
    'H137',
    {
      name: '백화사설초',
      nameForSearching: '백화사설초',
      code: 'H137',
    },
  ],
  [
    'H138',
    {
      name: '별갑',
      nameForSearching: '별갑',
      code: 'H138',
    },
  ],
  [
    'H139',
    {
      name: '보골지',
      nameForSearching: '보골지',
      code: 'H139',
    },
  ],
  [
    'H141',
    {
      name: '복룡간',
      nameForSearching: '복룡간',
      code: 'H141',
    },
  ],
  [
    'H142',
    {
      name: '복분자',
      nameForSearching: '복분자',
      code: 'H142',
    },
  ],
  [
    'H143',
    {
      name: '봉밀',
      nameForSearching: '봉밀',
      code: 'H143',
    },
  ],
  [
    'H144',
    {
      name: '부소맥',
      nameForSearching: '부소맥||소맥||소맥노||소맥면',
      code: 'H144',
    },
  ],
  [
    'H145',
    {
      name: '부자',
      nameForSearching: '부자',
      code: 'H145',
    },
  ],
  [
    'H146',
    {
      name: '부평',
      nameForSearching: '부평',
      code: 'H146',
    },
  ],
  [
    'H147',
    {
      name: '붕사',
      nameForSearching: '붕사',
      code: 'H147',
    },
  ],
  [
    'H148',
    {
      name: '비석',
      nameForSearching: '비석',
      code: 'H148',
    },
  ],
  [
    'H149',
    {
      name: '비자',
      nameForSearching: '비자',
      code: 'H149',
    },
  ],
  [
    'H150',
    {
      name: '비파엽',
      nameForSearching: '비파엽',
      code: 'H150',
    },
  ],
  [
    'H151',
    {
      name: '비해',
      nameForSearching: '비해',
      code: 'H151',
    },
  ],
  [
    'H152',
    {
      name: '빈랑',
      nameForSearching: '빈랑||빈랑자',
      code: 'H152',
    },
  ],
  [
    'H153',
    {
      name: '빙편',
      nameForSearching: '빙편||용뇌',
      code: 'H153',
    },
  ],
  [
    'H154',
    {
      name: '사간',
      nameForSearching: '사간',
      code: 'H154',
    },
  ],
  [
    'H155',
    {
      name: '사군자',
      nameForSearching: '사군자',
      code: 'H155',
    },
  ],
  [
    'H156',
    {
      name: '사삼',
      nameForSearching: '사삼',
      code: 'H156',
    },
  ],
  [
    'H157',
    {
      name: '사상자',
      nameForSearching: '사상자',
      code: 'H157',
    },
  ],
  [
    'H158',
    {
      name: '사원자',
      nameForSearching: '사원자',
      code: 'H158',
    },
  ],
  [
    'H159',
    {
      name: '사인',
      nameForSearching: '사인',
      code: 'H159',
    },
  ],
  [
    'H160',
    {
      name: '사향',
      nameForSearching: '사향',
      code: 'H160',
    },
  ],
  [
    'H161',
    {
      name: '산두근',
      nameForSearching: '산두근',
      code: 'H161',
    },
  ],
  [
    'H162',
    {
      name: '산사',
      nameForSearching: '산사',
      code: 'H162',
    },
  ],
  [
    'H163',
    {
      name: '산수유',
      nameForSearching: '산수유',
      code: 'H163',
    },
  ],
  [
    'H164',
    {
      name: '산약',
      nameForSearching: '산약',
      code: 'H164',
    },
  ],
  [
    'H165',
    {
      name: '산자고',
      nameForSearching: '산자고',
      code: 'H165',
    },
  ],
  [
    'H166',
    {
      name: '산조인',
      nameForSearching: '산조인',
      code: 'H166',
    },
  ],
  [
    'H167',
    {
      name: '삼릉',
      nameForSearching: '삼릉',
      code: 'H167',
    },
  ],
  [
    'H168',
    {
      name: '삼백초',
      nameForSearching: '삼백초',
      code: 'H168',
    },
  ],
  [
    'H169',
    {
      name: '삼칠근',
      nameForSearching: '삼칠근',
      code: 'H169',
    },
  ],
  [
    'H170',
    {
      name: '상기생',
      nameForSearching: '상기생',
      code: 'H170',
    },
  ],
  [
    'H171',
    {
      name: '상륙',
      nameForSearching: '상륙',
      code: 'H171',
    },
  ],
  [
    'H172',
    {
      name: '상백피',
      nameForSearching: '상백피||상피',
      code: 'H172',
    },
  ],
  [
    'H173',
    {
      name: '상산',
      nameForSearching: '상산',
      code: 'H173',
    },
  ],
  [
    'H174',
    {
      name: '상심자',
      nameForSearching: '상심자',
      code: 'H174',
    },
  ],
  [
    'H175',
    {
      name: '상엽',
      nameForSearching: '상엽',
      code: 'H175',
    },
  ],
  [
    'H176',
    {
      name: '상지',
      nameForSearching: '상지',
      code: 'H176',
    },
  ],
  [
    'H177',
    {
      name: '상표초',
      nameForSearching: '상표초',
      code: 'H177',
    },
  ],
  [
    'H178',
    {
      name: '생강',
      nameForSearching: '생강||건생강||생강즙||생강피',
      code: 'H178',
    },
  ],
  [
    'H179',
    {
      name: '생지황',
      nameForSearching: '생지황||건지황',
      code: 'H179',
    },
  ],
  [
    'H180',
    {
      name: '서각',
      nameForSearching: '서각||수우각',
      code: 'H180',
    },
  ],
  [
    'H181',
    {
      name: '석결명',
      nameForSearching: '석결명',
      code: 'H181',
    },
  ],
  [
    'H182',
    {
      name: '석고',
      nameForSearching: '석고',
      code: 'H182',
    },
  ],
  [
    'H183',
    {
      name: '석곡',
      nameForSearching: '석곡',
      code: 'H183',
    },
  ],
  [
    'H184',
    {
      name: '석류피',
      nameForSearching: '석류피',
      code: 'H184',
    },
  ],
  [
    'H185',
    {
      name: '석위',
      nameForSearching: '석위',
      code: 'H185',
    },
  ],
  [
    'H186',
    {
      name: '석창포',
      nameForSearching: '석창포',
      code: 'H186',
    },
  ],
  [
    'H187',
    {
      name: '선모',
      nameForSearching: '선모',
      code: 'H187',
    },
  ],
  [
    'H188',
    {
      name: '선복화',
      nameForSearching: '선복화',
      code: 'H188',
    },
  ],
  [
    'H189',
    {
      name: '선태',
      nameForSearching: '선태||선퇴',
      code: 'H189',
    },
  ],
  [
    'H190',
    {
      name: '선학초',
      nameForSearching: '선학초||용아초',
      code: 'H190',
    },
  ],
  [
    'H191',
    {
      name: '섬수',
      nameForSearching: '섬수||섬서',
      code: 'H191',
    },
  ],
  [
    'H192',
    {
      name: '세신',
      nameForSearching: '세신',
      code: 'H192',
    },
  ],
  [
    'H193',
    {
      name: '소계',
      nameForSearching: '소계||소계근||소계엽',
      code: 'H193',
    },
  ],
  [
    'H194',
    {
      name: '소목',
      nameForSearching: '소목',
      code: 'H194',
    },
  ],
  [
    'H195',
    {
      name: '소자',
      nameForSearching: '소자',
      code: 'H195',
    },
  ],
  [
    'H196',
    {
      name: '소합향',
      nameForSearching: '소합향',
      code: 'H196',
    },
  ],
  [
    'H197',
    {
      name: '소회향',
      nameForSearching: '소회향||회향',
      code: 'H197',
    },
  ],
  [
    'H198',
    {
      name: '속단',
      nameForSearching: '속단',
      code: 'H198',
    },
  ],
  [
    'H199',
    {
      name: '속수자',
      nameForSearching: '속수자',
      code: 'H199',
    },
  ],
  [
    'H200',
    {
      name: '쇄양',
      nameForSearching: '쇄양',
      code: 'H200',
    },
  ],
  [
    'H201',
    {
      name: '수질',
      nameForSearching: '수질',
      code: 'H201',
    },
  ],
  [
    'H202',
    {
      name: '숙지황',
      nameForSearching: '숙지황',
      code: 'H202',
    },
  ],
  [
    'H203',
    {
      name: '승마',
      nameForSearching: '승마',
      code: 'H203',
    },
  ],
  [
    'H204',
    {
      name: '시체',
      nameForSearching: '시체',
      code: 'H204',
    },
  ],
  [
    'H205',
    {
      name: '시호',
      nameForSearching: '시호',
      code: 'H205',
    },
  ],
  [
    'H206',
    {
      name: '신곡',
      nameForSearching: '신곡||신국',
      code: 'H206',
    },
  ],
  [
    'H207',
    {
      name: '신이',
      nameForSearching: '신이',
      code: 'H207',
    },
  ],
  [
    'H208',
    {
      name: '아교',
      nameForSearching: '아교',
      code: 'H208',
    },
  ],
  [
    'H209',
    {
      name: '아출',
      nameForSearching: '아출',
      code: 'H209',
    },
  ],
  [
    'H210',
    {
      name: '안식향',
      nameForSearching: '안식향',
      code: 'H210',
    },
  ],
  [
    'H211',
    {
      name: '애엽',
      nameForSearching: '애엽',
      code: 'H211',
    },
  ],
  [
    'H212',
    {
      name: '앵속각',
      nameForSearching: '앵속각',
      code: 'H212',
    },
  ],
  [
    'H213',
    {
      name: '야교등',
      nameForSearching: '야교등',
      code: 'H213',
    },
  ],
  [
    'H214',
    {
      name: '야국화',
      nameForSearching: '야국화',
      code: 'H214',
    },
  ],
  [
    'H215',
    {
      name: '양기석',
      nameForSearching: '양기석',
      code: 'H215',
    },
  ],
  [
    'H216',
    {
      name: '양제근',
      nameForSearching: '양제근',
      code: 'H216',
    },
  ],
  [
    'H217',
    {
      name: '어성초',
      nameForSearching: '어성초',
      code: 'H217',
    },
  ],
  [
    'H218',
    {
      name: '여로',
      nameForSearching: '여로',
      code: 'H218',
    },
  ],
  [
    'H219',
    {
      name: '여정자',
      nameForSearching: '여정자',
      code: 'H219',
    },
  ],
  [
    'H220',
    {
      name: '여지핵',
      nameForSearching: '여지핵',
      code: 'H220',
    },
  ],
  [
    'H221',
    {
      name: '연교',
      nameForSearching: '연교',
      code: 'H221',
    },
  ],
  [
    'H222',
    {
      name: '연단',
      nameForSearching: '연단',
      code: 'H222',
    },
  ],
  [
    'H223',
    {
      name: '연자육',
      nameForSearching: '연자육||연자심',
      code: 'H223',
    },
  ],
  [
    'H224',
    {
      name: '영양각',
      nameForSearching: '영양각',
      code: 'H224',
    },
  ],
  [
    'H225',
    {
      name: '영지',
      nameForSearching: '영지',
      code: 'H225',
    },
  ],
  [
    'H226',
    {
      name: '오가피',
      nameForSearching: '오가피',
      code: 'H226',
    },
  ],
  [
    'H227',
    {
      name: '오공',
      nameForSearching: '오공',
      code: 'H227',
    },
  ],
  [
    'H228',
    {
      name: '오령지',
      nameForSearching: '오령지',
      code: 'H228',
    },
  ],
  [
    'H229',
    {
      name: '오매',
      nameForSearching: '오매||매실',
      code: 'H229',
    },
  ],
  [
    'H230',
    {
      name: '오미자',
      nameForSearching: '오미자',
      code: 'H230',
    },
  ],
  [
    'H231',
    {
      name: '오배자',
      nameForSearching: '오배자',
      code: 'H231',
    },
  ],
  [
    'H232',
    {
      name: '오수유',
      nameForSearching: '오수유',
      code: 'H232',
    },
  ],
  [
    'H233',
    {
      name: '오약',
      nameForSearching: '오약',
      code: 'H233',
    },
  ],
  [
    'H234',
    {
      name: '오초사',
      nameForSearching: '오초사',
      code: 'H234',
    },
  ],
  [
    'H235',
    {
      name: '옥미수',
      nameForSearching: '옥미수',
      code: 'H235',
    },
  ],
  [
    'H236',
    {
      name: '옥죽',
      nameForSearching: '옥죽',
      code: 'H236',
    },
  ],
  [
    'H237',
    {
      name: '와릉자',
      nameForSearching: '와릉자',
      code: 'H237',
    },
  ],
  [
    'H238',
    {
      name: '왕불유행',
      nameForSearching: '왕불유행||왕불류행',
      code: 'H238',
    },
  ],
  [
    'H239',
    {
      name: '용골',
      nameForSearching: '용골',
      code: 'H239',
    },
  ],
  [
    'H240',
    {
      name: '용담초',
      nameForSearching: '용담초||용담',
      code: 'H240',
    },
  ],
  [
    'H241',
    {
      name: '용안육',
      nameForSearching: '용안육',
      code: 'H241',
    },
  ],
  [
    'H242',
    {
      name: '우방자',
      nameForSearching: '우방자',
      code: 'H242',
    },
  ],
  [
    'H243',
    {
      name: '우슬',
      nameForSearching: '우슬',
      code: 'H243',
    },
  ],
  [
    'H244',
    {
      name: '우여량',
      nameForSearching: '우여량',
      code: 'H244',
    },
  ],
  [
    'H245',
    {
      name: '우절',
      nameForSearching: '우절',
      code: 'H245',
    },
  ],
  [
    'H246',
    {
      name: '우황',
      nameForSearching: '우황',
      code: 'H246',
    },
  ],
  [
    'H247',
    {
      name: '욱리인',
      nameForSearching: '욱리인',
      code: 'H247',
    },
  ],
  [
    'H248',
    {
      name: '울금',
      nameForSearching: '울금',
      code: 'H248',
    },
  ],
  [
    'H249',
    {
      name: '웅담',
      nameForSearching: '웅담',
      code: 'H249',
    },
  ],
  [
    'H250',
    {
      name: '웅황',
      nameForSearching: '웅황',
      code: 'H250',
    },
  ],
  [
    'H251',
    {
      name: '원지',
      nameForSearching: '원지',
      code: 'H251',
    },
  ],
  [
    'H252',
    {
      name: '원화',
      nameForSearching: '원화',
      code: 'H252',
    },
  ],
  [
    'H253',
    {
      name: '위령선',
      nameForSearching: '위령선',
      code: 'H253',
    },
  ],
  [
    'H254',
    {
      name: '위릉채',
      nameForSearching: '위릉채',
      code: 'H254',
    },
  ],
  [
    'H255',
    {
      name: '유기노',
      nameForSearching: '유기노',
      code: 'H255',
    },
  ],
  [
    'H256',
    {
      name: '유향',
      nameForSearching: '유향',
      code: 'H256',
    },
  ],
  [
    'H257',
    {
      name: '유황',
      nameForSearching: '유황',
      code: 'H257',
    },
  ],
  [
    'H258',
    {
      name: '육계',
      nameForSearching: '육계||계심',
      code: 'H258',
    },
  ],
  [
    'H259',
    {
      name: '육두구',
      nameForSearching: '육두구',
      code: 'H259',
    },
  ],
  [
    'H260',
    {
      name: '육종용',
      nameForSearching: '육종용',
      code: 'H260',
    },
  ],
  [
    'H261',
    {
      name: '은시호',
      nameForSearching: '은시호',
      code: 'H261',
    },
  ],
  [
    'H262',
    {
      name: '음양곽',
      nameForSearching: '음양곽',
      code: 'H262',
    },
  ],
  [
    'H263',
    {
      name: '의이인',
      nameForSearching: '의이인',
      code: 'H263',
    },
  ],
  [
    'H264',
    {
      name: '익모초',
      nameForSearching: '익모초',
      code: 'H264',
    },
  ],
  [
    'H265',
    {
      name: '익지인',
      nameForSearching: '익지인||익지',
      code: 'H265',
    },
  ],
  [
    'H266',
    {
      name: '인동초',
      nameForSearching: '인동초||인동',
      code: 'H266',
    },
  ],
  [
    'H267',
    {
      name: '인삼',
      nameForSearching: '인삼',
      code: 'H267',
    },
  ],
  [
    'H268',
    {
      name: '인진호',
      nameForSearching: '인진호',
      code: 'H268',
    },
  ],
  [
    'H269',
    {
      name: '자석',
      nameForSearching: '자석',
      code: 'H269',
    },
  ],
  [
    'H270',
    {
      name: '자소엽',
      nameForSearching: '자소엽',
      code: 'H270',
    },
  ],
  [
    'H271',
    {
      name: '자연동',
      nameForSearching: '자연동',
      code: 'H271',
    },
  ],
  [
    'H272',
    {
      name: '자완',
      nameForSearching: '자완',
      code: 'H272',
    },
  ],
  [
    'H273',
    {
      name: '자초',
      nameForSearching: '자초||자초화',
      code: 'H273',
    },
  ],
  [
    'H274',
    {
      name: '자충',
      nameForSearching: '자충',
      code: 'H274',
    },
  ],
  [
    'H275',
    {
      name: '자하거',
      nameForSearching: '자하거',
      code: 'H275',
    },
  ],
  [
    'H276',
    {
      name: '자화지정',
      nameForSearching: '자화지정',
      code: 'H276',
    },
  ],
  [
    'H277',
    {
      name: '잠사',
      nameForSearching: '잠사',
      code: 'H277',
    },
  ],
  [
    'H278',
    {
      name: '장뇌',
      nameForSearching: '장뇌',
      code: 'H278',
    },
  ],
  [
    'H279',
    {
      name: '저령',
      nameForSearching: '저령',
      code: 'H279',
    },
  ],
  [
    'H280',
    {
      name: '저마근',
      nameForSearching: '저마근',
      code: 'H280',
    },
  ],
  [
    'H281',
    {
      name: '저실자',
      nameForSearching: '저실자',
      code: 'H281',
    },
  ],
  [
    'H283',
    {
      name: '적석지',
      nameForSearching: '적석지',
      code: 'H283',
    },
  ],
  [
    'H284',
    {
      name: '적소두',
      nameForSearching: '적소두',
      code: 'H284',
    },
  ],
  [
    'H285',
    {
      name: '적작약',
      nameForSearching: '적작약',
      code: 'H285',
    },
  ],
  [
    'H286',
    {
      name: '전갈',
      nameForSearching: '전갈',
      code: 'H286',
    },
  ],
  [
    'H287',
    {
      name: '전호',
      nameForSearching: '전호',
      code: 'H287',
    },
  ],
  [
    'H288',
    {
      name: '절패모',
      nameForSearching: '절패모',
      code: 'H288',
    },
  ],
  [
    'H289',
    {
      name: '정력자',
      nameForSearching: '정력자',
      code: 'H289',
    },
  ],
  [
    'H290',
    {
      name: '정류',
      nameForSearching: '정류',
      code: 'H290',
    },
  ],
  [
    'H291',
    {
      name: '정향',
      nameForSearching: '정향',
      code: 'H291',
    },
  ],
  [
    'H292',
    {
      name: '조각자',
      nameForSearching: '조각자||조협자',
      code: 'H292',
    },
  ],
  [
    'H293',
    {
      name: '조구등',
      nameForSearching: '조구등',
      code: 'H293',
    },
  ],
  [
    'H294',
    {
      name: '조협',
      nameForSearching: '조협',
      code: 'H294',
    },
  ],
  [
    'H295',
    {
      name: '조휴',
      nameForSearching: '조휴',
      code: 'H295',
    },
  ],
  [
    'H296',
    {
      name: '종려피',
      nameForSearching: '종려피',
      code: 'H296',
    },
  ],
  [
    'H297',
    {
      name: '주사',
      nameForSearching: '주사',
      code: 'H297',
    },
  ],
  [
    'H298',
    {
      name: '죽력',
      nameForSearching: '죽력',
      code: 'H298',
    },
  ],
  [
    'H299',
    {
      name: '죽여',
      nameForSearching: '죽여',
      code: 'H299',
    },
  ],
  [
    'H300',
    {
      name: '죽엽',
      nameForSearching: '죽엽||고죽엽',
      code: 'H300',
    },
  ],
  [
    'H301',
    {
      name: '지각',
      nameForSearching: '지각',
      code: 'H301',
    },
  ],
  [
    'H302',
    {
      name: '지골피',
      nameForSearching: '지골피',
      code: 'H302',
    },
  ],
  [
    'H303',
    {
      name: '지모',
      nameForSearching: '지모',
      code: 'H303',
    },
  ],
  [
    'H304',
    {
      name: '지부자',
      nameForSearching: '지부자',
      code: 'H304',
    },
  ],
  [
    'H305',
    {
      name: '지실',
      nameForSearching: '지실',
      code: 'H305',
    },
  ],
  [
    'H306',
    {
      name: '지유',
      nameForSearching: '지유',
      code: 'H306',
    },
  ],
  [
    'H307',
    {
      name: '진교',
      nameForSearching: '진교',
      code: 'H307',
    },
  ],
  [
    'H308',
    {
      name: '진주',
      nameForSearching: '진주',
      code: 'H308',
    },
  ],
  [
    'H309',
    {
      name: '진주모',
      nameForSearching: '진주모',
      code: 'H309',
    },
  ],
  [
    'H310',
    {
      name: '진피',
      nameForSearching: '진피',
      code: 'H310',
    },
  ],
  [
    'H311',
    {
      name: '차전자',
      nameForSearching: '차전자',
      code: 'H311',
    },
  ],
  [
    'H312',
    {
      name: '창이자',
      nameForSearching: '창이자||창이',
      code: 'H312',
    },
  ],
  [
    'H313',
    {
      name: '창출',
      nameForSearching: '창출',
      code: 'H313',
    },
  ],
  [
    'H314',
    {
      name: '천궁',
      nameForSearching: '천궁',
      code: 'H314',
    },
  ],
  [
    'H315',
    {
      name: '천남성',
      nameForSearching: '천남성||남성',
      code: 'H315',
    },
  ],
  [
    'H316',
    {
      name: '천련자',
      nameForSearching: '천련자',
      code: 'H316',
    },
  ],
  [
    'H317',
    {
      name: '천마',
      nameForSearching: '천마',
      code: 'H317',
    },
  ],
  [
    'H318',
    {
      name: '천문동',
      nameForSearching: '천문동',
      code: 'H318',
    },
  ],
  [
    'H319',
    {
      name: '천산갑',
      nameForSearching: '천산갑',
      code: 'H319',
    },
  ],
  [
    'H320',
    {
      name: '천오두',
      nameForSearching: '천오두||천오',
      code: 'H320',
    },
  ],
  [
    'H321',
    {
      name: '천초근',
      nameForSearching: '천초근',
      code: 'H321',
    },
  ],
  [
    'H322',
    {
      name: '천축황',
      nameForSearching: '천축황',
      code: 'H322',
    },
  ],
  [
    'H323',
    {
      name: '천패모',
      nameForSearching: '천패모',
      code: 'H323',
    },
  ],
  [
    'H324',
    {
      name: '청대',
      nameForSearching: '청대||남엽||남칠',
      code: 'H324',
    },
  ],
  [
    'H325',
    {
      name: '청목향',
      nameForSearching: '청목향',
      code: 'H325',
    },
  ],
  [
    'H326',
    {
      name: '청몽석',
      nameForSearching: '청몽석',
      code: 'H326',
    },
  ],
  [
    'H327',
    {
      name: '청상자',
      nameForSearching: '청상자',
      code: 'H327',
    },
  ],
  [
    'H328',
    {
      name: '청피',
      nameForSearching: '청피',
      code: 'H328',
    },
  ],
  [
    'H329',
    {
      name: '청호',
      nameForSearching: '청호',
      code: 'H329',
    },
  ],
  [
    'H330',
    {
      name: '초과',
      nameForSearching: '초과',
      code: 'H330',
    },
  ],
  [
    'H331',
    {
      name: '초두구',
      nameForSearching: '초두구||초두구피',
      code: 'H331',
    },
  ],
  [
    'H332',
    {
      name: '초오',
      nameForSearching: '초오',
      code: 'H332',
    },
  ],
  [
    'H333',
    {
      name: '촉초',
      nameForSearching: '촉초',
      code: 'H333',
    },
  ],
  [
    'H334',
    {
      name: '총백',
      nameForSearching: '총백||총근',
      code: 'H334',
    },
  ],
  [
    'H335',
    {
      name: '춘피',
      nameForSearching: '춘피',
      code: 'H335',
    },
  ],
  [
    'H336',
    {
      name: '충울자',
      nameForSearching: '충울자||충위자',
      code: 'H336',
    },
  ],
  [
    'H337',
    {
      name: '취오동',
      nameForSearching: '취오동',
      code: 'H337',
    },
  ],
  [
    'H338',
    {
      name: '측백엽',
      nameForSearching: '측백엽',
      code: 'H338',
    },
  ],
  [
    'H339',
    {
      name: '치자',
      nameForSearching: '치자',
      code: 'H339',
    },
  ],
  [
    'H340',
    {
      name: '침향',
      nameForSearching: '침향',
      code: 'H340',
    },
  ],
  [
    'H341',
    {
      name: '택란',
      nameForSearching: '택란',
      code: 'H341',
    },
  ],
  [
    'H342',
    {
      name: '택사',
      nameForSearching: '택사',
      code: 'H342',
    },
  ],
  [
    'H343',
    {
      name: '토목향',
      nameForSearching: '토목향',
      code: 'H343',
    },
  ],
  [
    'H344',
    {
      name: '토복령',
      nameForSearching: '토복령',
      code: 'H344',
    },
  ],
  [
    'H345',
    {
      name: '토사자',
      nameForSearching: '토사자',
      code: 'H345',
    },
  ],
  [
    'H346',
    {
      name: '통초',
      nameForSearching: '통초',
      code: 'H346',
    },
  ],
  [
    'H347',
    {
      name: '파극천',
      nameForSearching: '파극천',
      code: 'H347',
    },
  ],
  [
    'H348',
    {
      name: '파두',
      nameForSearching: '파두',
      code: 'H348',
    },
  ],
  [
    'H349',
    {
      name: '판람근',
      nameForSearching: '판람근',
      code: 'H349',
    },
  ],
  [
    'H350',
    {
      name: '패란',
      nameForSearching: '패란',
      code: 'H350',
    },
  ],
  [
    'H351',
    {
      name: '패장',
      nameForSearching: '패장||패장초',
      code: 'H351',
    },
  ],
  [
    'H352',
    {
      name: '편축',
      nameForSearching: '편축',
      code: 'H352',
    },
  ],
  [
    'H353',
    {
      name: '포공영',
      nameForSearching: '포공영',
      code: 'H353',
    },
  ],
  [
    'H354',
    {
      name: '포황',
      nameForSearching: '포황',
      code: 'H354',
    },
  ],
  [
    'H355',
    {
      name: '필발',
      nameForSearching: '필발',
      code: 'H355',
    },
  ],
  [
    'H356',
    {
      name: '필징가',
      nameForSearching: '필징가',
      code: 'H356',
    },
  ],
  [
    'H357',
    {
      name: '하고초',
      nameForSearching: '하고초',
      code: 'H357',
    },
  ],
  [
    'H358',
    {
      name: '하수오',
      nameForSearching: '하수오',
      code: 'H358',
    },
  ],
  [
    'H359',
    {
      name: '학슬',
      nameForSearching: '학슬',
      code: 'H359',
    },
  ],
  [
    'H360',
    {
      name: '한련초',
      nameForSearching: '한련초',
      code: 'H360',
    },
  ],
  [
    'H361',
    {
      name: '합개',
      nameForSearching: '합개',
      code: 'H361',
    },
  ],
  [
    'H362',
    {
      name: '합환피',
      nameForSearching: '합환피',
      code: 'H362',
    },
  ],
  [
    'H363',
    {
      name: '해구신',
      nameForSearching: '해구신',
      code: 'H363',
    },
  ],
  [
    'H364',
    {
      name: '해금사',
      nameForSearching: '해금사',
      code: 'H364',
    },
  ],
  [
    'H365',
    {
      name: '해동피',
      nameForSearching: '해동피',
      code: 'H365',
    },
  ],
  [
    'H366',
    {
      name: '해마',
      nameForSearching: '해마',
      code: 'H366',
    },
  ],
  [
    'H367',
    {
      name: '해백',
      nameForSearching: '해백',
      code: 'H367',
    },
  ],
  [
    'H368',
    {
      name: '해부석',
      nameForSearching: '해부석',
      code: 'H368',
    },
  ],
  [
    'H369',
    {
      name: '해조',
      nameForSearching: '해조',
      code: 'H369',
    },
  ],
  [
    'H370',
    {
      name: '해표초',
      nameForSearching: '해표초',
      code: 'H370',
    },
  ],
  [
    'H371',
    {
      name: '행인',
      nameForSearching: '행인',
      code: 'H371',
    },
  ],
  [
    'H372',
    {
      name: '향부자',
      nameForSearching: '향부자',
      code: 'H372',
    },
  ],
  [
    'H373',
    {
      name: '향유',
      nameForSearching: '향유',
      code: 'H373',
    },
  ],
  [
    'H374',
    {
      name: '현삼',
      nameForSearching: '현삼',
      code: 'H374',
    },
  ],
  [
    'H375',
    {
      name: '현호색',
      nameForSearching: '현호색',
      code: 'H375',
    },
  ],
  [
    'H376',
    {
      name: '혈갈',
      nameForSearching: '혈갈',
      code: 'H376',
    },
  ],
  [
    'H377',
    {
      name: '형개',
      nameForSearching: '형개',
      code: 'H377',
    },
  ],
  [
    'H378',
    {
      name: '호골',
      nameForSearching: '호골',
      code: 'H378',
    },
  ],
  [
    'H379',
    {
      name: '호도육',
      nameForSearching: '호도육||호도',
      code: 'H379',
    },
  ],
  [
    'H380',
    {
      name: '호로파',
      nameForSearching: '호로파',
      code: 'H380',
    },
  ],
  [
    'H381',
    {
      name: '호박',
      nameForSearching: '호박',
      code: 'H381',
    },
  ],
  [
    'H382',
    {
      name: '호장근',
      nameForSearching: '호장근',
      code: 'H382',
    },
  ],
  [
    'H383',
    {
      name: '호초',
      nameForSearching: '호초||후추',
      code: 'H383',
    },
  ],
  [
    'H384',
    {
      name: '호황련',
      nameForSearching: '호황련',
      code: 'H384',
    },
  ],
  [
    'H385',
    {
      name: '홍화',
      nameForSearching: '홍화||홍화자',
      code: 'H385',
    },
  ],
  [
    'H386',
    {
      name: '화마인',
      nameForSearching: '화마인||마인',
      code: 'H386',
    },
  ],
  [
    'H387',
    {
      name: '화예석',
      nameForSearching: '화예석',
      code: 'H387',
    },
  ],
  [
    'H388',
    {
      name: '활석',
      nameForSearching: '활석',
      code: 'H388',
    },
  ],
  [
    'H389',
    {
      name: '황금',
      nameForSearching: '황금',
      code: 'H389',
    },
  ],
  [
    'H390',
    {
      name: '황기',
      nameForSearching: '황기',
      code: 'H390',
    },
  ],
  [
    'H391',
    {
      name: '황련',
      nameForSearching: '황련',
      code: 'H391',
    },
  ],
  [
    'H392',
    {
      name: '황백',
      nameForSearching: '황백',
      code: 'H392',
    },
  ],
  [
    'H393',
    {
      name: '황정',
      nameForSearching: '황정',
      code: 'H393',
    },
  ],
  [
    'H394',
    {
      name: '후박',
      nameForSearching: '후박',
      code: 'H394',
    },
  ],
  [
    'H395',
    {
      name: '흑지마',
      nameForSearching: '흑지마',
      code: 'H395',
    },
  ],
  [
    'H396',
    {
      name: '희렴초',
      nameForSearching: '희렴초||희렴',
      code: 'H396',
    },
  ],
  [
    'H397',
    {
      name: '진피(秦皮)',
      nameForSearching: '진피(秦皮)',
      code: 'H397',
    },
  ],
  [
    'H398',
    {
      name: '백미',
      nameForSearching: '백미',
      code: 'H398',
    },
  ],
  [
    'H501',
    {
      name: '갈화',
      nameForSearching: '갈화',
      code: 'H501',
    },
  ],
  [
    'H502',
    {
      name: '가화',
      nameForSearching: '가화',
      code: 'H502',
    },
  ],
  [
    'H503',
    {
      name: '갈미',
      nameForSearching: '갈미',
      code: 'H503',
    },
  ],
  [
    'H504',
    {
      name: '감자즙',
      nameForSearching: '감자즙',
      code: 'H504',
    },
  ],
  [
    'H505',
    {
      name: '감초초',
      nameForSearching: '감초초',
      code: 'H505',
    },
  ],
  [
    'H506',
    {
      name: '강랑',
      nameForSearching: '강랑',
      code: 'H506',
    },
  ],
  [
    'H507',
    {
      name: '강향',
      nameForSearching: '강향',
      code: 'H507',
    },
  ],
  [
    'H508',
    {
      name: '갱미',
      nameForSearching: '갱미',
      code: 'H508',
    },
  ],
  [
    'H509',
    {
      name: '갱미반',
      nameForSearching: '갱미반',
      code: 'H509',
    },
  ],
  [
    'H510',
    {
      name: '건섬두',
      nameForSearching: '건섬두',
      code: 'H510',
    },
  ],
  [
    'H511',
    {
      name: '건율',
      nameForSearching: '건율',
      code: 'H511',
    },
  ],
  [
    'H512',
    {
      name: '견',
      nameForSearching: '견',
      code: 'H512',
    },
  ],
  [
    'H513',
    {
      name: '경향',
      nameForSearching: '경향',
      code: 'H513',
    },
  ],
  [
    'H514',
    {
      name: '계관화',
      nameForSearching: '계관화',
      code: 'H514',
    },
  ],
  [
    'H515',
    {
      name: '계란각',
      nameForSearching: '계란각',
      code: 'H515',
    },
  ],
  [
    'H516',
    {
      name: '계란중백피',
      nameForSearching: '계란중백피',
      code: 'H516',
    },
  ],
  [
    'H517',
    {
      name: '계란황',
      nameForSearching: '계란황',
      code: 'H517',
    },
  ],
  [
    'H518',
    {
      name: '계설향',
      nameForSearching: '계설향',
      code: 'H518',
    },
  ],
  [
    'H519',
    {
      name: '계소엽',
      nameForSearching: '계소엽',
      code: 'H519',
    },
  ],
  [
    'H520',
    {
      name: '계자',
      nameForSearching: '계자',
      code: 'H520',
    },
  ],
  [
    'H521',
    {
      name: '계자난백',
      nameForSearching: '계자난백',
      code: 'H521',
    },
  ],
  [
    'H522',
    {
      name: '고마혜저',
      nameForSearching: '고마혜저',
      code: 'H522',
    },
  ],
  [
    'H523',
    {
      name: '고문전',
      nameForSearching: '고문전',
      code: 'H523',
    },
  ],
  [
    'H524',
    {
      name: '고호',
      nameForSearching: '고호',
      code: 'H524',
    },
  ],
  [
    'H525',
    {
      name: '곡정초',
      nameForSearching: '곡정초',
      code: 'H525',
    },
  ],
  [
    'H526',
    {
      name: '공청',
      nameForSearching: '공청',
      code: 'H526',
    },
  ],
  [
    'H527',
    {
      name: '괴백피',
      nameForSearching: '괴백피',
      code: 'H527',
    },
  ],
  [
    'H528',
    {
      name: '괴윤',
      nameForSearching: '괴윤',
      code: 'H528',
    },
  ],
  [
    'H529',
    {
      name: '괴지',
      nameForSearching: '괴지',
      code: 'H529',
    },
  ],
  [
    'H530',
    {
      name: '교맥',
      nameForSearching: '교맥',
      code: 'H530',
    },
  ],
  [
    'H531',
    {
      name: '교맥면',
      nameForSearching: '교맥면',
      code: 'H531',
    },
  ],
  [
    'H532',
    {
      name: '교이',
      nameForSearching: '교이',
      code: 'H532',
    },
  ],
  [
    'H533',
    {
      name: '구',
      nameForSearching: '구',
      code: 'H533',
    },
  ],
  [
    'H534',
    {
      name: '구담',
      nameForSearching: '구담',
      code: 'H534',
    },
  ],
  [
    'H535',
    {
      name: '구두골',
      nameForSearching: '구두골',
      code: 'H535',
    },
  ],
  [
    'H536',
    {
      name: '구백',
      nameForSearching: '구백',
      code: 'H536',
    },
  ],
  [
    'H537',
    {
      name: '구보',
      nameForSearching: '구보',
      code: 'H537',
    },
  ],
  [
    'H538',
    {
      name: '구즙',
      nameForSearching: '구즙',
      code: 'H538',
    },
  ],
  [
    'H539',
    {
      name: '구채',
      nameForSearching: '구채',
      code: 'H539',
    },
  ],
  [
    'H540',
    {
      name: '구치',
      nameForSearching: '구치',
      code: 'H540',
    },
  ],
  [
    'H541',
    {
      name: '국',
      nameForSearching: '국',
      code: 'H541',
    },
  ],
  [
    'H542',
    {
      name: '권삼',
      nameForSearching: '권삼',
      code: 'H542',
    },
  ],
  [
    'H543',
    {
      name: '귀구',
      nameForSearching: '귀구',
      code: 'H543',
    },
  ],
  [
    'H544',
    {
      name: '귀뇨',
      nameForSearching: '귀뇨',
      code: 'H544',
    },
  ],
  [
    'H545',
    {
      name: '귀육',
      nameForSearching: '귀육',
      code: 'H545',
    },
  ],
  [
    'H546',
    {
      name: '귀전우',
      nameForSearching: '귀전우',
      code: 'H546',
    },
  ],
  [
    'H547',
    {
      name: '귤핵',
      nameForSearching: '귤핵',
      code: 'H547',
    },
  ],
  [
    'H548',
    {
      name: '귤홍',
      nameForSearching: '귤홍',
      code: 'H548',
    },
  ],
  [
    'H549',
    {
      name: '금박',
      nameForSearching: '금박',
      code: 'H549',
    },
  ],
  [
    'H550',
    {
      name: '나륵',
      nameForSearching: '나륵',
      code: 'H550',
    },
  ],
  [
    'H551',
    {
      name: '나미',
      nameForSearching: '나미',
      code: 'H551',
    },
  ],
  [
    'H552',
    {
      name: '난발',
      nameForSearching: '난발',
      code: 'H552',
    },
  ],
  [
    'H553',
    {
      name: '난초',
      nameForSearching: '난초',
      code: 'H553',
    },
  ],
  [
    'H554',
    {
      name: '남실',
      nameForSearching: '남실',
      code: 'H554',
    },
  ],
  [
    'H555',
    {
      name: '남유',
      nameForSearching: '남유',
      code: 'H555',
    },
  ],
  [
    'H556',
    {
      name: '낭독',
      nameForSearching: '낭독',
      code: 'H556',
    },
  ],
  [
    'H557',
    {
      name: '내복즙',
      nameForSearching: '내복즙',
      code: 'H557',
    },
  ],
  [
    'H558',
    {
      name: '녹두분',
      nameForSearching: '녹두분',
      code: 'H558',
    },
  ],
  [
    'H559',
    {
      name: '녹두화',
      nameForSearching: '녹두화',
      code: 'H559',
    },
  ],
  [
    'H560',
    {
      name: '녹반',
      nameForSearching: '녹반',
      code: 'H560',
    },
  ],
  [
    'H561',
    {
      name: '누고',
      nameForSearching: '누고',
      code: 'H561',
    },
  ],
  [
    'H562',
    {
      name: '다년개옥상난초',
      nameForSearching: '다년개옥상난초',
      code: 'H562',
    },
  ],
  [
    'H563',
    {
      name: '다엽',
      nameForSearching: '다엽',
      code: 'H563',
    },
  ],
  [
    'H564',
    {
      name: '단서미',
      nameForSearching: '단서미',
      code: 'H564',
    },
  ],
  [
    'H565',
    {
      name: '단웅계두',
      nameForSearching: '단웅계두',
      code: 'H565',
    },
  ],
  [
    'H566',
    {
      name: '단웅계육',
      nameForSearching: '단웅계육',
      code: 'H566',
    },
  ],
  [
    'H567',
    {
      name: '단유',
      nameForSearching: '단유',
      code: 'H567',
    },
  ],
  [
    'H568',
    {
      name: '단철조중회',
      nameForSearching: '단철조중회',
      code: 'H568',
    },
  ],
  [
    'H569',
    {
      name: '당묵',
      nameForSearching: '당묵',
      code: 'H569',
    },
  ],
  [
    'H570',
    {
      name: '대맥',
      nameForSearching: '대맥',
      code: 'H570',
    },
  ],
  [
    'H571',
    {
      name: '대산',
      nameForSearching: '대산',
      code: 'H571',
    },
  ],
  [
    'H572',
    {
      name: '도교',
      nameForSearching: '도교',
      code: 'H572',
    },
  ],
  [
    'H573',
    {
      name: '도지',
      nameForSearching: '도지',
      code: 'H573',
    },
  ],
  [
    'H574',
    {
      name: '도효',
      nameForSearching: '도효',
      code: 'H574',
    },
  ],
  [
    'H575',
    {
      name: '돈란',
      nameForSearching: '돈란',
      code: 'H575',
    },
  ],
  [
    'H576',
    {
      name: '돈방고',
      nameForSearching: '돈방고',
      code: 'H576',
    },
  ],
  [
    'H577',
    {
      name: '돈육',
      nameForSearching: '돈육',
      code: 'H577',
    },
  ],
  [
    'H578',
    {
      name: '돈혈',
      nameForSearching: '돈혈',
      code: 'H578',
    },
  ],
  [
    'H579',
    {
      name: '동과즙',
      nameForSearching: '동과즙',
      code: 'H579',
    },
  ],
  [
    'H580',
    {
      name: '등황',
      nameForSearching: '등황',
      code: 'H580',
    },
  ],
  [
    'H581',
    {
      name: '마린근',
      nameForSearching: '마린근',
      code: 'H581',
    },
  ],
  [
    'H582',
    {
      name: '마린화',
      nameForSearching: '마린화',
      code: 'H582',
    },
  ],
  [
    'H583',
    {
      name: '마미',
      nameForSearching: '마미',
      code: 'H583',
    },
  ],
  [
    'H584',
    {
      name: '마발',
      nameForSearching: '마발',
      code: 'H584',
    },
  ],
  [
    'H586',
    {
      name: '만청자',
      nameForSearching: '만청자',
      code: 'H586',
    },
  ],
  [
    'H587',
    {
      name: '매지',
      nameForSearching: '매지',
      code: 'H587',
    },
  ],
  [
    'H588',
    {
      name: '매화',
      nameForSearching: '매화',
      code: 'H588',
    },
  ],
  [
    'H589',
    {
      name: '맹충',
      nameForSearching: '맹충',
      code: 'H589',
    },
  ],
  [
    'H590',
    {
      name: '명다',
      nameForSearching: '명다',
      code: 'H590',
    },
  ],
  [
    'H591',
    {
      name: '명선',
      nameForSearching: '명선',
      code: 'H591',
    },
  ],
  [
    'H592',
    {
      name: '모구육',
      nameForSearching: '모구육',
      code: 'H592',
    },
  ],
  [
    'H593',
    {
      name: '모구음경',
      nameForSearching: '모구음경',
      code: 'H593',
    },
  ],
  [
    'H594',
    {
      name: '모서분',
      nameForSearching: '모서분',
      code: 'H594',
    },
  ],
  [
    'H595',
    {
      name: '모서육',
      nameForSearching: '모서육',
      code: 'H595',
    },
  ],
  [
    'H596',
    {
      name: '모우슬',
      nameForSearching: '모우슬',
      code: 'H596',
    },
  ],
  [
    'H597',
    {
      name: '모향화',
      nameForSearching: '모향화',
      code: 'H597',
    },
  ],
  [
    'H598',
    {
      name: '모화',
      nameForSearching: '모화',
      code: 'H598',
    },
  ],
  [
    'H599',
    {
      name: '목란',
      nameForSearching: '목란',
      code: 'H599',
    },
  ],
  [
    'H600',
    {
      name: '목별자',
      nameForSearching: '목별자',
      code: 'H600',
    },
  ],
  [
    'H601',
    {
      name: '목이',
      nameForSearching: '목이',
      code: 'H601',
    },
  ],
  [
    'H602',
    {
      name: '몰식자',
      nameForSearching: '몰식자',
      code: 'H602',
    },
  ],
  [
    'H603',
    {
      name: '묘경상모',
      nameForSearching: '묘경상모',
      code: 'H603',
    },
  ],
  [
    'H604',
    {
      name: '묘두골',
      nameForSearching: '묘두골',
      code: 'H604',
    },
  ],
  [
    'H605',
    {
      name: '묘치',
      nameForSearching: '묘치',
      code: 'H605',
    },
  ],
  [
    'H606',
    {
      name: '무명이',
      nameForSearching: '무명이',
      code: 'H606',
    },
  ],
  [
    'H607',
    {
      name: '무회주',
      nameForSearching: '무회주',
      code: 'H607',
    },
  ],
  [
    'H608',
    {
      name: '미개안눈노서',
      nameForSearching: '미개안눈노서',
      code: 'H608',
    },
  ],
  [
    'H609',
    {
      name: '미후도',
      nameForSearching: '미후도',
      code: 'H609',
    },
  ],
  [
    'H610',
    {
      name: '밀랍',
      nameForSearching: '밀랍',
      code: 'H610',
    },
  ],
  [
    'H611',
    {
      name: '밀타승',
      nameForSearching: '밀타승',
      code: 'H611',
    },
  ],
  [
    'H612',
    {
      name: '발피',
      nameForSearching: '발피',
      code: 'H612',
    },
  ],
  [
    'H613',
    {
      name: '발합분',
      nameForSearching: '발합분',
      code: 'H613',
    },
  ],
  [
    'H614',
    {
      name: '방풍',
      nameForSearching: '방풍',
      code: 'H614',
    },
  ],
  [
    'H615',
    {
      name: '배자',
      nameForSearching: '배자',
      code: 'H615',
    },
  ],
  [
    'H616',
    {
      name: '백강',
      nameForSearching: '백강',
      code: 'H616',
    },
  ],
  [
    'H617',
    {
      name: '백과아',
      nameForSearching: '백과아',
      code: 'H617',
    },
  ],
  [
    'H618',
    {
      name: '백곽로',
      nameForSearching: '백곽로',
      code: 'H618',
    },
  ],
  [
    'H619',
    {
      name: '백교향',
      nameForSearching: '백교향',
      code: 'H619',
    },
  ],
  [
    'H620',
    {
      name: '백구시',
      nameForSearching: '백구시',
      code: 'H620',
    },
  ],
  [
    'H621',
    {
      name: '백동과',
      nameForSearching: '백동과',
      code: 'H621',
    },
  ],
  [
    'H622',
    {
      name: '백랍',
      nameForSearching: '백랍',
      code: 'H622',
    },
  ],
  [
    'H623',
    {
      name: '백마육',
      nameForSearching: '백마육',
      code: 'H623',
    },
  ],
  [
    'H624',
    {
      name: '백매',
      nameForSearching: '백매',
      code: 'H624',
    },
  ],
  [
    'H625',
    {
      name: '백맥반석',
      nameForSearching: '백맥반석',
      code: 'H625',
    },
  ],
  [
    'H626',
    {
      name: '백면자',
      nameForSearching: '백면자',
      code: 'H626',
    },
  ],
  [
    'H627',
    {
      name: '백반',
      nameForSearching: '백반',
      code: 'H627',
    },
  ],
  [
    'H628',
    {
      name: '백분',
      nameForSearching: '백분',
      code: 'H628',
    },
  ],
  [
    'H629',
    {
      name: '백사당',
      nameForSearching: '백사당',
      code: 'H629',
    },
  ],
  [
    'H630',
    {
      name: '백수오',
      nameForSearching: '백수오',
      code: 'H630',
    },
  ],
  [
    'H631',
    {
      name: '백시',
      nameForSearching: '백시',
      code: 'H631',
    },
  ],
  [
    'H632',
    {
      name: '백아고',
      nameForSearching: '백아고',
      code: 'H632',
    },
  ],
  [
    'H633',
    {
      name: '백압육',
      nameForSearching: '백압육',
      code: 'H633',
    },
  ],
  [
    'H634',
    {
      name: '백약전',
      nameForSearching: '백약전',
      code: 'H634',
    },
  ],
  [
    'H635',
    {
      name: '백양심',
      nameForSearching: '백양심',
      code: 'H635',
    },
  ],
  [
    'H636',
    {
      name: '백양엽',
      nameForSearching: '백양엽',
      code: 'H636',
    },
  ],
  [
    'H637',
    {
      name: '백양자간',
      nameForSearching: '백양자간',
      code: 'H637',
    },
  ],
  [
    'H638',
    {
      name: '백웅계담',
      nameForSearching: '백웅계담',
      code: 'H638',
    },
  ],
  [
    'H639',
    {
      name: '백유',
      nameForSearching: '백유',
      code: 'H639',
    },
  ],
  [
    'H640',
    {
      name: '백유마',
      nameForSearching: '백유마',
      code: 'H640',
    },
  ],
  [
    'H641',
    {
      name: '백초상',
      nameForSearching: '백초상',
      code: 'H641',
    },
  ],
  [
    'H642',
    {
      name: '벽전',
      nameForSearching: '벽전',
      code: 'H642',
    },
  ],
  [
    'H643',
    {
      name: '별육',
      nameForSearching: '별육',
      code: 'H643',
    },
  ],
  [
    'H644',
    {
      name: '복분자엽',
      nameForSearching: '복분자엽',
      code: 'H644',
    },
  ],
  [
    'H645',
    {
      name: '복익',
      nameForSearching: '복익',
      code: 'H645',
    },
  ],
  [
    'H646',
    {
      name: '본인토적혈',
      nameForSearching: '본인토적혈',
      code: 'H646',
    },
  ],
  [
    'H647',
    {
      name: '봉자',
      nameForSearching: '봉자',
      code: 'H647',
    },
  ],
  [
    'H648',
    {
      name: '부',
      nameForSearching: '부',
      code: 'H648',
    },
  ],
  [
    'H649',
    {
      name: '부용엽',
      nameForSearching: '부용엽',
      code: 'H649',
    },
  ],
  [
    'H650',
    {
      name: '분저',
      nameForSearching: '분저',
      code: 'H650',
    },
  ],
  [
    'H651',
    {
      name: '불이초',
      nameForSearching: '불이초',
      code: 'H651',
    },
  ],
  [
    'H652',
    {
      name: '불회목',
      nameForSearching: '불회목',
      code: 'H652',
    },
  ],
  [
    'H653',
    {
      name: '비상',
      nameForSearching: '비상',
      code: 'H653',
    },
  ],
  [
    'H654',
    {
      name: '사골',
      nameForSearching: '사골',
      code: 'H654',
    },
  ],
  [
    'H655',
    {
      name: '사과근',
      nameForSearching: '사과근',
      code: 'H655',
    },
  ],
  [
    'H656',
    {
      name: '사과락',
      nameForSearching: '사과락',
      code: 'H656',
    },
  ],
  [
    'H657',
    {
      name: '사세',
      nameForSearching: '사세',
      code: 'H657',
    },
  ],
  [
    'H658',
    {
      name: '사함석',
      nameForSearching: '사함석',
      code: 'H658',
    },
  ],
  [
    'H659',
    {
      name: '산다화',
      nameForSearching: '산다화',
      code: 'H659',
    },
  ],
  [
    'H660',
    {
      name: '산초',
      nameForSearching: '산초',
      code: 'H660',
    },
  ],
  [
    'H661',
    {
      name: '삼내자',
      nameForSearching: '삼내자',
      code: 'H661',
    },
  ],
  [
    'H662',
    {
      name: '삼목절',
      nameForSearching: '삼목절',
      code: 'H662',
    },
  ],
  [
    'H663',
    {
      name: '상산묘',
      nameForSearching: '상산묘',
      code: 'H663',
    },
  ],
  [
    'H664',
    {
      name: '상시회',
      nameForSearching: '상시회',
      code: 'H664',
    },
  ],
  [
    'H665',
    {
      name: '상심자',
      nameForSearching: '상심자',
      code: 'H665',
    },
  ],
  [
    'H666',
    {
      name: '상아',
      nameForSearching: '상아',
      code: 'H666',
    },
  ],
  [
    'H667',
    {
      name: '상이',
      nameForSearching: '상이',
      code: 'H667',
    },
  ],
  [
    'H668',
    {
      name: '생율',
      nameForSearching: '생율',
      code: 'H668',
    },
  ],
  [
    'H669',
    {
      name: '서내신',
      nameForSearching: '서내신',
      code: 'H669',
    },
  ],
  [
    'H670',
    {
      name: '서미',
      nameForSearching: '서미',
      code: 'H670',
    },
  ],
  [
    'H671',
    {
      name: '석',
      nameForSearching: '석',
      code: 'H671',
    },
  ],
  [
    'H672',
    {
      name: '석렴',
      nameForSearching: '석렴',
      code: 'H672',
    },
  ],
  [
    'H673',
    {
      name: '석류',
      nameForSearching: '석류',
      code: 'H673',
    },
  ],
  [
    'H674',
    {
      name: '석류동행근피',
      nameForSearching: '석류동행근피',
      code: 'H674',
    },
  ],
  [
    'H675',
    {
      name: '석류지',
      nameForSearching: '석류지',
      code: 'H675',
    },
  ],
  [
    'H676',
    {
      name: '석수어두중석',
      nameForSearching: '석수어두중석',
      code: 'H676',
    },
  ],
  [
    'H677',
    {
      name: '석연',
      nameForSearching: '석연',
      code: 'H677',
    },
  ],
  [
    'H678',
    {
      name: '석영',
      nameForSearching: '석영',
      code: 'H678',
    },
  ],
  [
    'H679',
    {
      name: '석유황',
      nameForSearching: '석유황',
      code: 'H679',
    },
  ],
  [
    'H680',
    {
      name: '석종유',
      nameForSearching: '석종유',
      code: 'H680',
    },
  ],
  [
    'H681',
    {
      name: '석회',
      nameForSearching: '석회',
      code: 'H681',
    },
  ],
  [
    'H682',
    {
      name: '선어',
      nameForSearching: '선어',
      code: 'H682',
    },
  ],
  [
    'H683',
    {
      name: '선어두골',
      nameForSearching: '선어두골',
      code: 'H683',
    },
  ],
  [
    'H684',
    {
      name: '섬여방',
      nameForSearching: '섬여방',
      code: 'H684',
    },
  ],
  [
    'H685',
    {
      name: '속미',
      nameForSearching: '속미',
      code: 'H685',
    },
  ],
  [
    'H686',
    {
      name: '속미분',
      nameForSearching: '속미분',
      code: 'H686',
    },
  ],
  [
    'H687',
    {
      name: '송라',
      nameForSearching: '송라',
      code: 'H687',
    },
  ],
  [
    'H688',
    {
      name: '송수피상록의',
      nameForSearching: '송수피상록의',
      code: 'H688',
    },
  ],
  [
    'H689',
    {
      name: '송연묵',
      nameForSearching: '송연묵',
      code: 'H689',
    },
  ],
  [
    'H690',
    {
      name: '송절',
      nameForSearching: '송절',
      code: 'H690',
    },
  ],
  [
    'H691',
    {
      name: '송지',
      nameForSearching: '송지',
      code: 'H691',
    },
  ],
  [
    'H692',
    {
      name: '송화분',
      nameForSearching: '송화분',
      code: 'H692',
    },
  ],
  [
    'H693',
    {
      name: '수',
      nameForSearching: '수',
      code: 'H693',
    },
  ],
  [
    'H694',
    {
      name: '수은',
      nameForSearching: '수은',
      code: 'H694',
    },
  ],
  [
    'H695',
    {
      name: '수중지혈',
      nameForSearching: '수중지혈',
      code: 'H695',
    },
  ],
  [
    'H696',
    {
      name: '수질',
      nameForSearching: '수질',
      code: 'H696',
    },
  ],
  [
    'H697',
    {
      name: '숙금',
      nameForSearching: '숙금',
      code: 'H697',
    },
  ],
  [
    'H698',
    {
      name: '숙애',
      nameForSearching: '숙애',
      code: 'H698',
    },
  ],
  [
    'H699',
    {
      name: '순류수',
      nameForSearching: '순류수',
      code: 'H699',
    },
  ],
  [
    'H700',
    {
      name: '시병',
      nameForSearching: '시병',
      code: 'H700',
    },
  ],
  [
    'H701',
    {
      name: '식수유',
      nameForSearching: '식수유',
      code: 'H701',
    },
  ],
  [
    'H702',
    {
      name: '식염',
      nameForSearching: '식염',
      code: 'H702',
    },
  ],
  [
    'H703',
    {
      name: '신면',
      nameForSearching: '신면',
      code: 'H703',
    },
  ],
  [
    'H704',
    {
      name: '신생소아제',
      nameForSearching: '신생소아제',
      code: 'H704',
    },
  ],
  [
    'H705',
    {
      name: '신와',
      nameForSearching: '신와',
      code: 'H705',
    },
  ],
  [
    'H706',
    {
      name: '쌀뜨물',
      nameForSearching: '쌀뜨물',
      code: 'H706',
    },
  ],
  [
    'H707',
    {
      name: '아위',
      nameForSearching: '아위',
      code: 'H707',
    },
  ],
  [
    'H708',
    {
      name: '아치',
      nameForSearching: '아치',
      code: 'H708',
    },
  ],
  [
    'H709',
    {
      name: '아편',
      nameForSearching: '아편',
      code: 'H709',
    },
  ],
  [
    'H710',
    {
      name: '안담',
      nameForSearching: '안담',
      code: 'H710',
    },
  ],
  [
    'H711',
    {
      name: '안방',
      nameForSearching: '안방',
      code: 'H711',
    },
  ],
  [
    'H712',
    {
      name: '압자청',
      nameForSearching: '압자청',
      code: 'H712',
    },
  ],
  [
    'H713',
    {
      name: '압척초',
      nameForSearching: '압척초',
      code: 'H713',
    },
  ],
  [
    'H714',
    {
      name: '앵도',
      nameForSearching: '앵도',
      code: 'H714',
    },
  ],
  [
    'H715',
    {
      name: '야명사',
      nameForSearching: '야명사',
      code: 'H715',
    },
  ],
  [
    'H716',
    {
      name: '야자고',
      nameForSearching: '야자고',
      code: 'H716',
    },
  ],
  [
    'H717',
    {
      name: '야합분',
      nameForSearching: '야합분',
      code: 'H717',
    },
  ],
  [
    'H718',
    {
      name: '양경골',
      nameForSearching: '양경골',
      code: 'H718',
    },
  ],
  [
    'H719',
    {
      name: '양담',
      nameForSearching: '양담',
      code: 'H719',
    },
  ],
  [
    'H720',
    {
      name: '양배려',
      nameForSearching: '양배려',
      code: 'H720',
    },
  ],
  [
    'H721',
    {
      name: '양분',
      nameForSearching: '양분',
      code: 'H721',
    },
  ],
  [
    'H722',
    {
      name: '양상진',
      nameForSearching: '양상진',
      code: 'H722',
    },
  ],
  [
    'H723',
    {
      name: '양신',
      nameForSearching: '양신',
      code: 'H723',
    },
  ],
  [
    'H724',
    {
      name: '양엽',
      nameForSearching: '양엽',
      code: 'H724',
    },
  ],
  [
    'H725',
    {
      name: '양요자',
      nameForSearching: '양요자',
      code: 'H725',
    },
  ],
  [
    'H726',
    {
      name: '양육',
      nameForSearching: '양육',
      code: 'H726',
    },
  ],
  [
    'H727',
    {
      name: '양척촉',
      nameForSearching: '양척촉',
      code: 'H727',
    },
  ],
  [
    'H728',
    {
      name: '양폐',
      nameForSearching: '양폐',
      code: 'H728',
    },
  ],
  [
    'H729',
    {
      name: '어교',
      nameForSearching: '어교',
      code: 'H729',
    },
  ],
  [
    'H730',
    {
      name: '여두',
      nameForSearching: '여두',
      code: 'H730',
    },
  ],
  [
    'H731',
    {
      name: '여실',
      nameForSearching: '여실',
      code: 'H731',
    },
  ],
  [
    'H732',
    {
      name: '여여',
      nameForSearching: '여여',
      code: 'H732',
    },
  ],
  [
    'H733',
    {
      name: '여제육',
      nameForSearching: '여제육',
      code: 'H733',
    },
  ],
  [
    'H734',
    {
      name: '연',
      nameForSearching: '연',
      code: 'H734',
    },
  ],
  [
    'H735',
    {
      name: '연근총백',
      nameForSearching: '연근총백',
      code: 'H735',
    },
  ],
  [
    'H736',
    {
      name: '연밀',
      nameForSearching: '연밀',
      code: 'H736',
    },
  ],
  [
    'H737',
    {
      name: '연봉',
      nameForSearching: '연봉',
      code: 'H737',
    },
  ],
  [
    'H738',
    {
      name: '연봉각',
      nameForSearching: '연봉각',
      code: 'H738',
    },
  ],
  [
    'H739',
    {
      name: '연분',
      nameForSearching: '연분',
      code: 'H739',
    },
  ],
  [
    'H740',
    {
      name: '연수총',
      nameForSearching: '연수총',
      code: 'H740',
    },
  ],
  [
    'H741',
    {
      name: '연지',
      nameForSearching: '연지',
      code: 'H741',
    },
  ],
  [
    'H742',
    {
      name: '연지배',
      nameForSearching: '연지배',
      code: 'H742',
    },
  ],
  [
    'H743',
    {
      name: '연초',
      nameForSearching: '연초',
      code: 'H743',
    },
  ],
  [
    'H744',
    {
      name: '연화',
      nameForSearching: '연화',
      code: 'H744',
    },
  ],
  [
    'H745',
    {
      name: '연화예',
      nameForSearching: '연화예',
      code: 'H745',
    },
  ],
  [
    'H746',
    {
      name: '열탕',
      nameForSearching: '열탕',
      code: 'H746',
    },
  ],
  [
    'H747',
    {
      name: '염고',
      nameForSearching: '염고',
      code: 'H747',
    },
  ],
  [
    'H748',
    {
      name: '염초',
      nameForSearching: '염초',
      code: 'H748',
    },
  ],
  [
    'H749',
    {
      name: '염탕',
      nameForSearching: '염탕',
      code: 'H749',
    },
  ],
  [
    'H750',
    {
      name: '영릉향',
      nameForSearching: '영릉향',
      code: 'H750',
    },
  ],
  [
    'H751',
    {
      name: '영사',
      nameForSearching: '영사',
      code: 'H751',
    },
  ],
  [
    'H752',
    {
      name: '영실근',
      nameForSearching: '영실근',
      code: 'H752',
    },
  ],
  [
    'H753',
    {
      name: '오골계경골',
      nameForSearching: '오골계경골',
      code: 'H753',
    },
  ],
  [
    'H754',
    {
      name: '오금석',
      nameForSearching: '오금석',
      code: 'H754',
    },
  ],
  [
    'H755',
    {
      name: '오배목근',
      nameForSearching: '오배목근',
      code: 'H755',
    },
  ],
  [
    'H756',
    {
      name: '오사',
      nameForSearching: '오사',
      code: 'H756',
    },
  ],
  [
    'H757',
    {
      name: '오아시우',
      nameForSearching: '오아시우',
      code: 'H757',
    },
  ],
  [
    'H758',
    {
      name: '오웅계관혈',
      nameForSearching: '오웅계관혈',
      code: 'H758',
    },
  ],
  [
    'H759',
    {
      name: '오웅계담',
      nameForSearching: '오웅계담',
      code: 'H759',
    },
  ],
  [
    'H760',
    {
      name: '오웅계시백',
      nameForSearching: '오웅계시백',
      code: 'H760',
    },
  ],
  [
    'H761',
    {
      name: '오웅계육',
      nameForSearching: '오웅계육',
      code: 'H761',
    },
  ],
  [
    'H762',
    {
      name: '오웅계장',
      nameForSearching: '오웅계장',
      code: 'H762',
    },
  ],
  [
    'H763',
    {
      name: '오자계분',
      nameForSearching: '오자계분',
      code: 'H763',
    },
  ],
  [
    'H764',
    {
      name: '오흑상',
      nameForSearching: '오흑상',
      code: 'H764',
    },
  ],
  [
    'H765',
    {
      name: '와거자',
      nameForSearching: '와거자',
      code: 'H765',
    },
  ],
  [
    'H766',
    {
      name: '와릉자',
      nameForSearching: '와릉자',
      code: 'H766',
    },
  ],
  [
    'H767',
    {
      name: '와송',
      nameForSearching: '와송',
      code: 'H767',
    },
  ],
  [
    'H768',
    {
      name: '와우',
      nameForSearching: '와우',
      code: 'H768',
    },
  ],
  [
    'H769',
    {
      name: '완두',
      nameForSearching: '완두',
      code: 'H769',
    },
  ],
  [
    'H770',
    {
      name: '완두화',
      nameForSearching: '완두화',
      code: 'H770',
    },
  ],
  [
    'H771',
    {
      name: '완형엽',
      nameForSearching: '완형엽',
      code: 'H771',
    },
  ],
  [
    'H772',
    {
      name: '왕과',
      nameForSearching: '왕과',
      code: 'H772',
    },
  ],
  [
    'H773',
    {
      name: '왕과등',
      nameForSearching: '왕과등',
      code: 'H773',
    },
  ],
  [
    'H774',
    {
      name: '요사',
      nameForSearching: '요사',
      code: 'H774',
    },
  ],
  [
    'H775',
    {
      name: '용천분',
      nameForSearching: '용천분',
      code: 'H775',
    },
  ],
  [
    'H776',
    {
      name: '용치',
      nameForSearching: '용치',
      code: 'H776',
    },
  ],
  [
    'H777',
    {
      name: '우각새',
      nameForSearching: '우각새',
      code: 'H777',
    },
  ],
  [
    'H778',
    {
      name: '우담',
      nameForSearching: '우담',
      code: 'H778',
    },
  ],
  [
    'H779',
    {
      name: '우담남성',
      nameForSearching: '우담남성',
      code: 'H779',
    },
  ],
  [
    'H780',
    {
      name: '우분',
      nameForSearching: '우분',
      code: 'H780',
    },
  ],
  [
    'H781',
    {
      name: '우수',
      nameForSearching: '우수',
      code: 'H781',
    },
  ],
  [
    'H782',
    {
      name: '우유',
      nameForSearching: '우유',
      code: 'H782',
    },
  ],
  [
    'H783',
    {
      name: '우육',
      nameForSearching: '우육',
      code: 'H783',
    },
  ],
  [
    'H784',
    {
      name: '우전초',
      nameForSearching: '우전초',
      code: 'H784',
    },
  ],
  [
    'H785',
    {
      name: '우즙',
      nameForSearching: '우즙',
      code: 'H785',
    },
  ],
  [
    'H786',
    {
      name: '운모',
      nameForSearching: '운모',
      code: 'H786',
    },
  ],
  [
    'H787',
    {
      name: '웅묘뇨',
      nameForSearching: '웅묘뇨',
      code: 'H787',
    },
  ],
  [
    'H788',
    {
      name: '웅작시',
      nameForSearching: '웅작시',
      code: 'H788',
    },
  ],
  [
    'H789',
    {
      name: '원잠아',
      nameForSearching: '원잠아',
      code: 'H789',
    },
  ],
  [
    'H790',
    {
      name: '원청',
      nameForSearching: '원청',
      code: 'H790',
    },
  ],
  [
    'H791',
    {
      name: '월경포',
      nameForSearching: '월경포',
      code: 'H791',
    },
  ],
  [
    'H792',
    {
      name: '위엽',
      nameForSearching: '위엽',
      code: 'H792',
    },
  ],
  [
    'H793',
    {
      name: '위피',
      nameForSearching: '위피',
      code: 'H793',
    },
  ],
  [
    'H794',
    {
      name: '유당',
      nameForSearching: '유당',
      code: 'H794',
    },
  ],
  [
    'H795',
    {
      name: '유백피',
      nameForSearching: '유백피',
      code: 'H795',
    },
  ],
  [
    'H796',
    {
      name: '유엽',
      nameForSearching: '유엽',
      code: 'H796',
    },
  ],
  [
    'H797',
    {
      name: '유월하중열사',
      nameForSearching: '유월하중열사',
      code: 'H797',
    },
  ],
  [
    'H798',
    {
      name: '유지',
      nameForSearching: '유지',
      code: 'H798',
    },
  ],
  [
    'H799',
    {
      name: '유핵',
      nameForSearching: '유핵',
      code: 'H799',
    },
  ],
  [
    'H800',
    {
      name: '율초',
      nameForSearching: '율초',
      code: 'H800',
    },
  ],
  [
    'H801',
    {
      name: '은박',
      nameForSearching: '은박',
      code: 'H801',
    },
  ],
  [
    'H802',
    {
      name: '은주',
      nameForSearching: '은주',
      code: 'H802',
    },
  ],
  [
    'H803',
    {
      name: '음교',
      nameForSearching: '음교',
      code: 'H803',
    },
  ],
  [
    'H804',
    {
      name: '응시백',
      nameForSearching: '응시백',
      code: 'H804',
    },
  ],
  [
    'H805',
    {
      name: '의어',
      nameForSearching: '의어',
      code: 'H805',
    },
  ],
  [
    'H806',
    {
      name: '이근백피',
      nameForSearching: '이근백피',
      code: 'H806',
    },
  ],
  [
    'H807',
    {
      name: '이어담',
      nameForSearching: '이어담',
      code: 'H807',
    },
  ],
  [
    'H808',
    {
      name: '이어육',
      nameForSearching: '이어육',
      code: 'H808',
    },
  ],
  [
    'H809',
    {
      name: '이자',
      nameForSearching: '이자',
      code: 'H809',
    },
  ],
  [
    'H810',
    {
      name: '이지',
      nameForSearching: '이지',
      code: 'H810',
    },
  ],
  [
    'H811',
    {
      name: '인곤당',
      nameForSearching: '인곤당',
      code: 'H811',
    },
  ],
  [
    'H812',
    {
      name: '인뇨',
      nameForSearching: '인뇨',
      code: 'H812',
    },
  ],
  [
    'H813',
    {
      name: '인삼노두',
      nameForSearching: '인삼노두',
      code: 'H813',
    },
  ],
  [
    'H814',
    {
      name: '인시',
      nameForSearching: '인시',
      code: 'H814',
    },
  ],
  [
    'H815',
    {
      name: '인우엽',
      nameForSearching: '인우엽',
      code: 'H815',
    },
  ],
  [
    'H816',
    {
      name: '인유',
      nameForSearching: '인유',
      code: 'H816',
    },
  ],
  [
    'H817',
    {
      name: '인조갑',
      nameForSearching: '인조갑',
      code: 'H817',
    },
  ],
  [
    'H818',
    {
      name: '인중백',
      nameForSearching: '인중백',
      code: 'H818',
    },
  ],
  [
    'H819',
    {
      name: '인중황',
      nameForSearching: '인중황',
      code: 'H819',
    },
  ],
  [
    'H820',
    {
      name: '자근',
      nameForSearching: '자근',
      code: 'H820',
    },
  ],
  [
    'H821',
    {
      name: '자금피',
      nameForSearching: '자금피',
      code: 'H821',
    },
  ],
  [
    'H822',
    {
      name: '자단향',
      nameForSearching: '자단향',
      code: 'H822',
    },
  ],
  [
    'H823',
    {
      name: '자석영',
      nameForSearching: '자석영',
      code: 'H823',
    },
  ],
  [
    'H824',
    {
      name: '자소경',
      nameForSearching: '자소경',
      code: 'H824',
    },
  ],
  [
    'H825',
    {
      name: '자소자',
      nameForSearching: '자소자',
      code: 'H825',
    },
  ],
  [
    'H826',
    {
      name: '자초용',
      nameForSearching: '자초용',
      code: 'H826',
    },
  ],
  [
    'H827',
    {
      name: '자패',
      nameForSearching: '자패',
      code: 'H827',
    },
  ],
  [
    'H828',
    {
      name: '자황',
      nameForSearching: '자황',
      code: 'H828',
    },
  ],
  [
    'H829',
    {
      name: '작란',
      nameForSearching: '작란',
      code: 'H829',
    },
  ],
  [
    'H830',
    {
      name: '작옹',
      nameForSearching: '작옹',
      code: 'H830',
    },
  ],
  [
    'H831',
    {
      name: '잠포지',
      nameForSearching: '잠포지',
      code: 'H831',
    },
  ],
  [
    'H832',
    {
      name: '장미',
      nameForSearching: '장미',
      code: 'H832',
    },
  ],
  [
    'H833',
    {
      name: '장수',
      nameForSearching: '장수',
      code: 'H833',
    },
  ],
  [
    'H834',
    {
      name: '장청',
      nameForSearching: '장청',
      code: 'H834',
    },
  ],
  [
    'H835',
    {
      name: '재백피',
      nameForSearching: '재백피',
      code: 'H835',
    },
  ],
  [
    'H836',
    {
      name: '저간',
      nameForSearching: '저간',
      code: 'H836',
    },
  ],
  [
    'H837',
    {
      name: '저경상모',
      nameForSearching: '저경상모',
      code: 'H837',
    },
  ],
  [
    'H838',
    {
      name: '저골',
      nameForSearching: '저골',
      code: 'H838',
    },
  ],
  [
    'H839',
    {
      name: '저골수',
      nameForSearching: '저골수',
      code: 'H839',
    },
  ],
  [
    'H840',
    {
      name: '저담',
      nameForSearching: '저담',
      code: 'H840',
    },
  ],
  [
    'H841',
    {
      name: '저담즙',
      nameForSearching: '저담즙',
      code: 'H841',
    },
  ],
  [
    'H842',
    {
      name: '저두',
      nameForSearching: '저두',
      code: 'H842',
    },
  ],
  [
    'H843',
    {
      name: '저두강',
      nameForSearching: '저두강',
      code: 'H843',
    },
  ],
  [
    'H844',
    {
      name: '저백피',
      nameForSearching: '저백피',
      code: 'H844',
    },
  ],
  [
    'H845',
    {
      name: '저부',
      nameForSearching: '저부',
      code: 'H845',
    },
  ],
  [
    'H846',
    {
      name: '저시',
      nameForSearching: '저시',
      code: 'H846',
    },
  ],
  [
    'H847',
    {
      name: '저심',
      nameForSearching: '저심',
      code: 'H847',
    },
  ],
  [
    'H848',
    {
      name: '저염육자',
      nameForSearching: '저염육자',
      code: 'H848',
    },
  ],
  [
    'H849',
    {
      name: '저유즙',
      nameForSearching: '저유즙',
      code: 'H849',
    },
  ],
  [
    'H850',
    {
      name: '저전제갑',
      nameForSearching: '저전제갑',
      code: 'H850',
    },
  ],
  [
    'H851',
    {
      name: '저지',
      nameForSearching: '저지',
      code: 'H851',
    },
  ],
  [
    'H852',
    {
      name: '저치',
      nameForSearching: '저치',
      code: 'H852',
    },
  ],
  [
    'H853',
    {
      name: '저포',
      nameForSearching: '저포',
      code: 'H853',
    },
  ],
  [
    'H854',
    {
      name: '저현제',
      nameForSearching: '저현제',
      code: 'H854',
    },
  ],
  [
    'H855',
    {
      name: '적동설',
      nameForSearching: '적동설',
      code: 'H855',
    },
  ],
  [
    'H856',
    {
      name: '적소두화',
      nameForSearching: '적소두화',
      code: 'H856',
    },
  ],
  [
    'H857',
    {
      name: '적송피',
      nameForSearching: '적송피',
      code: 'H857',
    },
  ],
  [
    'H858',
    {
      name: '적전',
      nameForSearching: '적전',
      code: 'H858',
    },
  ],
  [
    'H859',
    {
      name: '전두등',
      nameForSearching: '전두등',
      code: 'H859',
    },
  ],
  [
    'H860',
    {
      name: '전라',
      nameForSearching: '전라',
      code: 'H860',
    },
  ],
  [
    'H861',
    {
      name: '전라각',
      nameForSearching: '전라각',
      code: 'H861',
    },
  ],
  [
    'H862',
    {
      name: '정화수',
      nameForSearching: '정화수',
      code: 'H862',
    },
  ],
  [
    'H863',
    {
      name: '제니',
      nameForSearching: '제니',
      code: 'H863',
    },
  ],
  [
    'H864',
    {
      name: '제조',
      nameForSearching: '제조',
      code: 'H864',
    },
  ],
  [
    'H865',
    {
      name: '제채자',
      nameForSearching: '제채자',
      code: 'H865',
    },
  ],
  [
    'H866',
    {
      name: '조',
      nameForSearching: '조',
      code: 'H866',
    },
  ],
  [
    'H867',
    {
      name: '조각수근피',
      nameForSearching: '조각수근피',
      code: 'H867',
    },
  ],
  [
    'H868',
    {
      name: '조각즙',
      nameForSearching: '조각즙',
      code: 'H868',
    },
  ],
  [
    'H869',
    {
      name: '조등피',
      nameForSearching: '조등피',
      code: 'H869',
    },
  ],
  [
    'H870',
    {
      name: '조목근',
      nameForSearching: '조목근',
      code: 'H870',
    },
  ],
  [
    'H871',
    {
      name: '주',
      nameForSearching: '주',
      code: 'H871',
    },
  ],
  [
    'H872',
    {
      name: '주국',
      nameForSearching: '주국',
      code: 'H872',
    },
  ],
  [
    'H873',
    {
      name: '주웅계관혈',
      nameForSearching: '주웅계관혈',
      code: 'H873',
    },
  ],
  [
    'H874',
    {
      name: '죽순',
      nameForSearching: '죽순',
      code: 'H874',
    },
  ],
  [
    'H875',
    {
      name: '즉어',
      nameForSearching: '즉어',
      code: 'H875',
    },
  ],
  [
    'H876',
    {
      name: '증청',
      nameForSearching: '증청',
      code: 'H876',
    },
  ],
  [
    'H877',
    {
      name: '지룡',
      nameForSearching: '지룡',
      code: 'H877',
    },
  ],
  [
    'H878',
    {
      name: '지주',
      nameForSearching: '지주',
      code: 'H878',
    },
  ],
  [
    'H879',
    {
      name: '지황엽',
      nameForSearching: '지황엽',
      code: 'H879',
    },
  ],
  [
    'H880',
    {
      name: '진름미',
      nameForSearching: '진름미',
      code: 'H880',
    },
  ],
  [
    'H881',
    {
      name: '진속미',
      nameForSearching: '진속미',
      code: 'H881',
    },
  ],
  [
    'H882',
    {
      name: '진황미',
      nameForSearching: '진황미',
      code: 'H882',
    },
  ],
  [
    'H883',
    {
      name: '차전엽',
      nameForSearching: '차전엽',
      code: 'H883',
    },
  ],
  [
    'H884',
    {
      name: '차전초',
      nameForSearching: '차전초',
      code: 'H884',
    },
  ],
  [
    'H885',
    {
      name: '창이엽',
      nameForSearching: '창이엽',
      code: 'H885',
    },
  ],
  [
    'H886',
    {
      name: '창이초근',
      nameForSearching: '창이초근',
      code: 'H886',
    },
  ],
  [
    'H887',
    {
      name: '천령개',
      nameForSearching: '천령개',
      code: 'H887',
    },
  ],
  [
    'H888',
    {
      name: '천오첨',
      nameForSearching: '천오첨',
      code: 'H888',
    },
  ],
  [
    'H889',
    {
      name: '천웅',
      nameForSearching: '천웅',
      code: 'H889',
    },
  ],
  [
    'H890',
    {
      name: '철분',
      nameForSearching: '철분',
      code: 'H890',
    },
  ],
  [
    'H891',
    {
      name: '철정',
      nameForSearching: '철정',
      code: 'H891',
    },
  ],
  [
    'H892',
    {
      name: '철퇴병',
      nameForSearching: '철퇴병',
      code: 'H892',
    },
  ],
  [
    'H893',
    {
      name: '청귤엽',
      nameForSearching: '청귤엽',
      code: 'H893',
    },
  ],
  [
    'H894',
    {
      name: '청목향등',
      nameForSearching: '청목향등',
      code: 'H894',
    },
  ],
  [
    'H895',
    {
      name: '청양담',
      nameForSearching: '청양담',
      code: 'H895',
    },
  ],
  [
    'H896',
    {
      name: '청어담',
      nameForSearching: '청어담',
      code: 'H896',
    },
  ],
  [
    'H897',
    {
      name: '청염',
      nameForSearching: '청염',
      code: 'H897',
    },
  ],
  [
    'H898',
    {
      name: '청총',
      nameForSearching: '청총',
      code: 'H898',
    },
  ],
  [
    'H899',
    {
      name: '청포',
      nameForSearching: '청포',
      code: 'H899',
    },
  ],
  [
    'H900',
    {
      name: '초',
      nameForSearching: '초',
      code: 'H900',
    },
  ],
  [
    'H901',
    {
      name: '초과인',
      nameForSearching: '초과인',
      code: 'H901',
    },
  ],
  [
    'H902',
    {
      name: '초두구피',
      nameForSearching: '초두구피',
      code: 'H902',
    },
  ],
  [
    'H903',
    {
      name: '초목',
      nameForSearching: '초목',
      code: 'H903',
    },
  ],
  [
    'H904',
    {
      name: '초염',
      nameForSearching: '초염',
      code: 'H904',
    },
  ],
  [
    'H905',
    {
      name: '촉규화',
      nameForSearching: '촉규화',
      code: 'H905',
    },
  ],
  [
    'H906',
    {
      name: '추석',
      nameForSearching: '추석',
      code: 'H906',
    },
  ],
  [
    'H907',
    {
      name: '출미',
      nameForSearching: '출미',
      code: 'H907',
    },
  ],
  [
    'H908',
    {
      name: '취저목근',
      nameForSearching: '취저목근',
      code: 'H908',
    },
  ],
  [
    'H909',
    {
      name: '치두',
      nameForSearching: '치두',
      code: 'H909',
    },
  ],
  [
    'H910',
    {
      name: '침사',
      nameForSearching: '침사',
      code: 'H910',
    },
  ],
  [
    'H911',
    {
      name: '토간',
      nameForSearching: '토간',
      code: 'H911',
    },
  ],
  [
    'H912',
    {
      name: '토뇌',
      nameForSearching: '토뇌',
      code: 'H912',
    },
  ],
  [
    'H913',
    {
      name: '토도사',
      nameForSearching: '토도사',
      code: 'H913',
    },
  ],
  [
    'H914',
    {
      name: '토봉방',
      nameForSearching: '토봉방',
      code: 'H914',
    },
  ],
  [
    'H915',
    {
      name: '토시',
      nameForSearching: '토시',
      code: 'H915',
    },
  ],
  [
    'H916',
    {
      name: '토혈',
      nameForSearching: '토혈',
      code: 'H916',
    },
  ],
  [
    'H917',
    {
      name: '파자',
      nameForSearching: '파자',
      code: 'H917',
    },
  ],
  [
    'H918',
    {
      name: '파초',
      nameForSearching: '파초',
      code: 'H918',
    },
  ],
  [
    'H919',
    {
      name: '파초즙',
      nameForSearching: '파초즙',
      code: 'H919',
    },
  ],
  [
    'H920',
    {
      name: '팔각회향',
      nameForSearching: '팔각회향',
      code: 'H920',
    },
  ],
  [
    'H921',
    {
      name: '패고피',
      nameForSearching: '패고피',
      code: 'H921',
    },
  ],
  [
    'H922',
    {
      name: '패자',
      nameForSearching: '패자',
      code: 'H922',
    },
  ],
  [
    'H923',
    {
      name: '포도근',
      nameForSearching: '포도근',
      code: 'H923',
    },
  ],
  [
    'H924',
    {
      name: '풍엽',
      nameForSearching: '풍엽',
      code: 'H924',
    },
  ],
  [
    'H925',
    {
      name: '풍화초',
      nameForSearching: '풍화초',
      code: 'H925',
    },
  ],
  [
    'H926',
    {
      name: '피마자',
      nameForSearching: '피마자',
      code: 'H926',
    },
  ],
  [
    'H927',
    {
      name: '하',
      nameForSearching: '하',
      code: 'H927',
    },
  ],
  [
    'H928',
    {
      name: '하마',
      nameForSearching: '하마',
      code: 'H928',
    },
  ],
  [
    'H929',
    {
      name: '하엽',
      nameForSearching: '하엽',
      code: 'H929',
    },
  ],
  [
    'H930',
    {
      name: '한수석',
      nameForSearching: '한수석',
      code: 'H930',
    },
  ],
  [
    'H931',
    {
      name: '한식면',
      nameForSearching: '한식면',
      code: 'H931',
    },
  ],
  [
    'H932',
    {
      name: '해대',
      nameForSearching: '해대',
      code: 'H932',
    },
  ],
  [
    'H933',
    {
      name: '해분',
      nameForSearching: '해분',
      code: 'H933',
    },
  ],
  [
    'H934',
    {
      name: '해삼',
      nameForSearching: '해삼',
      code: 'H934',
    },
  ],
  [
    'H935',
    {
      name: '해석',
      nameForSearching: '해석',
      code: 'H935',
    },
  ],
  [
    'H936',
    {
      name: '해송자',
      nameForSearching: '해송자',
      code: 'H936',
    },
  ],
  [
    'H937',
    {
      name: '해송자유',
      nameForSearching: '해송자유',
      code: 'H937',
    },
  ],
  [
    'H938',
    {
      name: '해아다',
      nameForSearching: '해아다',
      code: 'H938',
    },
  ],
  [
    'H939',
    {
      name: '해합분',
      nameForSearching: '해합분',
      code: 'H939',
    },
  ],
  [
    'H940',
    {
      name: '행인유',
      nameForSearching: '행인유',
      code: 'H940',
    },
  ],
  [
    'H941',
    {
      name: '향당로내회',
      nameForSearching: '향당로내회',
      code: 'H941',
    },
  ],
  [
    'H942',
    {
      name: '향춘아',
      nameForSearching: '향춘아',
      code: 'H942',
    },
  ],
  [
    'H943',
    {
      name: '현각',
      nameForSearching: '현각',
      code: 'H943',
    },
  ],
  [
    'H944',
    {
      name: '현육',
      nameForSearching: '현육',
      code: 'H944',
    },
  ],
  [
    'H945',
    {
      name: '현정석',
      nameForSearching: '현정석',
      code: 'H945',
    },
  ],
  [
    'H946',
    {
      name: '형화',
      nameForSearching: '형화',
      code: 'H946',
    },
  ],
  [
    'H947',
    {
      name: '호경골',
      nameForSearching: '호경골',
      code: 'H947',
    },
  ],
  [
    'H948',
    {
      name: '호도외청피',
      nameForSearching: '호도외청피',
      code: 'H948',
    },
  ],
  [
    'H949',
    {
      name: '호동루',
      nameForSearching: '호동루',
      code: 'H949',
    },
  ],
  [
    'H950',
    {
      name: '호두골',
      nameForSearching: '호두골',
      code: 'H950',
    },
  ],
  [
    'H951',
    {
      name: '호로눈만',
      nameForSearching: '호로눈만',
      code: 'H951',
    },
  ],
  [
    'H952',
    {
      name: '호마유',
      nameForSearching: '호마유',
      code: 'H952',
    },
  ],
  [
    'H953',
    {
      name: '호유경',
      nameForSearching: '호유경',
      code: 'H953',
    },
  ],
  [
    'H954',
    {
      name: '호총',
      nameForSearching: '호총',
      code: 'H954',
    },
  ],
  [
    'H955',
    {
      name: '호황토',
      nameForSearching: '호황토',
      code: 'H955',
    },
  ],
  [
    'H956',
    {
      name: '홍국주',
      nameForSearching: '홍국주',
      code: 'H956',
    },
  ],
  [
    'H957',
    {
      name: '홍두구',
      nameForSearching: '홍두구',
      code: 'H957',
    },
  ],
  [
    'H958',
    {
      name: '홍반',
      nameForSearching: '홍반',
      code: 'H958',
    },
  ],
  [
    'H959',
    {
      name: '홍시',
      nameForSearching: '홍시',
      code: 'H959',
    },
  ],
  [
    'H960',
    {
      name: '홍촉규',
      nameForSearching: '홍촉규',
      code: 'H960',
    },
  ],
  [
    'H961',
    {
      name: '홍촉규자',
      nameForSearching: '홍촉규자',
      code: 'H961',
    },
  ],
  [
    'H962',
    {
      name: '화피',
      nameForSearching: '화피',
      code: 'H962',
    },
  ],
  [
    'H963',
    {
      name: '환육',
      nameForSearching: '환육',
      code: 'H963',
    },
  ],
  [
    'H964',
    {
      name: '황견',
      nameForSearching: '황견',
      code: 'H964',
    },
  ],
  [
    'H965',
    {
      name: '황단',
      nameForSearching: '황단',
      code: 'H965',
    },
  ],
  [
    'H966',
    {
      name: '황명교',
      nameForSearching: '황명교',
      code: 'H966',
    },
  ],
  [
    'H967',
    {
      name: '황반',
      nameForSearching: '황반',
      code: 'H967',
    },
  ],
  [
    'H968',
    {
      name: '황송절',
      nameForSearching: '황송절',
      code: 'H968',
    },
  ],
  [
    'H969',
    {
      name: '황약자',
      nameForSearching: '황약자',
      code: 'H969',
    },
  ],
  [
    'H970',
    {
      name: '황연향',
      nameForSearching: '황연향',
      code: 'H970',
    },
  ],
  [
    'H971',
    {
      name: '황우담즙',
      nameForSearching: '황우담즙',
      code: 'H971',
    },
  ],
  [
    'H972',
    {
      name: '황자계육',
      nameForSearching: '황자계육',
      code: 'H972',
    },
  ],
  [
    'H973',
    {
      name: '황주',
      nameForSearching: '황주',
      code: 'H973',
    },
  ],
  [
    'H974',
    {
      name: '황채봉과',
      nameForSearching: '황채봉과',
      code: 'H974',
    },
  ],
  [
    'H975',
    {
      name: '황촉규자',
      nameForSearching: '황촉규자',
      code: 'H975',
    },
  ],
  [
    'H976',
    {
      name: '황촉규화',
      nameForSearching: '황촉규화',
      code: 'H976',
    },
  ],
  [
    'H977',
    {
      name: '회어',
      nameForSearching: '회어',
      code: 'H977',
    },
  ],
  [
    'H978',
    {
      name: '훤초근',
      nameForSearching: '훤초근',
      code: 'H978',
    },
  ],
  [
    'H979',
    {
      name: '흑두',
      nameForSearching: '흑두',
      code: 'H979',
    },
  ],
  [
    'H980',
    {
      name: '흑사당',
      nameForSearching: '흑사당',
      code: 'H980',
    },
  ],
  [
    'H981',
    {
      name: '흑향',
      nameForSearching: '흑향',
      code: 'H981',
    },
  ],
  [
    'H982',
    {
      name: '방분',
      nameForSearching: '방분',
      code: 'H982',
    },
  ],
]);
