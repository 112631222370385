import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AdminLayout from '../../templates/AdminLayout';
import BasicSetting from 'components/UI/organisms/clinic/BasicSetting';
import NoticeSetting from 'components/UI/organisms/clinic/NoticeSetting';
import ClinicManagement from 'components/UI/organisms/clinic/ClinicManagement';
import MemberManagement from 'components/UI/organisms/clinic/MemberManagement';
import ClinicTab from '../../UI/organisms/clinic/ClinicTab';
import { TutorialModal } from 'components/UI/atoms';
import { observer } from 'mobx-react';
import { useStores } from 'stores/Context';

const SettingPage = () => {
  const location = useLocation();
  const { clientStore } = useStores();

  const [isOpenTutorialModal, setIsOpenTutorialModal] = useState<boolean>(false);

  const masterFlag = useMemo(
    () => clientStore.metaData?.userRole?.roleName === 'MASTER',
    [clientStore.metaData?.userRole?.roleName],
  );
  const tabList = useMemo(
    () => [
      {
        tabName: '기본 설정',
        content: <BasicSetting />,
      },
      { tabName: '알림 설정', content: <NoticeSetting /> },
      {
        tabName: '구성원 관리',
        content: <MemberManagement />,
      },
      {
        tabName: masterFlag ? '한의원 관리' : '소속 관리',
        content: <ClinicManagement role={clientStore.metaData?.userRole?.roleName} />,
      },
    ],
    [masterFlag],
  );
  const tab = sessionStorage.getItem('settingTab') ?? '0';
  const [selectedTab, setSelectedTab] = useState(tab);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('settingTab');
    };
  }, []);

  useEffect(() => {
    if (location.state?.tutorialFlag) {
      setIsOpenTutorialModal(true);
      window.history.replaceState({}, '');
    }
  }, [location.state?.tutorialFlag, clientStore.user?.config]);

  return (
    <>
      {isOpenTutorialModal && (
        <TutorialModal
          cancelFunction={async () => {
            setIsOpenTutorialModal(false);
          }}
        />
      )}
      <AdminLayout
        title={'설정'}
        content={<ClinicTab tabList={tabList} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />}
        sideType={0}
        // youtubeLinkParam={{
        //   title: 'Ye-jin Clinic Management',
        //   src: 'https://www.youtube.com/embed/BEfUQRlBO2Q?autoplay=1&start=',
        // }}
      />
    </>
  );
};

export default observer(SettingPage);
