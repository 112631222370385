import { API, Auth, graphqlOperation } from 'aws-amplify';
import { makeAutoObservable, runInAction } from 'mobx';
import { Clinic, CreateClinicInput, RoleByClinicQuery, UpdateClinicInput, UserRole } from '../API';
import ClinicRepository from '../repository/ClinicRepository';
import { RootStore } from './RootStore';
import * as queries from '../graphql/queries';
import { MetaData } from './ClientStore';
// import { stepPayApi } from 'api/steppay';
import {
  parseJwt,
  // getMonthPeriod,
  // parseDateString,
  // setTempSubscription
} from 'utils/util';
import _ from 'lodash';
import moment from 'moment';

export class ClinicStore {
  rootStore: RootStore;
  feedbackDate: number;
  clinic?: Clinic;
  clinics?: Clinic[] = [];
  roles: UserRole[] = [];
  currentUserRole: string;
  stepPayUserInfo: any = {};
  subscriptionInfo: any = {};
  nextToken?: string;
  loading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setFeedbackDate = (value: number) => {
    if (!(value < 0 && this.feedbackDate === 1)) {
      this.feedbackDate += value;
    }
  };

  getUserRoleInClinic = async (clinicId: string) => {
    const userRole = JSON.parse(
      parseJwt((await Auth.currentSession()).getIdToken().getJwtToken()).userRole,
    )?.[clinicId]?.roleName;
    return userRole;
  };

  isDoctor = () => {
    const userRole = this.currentUserRole;
    return ['MASTER', 'DOCTOR'].includes(userRole);
  };

  setClinicData = async (clinic: Clinic, getStepPayDataFlag = true) => {
    const { feedbackDate, promotion } = clinic;
    const parsedPromotion = _.isEmpty(promotion) ? {} : JSON.parse(promotion);
    this.clinic = {
      ...clinic,
      promotion: parsedPromotion,
    };
    this.feedbackDate = feedbackDate || 20;
    this.currentUserRole = await this.getUserRoleInClinic(clinic.id);
    // 한의원의 구독 정보를 세팅하는 코드. 당분간 요금제 비활성화하는 동안 주석 처리
    // if (getStepPayDataFlag) {
    //   // 가져온 클리닉 데이터로 스탭페이 내 최신 정보 가져옴
    //   if (clinic?.stepPayID) {
    //     try {
    //       const stepPayCustomerInfo = await stepPayApi({
    //         method: 'GET',
    //         uri: `/steppay/api/v1/customers/${clinic?.stepPayID}`,
    //       });
    //       if (!_.isEmpty(stepPayCustomerInfo) && stepPayCustomerInfo?.id) {
    //         this.stepPayUserInfo = stepPayCustomerInfo;
    //       } else if (stepPayCustomerInfo?.errorCode === 'xxx' && clinic.planInfo) {
    //         // 예진 DB에 스탭페이고객 id가 있으나, 고객 상세 정보를 가져오는 스탭페이 API가 문제가 있는 상황
    //         this.subscriptionInfo = setTempSubscription(clinic.planInfo);
    //         this.stepPayUserInfo = {};
    //       } else {
    //         this.stepPayUserInfo = {};
    //       }

    //       // 결제일 역순으로 과거 구독 목록 정렬 후 활성구독 find
    //       const subscriptionId = _.reverse(
    //         _.sortBy(this.stepPayUserInfo?.subscriptions ?? [], (e) => e.lastPaymentDateTime),
    //       )?.find((e) => e.status === 'ACTIVE')?.id;
    //       if (subscriptionId) {
    //         // 유효한 구독 id가 존재하면
    //         const subscriptionInfo = await stepPayApi({
    //           method: 'GET',
    //           uri: `/steppay/api/v1/subscriptions/${subscriptionId}`,
    //         });
    //         if (!_.isEmpty(subscriptionInfo) && subscriptionInfo?.id) {
    //           this.subscriptionInfo = subscriptionInfo;
    //         } else if (subscriptionInfo?.errorCode === 'xxx' && clinic.planInfo) {
    //           // 유효한 구독 id가 있으나, 구독 상세 정보를 가져오는 스탭페이 API가 문제가 있는 상황
    //           this.subscriptionInfo = setTempSubscription(clinic.planInfo);
    //         } else {
    //           this.subscriptionInfo = {};
    //         }
    //       }

    //       if (!_.isEmpty(this.subscriptionInfo)) {
    //         this.subscriptionInfo = {
    //           ...this.subscriptionInfo,
    //           lastPaymentDate: moment(this.subscriptionInfo.lastPaymentDate, 'YYYY-MM-DDTHH:mm:ss')
    //             .add(9, 'hour')
    //             .format('YYYY-MM-DDTHH:mm:ss'),
    //           nextPaymentDate: moment(this.subscriptionInfo.nextPaymentDate, 'YYYY-MM-DDTHH:mm:ss')
    //             .add(9, 'hour')
    //             .format('YYYY-MM-DDTHH:mm:ss'),
    //         };
    //       }
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }
    //   if (
    //     _.isObject(this.subscriptionInfo) &&
    //     !_.isEmpty(this.subscriptionInfo) &&
    //     //@ts-ignore
    //     this.subscriptionInfo?.price?.planDescription?.split('||')?.[1]
    //   ) {
    //     //@ts-ignore
    //     const limitInfo = JSON.parse(this.subscriptionInfo?.price?.planDescription?.split('||')?.[1]);
    //     this.subscriptionInfo = {
    //       ...this.subscriptionInfo,
    //       limitInfo,
    //     };
    //   }
    // }

    // console.log(JSON.parse(JSON.stringify(this.clinic)));
    // console.log(JSON.parse(JSON.stringify(this.stepPayUserInfo)));
    // console.log(JSON.parse(JSON.stringify(this.subscriptionInfo)));
  };

  getClinic = async (clinicID: string, getStepPayDataFlag = true) => {
    this.currentUserRole = undefined;
    const data = await ClinicRepository.getClinic(clinicID);
    await this.setClinicData(data.getClinic, getStepPayDataFlag);
    return { clinic: this.clinic, subscriptionInfo: this.subscriptionInfo };
  };

  getUsageStatistics = async ({ clinicID, dateRangeArr }: { clinicID: string; dateRangeArr?: string[] }) => {
    const res = await ClinicRepository.getUsageStatistics({ clinicID, dateRangeArr });
    // console.log(res);
    const resultObj = {};
    let totalNumberOfKaKaoSent = 0;
    let totalNumberOfSurveySentBySelf = 0;
    let totalNumberOfTreatment = 0;
    // let totalNumberOfTreatmentCompletion = 0;
    // let totalNumberOfTreatmentCompletionsWithPrescription = 0;
    _.forEach(res?.items, (e) => {
      totalNumberOfKaKaoSent += e.numberOfKakaoSent;
      totalNumberOfTreatment += e.numberOfTreatment;
      totalNumberOfSurveySentBySelf += e.numberOfSurveySentBySelf ?? 0;
      // totalNumberOfTreatmentCompletion += e.numberOfTreatmentCompletion;
      // totalNumberOfTreatmentCompletionsWithPrescription += e.numberOfTreatmentCompletionsWithPrescription;
      resultObj[e.recordDate] = {
        recordDate: e.recordDate,
        numberOfKakaoSent: e.numberOfKakaoSent,
        numberOfTreatment: e.numberOfTreatment,
        numberOfSurveySentBySelf: e.numberOfSurveySentBySelf ?? 0,
        // numberOfTreatmentCompletion: e.numberOfTreatmentCompletion,
        // numberOfTreatmentCompletionsWithPrescription: e.numberOfTreatmentCompletionsWithPrescription,
      };
    });
    return {
      totalNumberOfKaKaoSent,
      totalNumberOfTreatment,
      totalNumberOfSurveySentBySelf,
      // totalNumberOfTreatmentCompletion,
      // totalNumberOfTreatmentCompletionsWithPrescription,
      resultObj,
    };
  };

  updateClinic = async (updateClinic: UpdateClinicInput) => {
    await ClinicRepository.updateClinic(updateClinic);
  };

  resetNextToken = () => {
    this.nextToken = undefined;
  };

  resetClinics = () => {
    this.clinics = [];
  };

  resetClinic = () => {
    this.clinic = undefined;
    this.currentUserRole = undefined;
    this.feedbackDate = 20;
  };

  listRolesByClinic = async () => {
    const metaData: MetaData = JSON.parse(sessionStorage.getItem('MetaData')!);

    const roles = (await API.graphql(
      graphqlOperation(
        queries.roleByClinic,
        { clinicID: metaData.userRole.clinicID },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: RoleByClinicQuery };
    // console.log(roles);

    runInAction(() => {
      this.roles = roles.data.roleByClinic!.items.map((item) => item as UserRole);
    });
    // console.log('권한 리스트', this.roles);
  };

  getStatistics = async (clinicId: string) => {
    // 주석처리된 것은 스탭페이 요금제가 반영되었을 시절에 사용한 코드, 원복의 가능성이 있어 주석으로 남김

    // let inputDateFormat = 'YYYYMMDD';
    const outputDateFormat = 'YYYYMMDD';
    // const startDt = clinic?.subscriptionInfo?.lastPaymentDate;
    // const endDt = clinic?.subscriptionInfo?.nextPaymentDate;
    const startDt = moment().startOf('month').format(outputDateFormat);
    const endDt = moment().endOf('month').format(outputDateFormat);
    // let period;
    // if (startDt && endDt) {
    //   inputDateFormat = 'YYYY-MM-DDTHH:mm:ss';
    //   period = [startDt, endDt];
    // } else {
    //   period = getMonthPeriod({
    //     inputDate: clinic?.clinic?.createdAt,
    //     inputDateFormat: 'YYYY-MM-DDTHH:mm:ss.sssZ',
    //   });
    // }
    const statRes = await this.getUsageStatistics({
      clinicID: clinicId,
      dateRangeArr: [
        // parseDateString(period[0], inputDateFormat, outputDateFormat),
        // parseDateString(period[1], inputDateFormat, outputDateFormat),
        startDt,
        endDt,
      ],
    });

    return statRes;
  };
}
