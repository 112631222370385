import { Row, Col, TypographyText } from 'components/UI/atoms';
import { Term } from 'components/UI/molecules';

const SignUpStep1 = ({
  signUpType,
  setSignUpType,
  setSignupStep,
  service,
  setService,
  privacy,
  setPrivacy,
  marketing,
  setMarketing,
}: {
  signUpType: string;
  setSignUpType: any;
  setSignupStep: any;
  service: boolean;
  setService: (value: boolean) => void;
  privacy: boolean;
  setPrivacy: (value: boolean) => void;
  marketing: boolean;
  setMarketing: (value: boolean) => void;
}) => {
  return (
    <Row style={{ display: 'flex', flexDirection: 'column' }}>
      <Row
        style={{
          display: 'flex',
          width: '100%',
          columnGap: '0.3rem',
        }}
      >
        <Col
          style={{
            padding: '1.25rem 1.5rem 1rem 1.5rem',
            display: 'flex',
            height: '9.1rem',
            flex: 1,
            border: signUpType === 'academy' ? '1px solid #346AFF' : '1px solid #c4c4c4',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '0.75rem',
            cursor: 'pointer',
          }}
          onClick={() => setSignUpType('academy')}
        >
          <Row
            style={{
              display: 'flex',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/academy-logo.webp`}
              style={{ width: '2.7rem', cursor: 'pointer' }}
              alt={`checked`}
            />
          </Row>
          <Row
            style={{
              display: 'flex',
              textAlign: 'center',
            }}
          >
            <TypographyText
              color={signUpType === 'academy' ? '#346AFF' : '#c4c4c4'}
              lineHeight={`1.7rem`}
              fontSize={`1.2rem`}
              whiteSpace="pre-wrap"
              wordBreak="keep-all"
            >
              {`대한융합한의학회 회원 가입하기`}
            </TypographyText>
          </Row>
        </Col>
        <Col
          style={{
            padding: '1.25rem 1.5rem 1rem 1.5rem',
            display: 'flex',
            height: '9.1rem',
            flex: 1,
            border: signUpType === 'direct' ? '1px solid #346AFF' : '1px solid #c4c4c4',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setSignUpType('direct')}
        >
          <Row
            style={{
              display: 'flex',
              textAlign: 'center',
            }}
          >
            <TypographyText
              color={signUpType === 'direct' ? '#346AFF' : '#c4c4c4'}
              lineHeight={`1.7rem`}
              fontSize={`1.2rem`}
              whiteSpace="pre-wrap"
              wordBreak="keep-all"
            >
              {`비회원 직접 가입하기`}
            </TypographyText>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: '0.75rem',
          marginBottom: '2rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.75rem',
        }}
      >
        <Row
          style={{
            display: 'flex',
          }}
        >
          <TypographyText whiteSpace="pre-wrap" wordBreak="keep-all" lineHeight={`1.2em`} fontSize={`0.8rem`}>
            {`대한융합한의학회 회원인 경우, 대한융합한의학회에 가입하신 이메일로 회원가입을 진행해주세요. 
한의대 학생인 경우, 대한융합한의학회 회원만 가입 가능합니다.`}
          </TypographyText>
        </Row>
        <Row
          style={{
            display: 'flex',
            cursor: 'pointer',
          }}
          onClick={() => window.open('https://ackm.org/', '_blank')}
        >
          <TypographyText
            color={'#1d1c1d'}
            fontWeight={700}
            fontSize={`0.8rem`}
            lineHeight="0.8rem"
            textDecoration="underline"
          >
            {`대한융합한의학회 홈페이지 바로가기`}
          </TypographyText>
        </Row>
      </Row>
      <Term
        isCheckedServiceTerm={service}
        isCheckedPrivacyTerm={privacy}
        isCheckedMarketingTerm={marketing}
        onChangeServiceTerm={(value) => setService(value)}
        onChangePrivacyTerm={(value) => setPrivacy(value)}
        onChangeMarketingTerm={(value) => setMarketing(value)}
      />
      <Row
        style={{
          marginTop: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '3.6rem',
          cursor: 'pointer',
          background: service && privacy && signUpType?.length ? '#346AFF' : '#c4c4c4',
          borderRadius: '3px',
        }}
        onClick={() => {
          if (service && privacy && signUpType?.length) setSignupStep('step2');
        }}
      >
        <TypographyText
          color="#ffffff"
          fontWeight={700}
          fontSize="1.2rem"
          lineHeight="1.2rem"
        >{`예진 회원가입하기`}</TypographyText>
      </Row>
    </Row>
  );
};

export default SignUpStep1;
