import { css } from '@emotion/css';
import { TextField, TextFieldParams } from '../atoms/inputs/TextField';

const inputStyle = css`
  input {
    border-radius: 3px;
    height: 3.2rem;
    width: 20rem;
    align-items: center;
  }
  label {
    color: #1d1c1d;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.5%;
  }
`;

export const Input = ({ ...rest }: TextFieldParams) => {
  return (
    <TextField
      {...rest}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className={inputStyle}
      margin={'0.2rem 0'}
    />
  );
};
