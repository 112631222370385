import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

export class PatientPageStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
}
