import styled from '@emotion/styled';

interface ProgressItemParams {
  name: string;
  active?: boolean;
}

const ProgressItem = ({ name, active = false }: ProgressItemParams) => {
  const StyledItem = styled.div`
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #e5e5e5;
    border-bottom: 0.3rem solid #e5e5e5;
    flex: 1;

    padding-bottom: 0.9rem;

    &.yejin-progress--true {
      color: #39576f;
      border-bottom: 0.3rem solid #39576f;
    }
  `;

  return (
    <StyledItem className={`yejin-progress--${active}`}>
      <div>{name}</div>
    </StyledItem>
  );
};

export default ProgressItem;
