import { makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';
import { Treatment, UpdateTreatmentInput } from '../API';

import TreatmentRepository from '../repository/TreatmentRepository';
import { RootStore } from './RootStore';

class TreatmentStore {
  rootStore: RootStore;
  treatment?: Treatment;
  treatments: Treatment[] = [];
  checkedSurvey: Set<string> = new Set();
  // checkedSurvey는 증상/변증 탭에서 의사가 중요로 표기한 설문코드 집합
  checkedSymptom: Set<string> = new Set();
  // checkedSymptom은 설문으로 수집된 증상코드가 아닌 진료 과정에서 의사가 사진으로 선택한 증상코드들의 집합
  selectedBCode: { code: string; relativeSCode: { code: string; contribution: number }[] }[] = [];
  // selectedBCode는 한의사가 선택한 중요 변증 정보 집합
  loading: boolean = false;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }
  treatmentReset = () => {
    this.treatment = undefined;
    this.treatments = [];
    this.selectedBCode = [];
    this.checkedSurvey.clear();
    this.checkedSymptom.clear();
  };

  setTreatmentData = (treatment: Treatment) => {
    const {
      surveyCheck = [], // 중요 체크된 설문
      symptomCheck = [], // 사상
      selectedBCode = [],
    } = treatment;
    // console.log(JSON.parse(JSON.stringify(treatment.survey)));
    // console.log(JSON.parse(JSON.stringify(treatment.selectedBCode)));
    this.selectedBCode = selectedBCode?.map((e) => JSON.parse(e)) ?? [];
    this.checkedSurvey = new Set(surveyCheck || []);
    this.checkedSymptom = new Set(symptomCheck || []);
  };

  getTreatment = async (treatmentID: string) => {
    const response = await TreatmentRepository.getTreatment(treatmentID);
    const treatment = response.getTreatment as Treatment;
    if (
      treatment?.clinicID === this.rootStore.clientStore?.metaData?.userRole?.clinicID &&
      treatment?.isDeleted !== true
    ) {
      this.treatment = treatment;
    } else {
      this.treatment = null;
    }
    if (!_.isEmpty(this.treatment)) {
      this.setTreatmentData(this.treatment);
    }
    return treatment;
  };

  refreshTreatmentSurvey = async (treatmentID: string) => {
    const response = await TreatmentRepository.getTreatment(treatmentID);
    const treatment = response.getTreatment as Treatment;
    if (!_.isEmpty(this.treatment) && treatment.survey) {
      this.treatment = { ...this.treatment, survey: treatment.survey };
    }
    return treatment;
  };

  updateTreatment = async (params: UpdateTreatmentInput, refreshFlag) => {
    await TreatmentRepository.updateTreatment(params);
    if (refreshFlag) {
      await this.getTreatment(params.id);
    }
  };

  resetTreatmentsOnly = () => {
    this.treatments = [];
  };

  treatmentPatientByDate = async ({
    patientID,
    treatmentedAt,
  }: {
    patientID: string;
    treatmentedAt?: string[];
  }) => {
    this.loading = true;
    const data = await TreatmentRepository.treatmentPatientByDate({ patientID, treatmentedAt });
    runInAction(() => {
      this.loading = false;
      this.treatments = data!.items.map((item) => item as Treatment);
    });
  };

  addCheckedSurvey = (surveyCode: string) => {
    this.checkedSurvey.add(surveyCode);
  };

  deleteCheckedSurvey = (surveyCode: string) => {
    this.checkedSurvey.delete(surveyCode);
  };

  setCheckedSurvey = (surveyCodes: string[]) => {
    this.checkedSurvey = new Set(surveyCodes);
  };

  addCheckedSymptom = (symptomCode: string) => {
    this.checkedSymptom.add(symptomCode);
  };

  deleteCheckedSymptom = (symptomCode: string) => {
    this.checkedSymptom.delete(symptomCode);
  };

  /**
   * 중요 변증 추가
   * @param input @type { code: string; relativeSCode: { code: string; contribution: number }
   */
  addSelectedBCode(input: { code: string; relativeSCode: { code: string; contribution: number }[] }) {
    this.selectedBCode.push(input);
  }

  /**
   * 중요 변증 삭제
   * @param code @type {string}
   */
  deleteSelectedBCode(code: string) {
    this.selectedBCode = _.filter(this.selectedBCode, (item) => item.code !== code);
  }
}

export default TreatmentStore;
