import { Flex, Text } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import LandingLayout from 'components/templates/LandingLayout';
import { Button } from 'components/UI/atoms/inputs/Button';
import { TextField } from 'components/UI/atoms/inputs/TextField';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import message from 'antd-message';
import { emailValidationCheck } from 'utils/util';
import { Row, ModalWithJustX } from 'components/UI/atoms';
import { GuideButton } from 'components/UI/molecules';

const PasswordResetPage = () => {
  const [valid, setValid] = useState(false);
  const [isOpenMediaModal, setIsOpenMediaModal] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const resetPaswordHandler = () => {
    Auth.forgotPassword(email)
      .then(() => {
        message.success(`이메일을 통해 인증번호를 확인해주세요.`, 2500);
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'UserNotFoundException') {
          message.error(`존재하지 않는 계정입니다.`, 2500);
        } else {
          message.error(`알수없는 에러가 발생했습니다. ${error}`, 2500);
        }
      });
  };
  const content = (
    <Flex direction={'column'} alignItems={'center'}>
      <ModalWithJustX
        isOpen={isOpenMediaModal}
        width={`48rem`}
        height={`27rem`}
        content={
          <Row
            style={{
              position: 'relative',
              width: '100%',
              paddingBottom: '56.25%',
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
              }}
              src={`https://www.youtube.com/embed/e5l75OuvL_s?autoplay=1&start=57`}
              title="Ye-jin Signup"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
            />
          </Row>
        }
        cancelFunction={() => setIsOpenMediaModal(false)}
      />
      <Flex direction={'column'}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '3rem 0',
          }}
        >
          <Text fontSize={'1.6rem'} style={{ whiteSpace: 'pre-wrap' }}>
            {`본인확인을 위해 가입 시
입력한 이메일을 입력해주세요.`}
          </Text>
          {/* <GuideButton buttonText="동영상 가이드" onClickFunc={() => setIsOpenMediaModal(true)} /> */}
        </Row>

        <TextField
          label={'email'}
          labelHidden={true}
          placeholder={'아이디(이메일)'}
          fontSize={'1rem'}
          fontWeight={400}
          autoComplete="off"
          onChange={(e: any) => {
            setEmail(e.target.value);
            if (emailValidationCheck(e.target.value)) {
              setValid(true);
            } else {
              setValid(false);
            }
          }}
          hasError={!!email?.length && !emailValidationCheck(email)}
        ></TextField>

        {!!email?.length && !emailValidationCheck(email) && (
          <Text color={'#DF635C'} fontSize="0.8rem">
            이메일 형식이 맞는지 확인해주세요.
          </Text>
        )}

        {valid ? (
          <Button
            marginTop={'1rem'}
            width={'29.2rem'}
            name={'black'}
            style={{ background: '#346AFF' }}
            onClick={resetPaswordHandler}
          >
            이메일로 인증번호 받기
          </Button>
        ) : (
          <Button
            backgroundColor={'#AFAFAF'}
            isDisabled={true}
            marginTop={'1rem'}
            width={'29.2rem'}
            name={'black'}
          >
            이메일로 인증번호 받기
          </Button>
        )}
      </Flex>
    </Flex>
  );
  return <LandingLayout content={content} />;
};
export default PasswordResetPage;
