/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Loader } from '@aws-amplify/ui-react';
import message from 'antd-message';
import _ from 'lodash';
import { css } from '@emotion/css';

import { useStores } from 'stores/Context';
import { codeMap } from 'utils/code';
import { TypographyText, Row, Col, Divider } from 'components/UI/atoms';
import { TooltipCard } from 'components/UI/molecules';
import { getRootFontSize, checkIGa, parseByeonjeungData, getBrowser } from 'utils/util';
import { convertOnsetValueToString } from 'utils/DataConvertUtil';
import { getSummarySurveyData, selectSurvey } from 'utils/util';
import { BoldText } from 'utils/ComponentUtil';

import Palgang from './Palgang';

const TreatmentAnalysis = ({ setIsFullScreen, isFullScreen, updateFootprintsLog }) => {
  const { id } = useParams();
  const [highlight, setHighlight] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenTooltip1, setIsOpenTooltip1] = useState(false);
  const [isOpenTooltip2, setIsOpenTooltip2] = useState(false);
  const [selectedByeonjeungIndex, setSelectedByeonjeungIndex] = useState(0);
  const { treatmentStore, symptomStore, surveyStore } = useStores();

  const getTreatment = useCallback(async () => {
    try {
      await setIsLoading(true);
      const { recommenededHerbs } = await symptomStore.getRecommendation({
        symptoms: [...(treatmentStore.treatment?.selectSymptom ?? [])],
        mode: 'byeonjeung',
      });
      await symptomStore.getPatientAnalysis(recommenededHerbs);
      setHighlight(treatmentStore.selectedBCode.reduce((a, c) => ({ ...a, [c.code]: true }), {}));
    } catch (e) {
      message.error(e, 2500);
    } finally {
      await setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getTreatment();
    updateFootprintsLog('A');
    return () => {
      symptomStore.resetAnalysisResult();
    };
  }, []);

  const surveyData = useMemo(() => {
    return selectSurvey(treatmentStore.treatment?.survey?.symptoms!);
  }, [treatmentStore.treatment?.survey?.symptoms]);

  const byeonjeungData = useMemo(
    () =>
      parseByeonjeungData({
        b: symptomStore.analysisResult,
        selectedBCodeArray: _.map(treatmentStore.selectedBCode ?? [], (e) => e.code),
      }),
    [symptomStore.analysisResult, treatmentStore.selectedBCode],
  );

  const byeonjeungDesc = useMemo(() => {
    const targetB = byeonjeungData.변증?.[selectedByeonjeungIndex];
    if (!_.isEmpty(targetB?.relativeSymptomCode)) {
      const surveySet = new Set();

      _.forEach(targetB.relativeSymptomCode, (s, i) => {
        const relatedSurveys = _.filter(surveyData, (e) => {
          const sData = codeMap.get(e.code);
          return ['Check', 'Radio'].includes(sData?.selectType)
            ? sData?.answers?.findIndex((a) => a?.symptomCode?.includes(s.code)) > -1
            : sData?.symptomCode?.includes(s.code);
        });
        _.forEach(relatedSurveys, (e) => {
          if (!surveySet.has(e.code)) {
            surveySet.add(e.code);
          }
        });
      });
      const result = [];
      const showOnlyLabelFlag = surveySet.size > 3; // 관련 증상이 3개를 넘어가면 레이블만 보여줌
      surveySet.forEach((surveyCode: string) => {
        surveyData
          .filter((sd) => sd.code === surveyCode)
          .reduce((a, c) => {
            if (_.isArray(c?.value)) {
              const newValue = [];
              c.value
                .filter((e) => e !== -1 && typeof e === 'number')
                .forEach((v) => {
                  newValue.push({ code: c.code, value: [v] });
                });
              return [...a, ...newValue];
            } else {
              return [...a, c];
            }
          }, [])
          .forEach((symptomData) => {
            const {
              symptom,
              plainStringValue,
              codeData: data,
              desc,
            } = getSummarySurveyData(symptomData, 'byeonjeung');
            const surveyComponent = (
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Row
                  style={{
                    display: 'flex',
                    border: '1px solid #e5e5e5',
                  }}
                >
                  <Col
                    style={{
                      width: '6.4rem',
                      padding: '0.75rem 1rem',
                      background: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <TypographyText
                      fontWeight={700}
                      fontSize="0.9rem"
                      lineHeight="1.08rem"
                      whiteSpace={'pre-wrap'}
                      wordBreak={'keep-all'}
                    >
                      {data?.label}
                    </TypographyText>
                  </Col>
                  <Col
                    style={{
                      flex: 1,
                      padding: '0.75rem 1rem',
                      display: 'flex',
                      alignContent: 'center',
                    }}
                  >
                    <TypographyText
                      whiteSpace="pre-wrap"
                      wordBreak="keep-all"
                      fontSize="0.9rem"
                      lineHeight="1.08rem"
                      style={desc?.length ? { paddingBottom: '0.6rem', display: 'block' } : {}}
                    >
                      {plainStringValue ? plainStringValue : data?.desc?.join(', ')}
                    </TypographyText>
                    {!!desc?.length && (
                      <TypographyText
                        yjTypeTypographyText="symptom-table-body"
                        fontSize="0.9rem"
                        lineHeight="1.08rem"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {desc}
                      </TypographyText>
                    )}
                  </Col>
                </Row>
                {!showOnlyLabelFlag && symptom?.value?.scale >= 0 && (
                  <Row
                    style={{
                      display: 'flex',
                      border: '1px solid #e5e5e5',
                      borderTop: 'none',
                    }}
                  >
                    <Col
                      style={{
                        width: '6.4rem',
                        padding: '0.75rem 1rem',
                        background: '#F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <TypographyText
                        fontWeight={700}
                        whiteSpace={'pre-wrap'}
                        wordBreak={'keep-all'}
                        fontSize="0.9rem"
                        lineHeight="1.08rem"
                      >
                        {`불편한 정도`}
                      </TypographyText>
                    </Col>
                    <Col
                      style={{
                        flex: 1,
                        padding: '0.75rem 1rem',
                        display: 'flex',
                        alignContent: 'center',
                      }}
                    >
                      <Col
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          columnGap: '0.4rem',
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/img/survey_icon_${symptom.value.scale}.webp`}
                          style={{ width: '1.1rem' }}
                          alt={`${symptom.code}${symptom.value.scale}`}
                        />
                        <TypographyText yjTypeTypographyText="symptom-table-body" fontSize="0.9rem">
                          {symptom.value.scale}
                        </TypographyText>
                      </Col>
                    </Col>
                  </Row>
                )}
                {!showOnlyLabelFlag && symptom?.value?.onset >= 0 && (
                  <Row
                    style={{
                      display: 'flex',
                      border: '1px solid #e5e5e5',
                      borderTop: 'none',
                    }}
                  >
                    <Col
                      style={{
                        width: '6.4rem',
                        padding: '0.75rem 1rem',
                        background: '#F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <TypographyText
                        fontWeight={700}
                        whiteSpace={'pre-wrap'}
                        wordBreak={'keep-all'}
                        fontSize="0.9rem"
                        lineHeight="1.08rem"
                      >
                        {`발생시기`}
                      </TypographyText>
                    </Col>
                    <Col
                      style={{
                        flex: 1,
                        padding: '0.75rem 1rem',
                        display: 'flex',
                        alignContent: 'center',
                      }}
                    >
                      <Col
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <TypographyText
                          yjTypeTypographyText="symptom-table-body"
                          whiteSpace="pre"
                          wordBreak="keep-all"
                          fontSize="0.9rem"
                        >
                          {convertOnsetValueToString(symptom.value.onset)}
                        </TypographyText>
                      </Col>
                    </Col>
                  </Row>
                )}
              </Row>
            );
            result.push(surveyComponent);
          });
      });
      return result;
    } else {
      return <></>;
    }
  }, [byeonjeungData.변증, selectedByeonjeungIndex, surveyData]);

  return (
    <Row
      style={{
        display: 'flex',
        height: '100%',
      }}
    >
      <Col
        style={{
          flex: 320,
          maxWidth: 'calc(100% / 3 * 2 / 100 * 48)',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          paddingTop: '2rem',
          paddingRight: '1rem',
          paddingBottom: '0.5rem',
          borderRight: '1px solid #e5e5e5',
          overflow: 'auto',
        }}
        className={css`
          overflow-x: hidden !important;
          &::-webkit-scrollbar {
            background-color: white;
            width: 0.25rem;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #e5e5e5;
            border-radius: 1rem;
            cursor: pointer;
          }
        `}
      >
        <Palgang
          patientName={treatmentStore.treatment?.name}
          byeonjeungData={byeonjeungData}
          isLoading={symptomStore.isloadingForPatientAnalysis || isLoading}
        />
      </Col>
      <Col
        style={{
          flex: 347,
          display: 'flex',
          maxWidth: 'calc(100% / 3 * 2 / 1000 * 520)',
          flexDirection: 'column',
          height: '100%',
          paddingTop: '2rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '0.5rem',
          borderRight: '1px solid #e5e5e5',
          overflow: 'auto',
        }}
        className={css`
          overflow-x: hidden !important;
          &::-webkit-scrollbar {
            background-color: white;
            width: 0.25rem;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #e5e5e5;
            border-radius: 1rem;
            cursor: pointer;
          }
        `}
      >
        <Row
          style={{
            display: 'flex',
            columnGap: '0.3rem',
            marginBottom: '1rem',
          }}
        >
          <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2rem">
            변증
          </TypographyText>
          <TypographyText fontSize={'1.2rem'} lineHeight="1.2rem">
            辨證
          </TypographyText>
        </Row>
        <Row
          style={{
            height: '2.7rem',
            minHeight: '2.7rem',
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <TypographyText fontSize={'0.9rem'} lineHeight="1.2rem" letterSpacing="-0.01em" whiteSpace="pre">
            {`환자에게 변증 결과 발송 시, 상위 3개 변증이 발송되며,
중요 변증을 선택하면 해당 변증만 발송됩니다.`}
          </TypographyText>
        </Row>
        {symptomStore.isloadingForPatientAnalysis || isLoading ? (
          <Row
            style={{
              display: 'flex',
              height: '23.5rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
          </Row>
        ) : (
          <Row>
            <Row
              id="analysis-byeonjeung-thead"
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: '0.75rem',
                paddingRight: '0.75rem',
                height: '2.1rem',
                background: '#f5f5f5',
                border: '1px solid #e5e5e5',
                borderBottom: 'none',
              }}
            >
              <Col
                style={{
                  display: 'flex',
                  columnGap: '0.15rem',
                  alignItems: 'center',
                }}
              >
                <TypographyText fontSize="0.8rem">{`변증`}</TypographyText>
                <Col
                  style={{ display: 'flex', cursor: 'pointer' }}
                  onMouseOver={() => setIsOpenTooltip1(true)}
                  onMouseOut={() => setIsOpenTooltip1(false)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/question-icon-white.webp`}
                    style={{ width: '0.7rem', cursor: 'pointer' }}
                    alt="question-icon"
                  />
                </Col>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  columnGap: '0.15rem',
                  alignItems: 'center',
                  width: '2.3rem',
                }}
              >
                <TypographyText fontSize="0.8rem" whiteSpace="pre">{`중요`}</TypographyText>
                <Col
                  style={{ display: 'flex', cursor: 'pointer' }}
                  onMouseOver={() => setIsOpenTooltip2(true)}
                  onMouseOut={() => setIsOpenTooltip2(false)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/question-icon-white.webp`}
                    style={{ width: '0.7rem', cursor: 'pointer' }}
                    alt="question-icon"
                  />
                </Col>
              </Col>
              <TooltipCard
                title="각 변증 별 관련 증상 확인하기"
                isOpen={isOpenTooltip1}
                targetComponentId="analysis-byeonjeung-thead"
                topRem={-3.75}
                leftRem={-0.05}
                description={`변증(타이틀 또는 막대그래프)을 선택하면 우측 영역에 
해당 변증이 도출된 원인을 환자의 증상 별로 보여드립니다.`}
              />
              <TooltipCard
                title="중요 변증이란?"
                isOpen={isOpenTooltip2}
                targetComponentId="analysis-byeonjeung-thead"
                topRem={-5.71}
                rightRem={0}
                description={`변증 선택 시, 약재 추천 알고리즘에 가중치가 반영됩니다.
환자에게 변증 결과 발송 시, 상위 3개 변증이 발송되며,
중요 변증을 선택하면 해당 변증만 발송됩니다.
(중복 선택 가능)`}
              />
            </Row>
            {byeonjeungData?.변증.length ? (
              byeonjeungData.변증?.map((e, i) => {
                return (
                  <Row
                    key={i}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #e5e5e5',
                      borderTop: 'none',
                      cursor: 'pointer',
                      padding: '0.6rem 0.75rem',
                    }}
                  >
                    <Col
                      style={{
                        flex: 1,
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                        rowGap: '0.5rem',
                      }}
                      onClick={() => {
                        setSelectedByeonjeungIndex(i);
                      }}
                    >
                      {i === selectedByeonjeungIndex && (
                        <Col
                          style={{
                            position: 'absolute',
                            top: '-0.6rem',
                            left: '-0.75rem',
                            width: '0.25rem',
                            height: 'calc(100% + 1.2rem)',
                            background: '#52D2B4',
                          }}
                        ></Col>
                      )}
                      <Row
                        style={{
                          display: 'flex',
                          columnGap: '0.5rem',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: '1.7rem',
                        }}
                      >
                        <Col
                          style={{
                            width: '4.2rem',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            position: 'relative',
                          }}
                        >
                          <TypographyText
                            fontWeight={700}
                            fontSize={'0.9rem'}
                            lineHeight="1.05rem"
                            style={{ whiteSpace: 'pre-wrap', wordBreak: 'keep-all' }}
                          >
                            {`${e.koreanName} ${e.chineseName}`}
                          </TypographyText>
                        </Col>
                        <Col
                          style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                          }}
                        >
                          <div className={`flex flex-1 h-6.8`}>
                            <div
                              className={`${i === selectedByeonjeungIndex ? 'bg-52D2B4' : 'bg-FBE840'}`}
                              style={{ flex: !isLoading && e.weight, transition: 'all 0.4s ease' }}
                            />
                            <div
                              className={`bg-F0F7FA ${isLoading ? 'flex-1' : ''}`}
                              style={{ flex: !isLoading && 100 - e.weight }}
                            />
                          </div>
                        </Col>
                        <Col
                          style={{
                            width: '2.5rem',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <TypographyText
                            fontSize={'0.9rem'}
                            lineHeight="1.7rem"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {`${e.weight}%`}
                          </TypographyText>
                        </Col>
                        <Col
                          style={{
                            width: '2.3rem',
                          }}
                        >
                          <Col
                            onClick={async (event) => {
                              event.preventDefault();
                              event.stopPropagation(); //event capturing
                              if (highlight[e.code]) {
                                await setHighlight({ ...highlight, [e.code]: false });
                                await treatmentStore.deleteSelectedBCode(e.code);
                              } else {
                                await setHighlight({ ...highlight, [e.code]: true });
                                await treatmentStore.addSelectedBCode({
                                  code: e.code,
                                  relativeSCode: e.relativeSymptomCode,
                                });
                              }
                            }}
                            yjTypeCol={highlight[e.code] ? 'selected-check-button' : 'check-button'}
                            style={{
                              width: '1.4rem',
                              minWidth: 'unset',
                              height: 'auto',
                              margin: '0 auto',
                            }}
                          >
                            <Row
                              yjTypeRow="flex"
                              style={{
                                width: 'fit-content',
                              }}
                            >
                              <Col
                                yjTypeCol={
                                  highlight[e.code]
                                    ? 'selected-survey-checkbox-wrapper'
                                    : 'survey-checkbox-wrapper'
                                }
                                style={{ margin: '0' }}
                              >
                                <Col
                                  yjTypeCol={
                                    highlight[e.code] ? 'selected-survey-checkbox' : 'survey-checkbox'
                                  }
                                ></Col>
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display: 'flex',
                          columnGap: '0.75rem',
                        }}
                      >
                        <Col
                          style={{
                            flex: 1,
                            display: 'flex',
                          }}
                        >
                          <TypographyText
                            fontSize={isFullScreen ? '0.9rem' : '0.7rem'}
                            lineHeight="1.2em"
                            whiteSpace="pre-wrap"
                            wordBreak="keep-all"
                          >
                            {BoldText({
                              text: e.koreanDesc,
                              boldText: '주로 나타나는 증상',
                            })}
                          </TypographyText>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <Row
                style={{
                  border: '1px solid #e5e5e5',
                  borderTop: 'none',
                  height: '3.6rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TypographyText
                  yjTypeTypographyText="symptom-table-body"
                  color="#afafaf"
                  fontSize="0.9rem"
                  lineHeight="1.7rem"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {`한의사의 진단이 필요합니다.`}
                </TypographyText>
              </Row>
            )}
          </Row>
        )}
      </Col>
      <Col
        style={{
          flex: 334,
          position: 'relative',
          display: 'flex',
          maxWidth: 'calc(100% / 3)',
          flexDirection: 'column',
          height: '100%',
          paddingTop: '2rem',
          paddingLeft: '1rem',
          paddingBottom: '0.5rem',
          overflow: 'auto',
        }}
        className={css`
          overflow-x: hidden !important;
          &::-webkit-scrollbar {
            background-color: white;
            width: 0.25rem;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #e5e5e5;
            border-radius: 1rem;
            cursor: pointer;
          }
        `}
      >
        {symptomStore.isloadingForPatientAnalysis || isLoading ? (
          <Row
            style={{
              display: 'flex',
              height: `${(getRootFontSize() * 16.5 * 5) / 4}px`,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
          </Row>
        ) : _.isEmpty(byeonjeungData?.변증?.[selectedByeonjeungIndex]) ? (
          <Row
            style={{
              textAlign: 'center',
            }}
          >
            <Divider marginRem={4.1} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
            <TypographyText
              yjTypeTypographyText="symptom-table-body"
              color="#afafaf"
              fontSize="0.9rem"
              lineHeight="1.7rem"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {`한의사의 진단이 필요합니다.`}
            </TypographyText>
          </Row>
        ) : (
          <>
            <div
              className="flex absolute right-0 top-7.2 cursor-pointer"
              onClick={() => {
                if (isFullScreen) {
                  if (getBrowser() === 'Safari') {
                    //@ts-ignore
                    document.webkitExitFullscreen();
                  } else {
                    document.exitFullscreen();
                  }
                  setIsFullScreen(false);
                } else {
                  if (getBrowser() === 'Safari') {
                    //@ts-ignore
                    document.documentElement.webkitRequestFullscreen();
                  } else {
                    document.documentElement.requestFullscreen();
                  }
                  setIsFullScreen(true);
                }
              }}
            >
              <button
                className="treatment-detail-button"
                style={{
                  height: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0.45rem 0.75rem',
                  borderRadius: '3px',
                  background: '#EEEFF3',
                }}
              >
                <TypographyText fontWeight={700} fontSize={'0.8rem'} lineHeight="1em" whiteSpace="pre">
                  {isFullScreen ? '전체화면 닫기' : '전체화면 보기'}
                </TypographyText>
              </button>
            </div>
            <Row
              style={{
                display: 'flex',
                columnGap: '0.3rem',
                marginBottom: '1rem',
              }}
            >
              <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2rem">
                {`${byeonjeungData?.변증?.[selectedByeonjeungIndex]?.koreanName}`}
              </TypographyText>
              <TypographyText fontSize={'1.2rem'} lineHeight="1.2rem">
                {`${byeonjeungData?.변증?.[selectedByeonjeungIndex]?.chineseName}`}
              </TypographyText>
            </Row>
            <Row style={{ height: '2.7rem', minHeight: '2.7rem', display: 'flex', alignItems: 'flex-start' }}>
              <TypographyText
                fontSize={'0.9rem'}
                letterSpacing="-0.01em"
                wordBreak="keep-all"
                style={{
                  lineHeight: '1.2rem',
                }}
              >
                {`아래 설문을 통해 ${byeonjeungData?.변증?.[selectedByeonjeungIndex]?.koreanName}${checkIGa(
                  byeonjeungData?.변증?.[selectedByeonjeungIndex]?.koreanName?.replace(
                    /[^\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/gi,
                    '',
                  ) ?? '',
                )} ${byeonjeungData?.변증?.[selectedByeonjeungIndex]?.weight}% 도출되었습니다.`}
              </TypographyText>
            </Row>
            <Col style={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
              {byeonjeungDesc}
            </Col>
          </>
        )}
      </Col>
    </Row>
  );
};
export default observer(TreatmentAnalysis);
