import moment from 'moment';

import { TypographyText } from 'components/UI/atoms';

const SurveyCard = ({
  surveyData: s,
  selectedFlag,
  setSelectedSurveyData,
}: {
  surveyData: any;
  selectedFlag: boolean;
  setSelectedSurveyData: (t: any) => void;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.6rem',
        padding: '1.9rem 1.6rem',
        boxShadow: '0px 4px 4px 0px #E5E5E580',
        border: `1px solid ${selectedFlag ? '#1c252c' : '#e5e5e5'}`,
        borderRadius: '3px',
        cursor: 'pointer',
        background: '#ffffff',
      }}
      onClick={() => setSelectedSurveyData(s)}
    >
      <TypographyText fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>{`${moment(
        s.surveyedAt,
      ).format('YYYY/MM/DD')} 설문내역`}</TypographyText>
    </div>
  );
};

export default SurveyCard;
