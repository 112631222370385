import { ClientStore } from './ClientStore';
import { ClinicStore } from './ClinicStore';
import { FeedbackStore } from './FeedbackStore';
import { HistoryStore } from './HistoryStore';
import { PatientPageStore } from './PatientPageStore';
import { PatientStore } from './PatientStore';
import { PrescriptionStore } from './PrescriptionStore';
import { RoleStore } from './RoleStore';
import { SignStore } from './SignStore';
import { SurveyStore } from './SurveyStore';
import { SymptomStore } from './SymptomStore';
import { TreatmentPageStore } from './TreatmentPageStore';
import TreatmentStore from './TreatmentStore';
import UserStore from './UserStore';

export class RootStore {
  patientStore;
  patientPageStore;
  treatmentPageStore;
  userStore;
  clinicStore;
  roleStore;
  historyStore;
  symptomStore;
  prescriptionStore;
  signStore;
  clientStore;
  treatmentStore;
  surveyStore;
  feedbackStore;
  constructor() {
    this.clientStore = new ClientStore(this);
    this.signStore = new SignStore(this);
    this.patientStore = new PatientStore(this);
    this.patientPageStore = new PatientPageStore(this);
    this.treatmentPageStore = new TreatmentPageStore(this);
    this.userStore = new UserStore(this);
    this.clinicStore = new ClinicStore(this);
    this.roleStore = new RoleStore(this);
    this.historyStore = new HistoryStore(this);
    this.symptomStore = new SymptomStore(this);
    this.prescriptionStore = new PrescriptionStore(this);
    this.treatmentStore = new TreatmentStore(this);
    this.surveyStore = new SurveyStore(this);
    this.feedbackStore = new FeedbackStore(this);
  }
}
