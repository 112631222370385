/* eslint-disable eqeqeq */
import React, { ReactElement, useState, useCallback } from 'react';
import { Form } from 'antd';
import _ from 'lodash';

import { Row, Radio as ARadio, RadioGroup } from 'components/UI/atoms';
import { codeMap } from 'utils/code';

import Onset from './Onset';
import Scale from './Scale';

const { Item: FormItem } = Form;

function Radio({
  form,
  k,
  v,
  recentChangedField,
  scaleOnsetKeys,
}: {
  form: any;
  k: string;
  v: any;
  recentChangedField: React.MutableRefObject<any>;
  scaleOnsetKeys: string[];
}): ReactElement {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const fv = form.getFieldValue(k);
  let additionalSurveyFlag = false;
  let additionalSurvey;
  if (_.includes(scaleOnsetKeys, fv)) {
    additionalSurveyFlag = true;
    additionalSurvey = codeMap.get(fv);
  }
  return (
    <Row yjTypeRow="survey-answer">
      <RadioGroup buttonStyle="solid">
        {v.answers?.map(({ value, label }: { value: string | number; label: string }) => {
          return (
            <ARadio
              className={form.getFieldValue(k) == value ? 'ant-radio-wrapper-checked' : ''}
              yjTypeRadio="survey"
              key={`${k}_${value}`}
              value={value}
              onChange={() => {
                recentChangedField.current = k;
                forceUpdate();
              }}
            >
              {label}
            </ARadio>
          );
        })}
      </RadioGroup>
      <Row style={{ marginLeft: '-3.4rem' }}>
        {additionalSurveyFlag ? (
          <>
            {_.includes(additionalSurvey?.selectType, 'Scale') && (
              <FormItem name={fv} noStyle>
                <Scale form={form} k={fv} v={v} recentChangedField={recentChangedField} />
              </FormItem>
            )}
            {_.includes(additionalSurvey?.selectType, 'Onset') && (
              <FormItem name={fv} noStyle>
                <Onset form={form} k={fv} v={v} recentChangedField={recentChangedField} />
              </FormItem>
            )}
          </>
        ) : (
          <></>
        )}
      </Row>
    </Row>
  );
}

export default Radio;
