/* eslint-disable react-hooks/exhaustive-deps */
import { Auth } from 'aws-amplify';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from '../stores/Context';
import { signOutHandler } from 'utils/util';

const RequireAuth = (props: any) => {
  const location = useLocation();
  const { roleStore, clientStore } = useStores();

  useEffect(() => {
    if (typeof clientStore.metaData?.userRole !== 'undefined') {
      roleStore.getUserRole(clientStore.metaData?.userRole.id!);
    }
  }, []);

  useEffect(() => {
    const userUuid = sessionStorage.getItem('sub');
    if (userUuid) {
      Auth.currentSession().catch((e) => {
        if (e === 'No current user') {
          signOutHandler(); // 메인 화면으로 이동 & 강제 새로고침
        }
      });
    } else {
      // 유저 uuid가 세션스토리지에 세팅되어 있지 않은 경우
      signOutHandler(); // 메인 화면으로 이동 & 강제 새로고침
    }
  }, [location?.pathname]);

  return <>{props.children}</>;
};

export default RequireAuth;
