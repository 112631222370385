import { makeAutoObservable, runInAction } from 'mobx';
import { SelectSymptom } from '../API';
import SymptomRepository from '../repository/SymptomRepository';
import { Herb } from '../utils/HerbCode';
import { RootStore } from './RootStore';

export class SymptomStore {
  rootStore: RootStore;
  mainSymptom: string = '';
  symptoms: string[] = [];
  herbs: Herb[] = [];
  recommenededFormula: { 고방: any[]; 후세방: any[] } = { 고방: [], 후세방: [] };
  analysisResult = {};
  isloadingForRecommendingHerb: boolean = false;
  isloadingForRecommendingFormula: boolean = false;
  isloadingForPatientAnalysis: boolean = true;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setMainSymptom = (mainSymptom: string) => {
    this.mainSymptom = mainSymptom;
  };

  addSymptoms = (symptom: string) => {
    this.symptoms?.push(symptom);
  };
  setSymptoms = (symptoms: string[]) => {
    this.symptoms = [];
    this.symptoms = symptoms;
  };
  deleteSymptom = (symptom: string) => {
    this.symptoms = this.symptoms.filter((data) => {
      return symptom !== data;
    });
  };

  resetSymptom = () => {
    this.mainSymptom = '';
    this.symptoms = [];
    this.herbs = [];
    this.recommenededFormula = { 고방: [], 후세방: [] };
    this.analysisResult = {};
  };

  resetHerb = () => {
    this.herbs = [];
  };

  resetAnalysisResult = () => {
    this.analysisResult = {};
  };

  getRecommendation = async ({
    symptoms,
    mode,
    subMode,
    insuranceMode,
    insuranceDiseaseCode,
    herbCodeArrayforSearch = [],
    processingCodeArrayforSearch = [],
    queryParams,
  }: {
    symptoms: (SelectSymptom | null)[];
    mode: 'herb' | 'byeonjeung';
    subMode?: string;
    insuranceMode?: string;
    insuranceDiseaseCode?: string;
    herbCodeArrayforSearch?: string[];
    processingCodeArrayforSearch?: string[];
    queryParams;
  }) => {
    try {
      const response = await SymptomRepository.getSuggestHerb({
        symptoms,
        mode,
        queryParams,
        insuranceMode,
        insuranceDiseaseCode,
        subMode,
        herbCodeArrayforSearch,
        processingCodeArrayforSearch,
      });
      // console.log('약재 추천 input 증상코드', JSON.stringify(symptoms));

      let res = {} as any;
      if (mode === 'herb') {
        if (insuranceMode === '급여') {
          res = {
            ...res,
            recommenededPrescriptions: {
              고유: response.data?.prescription?.고유 ?? [],
              사상: response.data?.prescription?.사상 ?? [],
              변증: response.data?.prescription?.변증 ?? [],
            },
          };
        } else if (insuranceMode === '비급여') {
          res = {
            ...res,
            recommenededPrescriptions: {
              고방: response.data?.prescription?.go ?? [],
              후세방: response.data?.prescription?.hu ?? [],
            },
          };
        }
      }

      if (mode === 'byeonjeung' || (mode === 'herb' && subMode === 'all')) {
        const herbs = response.data?.herbs?.map((herb: any) => ({
          code: herb.code,
          score: +herb.weight,
          relativeSymptomCode: herb.relativeSymptomCode,
        }));
        res = {
          ...res,
          recommenededHerbs: herbs,
        };
      }
      return res;
    } catch (e) {
      console.log(e);
    }
  };

  setIsloadingForPatientAnalysis = (isloading: boolean) => {
    this.isloadingForPatientAnalysis = isloading;
  };

  getPatientAnalysis = async (herbCodes: string[]) => {
    try {
      this.isloadingForPatientAnalysis = true;
      const response = await SymptomRepository.getPatientAnalysis(herbCodes);
      // console.log(`변증 로직에 쓰이는 허브코드 목록: ${herbCodes}`);

      runInAction(() => {
        this.analysisResult = response?.data ?? {};
        // console.log(`변증 도출 결과: ${JSON.stringify(this.analysisResult)}`);
        this.isloadingForPatientAnalysis = false;
      });
    } catch (e) {
      this.isloadingForPatientAnalysis = false;
      console.log(e);
    }
  };
}
