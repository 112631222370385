import { useState, ReactNode } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Heading } from '@aws-amplify/ui-react';

import { Row, ModalWithJustX } from 'components/UI/atoms';
import SideBar from 'components/UI/organisms/sidebar/SideBar';

interface AdminLayoutProps {
  title: String;
  titleStyle?: React.CSSProperties;
  content: ReactNode;
  footerButtons?: ReactNode;
  sideType: number;
  serviceGuideLink?: string;
  youtubeLinkParam?: {
    title: string;
    src: string;
  };
}
const AdminLayout = ({
  title,
  titleStyle,
  content,
  sideType,
  serviceGuideLink,
  youtubeLinkParam,
  footerButtons,
}: AdminLayoutProps) => {
  const [isOpenMediaModal, setIsOpenMediaModal] = useState(false);
  return (
    <Row style={{ height: '100%', width: '100%' }}>
      <SideBar type={sideType} />
      <Row
        style={{
          marginLeft: '14rem', //sidebar width 280ox
          width: `calc(100% - 14rem)`,
          height: '100%',
          minHeight: '100vh',
          padding: '0 2.4rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {title.length ? (
          <Row
            style={{
              paddingTop: '2rem',
              paddingBottom: '1.4rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ...titleStyle,
            }}
          >
            <Heading fontWeight={700} fontSize={'1.8rem'}>
              {title}
            </Heading>
            {!_.isEmpty(youtubeLinkParam) && (
              <ModalWithJustX
                isOpen={isOpenMediaModal}
                width={`48rem`}
                height={`27rem`}
                content={
                  <Row
                    style={{
                      position: 'relative',
                      width: '100%',
                      paddingBottom: '56.25%',
                    }}
                  >
                    <iframe
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 0,
                      }}
                      src={
                        youtubeLinkParam.title === 'Ye-jin Clinic Management'
                          ? youtubeLinkParam.src +
                            (sessionStorage.getItem('settingTabYoutubeStartTime') ?? '0')
                          : youtubeLinkParam.src
                      }
                      title={youtubeLinkParam.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen={true}
                    />
                  </Row>
                }
                cancelFunction={() => {
                  setIsOpenMediaModal(false);
                }}
              />
            )}
            {/* {serviceGuideLink || !_.isEmpty(youtubeLinkParam) ? (
              <GuideButton
                buttonText="동영상 가이드"
                onClickFunc={() => {
                  if (!_.isEmpty(youtubeLinkParam)) {
                    setIsOpenMediaModal(true);
                  } else if (serviceGuideLink) {
                    window.open(serviceGuideLink, '_blank');
                  }
                }}
              />
            ) : (
              <></>
            )} */}
          </Row>
        ) : null}
        {content}
        {footerButtons && (
          <Row className={'no-print'}>
            <Row
              style={{
                position: 'fixed',
                bottom: 0,
                left: '14rem', // 사이드바 너비 14rem
                background: '#ffffff',
                width: 'calc(100% - 14rem)', // 사이드바 너비 14rem
                height: '5rem',
                borderTop: '1px solid #e5e5e5',
              }}
            >
              <Row
                style={{
                  display: 'flex',
                  height: '100%',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: '2.4rem',
                  columnGap: '0.6rem',
                }}
              >
                {footerButtons}
              </Row>
            </Row>
          </Row>
        )}
      </Row>
    </Row>
  );
};

export default observer(AdminLayout);
