import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Table, TableBody, TableHead, TableRow } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import ReactStars from 'react-rating-stars-component';

import { TypographyText, Row, Col } from 'components/UI/atoms';
import { TooltipCard } from 'components/UI/molecules';
import { codeMap } from 'utils/code';
import { TableCell } from 'components/UI/atoms/table/TableCell';
import { useStores } from 'stores/Context';
import { getSummarySurveyData } from 'utils/util';
import { treatmentModeMap } from 'constant';

import FeedbackResultTd from './FeedbackResultTd';
import ScaleTd from './ScaleTd';
import CategoryTd from './CategoryTd';
import SymptomDescTd from './SymptomDescTd';
import SymptomHighlightTd from './SymptomHighlightTd';
import FeedbackCheckTd from './FeedbackCheckTd';

const CommonSymptomTable = ({
  survey,
  symptoms = [],
  mode,
  treatment,
  feedbackScheduleFlag,
}: {
  survey: any;
  symptoms: any;
  mode:
    | 'patientSymptom' // 환자 관리 - 설문 내역 & 진료실 - 진료 목록 - 증상 보기
    | 'feedbackHistory' // 환자 관리 - 진료 내역 및 피드백 탭
    | 'treatmentSymptom' // 진료실 - 증상 탭
    | 'treatmentSymptomReadOnlyForPrecriptionPage'; // 진료실 - 처방 탭 & 진료 내역
  treatment?: any;
  feedbackScheduleFlag?: boolean;
}) => {
  const prevLabel = useRef('');
  const { feedbackStore } = useStores();
  const [highlight, setHighlight] = useState({});
  const [checklist, setChecklist] = useState({});
  const [isOpenTooltip1, setIsOpenTooltip1] = useState(false);
  const [isOpenTooltip2, setIsOpenTooltip2] = useState(false);
  // feedbackResult가 none으로 들어간 것 방어코드
  let feedbackResult = [];
  if (!_.isEmpty(survey?.feedbackResult) && survey?.feedbackResult !== 'none') {
    feedbackResult = JSON.parse(survey?.feedbackResult);
  }
  let surveyCodeArrForfeedback = [];
  if (!_.isEmpty(survey?.feedbacks) && survey?.feedbacks !== 'none') {
    surveyCodeArrForfeedback = JSON.parse(survey?.feedbacks)?.map((e) => e.code);
  }
  const feedbackResultObject = feedbackResult.reduce((a, c) => ({ ...a, [c.code]: c.value }), {}) ?? [];
  let noDataMessage = '증상이 없습니다.';

  const insuranceSurveyTypeFlag = useMemo(
    () => treatmentModeMap.has(survey?.insuranceType),
    [survey?.insuranceType],
  );

  const sortedSymptoms = useMemo(() => {
    if (survey.mainSymptomCategory && _.isArray(symptoms)) {
      const classifySymptoms = symptoms.reduce(
        (a, c) => {
          if (c.code.startsWith(survey.mainSymptomCategory)) {
            return {
              ...a,
              mainCategory: [...a.mainCategory, c],
            };
          } else {
            return {
              ...a,
              nonMainCategory: [...a.nonMainCategory, c],
            };
          }
        },
        {
          mainCategory: [],
          nonMainCategory: [],
        },
      );
      return [...classifySymptoms.mainCategory, ...classifySymptoms.nonMainCategory];
    } else {
      return symptoms;
    }
  }, [survey.mainSymptomCategory, symptoms]);

  const categoryTh = useMemo(() => {
    return (
      <TableCell
        as="th"
        style={{
          borderLeft: 'none',
          width: '8.5rem',
          padding: '0',
        }}
      >
        <Row style={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0' }}>
          <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
            카테고리
          </TypographyText>
        </Row>
      </TableCell>
    );
  }, []);

  const symptomDescTh = useMemo(() => {
    return (
      <TableCell
        as="th"
        style={{
          padding: '0',
          textIndent: '0.1rem',
        }}
      >
        <Row style={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0 0.5rem 0.5rem' }}>
          <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
            증상
          </TypographyText>
        </Row>
      </TableCell>
    );
  }, []);

  const scaleTh = useMemo(() => {
    return (
      <TableCell
        as="th"
        style={{
          padding: '0',
        }}
      >
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '0.5rem 0 0.5rem 0.3rem',
          }}
        >
          <TypographyText
            fontSize="0.8rem"
            lineHeight="1em"
            fontWeight={700}
            whiteSpace="pre-wrap"
            wordBreak="keep-all"
          >
            {`불편한 정도(1~5)`}
          </TypographyText>
        </Row>
      </TableCell>
    );
  }, []);

  const symptomHighlightTh = useMemo(() => {
    return (
      <TableCell
        as="th"
        style={{
          padding: '0',
        }}
      >
        <Row
          style={{
            padding: '0.5rem 0',
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.05rem',
            justifyContent: 'center',
            textIndent: '-0.15rem',
          }}
        >
          <TooltipCard
            isOpen={isOpenTooltip1}
            targetComponentId={`common-symptom-table-${mode}`}
            topRem={-3.75}
            leftRem={0}
            description={
              mode === 'treatmentSymptom'
                ? `환자의 주된 호소 증상 선택시, 해당 증상에 가중치가 
부여되어 변증 및 약재 추천에 반영됩니다.`
                : `환자 증상 중 중점적으로 볼 항목입니다. 
선택된 증상에 가중치가 부여되어 약재가 추천됩니다.`
            }
          />
          <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
            중요
          </TypographyText>
          <Col
            onMouseOver={() => setIsOpenTooltip1(true)}
            onMouseLeave={() => setIsOpenTooltip1(false)}
            style={{ display: 'flex', cursor: 'pointer', flexShrink: 0 }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/question-icon-white.webp`}
              style={{ width: '0.7rem', cursor: 'pointer' }}
              alt="question-icon"
            />
          </Col>
        </Row>
      </TableCell>
    );
  }, [mode, isOpenTooltip1]);

  const feedbackTh = useMemo(() => {
    return (
      <TableCell
        as="th"
        style={{
          borderRight: 'none',
          padding: '0.5rem 0rem 0.5rem 0.3rem',
        }}
      >
        <Row
          style={{ display: 'flex', alignItems: 'center', columnGap: '0.05rem', justifyContent: 'center' }}
        >
          <TooltipCard
            isOpen={isOpenTooltip2}
            targetComponentId={`common-symptom-table-${mode}`}
            topRem={mode === 'treatmentSymptom' ? -4.71 : -3.75}
            rightRem={0}
            description={
              mode === 'treatmentSymptom'
                ? `진료 및 복약 후 선택한 증상의 개선 여부를 환자에게 묻게 됩니다.
피드백을 원하시는 증상을 선택해주세요. (설정하신 기간이 되면
환자에게 카카오톡을 통해 피드백 설문 링크를 발송합니다.)`
                : `선택한 증상의 피드백을 확인하는 카카오톡 메시지를 환자에게 발송합니다.
환자가 메시지에 응답하면 피드백을 확인할 수 있습니다.`
            }
          />
          <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
            피드백
          </TypographyText>
          <Col
            onMouseOver={() => setIsOpenTooltip2(true)}
            onMouseLeave={() => setIsOpenTooltip2(false)}
            style={{ display: 'flex', cursor: 'pointer', flexShrink: 0 }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/question-icon-white.webp`}
              style={{ width: '0.7rem', cursor: 'pointer' }}
              alt="question-icon"
            />
          </Col>
        </Row>
      </TableCell>
    );
  }, [mode, isOpenTooltip2]);

  let thList = [categoryTh, symptomDescTh, scaleTh];

  // console.log(JSON.stringify(survey));
  // console.log(feedbackResult);
  switch (mode) {
    case 'patientSymptom':
      break;
    case 'feedbackHistory':
      thList = [categoryTh, symptomHighlightTh, symptomDescTh, scaleTh, feedbackTh];
      break;
    case 'treatmentSymptom':
      thList = [categoryTh, symptomHighlightTh, symptomDescTh, scaleTh, feedbackTh];
      break;
    case 'treatmentSymptomReadOnlyForPrecriptionPage':
      thList = [categoryTh, symptomHighlightTh, symptomDescTh, scaleTh];
      break;
  }

  useEffect(() => {
    if (
      ['treatmentSymptom', 'feedbackHistory', 'treatmentSymptomReadOnlyForPrecriptionPage'].includes(mode) &&
      !_.isEmpty(treatment)
    ) {
      const symptomCodes = symptoms?.reduce(
        (a, c) => ({
          ...a,
          [c.code]: treatment.surveyCheck?.includes(c.code),
        }),
        {},
      );
      setHighlight(symptomCodes);
    }
  }, [mode, symptoms, treatment]);

  useEffect(() => {
    if (mode === 'treatmentSymptom') {
      const SurveyCodeArr = feedbackStore?.symptoms?.map((e) => e.code);
      const symptomCodes = symptoms?.reduce(
        (a, c) => ({
          ...a,
          [c.code]: _.includes(SurveyCodeArr, c.code),
        }),
        {},
      );
      setChecklist(symptomCodes ?? {});
    }
  }, [mode, feedbackStore, symptoms]);

  return (
    <Row>
      <Row style={{ marginBottom: '0.5rem' }}>
        {mode !== 'patientSymptom' && survey?.feedbackGrade > 0 && (
          <>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '2rem',
                borderTop: '2px solid #e5e5e5',
                borderBottom: '1px solid #e5e5e5',
              }}
            >
              <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700}>
                진료만족도
              </TypographyText>
            </Row>
            <Row
              style={{
                display: 'flex',
                columnGap: '1rem',
                alignItems: 'center',
                paddingBlock: '0.7rem',
              }}
            >
              <ReactStars
                activeColor="#FFCE21"
                color="#D9D9D9"
                size={21}
                edit={false}
                value={survey.feedbackGrade}
                classNames="feedback-grade"
              />
              <TypographyText yjTypeTypographyText="sub-title">{survey.feedbackSatisfaction}</TypographyText>
            </Row>
          </>
        )}
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '2rem',
            borderTop: '2px solid #e5e5e5',
            borderBottom: '1px solid #e5e5e5',
          }}
        >
          <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700}>
            주소증
          </TypographyText>
        </Row>
        <Row
          style={{
            display: 'flex',
            columnGap: '0.4rem',
            alignItems: 'center',
            paddingBlock: '0.75rem',
          }}
        >
          {survey.mainSymptomCategory && codeMap.has(survey.mainSymptomCategory) && (
            <TypographyText yjTypeTypographyText="sub-title" fontWeight={700}>
              {`[${codeMap.get(survey.mainSymptomCategory)?.label}]`}
            </TypographyText>
          )}
          <TypographyText yjTypeTypographyText="sub-title">
            {survey.mainSymptom || (insuranceSurveyTypeFlag ? '' : '주소증 미입력')}
          </TypographyText>
        </Row>
        {survey?.additionalSymptoms && (
          <>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '2rem',
                borderTop: '2px solid #e5e5e5',
                borderBottom: '1px solid #e5e5e5',
              }}
            >
              <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700}>
                추가증상
              </TypographyText>
            </Row>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingBlock: '0.75rem',
              }}
            >
              <TypographyText fontSize="1rem" lineHeight="1.2em">
                {survey.additionalSymptoms}
              </TypographyText>
            </Row>
          </>
        )}
      </Row>
      <Row>
        <Col>
          <Table
            id={`common-symptom-table-${mode}`}
            highlightOnHover={false}
            size={undefined}
            variation={undefined}
            style={{
              position: 'relative',
              border: '1px solid #f2f2f2',
              borderInline: 'none',
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  borderTop: '2px solid #e5e5e5',
                  borderBottom: '1px solid #f2f2f2',
                  backgroundColor: '#ffffff',
                }}
              >
                {thList}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                // 복수 응답이 존재하는 것을 제외한 최상위 설문은 보여주지 않는다.
                _.filter(
                  sortedSymptoms,
                  (symptom) => !(symptom?.code?.length === 1 && !_.isArray(symptom?.value)),
                  // eslint-disable-next-line array-callback-return
                )?.map((symptomData: any, index) => {
                  if (index === 0) {
                    prevLabel.current = '';
                  }
                  const {
                    symptom,
                    plainStringValue,
                    codeData: data,
                    desc,
                  } = getSummarySurveyData(symptomData);
                  const categoryOmitFlag = prevLabel.current === data?.label;
                  prevLabel.current = data?.label;

                  switch (mode) {
                    case 'patientSymptom':
                      return (
                        <TableRow key={symptom.code}>
                          <CategoryTd data={data} categoryOmitFlag={categoryOmitFlag} />
                          <SymptomDescTd data={data} plainStringValue={plainStringValue} desc={desc} />
                          <ScaleTd symptom={symptom} />
                        </TableRow>
                      );
                    case 'feedbackHistory':
                      return (
                        <TableRow key={symptom.code}>
                          <CategoryTd data={data} categoryOmitFlag={categoryOmitFlag} />
                          <SymptomHighlightTd
                            symptom={symptom}
                            highlight={highlight}
                            setHighlight={setHighlight}
                            readonlyFlag
                          />
                          <SymptomDescTd data={data} plainStringValue={plainStringValue} desc={desc} />
                          <ScaleTd symptom={symptom} />
                          <FeedbackResultTd
                            symptom={symptom}
                            feedbackResult={feedbackResult}
                            feedbackResultObject={feedbackResultObject}
                            surveyCodeArrForfeedback={surveyCodeArrForfeedback}
                            feedbackScheduleFlag={feedbackScheduleFlag}
                          />
                        </TableRow>
                      );
                    case 'treatmentSymptom':
                      return (
                        <TableRow key={symptom.code}>
                          <CategoryTd data={data} categoryOmitFlag={categoryOmitFlag} />
                          <SymptomHighlightTd
                            symptom={symptom}
                            highlight={highlight}
                            setHighlight={setHighlight}
                            readonlyFlag={false}
                          />
                          <SymptomDescTd data={data} plainStringValue={plainStringValue} desc={desc} />
                          <ScaleTd symptom={symptom} />
                          <FeedbackCheckTd
                            symptom={symptom}
                            checklist={checklist}
                            setChecklist={setChecklist}
                          />
                        </TableRow>
                      );
                    case 'treatmentSymptomReadOnlyForPrecriptionPage':
                      return (
                        <TableRow key={symptom.code}>
                          <CategoryTd data={data} categoryOmitFlag={categoryOmitFlag} />
                          <SymptomHighlightTd
                            symptom={symptom}
                            highlight={highlight}
                            setHighlight={setHighlight}
                            readonlyFlag
                          />
                          <SymptomDescTd data={data} plainStringValue={plainStringValue} desc={desc} />
                          <ScaleTd symptom={symptom} />
                        </TableRow>
                      );
                  }
                })
              }
              {symptoms?.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    style={{
                      borderLeft: 'none',
                      borderRight: 'none',
                      textAlign: 'center',
                    }}
                  >
                    <TypographyText yjTypeTypographyText="symptom-table-body" color="#afafaf">
                      {`${noDataMessage}`}
                    </TypographyText>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Col>
      </Row>
    </Row>
  );
};

export default observer(CommonSymptomTable);
