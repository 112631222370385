import { Divider, Flex, Heading, Table, Text } from '@aws-amplify/ui-react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

const MarketingPolicy = () => {
  const StyledTD = styled.td`
    padding: 1rem;
  `;

  const StyledTHeader = styled.tr`
    border-top: 1px solid #afafaf;
    background-color: #f5f5f5;
    font-size: 1.2rem;
    font-weight: 700;
  `;
  const StyledTR = styled.tr`
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    font-size: 1rem;
    font-weight: 400;
    vertical-align: top;
  `;

  return (
    <Flex
      direction={'column'}
      gap={0}
      marginBottom={'5rem'}
      paddingBottom={'5rem'}
      id="marketing-policy-block"
    >
      <Heading level={1} fontWeight={700} fontSize={'1.6rem'}>
        마케팅 활용 동의
      </Heading>
      <Divider marginTop={'1rem'} border={'1px solid #AFAFAF'} />
      <Text fontSize={'1rem'} fontWeight={400} marginTop={'2rem'}>
        마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집이용을 거부하실 수 있으며, 거부 시에도 예진
        서비스를 이용하실 수 있으나, 동의를 거부한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다.
        <br />
        <Table
          margin={'1rem 0'}
          highlightOnHover={false}
          size={undefined}
          variation={undefined}
          className={css`
            padding: 0.5rem !important;
          `}
        >
          <StyledTHeader>
            <StyledTD width={'20%'}>개인정보 수집 항목</StyledTD>
            <StyledTD width={'33%'}>개인정보 수집 이용 목적</StyledTD>
            <StyledTD width={'33%'}>보유 및 이용기간</StyledTD>
          </StyledTHeader>
          <StyledTR>
            <StyledTD>이름, 휴대폰번호, 이메일, 주소</StyledTD>
            <StyledTD>
              - 이벤트 운영 및 광고성 정보 전송
              <br />- 서비스 관련 정보 전송
            </StyledTD>
            <StyledTD>이용자가 동의를 철회하거나, 탈퇴시까지 보유•이용</StyledTD>
          </StyledTR>
        </Table>
      </Text>
    </Flex>
  );
};
export default MarketingPolicy;
