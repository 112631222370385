import message from 'antd-message';
import { useState } from 'react';
import { API } from 'aws-amplify';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import { useStores } from 'stores/Context';
import { Row, Col, TypographyText, Input, ModalType2, Modal } from 'components/UI/atoms';
import { licenseValidationCheck } from 'utils/util';
import us from 'repository/UserRepository';

export const LicenseRegistrationModal = ({
  setIsOpenLicenseRegistrationModal,
  showConfirmedPageFlag = false,
}) => {
  const { clinicStore, clientStore } = useStores();

  const [licenseNumber, setLicenseNumber] = useState<string>();
  const [image, setImage] = useState([]);
  const [isOpenRemoveImageModal, setIsOpenRemoveImageModal] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  // error
  const [errors, setErrors] = useState<{
    imageMaxFileSize: boolean;
    licenseNumberValidation: boolean;
  }>({
    imageMaxFileSize: false,
    licenseNumberValidation: false,
  });

  const requestLicenseRegstation = async ({
    userId,
    name,
    email,
    phone,
    image,
    licenseNumber,
    clinicName,
    clinicEmail,
    clinicPhone,
    showConfirmedPageFlag,
  }: {
    userId: string;
    name: string;
    email: string;
    phone: string;
    image: any[];
    licenseNumber: string;
    clinicName: string;
    clinicEmail: string;
    clinicPhone: string;
    showConfirmedPageFlag?: boolean;
  }) => {
    try {
      await setIsLoading(true);
      // 모든 유효성 검사를 통과 했음을 전제로 body 구성
      const body: any = {
        userId,
        mode: 'LICENSE_REGISTRATION',
        name,
        email,
        phone,
        clinicName,
        clinicEmail,
        clinicPhone,
        licenseImage: image[0].dataURL.split(';base64,')[1],
        licenseNumber,
      };

      try {
        await Promise.all([
          API.post('yejinadminrestapi', '/signup', {
            headers: {
              'Content-Type': 'application/json',
            },
            response: true,
            body,
          }),
          us.updateUser({
            id: userId,
            licensePendingReviewFlag: true,
          }),
        ]);
        await clientStore.getUser(userId);
        if (!showConfirmedPageFlag) {
          message.success('면허번호 등록 승인 요청이 완료되었습니다.', 3000);
          setIsOpenLicenseRegistrationModal(false);
        }
      } catch (error) {
        console.log(error.response);
        console.log(error);
        message.error(`면허번호 등록 승인 요청에 문제가 발생했습니다. E:${error.response.status}`, 10000);
        setIsLoading(false);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      message.error(`면허번호 등록 승인 요청에 문제가 발생했습니다.`, 8000);
      setIsLoading(false);
    }
  };

  const confirmFlag =
    licenseNumber && !errors.licenseNumberValidation && image.length > 0 && !errors.imageMaxFileSize;

  return (
    <>
      {page === 1 && (
        <ModalType2
          isOpen={true}
          title="면허번호 등록하기"
          width={40}
          content={
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1.25rem',
              }}
            >
              <Modal
                isOpen={isOpenRemoveImageModal !== undefined}
                okMsg="네, 삭제하겠습니다."
                cancelMsg="취소"
                okFunction={async () => {
                  await isOpenRemoveImageModal(0);
                  await setIsOpenRemoveImageModal(undefined);
                }}
                cancelFunction={() => setIsOpenRemoveImageModal(undefined)}
                contentMsg={`첨부한 이미지를 삭제하시겠습니까?`}
              />
              <table>
                <colgroup>
                  <col style={{ width: '9.6rem' }} />
                  <col style={{ width: 'calc(100% - 9.6rem)' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <TypographyText style={{ fontSize: '1rem', lineHeight: '1.2em', fontWeight: 700 }}>
                        면허번호
                      </TypographyText>
                    </td>
                    <td>
                      <div style={{ width: '100%' }} className="relative">
                        <Input
                          style={{
                            height: '3.2rem',
                            width: '100%',
                            border: '1px solid #afafaf',
                            borderRadius: '3px',
                            textIndent: '0.4rem',
                            fontSize: '1rem',
                            outline: 'none',
                          }}
                          value={licenseNumber}
                          placeholder="면허번호"
                          onChange={(e) => {
                            if (licenseValidationCheck(e.target.value)) {
                              setErrors({
                                ...errors,
                                licenseNumberValidation: false,
                              });
                            } else {
                              setErrors({
                                ...errors,
                                licenseNumberValidation: true,
                              });
                            }
                            setLicenseNumber(e.target.value);
                          }}
                          autoComplete="off"
                        />
                        {!!licenseNumber?.length && errors.licenseNumberValidation && (
                          <Row flex style={{ marginTop: '0.5rem' }}>
                            <TypographyText yjTypeTypographyText="survey-validation">
                              {'면허번호를 확인해주세요.'}
                            </TypographyText>
                          </Row>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr style={{ height: '0.75rem', width: '100%' }}></tr>
                  <tr>
                    <td>
                      <TypographyText style={{ fontSize: '1rem', lineHeight: '1.2em', fontWeight: 700 }}>
                        면허 이미지
                      </TypographyText>
                    </td>
                    <td>
                      <Col style={{ position: 'relative', width: '100%' }}>
                        <ImageUploading
                          multiple
                          value={image}
                          onChange={(imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
                            // console.log(imageList, addUpdateIndex);
                            if (imageList?.length > 0) {
                              setErrors({
                                ...errors,
                                imageMaxFileSize: false,
                              });
                              // setImage(imageList[0].dataURL.split(';base64,')[1]);
                            }
                            setImage(imageList as never[]);
                          }}
                          maxNumber={1}
                          maxFileSize={5242880} // 5MB
                          acceptType={['jpg', 'png']}
                          onError={(e) => {
                            if (e.maxFileSize) {
                              setErrors({
                                ...errors,
                                imageMaxFileSize: true,
                              });
                            }
                          }}
                        >
                          {({ imageList, onImageUpload, onImageRemove }) => (
                            <Row className="upload__image-wrapper">
                              <Row
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  height: '3.2rem',
                                  width: '100%',
                                  border: errors.imageMaxFileSize ? '1px solid #DF635C' : '1px solid #afafaf',
                                  borderRadius: '3px',
                                  fontSize: '1rem',
                                  outline: 'none',
                                  paddingLeft: '0.8rem',
                                  paddingRight: '0.9rem',
                                }}
                              >
                                <Col flexFlag alignItems="center" style={{ columnGap: '0.45rem' }}>
                                  {!imageList?.length && (
                                    <Col
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: imageList?.length ? '1px solid #c4c4c4' : '1px solid #000000',
                                        borderRadius: '3px',
                                        background: imageList?.length ? '#f9f9f9' : '#e5e5e5',
                                        height: '2rem',
                                        paddingLeft: '0.5rem',
                                        paddingRight: '0.5rem',
                                        cursor: 'pointer',
                                        pointerEvents: imageList?.length ? 'none' : 'auto',
                                      }}
                                      onClick={onImageUpload}
                                    >
                                      <TypographyText
                                        fontSize="1rem"
                                        whiteSpace="pre"
                                        color={imageList?.length ? '#C4C4C4' : '#1d1c1d'}
                                      >{`면허 이미지 첨부`}</TypographyText>
                                    </Col>
                                  )}
                                  <Col flexFlag alignItems="center" justifyContent="center">
                                    <TypographyText fontSize="1rem" color="#AFAFAF">
                                      {imageList?.length
                                        ? imageList.map((e, i) => {
                                            return <TypographyText key={i}>{e.file.name}</TypographyText>;
                                          })
                                        : `형식: jpg, png`}
                                    </TypographyText>
                                  </Col>
                                </Col>
                                {imageList?.length > 0 && (
                                  <Col
                                    flexFlag
                                    style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                      await setIsOpenRemoveImageModal(() => {
                                        return onImageRemove;
                                      });
                                    }}
                                  >
                                    <img
                                      src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                                      style={{ width: '1rem' }}
                                      alt="serach_initialization_icon"
                                    />
                                  </Col>
                                )}
                              </Row>
                              {errors.imageMaxFileSize && (
                                <Row flex style={{ marginTop: '0.5rem' }}>
                                  <TypographyText yjTypeTypographyText="survey-validation">
                                    {'이미지 크기는 5MB 이하로 설정해주세요.'}
                                  </TypographyText>
                                </Row>
                              )}
                            </Row>
                          )}
                        </ImageUploading>
                      </Col>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Row
                style={{
                  display: 'flex',
                  padding: '1.25rem',
                  background: '#f2f2f2',
                  width: '100%',
                }}
              >
                <TypographyText
                  style={{
                    whiteSpace: 'pre-wrap',
                    fontSize: '0.8rem',
                    lineHeight: '1.375em',
                    letterSpacing: '0.03em',
                  }}
                >
                  {`- 면허 이미지와 면허번호가 불일치할 경우, 등록 신청이 거절될 수 있습니다.
- 개인 전화번호의 카카오톡으로 등록 신청 승인 여부를 알려드립니다.`}
                </TypographyText>
              </Row>
            </Row>
          }
          okMsg="등록 신청하기"
          okBtnDisabled={!confirmFlag}
          okFunction={async () => {
            if (confirmFlag) {
              await requestLicenseRegstation({
                userId: clientStore.metaData?.user.id,
                name: clientStore.user?.username,
                email: clientStore.user?.email,
                phone: clientStore.user?.phone,
                image,
                licenseNumber,
                clinicName: clinicStore.clinic?.clinicName,
                clinicEmail: clinicStore.clinic?.clinicEmail,
                clinicPhone: clinicStore.clinic?.phone,
                showConfirmedPageFlag,
              });
              if (showConfirmedPageFlag) {
                setPage(2);
              }
            }
          }}
          okBtnLoading={isLoading}
          cancelFunction={() => setIsOpenLicenseRegistrationModal(false)}
        />
      )}
      {page === 2 && (
        <ModalType2
          isOpen={true}
          width={40}
          content={
            <Row
              style={{
                paddingTop: '1rem',
                paddingBottom: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                rowGap: '0.75rem',
                textAlign: 'center',
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/signup-complete-icon-blue.webp`}
                style={{ width: '2.5rem' }}
                alt="면허 인증 요청 완료"
              />
              <TypographyText
                style={{
                  color: '#000000',
                  whiteSpace: 'pre',
                  fontSize: '1.6rem',
                  lineHeight: '1.3125em',
                  fontWeight: 700,
                }}
              >
                {`면허 인증 요청 완료`}
              </TypographyText>
              <TypographyText
                style={{
                  color: '#1d1c1d',
                  whiteSpace: 'pre-wrap',
                  fontSize: '1rem',
                  lineHeight: '1.4em',
                }}
              >
                {`면허 인증 요청이 승인되면 승인 여부를 카카오톡으로 안내해 드립니다.
*최소 30분 최대 24시간 소요`}
              </TypographyText>
            </Row>
          }
          cancelFunction={() => setIsOpenLicenseRegistrationModal(false)}
        />
      )}
    </>
  );
};
