import { makeAutoObservable, runInAction } from 'mobx';
import { UserRole } from '../API';
import RoleRepository from '../repository/RoleRepository';
import { RootStore } from './RootStore';

export class RoleStore {
  rootStore: RootStore;
  userRole?: UserRole;
  doctors?: UserRole[];
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getUserRole = async (roleID: string) => {
    const userRole = (await RoleRepository.getRole(roleID)).getUserRole;
    runInAction(() => {
      this.userRole = userRole as UserRole;
    });
    return userRole;
  };

  updateUserName = async (roleID: string, name: string) => {
    await RoleRepository.upateUserRoleName(roleID, name);
  };
}
