import React, { ReactNode } from 'react';

import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { color } from '../../../../styles/themes';
import { Typography as ATypography, TypographyProps } from 'antd';
import { tuple, tupleNum } from 'antd/lib/_util/type';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { TextProps } from 'antd/lib/typography/Text';
import { TitleProps } from 'antd/lib/typography/Title';
import { transparentize } from 'polished';

const { Title: ATitle, Paragraph: AParagraph, Text: AText } = ATypography;

export const tYzTypeTypography = tuple('HEAD', 'BODY');
export const tYzTypeTypographyText = tuple(
  'title',
  'sub-title',
  'basic',
  'button',
  'symptom',
  'survey-main-question',
  'survey-validation',
  'landing-page-big-font',
  'landing-page-small-font',
  'landing-page-footer',
  'symptom-table-head',
  'symptom-table-body',
);
export const tLevel = tupleNum(1, 2, 3, 4, 5);

export type TYzTypeTypography = (typeof tYzTypeTypography)[number] | undefined;
export type TYzTypeTypographyText = (typeof tYzTypeTypographyText)[number] | undefined;

type TLevel = (typeof tLevel)[number] | undefined;

type TStyledTypographyParams = {
  yjTypeTypography?: TYzTypeTypography;
  marginBottom?: string;
  marginTop?: string;
};

export type TTypographyParams = TypographyProps &
  TStyledTypographyParams & {
    children: any;
  };

export interface ITitleParams extends TitleProps {
  level: TLevel;
  icon?: React.ReactNode;
  iconStyle?: any;
  isBold?: boolean;
}

export interface IParagraphParams extends ParagraphProps {}

type TStyledTypographyTextParams = TextProps & {
  yjTypeTypographyText?: TYzTypeTypographyText;
  fontSize?: string;
  color?: string;
  fontWeight?: number;
  lineHeight?: string;
  fontStyle?: string;
  letterSpacing?: string;
  textDecoration?: string;
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';
  wordBreak?:
    | 'normal'
    | 'break-all'
    | 'keep-all'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';
};

export type ITextParams = {
  children?: ReactNode;
} & TStyledTypographyTextParams &
  TextProps;

const handleTypographyWithType = (yjTypeTypography: TYzTypeTypography) => {
  switch (yjTypeTypography) {
    case 'HEAD':
      return css`
        padding-left: 0.55rem;
      `;
    case 'BODY':
      return css`
        padding-left: 0.45rem;
      `;
    default:
      return '';
  }
};

const StyledTypography = styled(ATypography, {
  shouldForwardProp: isPropValid,
})<TStyledTypographyParams>`
  ${({ yjTypeTypography }) => handleTypographyWithType(yjTypeTypography)}
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
`;

export const Typography = ({ children, ...rest }: TTypographyParams) => (
  <StyledTypography {...rest}>{children}</StyledTypography>
);

const StyledTypographyTitle = styled(ATitle, {
  shouldForwardProp: isPropValid,
})<ITitleParams>`
  font-weight: ${({ isBold }) => (isBold ? 'bold !important' : 'normal !important')};
  color: ${color.infoText};
  margin-bottom: 0;
  ${({ level }) => {
    switch (level) {
      case 1:
        return css`
          font-size: 1.35rem !important;
        `;
      case 2:
        return css`
          font-size: 0.6rem !important;
        `;
      case 3:
        return css`
          font-size: 1rem !important;
        `;
      case 4:
        return css`
          font-size: 0.9rem !important;
        `;
      case 5:
        return css`
          font-size: 0.8rem !important;
        `;
      default:
        return css``;
    }
  }}
`;

export const TypographyTitle = ({ children, icon, iconStyle, level = 1, ...props }: ITitleParams) => (
  <StyledTypographyTitle {...props} level={level < 5 ? level : 4}>
    <span style={iconStyle}>{icon}</span>
    <span>{children}</span>
  </StyledTypographyTitle>
);

const StyledTypographyParagraph = styled(AParagraph, {
  shouldForwardProp: isPropValid,
})<IParagraphParams>`
  margin-bottom: 0 !important;
  font-size: 0.8rem;
  color: ${color.infoText};
  white-space: pre-line;
  word-break: keep-all;
`;

export const TypographyParagraph = ({ children, ...rest }: IParagraphParams) => (
  <StyledTypographyParagraph {...rest}>{children}</StyledTypographyParagraph>
);

const handleTypographyTextWithType = (yjTypeTypographyText: TYzTypeTypographyText) => {
  switch (yjTypeTypographyText) {
    case 'title':
      return css`
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: -0.02rem;
        line-height: 1.8rem;
      `;
    case 'landing-page-big-font':
      return css`
        font-size: 2.4rem;
        font-weight: 300;
        letter-spacing: -0.03rem;
        line-height: 3.1rem;
        white-space: nowrap;
      `;
    case 'landing-page-small-font':
      return css`
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: -0.01rem;
        white-space: nowrap;
        display: block;
      `;
    case 'landing-page-footer':
      return css`
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.6rem;
        letter-spacing: -0.01rem;
        color: #69737d;
        display: block;
      `;
    case 'sub-title':
      return css`
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: -0.02rem;
        line-height: 1.2em;
      `;
    case 'symptom-table-head':
      return css`
        font-size: 0.8rem;
        font-weight: 400;
        letter-spacing: -0.02rem;
        line-height: 0.8rem;
      `;
    case 'symptom-table-body':
      return css`
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.05rem;
        line-height: 1rem;
      `;
    case 'survey-validation':
      return css`
        animation: fadein 0.3s;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 0.95rem;
        color: #df635c;
      `;
    case 'symptom':
      return css`
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: -0.01rem;
        line-height: 1.05rem;
      `;
    case 'basic':
      return css`
        font-size: 1.2rem;
        line-height: 3rem;
        letter-spacing: -0.01em;
        font-weight: 400;
      `;
    case 'survey-main-question':
      return css`
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 1.2rem;
        color: #1d1c1d;
      `;
    case 'button':
      return css`
        font-size: 0.8rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
        line-height: 0.8rem;

        :disabled {
          border: none;
          color: ${transparentize(0.3, color.disabled)};
        }
      `;
    default:
      return css`
        font-size: 1rem;
      `;
  }
};

const StyledTypographyText = styled(AText, {
  shouldForwardProp: isPropValid,
})<TStyledTypographyTextParams>`
  margin: 0 !important;
  color: ${color.infoText};
  font-size: 1rem;
  line-height: 1em;

  ${({ yjTypeTypographyText }) => handleTypographyTextWithType(yjTypeTypographyText)}

  ${({ fontSize }) => css`
    font-size: ${fontSize};
  `}
  ${({ color: customColor }) => css`
    color: ${customColor};
  `}
  ${({ fontWeight }) => css`
    font-weight: ${fontWeight};
  `}
  ${({ lineHeight }) => css`
    line-height: ${lineHeight};
  `}
  ${({ fontStyle }) => css`
    font-style: ${fontStyle};
  `}
  ${({ letterSpacing }) => css`
    letter-spacing: ${letterSpacing};
  `}
  ${({ textDecoration }) => css`
    text-decoration: ${textDecoration};
  `}
  ${({ whiteSpace }) => css`
    white-space: ${whiteSpace};
  `}
  ${({ wordBreak }) => css`
    word-break: ${wordBreak};
  `}
`;

export const TypographyText = ({ children, ...rest }: ITextParams) => (
  <StyledTypographyText {...rest}>{children}</StyledTypographyText>
);
