import React, { useState } from 'react';
import throttle from 'lodash/throttle';

import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
// import { ico16Hide, ico16Show } from '@images/svg';
import { color } from '../../../../styles/themes';
import { Input as AInput, InputProps } from 'antd';
import { tuple } from 'antd/lib/_util/type';
import { Row, Col } from 'components/UI/atoms';
import { PasswordProps, TextAreaProps } from 'antd/lib/input';

export const tYzTypeTextArea = tuple('form', 'surveyItem', 'survey-desc');

const { TextArea: ATextArea, Group: AInputGroup, Password: APassword } = AInput;

export interface TInputParams extends InputProps {}

export interface TTextAreaParams extends TextAreaProps {
  yjTypeTextArea?: (typeof tYzTypeTextArea)[number] | undefined;
}

export interface TPasswordParams extends PasswordProps {}

// type TIconType = {
//   disabled?: boolean;
// };

const StyledInput = styled(AInput)<TInputParams>`
  height: 1.8rem;
  padding: 0.35rem 0.6rem 0.35rem 0.6rem !important;
  box-shadow: none !important;
  .ant-form-item-has-error &.ant-input {
    color: ${({ theme }) => theme.error3} !important;
  }
  .ant-input-prefix,
  .ant-input-suffix {
    position: relative;
    top: 1px;
  }
`;

export const Input = ({ ...rest }: TInputParams) => <StyledInput {...rest} />;

export const InputGroup = styled(AInputGroup)``;

export const TextArea = styled(ATextArea, {
  shouldForwardProp: isPropValid,
})<TTextAreaParams>`
  ${({ yjTypeTextArea }) => {
    switch (yjTypeTextArea) {
      case 'surveyItem': {
        return css`
          border: 1px solid #e5e5e5;
          height: 5rem !important;
          min-height: 5rem !important;
          width: calc(100vw - 2.4rem) !important;
          border-radius: 3px !important;
          resize: none;
          color: ${color.infoText};
          padding: 0.8rem;
          font-weight: 400 !important;
          font-size: 0.75rem !important;
          line-height: 0.9rem !important;
          letter-spacing: -0.01em !important;

          ::placeholder {
            color: #afafaf;
            font-weight: 400 !important;
            font-size: 0.75rem !important;
            line-height: 0.9rem !important;
            letter-spacing: -0.01em !important;
          }

          :active,
          :focus,
          :hover {
            border-color: #e5e5e5 !important;
            outline: none !important;
            box-shadow: 0 0 0.3rem #e5e5e5;
          }

          !important &:disabled {
            background: rgba(142, 172, 255, 0.07);
            color: #363c50;
            border: 0;
          }
        `;
      }
      case 'form': {
        return css`
          border: 1px solid #e5e5e5;
          height: 7rem !important;
          width: 100% !important;
          max-width: 65.5rem !important;
          border-radius: 3px !important;
          resize: none;
          color: ${color.infoText};
          padding: 1rem 0.6rem 1rem 1rem;
          font-weight: 400 !important;
          font-size: 1rem !important;
          line-height: 1rem !important;
          letter-spacing: 0.005em !important;

          ::placeholder {
            color: #afafaf;
            font-weight: 400 !important;
            font-size: 1rem !important;
            line-height: 1rem !important;
            letter-spacing: 0.005em !important;
          }

          :active,
          :focus,
          :hover {
            border-color: #e5e5e5 !important;
            outline: none !important;
          }
        `;
      }
      case 'survey-desc': {
        return css`
          border: 1px solid #e5e5e5;
          height: 7rem !important;
          width: 31rem !important;
          margin-left: 1rem;
          border-radius: 3px !important;
          resize: none;
          color: ${color.infoText};
          padding: 1rem 0.6rem 1rem 1rem;
          font-weight: 400 !important;
          font-size: 1rem !important;
          line-height: 1rem !important;
          letter-spacing: 0.005em !important;

          ::placeholder {
            color: #afafaf;
            font-weight: 400 !important;
            font-size: 1rem !important;
            line-height: 1rem !important;
            letter-spacing: 0.005em !important;
          }

          :active,
          :focus,
          :hover {
            border-color: #e5e5e5 !important;
            outline: none !important;
          }
        `;
      }
      default: {
        return css`
          :active,
          :focus,
          :hover {
            border-color: #e5e5e5 !important;
            outline: none !important;
          }
        `;
      }
    }
  }}
`;

const StyledPassword = styled(APassword)<TPasswordParams>`
  height: 1.8rem;
  padding: 0.35rem 0.6rem 0.35rem 0.6rem !important;
  box-shadow: none !important;
`;

export const SearchInput = ({
  searchFunc,
  placeholder,
  buttonText = '추가',
  buttonWidth = '5rem',
  columnGap = '0.45rem',
  defaultSearchValue = '',
  resetSearchValueAfterSearch = false,
}) => {
  const [searchValue, setSearchValue] = useState(defaultSearchValue);

  return (
    <Row style={{ display: 'flex', columnGap: columnGap, width: '100%' }}>
      <Col
        style={{
          flex: 1,
        }}
      >
        <Col style={{ position: 'relative', flex: 1 }}>
          <Input
            style={{
              height: '2.4rem',
              width: '100%',
              border: '1px solid #c4c4c4',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              lineHeight: '1.2em',
              fontSize: '0.8rem',
              fontWeight: '400',
              textIndent: '1.2rem',
            }}
            placeholder={placeholder}
            className="search-input"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyUp={throttle((e) => {
              if (e.keyCode === 13) {
                searchFunc(searchValue);
                resetSearchValueAfterSearch && setSearchValue('');
              }
            }, 300)}
          />
          {searchValue?.length > 0 && (
            <Col
              style={{
                position: 'absolute',
                right: '0.5rem',
                top: '50%',
                translate: '0 -50%',
                height: '0.9rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSearchValue('');
                searchFunc('');
                resetSearchValueAfterSearch && setSearchValue('');
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                style={{ width: '0.9rem' }}
                alt="serach_initialization_icon"
              />
            </Col>
          )}
          <Col
            style={{
              position: 'absolute',
              left: '0.5rem',
              top: 'calc(50% + 0.05rem)',
              transform: 'translateY(-50%)',
              height: '0.9rem',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/search_icon.webp`}
              style={{ width: '0.9rem' }}
              alt="search_icon"
            />
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export const Password = ({ ...rest }: TInputParams) => <StyledPassword {...rest} />;
