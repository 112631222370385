import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'stores/Context';
import { Row, Col } from 'components/UI/atoms';
import { TableCell } from 'components/UI/atoms/table/TableCell';

const SymptomHighlightTd = ({
  symptom,
  highlight = {},
  setHighlight,
  readonlyFlag,
}: {
  symptom: any;
  highlight: any;
  setHighlight: any;
  readonlyFlag: boolean;
}) => {
  const { treatmentStore } = useStores();
  return (
    <>
      {readonlyFlag ? (
        <>
          <TableCell
            textAlign={'center'}
            style={{
              borderLeft: 'none',
              borderRight: 'none',
              paddingLeft: '0',
              paddingRight: '0.6rem',
            }}
          >
            {highlight[symptom.code] && (
              <Col style={{ display: 'flex', width: 'fit-content', margin: '0 auto' }}>
                <img
                  src={`${process.env.PUBLIC_URL}/img/readonly-check-icon.webp`}
                  style={{ width: '1.2rem', cursor: 'pointer' }}
                  alt={`${symptom.code}-readonly`}
                />
              </Col>
            )}
          </TableCell>
        </>
      ) : (
        <TableCell
          textAlign={'center'}
          style={{
            borderLeft: 'none',
            borderRight: 'none',
            paddingLeft: '0',
            paddingRight: '0.6rem',
          }}
        >
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={async () => {
              if (highlight[symptom.code]) {
                await treatmentStore.deleteCheckedSurvey(symptom.code);
                await setHighlight({
                  ...highlight,
                  [symptom.code]: false,
                });
              } else {
                await treatmentStore.addCheckedSurvey(symptom.code);
                await setHighlight({
                  ...highlight,
                  [symptom.code]: true,
                });
              }
            }}
          >
            {highlight[symptom.code] ? (
              <img
                src={`${process.env.PUBLIC_URL}/img/checked-blue.webp`}
                style={{ width: '1.2rem' }}
                alt={`checked`}
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/img/not-checked.webp`}
                style={{ width: '1.2rem' }}
                alt={`checked`}
              />
            )}
          </Col>
        </TableCell>
      )}
    </>
  );
};

export default observer(SymptomHighlightTd);
