import React from 'react';

import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Button as AButton, ButtonProps } from 'antd';
import { tuple } from 'antd/lib/_util/type';

export const tYzTypeButton = tuple(
  'grey-link',
  'footer-black-button',
  'footer-white-button',
  'symptom-black-button',
  'symptom-white-button',
);

export type TYzTypeButton = (typeof tYzTypeButton)[number] | undefined;

type THandleButtonWithTypeParams = {
  yjTypeButton?: TYzTypeButton;
};

interface IStyledbutton extends ButtonProps {
  /** Button Style */
  yjTypeButton?: TYzTypeButton;
  isInputButton?: boolean;
  round?: boolean;
}

export interface IButtonParams extends IStyledbutton {
  buttonText?: string | React.ReactNode;
}

const handleButtonWithType = ({ yjTypeButton }: THandleButtonWithTypeParams) => {
  switch (yjTypeButton) {
    case 'symptom-black-button':
      return css`
        width: 7.6rem;
        height: 2.6rem;
        border: none;
        background: #1c252c;
        color: #ffffff;
        padding: 0.75rem 1.1rem;
        border-radius: 3px;

        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.005em;
        font-weight: 700;

        :hover {
          cursor: pointer;
        }

        &[disabled],
        &[disabled]:hover {
          border: none;
        }
      `;
    case 'symptom-white-button':
      return css`
        width: 7.6rem;
        height: 2.6rem;
        border: none;
        background: #ffffff;
        color: #1d1c1d;
        padding: 0.75rem 1.1rem;
        border: 1px solid #afafaf;
        border-radius: 3px;

        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.005em;
        font-weight: 700;

        :hover {
          cursor: pointer;
        }

        &[disabled],
        &[disabled]:hover {
          border: none;
        }
      `;
    case 'footer-white-button':
      return css`
        width: 100%;
        height: 3.2rem;
        border: none;
        background: #ffffff;
        color: #1d1c1d;
        padding: 1rem 2rem;
        border: 1px solid #afafaf;

        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.005rem;
        font-weight: 700;

        :hover {
          cursor: pointer;
        }

        &[disabled],
        &[disabled]:hover {
          border: none;
        }
      `;
    case 'footer-black-button':
      return css`
        width: 100%;
        height: 3.2rem;
        border: none;
        background: #1c252c;
        color: #ffffff !important;
        padding: 1rem 2rem;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.005rem;
        font-weight: 700;

        :hover {
          cursor: pointer;
        }

        &[disabled],
        &[disabled]:hover {
          border: none;
        }
      `;
    case 'grey-link':
      return css`
        border: none;
        border-bottom: 1px #1c252c solid;
        border-radius: 0;
        height: 1.4rem;
        align-items: flex-start;
        padding: 0.45rem 0px 0px 0px;
        gap: 0.5rem;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.2rem;
        background: none;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;

        color: #1c252c;
        :hover {
          cursor: pointer;
        }
      `;
    default:
      return css``;
  }
};

export const StyledButton = styled(AButton, {
  shouldForwardProp: isPropValid,
})<IStyledbutton>`
  height: 1.8rem;
  border-radius: 3px;
  font-size: 0.9rem;
  font-family: Lato, NotoSansCJKkr, sans-serif;

  & svg,
  & img {
    margin-right: 0.2rem;
  }

  &[disabled] {
    background-color: #afafaf !important;
    color: white !important;
    cursor: not-allowed;
  }
  ${({ yjTypeButton }) => handleButtonWithType({ yjTypeButton })}
`;

export const Button = ({ buttonText, children, ...rest }: IButtonParams) => {
  switch (rest.yjTypeButton) {
    default:
      return <StyledButton {...rest}>{buttonText || children}</StyledButton>;
  }
};

export const MinusButton = ({ onClick }) => (
  <div
    className="relative border border-afafaf w-4.8 h-4.8 border-collapse cursor-pointer bg-white "
    onClick={onClick}
  >
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 h-2px bg-1c252c  " />
  </div>
);

export const PlusButton = ({ onClick, style }: { onClick: () => void; style?: any }) => (
  <div
    style={style}
    className="relative border border-afafaf w-4.8 h-4.8 cursor-pointer flex bg-white "
    onClick={onClick}
  >
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 h-2px bg-1c252c  " />
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-2px h-1/2 bg-1c252c " />
  </div>
);
