import React from 'react';
import { observer } from 'mobx-react';

import { Col } from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import { TableCell } from 'components/UI/atoms/table/TableCell';

const FeedbackCheckTd = ({
  symptom,
  checklist = {},
  setChecklist,
}: {
  symptom: any;
  checklist: any;
  setChecklist: any;
}) => {
  const { feedbackStore } = useStores();
  return (
    <TableCell
      textAlign={'center'}
      style={{
        borderLeft: 'none',
        borderRight: 'none',
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      {symptom?.value?.scale >= 0 && (
        <Col
          style={{ display: 'flex', width: 'fit-content', margin: '0 auto', cursor: 'pointer' }}
          onClick={async () => {
            if (!checklist[symptom.code]) {
              await feedbackStore.addSymptoms(symptom);
              await setChecklist({
                ...checklist,
                [symptom.code]: true,
              });
            } else {
              await feedbackStore.deleteSymptoms(symptom.code);
              await setChecklist({
                ...checklist,
                [symptom.code]: false,
              });
            }
          }}
        >
          {checklist[symptom.code] ? (
            <img
              src={`${process.env.PUBLIC_URL}/img/checked-talk-icon-blue.webp`}
              style={{ width: '1.2rem', cursor: 'pointer' }}
              alt={`${symptom.code}checked`}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/img/default-talk-icon.webp`}
              style={{ width: '1.2rem', cursor: 'pointer' }}
              alt={`${symptom.code}unchecked`}
            />
          )}
        </Col>
      )}
    </TableCell>
  );
};

export default observer(FeedbackCheckTd);
