export const byeonjeungDataSample = {
  팔강: {
    한: 0,
    열: 0,
    표: 0,
    리: 0,
    허: 0,
    실: 0,
  },
  변증: [
    {
      code: 'B004',
      koreanName: '습열',
      chineseName: '濕熱',
      koreanDesc:
        '습기와 열기가 결합한 것으로, 열이 나며 머리가 아프고 몸이 무거우며 뱃속이 더부룩하고 소변이 짧아지는 증상이 주로 있어요',
      chineseDesc: '院腹漲悶 頭身困重 嘔吐 身熱 午後熱甚 小便短赤 目黃',
      weight: 23,
      checkFlag: false,
      relativeSymptomCode: [
        {
          code: 'S105',
          contribution: 100,
        },
      ],
    },
    {
      code: 'B025',
      koreanName: '기허',
      chineseName: '氣虛',
      koreanDesc:
        '기운이 허약하다는 뜻으로, 선천적으로 부족하거나 장기가 손상되어 발생하는 증상이 주로 있어요',
      chineseDesc: '自汗 氣短 動則氣急 大便溏薄 小便淋瀝',
      weight: 12.2,
      checkFlag: false,
      relativeSymptomCode: [
        {
          code: 'S105',
          contribution: 100,
        },
      ],
    },
    {
      code: 'B027',
      koreanName: '혈허',
      chineseName: '血虛',
      koreanDesc:
        '피가 부족하다는 뜻으로, 영양이 부족하거나 만성 질병, 출혈로 발생하며 얼굴이 창백하고 앉았다 일어나면 어지럽고 손발에 마비가 일어나는 증상이 주로 있어요',
      chineseDesc: '面色不華 面色淡白 指甲不榮 手足麻木',
      weight: 11.9,
      checkFlag: false,
      relativeSymptomCode: [
        {
          code: 'S105',
          contribution: 100,
        },
      ],
    },
    {
      code: 'B003',
      koreanName: '실열',
      chineseName: '實熱',
      koreanDesc:
        '실제 있는 열로, 우리 몸의 기운과 외부에서 침투한 기운이 맞서 싸우는 과정에서 발생하는 열로 몸이 뜨겁고 답답하며 배에 통증이 생기고 목이 마르며 변비가 생기는 증상이 주로 있어요',
      chineseDesc: '壯熱 煩渴 神昏 腹服滿病痛 拒援 大便閉結',
      weight: 9.2,
      checkFlag: false,
      relativeSymptomCode: [
        {
          code: 'S105',
          contribution: 100,
        },
      ],
    },
    {
      code: 'B012',
      koreanName: '열림',
      chineseName: '熱淋',
      koreanDesc:
        '열로 인한 비뇨기질환으로, 습기와 열기가 결합하여 아랫 배에 몰려서 생기며 소변을 조금씩 자주 보며 잘 나오지 않고 작열감을 동반하며 아랫배가 당기며 아픈 증상이 주로 있어요',
      chineseDesc: '小便黃柏汁 熱澁 小腹拘急疼痛',
      weight: 8.9,
      checkFlag: false,
      relativeSymptomCode: [
        {
          code: 'S105',
          contribution: 100,
        },
      ],
    },
  ],
};
