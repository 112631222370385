/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import message from 'antd-message';
import moment from 'moment';
import _ from 'lodash';
import { observer } from 'mobx-react';

import { useStores } from 'stores/Context';
import { TooltipCard } from 'components/UI/molecules';
import { Row, Col, Input, TypographyText, ModalType2, Modal } from 'components/UI/atoms';
import PR from 'repository/PrescriptionRepository';
import { treatmentModeMap, 기준처방분류코드_질환_Map } from 'constant/index';
import { checkBatchimEnding } from 'utils/util';

const MyPrescriptionSaveModal = ({
  treatmentMode,
  isOpen,
  hiddenFlag,
  setIsOpen,
  setRefreshFlag,
  prescribedHerbs,
  myPrescriptionList,
  setIsOpenAddHerbMethodModal,
  setPrescribedHerbs,
  setPrescribedHerbTableScrollTrigger,
  setSelectedPrescriptionData,
  setSelectedMyPrescriptionData,
  setIsOpenPrescriptionInfoModal,
  setNeedConfirmationHerbsTemp,
  setDontNeedConfirmationHerbsTemp,
}) => {
  const { prescriptionStore: ps } = useStores();

  const [isLoadingForDelete, setIsLoadingForDelete] = useState(false);
  const [targetDataForDelete, setTargetDataForDelete] = useState<any>({});
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueTemp, setSearchValueTemp] = useState('');
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isOpenTooltip, setIsOpenTooltip] = useState('');

  const buttonStyle = useMemo(
    () => ({
      borderRadius: '3px',
      paddingInline: '0.75rem',
      paddingBlock: '0.35rem',
      background: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  );
  const convertInsuranceDiseaseCodeObj = useMemo(
    () => ({
      B: 'N944',
      C: 'G510',
      D: 'I69',
      E: 'M51',
      F: 'J30',
      G: 'K30',
    }),
    [],
  );
  const disabledBtnCondition1 = useMemo(
    () => ![undefined, 'GENERAL'].includes(treatmentMode),
    [treatmentMode],
  );

  useEffect(() => {
    setSearchTrigger(true);
  }, [myPrescriptionList]);

  useEffect(() => {
    if (searchTrigger) {
      if (searchValue) {
        setList(myPrescriptionList.filter((e) => e.kn.includes(searchValue)));
      } else {
        setList(myPrescriptionList);
      }
      setSearchTrigger(false);
    }
  }, [searchTrigger]);

  return (
    <>
      <ModalType2
        isOpen={isOpen}
        width={34}
        title={`나의 처방`}
        hiddenFlag={hiddenFlag}
        content={
          <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
            <Row style={{ display: 'flex', alignItems: 'center', columnGap: '0.4rem' }}>
              <Col style={{ position: 'relative' }}>
                <Input
                  style={{
                    height: '2.1rem',
                    width: '14rem',
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    fontSize: '0.8rem',
                    lineHeight: '0.8rem',
                    outline: 'none',
                    textIndent: '1.2rem',
                  }}
                  value={searchValueTemp}
                  placeholder="처방명으로 검색"
                  onKeyUp={async (e) => {
                    if (e.keyCode === 13) {
                      await setSearchValue(searchValueTemp);
                      await setSearchTrigger(true);
                    }
                  }}
                  onChange={(e) => {
                    setSearchValueTemp(e.target.value);
                  }}
                  autoComplete="off"
                />
                {searchValue?.length > 0 && (
                  <Col
                    style={{
                      position: 'absolute',
                      display: 'flex',
                      right: '0.5rem',
                      top: '0.6rem',
                      cursor: 'pointer',
                      height: '1rem',
                    }}
                    onClick={async () => {
                      await setSearchValueTemp('');
                      await setSearchValue('');
                      await setSearchTrigger(true);
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                      style={{ width: '1rem' }}
                      alt="serach_initialization_icon"
                    />
                  </Col>
                )}
                <Col
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    left: '0.5rem',
                    top: 'calc(50% + 0.05rem)',
                    transform: 'translateY(-50%)',
                    height: '1rem',
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/search_icon.webp`}
                    style={{ width: '1rem' }}
                    alt="search_icon"
                  />
                </Col>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  height: '2.1rem',
                  border: '1px solid #346AFF',
                  borderRadius: '3px',
                  background: '#ffffff',
                  cursor: 'pointer',
                  flexShrink: 0,
                }}
                onClick={async () => {
                  await setSearchValue(searchValueTemp);
                  await setSearchTrigger(true);
                }}
              >
                <TypographyText
                  color="#346AFF"
                  fontSize="1rem"
                  letterSpacing="0.05em"
                  fontWeight={700}
                  lineHeight="1.2rem"
                >
                  검색
                </TypographyText>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  height: '2.1rem',
                  border: '1px solid #afafaf',
                  borderRadius: '3px',
                  background: '#ffffff',
                  cursor: 'pointer',
                  flexShrink: 0,
                }}
                onClick={async () => {
                  await setSearchValueTemp('');
                  await setSearchValue('');
                  await setSearchTrigger(true);
                }}
              >
                <TypographyText
                  color="#1c252c"
                  fontSize="1rem"
                  letterSpacing="0.05em"
                  fontWeight={700}
                  lineHeight="1.2rem"
                >
                  초기화
                </TypographyText>
              </Col>
            </Row>
            <table className="my-prescription-table">
              <thead>
                <tr>
                  <th></th>
                  <th>처방명</th>
                  <th>생성일</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list?.length > 0 ? (
                  list.map((e, i) => {
                    const 비급여Flag = !e.insrCCode;
                    const disabledBtnFlag =
                      disabledBtnCondition1 && // 현재 진료가 비급여 모드가 아니면서
                      (비급여Flag || // 처방에 기준처방분류코드가 없거나 (비급여)
                        !기준처방분류코드_질환_Map.get(e.insrCCode)?.includes(treatmentMode)); // 질환에 해당하는 기준처방분류코드가 없으면
                    return (
                      <>
                        <tr key={e.id}>
                          <td>
                            <Row
                              className={`my-prescription-plus-minus-wrapper`}
                              style={{
                                display: 'flex',
                                cursor: 'pointer',
                                marginTop: '0.1rem',
                              }}
                              onClick={() => {
                                setSelectedIndex(selectedIndex === i ? -1 : i);
                              }}
                            >
                              <img
                                className="black-plus-minus"
                                src={`${process.env.PUBLIC_URL}/img/black-${
                                  selectedIndex === i ? 'minus' : 'plus'
                                }.webp`}
                                style={{ width: '1rem', cursor: 'pointer' }}
                                alt="펼치기"
                              />
                              <img
                                className="blue-plus-minus"
                                src={`${process.env.PUBLIC_URL}/img/blue-${
                                  selectedIndex === i ? 'minus' : 'plus'
                                }.webp`}
                                style={{ width: '1rem', cursor: 'pointer' }}
                                alt="펼치기"
                              />
                            </Row>
                          </td>
                          <td>
                            <TypographyText
                              style={{
                                fontSize: '0.9rem',
                                lineHeight: '1.2em',
                              }}
                            >
                              {e.kn}
                            </TypographyText>
                          </td>
                          <td>
                            <TypographyText
                              style={{
                                fontSize: '0.9rem',
                                lineHeight: '1.2em',
                              }}
                            >
                              {moment(e.createdAt).format('YYYY-MM-DD')}
                            </TypographyText>
                          </td>
                          <td>
                            <Row
                              style={{
                                width: '100%',
                                display: 'flex',
                                columnGap: '0.3rem',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Col
                                id={`${e.id}-btn`}
                                style={{ position: 'relative' }}
                                onMouseOver={() => {
                                  if (disabledBtnFlag) {
                                    setIsOpenTooltip(`${e.id}`);
                                  }
                                }}
                                onMouseOut={() => {
                                  if (disabledBtnFlag) {
                                    setIsOpenTooltip('');
                                  }
                                }}
                              >
                                {disabledBtnFlag && (
                                  <TooltipCard
                                    isOpen={isOpenTooltip === `${e.id}`}
                                    targetComponentId={`${e.id}-btn`}
                                    topRem={-3.75}
                                    leftRem={0}
                                    minWidth={'4rem'}
                                    description={[
                                      <Row
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          rowGap: '0.3rem',
                                        }}
                                      >
                                        <TypographyText
                                          fontSize="0.8rem"
                                          whiteSpace="pre-wrap"
                                          wordBreak="keep-all"
                                        >
                                          {`"${e.kn}"${checkBatchimEnding(e.kn) ? '은' : '는'} ${
                                            treatmentModeMap.get(treatmentMode).label
                                          }에 급여로 처방할 수 없습니다.`}
                                        </TypographyText>
                                        <div className="flex gap-x-1">
                                          {비급여Flag ? (
                                            <TypographyText
                                              fontSize="0.8rem"
                                              whiteSpace="pre-wrap"
                                              wordBreak="keep-all"
                                            >
                                              {`비급여에서 처방가능합니다.`}
                                            </TypographyText>
                                          ) : (
                                            <>
                                              <TypographyText
                                                fontWeight={700}
                                                fontSize="0.8rem"
                                                whiteSpace="pre-wrap"
                                                wordBreak="keep-all"
                                              >
                                                {'[처방 가능 질환]'}
                                              </TypographyText>
                                              <TypographyText
                                                fontSize="0.8rem"
                                                whiteSpace="pre-wrap"
                                                wordBreak="keep-all"
                                              >
                                                {`${기준처방분류코드_질환_Map
                                                  .get(e.insrCCode)
                                                  ?.map((d) => treatmentModeMap.get(d).label)
                                                  .join(', ')}`}
                                              </TypographyText>
                                            </>
                                          )}
                                        </div>
                                      </Row>,
                                    ]}
                                  />
                                )}
                                <button
                                  style={{
                                    ...buttonStyle,
                                    border: disabledBtnFlag ? '1px solid #afafaf' : '1px solid #346AFF',
                                    background: disabledBtnFlag ? '#afafaf' : '#346AFF',
                                  }}
                                  onClick={async () => {
                                    if (disabledBtnFlag) return;
                                    const duplicatedHerbs = {};
                                    const newPrescribedHerbs = {};
                                    _.sortBy(e.recipe, (e) => -e.amt).forEach((e) => {
                                      if (prescribedHerbs[e.pc]) {
                                        duplicatedHerbs[e.pc] = {
                                          code: e.pc,
                                          name: ps.yejinHerbForSearchByProcessingCode.get(e.pc).koreanName,
                                          amount: e.amt,
                                        };
                                      } else {
                                        newPrescribedHerbs[e.pc] = {
                                          code: e.pc,
                                          amount: e.amt,
                                        };
                                      }
                                    });

                                    if (_.isEmpty(prescribedHerbs)) {
                                      setPrescribedHerbs(newPrescribedHerbs);
                                      setPrescribedHerbTableScrollTrigger(true);
                                      if (e.originalHerbalFormulaData) {
                                        setSelectedPrescriptionData(e.originalHerbalFormulaData);
                                      }
                                      setSelectedMyPrescriptionData(e);
                                      setIsOpen({
                                        isOpen: false,
                                        hiddenFlag: false,
                                      });
                                      message.success(`약제가 추가되었습니다.`, 2500);
                                      setIsOpenPrescriptionInfoModal({});
                                    } else {
                                      await setNeedConfirmationHerbsTemp(duplicatedHerbs);
                                      await setDontNeedConfirmationHerbsTemp(newPrescribedHerbs);
                                      if (e.originalHerbalFormulaData) {
                                        setIsOpenPrescriptionInfoModal({
                                          ...e.originalHerbalFormulaData,
                                          customHerbalFormulaData: e,
                                          hiddenModalFlag: true,
                                          from: 'plusButton',
                                        });
                                      }
                                      setIsOpen({
                                        isOpen: true,
                                        hiddenFlag: true,
                                      });
                                      setIsOpenAddHerbMethodModal(true);
                                    }
                                  }}
                                >
                                  <TypographyText
                                    style={{
                                      fontSize: '0.8rem',
                                      fontWeight: 700,
                                      lineHeight: '1.375em',
                                      whiteSpace: 'pre',
                                      color: '#ffffff',
                                    }}
                                  >
                                    처방하기
                                  </TypographyText>
                                </button>
                              </Col>
                              <Col>
                                <button
                                  style={{ ...buttonStyle, border: '1px solid #afafaf' }}
                                  onClick={() => {
                                    setIsOpen({
                                      isOpen: true,
                                      hiddenFlag: true,
                                    });
                                    setTargetDataForDelete(e);
                                  }}
                                >
                                  <TypographyText
                                    style={{
                                      fontSize: '0.8rem',
                                      fontWeight: 700,
                                      lineHeight: '1.375em',
                                      whiteSpace: 'pre',
                                    }}
                                  >
                                    삭제
                                  </TypographyText>
                                </button>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                        {selectedIndex === i && (
                          <tr style={{ borderBlock: '3px solid #e5e5e5' }}>
                            <td colSpan={4}>
                              <table className="my-prescription-sub-table">
                                <colgroup>
                                  <col style={{ width: '50%' }} />
                                  <col style={{ width: '50%' }} />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th>
                                      <TypographyText
                                        style={{
                                          fontSize: '0.8rem',
                                          lineHeight: '1.2em',
                                          fontWeight: 700,
                                        }}
                                      >
                                        약재명
                                      </TypographyText>
                                    </th>
                                    <th>
                                      <TypographyText
                                        style={{
                                          fontSize: '0.8rem',
                                          lineHeight: '1.2em',
                                          fontWeight: 700,
                                        }}
                                      >
                                        1첩용량(g)
                                      </TypographyText>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(_.sortBy(e.recipe, (r) => -r.amt) ?? []).map((r) => {
                                    const herbInfo = ps.yejinHerbForSearchByProcessingCode.get(r.pc);
                                    return (
                                      <tr key={`${i}-${r.pc}`}>
                                        <td>
                                          <TypographyText
                                            style={{
                                              fontSize: '0.9rem',
                                              lineHeight: '1.2em',
                                            }}
                                          >
                                            {herbInfo.koreanName}
                                          </TypographyText>
                                        </td>
                                        <td>
                                          <TypographyText
                                            style={{
                                              fontSize: '0.8rem',
                                              lineHeight: '1.2em',
                                            }}
                                          >
                                            {_.round(r.amt / 1000, 2)}
                                          </TypographyText>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      <TypographyText
                        style={{
                          fontSize: '0.9rem',
                          lineHeight: '1.2em',
                          textAlign: 'center',
                          color: '#afafaf',
                        }}
                      >
                        등록된 나의 처방이 없습니다.
                      </TypographyText>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Row>
        }
        contentHeight={'30rem'}
        cancelMsg="닫기"
        cancelFunction={() =>
          setIsOpen({
            isOpen: false,
            hiddenFlag: false,
          })
        }
      />
      {!_.isEmpty(targetDataForDelete) && (
        <Modal
          contentMsg={`<${targetDataForDelete.kn}>을 정말 삭제하시겠습니까?`}
          cancelMsg="취소"
          okMsg="네, 삭제하겠습니다."
          okBtnLoading={isLoadingForDelete}
          okFunction={async () => {
            if (isLoadingForDelete) return;
            try {
              await setIsLoadingForDelete(true);
              await PR.updateCustomHerbalFormula({
                id: targetDataForDelete.id,
                isDeleted: true,
              });
              setRefreshFlag(true);
              message.success(`<${targetDataForDelete.kn}> 삭제를 완료했습니다.`, 3000);
            } catch (e) {
              console.log(e);
              message.error(`<${targetDataForDelete.kn}> 삭제 과정에 문제가 발생했습니다. ${e}`, 3000);
            } finally {
              setIsLoadingForDelete(false);
              setTargetDataForDelete({});
              setIsOpen({
                isOpen: true,
                hiddenFlag: false,
              });
            }
          }}
          cancelFunction={() => {
            setTargetDataForDelete({});
            setIsOpen({
              isOpen: true,
              hiddenFlag: false,
            });
          }}
        />
      )}
    </>
  );
};

export default observer(MyPrescriptionSaveModal);
