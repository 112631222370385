import * as AmplifyUI from '@aws-amplify/ui-react';
import styled from '@emotion/styled';

interface TableCellParams extends AmplifyUI.TableCellProps {
  children?: any;
  colSpan?: number;
}
const StyledTableCell = styled(AmplifyUI.TableCell)<TableCellParams>``;
export const TableCell = ({ colSpan, ...rest }: TableCellParams) => {
  return (
    <StyledTableCell colSpan={colSpan} {...rest}>
      {rest.children}
    </StyledTableCell>
  );
};
