/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { Loader } from '@aws-amplify/ui-react';
import message from 'antd-message';
import moment from 'moment';
import _ from 'lodash';
import { observer } from 'mobx-react';

import { Row, Col, TypographyText, ModalType2 } from 'components/UI/atoms';
import { convertPulseMeterResult } from 'utils/util';
import { DIAGNOSIS } from 'utils/SymptomCode';

const PulseMeterModal = ({
  setIsOpenPulseMeterModal,
  medicalInstitutionNumber,
  patientName,
  patientGender,
  patientBirth,
  addCheckedSymptom,
  deleteCheckedSymptom,
  setChecklist,
  checklist,
}) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const 맥진정보 = useMemo(() => DIAGNOSIS.find((d) => d.label === '맥진').children, []);

  const getPulseMeterDataList = useCallback(
    async ({ medicalInstitutionNumber, patientName, patientGender, patientBirth }) => {
      try {
        setIsLoading(true);
        const res = await API.post('yejinadminrestapi', '/getPulseMeterLog', {
          headers: {
            'Content-Type': 'application/json',
          },
          response: true,
          body: {
            medicalInstitutionNumber,
            patientName: 'Test', // todo test code 제거
            patientGender: 'MALE', // todo test code 제거
            patientBirth: '1980-01-01', // todo test code 제거
          },
        });
        setList(res?.data?.Items ?? []);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const buttonStyle = useMemo(
    () => ({
      borderRadius: '3px',
      paddingInline: '0.75rem',
      paddingBlock: '0.35rem',
      background: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  );

  useEffect(() => {
    getPulseMeterDataList({
      medicalInstitutionNumber,
      patientName,
      patientGender,
      patientBirth,
    });
  }, []);

  return (
    <>
      <ModalType2
        isOpen={true}
        width={50}
        title={`맥진 측정 기록`}
        content={
          <Row style={{ display: 'flex', flexDirection: 'column', position: 'relative', height: '100%' }}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Col style={{ display: 'flex', flexDirection: 'column', rowGap: '0.25rem' }}>
                {[
                  {
                    label: '맥의 세기',
                    desc: '0 ~ 3: 무력 / 4 ~ 7: 평균 / 8 ~ 11: 유력',
                  },
                  {
                    label: '맥의 깊이',
                    desc: '0 ~ 3: 침 / 4 ~ 7: 평균 / 8 ~ 11: 부',
                  },
                  {
                    label: '맥의 빠르기(bpm)',
                    desc: '60 미만: 지 / 60 ~ 80: 평균 / 80 초과: 삭',
                  },
                ].map((e) => {
                  return (
                    <Row style={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '1.25rem',
                          width: '6.9rem',
                          background: '#eeeff3',
                        }}
                      >
                        <TypographyText
                          style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.2em',
                            whiteSpace: 'pre',
                          }}
                        >
                          {e.label}
                        </TypographyText>
                      </Col>
                      <Col style={{ display: 'flex' }}>
                        <TypographyText
                          style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.2em',
                            whiteSpace: 'pre',
                          }}
                        >
                          {e.desc}
                        </TypographyText>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
              <Col>
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #afafaf',
                    borderRadius: '3px',
                    height: '2.1rem',
                    padding: '0.45rem 1rem',
                  }}
                  onClick={() => {
                    getPulseMeterDataList({
                      medicalInstitutionNumber,
                      patientName,
                      patientGender,
                      patientBirth,
                    });
                  }}
                >
                  <TypographyText style={{ fontWeight: 700, whiteSpace: 'pre' }}>새로고침</TypographyText>
                </button>
              </Col>
            </Row>
            <table className={`my-prescription-table mt-3`}>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '14%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '15%' }} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <TypographyText style={{ fontSize: '0.9rem', whiteSpace: 'pre' }}>측정일</TypographyText>
                  </th>
                  <th>
                    <TypographyText style={{ fontSize: '0.9rem', whiteSpace: 'pre' }}>이름</TypographyText>
                  </th>
                  <th>
                    <TypographyText style={{ fontSize: '0.9rem', whiteSpace: 'pre' }}>
                      생년월일
                    </TypographyText>
                  </th>
                  <th>
                    <TypographyText style={{ fontSize: '0.9rem', whiteSpace: 'pre' }}>성별</TypographyText>
                  </th>
                  <th>
                    <TypographyText style={{ fontSize: '0.9rem', whiteSpace: 'pre' }}>
                      맥의 세기
                    </TypographyText>
                  </th>
                  <th>
                    <TypographyText style={{ fontSize: '0.9rem', whiteSpace: 'pre' }}>
                      맥의 깊이
                    </TypographyText>
                  </th>
                  <th>
                    <TypographyText style={{ fontSize: '0.9rem', whiteSpace: 'pre' }}>
                      맥의 빠르기
                    </TypographyText>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!isLoading ? (
                  list?.length > 0 ? (
                    list.map((e, i) => {
                      const 맥진결과: {
                        유무력: {
                          code: string | undefined;
                          result: string;
                        };
                        부침: {
                          code: string | undefined;
                          result: string;
                        };
                        지삭: {
                          code: string | undefined;
                          result: string;
                        };
                      } = convertPulseMeterResult({
                        pf_pl: e.pf_pl as string,
                        ft_sk: e.ft_sk as string,
                        fs_sl: e.fs_sl as string,
                      });

                      return (
                        <>
                          <tr key={e.id}>
                            <td>
                              <TypographyText
                                style={{
                                  fontSize: '0.8rem',
                                  lineHeight: '1.2em',
                                  color: '#AFAFAF',
                                }}
                              >
                                {moment(e.date_time, 'YYYY-MM-DDTHH:mm:ss').format('YYYY/MM/DD HH:mm')}
                              </TypographyText>
                            </td>
                            <td>
                              <TypographyText
                                style={{
                                  fontSize: '0.9rem',
                                  lineHeight: '1.2em',
                                }}
                              >
                                {e.patient_name}
                              </TypographyText>
                            </td>
                            <td>
                              <TypographyText
                                style={{
                                  fontSize: '0.9rem',
                                  lineHeight: '1.2em',
                                }}
                              >
                                {e.birthday}
                              </TypographyText>
                            </td>
                            <td>
                              <TypographyText
                                style={{
                                  fontSize: '0.9rem',
                                  lineHeight: '1.2em',
                                }}
                              >
                                {e.gender_id === '1' ? '남성' : '여성'}
                              </TypographyText>
                            </td>
                            <td>
                              <TypographyText
                                style={{
                                  fontSize: '0.9rem',
                                  lineHeight: '1.2em',
                                }}
                              >
                                {`${맥진결과.유무력.result}(${e.pf_pl})`}
                              </TypographyText>
                            </td>
                            <td>
                              <TypographyText
                                style={{
                                  fontSize: '0.9rem',
                                  lineHeight: '1.2em',
                                }}
                              >
                                {`${맥진결과.부침.result}(${e.ft_sk})`}
                              </TypographyText>
                            </td>
                            <td>
                              <TypographyText
                                style={{
                                  fontSize: '0.9rem',
                                  lineHeight: '1.2em',
                                }}
                              >
                                {`${맥진결과.지삭.result}(${e.fs_sl})`}
                              </TypographyText>
                            </td>
                            <td>
                              <Row
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  columnGap: '0.3rem',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Col id={`${e.id}-btn`} style={{ position: 'relative' }}>
                                  <button
                                    style={{
                                      ...buttonStyle,
                                      border: '1px solid #346AFF',
                                      background: '#346AFF',
                                    }}
                                    onClick={async () => {
                                      const 맥진추가대상 = [];
                                      const 맥진제거대상 = [];
                                      const 측정된증상코드 = _.values(맥진결과)
                                        .map((m) => m.code)
                                        .filter((m) => m);
                                      맥진정보.forEach((m) => {
                                        if (측정된증상코드.includes(m.sympomCode)) {
                                          맥진추가대상.push(m.sympomCode);
                                        } else {
                                          맥진제거대상.push(m.sympomCode);
                                        }
                                      });
                                      맥진추가대상.forEach((m) => {
                                        addCheckedSymptom(m);
                                      });
                                      맥진제거대상.forEach((m) => {
                                        deleteCheckedSymptom(m);
                                      });
                                      setChecklist({
                                        ...checklist,
                                        ...맥진추가대상.reduce((acc, cur) => {
                                          acc[cur] = true;
                                          return acc;
                                        }, {}),
                                        ...맥진제거대상.reduce((acc, cur) => {
                                          acc[cur] = false;
                                          return acc;
                                        }, {}),
                                      });
                                      setIsOpenPulseMeterModal(false);
                                      message.success('맥진 측정 기록이 반영되었습니다.', 3000);
                                    }}
                                  >
                                    <TypographyText
                                      style={{
                                        fontSize: '0.8rem',
                                        fontWeight: 700,
                                        lineHeight: '1.375em',
                                        whiteSpace: 'pre',
                                        color: '#ffffff',
                                      }}
                                    >
                                      불러오기
                                    </TypographyText>
                                  </button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} style={{ textAlign: 'center' }}>
                        <TypographyText
                          style={{
                            fontSize: '0.9rem',
                            lineHeight: '1.2em',
                            textAlign: 'center',
                            color: '#afafaf',
                          }}
                        >
                          측정 기록이 없습니다.
                        </TypographyText>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr style={{ borderBottom: 'none', height: 0 }}></tr>
                )}
              </tbody>
            </table>
            {isLoading && (
              <Row
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  flex: '1 1 0',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Row style={{ display: 'flex' }}>
                  <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
                </Row>
              </Row>
            )}
          </Row>
        }
        contentHeight={'30rem'}
        cancelMsg="닫기"
        cancelFunction={() => setIsOpenPulseMeterModal(false)}
      />
    </>
  );
};

export default observer(PulseMeterModal);
