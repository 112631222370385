/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect, useRef } from 'react';
import message from 'antd-message';
import { v4 } from 'uuid';
import _, { throttle } from 'lodash';
import { observer } from 'mobx-react';

import { useStores } from 'stores/Context';
import { Row, Col, Input, TypographyText, ModalType2 } from 'components/UI/atoms';
import PR from 'repository/PrescriptionRepository';

const MyPrescriptionSaveModal = ({
  isOpen,
  setIsOpen,
  setRefreshFlag,
  userId,
  prescribedHerbs,
  selectedPrescriptionData: pd,
  isLoadingMyPrescriptionList,
  myPrescriptionList,
}) => {
  const { prescriptionStore: ps } = useStores();

  const prescriptionSet = useRef(new Set());
  const [name, setName] = useState('');
  const [duplicatedError, setDuplicatedError] = useState(false);
  const [isLoadingForSaving, setIsLoadingForSaving] = useState(false);

  const checkDuplicate = useCallback(
    (targetName) => prescriptionSet.current.has(targetName),
    [prescriptionSet.current],
  );

  const submit = useCallback(
    async ({ name, userId, prescribedHerbs, pd, isLoadingForSaving }) => {
      if (isLoadingForSaving) return;
      const isDuplicated = checkDuplicate(name);
      if (!isDuplicated) {
        setIsLoadingForSaving(true);
        const herbCodes = [];
        const processingCodes = [];
        const recipe = [];
        _.values(prescribedHerbs).forEach((v) => {
          const herbInfo = ps.yejinHerbForSearchByProcessingCode.get(v.code);
          herbCodes.push(herbInfo.herbCode);
          processingCodes.push(v.code);
          recipe.push({
            pc: v.code,
            amt: v.amount,
          });
        });
        await PR.mutationCustomHerbalFormula({
          id: v4().toString(),
          ownerUserID: userId,
          originalHerbalFormulaID: pd.id || null,
          insrCCode: pd.insrCCode || null,
          insrDsCode: pd.insrDsCode || null,
          InsrPrscrType: pd.InsrPrscrType || null,
          kn: name,
          herbCodes,
          processingCodes,
          recipe: _.sortBy(recipe, (e) => -e.amt),
          scoringType: pd.scoringType || 1,
          isDeleted: false,
        });
        setIsLoadingForSaving(false);
        setIsOpen(false);
        setRefreshFlag(true);
        message.success(`<${name}>이 나의 처방에 저장되었습니다.`, 3000);
      } else {
        setDuplicatedError(true);
      }
    },
    [checkDuplicate],
  );

  useEffect(() => {
    myPrescriptionList.forEach((e) => {
      prescriptionSet.current.add(e.kn);
    });
    // focus on input
    document.getElementById('myPrescriptionNameInput').focus();
  });

  return (
    <ModalType2
      isOpen={isOpen}
      width={34}
      title={`나의 처방 저장`}
      content={
        <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
          <Col style={{ position: 'relative' }}>
            <Input
              id="myPrescriptionNameInput"
              style={{
                height: '2.4rem',
                width: '100%',
                border: '1px solid #c4c4c4',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                lineHeight: '1.2em',
                fontSize: '0.8rem',
                fontWeight: '400',
              }}
              placeholder={'나의 처방 이름을 입력해주세요.(최대 20자)'}
              className="search-input"
              value={name}
              onChange={(e) => {
                setDuplicatedError(false);
                if (e.target.value.length <= 20) setName(e.target.value);
              }}
              onKeyUp={throttle((e) => {
                if (e.keyCode === 13) {
                  submit({ name, userId, prescribedHerbs, pd, isLoadingForSaving });
                }
              }, 300)}
            />
            {name?.length > 0 && (
              <Col
                style={{
                  position: 'absolute',
                  right: '0.5rem',
                  top: '50%',
                  translate: '0 -50%',
                  height: '0.9rem',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setName('');
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                  style={{ width: '0.9rem' }}
                  alt="serach_initialization_icon"
                />
              </Col>
            )}
          </Col>
          {duplicatedError && (
            <Row style={{ display: 'flex' }}>
              <TypographyText
                style={{
                  color: '#DF635C',
                  fontSize: '0.8rem',
                  lineHeight: '1em',
                  whiteSpace: 'pre',
                }}
              >
                {'이미 존재하는 이름입니다.'}
              </TypographyText>
            </Row>
          )}
        </Row>
      }
      okMsg="나의 처방 저장하기"
      okBtnDisabled={duplicatedError || name.length === 0}
      okBtnLoading={isLoadingForSaving}
      okFunction={() => submit({ name, userId, prescribedHerbs, pd, isLoadingForSaving })}
      cancelMsg="닫기"
      cancelFunction={() => setIsOpen(false)}
    />
  );
};

export default observer(MyPrescriptionSaveModal);
