import { map, isNaN, isNull } from 'lodash';

import { Span } from '../atoms';
import { BoldText } from 'utils/ComponentUtil';

/* eslint-disable @typescript-eslint/no-explicit-any */
type TGraphType1 = {
  mode?: 'reportPreview' | 'admin-treatment-analysis';
  leftKLabel: string;
  leftCLabel: string;
  rightKLabel: string;
  rightCLabel: string;
  leftValue: number;
  rightValue: number;
  isLoading?: boolean;
  bottomDidiverFlag?: boolean;
};

export function GraphType1({
  mode = 'reportPreview',
  leftKLabel,
  leftCLabel,
  rightKLabel,
  rightCLabel,
  leftValue = 0,
  rightValue = 0,
  isLoading = false,
  bottomDidiverFlag = false,
}: TGraphType1) {
  const notValidValueFlag =
    leftValue + rightValue === 0 ||
    typeof leftValue === 'undefined' ||
    typeof rightValue === 'undefined' ||
    isNaN(leftValue) ||
    isNaN(rightValue) ||
    isNull(leftValue) ||
    isNull(rightValue);

  let fontStyle = '';
  let valueFontSize = '';
  let leftLabelWidth = '';
  let rightLabelWidth = '';
  let leftValueWidth = '';
  let rightValueWidth = '';
  let contentHeight = '';
  let graphHeight = '';
  let balanceTextFontSize = '';

  switch (mode) {
    case 'reportPreview':
      fontStyle = 'text-0.75 leading-1.2';
      valueFontSize = 'text-0.6';
      contentHeight = 'h-7.2';
      graphHeight = 'h-2.8';
      leftLabelWidth = 'w-9';
      rightLabelWidth = 'w-9';
      leftValueWidth = 'w-7';
      rightValueWidth = 'w-7';
      balanceTextFontSize = 'text-0.7';

      break;
    case 'admin-treatment-analysis':
      fontStyle = 'text-0.9 leading-1.2';
      valueFontSize = 'text-0.8';
      contentHeight = 'h-10.4';
      graphHeight = 'h-4';
      leftLabelWidth = 'w-12';
      rightLabelWidth = 'w-12';
      leftValueWidth = 'w-7';
      rightValueWidth = 'w-7';
      balanceTextFontSize = 'text-0.75';
      break;
  }
  return (
    <div>
      <div
        className={`graph-type1-wrapper flex justify-between border-collapse border border-e5e5e5 border-l-0 border-r-0 ${
          bottomDidiverFlag && 'border-b-0'
        } `}
      >
        <div
          className={`flex justify-center ${contentHeight} gap-x-0.8 items-center bg-F5F5F5 ${leftLabelWidth} `}
        >
          <Span className={`${fontStyle} font-bold`}>{leftKLabel}</Span>
          <Span className={`${fontStyle}`}>{leftCLabel}</Span>
        </div>
        <div className={`flex flex-1 ${contentHeight}  `}>
          {!isLoading && notValidValueFlag ? (
            <Span
              className={`${fontStyle} text-afafaf`}
              divClassName={`w-full justify-center items-center`}
            >{`한의사의 진단이 필요합니다.`}</Span>
          ) : (
            <>
              <div className={`relative flex w-1/2 border-r border-r-e5e5e5  ${contentHeight} items-end`}>
                <div
                  className={`bg-white ${isLoading ? 'flex-1' : ''} `}
                  style={{ flex: !isLoading && 100 - leftValue, transition: 'all 1.5s ease' }}
                />
                <div
                  className={`relative bg-ED7169 ${graphHeight}  z-1`}
                  style={{ flex: !isLoading && leftValue, transition: 'all 1.5s ease' }}
                >
                  <div className={`absolute -top-100%+0.25 ${leftValue > 20 ? 'left-0' : 'right-0.4'} `}>
                    <Span
                      divClassName={`${isLoading && 'blur-sm'} ${leftValueWidth} ${
                        leftValue > 20 ? 'justify-start' : 'justify-end'
                      }`}
                      className={`${valueFontSize}`}
                    >{`${leftValue}%`}</Span>
                  </div>
                </div>
                <div className={`absolute bg-FBE840/15 left-2/5 w-1/5 ${contentHeight}   `} />
                {!bottomDidiverFlag && (
                  <button
                    className={`absolute text-F2A549 font-bold whitespace-nowrap ${balanceTextFontSize} leading-0.95rem cursor-default flex  justify-center items-center left-1/2 -translate-x-1/2 -bottom-4.8 flex-shrink-0 w-7 h-3.8 `}
                  >
                    균형
                  </button>
                )}
              </div>
              <div className={`relative flex w-1/2  ${contentHeight} items-end`}>
                <div
                  className={`relative bg-52B4D2  ${graphHeight} z-1 `}
                  style={{ flex: !isLoading && rightValue, transition: 'all 1.5s ease' }}
                >
                  <div className={`absolute -top-100%+0.25 ${rightValue > 20 ? 'right-0' : 'left-0.4'} `}>
                    <Span
                      divClassName={`${isLoading && 'blur-sm'} ${rightValueWidth} ${
                        rightValue > 20 ? 'justify-end' : 'justify-start'
                      }`}
                      className={`${valueFontSize}`}
                    >{`${rightValue}%`}</Span>
                  </div>
                </div>
                <div
                  className={`bg-white  ${isLoading ? 'flex-1' : ''}`}
                  style={{ flex: !isLoading && 100 - rightValue, transition: 'all 1.5s ease' }}
                />
                <div className={`absolute bg-FBE840/15 right-2/5 w-1/5 ${contentHeight}  `} />
                {!bottomDidiverFlag && (
                  <button
                    className={`absolute text-F2A549 font-bold whitespace-nowrap ${balanceTextFontSize} leading-0.95rem cursor-default flex justify-center items-center left-1/2 -translate-x-1/2 -bottom-4.8 flex-shrink-0 w-7 h-3.8 `}
                  >
                    균형
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        <div
          className={`flex justify-center ${contentHeight} gap-x-0.8 items-center bg-F5F5F5 ${rightLabelWidth} `}
        >
          <Span className={`${fontStyle} font-bold`}>{rightKLabel}</Span>
          <Span className={`${fontStyle}`}>{rightCLabel}</Span>
        </div>
      </div>
    </div>
  );
}

type TGraphType2 = {
  data: {
    code: string;
    weight: number;
  }[];
  isLoading?: boolean;
};

export function GraphType2({ data = [], isLoading = false }: TGraphType2) {
  const parsedBData = map(data, (d: any) => ({ ...d }));
  return (
    <div className="flex flex-col ">
      {map(parsedBData, (d, i) => (
        <div key={`b-${i}`} className={`border border-e5e5e5 ${i !== 0 && 'mt-1.6'}`}>
          <Span
            divClassName={`${isLoading && 'blur-sm'} pt-2 pb-2 pl-3 pr-3 bg-F9F9F9 border-b border-e5e5e5`}
            className="text-0.75 font-bold leading-1.2"
          >{`${i + 1}. ${d.koreanName} ${d.chineseName}`}</Span>
          <Span
            className="text-0.75 leading-1.2 tracking-m1 p-3 whitespace-pre-wrap break-keep "
            divClassName={`${isLoading && 'blur-sm'}`}
          >
            {BoldText({
              text: d.koreanDesc,
              boldText: '주로 나타나는 증상',
            }).map((e, index) =>
              index > 0 && typeof e !== 'string' ? (
                <>
                  {`\n`}
                  {e}
                </>
              ) : (
                e
              ),
            )}
          </Span>
        </div>
      ))}
    </div>
  );
}
