/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores/Context';
import _ from 'lodash';
import message from 'antd-message';
import { API, Auth } from 'aws-amplify';

import { Button } from '../../atoms/inputs/Button';
import { permissionConverter } from 'utils/DataConvertUtil';
import { Row, TypographyText, Modal } from 'components/UI/atoms';
import { Loading } from 'components/UI/molecules';
import { InviteModal } from './InviteModal';
import { checkBatchimEnding } from 'utils/util';

const MemberManagement = () => {
  const { clinicStore, clientStore } = useStores();
  const [isOpenDeleteStepModal, setIsOpenDeleteStepModal] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(true);
  const [isOpenInviteModal, setIsOpenInviteModal] = useState<boolean>(false);

  const refeshData = useCallback(async () => {
    try {
      await setIsGlobalLoading(true);
      await clinicStore.getClinic(clientStore.metaData?.userRole.clinicID);
      await getRoleByClinic();
    } catch (e) {
      console.log(e);
    } finally {
      setIsGlobalLoading(false);
    }
  }, []);

  const getRoleByClinic = useCallback(async () => {
    await clinicStore.listRolesByClinic();
    // console.log(JSON.parse(JSON.stringify(clinicStore.roles)));
  }, []);

  useEffect(() => {
    refeshData();
  }, []);

  return (
    <>
      {isGlobalLoading ? (
        <Loading />
      ) : (
        <>
          {isLoading && <Loading mode={'global'} />}
          {isOpenInviteModal && <InviteModal setIsOpenInviteModal={setIsOpenInviteModal} />}
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '0.75rem',
              paddingBottom: '1rem',
              rowGap: '0.75rem',
            }}
          >
            <Row
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <button
                onClick={() => setIsOpenInviteModal(true)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1rem 2rem',
                  borderRadius: '3px',
                  border: '1px solid #346AFF',
                  background: '#346AFF',
                }}
              >
                <TypographyText color="#ffffff" fontSize="1rem" fontWeight={700} lineHeight="1.2em">
                  초대하기
                </TypographyText>
              </button>
            </Row>
            <table className={`list-page-common-table role-list-table`}>
              <thead>
                <tr>
                  <th>
                    <span>구성원</span>
                  </th>
                  <th>
                    <span>이메일</span>
                  </th>
                  <th>
                    <span>전화번호</span>
                  </th>
                  <th>
                    <span>역할</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {clinicStore.roles?.length > 0 &&
                  clinicStore.roles?.map((role, i) => (
                    <tr key={role.id}>
                      <td>
                        <span>{role.user?.username}</span>
                      </td>
                      <td>
                        <span>{role.user?.email}</span>
                      </td>
                      <td>
                        <span>
                          {role?.user?.phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) ?? '-'}
                        </span>
                      </td>
                      <td>
                        <span>{permissionConverter(role.roleName)}</span>
                      </td>
                      <td>
                        {/* 삭제 조건
                        - 자기 자신은 삭제 불가능
                        - 마스터는 삭제 불가능
                        - 마스터만 삭제 권한을 가짐
                        */}
                        {role.roleName !== 'MASTER' &&
                          clientStore.metaData?.userRole?.roleName === 'MASTER' &&
                          clientStore.metaData?.userRole.userID !== role?.userID && (
                            <Row
                              style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setIsOpenDeleteStepModal(role);
                                }}
                                style={{
                                  backgroundColor: '#ffffff',
                                  border: '1px solid #afafaf',
                                  padding: '0.45rem 1.8rem',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <TypographyText
                                  style={{
                                    fontSize: '0.8rem',
                                    lineHeight: '1.375em',
                                    fontWeight: 700,
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  삭제
                                </TypographyText>
                              </Button>
                            </Row>
                          )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Row>
          {!_.isEmpty(isOpenDeleteStepModal) && (
            <Modal
              contentMsg={`${isOpenDeleteStepModal?.userName ?? ``} ${permissionConverter(
                isOpenDeleteStepModal?.roleName,
              )}${
                checkBatchimEnding(permissionConverter(isOpenDeleteStepModal?.roleName)) ? '을' : '를'
              } 구성원에서 삭제하겠습니까?`}
              cancelMsg="취소"
              okMsg="네, 삭제하겠습니다."
              okFunction={async () => {
                try {
                  const userRoleId = isOpenDeleteStepModal?.id;
                  const userName = isOpenDeleteStepModal?.userName;
                  await setIsOpenDeleteStepModal({});
                  await setIsLoading(true);
                  await API.del('yejinadminrestapi', `/step/${userRoleId}`, {
                    headers: {
                      authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
                    },
                  });
                  await getRoleByClinic();
                  message.success(`${userName ?? ``} 스탭이 삭제되었습니다.`);
                } catch (e) {
                  message.error(`스탭 삭제 과정에 문제가 발생했습니다.`);
                  console.log(e);
                } finally {
                  await setIsLoading(false);
                }
              }}
              cancelFunction={() => setIsOpenDeleteStepModal({})}
            />
          )}
        </>
      )}
    </>
  );
};

export default observer(MemberManagement);
