/* eslint-disable react-hooks/exhaustive-deps */
import { API, Auth } from 'aws-amplify';
import PhoneInput from 'react-phone-number-input/input';
import message from 'antd-message';

import LandingLayout from 'components/templates/LandingLayout';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores/Context';
import { Loading } from 'components/UI/molecules';
import { TypographyText, Row, Col, Input, ModalType2 } from 'components/UI/atoms';
import DaumPost from 'components/UI/molecules/DaumPost';
import { emailValidationCheck, allCasePhoneValidationCheck, phoneValidationCheck } from 'utils/util';
import ur from 'repository/UserRepository';

const CreateClinicPage = () => {
  const { clientStore } = useStores();
  const [phone, setPhone] = useState<string>(clientStore.metaData?.user?.phone ?? '');
  const [clinicName, setClinicName] = useState('');
  const [clinicPhone, setClinicPhone] = useState('');
  const [clinicEmail, setClinicEmail] = useState('');
  const [businessType, setBusinessType] = useState<'BUSINESS' | 'PERSONAL'>('BUSINESS');
  const [clinicAddress, setClinicAddress] = useState(''); // 한의원 주소
  const [clinicDetailAddress, setClinicDetailAddress] = useState(''); // 한의원 상세 주소

  const [isOpenAddressModal, setIsOpenAddressModal] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!['DOCTOR', 'MASTER'].includes(clientStore.metaData.user.roleName)) {
      // 비정상 접근 케이스
      navigate('/signin');
    }
  }, []);

  // error
  const [errors, setErrors] = useState<{
    phoneValidation: boolean;
    clinicPhoneValidation: boolean;
    clinicEmailValidation: boolean;
  }>({
    phoneValidation: false,
    clinicPhoneValidation: false,
    clinicEmailValidation: false,
  });

  const confirmFlag = useMemo(() => {
    if (businessType === 'BUSINESS') {
      return (
        !!phone &&
        !!clinicName &&
        !!clinicEmail &&
        !!clinicEmail &&
        !errors.phoneValidation &&
        !errors.clinicPhoneValidation &&
        !errors.clinicEmailValidation
      );
    } else {
      return !!phone && !errors.phoneValidation;
    }
  }, [businessType, clinicName, clinicPhone, clinicEmail, phone]);

  const createClinic = useCallback(
    async ({
      name,
      phone,
      email,
      clinicName,
      clinicPhone,
      clinicEmail,
      businessType,
      clinicAddress,
      clinicDetailAddress,
    }: {
      name: string;
      phone: string;
      email: string;
      clinicName?: string;
      clinicPhone?: string;
      clinicEmail?: string;
      businessType: string;
      clinicAddress?: string;
      clinicDetailAddress?: string;
    }) => {
      try {
        await setIsLoading(true);
        const body = {
          businessType,
        };
        if (businessType === 'BUSINESS') {
          if (!clinicName && !clinicPhone && !clinicEmail) {
            await setIsLoading(false);
            message.error(`의료기관 정보를 입력해주세요.`, 2500);
            return;
          }
          body['clinicName'] = clinicName;
          body['clinicPhone'] = clinicPhone?.replace('+82', '0') ?? clinicPhone;
          body['clinicEmail'] = clinicEmail;
          if (clinicAddress) {
            body['clinicAddress'] = `${clinicAddress}${
              clinicDetailAddress ? `||${clinicDetailAddress}` : ''
            }`;
          }
        } else {
          body['clinicName'] = name;
          body['clinicPhone'] = phone;
          body['clinicEmail'] = email;
        }
        await API.post('yejinadminrestapi', '/clinic', {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
          },
          response: true,
          body,
        });
        if (!clientStore.metaData?.user?.phone) {
          await ur.updateUser({
            id: clientStore.metaData?.user?.id,
            phone,
          });
        }
        await clientStore.setMetaData(sessionStorage.getItem('sub'));
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
            console.log('session', err, session);
          });
        } catch (e) {
          console.log('Unable to refresh Token', e);
        }
        await setIsLoading(false);
        message.success(`${clientStore.metaData?.user.username} 한의사님, 환영합니다. 😄`, 8000);
        navigate('/patient', {
          state: {
            noticeFlag: true,
          },
        });
      } catch (e) {
        await setIsLoading(false);
        message.error(`한의원 생성 과정에서 문제가 발생했습니다.`, 2500);
      }
    },
    [],
  );

  let content = (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '3rem',
        paddingBottom: '1.5rem',
      }}
    >
      {isLoading && <Loading mode="global" />}
      <Row style={{ display: 'flex', flexDirection: 'column' }}>
        <Col style={{ display: 'flex' }}>
          <TypographyText fontSize="1.6rem" lineHeight="1.3125em" whiteSpace="pre-wrap">
            {`가입 유형 선택 후
예진 서비스를 시작해주세요.`}
          </TypographyText>
        </Col>
        <Row
          style={{
            marginTop: '2rem',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.75rem',
          }}
        >
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize={`1rem`} fontWeight={700}>
              {`가입 유형 선택`}
            </TypographyText>
          </Row>
          <Row
            style={{
              display: 'flex',
              columnGap: '0.6rem',
            }}
          >
            <Col
              style={{
                padding: '1.4rem 1rem',
                minWidth: '14.3rem',
                display: 'flex',
                height: '4.5rem',
                flex: 1,
                border: businessType === 'BUSINESS' ? '1px solid #346AFF' : '1px solid #c4c4c4',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setBusinessType('BUSINESS')}
            >
              <Row
                style={{
                  display: 'flex',
                  textAlign: 'center',
                }}
              >
                <TypographyText
                  color={businessType === 'BUSINESS' ? '#346AFF' : '#c4c4c4'}
                  lineHeight={`1.7rem`}
                  fontSize={`1.2rem`}
                  whiteSpace="pre-wrap"
                  wordBreak="keep-all"
                >
                  {`사업자(한의원 개설자)`}
                </TypographyText>
              </Row>
            </Col>
            <Col
              style={{
                padding: '1.4rem 1rem',
                display: 'flex',
                minWidth: '14.3rem',
                height: '4.5rem',
                flex: 1,
                border: businessType === 'PERSONAL' ? '1px solid #346AFF' : '1px solid #c4c4c4',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setBusinessType('PERSONAL')}
            >
              <Row
                style={{
                  display: 'flex',
                  textAlign: 'center',
                }}
              >
                <TypographyText
                  color={businessType === 'PERSONAL' ? '#346AFF' : '#c4c4c4'}
                  lineHeight={`1.7rem`}
                  fontSize={`1.2rem`}
                  whiteSpace="pre-wrap"
                  wordBreak="keep-all"
                >
                  {'개인(일반 한의사)'}
                </TypographyText>
              </Row>
            </Col>
          </Row>
        </Row>
        <Row style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
          <Row style={{ display: 'flex' }}>
            <TypographyText fontSize="1rem" lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>
              {'개인 정보'}
            </TypographyText>
          </Row>
          <Row style={{ marginTop: '0.25rem', display: 'flex', alignItems: 'center' }}>
            <Col style={{ display: 'flex', width: '6.25rem' }}>
              <TypographyText lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>
                {'이메일'}
              </TypographyText>
            </Col>
            <Col style={{ display: 'flex', flex: 1 }}>
              <TypographyText lineHeight="1.2em" whiteSpace="pre">
                {clientStore.metaData?.user?.email}
              </TypographyText>
            </Col>
          </Row>
          <Row style={{ marginTop: '0.25rem', display: 'flex', alignItems: 'center' }}>
            <Col style={{ display: 'flex', width: '6.25rem' }}>
              <TypographyText lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>
                {'이름'}
              </TypographyText>
            </Col>
            <Col style={{ display: 'flex', flex: 1 }}>
              <TypographyText lineHeight="1.2em" whiteSpace="pre">
                {clientStore.metaData?.user?.username}
              </TypographyText>
            </Col>
          </Row>
          {clientStore.metaData?.user?.licenseNumber && (
            <Row style={{ marginTop: '0.25rem', display: 'flex', alignItems: 'center' }}>
              <Col style={{ display: 'flex', width: '6.25rem' }}>
                <TypographyText lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>
                  {'면허번호'}
                </TypographyText>
              </Col>
              <Col style={{ display: 'flex', flex: 1 }}>
                <TypographyText lineHeight="1.2em" whiteSpace="pre">
                  {clientStore.metaData?.user?.licenseNumber}
                </TypographyText>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: '0.25rem', display: 'flex', alignItems: 'center' }}>
            <Col style={{ display: 'flex', width: '6.25rem' }}>
              <TypographyText lineHeight="1.2em" whiteSpace="pre" fontWeight={700}>
                {'전화번호'}
              </TypographyText>
            </Col>
            <Col style={{ position: 'relative', display: 'flex', flex: 1 }}>
              {clientStore.metaData?.user?.phone ? (
                <TypographyText lineHeight="1.2em" whiteSpace="pre">
                  {phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) ?? '-'}
                </TypographyText>
              ) : (
                <Col style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <PhoneInput
                    style={{
                      height: '3.2rem',
                      width: '100%',
                      border: '1px solid #afafaf',
                      borderRadius: '3px',
                      padding: '0.35rem 0.6rem 0.35rem 0.6rem',
                      textIndent: '0.4rem',
                      fontSize: '1rem',
                      outline: 'none',
                    }}
                    country={'KR'}
                    value={phone}
                    placeholder="전화번호"
                    onChange={(e) => {
                      if (phoneValidationCheck(phone?.replace('+82', '0'))) {
                        setErrors({
                          ...errors,
                          phoneValidation: false,
                        });
                      } else {
                        setErrors({
                          ...errors,
                          phoneValidation: true,
                        });
                      }
                      setPhone(e);
                    }}
                    autoComplete="off"
                  />
                  {phone?.length > 0 && errors.phoneValidation && (
                    <Row
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        bottom: '0',
                        transform: 'translateY(100%)',
                      }}
                    >
                      <TypographyText yjTypeTypographyText="survey-validation">
                        {'전화번호 형식이 맞는지 확인해주세요.'}
                      </TypographyText>
                    </Row>
                  )}
                </Col>
              )}
            </Col>
          </Row>
        </Row>
        {businessType === 'BUSINESS' && (
          <Row style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
            <Row style={{ display: 'flex' }}>
              <TypographyText fontSize={`1rem`} fontWeight={700}>
                {`의료기관 정보`}
              </TypographyText>
            </Row>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '0.6rem',
              }}
            >
              <Input
                style={{
                  height: '3.2rem',
                  border: '1px solid #afafaf',
                  borderRadius: '3px',
                  textIndent: '0.4rem',
                  fontSize: '1rem',
                  outline: 'none',
                }}
                value={clinicName}
                placeholder="의료기관 이름"
                onChange={(e) => setClinicName(e.target.value)}
                autoComplete="off"
              />
              <PhoneInput
                style={{
                  height: '3.2rem',
                  padding: '0.35rem 0.6rem 0.35rem 0.6rem',
                  border: '1px solid #afafaf',
                  borderRadius: '3px',
                  textIndent: '0.4rem',
                  fontSize: '1rem',
                  outline: 'none',
                }}
                country={'KR'}
                value={clinicPhone}
                placeholder="의료기관 전화번호"
                onChange={(e: string) => {
                  if (allCasePhoneValidationCheck(clinicPhone?.replace('+82', '0'))) {
                    setErrors({
                      ...errors,
                      clinicPhoneValidation: false,
                    });
                  } else {
                    setErrors({
                      ...errors,
                      clinicPhoneValidation: true,
                    });
                  }
                  setClinicPhone(e);
                }}
                autoComplete="off"
              />
              {!!clinicPhone?.length && errors.clinicPhoneValidation && (
                <Row flex style={{ marginTop: '-0.2rem' }}>
                  <TypographyText yjTypeTypographyText="survey-validation">
                    {'전화번호 형식이 맞는지 확인해주세요.'}
                  </TypographyText>
                </Row>
              )}
              <Input
                style={{
                  height: '3.2rem',
                  border: '1px solid #afafaf',
                  borderRadius: '3px',
                  textIndent: '0.4rem',
                  fontSize: '1rem',
                  outline: 'none',
                }}
                value={clinicEmail}
                placeholder="의료기관 이메일"
                onChange={(e) => {
                  if (emailValidationCheck(clinicEmail)) {
                    setErrors({
                      ...errors,
                      clinicEmailValidation: false,
                    });
                  } else {
                    setErrors({
                      ...errors,
                      clinicEmailValidation: true,
                    });
                  }
                  setClinicEmail(e.target.value);
                }}
                autoComplete="off"
              />
              {!!clinicEmail?.length && errors.clinicEmailValidation && (
                <Row flex style={{ marginTop: '-0.2rem' }}>
                  <TypographyText yjTypeTypographyText="survey-validation">
                    {'이메일 형식이 맞는지 확인해주세요.'}
                  </TypographyText>
                </Row>
              )}
              <Row
                style={{
                  display: 'flex',
                  width: '100%',
                  maxWidth: '29.2rem',
                }}
              >
                <TypographyText
                  color={'#afafaf'}
                  lineHeight={`1.2em`}
                  fontSize={`0.8rem`}
                  whiteSpace="pre-wrap"
                  wordBreak="keep-all"
                >
                  {`세금계산서 발행용으로, 별도의 한의원 이메일이 없다면 개인 이메일(아이디)과 동일해도 무방합니다.`}
                </TypographyText>
              </Row>
            </Row>
            <Row style={{ marginTop: '0.25rem', display: 'flex', flexDirection: 'column', rowGap: '0.6rem' }}>
              <Row flex alignItems="center">
                <Col
                  style={{
                    flex: '1 1 0',
                    display: 'flex',
                    alignItems: 'center',
                    height: '3.2rem',
                    border: '1px solid #afafaf',
                    background: 'rgba(175, 175, 175, 0.1)',
                    paddingInline: '0.6rem',
                    borderRadius: '3px',
                    marginRight: '0.5rem',
                  }}
                >
                  <TypographyText color={`${clinicAddress ? '#1d1c1d' : '#AFAFAF'}`} lineHeight="1.2em">
                    {clinicAddress || '의료기관 주소(선택)'}
                  </TypographyText>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    columnGap: '0.25rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '3.2rem',
                    paddingInline: '1.25rem',
                    border: '1px solid #afafaf',
                    borderColor: '#afafaf',
                    borderRadius: '3px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setIsOpenAddressModal(true);
                  }}
                >
                  <Col flexFlag>
                    <TypographyText
                      yjTypeTypographyText="sub-title"
                      fontWeight={700}
                      letterSpacing="0.02rem"
                      fontSize={'1rem'}
                      lineHeight="1.2em"
                    >
                      {clinicAddress ? '주소 재검색' : '주소 찾기'}
                    </TypographyText>
                  </Col>
                  <Col flexFlag>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/search-black-icon.webp`}
                      style={{ width: '1rem' }}
                      alt="search-black-icon"
                    />
                  </Col>
                </Col>
              </Row>
              <Input
                disabled={!clinicAddress}
                style={{
                  height: '3.2rem',
                  paddingInline: '0.6rem',
                  border: '1px solid #afafaf',
                  borderRadius: '3px',
                  fontSize: '1rem',
                  lineHeight: '1em',
                  outline: 'none',
                }}
                value={clinicDetailAddress}
                placeholder="상세 주소"
                onChange={(e) => {
                  setClinicDetailAddress(e.target.value);
                }}
                autoComplete="off"
              />
            </Row>
          </Row>
        )}
        <Row style={{ marginBlock: '2rem' }}>
          <button
            style={{
              width: '100%',
              background: confirmFlag ? '#346AFF' : '#C4C4C4',
              border: confirmFlag ? '1px solid #346AFF' : '1px solid #C4C4C4',
              borderRadius: '3px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '3.6rem',
            }}
            disabled={!confirmFlag}
            onClick={async () => {
              createClinic({
                name: clientStore.metaData?.user?.username ?? '',
                phone: phone?.replace('+82', '0') ?? phone ?? '',
                email: clientStore.metaData?.user?.email ?? '',
                clinicName,
                clinicPhone: clinicPhone?.replace('+82', '0') ?? clinicPhone,
                clinicEmail,
                businessType,
                clinicAddress,
                clinicDetailAddress,
              });
            }}
          >
            <TypographyText color="#FFFFFF" fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2em">
              {'한의원 생성하기'}
            </TypographyText>
          </button>
        </Row>
      </Row>
      {isOpenAddressModal && (
        <ModalType2
          isOpen={isOpenAddressModal}
          cancelMsg="닫기"
          cancelFunction={() => setIsOpenAddressModal(false)}
          content={
            <Row style={{ marginBottom: '0' }}>
              <DaumPost setIsOpenAddressModal={setIsOpenAddressModal} setAddress={setClinicAddress} />
            </Row>
          }
          contentNonePaddingFlag={true}
          width={'600px'}
          contentHeight={'445px'}
        />
      )}
    </Row>
  );
  return <LandingLayout content={content} />;
};

export default CreateClinicPage;
