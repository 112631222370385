/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useMemo } from 'react';
import { API, Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { Col, Row, TypographyText, Input, ModalType2 } from 'components/UI/atoms';
import { css } from '@emotion/css';
import { allCasePhoneValidationCheck, emailValidationCheck } from 'utils/util';
import _ from 'lodash';

import { Loading } from 'components/UI/molecules';
import PhoneInput from 'react-phone-number-input/input';
import message from 'antd-message';
import { observer } from 'mobx-react';
import { useStores } from 'stores/Context';
import us from 'repository/UserRepository';
import cr from 'repository/ClinicRepository';
import DaumPost from 'components/UI/molecules/DaumPost';

import { LicenseRegistrationModal } from './LicenseRegistrationModal';

const BasicSetting: React.FC<{}> = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pulseMeterParam = searchParams.get('pulseMeter');

  const { clinicStore, clientStore, roleStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);

  // 개인 정보
  const [name, setName] = useState(''); // 이름
  const [email, setEmail] = useState(''); // 이메일
  const [phone, setPhone] = useState(''); // 전화번호
  const [licenseNumber, setLicenseNumber] = useState(''); // 면허번호

  // 한의원 정보
  const [clinicName, setClinicName] = useState(''); // 한의원 이름
  const [clinicPhone, setClinicPhone] = useState(''); // 한의원 전화번호 겸 개인 한의원에서는 개인 한의사 번호로 사용
  const [clinicEmail, setClinicEmail] = useState(''); // 한의원 이메일
  const [clinicFax, setClinicFax] = useState(''); // 한의원 팩스
  const [medicalInstitutionNumber, setMedicalInstitutionNumber] = useState(''); // 요양기관 번호
  const [clinicAddress, setClinicAddress] = useState(''); // 한의원 주소
  const [clinicDetailAddress, setClinicDetailAddress] = useState(''); // 한의원 상세 주소

  // 기타
  const [isJoinInsuranceBusiness, setIsJoinInsuranceBusiness] = useState(0); // 첩약건보 사업 참여 여부
  const [isPulseMeterUsed, setIsPulseMeterUsed] = useState(0); // 대요메디 맥진기 사용 여부
  const [prescriptionValidityPeriod, setPrescriptionValidityPeriod] = useState(7); // 처방전 사용기간
  const [feedbackDate, setFeedbackDate] = useState(20); // 피드백 발송일
  const [showFeedbackPreview, setShowFeedbackPreview] = useState(false); // 피드백 미리보기
  const [isOpenLicenseRegistationModal, setIsOpenLicenseRegistrationModal] = useState(false); // 면허등록 모달 노출 여부
  const [isOpenRequireMedicalInstitutionNumberModal, setIsOpenRequireMedicalInstitutionNumberModal] =
    useState(false); // 요양기관기호 모달 노출 여부
  const [requireMedicalInstitutionNumber, setRequireMedicalInstitutionNumber] = useState(''); // 요양기관기호 모달 내 입력값

  const [validationFlag, setValidationFlag] = useState({
    phone: false,
    clinicName: false,
    clinicEmail: false,
    clinicPhone: false,
  });

  const [userValidationFlag, setUserValidationFlag] = useState({
    phone: false,
  });

  const doctorFlag = useMemo(
    () => ['MASTER', 'DOCTOR'].includes(clinicStore.currentUserRole),
    [clinicStore.currentUserRole],
  );

  const businessFlag = useMemo(
    () => !clinicStore.clinic?.businessType || clinicStore.clinic?.businessType === 'BUSINESS',
    [clinicStore.clinic?.businessType],
  );

  const licensePendingReviewFlag = useMemo(
    () => clientStore.user?.licensePendingReviewFlag,
    [clientStore.user?.licensePendingReviewFlag],
  );

  const inputStyle = useMemo(
    () => ({
      height: '2.1rem',
      minWidth: '17rem',
      width: '100%',
      paddingInline: '0.6rem',
      border: '1px solid #afafaf',
      borderRadius: '3px',
      fontSize: '1rem',
      lineHeight: '1em',
      outline: 'none',
    }),
    [],
  );
  const buttonStyle = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '5.7rem',
      height: '2.2rem',
      cursor: 'pointer',
      borderRadius: '3px',
    }),
    [],
  );

  const confirmFlag = useMemo(() => {
    // 입력 필드의 유효성 검사 통과 여부
    return _.values(validationFlag).every((e) => e === false);
  }, [validationFlag]);

  const userConfirmFlag = useMemo(() => {
    // 입력 필드의 유효성 검사 통과 여부
    return _.values(userValidationFlag).every((e) => e === false);
  }, [userValidationFlag]);

  const setData = useCallback(async () => {
    // 데이터 세팅을 하기 위함
    setIsLoading(true); // 로딩 시작
    await Promise.all([
      // 한의원 정보, 한의사 정보 가져오기
      clinicStore.getClinic(clientStore.metaData?.userRole.clinicID!, false),
      clientStore.getUser(clientStore.metaData?.user.id),
    ])
      .then(() => {
        const clinicConfig = JSON.parse(clinicStore.clinic?.config ?? '{}');
        // 한의원 정보, 한의사 정보 가져오기 성공, 데이터 세팅
        setClinicName(clinicStore.clinic?.clinicName); // 한의원 이름
        setClinicEmail(clinicStore.clinic?.clinicEmail); // 한의원 이메일
        setClinicFax(clinicStore.clinic?.fax ?? ''); // 한의원 팩스
        setMedicalInstitutionNumber(clinicStore.clinic?.medicalInstitutionNumber ?? ''); // 요양기관 번호
        const address = clinicStore.clinic?.address?.split('||');
        setClinicAddress(address?.[0] ?? ''); // 한의원 주소
        setClinicDetailAddress(address?.[1] ?? ''); // 한의원 상세 주소
        setClinicPhone(clinicStore.clinic?.phone?.replace('0', '+82')); // 한의원 전화번호 겸 개인 한의원에서는 개인 한의사 번호로 사용
        setPrescriptionValidityPeriod(clinicStore.clinic?.prescriptionValidityPeriod ?? 7); // 처방전 사용기간
        setFeedbackDate(clinicStore.clinic?.feedbackDate ?? 20); // 진료완료 후 피드백 발송일
        setName(clientStore.user?.username); // 한의사 이름
        setEmail(clientStore.user?.email); // 한의사 이메일
        setPhone(clientStore.user?.phone?.replace('0', '+82')); // 한의사 전화번호
        setIsJoinInsuranceBusiness(clinicConfig?.insuranceBusinessJoinFlag ? 1 : 0); // 첩약건보 사업 참여 여부
        setIsPulseMeterUsed(clinicConfig?.pulseMeterUseFlag ? 1 : 0); // 대요메디 맥진기 사용 여부
        setLicenseNumber(clientStore.user?.licenseNumber); // 한의사 면허번호
      })
      .catch((e) => {
        // 한의원 정보, 한의사 정보 가져오기 실패
        console.log(e);
      })
      .finally(() => {
        // 로딩 종료
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setData();
  }, []);

  const updateHandler = async () => {
    try {
      setIsGlobalLoading(true);
      await API.put('yejinadminrestapi', `/clinic`, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        response: true,
        body: {
          clinicID: clientStore.metaData?.userRole.clinicID!,
          doctorName: name,
          clinicEmail: clinicEmail,
          clinicName: clinicName,
          phone: clinicPhone?.replace('+82', '0') ?? clinicPhone,
          fax: clinicFax,
          medicalInstitutionNumber: medicalInstitutionNumber,
          address: `${clinicAddress}${clinicDetailAddress ? `||${clinicDetailAddress}` : ''}`,
        },
      });
      await roleStore.updateUserName(clientStore.metaData?.userRole.id!, name);
      await clientStore.setMetaData(sessionStorage.getItem('sub')).then(() => {
        message.success(`한의원 정보 수정이 완료되었습니다.`, 2500);
      });
      await setData();
    } catch (e) {
      if (e?.message?.includes('409')) {
        message.error(`다른 한의원에서 사용 중인 이메일입니다. 다른 이메일을 입력해 주세요.`, 2500);
      } else if (e?.message?.includes('512')) {
        message.error(
          `현재 서버 오류로 정보 수정이 불가합니다. 잠시 후 다시 시도해 주세요.
※ 문제가 해결되지 않는 경우 고객센터(070-7782-7672)로 문의바랍니다.`,
          8000,
        );
      } else {
        message.error(`한의원 정보 수정 과정에서 문제가 발생했습니다.`, 2500);
      }
      console.log(e);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const updateUserDataHandler = async ({
    phone,
    receiveSurveyDoneAlimtalk,
  }: {
    phone?: string;
    receiveSurveyDoneAlimtalk?: number;
  }) => {
    try {
      setIsGlobalLoading(true);
      const value = {};
      if (phone) {
        value['phone'] = phone?.replace('+82', '0') ?? phone;
      }
      if (receiveSurveyDoneAlimtalk !== undefined) {
        const config = JSON.parse(clientStore.user?.config ?? '{}');
        value['config'] = JSON.stringify({
          ...config,
          receiveSurveyDoneAlimtalk: receiveSurveyDoneAlimtalk ? true : false,
        });
      }
      await us.updateUser({
        id: clientStore.metaData?.user.id!,
        ...value,
      });
      await roleStore.updateUserName(clientStore.metaData?.userRole.id!, name);
      await clientStore.setMetaData(sessionStorage.getItem('sub')).then(() => {
        message.success(`정보 수정이 완료되었습니다.`, 2500);
      });
      await setData();
    } catch (e) {
      message.error(`정보 수정 과정에서 문제가 발생했습니다.`, 2500);
      console.log(e);
    } finally {
      setIsGlobalLoading(false);
    }
  };
  const updateClinicDataHandler = async ({
    insuranceBusinessJoinFlag,
    medicalInstitutionNumber,
    prescriptionValidityPeriod,
    feedbackDate,
    pulseMeterUseFlag,
    mode,
  }: {
    insuranceBusinessJoinFlag?: number;
    medicalInstitutionNumber?: number;
    prescriptionValidityPeriod?: number;
    feedbackDate?: number;
    pulseMeterUseFlag?: number;
    mode: '첩약건보 참여' | '처방전 사용기간' | '피드백 발송' | '맥진기 연동';
  }) => {
    try {
      setIsGlobalLoading(true);
      const value = {};
      if (insuranceBusinessJoinFlag !== undefined) {
        const config = JSON.parse(clinicStore.clinic?.config ?? '{}');
        value['config'] = JSON.stringify({
          ...config,
          insuranceBusinessJoinFlag: insuranceBusinessJoinFlag ? true : false,
        });
      }
      if (pulseMeterUseFlag !== undefined) {
        const config = JSON.parse(clinicStore.clinic?.config ?? '{}');
        value['config'] = JSON.stringify({
          ...config,
          pulseMeterUseFlag: pulseMeterUseFlag ? true : false,
        });
      }
      if (prescriptionValidityPeriod !== undefined && typeof prescriptionValidityPeriod === 'number') {
        value['prescriptionValidityPeriod'] = prescriptionValidityPeriod;
      }
      if (typeof feedbackDate === 'number') {
        value['feedbackDate'] = feedbackDate;
      }
      if (medicalInstitutionNumber) {
        value['medicalInstitutionNumber'] = String(medicalInstitutionNumber);
        setMedicalInstitutionNumber(String(medicalInstitutionNumber));
      }
      await cr.updateClinic({
        id: clinicStore.clinic.id!,
        ...value,
      });
      await setData();

      message.success(`${mode} 설정이 완료되었습니다.`, 2500);
    } catch (e) {
      message.error(`${mode} 설정에 문제가 발생했습니다.`, 2500);
      console.log(e);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  return (
    <>
      {isLoading && !isGlobalLoading ? (
        <Loading />
      ) : (
        <Row flex style={{ flex: 1 }}>
          {isGlobalLoading && <Loading mode="global" />}
          {isOpenLicenseRegistationModal && (
            // 면허등록 모달
            <LicenseRegistrationModal setIsOpenLicenseRegistrationModal={setIsOpenLicenseRegistrationModal} />
          )}
          <Row
            style={{
              flex: '1 1 0',
              display: 'flex',
              width: '100%',
              flexDirection: businessFlag ? 'column' : 'row',
            }}
          >
            <Row
              style={{
                position: 'relative',
                flex: businessFlag ? 500 : 1,
                paddingTop: '2rem',
              }}
            >
              <Row style={{ display: 'flex', marginBottom: '1.5rem' }}>
                <TypographyText fontSize="1.2rem" lineHeight="1.2em" fontWeight={700}>
                  {'개인 정보'}
                </TypographyText>
              </Row>
              <table className="basic-setting-table" style={{ marginBottom: businessFlag ? '1rem' : 0 }}>
                <colgroup>
                  <col style={{ width: '7.8rem', minWidth: '7.8rem' }} />
                  <col style={{ width: 'calc(100% - 7.8rem)' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <span>{`아이디(이메일)`}</span>
                    </td>
                    <td>
                      <span>{email}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{`이름`}</span>
                    </td>
                    <td>
                      <span>{name}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{'면허번호'}</span>
                    </td>
                    <td>
                      {licenseNumber ? (
                        <span>{licenseNumber}</span>
                      ) : licensePendingReviewFlag ? (
                        <TypographyText style={{ fontSize: '1rem', lineHeight: '1.2em', color: '#AFAFAF' }}>
                          등록대기 중
                        </TypographyText>
                      ) : (
                        <button
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #000000',
                            borderRadius: '3px',
                            background: '#e5e5e5',
                            padding: '0.4rem 1rem',
                            height: '2rem',
                          }}
                          onClick={() => setIsOpenLicenseRegistrationModal(true)}
                        >
                          <TypographyText style={{ fontSize: '1rem', lineHeight: '1em', color: '#1D1C1D' }}>
                            등록하기
                          </TypographyText>
                        </button>
                      )}
                    </td>
                  </tr>
                  {!doctorFlag && !licenseNumber && !licensePendingReviewFlag && (
                    <tr style={{ height: '1.7rem' }}>
                      <td></td>
                      <td style={{ height: '1.7rem', paddingTop: '0.2rem' }}>
                        <div className="flex items-start h-full">
                          <TypographyText
                            style={{
                              fontSize: '0.8rem',
                              lineHeight: '1.2em',
                              color: '#afafaf',
                            }}
                          >
                            {'면허 인증 시, 스탭에서 한의사로 권한이 변경되며 진료가 가능해집니다.'}
                          </TypographyText>
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <span>{`전화번호`}</span>
                    </td>
                    <td>
                      <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.25rem' }}>
                        <PhoneInput
                          style={inputStyle}
                          country={'KR'}
                          placeholder={'전화번호를 입력해 주세요.'}
                          value={phone}
                          onChange={(e) => {
                            if (allCasePhoneValidationCheck(e?.replace('+82', '0'))) {
                              setUserValidationFlag({
                                ...userValidationFlag,
                                phone: false,
                              });
                            } else {
                              setUserValidationFlag({
                                ...userValidationFlag,
                                phone: true,
                              });
                            }
                            setPhone(e);
                          }}
                        />
                      </Col>
                    </td>
                  </tr>
                  {userValidationFlag.phone === true && (
                    <tr style={{ height: 'fit-content' }}>
                      <td></td>
                      <td>
                        <Row flex>
                          <TypographyText
                            style={{
                              color: '#DF635C',
                              fontSize: '0.8rem',
                              lineHeight: '1em',
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'keep-all',
                              textIndent: '0.2rem',
                            }}
                          >
                            {phone?.length > 0
                              ? `전화번호 형식이 맞는지 확인해 주세요.`
                              : `전화번호를 입력해 주세요.`}
                          </TypographyText>
                        </Row>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td></td>
                    <td>
                      <Row style={{ height: '3rem', display: 'flex', alignItems: 'end' }}>
                        <Row
                          style={{
                            ...buttonStyle,
                            background: userConfirmFlag ? '#346AFF' : '#c4c4c4',
                          }}
                          onClick={() => {
                            if (userConfirmFlag) updateUserDataHandler({ phone });
                          }}
                        >
                          <TypographyText
                            color="#ffffff"
                            fontWeight={700}
                            fontSize="1rem"
                            lineHeight="1rem"
                          >{`저장하기`}</TypographyText>
                        </Row>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
            <Row
              style={{
                flex: businessFlag ? 376 : 1,
                paddingTop: businessFlag ? '1.25rem' : '2rem',
                paddingLeft: businessFlag ? 0 : '1.25rem',
                borderTop: businessFlag ? '1px solid #e5e5e5' : 'none',
                borderLeft: businessFlag ? 'none' : '1px solid #e5e5e5',
              }}
            >
              <Row style={{ display: 'flex', marginBottom: businessFlag ? '1.25rem' : '1.5rem' }}>
                <TypographyText fontSize="1.2rem" lineHeight="1.2em" fontWeight={700}>
                  {'기타'}
                </TypographyText>
              </Row>
              <table className="basic-setting-table" style={{ width: '100%' }}>
                <colgroup>
                  <col style={{ width: '7.8rem', minWidth: '7.8rem' }} />
                  <col style={{ width: 'calc(100% - 7.8rem)', minWidth: 'calc(100% - 7.8rem)' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <span>{`첩약건보 참여`}</span>
                    </td>
                    <td style={{ width: '100%' }}>
                      <Row
                        style={{
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '0.5rem',
                        }}
                      >
                        <div style={{ width: 'fit-content' }} className="relative">
                          <select
                            className="select-input"
                            style={{
                              outline: 'none',
                              width: '10rem',
                              height: '2.1rem',
                              display: 'flex',
                              alignItems: 'center',
                              paddingInline: '1rem',
                              border: '1px solid #afafaf',
                              borderRadius: '3px',
                              cursor: 'pointer',
                              zIndex: 0,
                            }}
                            value={isJoinInsuranceBusiness}
                            onChange={(e) => {
                              setIsJoinInsuranceBusiness(e.target.value === '1' ? 0 : 1);
                              updateClinicDataHandler({
                                insuranceBusinessJoinFlag: Number(e.target.value),
                                mode: '첩약건보 참여',
                              });
                            }}
                          >
                            <option value={1}>참여</option>
                            <option value={0}>참여 안함</option>
                          </select>
                          <img
                            className="absolute"
                            src={`${process.env.PUBLIC_URL}/img/select-arrow-icon.webp`}
                            style={{
                              top: '50%',
                              right: '1rem',
                              width: '0.6rem',
                              transform: 'translateY(-50%)',
                            }}
                            alt="select-arrow-icon"
                          />
                        </div>
                      </Row>
                    </td>
                  </tr>
                  {pulseMeterParam === '1' && (
                    <>
                      <tr>
                        <td>
                          <span>{`맥진기 연동`}</span>
                        </td>
                        <td style={{ width: '100%' }}>
                          <Row
                            style={{
                              position: 'relative',
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '0.5rem',
                            }}
                          >
                            <div style={{ width: 'fit-content' }} className="relative">
                              <select
                                className="select-input"
                                style={{
                                  outline: 'none',
                                  width: '10rem',
                                  height: '2.1rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                  paddingInline: '1rem',
                                  border: '1px solid #afafaf',
                                  borderRadius: '3px',
                                  cursor: 'pointer',
                                  zIndex: 0,
                                }}
                                value={isPulseMeterUsed}
                                onChange={(e) => {
                                  setIsPulseMeterUsed(e.target.value === '1' ? 0 : 1);
                                  // 전화번호가 없으면
                                  if (!clinicStore.clinic?.medicalInstitutionNumber) {
                                    setIsOpenRequireMedicalInstitutionNumberModal(true);
                                  } else {
                                    updateClinicDataHandler({
                                      pulseMeterUseFlag: Number(e.target.value),
                                      mode: '맥진기 연동',
                                    });
                                  }
                                }}
                              >
                                <option value={1}>연동</option>
                                <option value={0}>연동 안함</option>
                              </select>
                              <img
                                className="absolute"
                                src={`${process.env.PUBLIC_URL}/img/select-arrow-icon.webp`}
                                style={{
                                  top: '50%',
                                  right: '1rem',
                                  width: '0.6rem',
                                  transform: 'translateY(-50%)',
                                }}
                                alt="select-arrow-icon"
                              />
                            </div>
                          </Row>
                        </td>
                      </tr>
                      <tr style={{ height: '1.5rem' }}>
                        <td></td>
                        <td>
                          <div className="flex">
                            <TypographyText
                              style={{
                                fontSize: '0.8rem',
                                lineHeight: '1.2em',
                                color: '#afafaf',
                              }}
                            >
                              {'3차원 맥영상 진단기(대요메디) 연동하기'}
                            </TypographyText>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td>
                      <span>{`처방전 사용기간`}</span>
                    </td>
                    <td>
                      <Row
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '0.5rem',
                        }}
                      >
                        <TypographyText>{'발급일부터'}</TypographyText>
                        <input
                          type="number"
                          step={1}
                          min={1}
                          className={css`
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                              -webkit-appearance: none;
                            }
                            outline: none;
                          `}
                          style={{
                            border: '1px solid #afafaf',
                            width: '2.25rem',
                            height: '1.5rem',
                            textAlign: 'center',
                            fontSize: '0.9rem',
                            fontWeight: 700,
                          }}
                          value={prescriptionValidityPeriod}
                          onChange={(e: any) => {
                            const v = Number(e.target.value);
                            if (_.isInteger(v)) {
                              if (v > 0) {
                                setPrescriptionValidityPeriod(v);
                              } else {
                                setPrescriptionValidityPeriod(1);
                              }
                            }
                          }}
                          onBlur={async (e: any) => {
                            await updateClinicDataHandler({
                              prescriptionValidityPeriod,
                              mode: '처방전 사용기간',
                            });
                          }}
                        />
                        <TypographyText>{'일 간'}</TypographyText>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{`피드백 발송`}</span>
                    </td>
                    <td>
                      <Row
                        style={{
                          position: 'relative',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '0.5rem',
                        }}
                      >
                        <TypographyText>{'진료완료'}</TypographyText>
                        <input
                          type="number"
                          step={1}
                          min={0}
                          className={css`
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                              -webkit-appearance: none;
                            }
                            outline: none;
                          `}
                          style={{
                            border: '1px solid #afafaf',
                            width: '2.25rem',
                            height: '1.5rem',
                            textAlign: 'center',
                            fontSize: '0.9rem',
                            fontWeight: 700,
                          }}
                          value={feedbackDate}
                          onChange={(e: any) => {
                            const v = Number(e.target.value);
                            if (_.isInteger(v)) {
                              if (v > 0) {
                                setFeedbackDate(v);
                              } else {
                                setFeedbackDate(1);
                              }
                            }
                          }}
                          onBlur={async (e: any) => {
                            await updateClinicDataHandler({
                              feedbackDate,
                              mode: '피드백 발송',
                            });
                          }}
                        />
                        <TypographyText>{'일 후에 발송'}</TypographyText>
                        <Col
                          style={{
                            position: businessFlag ? 'relative' : 'static',
                          }}
                        >
                          <Col
                            style={{
                              border: '1px solid #e5e5e5',
                              padding: '0.3rem 0.5rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onMouseOver={() => setShowFeedbackPreview(true)}
                            onMouseOut={() => setShowFeedbackPreview(false)}
                          >
                            <TypographyText
                              style={{
                                fontSize: '0.75rem',
                                color: '#c4c4c4',
                              }}
                              fontWeight={700}
                              whiteSpace="pre"
                            >
                              {'미리보기'}
                            </TypographyText>
                          </Col>
                          {showFeedbackPreview && (
                            <Row
                              style={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                left: businessFlag ? 'none' : '0',
                                right: businessFlag ? '0' : 'none',
                                bottom: '0',
                                transform: businessFlag
                                  ? 'translateX(calc(100% + 0.5rem))'
                                  : 'translateY(calc(100% + 0.5rem))',
                                zIndex: 10,
                                border: '1px solid #AFAFAF',
                              }}
                            >
                              <Row
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  backgroundColor: '#ffffff',
                                  padding: '1rem',
                                  borderBottom: '1px solid #AFAFAF',
                                }}
                              >
                                <TypographyText
                                  style={{
                                    fontSize: '0.8rem',
                                    lineHeight: '1.2em',
                                    fontWeight: 700,
                                  }}
                                >
                                  {'카카오톡 미리보기'}
                                </TypographyText>
                              </Row>
                              <Row
                                style={{
                                  position: 'relative',
                                  display: 'flex',
                                  padding: '1rem 1.9rem',
                                  border: '1px solid #E4EBF2',
                                  justifyContent: 'center',
                                  backgroundColor: '#E4EBF2',
                                }}
                              >
                                <Col flexFlag style={{ flex: 1 }}>
                                  <Row style={{ width: '14.5rem', display: 'flex', flexDirection: 'column' }}>
                                    <Row
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0.6rem',
                                        backgroundColor: '#FDE333',
                                        height: '2.3rem',
                                      }}
                                    >
                                      <TypographyText style={{ fontSize: '0.75rem', lineHeight: '1.3em' }}>
                                        {'알림톡 도착'}
                                      </TypographyText>
                                    </Row>
                                    <Row
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        padding: '0.6rem',
                                        paddingBottom: '0.75rem',
                                        background: '#ffffff',
                                        rowGap: '0.75rem',
                                      }}
                                    >
                                      <Row
                                        style={{
                                          whiteSpace: 'pre-wrap',
                                          display: 'flex',
                                        }}
                                      >
                                        <TypographyText
                                          style={{
                                            fontSize: '0.75rem',
                                            lineHeight: '1rem',
                                            whiteSpace: 'pre-wrap',
                                            letterSpacing: '-0.04em',
                                          }}
                                        >
                                          {`안녕하세요. 김예진님, ${clinicName}입니다.

한의원에 방문 하신지 ${feedbackDate}일이 지났습니다. 
증상 개선 정도와 진료 만족도에 대해 답변해주시면 더 나은 진료를 위한 자료로 사용하겠습니다.`}
                                        </TypographyText>
                                      </Row>
                                      <Row
                                        flex
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{
                                          backgroundColor: '#f5f5f5',
                                          height: '1.8rem',
                                        }}
                                      >
                                        <TypographyText
                                          style={{
                                            fontSize: '0.6rem',
                                            lineHeight: '1rem',
                                          }}
                                        >
                                          {'증상 개선 여부 답변하기'}
                                        </TypographyText>
                                      </Row>
                                    </Row>
                                  </Row>
                                </Col>
                              </Row>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
          </Row>
          {/* 사업자 유형인 경우 (1.3 기획 이전에는 사업자유형 필드가 없었음. 사업자 유형이 없는 한의원은 사업자 유형으로 취급) */}
          {businessFlag && (
            <Row
              style={{
                position: 'relative',
                flex: 1,
                borderLeft: '1px solid #e5e5e5',
                paddingLeft: '1.25rem',
                paddingTop: '2rem',
              }}
            >
              <Row style={{ display: 'flex', marginBottom: '1.5rem' }}>
                <TypographyText fontSize="1.2rem" lineHeight="1.2em" fontWeight={700}>
                  {'의료기관 정보'}
                </TypographyText>
              </Row>
              <table className="basic-setting-table">
                <colgroup>
                  <col style={{ width: '7.8rem', minWidth: '7.8rem' }} />
                  <col style={{ width: 'calc(100% - 7.8rem)' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <span>{`이름`}</span>
                    </td>
                    <td>
                      <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.25rem' }}>
                        <Input
                          style={{
                            ...inputStyle,
                          }}
                          className={css`
                            &::placeholder {
                              color: #afafaf;
                            }
                          `}
                          value={clinicName}
                          placeholder="한의원 이름"
                          onChange={(e) => {
                            if (e.target.value?.length) {
                              setValidationFlag({
                                ...validationFlag,
                                clinicName: false,
                              });
                            } else {
                              setValidationFlag({
                                ...validationFlag,
                                clinicName: true,
                              });
                            }
                            setClinicName(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </Col>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{`이메일`}</span>
                    </td>
                    <td>
                      <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.25rem' }}>
                        <Input
                          style={{
                            ...inputStyle,
                          }}
                          className={css`
                            &::placeholder {
                              color: #afafaf;
                            }
                          `}
                          value={clinicEmail}
                          placeholder="한의원 이메일을 입력해 주세요."
                          onChange={(e) => {
                            if (emailValidationCheck(e.target.value)) {
                              setValidationFlag({
                                ...validationFlag,
                                clinicEmail: false,
                              });
                            } else {
                              setValidationFlag({
                                ...validationFlag,
                                clinicEmail: true,
                              });
                            }
                            setClinicEmail(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </Col>
                    </td>
                  </tr>
                  {validationFlag.clinicEmail === true && (
                    <tr style={{ height: 'fit-content' }}>
                      <td></td>
                      <td>
                        <Row flex>
                          <TypographyText
                            style={{
                              color: '#DF635C',
                              fontSize: '0.8rem',
                              lineHeight: '1em',
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'keep-all',
                              textIndent: '0.2rem',
                            }}
                          >
                            {clinicEmail?.length > 0
                              ? `이메일 형식이 맞는지 확인해 주세요.`
                              : `한의원 이메일을 입력해 주세요.`}
                          </TypographyText>
                        </Row>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <span>{`전화번호`}</span>
                    </td>
                    <td>
                      <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.25rem' }}>
                        <PhoneInput
                          style={{
                            ...inputStyle,
                          }}
                          country={'KR'}
                          placeholder={'전화번호를 입력해 주세요.'}
                          value={clinicPhone}
                          onChange={(e) => {
                            if (allCasePhoneValidationCheck(e?.replace('+82', '0'))) {
                              setValidationFlag({
                                ...validationFlag,
                                clinicPhone: false,
                              });
                            } else {
                              setValidationFlag({
                                ...validationFlag,
                                clinicPhone: true,
                              });
                            }
                            setClinicPhone(e);
                          }}
                        />
                      </Col>
                    </td>
                  </tr>
                  {validationFlag.clinicPhone === true && (
                    <tr style={{ height: 'fit-content' }}>
                      <td></td>
                      <td>
                        <Row flex>
                          <TypographyText
                            style={{
                              color: '#DF635C',
                              fontSize: '0.8rem',
                              lineHeight: '1em',
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'keep-all',
                              textIndent: '0.2rem',
                            }}
                          >
                            {clinicPhone?.length > 0
                              ? `전화번호 형식이 맞는지 확인해 주세요.`
                              : `전화번호를 입력해 주세요.`}
                          </TypographyText>
                        </Row>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <span>{`팩스 번호`}</span>
                    </td>
                    <td>
                      <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.25rem' }}>
                        <Input
                          style={{
                            ...inputStyle,
                          }}
                          className={css`
                            &::placeholder {
                              color: #afafaf;
                            }
                          `}
                          value={clinicFax}
                          placeholder="팩스번호(선택)"
                          onChange={(e) => {
                            setClinicFax(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </Col>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{`요양기관기호`}</span>
                    </td>
                    <td>
                      <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.25rem' }}>
                        <Input
                          style={{
                            ...inputStyle,
                          }}
                          type="number"
                          className={css`
                            &::placeholder {
                              color: #afafaf;
                            }
                            ::-webkit-outer-spin-button,
                            ::-webkit-inner-spin-button {
                              -webkit-appearance: none;
                              margin: 0;
                            }
                            [type='number'] {
                              -moz-appearance: textfield;
                            }
                          `}
                          min={0}
                          value={medicalInstitutionNumber}
                          placeholder="요양기관기호(선택)"
                          onChange={(e) => {
                            setMedicalInstitutionNumber(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </Col>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>{`주소`}</span>
                    </td>
                    <td>
                      <Row flex alignItems="center">
                        <Col
                          style={{
                            display: 'flex',
                            minWidth: '16rem',
                            alignItems: 'center',
                            minHeight: '2.1rem',
                            border: '1px solid #afafaf',
                            background: 'rgba(175, 175, 175, 0.1)',
                            paddingInline: '0.6rem',
                            paddingBlock: '0.25rem',
                            borderRadius: '3px',
                            marginRight: '0.5rem',
                          }}
                        >
                          <TypographyText
                            style={{
                              lineHeight: '1.2em',
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-all',
                            }}
                            color={`${clinicAddress ? '#1d1c1d' : '#AFAFAF'}`}
                          >
                            {clinicAddress || '주소 찾기 버튼을 클릭해 주세요.'}
                          </TypographyText>
                        </Col>
                        <Col
                          style={{
                            display: 'flex',
                            columnGap: '0.25rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '2.1rem',
                            paddingInline: '0.6rem',
                            border: '1px solid #afafaf',
                            borderColor: '#afafaf',
                            borderRadius: '3px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setIsOpenAddressModal(true);
                          }}
                        >
                          <Col flexFlag>
                            <TypographyText
                              yjTypeTypographyText="sub-title"
                              fontWeight={700}
                              letterSpacing="0.02rem"
                              fontSize={'1rem'}
                              lineHeight="1.2em"
                            >
                              {clinicAddress ? '주소 재검색' : '주소 찾기'}
                            </TypographyText>
                          </Col>
                          <Col flexFlag>
                            <img
                              src={`${process.env.PUBLIC_URL}/img/search-black-icon.webp`}
                              style={{ width: '1rem' }}
                              alt="search-black-icon"
                            />
                          </Col>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Input
                        disabled={!clinicAddress}
                        style={{
                          ...inputStyle,
                        }}
                        className={css`
                          &::placeholder {
                            color: #afafaf;
                          }
                        `}
                        value={clinicDetailAddress}
                        placeholder="상세 주소"
                        onChange={(e) => {
                          setClinicDetailAddress(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Row style={{ height: '3rem', display: 'flex', alignItems: 'end' }}>
                        <Row
                          style={{
                            ...buttonStyle,
                            background: confirmFlag ? '#346AFF' : '#c4c4c4',
                          }}
                          onClick={() => {
                            if (confirmFlag) updateHandler();
                          }}
                        >
                          <TypographyText
                            color="#ffffff"
                            fontWeight={700}
                            fontSize="1rem"
                            lineHeight="1rem"
                          >{`저장하기`}</TypographyText>
                        </Row>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
          )}
        </Row>
      )}
      <ModalType2
        isOpen={isOpenRequireMedicalInstitutionNumberModal}
        okMsg="입력 완료"
        okBtnDisabled={requireMedicalInstitutionNumber.length === 0}
        okFunction={async () => {
          await updateClinicDataHandler({
            medicalInstitutionNumber: Number(requireMedicalInstitutionNumber),
            pulseMeterUseFlag: 1,
            mode: '맥진기 연동',
          });
          setIsOpenRequireMedicalInstitutionNumberModal(false);
          setIsPulseMeterUsed(1);
          setRequireMedicalInstitutionNumber('');
        }}
        cancelMsg="닫기"
        cancelFunction={() => {
          setIsOpenRequireMedicalInstitutionNumberModal(false);
          setIsPulseMeterUsed(0);
          setRequireMedicalInstitutionNumber('');
        }}
        width={33.2}
        content={
          <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', marginTop: '1rem' }}>
            <TypographyText fontSize="1.4rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
              {'맥진기 연동을 위해 요양기관기호를 입력해 주세요.'}
            </TypographyText>
            <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.25rem' }}>
              <Input
                style={{
                  ...inputStyle,
                  height: '3.2rem',
                  width: '100%',
                }}
                type="number"
                className={css`
                  &::placeholder {
                    color: #afafaf;
                  }
                  ::-webkit-outer-spin-button,
                  ::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                  [type='number'] {
                    -moz-appearance: textfield;
                  }
                `}
                min={0}
                value={requireMedicalInstitutionNumber}
                placeholder="요양기관기호(숫자만 입력)"
                onChange={(e) => {
                  setRequireMedicalInstitutionNumber(e.target.value);
                }}
                autoComplete="off"
              />
            </Col>
            <Row style={{ display: 'flex', marginTop: '-0.5rem' }}>
              <TypographyText
                style={{
                  color: '#afafaf',
                  fontSize: '0.8rem',
                  lineHeight: '1.2em',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'keep-all',
                }}
              >
                {`[기본 설정] > 의료기관 정보 > 요양기관기호로 등록됩니다.`}
              </TypographyText>
            </Row>
          </Row>
        }
      />
      <ModalType2
        isOpen={isOpenAddressModal}
        cancelMsg="닫기"
        cancelFunction={() => setIsOpenAddressModal(false)}
        content={
          <Row style={{ marginBottom: '0' }}>
            <DaumPost setIsOpenAddressModal={setIsOpenAddressModal} setAddress={setClinicAddress} />
          </Row>
        }
        contentNonePaddingFlag={true}
        width={'600px'}
        contentHeight={'445px'}
      />
    </>
  );
};

export default observer(BasicSetting);
