type TSpan = {
  divClassName?: string;
  className?: string;
  children?: string;
};

export function Span({ className, divClassName, children }: TSpan) {
  return (
    <div className={`flex ${divClassName}`}>
      <span className={`text ${className}`}>{children}</span>
    </div>
  );
}
