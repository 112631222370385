import AdminLayout from '../../templates/AdminLayout';
import DailySurveyAndTreatmentCountList from '../../UI/organisms/etc/DailySurveyAndTreatmentCountList';

const SurveyAndTreatmentCountPage = () => {
  return (
    <AdminLayout title={'일일 설문 및 진료 수'} content={<DailySurveyAndTreatmentCountList />} sideType={0} />
  );
};

export default SurveyAndTreatmentCountPage;
