import { css } from '@emotion/css';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores/Context';
import LandingLayout from 'components/templates/LandingLayout';
import { Row, TypographyText, ModalWithJustX, Input } from 'components/UI/atoms';
import { Loading } from 'components/UI/molecules';

const SignInPage: FC<{}> = () => {
  const { userStore, clientStore } = useStores();
  const [isOpenMediaModal, setIsOpenMediaModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [errors, setErrors] = useState<
    | 'notExistId'
    | 'notExistPw'
    | 'invalidAccount'
    | 'notExistAccount'
    | 'unknownError'
    | 'attemptsExceeded'
    | ''
  >('');

  useEffect(() => {}, []);

  const signIn = async () => {
    try {
      setIsLoading(true);
      await userStore.signIn(email, password);
      const sub = sessionStorage.getItem('sub')!;
      await clientStore.setMetaData(sub);

      // todo 로그인후 인증된 사용자의 병원 리스트 데이터 로드
      // const roleByUser = (await API.graphql(
      //   graphqlOperation(
      //     queries.roleByUser,
      //     { userID: sub },
      //     (await Auth.currentSession()).getIdToken().getJwtToken(),
      //   ),
      // )) as { data: RoleByUserQuery };

      if (typeof clientStore.metaData?.userRole !== 'undefined') {
        navigate('/patient', {
          state: {
            noticeFlag: true,
          },
        });
      } else {
        navigate('/signinWithoutClinic');
      }
    } catch (e) {
      console.log(e);
      if (e?.message?.includes('not exist')) {
        // 계정이 존재하지 않는 경우
        setErrors('notExistAccount');
      } else if (e?.message?.includes('Incorrect')) {
        // 계정 정보 잘못 입력한 경우
        setErrors('invalidAccount');
      } else if (e?.message?.includes('attempts exceeded')) {
        // 로그인 시도가 너무 빈번한 경우
        setErrors('attemptsExceeded');
      } else {
        setErrors('unknownError');
      }
      setIsLoading(false);
    }
  };

  const content = (
    <Row
      onKeyUp={(e) => {
        if (e.keyCode === 13) {
          signIn();
        }
      }}
    >
      {isLoading && <Loading mode="global" />}
      <Row flex flexDirection="column" justifyContent={'flex-start'} alignItems={'center'}>
        <ModalWithJustX
          isOpen={isOpenMediaModal}
          width={`48rem`}
          height={`27rem`}
          content={
            <Row
              style={{
                position: 'relative',
                width: '100%',
                paddingBottom: '56.25%',
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0,
                }}
                src={`https://www.youtube.com/embed/e5l75OuvL_s?autoplay=1&start=46`}
                title="Ye-jin Signup"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen={true}
              />
            </Row>
          }
          cancelFunction={() => setIsOpenMediaModal(false)}
        />
        <Row flex flexDirection={'column'} style={{ width: 'calc(100vw / 3)' }}>
          <Row
            flex
            style={{
              marginTop: '3rem',
              justifyContent: 'space-between',
            }}
          >
            <TypographyText
              style={{
                fontWeight: 700,
                fontSize: '1.8rem',
              }}
            >
              로그인
            </TypographyText>
            {/* <GuideButton buttonText="동영상 가이드" onClickFunc={() => setIsOpenMediaModal(true)} /> */}
          </Row>
          <Row flex flexDirection="column" style={{ marginTop: '1.75rem', rowGap: '0.6rem' }}>
            <Input
              style={{
                height: '3.2rem',
                width: '100%',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                textIndent: '0.4rem',
                fontSize: '1rem',
                outline: 'none',
              }}
              className={css`
                &::placeholder {
                  color: #afafaf;
                }
              `}
              value={email}
              placeholder="아이디(이메일)"
              onChange={(e) => {
                if (errors === 'notExistId') {
                  setErrors('');
                }
                setEmail(e.target.value);
              }}
              autoComplete="off"
            />
            <Input
              type="password"
              style={{
                height: '3.2rem',
                width: '100%',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                textIndent: '0.4rem',
                fontSize: '1rem',
                outline: 'none',
              }}
              className={css`
                &::placeholder {
                  color: #afafaf;
                }
              `}
              value={password}
              placeholder="비밀번호"
              onChange={(e) => {
                if (errors === 'notExistPw') {
                  setErrors('');
                }
                setPassword(e.target.value);
              }}
              autoComplete="off"
            />
          </Row>
          <Row flex justifyContent={'flex-end'} alignItems={'center'} style={{ marginTop: '1.2rem' }}>
            <TypographyText
              fontWeight={700}
              fontSize={'0.8rem'}
              textDecoration={'underline'}
              className={css`
                cursor: pointer !important;
              `}
              onClick={(e) => {
                navigate('/forgotpassword');
              }}
            >{`비밀번호 재설정`}</TypographyText>
          </Row>
          <Row flex flexDirection={'column'} style={{ marginTop: '1.5rem', rowGap: '0.5rem' }}>
            {errors === 'notExistId' && (
              <Row flex style={{ marginBottom: '0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {`아이디를 입력해 주세요.`}
                </TypographyText>
              </Row>
            )}
            {errors === 'notExistPw' && (
              <Row flex style={{ marginBottom: '0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {`비밀번호를 입력해 주세요.`}
                </TypographyText>
              </Row>
            )}
            {errors === 'invalidAccount' && (
              <Row flex style={{ marginBottom: '0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {`아이디(이메일) 또는 비밀번호가 일치하지 않습니다. 입력하신 내용을 다시 확인해 주세요.`}
                </TypographyText>
              </Row>
            )}
            {errors === 'notExistAccount' && (
              <Row flex style={{ marginBottom: '0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {`존재하지 않는 아이디(이메일) 입니다. 입력하신 내용을 다시 확인해 주세요.`}
                </TypographyText>
              </Row>
            )}
            {errors === 'attemptsExceeded' && (
              <Row flex style={{ marginBottom: '0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {`로그인 시도가 너무 많습니다. 잠시 후 다시 시도해 주세요.`}
                </TypographyText>
              </Row>
            )}
            {errors === 'unknownError' && (
              <Row flex style={{ marginBottom: '0.2rem' }}>
                <TypographyText yjTypeTypographyText="survey-validation">
                  {`일시적인 오류로 현재 로그인을 할 수 없습니다. 잠시 후 다시 이용해 주세요.`}
                </TypographyText>
              </Row>
            )}
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '3.6rem',
                cursor: 'pointer',
                background: '#346AFF',
                border: '1px solid #346AFF',
                borderRadius: '3px',
              }}
              onClick={() => {
                if (email?.length === 0) {
                  setErrors('notExistId');
                } else if (password?.length === 0) {
                  setErrors('notExistPw');
                } else {
                  signIn();
                }
              }}
            >
              <TypographyText
                color="#ffffff"
                fontWeight={700}
                fontSize="1.2rem"
                lineHeight="1.2rem"
              >{`로그인`}</TypographyText>
            </Row>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '3.6rem',
                cursor: 'pointer',
                background: '#ffffff',
                border: '1px solid #afafaf',
                borderRadius: '3px',
              }}
              onClick={() => navigate('/signup')}
            >
              <TypographyText
                fontWeight={700}
                fontSize="1.2rem"
                lineHeight="1.2rem"
              >{`회원가입`}</TypographyText>
            </Row>
          </Row>
        </Row>
      </Row>
    </Row>
  );
  return <LandingLayout content={content} />;
};

export default SignInPage;
