import { Divider, Flex, Heading, Table, Text } from '@aws-amplify/ui-react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import PolicyDetailLayout from 'components/templates/PolicyDetailLayout';

const PrivacyPolicy = () => {
  const StyledOLTag = styled.ol`
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1rem;
  `;

  const StyledTD = styled.td`
    padding: 1rem;
  `;

  const StyledTHeader = styled.tr`
    border-top: 1px solid #afafaf;
    background-color: #f5f5f5;
    font-size: 1.2rem;
    font-weight: 700;
  `;
  const StyledTR = styled.tr`
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    font-size: 1rem;
    font-weight: 400;
    vertical-align: top;
  `;

  return (
    <Flex direction={'column'} gap={0} marginBottom={'5rem'} id="privacy-policy-block">
      <Heading level={1} fontWeight={700} fontSize={'1.6rem'}>
        개인정보 처리방침
      </Heading>
      <Divider marginTop={'1rem'} border={'1px solid #AFAFAF'} />
      <Text fontSize={'1rem'} fontWeight={400} marginTop={'2rem'}>
        (주)헬리큐어 (“회사”라 함)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법,
        통신비밀보호법, 전기통신사업법 및 관련법 시행령, 시행규칙등 정보통신서비스제공자가 준수하여야 할 관련
        법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 예진 서비스 이용과
        관련하여 “회사”와 서비스 신청자(이라 “회원”이라 함) 권익 보호에 최선을 다하고 있습니다.
        <br />
        <br />본 개인정보처리방침은 “회사”가 제공하는 “홈페이지(yejin.clinic)” 및 “어플리케이션” (이하에서는
        홈페이지 및 어플리케이션을 이하 ‘서비스’라 합니다.) 이용에 적용되며 다음과 같은 내용을 담고 있습니다.
      </Text>

      <PolicyDetailLayout
        title={'개인정보의 수집 및 이용 목적'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 회원 가입 시 또는 서비스 이용 과정에서 온라인 페이지를 통해 아래와 같은 서비스 및 그
                외 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.
              </li>
              <li>
                “회사”는 다음과 같이 “회원”의 개인정보를 수집합니다. 처리하고 있는 개인정보는 다음의 목적
                이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라
                별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                <Table
                  margin={'1rem 0'}
                  highlightOnHover={false}
                  size={undefined}
                  variation={undefined}
                  className={css`
                    padding: 0.5rem !important;
                  `}
                >
                  <StyledTHeader>
                    <StyledTD width={'25%'}>구분</StyledTD>
                    <StyledTD width={'25%'}>수집·이용 목적</StyledTD>
                    <StyledTD width={'25%'}>수집·항목</StyledTD>
                    <StyledTD width={'25%'}>수집·이용 기간</StyledTD>
                  </StyledTHeader>
                  <StyledTR>
                    <StyledTD>예진 한의사 회원(공통)</StyledTD>
                    <StyledTD>
                      서비스 신청 및 회원 관리 <br />
                      “회사”가 제공하는 서비스 이용,
                      <br />
                      서비스 이용에 따른 본인 확인 절차 등
                    </StyledTD>
                    <StyledTD>
                      1)사업자 등록 번호, 요양기관번호, 요양기관명, 요양기관주소, 요양기관전화번호,
                      대표자성함, 대표자 전화번호, 대표자 이메일 <br />
                      2)병원의 의료진 정보, 시설정보, CAT-ID 정보 등 요양기관 관련 정보
                      <br />
                      3)서비스 이용 기록, 접속로그
                      <br />
                    </StyledTD>
                    <StyledTD>
                      서비스 회원 탈퇴 및 이용계약 종료 시<br />
                      (단,관련법령에 따라 별도 보관되는 정보는 예외)
                    </StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>서비스 이용자 (환자)</StyledTD>
                    <StyledTD>회사가 제공하는 서비스 이용, 서비스 이용에 따른 본인 확인 절차 등</StyledTD>
                    <StyledTD>
                      1) 이름, 생년월일, 성별, 전화번호
                      <br />
                      2) 서비스 이용 기록, 접속로그
                    </StyledTD>
                    <StyledTD>
                      서비스 회원 탈퇴 및 이용 계약 종료 시<br />
                      (단, 관련법령에 따라 별도 보관되는 정보는 예외)
                    </StyledTD>
                  </StyledTR>
                </Table>
              </li>
              <li>
                ”회사”는 “회원”의 개인정보보호를 매우 중요시하며, 『개인정보 보호법』과 『정보통신망 이용촉진
                및 정보보호 등에 관한 법률』상의 개인정보처리방침 및 행정안전부와 과학기술정보통신부가 제정한
                『개인정보 보호지침』을 준수하고 있습니다. “회사”는 개인정보처리방침을 통하여 “회원”이
                제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
                취해지고 있는지 알려드립니다.
              </li>
              <li>
                ”회사”는 개인정보처리방침을 사이트 첫 화면과 고객센터의 ‘개인정보처리방침’ 항목에서
                공개함으로써 “회원”이 언제나 용이하게 이를 확인할 수 있도록 조치하고 있습니다.
              </li>
              <li>
                ”회사”는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데 필요한 절차를
                정하고 있습니다. 그리고 개인정보처리방침을 개정하는 경우 버전 번호 등을 부여하여 개정된 사항을
                귀하께서 쉽게 알아볼 수 있도록 하고 있습니다.
              </li>
              <li>
                ”회사”는 “회원”을 대상으로 하는 부가 서비스 및 맞춤식 서비스 제공 또는 이벤트 응모 과정에서
                위와 같은 정보들을 수집할 수 있습니다.
              </li>
              <li>
                기타 “회사”가 제공하는 정보 전달, 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공에 활용될
                수 있습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'개인정보 수집 및 이용에 대한 동의'}
        content={
          <>
            “회사”는 “회원”이 회사의 개인정보 보호정책 또는 이용약관의 내용에 대하여 「동의」 버튼을 클릭할 수
            있는 절차를 마련하여, 「동의」 버튼을 클릭하면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다.
          </>
        }
      />

      <PolicyDetailLayout
        title={'개인정보의 처리 및 보유 기간'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”의 개인정보는 그 수집목적 또는 제공받은 목적이 달성되거나 “회원”이 이용계약
                해지(회원탈퇴)를 요청한 경우 파기하는 것을 원칙으로 하며, 이 경우 “회원”의 개인정보는 재생할
                수 없는 방법에 의하여 시스템에서 완전히 삭제 되며 어떠한 용도로 열람 또는 이용할 수 없도록
                처리됩니다. 또한, 일시적인 목적(설문조사 등)으로 입력 받은 개인정보는 그 목적이 달성된
                이후에는 동일한 방법으로 사후 재생이 불가능한 방법으로 처리됩니다.
              </li>
              <li>
                “회사”는 불량 “회원”의 부정한 이용의 재발을 방지하기 위해 이용계약 해지일(회원탈퇴일)로부터
                2년간 해당 “회원”의 개인정보를 보유할 수 있습니다. 그리고 상법, 전자상거래 등에서의
                소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 “회사”는
                관계법령에서 정한 일정한 기간 동안 “회원”의 개인정보를 보관합니다. 이 경우 “회사”는 보관하는
                정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                <Text fontWeight={700} fontSize={'1rem'} margin={'1rem 0'}>
                  ※ 회사 내부 정책에 의하여 수집 및 이용되는 정보
                </Text>
                <Table
                  margin={'1rem 0'}
                  highlightOnHover={false}
                  size={undefined}
                  variation={undefined}
                  className={css`
                    padding: 0.5rem !important;
                  `}
                >
                  <StyledTHeader>
                    <StyledTD width={'33%'}>구분</StyledTD>
                    <StyledTD width={'33%'}>항목</StyledTD>
                    <StyledTD width={'33%'}>기간</StyledTD>
                  </StyledTHeader>
                  <StyledTR>
                    <StyledTD>
                      회사 내부 정책
                      <br />
                      (부정거래의 배제 등 회사 방침에 의한 보존)
                    </StyledTD>
                    <StyledTD>아이디, CAT-ID</StyledTD>
                    <StyledTD>회원 탈퇴 후 2년</StyledTD>
                  </StyledTR>
                </Table>
                <Text fontWeight={700} fontSize={'1rem'} margin={'1rem 0'}>
                  ※ 법령에 의하여 수집 및 이용되는 정보
                </Text>
                <Table
                  margin={'1rem 0'}
                  highlightOnHover={false}
                  size={undefined}
                  variation={undefined}
                  className={css`
                    padding: 0.5rem !important;
                  `}
                >
                  <StyledTHeader>
                    <StyledTD width={'33%'}>근거법령</StyledTD>
                    <StyledTD width={'33%'}>항목</StyledTD>
                    <StyledTD width={'33%'}>보존기간</StyledTD>
                  </StyledTHeader>
                  <StyledTR>
                    <StyledTD rowSpan={4}>전자상거래 등에서의 소비자 보호에 관한 법률</StyledTD>
                    <StyledTD>
                      계약 또는 청약철회 등에 관한 기록 <br />
                    </StyledTD>
                    <StyledTD>5년</StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>
                      대금결제 및 재화 등의 공급에 관한 기록
                      <br />
                    </StyledTD>
                    <StyledTD>5년</StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>
                      소비자의 불만 또는 분쟁처리에 관한 기록
                      <br />
                    </StyledTD>
                    <StyledTD>3년</StyledTD>
                  </StyledTR>{' '}
                  <StyledTR>
                    <StyledTD>
                      표시/광고에 관한 기록
                      <br />
                    </StyledTD>
                    <StyledTD>6개월</StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>
                      신용정보의 이용 및 보호에 관한 법률
                      <br />
                    </StyledTD>
                    <StyledTD>신용정보의 수집/처리 및 이용 등에 관한 기록</StyledTD>
                    <StyledTD>3년</StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>통신비밀보호법</StyledTD>
                    <StyledTD>서비스 방문 기록</StyledTD>
                    <StyledTD>3개월</StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>전자금융거래법</StyledTD>
                    <StyledTD>전자금융 거래에 관한 기록</StyledTD>
                    <StyledTD>5년</StyledTD>
                  </StyledTR>
                </Table>
              </li>
              <li>
                이용약관 및 관계법령을 위반한 “회원”의 경우, 다른 “회원”을 보호하고 사법기관 수사의뢰 시
                증거자료로 활용하기 위해 “회사”는 “회원” 탈퇴 후 2년까지 “회원”의 개인정보를 보관할 수
                있습니다.
              </li>
              <li>
                2022년 10월 1일 이후 가입한 “회원”이 1년간 서비스 거래 기록이 없을 시, 서비스 미 이용 회원의
                개인정보는 「개인정보보호법」 제39조의6에 근거하여 “회원”에게 사전 통지 후 개인정보를
                파기하거나 별도로 분리하여 저장 관리합니다. 단, “회원”의 요청이 있을 경우에는 위 기간을 달리
                정할 수 있습니다.
              </li>
              <li>
                “회원”의 개인정보와 관련하여 ‘통신비밀보호법’, ‘전자상거래 등에서의 소비자보호에 관한 법률’ 및
                동법 시행령, 시행규칙 등의 관계법령의 규정에 의하여 별도 보존할 필요가 있는 경우에
                관계법령에서 규정한 일정한 기간 동안 ‘회원’의 개인정보를 보관합니다. “회사”는 위의 1년의 기간
                만료 30일 전까지 개인정보가 파기되거나 분리되어 저장, 관리되는 사실과 기간 만료일 및 해당
                개인정보의 항목을 전자우편 등의 방법으로 고객에게 알립니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'업무 위탁의 목적 및 책임범위'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”은 아래의 서비스에 대해 “회사”에게 위탁함에 있어 다음과 같은 내용으로 업무위탁계약을
                체결합니다.
              </li>
              <li>
                위탁업무의 내용 및 위탁 정보는 다음과 같습니다.
                <Table
                  margin={'1rem 0'}
                  highlightOnHover={false}
                  size={undefined}
                  variation={undefined}
                  className={css`
                    padding: 0.5rem !important;
                  `}
                >
                  <StyledTHeader>
                    <StyledTD width={'15rem'}>서비스 항목</StyledTD>
                    <StyledTD width={'25rem'}>서비스 목적</StyledTD>
                    <StyledTD>처리 항목</StyledTD>
                  </StyledTHeader>
                  <StyledTR>
                    <StyledTD rowSpan={2}>접수/예약 서비스</StyledTD>
                    <StyledTD>환자 접수/예약 및 서류발급</StyledTD>
                    <StyledTD>
                      1) 환자정보 <br />
                      - 환자 이름, 생년월일, 성별, 휴대전화번호 <br />
                      2) 진료 정보 <br />- 처방정보, 진료비 영수증, 진료비 세부내역서 등
                    </StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>접수/예약 서비스 사용방법 안내</StyledTD>
                    <StyledTD>
                      1) 환자 정보
                      <br />- 환자 이름, 휴대전화번호
                    </StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>증상 설문 서비스</StyledTD>
                    <StyledTD>환자 증상에 대한 상세 설문 결과 수집</StyledTD>
                    <StyledTD>
                      1) 환자정보
                      <br />
                      - 환자 이름, 생년월일, 성별, 휴대전화번호
                      <br />
                      2) 증상 정보
                      <br />- 증상 설문 입력 정보
                    </StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>진료 서비스</StyledTD>
                    <StyledTD>환자 증상 설문 데이터 기반의 진료</StyledTD>
                    <StyledTD>
                      1) 환자정보
                      <br />
                      - 환자 이름, 생년월일, 성별, 휴대전화번호, 이메일
                      <br />
                      2) 증상정보
                      <br />
                      - 증상 설문 입력 정보
                      <br />
                      3) 한의사정보
                      <br />
                      - 한의원, 한의사 이름, 이메일
                      <br />
                      4) 처방정보
                      <br />- 증상/변증, 처방, 치료
                    </StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>처방 피드백 서비스</StyledTD>
                    <StyledTD>환자 처방에 대한 피드백 수집</StyledTD>
                    <StyledTD>
                      1) 환자정보
                      <br />
                      - 환자 이름, 생년월일, 성별, 휴대전화번호
                      <br />
                      2) 처방 피드백 정보
                      <br />- 처방에 대한 환자 피드백 입력 정보
                    </StyledTD>
                  </StyledTR>
                </Table>
              </li>
              <li>“회사”와 “회원”은 본 계약상의 의무를 이행함에 있어 관련 법령을 준수하여야 합니다.</li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'개인정보 처리의 위탁'}
        content={
          <>
            <StyledOLTag>
              <li>
                1. “회사”가 업무를 위탁받는 업체는 다음과 같습니다.
                <Table
                  margin={'1rem 0'}
                  highlightOnHover={false}
                  size={undefined}
                  variation={undefined}
                  className={css`
                    padding: 0.5rem !important;
                  `}
                >
                  <StyledTHeader>
                    <StyledTD width={'50%'}>위탁 받는 자(수탁 업체)</StyledTD>
                    <StyledTD width={'50%'}>업무내용</StyledTD>
                  </StyledTHeader>
                  <StyledTR>
                    <StyledTD>비즈톡 주식회사</StyledTD>
                    <StyledTD>문자 발송 및 카카오 알림톡, 대량 문자 발송</StyledTD>
                  </StyledTR>
                  <StyledTR>
                    <StyledTD>Amazon Web Services, Inc</StyledTD>
                    <StyledTD>IT 시스템 운영</StyledTD>
                  </StyledTR>
                </Table>
              </li>
              <li>
                ”회사”는 서비스 향상을 위해서 “회원”의 개인정보를 외부 전문업체에 위탁하여 처리할 수 있습니다.
                “개인정보”의 처리를 위탁하는 경우에는 미리 그 사실을 회원에게 별도 공지할 것입니다.
              </li>
              <li>
                ”회원”의 개인정보의 위탁 처리하는 경우에는 위탁계약(위탁업무 수행 목적 외 개인정보 처리 금지,
                개인정보의 기술적 관리적 보호조치, 위탁업무의 목적 및 범위, 재위탁 제한, 개인정보에 대한 접근
                제한 등 안정성 확보 조치, 위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독,
                수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 등이 내용이 포함됨)을 통하여
                서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및
                사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관할 것입니다.
              </li>
              <li>
                이 경우에 “회사”는 위탁업무의 내용, 개인정보 처리업무를 위탁 받아 처리하는 자(수탁자)를
                “회원”이 언제든지 쉽게 확인할 수 있도록 “회사”의 ”서비스”에 지속적으로 게재하여 공개합니다.
              </li>
              <li>
                ”회사”는 업무 위탁으로 인하여 “회원”의 개인정보가 분실, 도난, 유출, 변조, 훼손되지 아니하도록
                수탁자를 교육하고 수탁자가 개인정보를 안전하게 처리하는 지를 감독할 것입니다.
              </li>
            </StyledOLTag>
          </>
        }
      />

      <PolicyDetailLayout
        title={'개인정보의 제3자에 대한 제공'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회사”는 원칙적으로 "회원”의 개인정보를 외부에 공개하지 않습니다. 다만 “회사”가 “회원”으로부터
                개인정보의 수집 및 이용에 대한 동의와 별도로 제3자 제공에 사전 동의를 얻은 경우에 한해
                “회원”의 개인정보를 제3자에게 제공할 수 있습니다.
              </li>
              <li>
                “회사”는 “회원”에게 개인정보를 제공받는 자의 성명과 연락처, 제공받는 자의 개인정보 이용 목적,
                제공하는 개인정보의 항목, 제공받는 자의 개인정보 보유 및 이용 기간, 동의 거부권이 존재한다는
                사실 및 동의 거부에 따른 불이익의 내용을 미리 알립니다.
              </li>
              <li>
                기타 다음 각 호에 해당하는 경우에는 “회원”의 동의 없이 개인정보를 제3자에게 제공할 수
                있습니다.
                <Flex direction={'column'} gap={0}>
                  <Text>1) 법률규정이 있거나 법령상 의무준수를 위해 불가피한 경우</Text>
                  <Text>2) 수사기관이 수사목적을 위해 관계법령이 정한 절차를 거쳐 요구하는 경우</Text>
                  <Text>
                    3) 통계작성 및 학술연구 등의 목적을 위해 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로
                    개인정보를 제공하는 경우
                  </Text>
                </Flex>
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'수탁자에 대한 관리, 감독 등'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”은 “회사”에 대하여 다음 각 호의 사항을 관리하도록 요구할 수 있으며, “회사”는 특별한
                사유가 없는 한 이에 응하여야 합니다.
                <Flex direction={'column'} gap={0}>
                  <Text>(1) 개인정보의 처리 현황</Text>
                  <Text>(2) 개인정보의 접근 또는 접속 현황</Text>
                  <Text>(3) 개인정보 접근 또는 접속 대상자</Text>
                  <Text>(4) 목적 외 이용, 제공 및 재위탁 금지 준수여부</Text>
                  <Text>(5) 암호화 등 안전성 확보 조치 이행여부</Text>
                  <Text>(6) 그 밖에 개인정보의 보호를 위하여 필요한 사항</Text>
                </Flex>
              </li>
              <li>
                “회원”은 “회사”에 대하여 제1항 각 호의 사항에 대한 실태를 점검하여 시정을 요구할 수 있으며,
                “회사”은 특별한 사유가 없는 한 이행하여야 합니다.
              </li>
              <li>
                “회원”은 처리 위탁으로 인하여 “회원”의 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지
                아니하도록 1년에 1회 “회사”를 교육할 수 있으며, “회사”는 이에 응하여야 합니다.
              </li>
              <li>
                본 조 제1항에 따른 교육의 시기와 방법 등에 대해서는 “회원”은 “회사”와 협의하여 시행합니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'개인정보 파기절차 및 방법'}
        content={
          <>
            “회원”의 개인정보는 원칙적으로 보유기간의 경과, 개인정보의 수집 및 이용목적 달성 등 그 개인정보가
            불필요하게 되었을 때에는 지체 없이 파기합니다. “회사”의 개인정보 파기절차 및 방법은 다음과
            같습니다.
            <StyledOLTag>
              <li>
                파기절차
                <Flex direction={'column'} gap={0}>
                  <Text>
                    (1) 회사는 개인정보의 파기에 관한 사항을 기록하고 관리하며, 파기는 개인정보 보호책임자의
                    책임하에 수행되며, 개인정보 보호책임자는 파기 결과를 확인합니다.
                  </Text>
                  <Text>
                    (2) 회사는 다른 법령에 따라 보존해야 하는 경우에는 예외적으로 “회원”의 개인정보를 파기하지
                    않을 수 있습니다.
                  </Text>
                </Flex>
              </li>
              <li>
                파기방법
                <Flex direction={'column'} gap={0}>
                  <Text>(1) 종이나 그 밖의 기록매체에 저장된 개인정보는 파쇄하거나 소각합니다.</Text>
                  <Text>
                    (2) 전자적 파일 형태로 저장된 개인정보는복원이 불가능한 방법(내지 기록을 재생할 수 없는
                    기술적 방법)으로 영구 삭제합니다.
                  </Text>
                </Flex>
              </li>
              <li>
                {' '}
                미 파기 정보의 보존방법 <br />
                “회사”는 법령에 따라 개인정보를 파기하지 않고 보존하는 경우에 해당 개인정보 또는
                개인정보파일을 다른 개인정보와 분리하여 저장 관리합니다. “회사”는 별도 DB로 옮긴 개인정보를
                법률에 의한 경우가 아니고서는 보유하는 이외의 다른 목적으로 이용하지 않습니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'개인정보주체의 권리와 의무 및 그 행사방법'}
        content={
          <>
            <StyledOLTag>
              <li>
                “회원”은 언제든지 “회사”에 “예진” 서비스를 통하여 등록되어 있는 “회원”의 개인정보, “회사”가
                “회원”의 개인정보를 이용하거나 제3자에게 제공한 현황, “회사”에게 개인정보 수집, 이용, 제공
                등의 동의를 한 현황에 대하여 열람이나 제공을 요구할 수 있고, 오류가 있는 경우에는 그 정정을
                요구할 수 있으며, 삭제 내지 가입해지를 요구할 수도 있습니다.
              </li>
              <li>
                “회원”의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을,
                가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정
                또는 탈퇴가 가능합니다.
              </li>
              <li>
                이 경우에 “회사”는 지체 없이 그 개인정보를 조사하여 “회원”의 요구에 따라 정정, 삭제 등 필요한
                조치를 한 후 그 결과를 “회원”에게 알립니다. “회사”는 필요한 조치를 할 때까지는 해당 개인정보를
                이용하거나 제공하지 않습니다.
              </li>
              <li>
                “회원”은 언제든지 “회사”에 자신의 개인정보 처리의 정지를 요구할 수 있으며, 이 경우에 “회사”는
                지체 없이 그 요구에 따라 개인정보 처리의 전부 내지 일부를 정지하며, 처리가 정지된 개인정보에
                대하여 지체 없이 그 개인정보의 파기 등 필요한 조치를 취합니다.
              </li>
              <li>
                개인정보와 관련하여 이의나 의견이 있으신 분은 개인정보 보호책임자 및 담당자에게 서면, 전화
                또는 E-mail로 연락하시면 접수 즉시 처리하고 결과를 안내해 드리겠습니다. 로그온(log-on)한
                상태에서는 주위의 다른 사람에게 개인정보가 유출되지 않도록 특별히 주의를 기울이시기 바랍니다.
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항'}
        content={
          <>
            “회사”는 “회원”에게 특화된 맞춤서비스를 제공하기 위해서 “회원”의 정보를 저장하고 수시로 불러오는
            ’쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 “회원”의 컴퓨터
            브라우저에게 보내는 소량의 정보이며 “회원”의 PC 컴퓨터 내 하드디스크에 저장되기도 합니다.
            <br />
            <StyledOLTag>
              <li>
                쿠키의 사용 목적: 회원들이 방문한 “예진”의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,
                인기 검색어, 보안접속 여부, 회원 규모 등을 파악하여 회원에게 최적화된 정보 제공을 위하여
                사용합니다.
              </li>
              <li>
                쿠키의 설치/운영 및 거부
                <Flex direction={'column'} gap={0}>
                  <Text>
                    (1) “회원”은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, “회원”은 웹브라우저에서
                    옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면
                    모든 쿠키의 저장을 거부할 수도 있습니다.
                  </Text>
                  <Text>
                    (2) 쿠키 설정을 거부하는 방법으로는 “회원”이 사용하는 웹 브라우저의 옵션을 선택함으로써
                    모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수
                    있습니다.
                  </Text>
                  <Text>
                    (3) 설정방법 예시는 다음과 같습니다. <br />
                    (‘인터넷 익스플로러’의 경우: 웹 브라우저 상단의 도구 {'>'} 인터넷 옵션 {'>'} 개인정보)
                  </Text>
                  <Text>
                    (4) 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을
                    수 있습니다.
                  </Text>
                </Flex>
              </li>
            </StyledOLTag>
          </>
        }
      />

      <PolicyDetailLayout
        title={'개인정보의 안전성 확보조치'}
        content={
          <>
            “회사”는 “회원”의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록
            다음과 같은 조치를 강구하고 있습니다.
            <br />
            <StyledOLTag>
              <li>
                기술적 조치
                <Flex direction={'column'} gap={0}>
                  <Text>
                    (1) “회원”의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일
                    잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
                  </Text>
                  <Text>
                    (2) “회사”는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를
                    취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우
                    백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                  </Text>
                  <Text>
                    (3) “회사”는 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템
                    등을 이용하여 보안에 만전을 기하고 있습니다.
                  </Text>
                </Flex>
              </li>
              <li>
                관리적 조치
                <Flex direction={'column'} gap={0}>
                  <Text>
                    (1) “회사”는 “회원”의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있으며,
                    “회원”을 직접 상대로 하여 마케팅 업무를 수행하는 자, 개인정보 보호책임자 및 담당자 등
                    개인정보관리업무를 수행하는 자, 기타 업무상 개인정보의 취급이 불가피한 자 외에는 접근을
                    엄격히 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고 있습니다. 단,
                    “회원” 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출해
                    발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
                  </Text>
                  <Text>
                    (2) 위와 같은 노력 이외에 “회원”님 스스로도 제3자에게 비밀번호 등이 노출되지 않도록
                    주의하셔야 합니다. 특히 비밀번호 등이 공공장소에 설치한 PC를 통해 유출되지 않도록 항상
                    유의하시기 바랍니다. 회원님의 ID와 비밀번호는 반드시 본인만 사용하고 비밀번호를 정기적으로
                    바꿔주시는 것이 좋습니다.
                  </Text>
                </Flex>
              </li>
            </StyledOLTag>
          </>
        }
      />
      <PolicyDetailLayout
        title={'개인정보관련 의견수렴 및 침해, 불만 처리에 관한 사항'}
        content={
          <>
            ”회사”는 개인정보보호와 관련하여 “회원”의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든
            절차와 방법을 마련하고 있습니다. “회원”은 회사의 서비스 이용 중 발생한 모든 개인정보 보호 관련
            문의, 불만처리, 피해구제 등에 관한 사항을 아래 개인정보 보호책임자 및 담당자에게 문의할 수
            있습니다. “회사”는 “회원”의 신고사항에 대하여 신속하게 처리하여 답변해드립니다.
            <br />
            <br />
            ▶ 개인정보 보호책임자 <br />
            담 당: 조성옥
            <br />
            전화번호: 010-9074-8755
            <br />
            이 메 일: healicure@gmail.com
            <br />
            <br />
            “회원”은 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
            개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 기타 개인정보침해의 신고,
            상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
            <br />
            <br />
            ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
            <br />
            소관업무: 개인정보 침해사실 신고, 상담 신청
            <br />
            홈페이지: privacy.kisa.or.kr
            <br />
            전 화: (국번없이) 118
            <br />
            주 소: (58324) 전남 나주시 진흥길 9, 3층 개인정보침해신고센터 (빛가람동 301-2)
            <br />
            <br />
            ▶ 개인정보 분쟁조정위원회
            <br />
            소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
            <br />
            홈페이지: www.kopico.go.kr
            <br />
            전화: (국번없이) 1833-6972
            <br />
            주소: (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층
            <br />
            <br />
            ▶ 대검찰청 사이버범죄수사단: 02-3480-3573 (www.spo.go.kr)
            <br />
            <br />
            ▶ 경찰청 사이버안전국: 182 (http://cyberbureau.police.go.kr)
            <br />
          </>
        }
      />
      <PolicyDetailLayout
        title={'개인정보 처리방침의 변경'}
        content={
          <>
            <StyledOLTag>
              <li>
                회사”는 개인정보 처리방침을 변경하는 경우에 “회원”이 그 변경 여부, 변경된 사항의 시행시기와
                변경된 내용을 언제든지 쉽게 알 수 있도록 지속적으로 ‘서비스’를 통하여 공개합니다. 변경된
                처리방침의 내용 중 “회원”에게 불리한 내용이 있는 경우, “회사”는 본 서비스 이용 약관 변경
                절차를 준용하여 최소 14일 전부터 변경사항을 고지합니다.
                <br />
              </li>
              <br />
              <li>
                이전 개인정보 처리방침은 아래에서 확인할 수 있습니다.
                <br />
                공고일자: 2022-10-14
                <br />
                시행일자: 2022-10-14
              </li>
            </StyledOLTag>
          </>
        }
      />
    </Flex>
  );
};

export default PrivacyPolicy;
