import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Radio as ARadio, RadioGroupProps, RadioProps } from 'antd';
import { tuple } from 'antd/lib/_util/type';

const { Group: ARadioGroup, Button: ARadioButton } = ARadio;

export const tYjTypeRadio = tuple('survey', 'survey-onset', 'survey-scale');

export interface IRadioParams extends RadioProps {
  yjTypeRadio?: typeof tYjTypeRadio[number] | undefined;
}
export interface IRadioGroupParams extends RadioGroupProps {}

const handleRadioWithType = ({ yjTypeRadio }: IRadioParams) => {
  switch (yjTypeRadio) {
    case 'survey-scale':
      return css`
        display: inline-block;
        text-align: center;
        width: 3.7rem;
        border: none;
        background: #ffffff;
        color: #1d1c1d;
        border: 1px solid #e5e5e5;
        margin-right: 0.5rem;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          background: #ffffff;
          color: #1d1c1d;
          border: 1px solid #e5e5e5;
        }
        &.ant-radio-wrapper-checked {
          background: #d5e8ff;
          color: #1d1c1d;
          border: 1px solid #c2d5eb;
        }
        > .ant-radio {
          display: none;
        }
      `;
    case 'survey-onset':
      return css`
        display: inline-block;
        text-align: center;
        min-width: 7.5rem;
        border: none;
        background: #ffffff;
        color: #1d1c1d;
        border: 1px solid #e5e5e5;
        margin-right: 0.5rem;

        font-size: 1.2rem;
        line-height: 3rem;
        letter-spacing: -0.01em;
        font-weight: 400;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          background: #ffffff;
          color: #1d1c1d;
          border: 1px solid #e5e5e5;
        }
        &.ant-radio-wrapper-checked {
          background: #d5e8ff;
          color: #1d1c1d;
          border: 1px solid #c2d5eb;
        }
        > .ant-radio {
          display: none;
        }
      `;
    case 'survey':
      return css`
        text-align: center;
        min-width: 12rem;
        border: none;
        background: #ffffff;
        color: #1d1c1d;
        border: 1px solid #e5e5e5;
        margin-right: 0.5rem;
        padding: 0.6875rem 0.5rem;

        font-size: 1.2rem;
        line-height: 3rem;
        letter-spacing: -0.01em;
        font-weight: 400;

        :hover {
          cursor: pointer;
        }

        :active,
        :focus,
        :hover {
          background: #ffffff;
          color: #1d1c1d;
          border: 1px solid #e5e5e5;
        }
        &.ant-radio-wrapper-checked {
          background: #d5e8ff;
          color: #1d1c1d;
          border: 1px solid #c2d5eb;
        }
        > .ant-radio {
          display: none;
        }
      `;
    default:
      return css``;
  }
};

export const StyledRadio = styled(ARadio)<IRadioParams>`
  ${({ yjTypeRadio }) => handleRadioWithType({ yjTypeRadio })}
`;

export const Radio = ({ children, ...rest }: IRadioParams) => <StyledRadio {...rest}>{children}</StyledRadio>;

const StyledRadioGroup = styled(ARadioGroup)<IRadioGroupParams>`
  width: 100%;
  border: 0;
  border-radius: 0.1rem;
`;

export const RadioGroup = ({ children, ...rest }: IRadioGroupParams) => (
  <StyledRadioGroup {...rest}>{children}</StyledRadioGroup>
);

export const RadioButton = ({ children, ...rest }: RadioProps) => (
  <ARadioButton {...rest}>{children}</ARadioButton>
);
