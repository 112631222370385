import '@emotion/react';

export const color = {
  main1: '#BFD4E4',
  main3: '#164ee6',
  main4: '#071640',
  main5: '#ffffff',
  footerButton: '#1D1C1D',
  white: '#ffffff',
  gray1: '#DAE4ED',
  gray2: '#fafafa',
  gray3: '#f0f0f0',
  gray4: '#e0e0e0',
  gray5: '#cccccc',
  gray6: '#b4b4b4',
  gray7: '#888888',
  gray8: '#666666',
  gray9: '#444444',
  gray10: '#222222',
  gray11: '#000000',
  link1: '#e5ebff',
  link2: '#164ee6',
  link3: '#0230b0',
  error1: '#ffebeb',
  error2: '#f35b5b',
  error3: '#dd0e54',
  guide: '#5c7cfa',
  success1: '#d9f9e1',
  success2: '#2fc035',
  success3: '#068f22',
  warning1: '#fff3be',
  warning2: '#f9c025',
  warning3: '#ea8900',
  infoText: '#1D1C1D',
  disabled: '#E5E5E5',
};

const size = {};

const padding = {
  halfPadding: 31,
  entirePadding: 62,
};

export const theme = {
  ...padding,
  ...color,
  ...size,
};

type TTheme = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends TTheme {}
}

export default theme;
