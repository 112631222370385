/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import DaumPostCode from 'react-daum-postcode';

const DaumPost = ({
  setIsOpenAddressModal,
  setAddress,
  setIsVaildSetAddress,
}: {
  setIsOpenAddressModal?: any;
  setAddress?: any;
  setIsVaildSetAddress?: any;
}) => {
  const handleComplete = useCallback((data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    if (setIsOpenAddressModal) {
      setIsOpenAddressModal(false);
    }
    if (setAddress) {
      setAddress(fullAddress);
    }
    if (setIsVaildSetAddress) {
      setIsVaildSetAddress(true);
    }
  }, []);

  return (
    <DaumPostCode
      style={{
        width: '100%',
        height: '445px',
      }}
      onComplete={(data) => handleComplete(data)}
      className="post-code"
    />
  );
};
export default DaumPost;
