import React from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { color } from '../../../../styles/themes';
import { Row as ARow, RowProps } from 'antd';
import { tuple } from 'antd/lib/_util/type';

export const tYzTypeRow = tuple(
  'vertical',
  'additionalGroup',
  'additionalRow',
  'additionalRowInOneField',
  'header',
  'footer-single-button',
  'footer-double-button',
  'bottom',
  'center',
  'content',
  'survey-content',
  'survey-answer',
  'patient-symptom-button',
  'flex',
  'onset',
);

export type TYzTypeRow = (typeof tYzTypeRow)[number] | undefined;

export interface IRowParams extends RowProps {
  yjTypeRow?: TYzTypeRow;
  align?: RowProps['align'];
  flex?: boolean;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
}

const handleRowStyleWithType = ({ yjTypeRow }: IRowParams) => {
  switch (yjTypeRow) {
    case 'onset':
      return css`
        margin-left: 3.4rem;
      `;
    case 'flex':
      return css`
        display: flex;
      `;
    case 'survey-answer':
      return css`
        display: inline-block;
      `;
    case 'patient-symptom-button':
      return css`
        border: 1px solid #afafaf;
        border-radius: 3px;
        display: flex;
        column-gap: 0.5rem;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      `;
    case 'survey-content':
      return css`
        overflow: hidden;
        padding-left: 2.4rem;
        margin-bottom: calc(100vh - 6.4rem - 7rem - 7rem);
      `;
    case 'header':
      return css`
        width: 100%;
        padding: 0 0 1.35rem 2.1rem;
        border-bottom: 1px solid #e5e5e5;
        position: absolute;
        bottom: 0;
      `;
    case 'content':
      return css`
        display: flex;
      `;
    case 'footer-single-button':
      return css`
        border-top: 1px solid #f5f5f5;
        height: 3.6rem;
        padding: 0.4rem 1.2rem 0.6rem 1.2rem;
        margin-left: -1.2rem !important;
        position: fixed;
        bottom: 0px;
        width: 100%;
        background: ${color.white} !important;
        z-index: 991;
      `;
    case 'footer-double-button':
      return css`
        align-content: space-between;
        height: 6.1rem;
        padding-bottom: 0.6rem;
        position: fixed;
        bottom: 0px;
        width: calc(100vw - 2.4rem);
        z-index: 991;
      `;
    case 'bottom':
      return css`
        position: fixed;
        align-items: center;
        padding-right: 5rem;
        height: 7rem;
        bottom: 0px;
        width: 100%;
        display: flex !important;
        z-index: 991;
        border-top: 1px #e5e5e5 solid;
      `;
    case 'center':
      return css`
        margin: 0 auto !important;
      `;
    case 'vertical':
      return css`
        display: block;
      `;
    case 'additionalGroup':
      return css`
        margin-top: 1rem !important;
        margin-bottom: 1.5rem !important;
        padding-top: 1.05rem !important;
        padding-bottom: 1rem !important;
        border-top: 1px solid #8991a3;
        border-bottom: 1px solid #8991a3;
        background: rgba(142, 172, 255, 0.07);
      `;
    case 'additionalRow':
      return css`
        margin-top: 1rem;
      `;
    case 'additionalRowInOneField':
      return css`
        margin-top: 0.25rem;
      `;
    default:
      return css`
        &::before {
          content: none;
        }

        &::after {
          content: none;
        }
      `;
  }
};

const isPropValid = (props: any) => props !== 'yjTypeRow';

const StyledRow = styled(ARow, { shouldForwardProp: isPropValid })<IRowParams>`
  ${({ yjTypeRow }) => handleRowStyleWithType({ yjTypeRow })}
  ${({ flex }) => flex && `display: flex;`}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
`;

export const Row = ({ children, ...rest }: IRowParams) => <StyledRow {...rest}>{children}</StyledRow>;
