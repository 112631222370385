import { API, Auth, graphqlOperation } from 'aws-amplify';
import { CreateHistoryInput, HistoriesByDateQuery, ModelSortDirection } from '../API';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

class HistoryRepository {
  createHistory = async (history: CreateHistoryInput) => {
    const newHistory = API.graphql(
      graphqlOperation(
        mutations.createHistory,
        { input: history },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    );
    return newHistory;
  };

  listHistoriesByDate = async (patientID: string) => {
    // console.log("환자 타임라인 조회");
    const histories = (await API.graphql(
      graphqlOperation(
        queries.historiesByDate,
        {
          patientID: patientID,
          sortDirection: ModelSortDirection.DESC,
        },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: HistoriesByDateQuery };

    return histories.data.historiesByDate;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new HistoryRepository();
