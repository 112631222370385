import { Button, Divider, Flex } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { signOutHandler } from 'utils/util';
import { TypographyText } from 'components/UI/atoms';

export const SideInterface = ({ setIsLoading }) => {
  const navigate = useNavigate();
  return (
    <>
      <Flex marginTop={'1.5rem'} columnGap={8}>
        <Flex width={'50%'} direction={'column'}>
          <Button
            backgroundColor={'transparent'}
            border={'1px solid #D2D2D2'}
            padding={'0.3rem 0.8rem'}
            style={{
              height: '1.7rem',
            }}
            borderRadius={'3px'}
            onClick={() => {
              navigate('/setting');
              sessionStorage.setItem('settingTabIndex', '0');
            }}
          >
            <TypographyText fontSize={'0.8rem'} color="#333333" whiteSpace="pre" fontWeight={400}>
              설정
            </TypographyText>
          </Button>
        </Flex>
        <Flex width={'50%'} direction={'column'}>
          <Button
            backgroundColor={'transparent'}
            border={'1px solid #D2D2D2'}
            padding={'0.3rem 0.8rem'}
            style={{
              height: '1.7rem',
            }}
            borderRadius={'3px'}
            onClick={async () => {
              try {
                await setIsLoading(true);
                await signOutHandler();
              } catch (error) {
                console.log(error);
                setIsLoading(false);
              }
            }}
          >
            <TypographyText fontSize={'0.8rem'} color="#333333" whiteSpace="pre" fontWeight={400}>
              로그아웃
            </TypographyText>
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
