/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { css } from '@emotion/css';
import { useParams, useNavigate } from 'react-router-dom';
import { Table, TableBody, TableHead, TableRow } from '@aws-amplify/ui-react';
import { TableCell } from 'components/UI/atoms/table/TableCell';
import message from 'antd-message';
import _ from 'lodash';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { Clinic } from 'API';
import { useStores } from 'stores/Context';
// import { stepPayApi } from 'api/steppay';
import ClinicRepository from 'repository/ClinicRepository';
import UserRepository from 'repository/UserRepository';
import PatientRepository from 'repository/PatientRepository';
import AdminLayout from 'components/templates/AdminLayout';
import { DateRangePicker } from 'components/UI/molecules';
import {
  // setTempSubscription,
  parseDateString,
  applyComma,
} from 'utils/util';
import { currentEnv } from 'config';
import { Loading } from 'components/UI/molecules';
import { TypographyText as T, Row, Col, Divider, Button } from 'components/UI/atoms';
import { permissionConverter } from 'utils/DataConvertUtil';

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;
  return (
    <text x={x} y={y} dy={-6} fill={stroke} fontSize={12} textAnchor="middle">
      {value}
    </text>
  );
};

const DashboardClinicDetailPage = () => {
  const { clinicId } = useParams();
  const navigate = useNavigate();
  const { clientStore } = useStores();
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
  const [
    isLoading,
    // setIsLoading
  ] = useState<boolean>(false);
  const [calendarMonth, setCalendarMonth] = useState<number>(1);
  // const [refundAmount, setRefundAmount] = useState<number>(0);
  const [dateRange, setDateRange] = useState<any[]>([
    new Date(moment().startOf('day').add(-1, 'M').format('YYYY/MM/DD')),
    new Date(moment().endOf('day').format('YYYY/MM/DD')),
  ]);
  const [stat, setStat] = useState<any>({});
  const [patients, setPatients] = useState<any>({});
  const [members, setMembers] = useState<any>([]);

  const [isStatLoading, setIsStatLoading] = useState<boolean>(false);
  const [clinic, setClinic] = useState<any>({});

  const usagelineGraphData = useMemo(() => {
    if (!_.isEmpty(stat?.resultObj)) {
      const result = _.map(_.values(stat.resultObj), (e) => ({
        name: e.recordDate,
        numberOfKakaoSent: e.numberOfKakaoSent || 0,
        numberOfTreatment: e.numberOfTreatment || 0,
        numberOfTreatmentCompletion:
          (e.numberOfTreatmentCompletion || 0) + (e.numberOfTreatmentCompletionsWithPrescription || 0),
        numberOfTreatmentCompletionWithoutPrescription: e.numberOfTreatmentCompletion || 0,
        numberOfTreatmentCompletionsWithPrescription: e.numberOfTreatmentCompletionsWithPrescription || 0,
      }));
      return result;
    } else {
      return [];
    }
  }, [stat]);
  const labelMinWidth = useMemo(() => 250, []);

  // const isAbleClickActivePlanStatBtn = useMemo(() => {
  //   return (
  //     !_.isEmpty(clinic.subscriptionInfo) && // 구독 정보를 가지고 있음
  //     clinic.isStepPayPlanInfo && // 스탭페이에서 받아온 활성화된 구독 정보임
  //     ![clinic.subscriptionInfo.lastPaymentDate, clinic.subscriptionInfo.nextPaymentDate].includes(
  //       'Invalid date',
  //     )
  //   ); // 결제일시에 문제가 없어야
  // }, [clinic]);

  // const updatePlanCheckFlag = useCallback(
  //   async (clinicId, planCheckPassFlag) => {
  //     try {
  //       await setIsLoading(true);
  //       await clinicStore.updateClinic({
  //         id: clinicId,
  //         planCheckPassFlag: !planCheckPassFlag,
  //       } as UpdateClinicInput);
  //       await getClinicData({ prevClinicData: clinic, getStepPayFlag: false, globalLoadingFlag: false });
  //       message.success(`요금제 사용량 변경에 성공했습니다.`);
  //     } catch (e) {
  //       message.error(`요금제 사용량 변경 과정에 문제가 생겼습니다. ${e}`);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   },
  //   [clinic],
  // );

  interface IUsageStatObj {
    [key: string]: {
      recordDate: string;
      numberOfKakaoSent: number;
      numberOfSurveySentBySelf: number;
      numberOfTreatment: number;
      numberOfTreatmentCompletion: number;
      numberOfTreatmentCompletionsWithPrescription: number;
    };
  }

  const getUsageStatistics = useCallback(
    async ({ clinicID, dateRangeArr }: { clinicID: string; dateRangeArr?: string[] }) => {
      const res = await ClinicRepository.getUsageStatistics({ clinicID, dateRangeArr, limit: 3650 });
      const resultObj: IUsageStatObj = {};
      let totalNumberOfKaKaoSent = 0;
      let totalNumberOfSurveySentBySelf = 0;
      let totalNumberOfTreatment = 0;
      let totalNumberOfTreatmentCompletion = 0;
      let totalNumberOfTreatmentCompletionsWithPrescription = 0;
      _.forEach(res?.items, (e) => {
        totalNumberOfKaKaoSent += e.numberOfKakaoSent;
        totalNumberOfSurveySentBySelf += e.numberOfSurveySentBySelf ?? 0;
        totalNumberOfTreatment += e.numberOfTreatment;
        totalNumberOfTreatmentCompletion += e.numberOfTreatmentCompletion;
        totalNumberOfTreatmentCompletionsWithPrescription += e.numberOfTreatmentCompletionsWithPrescription;
        resultObj[e.recordDate] = {
          recordDate: e.recordDate,
          numberOfKakaoSent: e.numberOfKakaoSent,
          numberOfSurveySentBySelf: e.numberOfSurveySentBySelf ?? 0,
          numberOfTreatment: e.numberOfTreatment,
          numberOfTreatmentCompletion: e.numberOfTreatmentCompletion,
          numberOfTreatmentCompletionsWithPrescription: e.numberOfTreatmentCompletionsWithPrescription,
        };
      });
      if (res?.items?.length < 30) {
        // 캘린더 시작일/말일 데이터 세팅 - 그래프 ui 개선
        const sDt = parseDateString(dateRangeArr[0], 'YYYYMMDD', 'YYYYMMDD');
        const eDt = parseDateString(dateRangeArr[1], 'YYYYMMDD', 'YYYYMMDD');
        _.forEach([sDt, eDt], (e) => {
          if (_.isEmpty(resultObj[e])) {
            resultObj[e] = {
              recordDate: e,
              numberOfKakaoSent: 0,
              numberOfTreatment: 0,
              numberOfSurveySentBySelf: 0,
              numberOfTreatmentCompletion: 0,
              numberOfTreatmentCompletionsWithPrescription: 0,
            };
          }
        });
      }

      return {
        totalNumberOfKaKaoSent,
        totalNumberOfSurveySentBySelf,
        totalNumberOfTreatment,
        totalNumberOfTreatmentCompletion,
        totalNumberOfTreatmentCompletionsWithPrescription,
        resultObj,
      };
    },
    [],
  );

  interface IPaitentRegistCntObj {
    [key: string]: {
      recordDate: string;
      count: number;
    };
  }

  const getPatientStatistics = useCallback(
    async ({ clinicID, dateRangeArr }: { clinicID: string; dateRangeArr?: string[] }) => {
      const res = await PatientRepository.patientsByClinic({
        clinicID,
        createdAtArr: [dateRangeArr[0], dateRangeArr[1]],
        limit: 5000,
      });
      const registPatientCntObj: IPaitentRegistCntObj = {};
      _.forEach(res?.items, (e) => {
        const registDt = moment(e.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YYYYMMDD');
        if (_.isEmpty(registPatientCntObj[registDt])) {
          registPatientCntObj[registDt] = { recordDate: registDt, count: 1 };
        } else {
          registPatientCntObj[registDt] = {
            ...registPatientCntObj[registDt],
            count: registPatientCntObj[registDt].count + 1,
          };
        }
      });
      if (res?.items?.length < 30) {
        // 캘린더 시작일/말일 데이터 세팅 - 그래프 ui 개선
        const sDt = parseDateString(dateRangeArr[0], 'YYYYMMDD', 'YYYYMMDD');
        const eDt = parseDateString(dateRangeArr[1], 'YYYYMMDD', 'YYYYMMDD');
        _.forEach([sDt, eDt], (e) => {
          if (_.isEmpty(registPatientCntObj[e])) {
            registPatientCntObj[e] = { recordDate: e, count: 0 };
          }
        });
      }

      const registPatientCnt = _.values(_.sortBy(registPatientCntObj, (e: any) => e.recordDate));
      return {
        registPatientCnt,
      };
    },
    [],
  );

  const getStatData = useCallback(async ({ dateRange, inputDateFormat = 'YYYYMMDD' }) => {
    try {
      await setIsStatLoading(true);
      const period = [dateRange[0], dateRange[1]];
      const statRes = await getUsageStatistics({
        clinicID: clinicId,
        dateRangeArr: [
          parseDateString(period[0], inputDateFormat, 'YYYYMMDD'),
          parseDateString(period[1], inputDateFormat, 'YYYYMMDD'),
        ],
      });
      setStat(statRes);
      const patientsRes = await getPatientStatistics({
        clinicID: clinicId,
        dateRangeArr: [
          parseDateString(period[0], inputDateFormat, 'YYYY-MM-DDTHH:mm:ss'),
          moment(parseDateString(period[1], inputDateFormat, 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss')
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
        ],
      });
      setPatients(patientsRes);
    } catch (e) {
      message.error(`환자 데이터를 가져오는데 문제가 생겼습니다. ${e}`);
      console.log(e);
    } finally {
      await setIsStatLoading(false);
    }
  }, []);

  const statInfoDataArr = useMemo(() => {
    const d: any = stat ?? {};
    if (_.isEmpty(d)) {
      return [];
    } else {
      return [
        { label: '카카오톡 전송 수', value: `${applyComma(d.totalNumberOfKaKaoSent)}건` },
        { label: 'QR 셀프 설문 수', value: `${applyComma(d.totalNumberOfSurveySentBySelf)}건` },
        { label: '진료 수', value: `${applyComma(d.totalNumberOfTreatment)}건` },
        {
          label: '진료 완료 수',
          value: `${applyComma(
            d.totalNumberOfTreatmentCompletionsWithPrescription + d.totalNumberOfTreatmentCompletion,
          )}건`,
        },
        {
          label: '처방 포함 진료 완료 수',
          value: `${applyComma(d.totalNumberOfTreatmentCompletionsWithPrescription)}건`,
        },
        { label: '처방 불포함 진료 완료 수', value: `${applyComma(d.totalNumberOfTreatmentCompletion)}건` },
      ];
    }
  }, [stat]);

  const patientInfoDataArr = useMemo(() => {
    const d: any = patients ?? {};
    if (_.isEmpty(d)) {
      return [];
    } else {
      return [
        {
          label: '신규 등록 환자 수',
          value: `${applyComma(_.reduce(d?.registPatientCnt ?? [], (a, c) => a + c.count, 0))}건`,
        },
      ];
    }
  }, [patients]);

  const basicInfoDataArr = useMemo(() => {
    const d: any = clinic ?? {};
    if (_.isEmpty(d)) {
      return [];
    } else {
      return [
        { label: '한의원 이메일', value: d.clinicEmail },
        {
          label: '한의원 전화번호',
          value: d.phone ? d.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : '',
        },
        { label: '환자 수', value: `${d.numberOfPatients || 0}명` },
        { label: '스탭 수', value: `${d.numberOfSteps || 0}명` },
        {
          label: '한의원 생성일시',
          value: d.createdAt
            ? moment(d.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YY/MM/DD HH:mm:ss')
            : '',
        },
      ];
    }
  }, [clinic]);

  //   const planInfoDataArr = useMemo(() => {
  //     const d: any = clinic ?? {};
  //     if (_.isEmpty(d)) {
  //       return [];
  //     } else {
  //       const isPlan = !_.isEmpty(d.subscriptionInfo);

  //       return [
  //         { label: '스탭페이 고객 ID', value: d.stepPayID },
  //         { label: '스탭페이 고객 식별자', value: d.stepPayUserInfo?.username?.split('@')?.[0] },
  //         {
  //           label: '현재 요금제',
  //           value: isPlan ? d.subscriptionInfo?.price?.planName : '스타터',
  //         },
  //         {
  //           label: '요금제 활성화 여부',
  //           value: d.isStepPayPlanInfo ? 'Yes' : d.isYejinPlanInfo ? '스탭페이 장애로 알 수 없음' : 'No',
  //         },
  //         {
  //           label: '결제 수단',
  //           value: isPlan ? d.subscriptionInfo?.paymentMethod?.paymentInfo : '-',
  //         },
  //         {
  //           label: '결제 당시 요금정보',
  //           value: isPlan
  //             ? `${applyComma(d.subscriptionInfo?.price?.price)}원/${d.subscriptionInfo?.interval}`
  //             : '-',
  //         },
  //         {
  //           label: '첫 결제일시',
  //           value: isPlan
  //             ? moment(d.subscriptionInfo?.startDate, 'YYYY-MM-DDTHH:mm:ss.sss').format('YY/MM/DD HH:mm:ss')
  //             : '-',
  //         },
  //         {
  //           label: '최근 결제일시',
  //           value: isPlan
  //             ? moment(d.subscriptionInfo?.lastPaymentDate, 'YYYY-MM-DDTHH:mm:ss.sss').format(
  //                 'YY/MM/DD HH:mm:ss',
  //               )
  //             : '-',
  //         },
  //         {
  //           label: '다음 결제일시',
  //           value: isPlan
  //             ? moment(d.subscriptionInfo?.nextPaymentDate, 'YYYY-MM-DDTHH:mm:ss.sss').format(
  //                 'YY/MM/DD HH:mm:ss',
  //               )
  //             : '-',
  //         },
  //         {
  //           label: `최초 다음 결제 시점
  // (nextPaymentDate는
  // 변경될 수 있기 때문에
  // 변경 전 데이터를 저장)`,
  //           value: isPlan
  //             ? moment(d.subscriptionInfo?.originNextPaymentDate, 'YYYY-MM-DDTHH:mm:ss.sss').format(
  //                 'YY/MM/DD HH:mm:ss',
  //               )
  //             : '-',
  //         },
  //         {
  //           label: '요금제 사용량 제한 해제 여부',
  //           value: d.planCheckPassFlag
  //             ? `Yes
  // (*요금제 관련 신속한 문제 해결이 필요한 상태, 박주원에게 문의)`
  //             : 'No',
  //         },
  //         {
  //           label: '구독 ID (환불에 필요할 수 있음)',
  //           value: isPlan ? d.subscriptionInfo?.id : '-',
  //         },
  //         {
  //           label: '주문 ID (환불에 필요할 수 있음)',
  //           value: isPlan ? d.subscriptionInfo?.parentOrder?.orderId : '-',
  //         },
  //       ];
  //     }
  //   }, [clinic]);

  const getMembers = useCallback(async (clinicId: string) => {
    try {
      const member = await UserRepository.listRolesByClinicId(clinicId);
      console.log(member);
      setMembers(member);
    } catch (e) {
      message.error(`구성원 정보를 가져오는데 문제가 생겼습니다. ${e}`);
      console.log(e);
    }
  }, []);

  const getClinicData = useCallback(
    async ({
      getStepPayFlag = true,
      globalLoadingFlag = true,
      prevClinicData = {},
    }: {
      getStepPayFlag?: boolean;
      globalLoadingFlag?: boolean;
      prevClinicData?: any;
    }) => {
      try {
        if (globalLoadingFlag) await setIsGlobalLoading(true);
        const subs = {
          local: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
          develop: ['borooboroo625@gmail.com'],
          staging: ['borooboroo625@gmail.com'],
          production: ['borooboroo625@gmail.com'],
        };
        if (!subs[currentEnv]?.includes(clientStore.metaData?.userRole?.email)) {
          navigate('/patient');
        }
        const clinicRes: Clinic = (await ClinicRepository.getClinic(clinicId)).getClinic;
        const clinicData: any = { ...prevClinicData, ...clinicRes };
        setClinic({ ...{}, ...(clinicData || {}) });
      } catch (e) {
        message.error(`한의원 데이터를 가져오는데 문제가 생겼습니다. ${JSON.stringify(e, null, 2)}`);
        console.log(e);
      } finally {
        if (globalLoadingFlag) setIsGlobalLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    getClinicData({});
    getMembers(clinicId);
  }, []);

  useEffect(() => {
    getStatData({ dateRange });
  }, [dateRange]);

  return (
    <AdminLayout
      title={`${clinic?.clinicName ?? ``}`}
      content={
        <>
          {isGlobalLoading ? (
            <Loading />
          ) : (
            <>
              {isLoading && <Loading />}
              <Row
                style={{
                  display: 'flex',
                  paddingBottom: '7rem',
                  columnGap: '4rem',
                }}
              >
                <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '2.5rem' }}>
                  <Col>
                    <Row>
                      <T
                        style={{ fontSize: '1.8rem', lineHeight: '1.8rem', fontWeight: 700 }}
                      >{`기본 정보`}</T>
                    </Row>
                    <Divider marginRem={0.5} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                    <Row style={{ width: 'fit-content' }}>
                      {basicInfoDataArr.map((e, i) => (
                        <Row key={`clinic_basic_info_${i}`} style={{ display: 'flex' }}>
                          <Col style={{ minWidth: labelMinWidth + 'px' }}>
                            <T style={{ whiteSpace: 'pre-wrap', fontWeight: 600 }}>{e.label}</T>
                          </Col>
                          <Col>
                            <T>{e.value}</T>
                          </Col>
                        </Row>
                      ))}
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <T
                        style={{ fontSize: '1.8rem', lineHeight: '1.8rem', fontWeight: 700 }}
                      >{`구성원 정보`}</T>
                    </Row>
                    <Divider marginRem={0.5} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                    <Row>
                      <Table>
                        <TableHead>
                          <TableRow style={{ background: '#f2f2f2', width: '100%' }}>
                            <TableCell
                              as="th"
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '2rem',
                              }}
                            >
                              <Row
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              >
                                <T yjTypeTypographyText="symptom-table-head">이름</T>
                              </Row>
                            </TableCell>
                            <TableCell
                              as="th"
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '2rem',
                              }}
                            >
                              <Row
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              >
                                <T yjTypeTypographyText="symptom-table-head">이메일</T>
                              </Row>
                            </TableCell>
                            <TableCell
                              as="th"
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '2rem',
                              }}
                            >
                              <Row
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              >
                                <T yjTypeTypographyText="symptom-table-head">역할</T>
                              </Row>
                            </TableCell>
                            <TableCell
                              as="th"
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '2rem',
                              }}
                            >
                              <Row
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              >
                                <T yjTypeTypographyText="symptom-table-head">{'면허번호'}</T>
                              </Row>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {members?.map((e) => (
                            <TableRow>
                              <TableCell
                                style={{
                                  padding: '0.45rem 0',
                                }}
                              >
                                <Row
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                  <T yjTypeTypographyText="symptom-table-head">{e?.user?.username}</T>
                                </Row>
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '0.45rem 0',
                                }}
                              >
                                <Row
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                  <T yjTypeTypographyText="symptom-table-head">{e?.user?.email}</T>
                                </Row>
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '0.45rem 0',
                                }}
                              >
                                <Row
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                  <T yjTypeTypographyText="symptom-table-head">
                                    {permissionConverter(e?.roleName)}
                                  </T>
                                </Row>
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: '0.45rem 0',
                                }}
                              >
                                <Row
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                  <T yjTypeTypographyText="symptom-table-head">{e?.user?.licenseNumber}</T>
                                </Row>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Row>
                  </Col>
                  <Col>
                    {/* <Row style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <Col style={{ minWidth: labelMinWidth + 'px' }}>
                        <T
                          style={{ fontSize: '1.8rem', lineHeight: '1.8rem', fontWeight: 700 }}
                        >{`요금제 정보`}</T>
                      </Col>
                      <Col flexFlag style={{ flexDirection: 'column', rowGap: '0.4rem' }}>
                        <Col
                          style={{
                            width: 'fit-content',
                            padding: '0.25rem 0.75rem',
                            borderRadius: '3px',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            whiteSpace: 'pre',
                            background: '#EFA548',
                            fontSize: '0.8rem',
                            lineHeight: '0.8rem',
                            color: '#ffffff',
                          }}
                          onClick={() => {
                            updatePlanCheckFlag(clinicId, clinic?.planCheckPassFlag);
                          }}
                        >{`요금제 사용량 제한 ${clinic?.planCheckPassFlag ? `설정하기` : `해제하기`}`}</Col>
                        <Col
                          style={{
                            width: 'fit-content',
                            padding: '0.25rem 0.75rem',
                            borderRadius: '3px',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            whiteSpace: 'pre',
                            background: '#EFA548',
                            fontSize: '0.8rem',
                            lineHeight: '0.8rem',
                            color: '#ffffff',
                          }}
                          onClick={() => {
                            updatePromotion({
                              clinicId,
                              promotionType: '2023academyMember',
                              activeFlag: !clinicPromotionData['2023academyMember']?.activeFlag,
                            });
                          }}
                        >{`2023 학회 정회원 프로모션 ${
                          clinicPromotionData['2023academyMember']?.activeFlag ? '해제' : '적용'
                        }하기`}</Col>
                      </Col>
                    </Row> */}
                    {/* <Divider marginRem={0.5} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                    <Row style={{ width: 'fit-content' }}>
                      {planInfoDataArr.map((e, i) => (
                        <Row key={`clinic_plan_info_${i}`} style={{ display: 'flex' }}>
                          <Col style={{ minWidth: labelMinWidth + 'px' }}>
                            <T style={{ whiteSpace: 'pre-wrap', fontWeight: 600 }}>{e.label}</T>
                          </Col>
                          <Col>
                            <T style={{ whiteSpace: 'pre-wrap' }}>{e.value}</T>
                          </Col>
                        </Row>
                      ))}
                      <Row key={`clinic_plan_info_refund`} style={{ display: 'flex', alignItems: 'center' }}>
                        <Col style={{ minWidth: labelMinWidth + 'px' }}>
                          <T style={{ whiteSpace: 'pre-wrap', fontWeight: 600 }}>{`환불`}</T>
                        </Col>
                        <Col style={{ display: 'flex', columnGap: '0.1rem' }}>
                          <input
                            type="number"
                            step={1}
                            min={0}
                            className={css`
                              height: 1.5rem;
                              width: 6rem;
                              border-radius: 3px;
                              border: 1px solid #afafaf;
                              text-align: center;
                              font-size: 1rem;
                              letter-spacing: 0.05em;
                            `}
                            value={refundAmount}
                            onChange={(e: any) => {
                              const v = Number(e.target.value);
                              setRefundAmount(v);
                            }}
                          />
                          <Row
                            style={{
                              width: 'fit-content',
                              height: '1.5rem',
                              padding: '0 0.75rem',
                              borderRadius: '3px',
                              cursor:
                                clinic.isStepPayPlanInfo && !_.isEmpty(clinic?.subscriptionInfo)
                                  ? 'pointer'
                                  : 'cursor',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              whiteSpace: 'pre',
                              background:
                                clinic.isStepPayPlanInfo && !_.isEmpty(clinic?.subscriptionInfo)
                                  ? '#EFA548'
                                  : '#e5e5e5',
                              fontSize: '0.8rem',
                              lineHeight: '0.8rem',
                              color: '#ffffff',
                            }}
                            onClick={async () => {
                              try {
                                if (clinic.isStepPayPlanInfo && !_.isEmpty(clinic?.subscriptionInfo)) {
                                  const res = await stepPayApi({
                                    method: 'PATCH',
                                    uri: `/steppay/api/v1/orders/${clinic?.subscriptionInfo?.parentOrder?.orderId}/cancel`,
                                    body: {
                                      requestPrice:
                                        typeof refundAmount === 'string'
                                          ? parseInt(refundAmount)
                                          : refundAmount,
                                    },
                                  });
                                  console.log(res);
                                  if (res?.errorCode) {
                                    throw JSON.stringify(res);
                                  }
                                  message.success(`${refundAmount}원 환불에 성공했습니다.`);
                                }
                              } catch (e) {
                                message.error(`환불 과정에 문제가 발생했습니다. ${e}`, 8000);
                              }
                            }}
                          >{`환불 하기`}</Row>
                        </Col>
                      </Row>
                    </Row> */}
                  </Col>
                </Row>
                <Row>
                  <Row style={{ display: 'flex', columnGap: '0.5rem', alignItems: 'flex-end' }}>
                    <Col>
                      <T
                        style={{
                          fontSize: '1.8rem',
                          lineHeight: '1.8rem',
                          fontWeight: 700,
                          whiteSpace: 'pre',
                        }}
                      >{`통계`}</T>
                    </Col>
                    <Col>
                      <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
                    </Col>
                    <Col style={{ display: 'flex', columnGap: '0.1rem' }}>
                      <Col>
                        <input
                          type="number"
                          step={1}
                          min={1}
                          className={css`
                            width: 3.2rem;
                            height: 2.1rem;
                            border-radius: 3px;
                            border: 1px solid #afafaf;
                            text-align: center;
                            font-size: 1rem;
                            letter-spacing: 0.05em;
                          `}
                          value={calendarMonth}
                          onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                              setDateRange([
                                new Date(
                                  moment()
                                    .startOf('day')
                                    .add(-1 * calendarMonth, 'M')
                                    .format('YYYY/MM/DD'),
                                ),
                                new Date(moment().endOf('day').format('YYYY/MM/DD')),
                              ]);
                            }
                          }}
                          onChange={(e: any) => {
                            const v = Number(e.target.value);
                            setCalendarMonth(v);
                          }}
                        />
                      </Col>
                      <Col
                        style={{
                          height: '2.1rem',
                          padding: '0 0.75rem',
                          borderRadius: '3px',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          whiteSpace: 'pre',
                          background: '#EFA548',
                          color: '#ffffff',
                          fontSize: '0.8rem',
                          lineHeight: '0.8rem',
                        }}
                        onClick={() => {
                          setDateRange([
                            new Date(
                              moment()
                                .startOf('day')
                                .add(-1 * calendarMonth, 'M')
                                .format('YYYY/MM/DD'),
                            ),
                            new Date(moment().endOf('day').format('YYYY/MM/DD')),
                          ]);
                        }}
                      >{`개월 전`}</Col>
                    </Col>
                    {/* <Col
                      style={{
                        height: '2.1rem',
                        padding: '0 0.75rem',
                        borderRadius: '3px',
                        cursor: isAbleClickActivePlanStatBtn ? 'pointer' : 'default',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        whiteSpace: 'pre',
                        background: isAbleClickActivePlanStatBtn ? '#EFA548' : '#e5e5e5',
                        color: '#ffffff',
                        fontSize: '0.8rem',
                        lineHeight: '0.8rem',
                      }}
                      onClick={() => {
                        if (isAbleClickActivePlanStatBtn) {
                          setDateRange([
                            moment(clinic.subscriptionInfo.lastPaymentDate, 'YYYY-MM-DDTHH:mm:ss').format(
                              'YYYY/MM/DD',
                            ),
                            moment(clinic.subscriptionInfo.nextPaymentDate, 'YYYY-MM-DDTHH:mm:ss').format(
                              'YYYY/MM/DD',
                            ),
                          ]);
                        }
                      }}
                    >{`사용 중인 요금제 과금 기간으로 설정`}</Col> */}
                  </Row>
                  <Divider marginRem={0.5} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                  <Row>
                    {isStatLoading ? (
                      <></>
                    ) : (
                      <>
                        {statInfoDataArr.map((e, i) => (
                          <Row key={`clinic_plan_info_${i}`} style={{ display: 'flex' }}>
                            <Col style={{ minWidth: labelMinWidth + 'px' }}>
                              <T style={{ whiteSpace: 'pre-wrap', fontWeight: 600 }}>{e.label}</T>
                            </Col>
                            <Col>
                              <T>{e.value}</T>
                            </Col>
                          </Row>
                        ))}
                        {patientInfoDataArr.map((e, i) => (
                          <Row key={`patient_info_${i}`} style={{ display: 'flex' }}>
                            <Col style={{ minWidth: labelMinWidth + 'px' }}>
                              <T style={{ whiteSpace: 'pre-wrap', fontWeight: 600 }}>{e.label}</T>
                            </Col>
                            <Col>
                              <T>{e.value}</T>
                            </Col>
                          </Row>
                        ))}
                        <Divider marginRem={0.25} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                        <Row>
                          <T
                            style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 700 }}
                          >{`요금제 사용량 관련`}</T>
                        </Row>
                        <ResponsiveContainer height={200}>
                          <LineChart data={usagelineGraphData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" interval={`preserveStartEnd`} />
                            <YAxis orientation="right" allowDecimals={false} domain={[0, 'auto']} />
                            <Tooltip />
                            <Legend />
                            <Line
                              // @ts-ignore
                              label={(props) => <CustomizedLabel {...props} stroke={'rgb(57, 87, 111)'} />}
                              type="monotone"
                              dataKey="numberOfTreatment"
                              stroke="rgb(57, 87, 111)"
                              name="진료 수"
                            />
                            <Line
                              // @ts-ignore
                              label={(props) => <CustomizedLabel {...props} stroke={'#EFA548'} />}
                              type="monotone"
                              dataKey="numberOfKakaoSent"
                              stroke="#EFA548"
                              name="카카오톡 전송 수"
                            />
                            <Line
                              // @ts-ignore
                              label={(props) => <CustomizedLabel {...props} stroke={'#1D1C1D'} />}
                              type="monotone"
                              dataKey="numberOfSurveySentBySelf"
                              stroke="#1D1C1D"
                              name="QR 셀프 설문 수"
                            />
                          </LineChart>
                        </ResponsiveContainer>
                        <Divider marginRem={0.25} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                        <Row>
                          <T
                            style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 700 }}
                          >{`진료 관련`}</T>
                        </Row>
                        <ResponsiveContainer height={200}>
                          <LineChart data={usagelineGraphData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" interval={`preserveStartEnd`} />
                            <YAxis orientation="right" allowDecimals={false} domain={[0, 'auto']} />
                            <Tooltip />
                            <Legend />
                            <Line
                              // @ts-ignore
                              label={(props) => <CustomizedLabel {...props} stroke={'rgb(57, 87, 111)'} />}
                              type="monotone"
                              dataKey="numberOfTreatment"
                              stroke="rgb(57, 87, 111)"
                              name="진료 시작"
                            />
                            <Line
                              // @ts-ignore
                              label={(props) => <CustomizedLabel {...props} stroke={'#EFA548'} />}
                              type="monotone"
                              dataKey="numberOfTreatmentCompletion"
                              stroke="#EFA548"
                              name="진료 완료"
                            />
                            <Line
                              // @ts-ignore
                              label={(props) => <CustomizedLabel {...props} stroke={'green'} />}
                              type="monotone"
                              dataKey="numberOfTreatmentCompletionWithoutPrescription"
                              stroke="green"
                              name="처방포함 진료 완료"
                            />
                            <Line
                              // @ts-ignore
                              label={(props) => <CustomizedLabel {...props} stroke={'#DF635C'} />}
                              type="monotone"
                              dataKey="numberOfTreatmentCompletionsWithPrescription"
                              stroke="#DF635C"
                              name="처방없이 진료 완료"
                            />
                          </LineChart>
                        </ResponsiveContainer>
                        <Divider marginRem={0.25} yjTypeDivider="TRANSPARENT_LITTLE_SPACE" />
                        <Row>
                          <T
                            style={{ fontSize: '1rem', lineHeight: '1rem', fontWeight: 700 }}
                          >{`신규 등록 환자 추세`}</T>
                        </Row>
                        <ResponsiveContainer height={200}>
                          <LineChart data={patients?.registPatientCnt ?? []}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="recordDate" interval={`preserveStartEnd`} />
                            <YAxis orientation="right" allowDecimals={false} domain={[0, 'auto']} />
                            <Tooltip />
                            <Legend />
                            <Line
                              // @ts-ignore
                              label={(props) => <CustomizedLabel {...props} stroke={'rgb(57, 87, 111)'} />}
                              type="monotone"
                              dataKey="count"
                              stroke="rgb(57, 87, 111)"
                              name="신규 환자 수"
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </>
                    )}
                  </Row>
                </Row>
              </Row>
            </>
          )}
        </>
      }
      sideType={0}
      footerButtons={
        <>
          <Col yjTypeCol="footer-prev-button-col">
            <Button onClick={() => navigate(-1)} yjTypeButton="footer-white-button">
              뒤로
            </Button>
          </Col>
        </>
      }
    />
  );
};

export default observer(DashboardClinicDetailPage);
