/* eslint-disable eqeqeq */
import React, { ReactElement, useState, useCallback, useEffect } from 'react';

import { Row, TypographyText } from 'components/UI/atoms';
import { codeMap } from 'utils/code';

function NumberInput({
  form,
  k,
  recentChangedField,
}: {
  form: any;
  k: string;
  recentChangedField: React.MutableRefObject<any>;
}): ReactElement {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const fv = form.getFieldValue(k);
  const { postfix, defaultValue } = codeMap.get(k);

  useEffect(() => {
    if (fv == undefined) {
      form.setFieldsValue({ [k]: defaultValue });
      forceUpdate();
    }
  });

  return (
    <Row yjTypeRow="survey-answer" style={{ display: 'flex', alignItems: 'center', columnGap: '0.75rem' }}>
      <input
        autoComplete="off"
        style={{
          outline: 'none',
          width: `4rem`,
          height: '3rem',
          border: '1px solid #afafaf',
          borderRadius: '3px',
          paddingInline: '0.7rem',
        }}
        value={fv ?? defaultValue}
        min={1}
        type={'number'}
        onChange={(e) => {
          form.setFieldsValue({ [k]: e.target.value }); // set current value
          recentChangedField.current = k;
          forceUpdate();
        }}
      />
      {postfix && <TypographyText>{postfix}</TypographyText>}
    </Row>
  );
}

export default NumberInput;
