/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import React from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';

import _ from 'lodash';
import moment from 'moment';
import moment_timezone from 'moment-timezone';

import { isEnableTillStaging } from 'config';
import { activityMessageMap, palgangLabelSet } from 'constant';
import { codeMap } from 'utils/code';
import { convertOnsetValueToString } from 'utils/DataConvertUtil';
import { DIAGNOSIS } from 'utils/SymptomCode';

interface IGetClinicData {
  [promotionCode: string]: {
    activeFlag: boolean;
    applyPeriod: string[];
  };
}
/**
 * 현재 클리닉에 적용된 프로모션 중에서 유효한 것이 있는지 확인하는 함수
 * @param {IGetClinicData} clinicPromotionData 클리닉 프로모션 데이터, clinic.promotion 필드
 * @param {string[]} targetPromotions 적용 대상 프로모션 코드 목록
 */
export const checkPromotion = ({
  clinicPromotionData,
  targetPromotions,
}: {
  clinicPromotionData: IGetClinicData;
  targetPromotions: string[];
}): boolean => {
  const today = moment();
  let result: boolean = false;

  _.forEach(targetPromotions, (promotionCode) => {
    const { activeFlag: flag, applyPeriod: period } = clinicPromotionData[promotionCode] ?? {};
    if (flag) {
      const [startDt, endDt] = period;
      const startDtMoment = moment(startDt, 'YYYYMMDD');
      const endDtMoment = moment(endDt, 'YYYYMMDD');
      if (today.isBetween(startDtMoment, endDtMoment, 'day', '[]')) {
        result = true;
        return false; // break
      }
    }
  });
  return result;
};

export const licenseValidationCheck = (licenseNumber: string) => {
  const req = /([0-9]+){1,5}$/;
  const reqTestResult = req.test(licenseNumber);
  if (!reqTestResult) return false;
  else {
    const parsedLicenseNumber = parseInt(licenseNumber);
    return parsedLicenseNumber >= 0 && parsedLicenseNumber < 100000;
  }
};

export const emailValidationCheck = (email: string) => {
  const req = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  return req.test(email);
};

export const passwordValidationCheck = (password: string) => {
  const req = /^(?=.*?[a-z])(?=.*\d)[A-Za-z\d₩`~!@#$%^&*()-_=+]{10,}$/;
  return req.test(password);
};

/**
 * 핸드폰 전화번호 유효성 체크
 * @param phone 전화번호 입력값
 * @returns 유효성 검사 통과 여부(boolean)
 */
export const phoneValidationCheck = (phone: string) => {
  const req = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
  return req.test(phone);
};

/**
 * 일반 유선 전화번호 유효성 체크
 * @param phone 전화번호 입력값
 * @returns 유효성 검사 통과 여부(boolean)
 */
export const cordedPhoneValidationCheck = (phone: string) => {
  const req = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
  return req.test(phone);
};

/**
 * 일반 유선 + 핸드폰 전화번호 유효성 동시 체크 (둘 중 하나만 통과해도 검사 통과)
 * @param phone 전화번호 입력값
 * @returns 유효성 검사 통과 여부(boolean)
 */
export const allCasePhoneValidationCheck = (phone: string) => {
  return phoneValidationCheck(phone) || cordedPhoneValidationCheck(phone);
};

export const getFeedbackTime = (day: number) => {
  const m = moment_timezone().tz('Asia/Seoul').add(day, 'days');
  return {
    feedbackSchedule_GSI_PK: m.format('YYYY-MM-DD') + '@Seoul',
    feedbackSendTime: m.toISOString(),
  };
};

/**
 * input moment 값과 현재날짜의 차이를 산출하고, 처방전 통계에 쓰일 한달치 기간을 반환하는 함수
 */
export const getMonthPeriod = ({
  inputDate,
  inputDateFormat,
  outputDateFormat = 'YYYYMMDD',
}: {
  inputDate: string;
  inputDateFormat: string;
  outputDateFormat?: string;
}) => {
  const currentDt = moment().startOf('day');
  const targetDt = moment(inputDate, inputDateFormat).startOf('day');
  const monthDiff = currentDt.diff(targetDt, 'months');
  const startDt = targetDt.add(monthDiff, 'M').format(outputDateFormat);
  const endDt = targetDt.add(1, 'M').format(outputDateFormat);
  return [startDt, endDt];
};

export const parseDateString = (date: string, inputFormat: string, outFormat: string) => {
  return moment_timezone(date, inputFormat).format(outFormat);
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

type TGraphQlReturn = {
  items: any[];
  nextToken: string;
};

/**
 * 원하는 limit 이상의 데이터를 가져올 떄 까지 or nextToken이 없을때 까지
 * GraphQL API를 반복호출하는 함수
 * @param {number} limit 원하는 데이터 수, default = 30
 * @param {string} nextToken
 * @param {string} targetGraphqlListQuery API 요청에 쓰일 GraphQL 쿼리
 * @param {string} targetGraphqlListFunctionDataFieldName 호출 응답 값에서 items, nextToken 값이 담겨있는 필드명, 모르겠다면 GraphQL 쿼리 명과 동일함
 * @param {any} targetGraphqlListQueryParam API 호출에 쓰일 파라미터 (단, targetGraphqlListQueryParam내 limit와 nextToken은 해당 함수가 받는 limit와 nextToken으로 덮어씌워짐)
 * @returns {TGraphQlReturn} items: 데이터가 담겨 있는 배열, nextToken: 전달받은 param과 쿼리로 API 호출을 limit이상으로 데이터를 가져온 뒤에도 db에 아직 탐색할 데이터가 남은 경우 nextToken을 반환함. 이후 동일한 조건의 탐색에서 이 때 전달받은 nextToken을 사용하면 잔여 데이터들을 탐색함
 */
export const repeatCallingListApiToLimit = async ({
  limit = 30, // 원하는 최종 데이터 수 (조금 넘을 수 있음)
  queryLimitBeforeFilter = 100, // 필터링 전에 가져올 데이터 수, DDB는 데이터를 {queryLimitBeforeFilter}만큼 일단 가져오고 필터링을 한다. default = 1000
  nextToken,
  targetGraphqlListQuery,
  targetGraphqlListFunctionDataFieldName,
  targetGraphqlListQueryParam = {},
}: {
  limit?: number;
  queryLimitBeforeFilter?: number;
  targetGraphqlListQuery: string;
  targetGraphqlListFunctionDataFieldName?: string;
  nextToken?: string;
  targetGraphqlListQueryParam?: any;
}): Promise<TGraphQlReturn> => {
  try {
    if (limit < 1) throw new Error('limit는 양수 값을 넣어야 함');
    const resultArr = [];
    let nextTokenTemp = nextToken;
    let callingCount = 1;
    while (resultArr.length < limit && (typeof nextTokenTemp === 'string' || nextTokenTemp === undefined)) {
      // 호출된 목록 데이터들의 수가 원하는 limit 값보다 낮으면서 nextToken이 정의되지않거나(=1페이지 호출), 문자열인 경우(=탐색할 다음 데이터가 존재)에만 목록 API 반복 호출
      const response = (await API.graphql(
        graphqlOperation(
          targetGraphqlListQuery,
          {
            ...targetGraphqlListQueryParam,
            limit: queryLimitBeforeFilter,
            nextToken: nextTokenTemp,
            // 제발 여기에 특정 쿼리를 위한 조건을 넣지 말아 주세요. 원하는 limit만큼의 데이터를 가져오는 함수일 뿐 입니다.
          },
          (await Auth.currentSession()).getIdToken().getJwtToken(),
        ),
      )) as any;
      const d = response?.data?.[targetGraphqlListFunctionDataFieldName];
      if (!d) throw new Error('잘못된 설정으로 API 응답 값을 가져올 수 없음');
      resultArr.push(...d?.items);
      nextTokenTemp = d?.nextToken;
      if (isEnableTillStaging) console.log(`${callingCount}번째 호출`, d?.items);
      callingCount++;
    }
    return {
      items: resultArr,
      nextToken: nextTokenTemp,
    };
  } catch (e) {
    console.log(e);
  }
};

export const uncomma = (str) => {
  return str.replace(/,/g, '');
};

export const applyComma = (value) => {
  if (value === undefined || value === null) return '';
  const val = String(value);
  if (val?.includes('.')) {
    return `${val.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${val.split('.')[1]}`;
  }
  return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const checkBatchimEnding = (word) => {
  if (typeof word !== 'string') return null;

  var lastLetter = word[word.length - 1];
  var uni = lastLetter.charCodeAt(0);

  if (uni < 44032 || uni > 55203) return null;

  return (uni - 44032) % 28 !== 0;
};

export const signOutHandler = async () => {
  try {
    await Auth.signOut();
  } catch (e) {
    console.log(e);
  } finally {
    sessionStorage.clear();
    window.open('/', '_self'); // 로그인 화면으로 이동 & 강제 새로고침
  }
};

// Clinic.planInfo는 요금제 변경 당시의 결제일시 정보를 가지고 있어, 현재 달에 맞는 결제일시 추정치로 변환
export const setTempSubscription = (planInfo: string) => {
  const s = JSON.parse(planInfo);
  if (s.lastPaymentDate) {
    s['lastPaymentDate'] = moment(s.lastPaymentDate)
      .add(moment().diff(s.lastPaymentDate, 'months'), 'month')
      .format('YYYY-MM-DDTHH:mm:ss');
  }
  if (s.nextPaymentDate) {
    s['nextPaymentDate'] = moment(s.nextPaymentDate)
      .add(moment().diff(s.nextPaymentDate, 'months'), 'month')
      .format('YYYY-MM-DDTHH:mm:ss');
  }
  return s;
};

/**
 * html 태그의 폰트 size를 반환하는 함수
 * @returns {number} html tag font-size
 */
export const getRootFontSize = () => {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
};

/**
 * 부모 설문의 응답이 부정인지 확인하는 함수
 * @param {any} form antd form instance
 * @param {string} surveyCode 설문 코드
 * @returns {boolean} 부모 설문의 응답이 부정인지 여부
 */
export const isParentSurveyNegative = (form: any, surveyCode: string) => {
  // surveyCode의 길이가 1이라면 부모 설문이 없음. true 반환
  if (surveyCode.length === 1) return true;
  // 부모 설문은 surveyCode의 마지막 글자를 제외한 나머지 문자열로 구성됨
  const parentSurveyCode = surveyCode.slice(0, -1);
  const parentSurveyAnswer = form.getFieldValue(parentSurveyCode);
  // console.log(`parentSurveyCode: ${parentSurveyCode}, value: ${parentSurveyAnswer}`);
  // 만약 parentSurveyAnswer가 '0'이거나 undefined라면 부모 설문의 응답이 부정이므로 true 반환
  if (parentSurveyAnswer == 0 || parentSurveyAnswer === undefined) return true;
  // 아니라면 parentSurveyCode의 마지막 글자를 제외한 나머지 문자열로 구성된 설문의 응답이 부정인지 확인
  return isParentSurveyNegative(form, parentSurveyCode);
};

/**
 * 이/가 중 무얼 붙일지 결정해주는 함수
 * @param value
 * @returns '이' or '가'
 */
export const checkIGa = (value: string) => {
  const lastChar = value.charCodeAt(value.length - 1);
  const isThereLastChar = (lastChar - 0xac00) % 28;
  if (isThereLastChar) {
    return '이';
  }
  return '가';
};

/**
 * 현재 브라우저가 무엇인지 반환하는 함수
 * @returns {string} 브라우저 이름
 */
export const getBrowser = () => {
  const browsers = [
    'Chrome',
    'Opera',
    'WebTV',
    'Whale',
    'Beonex',
    'Chimera',
    'NetPositive',
    'Phoenix',
    'Firefox',
    'Safari',
    'SkipStone',
    'Netscape',
    'Mozilla',
  ];

  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.includes('edg')) {
    return 'Edge';
  }

  if (userAgent.includes('trident') || userAgent.includes('msie')) {
    return 'Internet Explorer';
  }

  return browsers.find((browser) => userAgent.includes(browser.toLowerCase())) || 'Other';
};

/**
 * 처방전을 엑셀로 다운로드하는 함수
 * PrescriptionExcelTemplate 컴포넌트를 componentRef로 받아서 엑셀로 변환
 */
// export const DownloadPrescriptionExcel = ({
//   componentRef,
//   treatment = {},
//   patientName,
// }: {
//   componentRef: React.MutableRefObject<any>;
//   treatment: any;
//   patientName: string;
// }) => {
//   const workbook = XLSX.utils.table_to_book(componentRef);
//   const ws = workbook.Sheets['Sheet1'];
//   XLSX.utils.sheet_add_aoa(ws, [[`${moment(treatment.treatmentedAt).format('YYYY-MM-DD HH:mm:ss')}`]], {
//     origin: 'E3',
//   }); // 처방 일시 value

//   const rowCount = Number(ws['!ref'].split(':')[1].replace(/[^0-9]/g, ''));
//   const mergedCells = [
//     'C2',
//     'D2',
//     'E2',
//     'F2',
//     'C3',
//     'F3',
//     'C4',
//     'F4',
//     'C5',
//     'D5',
//     'E5',
//     'F5',
//     'C6',
//     'F6',
//     'B7',
//     'C7',
//     'D7',
//     'E7',
//     'F7',
//     'B8',
//     'C8',
//     'D8',
//     'E8',
//     'F8',
//     'B9',
//     'C9',
//     'D9',
//     'E9',
//     'F9',
//     `B${rowCount}`,
//     `C${rowCount}`,
//     `D${rowCount}`,
//   ];
//   _.forEach(
//     mergedCells,
//     (c) =>
//       (ws[c] = {
//         t: 's',
//         v: '',
//         s: {
//           border: {
//             top: { style: 'thin', color: '1D1C1D' },
//             left: { style: 'thin', color: '1D1C1D' },
//             bottom: { style: 'thin', color: '1D1C1D' },
//             right: { style: 'thin', color: '1D1C1D' },
//           },
//         },
//       }),
//   );
//   const thStyle = {
//     font: { sz: '14', bold: true, color: { rgb: '1D1C1D' } },
//     alignment: { horizontal: 'center', vertical: 'center' },
//     fill: { fgColor: { rgb: 'E5E5E5' } },
//   };
//   const tdStyle = {
//     font: { sz: '14', color: { rgb: '1D1C1D' } },
//     alignment: { vertical: 'center' },
//   };
//   const commonStyle = {
//     border: {
//       top: { style: 'thin', color: '1D1C1D' },
//       left: { style: 'thin', color: '1D1C1D' },
//       bottom: { style: 'thin', color: '1D1C1D' },
//       right: { style: 'thin', color: '1D1C1D' },
//     },
//   };

//   const excelStyleMap = new Map([
//     [
//       'A1',
//       {
//         // 주문내역서 (title)
//         font: { sz: '18' },
//         alignment: { vertical: 'center' },
//       },
//     ],
//     ['A2', { ...commonStyle, ...thStyle }], // 처방 명
//     ['B2', { ...commonStyle, ...tdStyle }], // 처방 명 value
//     ['A3', { ...commonStyle, ...thStyle }], // 처방 수량
//     ['B3', { ...commonStyle, ...tdStyle }], // 처방 수량 value
//     ['D3', { ...commonStyle, ...thStyle }], // 처방 일시
//     ['E3', { ...commonStyle, ...tdStyle }], // 처방 일시 value
//     ['A4', { ...commonStyle, ...thStyle }], // 한의원 명
//     ['B4', { ...commonStyle, ...tdStyle }], // 한의원 명 value
//     ['D4', { ...commonStyle, ...thStyle }], // 한의사 명
//     ['E4', { ...commonStyle, ...tdStyle }], // 한의사 명 value
//     ['A5', { ...commonStyle, ...thStyle }], // 한의원 연락처
//     ['B5', { ...commonStyle, ...tdStyle }], // 한의원 연락처 value
//     ['A6', { ...commonStyle, ...thStyle }], // 환자 명
//     ['B6', { ...commonStyle, ...tdStyle }], // 환자 명 value
//     ['D6', { ...commonStyle, ...thStyle }], // 환자 연락처
//     ['E6', { ...commonStyle, ...tdStyle }], // 환자 연락처 value
//     ['A7', { ...commonStyle, ...thStyle }], // 환자 주소
//     ['B7', { ...commonStyle, ...tdStyle }], // 환자 주소 value
//     ['A8', { ...commonStyle, ...thStyle }], // 배송 메모
//     ['B8', { ...commonStyle, ...tdStyle }], // 배송 메모 value
//     ['A9', { ...commonStyle, ...thStyle }], // 복용 메모
//     ['B9', { ...commonStyle, ...tdStyle }], // 복용 메모 value
//     ['A10', { ...commonStyle, ...thStyle, fill: { fgColor: { rgb: 'f5e2d0' } } }], // 약재명
//     ['B10', { ...commonStyle, ...thStyle, fill: { fgColor: { rgb: 'f5e2d0' } } }], // 첩당 조제량
//     ['C10', { ...commonStyle, ...thStyle, fill: { fgColor: { rgb: 'f5e2d0' } } }], // 총 투약량
//     ['D10', { ...commonStyle, ...thStyle, fill: { fgColor: { rgb: 'f5e2d0' } } }], // 약재명
//     ['E10', { ...commonStyle, ...thStyle, fill: { fgColor: { rgb: 'f5e2d0' } } }], // 첩당 조제량
//     ['F10', { ...commonStyle, ...thStyle, fill: { fgColor: { rgb: 'f5e2d0' } } }], // 총 투약량
//   ]);

//   _.forEach(Array.from(Array(rowCount).keys()).slice(10, rowCount), (e) => {
//     const index = e + 1;
//     const targetStyle = rowCount === index ? { ...thStyle, fill: { fgColor: { rgb: 'f5e2d0' } } } : tdStyle;
//     _.forEach([`A${index}`, `B${index}`, `C${index}`, `D${index}`, `E${index}`, `F${index}`], (j) => {
//       const additionalStyle = {};
//       if (rowCount !== index) {
//         if ([`A${index}`, `D${index}`].includes(j)) {
//           additionalStyle['fill'] = { fgColor: { rgb: 'f9f9f9' } };
//           additionalStyle['font'] = { ...tdStyle.font, bold: true };
//         }
//       }
//       excelStyleMap.set(j, {
//         ...commonStyle,
//         ...targetStyle,
//         ...additionalStyle,
//         alignment: { vertical: 'center', horizontal: 'center' },
//       });
//     });
//   });

//   ws['!rows'] = [{ hpx: 40 }, ..._.fill(Array(rowCount - 1), { hpx: 30 })];
//   for (let i in ws) {
//     // console.log(i, ws[i]);
//     if (i === '!cols') {
//       ws[i] = _.fill(Array(6), { wpx: 90 });
//     } else if (!i.startsWith('!')) {
//       if (excelStyleMap.has(i)) {
//         ws[i].t = 's';
//         ws[i].s = excelStyleMap.get(i);
//       }
//     }
//   }
//   XLSX.writeFile(
//     workbook,
//     `${patientName}_${
//       treatment?.treatmentedAt
//         ? moment(treatment.treatmentedAt).format('YYYY-MM-DD')
//         : moment().format('YYYY-MM-DD')
//     }.xlsx`,
//   );
// };

/**
 * 변증 데이터를 Report 테이블에 저장하기 위해 가공하는 함수
 */
export const parseByeonjeungData = ({
  b,
  selectedBCodeArray = [],
}: {
  b: any;
  selectedBCodeArray: string[];
}): {
  팔강: {
    [key: string]: number;
  };
  변증: {
    code: string;
    koreanName: string;
    chineseName: string;
    koreanDesc: string;
    chineseDesc: string;
    weight: number;
    relativeSymptomCode: { code: string; contribution: number }[];
    checkFlag: boolean;
  }[];
} => {
  if (!b?.팔강 || !b?.변증)
    return {
      팔강: {},
      변증: [],
    };

  const byeonjeungLengthLimit = 5;
  const bArray = _.reverse(_.sortBy(b.변증 ?? [], (e) => e.weight)).slice(0, byeonjeungLengthLimit);
  const pObj = _.fromPairs(
    _.map(_.keys(b.팔강), (k) => {
      const pv = b.팔강[k];
      if (pv > -1 && !_.isNaN(pv) && !_.isNull(pv)) {
        return [k, _.round(b.팔강[k])];
      } else {
        return [k, null];
      }
    }),
  );

  return {
    팔강: pObj,
    변증: _.map(bArray, (e) => ({
      code: e?.code,
      koreanName: e?.koreanName,
      chineseName: e?.chineseName,
      koreanDesc: e?.koreanDesc,
      chineseDesc: e?.chineseDesc,
      weight: _.round(e?.weight, 1),
      checkFlag: selectedBCodeArray.includes(e?.code),
      relativeSymptomCode: e?.relativeSymptomCode ?? [],
    })),
  };
};

/**
 * Patient 데이터의 최근 활동 메세지를 반환하는 함수
 */
export const convertPatientRecentActivityMessage = ({
  patientData = {},
  convertMode,
}: {
  patientData: any;
  convertMode: 'longMessage' | 'shortMessage';
}) => {
  const { recentActivityCode, recentActivityMessage } = patientData;
  if (recentActivityCode) {
    const convertedMessage = activityMessageMap.get(recentActivityCode)?.[convertMode];
    if (convertedMessage === null || convertedMessage === undefined) {
      return recentActivityMessage;
    }
    return convertedMessage;
  } else {
    return recentActivityMessage;
  }
};

/**
 * History 데이터의 최근 활동 메세지를 반환하는 함수
 */
export const convertHistoryMessage = ({
  historyData = {},
  convertMode,
}: {
  historyData: any;
  convertMode: 'longMessage' | 'shortMessage';
}) => {
  const { message, messageCode } = historyData;
  if (messageCode) {
    const convertedMessage = activityMessageMap.get(messageCode)?.[convertMode];
    if (convertedMessage === null || convertedMessage === undefined) {
      return message;
    }
    return convertedMessage;
  } else {
    return message;
  }
};

/**
 * 설문 데이터 중 요약 노출할 것들
 */
export const getSummarySurveyData = (symptom: any, mode?: string) => {
  const data = codeMap.get(symptom.code);
  // scale, onset 타입이 아닌 답변
  let plainStringValue;
  let desc;
  let newSymptom = { ...symptom };
  if (data?.selectType === 'NumberInput') {
    plainStringValue = `${newSymptom.value}${data?.postfix ?? ''}`;
  } else if (typeof newSymptom?.value === 'number') {
    plainStringValue = _.find(
      codeMap.get(newSymptom.code).answers,
      (s: any) => s?.value === newSymptom.value,
    )?.label;
    if (newSymptom.value === -1) {
      desc = newSymptom?.desc ? `${newSymptom.desc}` : '';
    }
  } else if (_.isArray(newSymptom?.value)) {
    if (newSymptom.value.includes(-1)) {
      plainStringValue = _.reduce(
        newSymptom.value.filter((e) => e !== -1 && typeof e === 'number'),
        (a, c) => {
          const label = _.find(codeMap.get(newSymptom.code).answers, (s: any) => s?.value === c)?.label;
          if (label) {
            return `${a}${label}, `;
          }
          return a;
        },
        '',
      )
        ?.slice(0, -2)
        ?.replace(/\./g, '');

      plainStringValue += newSymptom?.desc
        ? `${plainStringValue?.length > 0 ? `\n` : ''}${mode === 'copyChart' ? '' : '기타 [주관식 응답]: '}${
            newSymptom.desc
          }`
        : '';
    } else {
      plainStringValue = _.reduce(
        newSymptom.value.filter((e) => typeof e === 'number'),
        (a, c) => {
          const label = _.find(codeMap.get(newSymptom.code).answers, (s: any) => s?.value === c)?.label;
          if (label) {
            return `${a}${label}, `;
          }
          return a;
        },
        '',
      )
        ?.slice(0, -2)
        ?.replace(/\./g, '');
    }
  } else if (data?.selectType === 'Date') {
    plainStringValue = `${moment(newSymptom.value).format('YYYY-MM-DD')}`;
  }
  if (newSymptom.value.onset >= 0 && !['byeonjeung', 'copyChart'].includes(mode)) {
    plainStringValue = `${data?.desc?.join(', ') ?? ''} [${convertOnsetValueToString(
      newSymptom.value.onset,
    )}]`;
  }
  return {
    symptom: { ...newSymptom },
    codeData: data,
    plainStringValue,
    desc,
  };
};

/**
 * 처방전 약재명 파싱에 쓰이는 map
 */
export const pc2prescriptionHerbMap = new Map([
  ['3419H1AHM-1', { name: '작약', origin: '적작약' }],
  ['3419H1AHM', { name: '작약', origin: '백작약' }],
  ['3628H1AHM', { name: '작약주자', origin: '백작약' }],
  ['3629H1AHM', { name: '작약초', origin: '백작약' }],
]);

/**
 * 기준 처방에 비해 얼마나 차이가 나는지 계산하는 함수
 */
export const comparePrescription = ({ basePrescription, targetPrescription }) => {
  const baseMap = new Map(basePrescription.map((e) => [e.pc, e]));
  const targetMap = new Map(targetPrescription.map((e) => [e.pc, e]));

  const baseCodeArr = _.sortBy(basePrescription, (e) => -e.amt).map((e) => e.pc);
  const targetCodeArr = _.sortBy(targetPrescription, (e) => -e.amt).map((e) => e.pc);

  const 기준처방에서안쓴약재 = _.difference(baseCodeArr, targetCodeArr);
  const 새로쓴약재 = _.difference(targetCodeArr, baseCodeArr);
  const 겹치는약재 = _.intersection(baseCodeArr, targetCodeArr);

  const result = [];

  baseCodeArr.forEach((c) => {
    const baseData: any = baseMap.get(c);
    result.push({
      pc: c,
      amt: baseData.amt,
      adjustment: '',
    });
  });

  겹치는약재.forEach((c) => {
    const baseData: any = baseMap.get(c);
    const targetData: any = targetMap.get(c);
    const 용량차이 = (targetData.amt || 0) - (baseData.amt || 0);
    let 용량가감;
    if (용량차이 > 0) {
      용량가감 = `용량가`;
    } else if (용량차이 < 0) {
      용량가감 = `용량감`;
    } else {
      용량가감 = ``;
    }
    if (용량가감) {
      result.push({
        pc: c,
        amt: Math.abs(용량차이),
        adjustment: 용량가감,
      });
    }
  });

  기준처방에서안쓴약재.forEach((c) => {
    result.push({
      pc: c,
      amt: baseMap.get(c)?.['amt'],
      adjustment: '제품감',
    });
  });

  새로쓴약재.forEach((c) => {
    const herbData: any = targetMap.get(c);
    result.push({
      pc: c,
      amt: herbData.amt,
      adjustment: '제품가',
    });
  });

  return result;
};

const insertEnter = (str: string) => `${str}\n`;

export const selectSurvey = (rawSurvey: string) => {
  const codeArray = Array.from(codeMap, ([k]) => k);
  // console.log(rawSurvey);
  if (rawSurvey === 'none') {
    return [];
  }
  if (!rawSurvey) {
    return [];
  }
  const survey = JSON.parse(rawSurvey);
  const result = survey.reduce((a, res: any) => {
    const surveyData = codeMap.get(res.code);
    if (!surveyData) {
      return a;
    }
    if (res.desc) {
      // 주관식 답이 존재하면 이유 불문 보여줌
      return [...a, res];
    } else if (surveyData.selectType === 'NumberInput') {
      // 숫자 입력 타입은 무조건 보여줌
      return [...a, res];
    } else if (typeof res.value === 'number' && ![-1].includes(res.value)) {
      // 응답값이 숫자인 경우 설문 노출 여부 판단
      //@ts-ignore
      const targetAnswer = surveyData?.answers?.find((e) => e.value === res.value) ?? {};
      if (!_.isEmpty(targetAnswer?.['symptomCode']) || targetAnswer?.['mustShowFlagInYejinSymptomTable']) {
        return [...a, res];
      } else {
        return a;
      }
    } else if (_.isArray(res.value)) {
      // 응답 값이 숫자 배열인 경우 설문 노출 여부 판단
      const refinedValue = (res.value as Array<string | number>).filter(
        (e) => typeof e === 'number' && ![-1].includes(e),
      );
      let flag = false;
      refinedValue.forEach((r) => {
        //@ts-ignore
        const targetAnswer = surveyData?.answers?.find((e) => e.value === r);
        if (!_.isEmpty(targetAnswer?.symptomCode || targetAnswer?.['mustShowFlagInYejinSymptomTable'])) {
          flag = true;
          return false; // forEach 반복문 종료
        }
      });
      if (flag) {
        return [...a, res];
      } else {
        return a;
      }
    } else if (typeof res.value === 'string') {
      if (moment(res.value).isValid()) {
        // 응답 값이 날짜인 경우 설문 노출 여부 판단
        return [...a, res];
      } else {
        return a;
      }
    } else if (_.isObject(res.value)) {
      // 응답 포맷 상 object 형태의 응답은 scale, onset, onset&scale case만 존재
      return [...a, res];
    } else {
      return a;
    }
  }, []);

  // console.log(codeArray);
  result.sort(
    (a, b) =>
      codeArray.findIndex((e: string) => e === a.code!) - codeArray.findIndex((e: string) => e === b.code!),
  );
  return result;
};

/**
 * 차트 복사 함수
 * @param t treatment data
 * @param r 변증 report data
 * @returns string copied chart
 */
export const copyChart = (t, r) => {
  console.log(t);
  if (_.isEmpty(t) && !_.isObject(t)) return '';

  const convertScaleOnset = (symptomInput) => {
    const onsetValue = symptomInput.symptom.value.onset;
    const scaleValue = symptomInput.symptom.value.scale;
    let val = '';
    if (scaleValue >= 0) {
      val += `(불편한 정도: ${scaleValue})`;
    }
    if (onsetValue >= 0) {
      val += `[${convertOnsetValueToString(onsetValue)}]`;
    }
    return val;
  };

  const setSurveyText = ({ symptomInput, labelFlag = true }) => {
    const val = convertScaleOnset(symptomInput);
    let label = symptomInput.codeData.label;

    return `${labelFlag ? `${label}: ` : ''}${
      symptomInput.plainStringValue ? symptomInput.plainStringValue : symptomInput.codeData?.desc?.join(', ')
    } ${val}`;
  };

  const chartMap = new Map([
    ['C/C', 'C/C)'],
    ['O/S', 'O/S)'],
    ['P/I', 'P/I)'],
    ['P/H', 'P/H)'],
    ['F/H', 'F/H)'],
    ['ROS', 'ROS)'],
    ['Imp', 'Imp)'],
    ['Plan', 'Plan)'],
  ]);
  const { survey: s = {}, symptomCheck: 사진, prescriptionData: p, customHerbalFormulaData: customP } = t;

  const PH = []; // 질환, Onset
  const OS = []; // 첩건설문인 경우 Date 타입, 첩건설문이 아닌 경우는 해당 없음
  const PI = []; // 현 병력
  const FH = []; // 가족력
  const CC = []; // 질환, Date, Onset이 아닌 나머지 설문 중 주소증과 관련된 것
  const ROS = []; // 질환, Date, Onset이 아닌 나머지 설문 중 주소증과 관련되지 않은 것

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const symptoms = (selectSurvey(s?.symptoms) ?? []).map((symptomData) => {
    const surveyData = getSummarySurveyData(symptomData, 'copyChart');
    const { symptom, plainStringValue, codeData, desc } = surveyData;
    let chartType = codeData?.chartType;
    if (['Scale', 'Onset'].includes(codeData.selectType)) {
      const parentsCode = symptom.code.slice(0, -1);
      if (codeMap.get(parentsCode)?.chartType) {
        chartType = codeMap.get(parentsCode).chartType;
      }
    }
    if (chartType === 'OS') {
      OS.push(surveyData);
    } else if (chartType === 'CC') {
      CC.push(surveyData);
    } else if (chartType === 'PI') {
      PI.push(surveyData);
    } else if (chartType === 'FH') {
      FH.push(surveyData);
    } else if (
      codeData.label?.replace('뇌혈관질환', '').includes('질환') ||
      codeData?.selectType === 'Onset'
    ) {
      PH.push(surveyData);
    } else if (s.mainSymptomCategory && symptom?.code?.startsWith(s.mainSymptomCategory)) {
      CC.push(surveyData);
    } else {
      ROS.push(surveyData);
    }

    return {
      symptom,
      plainStringValue,
      codeData,
      desc,
    };
  });
  // console.log('symptoms', symptoms);

  // 주소증 카테고리
  if (s.mainSymptomCategory && s.mainSymptomCategory !== 'GENERAL') {
    chartMap.set('C/C', insertEnter(chartMap.get('C/C')) + `[${codeMap.get(s.mainSymptomCategory)?.label}]`);
  }

  // 주소증 서술형 답변
  if (s.mainSymptom && s.mainSymptom !== 'none') {
    chartMap.set('C/C', insertEnter(chartMap.get('C/C')) + `${s?.mainSymptom}`);
  }

  if (CC.length > 0) {
    CC.forEach((c) => {
      chartMap.set('C/C', insertEnter(chartMap.get('C/C')) + setSurveyText({ symptomInput: c }));
    });
  }

  if (OS.length > 0) {
    OS.forEach((c) => {
      chartMap.set('O/S', insertEnter(chartMap.get('O/S')) + setSurveyText({ symptomInput: c }));
    });
  }

  if (PI.length > 0) {
    PI.forEach((c) => {
      chartMap.set('P/I', insertEnter(chartMap.get('P/I')) + setSurveyText({ symptomInput: c }));
    });
  }

  if (FH.length > 0) {
    FH.forEach((c) => {
      chartMap.set('F/H', insertEnter(chartMap.get('F/H')) + setSurveyText({ symptomInput: c }));
    });
  }

  if (PH.length > 0) {
    PH.forEach((c) => {
      chartMap.set(
        'P/H',
        insertEnter(chartMap.get('P/H')) + setSurveyText({ symptomInput: c, labelFlag: false }),
      );
    });
  }

  if (ROS.length > 0) {
    ROS.forEach((c) => {
      chartMap.set('ROS', insertEnter(chartMap.get('ROS')) + setSurveyText({ symptomInput: c }));
    });
  }

  // 추가증상 서술형 답변
  if (s?.additionalSymptoms && s.additionalSymptoms !== 'none') {
    chartMap.set('ROS', insertEnter(chartMap.get('ROS')) + `${s.additionalSymptoms}`);
  }

  if (!_.isEmpty(사진)) {
    let 사진String = '';
    DIAGNOSIS.forEach((d) => {
      d.children.forEach((c) => {
        if (사진.includes(c.sympomCode)) {
          사진String = `${insertEnter(사진String)}${c.label}`;
        }
      });
    });
    chartMap.set('ROS', insertEnter(chartMap.get('ROS')) + `${사진String}`);
  }

  if (r?.변증?.length > 0) {
    const { 변증 } = r;
    const selectflag = 변증.some((e) => e.checkFlag); // 선택된 변증이 있는지 확인
    if (selectflag) {
      변증.filter((e) => e.checkFlag).forEach((e) => {
        chartMap.set('Imp', insertEnter(chartMap.get('Imp')) + `${e.koreanName}(${e.chineseName})`);
      });
    } else {
      _.sortBy(변증, (e) => -e.weight)
        .slice(0, 3)
        .forEach((e) => {
          chartMap.set('Imp', insertEnter(chartMap.get('Imp')) + `${e.koreanName}(${e.chineseName})`);
        });
    }
  }

  if (!_.isEmpty(customP) && customP.kn) {
    chartMap.set('Plan', insertEnter(chartMap.get('Plan')) + `H-med.(${customP.kn})`);
  } else if (!_.isEmpty(p) && p.kn) {
    chartMap.set('Plan', insertEnter(chartMap.get('Plan')) + `H-med.(${p.kn})`);
  } else if (t.prescribedRecipe?.length > 0 || t.prescribedHerb?.length > 0) {
    chartMap.set('Plan', insertEnter(chartMap.get('Plan')) + `H-med`);
  }

  const chartText = Array.from(chartMap.entries()).reduce((a, [k, v]) => {
    if (v === `${k})`) return a;
    return `${a}\n\n${v}`;
  }, '');

  window.navigator.clipboard.writeText(_.trim(chartText));
};

/**
 * 주민등록번호가 유효한지 확인하는 함수
 */
export const isValidRRN = (rrn: string) => {
  const regex = /^[0-9]{6}-[1-4][0-9*]{6}$/;
  return regex.test(rrn);
};

/**
 * 진료실 변증화면에서 쓰이는, 팔강 데이터를 설명해주는 함수
 */
export const convertPalgangData = (bd: any, patientName: string) => {
  const 팔강 = bd?.팔강;
  if (!팔강) return '';
  const totalPalgangCount = palgangLabelSet.length;
  let balancedPalgangCount = 0;
  const balancedPalgangList = [];
  const unbalancedPalgangList = [];
  palgangLabelSet.forEach((p) => {
    const leftValue = 팔강[p.leftKLabel];
    const rightValue = 팔강[p.rightKLabel];
    if (
      !_.isNaN(leftValue) &&
      !_.isNaN(rightValue) &&
      !_.isNull(leftValue) &&
      !_.isNull(rightValue) &&
      rightValue !== undefined &&
      leftValue !== undefined
    ) {
      if (leftValue >= 40 && leftValue <= 60) {
        balancedPalgangCount += 1;
        let label = '';
        if (['열', '한'].includes(p.leftKLabel)) {
          label = '한/열';
        } else if (['실', '허'].includes(p.leftKLabel)) {
          label = '허/실';
        } else if (['표', '리'].includes(p.leftKLabel)) {
          label = '표/리';
        }
        balancedPalgangList.push(`${label}`);
      } else {
        unbalancedPalgangList.push(leftValue > rightValue ? `'${p.leftKLabel}'` : `'${p.rightKLabel}'`);
      }
    }
  });

  if (_.isEmpty(balancedPalgangList) && _.isEmpty(unbalancedPalgangList)) {
    return '';
  }

  let text = `${patientName}님의 신체 상태는 `;
  if (totalPalgangCount === balancedPalgangCount) {
    text += '전반적으로 균형을 이루고 있습니다.';
  } else if (balancedPalgangCount === 0) {
    text += `${unbalancedPalgangList.join(', ')} 경향이 높습니다.`;
  } else {
    const bText = balancedPalgangList.join(', ');
    text += `${unbalancedPalgangList.join(', ')} 경향이 높으며, ${
      checkBatchimEnding(bText) ? `${bText}은` : `${bText}는`
    } 균형을 이루고 있습니다.`;
  }

  return text;
};

/**
 * 대요메디 맥진 결과 변환 함수
 */

export const convertPulseMeterResult = ({
  pf_pl,
  ft_sk,
  fs_sl,
}: {
  pf_pl: string; // 유무력
  ft_sk: string; // 부침
  fs_sl: string; // 지삭
}) => {
  const result = {
    유무력: {
      code: undefined,
      result: undefined,
    },
    부침: {
      code: undefined,
      result: undefined,
    },
    지삭: {
      code: undefined,
      result: undefined,
    },
  };

  console.log(pf_pl);

  const 유무력 = parseInt(pf_pl);
  const 부침 = parseInt(ft_sk);
  const 지삭 = parseInt(fs_sl);

  if (유무력 < 4) {
    result.유무력.code = 'S101';
    result.유무력.result = '무력';
  } else if (유무력 >= 4 && 유무력 <= 7) {
    result.유무력.result = '평균';
  } else if (유무력 > 7) {
    result.유무력.code = 'S104';
    result.유무력.result = '유력';
  }

  if (부침 < 4) {
    result.부침.code = 'S822';
    result.부침.result = '침';
  } else if (부침 >= 4 && 부침 <= 7) {
    result.부침.result = '평균';
  } else if (부침 > 7) {
    result.부침.code = 'S102';
    result.부침.result = '부';
  }

  if (지삭 < 60) {
    result.지삭.code = 'S821';
    result.지삭.result = '지';
  } else if (지삭 >= 60 && 지삭 <= 80) {
    result.지삭.result = '평균';
  } else if (지삭 > 80) {
    result.지삭.code = 'S103';
    result.지삭.result = '삭';
  }

  return result;
};
