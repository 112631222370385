import React, { ReactElement, useMemo } from 'react';

import { TypographyText, Row } from 'components/UI/atoms';
import { Patient, Survey } from 'API';

function PatientSurveyHeader({
  patient,
  survey,
  mode,
}: {
  patient: Patient;
  survey?: Survey;
  mode?: 'create';
}): ReactElement {
  const loadingFlag = useMemo(() => {
    if (mode === 'create') {
      return patient?.id ? false : true;
    } else {
      return patient?.id && survey?.id ? false : true;
    }
  }, [mode, patient?.id, survey?.id]);

  return (
    <Row
      style={{
        height: '5.3rem',
        borderBottom: '1px solid #e5e5e5',
        display: 'flex',
        columnGap: '1.25rem',
        paddingTop: '2rem',
        paddingBottom: '1.25rem',
        paddingLeft: '2rem',
      }}
    >
      {!loadingFlag && (
        <TypographyText fontSize="1.8rem" fontWeight={700}>
          {`${patient?.patientName}(${patient?.birth}) 증상설문`}
        </TypographyText>
      )}
    </Row>
  );
}

export default React.memo(PatientSurveyHeader);
