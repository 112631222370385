import { currentEnv } from 'config';

// https://www.notion.so/8caba1acde0347859009382d13e7ef60
export enum TreatmentStatus {
  None = 0,
  SurveyWaiting = 10,
  // SurveyWriting = 20, // 사용 안함
  SurveySubmit = 30,
  // Wating = 40, // surveySubmit으로 사용 중
  InProgress = 50,
  // Done = 60, // 사용안함
}

type TdefaultPricingLimit = {
  kakaoTransmissionLimit: number;
  treatmentLimit: number;
  stepLimit: number;
};

interface IdefaultPricingLimit {
  [key: string]: TdefaultPricingLimit;
}

// 유료요금제에 가입되지 않은 한의원의 요금제별 제한 항목 정보. 스테이징 이하는 원활한 테스트와 비용절감을 위해 한도를 낮춤. 하지만 실제 요금제 제한정보가 바뀌면 production의 제한정보는 똑같이 변경시켜줘야함
// https://portal.steppay.kr/product/product_dzAc1fM2B
export const defaultPricingLimitObj: IdefaultPricingLimit = {
  local: {
    kakaoTransmissionLimit: 10,
    treatmentLimit: 30,
    stepLimit: 0,
  },
  develop: {
    kakaoTransmissionLimit: 10,
    treatmentLimit: 30,
    stepLimit: 0,
  },
  staging: {
    kakaoTransmissionLimit: 10,
    treatmentLimit: 30,
    stepLimit: 0,
  },
  production: {
    kakaoTransmissionLimit: 100,
    treatmentLimit: 30,
    stepLimit: 0,
  },
};

export const defaultPricingLimit: TdefaultPricingLimit = defaultPricingLimitObj[currentEnv ?? 'local'];

export const palgangLabelSet = [
  {
    leftKLabel: '열',
    leftCLabel: '熱',
    rightKLabel: '한',
    rightCLabel: '寒',
  },
  {
    leftKLabel: '표',
    leftCLabel: '表',
    rightKLabel: '리',
    rightCLabel: '裏',
  },
  {
    leftKLabel: '실',
    leftCLabel: '實',
    rightKLabel: '허',
    rightCLabel: '虛',
  },
];

export const prescriptionItemLimit = 200; // 추천 외 방제사전 최대 개수

export const prescriptionCount = 30; // 추천받을 방제사전 최대 개수

export const treatmentModeMap = new Map([
  // 첩건 사업 갱신될 때마다 확인해야함
  // 급여/비급여 구분 목록
  [
    'GENERAL',
    {
      label: '비급여',
      value: 'GENERAL',
    },
  ],
  [
    'B',
    {
      label: '월경통',
      value: 'B',
    },
  ],
  [
    'C',
    {
      label: '안면신경마비',
      value: 'C',
    },
  ],
  [
    'D',
    {
      label: '뇌혈관질환후유증',
      value: 'D',
    },
  ],
  [
    'E',
    {
      label: '요추추간판탈출증',
      value: 'E',
    },
  ],
  [
    'F',
    {
      label: '알레르기비염',
      value: 'F',
    },
  ],
  [
    'G',
    {
      label: '기능성소화불량',
      value: 'G',
    },
  ],
]);

export const activityMessageMap = new Map([
  // 값이 null인 것들은 recentActivityMessage 필드 사용
  [
    'AM001',
    { longMessage: '환자가 등록되었습니다.', shortMessage: '환자 등록', filterOptionLabel: '환자 등록' },
  ],
  ['AM002', { longMessage: '환자 정보가 수정되었습니다.', shortMessage: null }], // 환자 최근 활동 내역에는 저장하지 말아야할 코드
  [
    'AM003',
    {
      longMessage: '카카오톡 설문 링크가 전송되었습니다.',
      shortMessage: '설문 전송',
      filterOptionLabel: '설문 전송',
    },
  ],
  [
    'AM004',
    {
      longMessage: '설문이 입력되어 진료 목록에 등록되었습니다.',
      shortMessage: '진료 대기',
      filterOptionLabel: '진료 대기',
    },
  ],
  ['AM005', { longMessage: '진료가 시작되었습니다.', shortMessage: '진료 중', filterOptionLabel: '진료 중' }],
  [
    'AM007',
    { longMessage: '진료가 완료되었습니다.', shortMessage: '진료 완료', filterOptionLabel: '진료 완료' },
  ],
  [
    'AM006',
    { longMessage: '진료가 취소되었습니다.', shortMessage: '진료 취소', filterOptionLabel: '진료 취소' },
  ],
  [
    'AM009',
    {
      longMessage: '피드백 링크가 전송되었습니다.',
      shortMessage: '피드백 전송',
      filterOptionLabel: '피드백 전송',
    },
  ],
  [
    'AM008',
    {
      longMessage: '피드백이 입력되었습니다.',
      shortMessage: '피드백 완료',
      filterOptionLabel: '피드백 완료',
    },
  ],
  ['AM010', { longMessage: null, shortMessage: null, filterOptionLabel: '카카오톡 전송 실패' }], // 카카오톡 전송 실패 케이스. 메세지 종류가 많으므로 recentActivityMessage 필드 사용
]);

export const 질환_처방구분별_상한금액 = new Map([
  // 첩건 사업 갱신될 때마다 확인해야함
  ['B1', 72510], // 월경통 - 질환고유처방
  ['B2', 46760], // 월경통 - 공통처방_변증
  ['B3', 58030], // 월경통 - 공통처방_사상
  ['C1', 69860], // 안면신경마비 - 질환고유처방
  ['C2', 46760], // 안면신경마비 - 공통처방_변증
  ['C3', 58030], // 안면신경마비 - 공통처방_사상
  ['D1', 66470], // 뇌혈관질환후유증 - 질환고유처방
  ['D2', 46760], // 뇌혈관질환후유증 - 공통처방_변증
  ['D3', 58030], // 뇌혈관질환후유증 - 공통처방_사상
  ['E1', 58330], // 요추추간판탈출증 - 질환고유처방
  ['E2', 46760], // 요추추간판탈출증 - 공통처방_변증
  ['E3', 58030], // 요추추간판탈출증 - 공통처방_사상
  ['F1', 42110], // 알레르기성비염 - 질환고유처방
  ['F2', 46760], // 알레르기성비염 - 공통처방_변증
  ['F3', 58030], // 알레르기성비염 - 공통처방_사상
  ['G1', 44860], // 기능성소화불량 - 질환고유처방
  ['G2', 46760], // 기능성소화불량 - 공통처방_변증
  ['G3', 58030], // 기능성소화불량 - 공통처방_사상
]);

export const 기준처방분류코드_질환_Map = new Map([
  // 첩건 사업 갱신될 때마다 확인해야함
  ['C0001', ['B']],
  ['C0002', ['B']],
  ['C0003', ['D', 'C']],
  ['C0004', ['D']],
  ['C0005', ['C']],
  ['C0006', ['C']],
  ['C0007', ['B']],
  ['C0008', ['D']],
  ['C0009', ['C']],
  ['C0010', ['C']],
  ['C0011', ['B']],
  ['C0012', ['B']],
  ['C0013', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0014', ['C']],
  ['C0015', ['D']],
  ['C0016', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0017', ['C']],
  ['C0018', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0019', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0020', ['D', 'C']],
  ['C0022', ['D']],
  ['C0023', ['D', 'C']],
  ['C0024', ['D']],
  ['C0025', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0026', ['B']],
  ['C0027', ['D', 'C']],
  ['C0028', ['D']],
  ['C0029', ['B']],
  ['C0030', ['C']],
  ['C0031', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0032', ['B']],
  ['C0033', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0034', ['B']],
  ['C0035', ['D']],
  ['C0036', ['D']],
  ['C0037', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0038', ['B']],
  ['C0039', ['D', 'B']],
  ['C0040', ['D']],
  ['C0041', ['D']],
  ['C0042', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0043', ['B']],
  ['C0044', ['B']],
  ['C0045', ['D', 'C']],
  ['C0046', ['D']],
  ['C0047', ['D']],
  ['C0049', ['B']],
  ['C0050', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0051', ['D', 'C', 'F', 'E', 'B']],
  ['C0052', ['D']],
  ['C0053', ['D']],
  ['C0054', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0055', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0056', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0057', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0058', ['D']],
  ['C0059', ['D']],
  ['C0060', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0061', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0062', ['D']],
  ['C0063', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0064', ['D']],
  ['C0066', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0067', ['D', 'C', 'F', 'E', 'B']],
  ['C0068', ['D', 'C']],
  ['C0069', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0070', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0071', ['B']],
  ['C0072', ['C']],
  ['C0073', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0074', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0075', ['D']],
  ['C0076', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0077', ['D']],
  ['C0078', ['G', 'C']],
  ['C0079', ['C']],
  ['C0080', ['B']],
  ['C0081', ['B']],
  ['C0082', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0083', ['B']],
  ['C0084', ['D']],
  ['C0085', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0086', ['C']],
  ['C0087', ['G', 'B']],
  ['C0088', ['C']],
  ['C0089', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0090', ['D']],
  ['C0091', ['D']],
  ['C0092', ['C']],
  ['C0093', ['D']],
  ['C0094', ['D']],
  ['C0095', ['D']],
  ['C0096', ['D']],
  ['C0097', ['D', 'B']],
  ['C0098', ['D']],
  ['C0099', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0100', ['B']],
  ['C0101', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0102', ['D']],
  ['C0103', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0104', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0105', ['D', 'C']],
  ['C0106', ['D']],
  ['C0107', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0108', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0109', ['D']],
  ['C0110', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0111', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0112', ['B']],
  ['C0113', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0114', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0115', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0116', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0117', ['C']],
  ['C0118', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0119', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0120', ['C']],
  ['C0121', ['B']],
  ['C0122', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0123', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0124', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0125', ['D']],
  ['C0126', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0127', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0128', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0129', ['B']],
  ['C0130', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0131', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0132', ['D']],
  ['C0133', ['D']],
  ['C0134', ['C']],
  ['C0135', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0136', ['D']],
  ['C0137', ['D']],
  ['C0138', ['B']],
  ['C0139', ['C']],
  ['C0140', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0141', ['D']],
  ['C0142', ['C']],
  ['C0143', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0144', ['C']],
  ['C0145', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0146', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0147', ['D']],
  ['C0148', ['D']],
  ['C0149', ['D']],
  ['C0150', ['C']],
  ['C0151', ['B']],
  ['C0152', ['D']],
  ['C0153', ['D']],
  ['C0154', ['B']],
  ['C0155', ['B']],
  ['C0156', ['B']],
  ['C0157', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0158', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0159', ['D']],
  ['C0160', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0161', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0162', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0163', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0164', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0165', ['B']],
  ['C0166', ['D', 'B']],
  ['C0168', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0169', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0170', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0171', ['C']],
  ['C0172', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0173', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0174', ['C']],
  ['C0175', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0176', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0177', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0178', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0179', ['E']],
  ['C0180', ['E']],
  ['C0181', ['F']],
  ['C0182', ['F']],
  ['C0183', ['F']],
  ['C0184', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0185', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0186', ['E']],
  ['C0187', ['G']],
  ['C0188', ['G']],
  ['C0189', ['E']],
  ['C0190', ['G']],
  ['C0191', ['E']],
  ['C0192', ['E']],
  ['C0193', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0194', ['E']],
  ['C0195', ['F']],
  ['C0196', ['G']],
  ['C0197', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0198', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0199', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0200', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0201', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0202', ['G']],
  ['C0203', ['F']],
  ['C0204', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0205', ['D', 'E']],
  ['C0206', ['G']],
  ['C0207', ['F']],
  ['C0208', ['G', 'E']],
  ['C0209', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0210', ['G']],
  ['C0211', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0212', ['F']],
  ['C0213', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0214', ['E']],
  ['C0215', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0216', ['G']],
  ['C0217', ['E']],
  ['C0218', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0219', ['E']],
  ['C0220', ['E']],
  ['C0221', ['F']],
  ['C0222', ['F']],
  ['C0223', ['G']],
  ['C0224', ['E']],
  ['C0225', ['F']],
  ['C0226', ['E']],
  ['C0227', ['G', 'D']],
  ['C0228', ['E']],
  ['C0229', ['F']],
  ['C0230', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0231', ['F']],
  ['C0232', ['E']],
  ['C0233', ['G']],
  ['C0234', ['G']],
  ['C0235', ['E']],
  ['C0236', ['G']],
  ['C0237', ['G']],
  ['C0238', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0239', ['F']],
  ['C0240', ['E']],
  ['C0241', ['F']],
  ['C0242', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0243', ['F']],
  ['C0244', ['E']],
  ['C0245', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0246', ['G']],
  ['C0247', ['G']],
  ['C0248', ['G', 'D', 'C', 'F', 'E', 'B']],
  ['C0249', ['G']],
  ['C0250', ['F']],
  ['C0251', ['G']],
  ['C0252', ['G']],
  ['C0253', ['F']],
  ['C0254', ['E']],
  ['C0255', ['G', 'D', 'C', 'F', 'E', 'B']],
]);
