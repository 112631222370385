/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Loader } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import message from 'antd-message';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { useStores } from 'stores/Context';
import { currentEnv } from 'config';
import { Row } from 'components/UI/atoms';
import ur from 'repository/UserRepository';
import sortBy from 'lodash/sortBy';
import _ from 'lodash';

const DailySignupUserList = () => {
  const navigate = useNavigate();
  const { clientStore } = useStores();

  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [countList, setCountList] = useState<any[]>([]);
  const [originCountList, setOriginCountList] = useState<any[]>([]);
  const [mode, setMode] = useState<string>('all');
  const [isShowGraph, setIsShowGraph] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [dateFilterTrigger, setDateFilterTrigger] = useState<boolean>(false);

  const buttonStyle = useMemo(
    () => ({
      width: 'fit-content',
      paddingInline: '1rem',
      height: '3rem',
      fontSize: '1rem',
      backgroundColor: '#f7f7f7',
      color: '#000',
      border: 'none',
      borderRadius: '0.4rem',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [],
  );

  const inputStyle = useMemo(() => ({ border: '1px solid #f1f1f1', textIndent: '0.3rem' }), []);

  const initFunc = useCallback(async () => {
    try {
      setIsListLoading(true);
      const subs = {
        local: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        develop: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        staging: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        production: ['borooboroo625@gmail.com'],
      };

      if (!subs[currentEnv]?.includes(clientStore.metaData?.userRole?.email)) {
        navigate('/patient');
      }

      const res = await ur.listDailySignupCount();
      const list = sortBy(res?.items, (e) => -e.date);
      await setCountList(list);
      await setOriginCountList(list);
    } catch (e) {
      message.error(`데이터를 가져오는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 2500);
    } finally {
      setIsListLoading(false);
    }
  }, []);

  const updateFunc = useCallback(async ({ date, memo }) => {
    try {
      setIsListLoading(true);
      const res = await ur.updateDailySignupCount({
        date,
        memo,
      });
      if (!_.isEmpty(res.updateDailySignupCount)) {
        setCountList((prev) => {
          const index = prev.findIndex((e) => e.date === date);
          const list = [...prev];
          list[index] = res.updateDailySignupCount;
          return list;
        });
      }
    } catch (e) {
      message.error(`데이터를 업데이트하는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 2500);
    } finally {
      setIsListLoading(false);
    }
  }, []);

  useEffect(() => {
    initFunc();
  }, []);

  const filterFunc = useCallback(({ startDate, endDate, mode, originCountList }) => {
    let isVaildStartDate = false;
    let isVaildEndDate = false;
    if (startDate) {
      isVaildStartDate = startDate.length === 8 && moment(startDate).isValid();
      if (!isVaildStartDate) {
        message.error('시작일을 확인해주세요. (YYYYMMDD)', 2500);
        return;
      }
    }
    if (endDate) {
      isVaildEndDate = endDate.length === 8 && moment(endDate).isValid();
      if (!isVaildEndDate) {
        message.error('종료일을 확인해주세요. (YYYYMMDD)', 2500);
        return;
      }
    }

    let list = [...originCountList];

    if (mode !== 'all') {
      list = list.filter((e) => e.count > 0);
    }

    return list.filter((e) => {
      if (isVaildStartDate && isVaildEndDate) {
        return e.date >= startDate && e.date <= endDate;
      } else if (isVaildStartDate) {
        return e.date >= startDate;
      } else if (isVaildEndDate) {
        return e.date <= endDate;
      }
      return true;
    });
  }, []);

  useEffect(() => {
    if (dateFilterTrigger) {
      const list = filterFunc({
        startDate,
        endDate,
        mode,
        originCountList,
      });
      if (list !== undefined) {
        setCountList(list);
      }
      setDateFilterTrigger(false);
    }
  }, [dateFilterTrigger]);

  useEffect(() => {
    const list = filterFunc({
      startDate,
      endDate,
      mode,
      originCountList,
    });
    if (list !== undefined) {
      setCountList(list);
    }
  }, [mode]);

  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        paddingBottom: '1rem',
        height: '100%',
        flex: '1 1 0',
      }}
    >
      <div style={{ display: 'flex', marginTop: '-1rem', marginBottom: '-0.5rem' }}>
        <span style={{ fontSize: '1rem' }}>{`누적 가입자 수: ${countList.reduce(
          (a, c) => a + c.count,
          0,
        )}`}</span>
      </div>
      <div className="flex gap-x-2 items-center">
        <button
          style={buttonStyle}
          onClick={() => {
            setMode(mode === 'all' ? 'positive' : 'all');
          }}
        >
          {`${mode === 'all' ? '가입자 수가 1명 이상인 날짜만 표시' : '모든 날짜 표시'}`}
        </button>
        <button style={buttonStyle} onClick={() => setIsShowGraph(!isShowGraph)}>
          {`${isShowGraph ? '그래프 표시 안함' : '그래프 표시'}`}
        </button>
        <div
          style={{
            display: 'flex',
            height: '3rem',
          }}
        >
          <input
            style={inputStyle}
            placeholder="시작일 (YYYYMMDD)"
            onChange={(e) => setStartDate(e.target.value)}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                setDateFilterTrigger(true);
              }
            }}
          />
          <input
            style={inputStyle}
            placeholder="종료일 (YYYYMMDD)"
            onChange={(e) => setEndDate(e.target.value)}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                setDateFilterTrigger(true);
              }
            }}
          />
          <button style={buttonStyle} onClick={() => setDateFilterTrigger(true)}>
            {`기간 설정`}
          </button>
        </div>
      </div>
      {isShowGraph && (
        <ResponsiveContainer height={400}>
          <LineChart data={_.reverse([...countList]) ?? []}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" interval={`preserveStartEnd`} />
            <YAxis orientation="right" allowDecimals={false} domain={[0, 'auto']} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="count" stroke="rgb(57, 87, 111)" name="일일 가입자 수" />
          </LineChart>
        </ResponsiveContainer>
      )}
      <table
        className={`list-page-common-table signup-list-page-table  ${isListLoading && 'height-100 flex-1'}`}
      >
        <colgroup>
          <col style={{ width: '10%', textAlign: 'left' }} />
          <col style={{ width: '10%', textAlign: 'left' }} />
          <col style={{ width: '10%', textAlign: 'left' }} />
          <col style={{ width: '70%', textAlign: 'left' }} />
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>일자</span>
            </th>
            <th>
              <span>가입자 수</span>
            </th>
            <th>
              <span>메모</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isListLoading ? (
            <tr>
              <td colSpan={3}>
                <Row
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Row style={{ display: 'flex', marginTop: '-4rem', marginLeft: '-1rem' }}>
                    <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
                  </Row>
                </Row>
              </td>
            </tr>
          ) : countList?.length > 0 ? (
            countList.map((d, i) => (
              <tr key={d.date}>
                <td>
                  <span>{moment(d.date, 'YYYYMMDD').format('YY/MM/DD')}</span>
                </td>
                <td>
                  <span>{d.count}</span>
                </td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{
                        fontSize: '0.8rem',
                        width: '15rem',
                        border: '1px solid #AFAFAF',
                        outline: 'none',
                        textIndent: '0.2rem',
                      }}
                      defaultValue={d.memo}
                      onBlur={(e) => {
                        updateFunc({
                          date: d.date,
                          memo: e.target.value,
                        });
                      }}
                    />
                  </div>
                </td>
                <td></td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <span
                  style={{
                    color: '#afafaf',
                  }}
                >
                  데이터가 없습니다.
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Row>
  );
};

export default observer(DailySignupUserList);
