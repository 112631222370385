import { Gender } from '../API';

export const permissionConverter = (permission: string) => {
  switch (permission) {
    case 'MASTER':
      return '대표원장';
    case 'DOCTOR':
      return '한의사';
    case 'STEP':
      return '스탭';
    case 'STAFF':
      return '스탭';
  }
  return '스탭';
};

export const genderConverter = (type: Gender) => {
  if (Gender.MALE === type) {
    return '남성';
  } else if (Gender.FEMALE === type) {
    return '여성';
  }
};

export const convertOnsetValueToString = (value: number) => {
  let result = `undefined onset value: ${value}`;
  switch (value) {
    case 0:
      result = '일주일 이내';
      break;
    case 1:
      result = '1개월 이내';
      break;
    case 2:
      result = '3개월 이내';
      break;
    case 3:
      result = '6개월 이내';
      break;
    case 4:
      result = '6개월 이상';
      break;
    default:
      break;
  }
  return result;
};
