import message from 'antd-message';
import { useState } from 'react';
import { API } from 'aws-amplify';
import { useStores } from 'stores/Context';
import { Row, Col, TypographyText, Input, ModalType2 } from 'components/UI/atoms';
import { emailValidationCheck } from 'utils/util';

export const InviteModal = ({ setIsOpenInviteModal }) => {
  const { clinicStore } = useStores();
  const [permission, setPermission] = useState('STAFF');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const inviteStep = async () => {
    const apiName = 'yejinadminrestapi';
    const path = '/invite';
    const option = {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
      body: {
        clinicID: clinicStore.clinic.id,
        clinicName: clinicStore.clinic.clinicName,
        permission,
        email,
      },
    };
    setIsLoading(true);
    API.post(apiName, path, option)
      .then(() => {
        setIsLoading(false);
        message.success(`초대가 완료 되었습니다.`, 2500);
        setIsOpenInviteModal(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          message.error(`이미 구성원으로 소속된 스탭입니다.`, 2500);
        } else if (error.response.status === 406) {
          message.error(`대표원장은 구성원 초대가 불가합니다.`, 2500);
        } else {
          message.error(`초대 과정에서 문제가 생겼습니다.`, 2500);
        }
        setIsLoading(false);
      });
  };

  const confirmFlag = !!email && emailValidationCheck(email);

  return (
    <ModalType2
      isOpen={true}
      title="구성원 초대하기"
      width={40}
      content={
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1.25rem',
          }}
        >
          <table>
            <colgroup>
              <col style={{ width: '9.6rem' }} />
              <col style={{ width: 'calc(100% - 9.6rem)' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <TypographyText style={{ fontSize: '1rem', lineHeight: '1.2em', fontWeight: 700 }}>
                    이메일
                  </TypographyText>
                </td>
                <td>
                  <Col style={{ position: 'relative', width: '100%' }}>
                    <Input
                      style={{
                        height: '3.2rem',
                        width: '100%',
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                        fontSize: '1rem',
                        lineHeight: '1rem',
                        outline: 'none',
                      }}
                      value={email}
                      placeholder={'이메일'}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                      onKeyUp={(e) => {
                        if (e.keyCode === 13 && confirmFlag) {
                          inviteStep();
                        }
                      }}
                    />
                    {email?.length > 0 && (
                      <Col
                        style={{
                          position: 'absolute',
                          right: '0.5rem',
                          top: 'calc(50% - 0.5rem)',
                          height: '1rem',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setEmail('');
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                          style={{ width: '1rem' }}
                          alt="serach_initialization_icon"
                        />
                      </Col>
                    )}
                    {!!email && !emailValidationCheck(email) && (
                      <Col
                        style={{
                          position: 'absolute',
                          bottom: '-1.3rem',
                        }}
                      >
                        <TypographyText yjTypeTypographyText="survey-validation">
                          {`이메일 형식이 맞는지 확인해주세요.`}
                        </TypographyText>
                      </Col>
                    )}
                  </Col>
                </td>
              </tr>
            </tbody>
          </table>
          <Row
            style={{
              display: 'flex',
              padding: '1.25rem',
              background: '#f2f2f2',
              width: '100%',
            }}
          >
            <TypographyText
              style={{
                whiteSpace: 'pre-wrap',
                fontSize: '0.8rem',
                lineHeight: '1.375em',
                letterSpacing: '0.03em',
              }}
            >
              {`- 초대된 구성원은 48시간이내에 수락해야합니다.
- 다른 소속의 구성원을 초대할 경우, 초대 수락 시 한의원 소속 권한이 변경됩니다.
- 면허등록 시, 스탭에서 한의사로 권한이 변경됩니다.
- 면허 승인은 최소 30분 ~ 최대 24시간이 소요됩니다.`}
            </TypographyText>
          </Row>
        </Row>
      }
      okMsg="초대하기"
      okBtnDisabled={!confirmFlag}
      okFunction={() => {
        if (confirmFlag) {
          inviteStep();
        }
      }}
      okBtnLoading={isLoading}
      cancelFunction={() => setIsOpenInviteModal(false)}
    />
  );
};
