/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import message from 'antd-message';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  AreaChart,
  ComposedChart,
  Area,
} from 'recharts';

import { useStores } from 'stores/Context';
import { currentEnv } from 'config';
import { Loading } from 'components/UI/molecules';
import { Row, Col, TypographyText } from 'components/UI/atoms';
import pr from 'repository/PatientRepository';
import tr from 'repository/TreatmentRepository';
import ur from 'repository/UserRepository';
import cr from 'repository/ClinicRepository';

import sortBy from 'lodash/sortBy';
import _ from 'lodash';

const DailyNewPatientList = () => {
  const navigate = useNavigate();
  const { clientStore } = useStores();

  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [countList, setCountList] = useState<any[]>([]);
  const [clinicList, setClinicList] = useState<any[]>([]);
  const [originCountList, setOriginCountList] = useState<any[]>([]);
  const [mode, setMode] = useState<string>('all');
  const [eventDateMap, setEventDateMap] = useState(new Map());
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [dateFilterTrigger, setDateFilterTrigger] = useState<boolean>(false);
  const [visibleOption, setVisibleOption] = useState({
    treatmentStartCount: true,
    totalSurveyCnt: true,
  });

  const buttonStyle = useMemo(
    () => ({
      width: 'fit-content',
      paddingInline: '1rem',
      height: '3rem',
      fontSize: '1rem',
      backgroundColor: '#f7f7f7',
      color: '#000',
      border: 'none',
      borderRadius: '0.4rem',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [],
  );

  const inputStyle = useMemo(() => ({ border: '1px solid #f1f1f1', textIndent: '0.3rem' }), []);
  const graphData = useMemo(() => {
    return _.reverse([...countList]) ?? [];
  }, [countList]);

  const initFunc = useCallback(async () => {
    try {
      setIsListLoading(true);
      const subs = {
        local: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        develop: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        staging: ['borooboroo625@gmail.com', 'pjwkhl@naver.com'],
        production: ['borooboroo625@gmail.com'],
      };

      if (!subs[currentEnv]?.includes(clientStore.metaData?.userRole?.email)) {
        navigate('/patient');
      }

      // const patientRes = await pr.listDailyPatientCounts();
      // const usageRes = await tr.listDailyUsageStatistics();
      // const signupUserRes = await ur.listDailySignupCount();

      const [patientRes, usageRes, signupUserRes, clinicRes] = await Promise.all([
        pr.listDailyPatientCounts(),
        tr.listDailyUsageStatistics(),
        ur.listDailySignupCount(),
        cr.listClinicsByCreatedAt({
          params: {},
          nextToken: undefined,
        }),
      ]);
      setClinicList(clinicRes?.items ?? []);
      const sortedUsageRes = sortBy(usageRes?.items, (e) => -e.date);
      const sortedPateintResMap = new Map(sortBy(patientRes?.items, (e) => -e.date).map((e) => [e.date, e]));
      const sortedSignupUserResMap = new Map(
        sortBy(signupUserRes?.items, (e) => -e.date).map((e) => [e.date, e]),
      );

      const eventDateMap = new Map();
      const combinedList = (sortedUsageRes ?? []).map((e) => {
        const patient = sortedPateintResMap.get(e.date);
        const signupUser = sortedSignupUserResMap.get(e.date);
        const totalTreatmentCompletionCount =
          e.treatmentCompletionWithoutPrescriptionCount + e.treatmentCompletionWithPrescriptionCount;
        const totalSurveyCnt = e.surveyCount + e.surveySentBySelfCount;
        if (signupUser?.memo) {
          eventDateMap.set(e.date, signupUser.memo);
        }
        return {
          ...e,
          totalSurveyCnt,
          surveyCountByYejinPercentage:
            totalSurveyCnt === 0 ? 0 : _.round((e.surveyCount / totalSurveyCnt) * 100),
          surveyCountBySelfPercentage:
            totalSurveyCnt === 0 ? 0 : _.round((e.surveySentBySelfCount / totalSurveyCnt) * 100),
          newPatientCnt: patient?.count ?? 0,
          signupUserCnt: signupUser?.count ?? 0,
          totalTreatmentCompletionCount,
          treatmentCompletionWithoutPrescriptionCountPercentage:
            totalTreatmentCompletionCount === 0
              ? 0
              : _.round(
                  (e.treatmentCompletionWithoutPrescriptionCount / totalTreatmentCompletionCount) * 100,
                ),
          treatmentCompletionWithPrescriptionCountPercentage:
            totalTreatmentCompletionCount === 0
              ? 0
              : _.round((e.treatmentCompletionWithPrescriptionCount / totalTreatmentCompletionCount) * 100),
        };
      });
      console.log(combinedList);
      setEventDateMap(eventDateMap);
      await setOriginCountList(combinedList);
      await setCountList(combinedList.filter((e) => e.date >= moment().add(-6, 'week').format('YYYYMMDD')));
    } catch (e) {
      message.error(`데이터를 가져오는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 2500);
    } finally {
      setIsListLoading(false);
    }
  }, []);

  useEffect(() => {
    initFunc();
  }, []);

  // 누적 환자 수
  const totalPatientCount = useMemo(
    () => originCountList.reduce((a, c) => a + c.newPatientCnt, 0),
    [originCountList],
  );

  const filterFunc = useCallback(({ startDate, endDate, mode, originCountList }) => {
    let isVaildStartDate = false;
    let isVaildEndDate = false;
    if (startDate) {
      isVaildStartDate = startDate.length === 8 && moment(startDate).isValid();
      if (!isVaildStartDate) {
        message.error('시작일을 확인해주세요. (YYYYMMDD)', 2500);
        return;
      }
    }
    if (endDate) {
      isVaildEndDate = endDate.length === 8 && moment(endDate).isValid();
      if (!isVaildEndDate) {
        message.error('종료일을 확인해주세요. (YYYYMMDD)', 2500);
        return;
      }
    }

    let list = [...originCountList];

    if (mode !== 'all') {
      list = list.filter((e) => e.count > 0);
    }

    return list.filter((e) => {
      if (isVaildStartDate && isVaildEndDate) {
        return e.date >= startDate && e.date <= endDate;
      } else if (isVaildStartDate) {
        return e.date >= startDate;
      } else if (isVaildEndDate) {
        return e.date <= endDate;
      }
      return true;
    });
  }, []);

  useEffect(() => {
    if (dateFilterTrigger) {
      const list = filterFunc({
        startDate,
        endDate,
        mode,
        originCountList,
      });
      if (list !== undefined) {
        setCountList(list);
      }
      setDateFilterTrigger(false);
    }
  }, [dateFilterTrigger]);

  useEffect(() => {
    const list = filterFunc({
      startDate,
      endDate,
      mode,
      originCountList,
    });
    if (list !== undefined) {
      setCountList(list);
    }
  }, [mode]);

  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        paddingBottom: '1rem',
        height: '100%',
        flex: '1 1 0',
      }}
    >
      {isListLoading && <Loading mode="global" />}
      <div className="flex gap-x-2 items-center">
        <div
          style={{
            display: 'flex',
            height: '3rem',
          }}
        >
          <input
            style={inputStyle}
            placeholder="시작일 (YYYYMMDD)"
            onChange={(e) => setStartDate(e.target.value)}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                setDateFilterTrigger(true);
              }
            }}
          />
          <input
            style={inputStyle}
            placeholder="종료일 (YYYYMMDD)"
            onChange={(e) => setEndDate(e.target.value)}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                setDateFilterTrigger(true);
              }
            }}
          />
          <button style={buttonStyle} onClick={() => setDateFilterTrigger(true)}>
            {`기간 설정`}
          </button>
        </div>
      </div>
      <Row style={{ display: 'flex', alignContent: 'baseline', columnGap: '0.5rem' }}>
        <Col style={{ display: 'flex', flex: 1 }}>
          <ResponsiveContainer height={240}>
            <LineChart data={graphData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={`preserveStartEnd`} />
              <YAxis orientation="right" allowDecimals={false} domain={[0, 'auto']} unit={'명'} />
              <Tooltip />
              <Legend />
              {eventDateMap.size > 0 &&
                Array.from(eventDateMap).map(([date, memo], index) => (
                  <ReferenceLine
                    x={date}
                    strokeDasharray="3 3"
                    stroke="#1D1C1D"
                    label={{
                      value: memo,
                      position: {
                        x: -1,
                        y: ((index % 5) + 1) * 20,
                      },
                    }}
                  />
                ))}
              <Line type="monotone" dataKey="signupUserCnt" stroke="#8e7cc3" name="신규 가입자" unit={'명'} />
            </LineChart>
          </ResponsiveContainer>
        </Col>
        <Col style={{ display: 'flex', flex: 1, justifyContent: 'space-between', paddingRight: '2rem' }}>
          <Row
            style={{
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.2rem',
              alignItems: 'flex-end',
            }}
          >
            <Row flex style={{ columnGap: '0.2rem', alignItems: 'baseline' }}>
              <TypographyText fontWeight={700}>{`누적 한의원 수:`}</TypographyText>
              <TypographyText>{`${clinicList?.length ?? 0}곳`}</TypographyText>
            </Row>
            <Row flex style={{ columnGap: '0.2rem', alignItems: 'baseline' }}>
              <TypographyText fontWeight={700}>{`누적 환자 수:`}</TypographyText>
              <TypographyText>{`${totalPatientCount}명`}</TypographyText>
            </Row>
          </Row>
          <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.3rem' }}>
            <TypographyText fontWeight={700}>{`Link`}</TypographyText>
            <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.4rem' }}>
              <Row
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  borderBottom: '1px solid #1d1c1d',
                  width: 'fit-content',
                }}
                onClick={() => navigate('/clinicList')}
              >
                <TypographyText>{`- 한의원 목록`}</TypographyText>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  borderBottom: '1px solid #1d1c1d',
                  width: 'fit-content',
                }}
                onClick={() => navigate('/qr-dashboard')}
              >
                <TypographyText>{`- QR 프로모션 참여 한의원 목록`}</TypographyText>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  borderBottom: '1px solid #1d1c1d',
                  width: 'fit-content',
                }}
                onClick={() => navigate('/dashboard-daily-signup-user-count')}
              >
                <TypographyText>{`- 일일 가입자`}</TypographyText>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  borderBottom: '1px solid #1d1c1d',
                  width: 'fit-content',
                }}
                onClick={() => navigate('/dashboard-daily-patient-count')}
              >
                <TypographyText>{`- 일일 신규 등록 환자`}</TypographyText>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  borderBottom: '1px solid #1d1c1d',
                  width: 'fit-content',
                }}
                onClick={() => navigate('/dashboard-daily-survey-treatment-count')}
              >
                <TypographyText>{`- 일일 설문 및 진료`}</TypographyText>
              </Row>
            </Row>
          </Row>
        </Col>
      </Row>
      <Row style={{ display: 'flex', alignContent: 'baseline', columnGap: '0.5rem' }}>
        <ResponsiveContainer height={240}>
          <LineChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" interval={`preserveStartEnd`} />
            <YAxis orientation="right" allowDecimals={false} domain={[0, 'auto']} unit={'명'} />
            <Tooltip />
            <Legend />
            {eventDateMap.size > 0 &&
              Array.from(eventDateMap).map(([date, memo], index) => (
                <ReferenceLine
                  x={date}
                  strokeDasharray="3 3"
                  stroke="#1D1C1D"
                  label={{
                    value: memo,
                    position: {
                      x: -1,
                      y: ((index % 5) + 1) * 20,
                    },
                  }}
                />
              ))}
            <Line type="monotone" dataKey="newPatientCnt" stroke="#ff9aa2" name="신환 수" unit={'명'} />
          </LineChart>
        </ResponsiveContainer>
        <ResponsiveContainer height={240}>
          <LineChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" interval={`preserveStartEnd`} />
            <YAxis orientation="right" allowDecimals={false} domain={[0, 'auto']} unit={'건'} />
            <Tooltip />
            <Legend
              onClick={(e: any) => {
                setVisibleOption((prev) => ({ ...prev, [e.dataKey]: !prev[e.dataKey] }));
              }}
            />
            {eventDateMap.size > 0 &&
              Array.from(eventDateMap).map(([date, memo], index) => (
                <ReferenceLine
                  x={date}
                  strokeDasharray="3 3"
                  stroke="#1D1C1D"
                  label={{
                    value: memo,
                    position: {
                      x: -1,
                      y: ((index % 5) + 1) * 20,
                    },
                  }}
                />
              ))}
            <Line
              type="monotone"
              dataKey="totalSurveyCnt"
              stroke="#f6bd60"
              name="설문 수"
              unit={'건'}
              hide={!visibleOption.totalSurveyCnt}
            />
            <Line
              type="monotone"
              dataKey="treatmentStartCount"
              stroke="#b5ead7"
              name="진료 수"
              unit={'건'}
              hide={!visibleOption.treatmentStartCount}
            />
          </LineChart>
        </ResponsiveContainer>
      </Row>
      <Row style={{ display: 'flex', alignContent: 'baseline', columnGap: '0.5rem' }}>
        <ResponsiveContainer height={240}>
          <ComposedChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" interval={`preserveStartEnd`} />
            <YAxis orientation="right" allowDecimals={false} domain={[0, 100]} unit={'%'} />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="totalTreatmentCompletionCount"
              stroke="#ff9aa2"
              name="진료 완료 수"
              dot={false}
              activeDot={false}
            />
            <Area
              type="monotone"
              dataKey="treatmentCompletionWithoutPrescriptionCountPercentage"
              stroke="#85d0cb"
              fill="#85d0cb"
              stackId="1"
              name="처방포함 진료 완료"
              unit={'%'}
            />
            <Area
              type="monotone"
              dataKey="treatmentCompletionWithPrescriptionCountPercentage"
              stroke="#e27d60"
              fill="#e27d60"
              stackId="1"
              name="처방없이 진료 완료"
              unit={'%'}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <ResponsiveContainer height={240}>
          <ComposedChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" interval={`preserveStartEnd`} />
            <YAxis orientation="right" allowDecimals={false} domain={[0, 100]} unit={'%'} />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="totalSurveyCnt"
              stroke="#f6bd60"
              name="설문 수"
              unit={'건'}
              dot={false}
              activeDot={false}
            />
            <Area
              type="monotone"
              dataKey="surveyCountBySelfPercentage"
              stroke="#8e7cc3"
              fill="#8e7cc3"
              stackId="1"
              name="QR 자가 설문"
              unit={'%'}
            />
            <Area
              type="monotone"
              dataKey="surveyCountByYejinPercentage"
              stroke="#ff9aa2"
              fill="#ff9aa2"
              stackId="1"
              name="한의원 발송 설문"
              unit={'%'}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Row>
    </Row>
  );
};

export default observer(DailyNewPatientList);
