/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import _ from 'lodash';

import { Row, TypographyText, Col } from 'components/UI/atoms';

export const StatisticCard = ({ title, value }: { title: string; value: string }) => {
  return (
    <Col
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: '#ffffff',
        border: '1px solid #e5e5e5',
        borderRadius: '3px',
        padding: '1.4rem',
        height: '6.85rem',
        width: '100%',
      }}
    >
      <Row>
        <TypographyText
          fontSize="1rem"
          lineHeight="1.2em"
          fontWeight={700}
          color={'#1d1c1d'}
          style={{ whiteSpace: 'nowrap' }}
        >
          {title}
        </TypographyText>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Row>
          <TypographyText
            fontSize="1.2rem"
            lineHeight="1.2rem"
            color={'#1d1c1d'}
            style={{ whiteSpace: 'nowrap' }}
          >
            {value}
          </TypographyText>
        </Row>
      </Row>
    </Col>
  );
};

export const TooltipCard = ({
  isOpen,
  targetComponentId,
  topRem,
  leftRem,
  rightRem,
  bottomRem,
  description,
  prefixIcon,
  padding = '0.75rem 1rem',
  minWidth = '18.4rem',
  title,
  zIndex = 3,
}: {
  isOpen: boolean;
  targetComponentId: string;
  topRem?: number;
  leftRem?: number;
  rightRem?: number;
  bottomRem?: number;
  description: string | any[];
  prefixIcon?: any;
  padding?: string;
  minWidth?: string;
  title?: string;
  zIndex?: number;
}) => {
  const target = useMemo(() => document.getElementById(targetComponentId), [isOpen]);
  const currentFontSize = useMemo(() => {
    const currentFontSize = parseFloat(window.getComputedStyle(document.body).getPropertyValue('font-size'));
    return currentFontSize;
  }, [
    // 브라우저 사이즈가 바뀔 때마다 폰트 사이즈를 갱신한다.
    window.innerWidth,
  ]);

  if (!target || !isOpen) return null;

  // get target component's position
  const targetRect = target?.getBoundingClientRect();
  const { top: targetTop, left: targetLeft, right: targetRight, bottom: targetBottom } = targetRect;

  // get target component's right offset from the right edge of the screen
  const targetRightOffset = window.innerWidth - targetRight;

  // get target component's bottom offset from the bottom edge of the screen
  const targetBottomOffset = window.innerHeight - targetBottom;

  const titleOffset = title ? -2.51 : 0;

  return (
    <Row
      style={{
        position: 'fixed',
        display: 'flex',
        zIndex,
        top:
          typeof topRem === 'number' ? `${targetTop / currentFontSize + topRem + titleOffset}rem` : undefined,
        left: typeof leftRem === 'number' ? `${targetLeft / currentFontSize + leftRem}rem` : undefined,
        right:
          typeof rightRem === 'number' ? `${targetRightOffset / currentFontSize + rightRem}rem` : undefined,
        bottom:
          typeof bottomRem === 'number'
            ? `${targetBottomOffset / currentFontSize + bottomRem + titleOffset}rem`
            : undefined,
        justifyContent: 'space-between',
        columnGap: '0.6rem',
        padding,
        border: '1px solid #d9d9d9',
        background: '#ffffff',
        minWidth,
      }}
    >
      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {title && (
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '0.75rem',
              borderBottom: '1px solid #e5e5e5',
              marginBottom: '0.75rem',
            }}
          >
            <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
              {title}
            </TypographyText>
          </Row>
        )}
        {_.isArray(description) ? (
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {description.map((desc, index) => {
              let descComponent;
              if (typeof desc === 'string') {
                descComponent = (
                  <TypographyText
                    fontWeight={400}
                    fontSize="0.8rem"
                    lineHeight="1.2em"
                    whiteSpace="pre"
                    letterSpacing="0.01em"
                    style={{ textIndent: 0 }}
                  >
                    {desc}
                  </TypographyText>
                );
              } else {
                descComponent = desc;
              }
              if (index === 0 && prefixIcon) {
                return (
                  <Row
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '0.15rem',
                    }}
                  >
                    {prefixIcon}
                    {descComponent}
                  </Row>
                );
              }
              return <>{descComponent}</>;
            })}
          </Col>
        ) : (
          <TypographyText
            fontWeight={400}
            fontSize="0.8rem"
            lineHeight="1.2em"
            whiteSpace="pre"
            letterSpacing="0.01em"
            style={{ textIndent: 0 }}
          >
            {description}
          </TypographyText>
        )}
      </Col>
    </Row>
  );
};
