import '@aws-amplify/ui-react/styles.css';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import * as Sentry from '@sentry/react';

import { BrowserTracing } from '@sentry/tracing';
import Amplify from 'aws-amplify';
import { currentEnv } from 'config';
import { createRoot } from 'react-dom/client';
import App from './App';
import awsExports from './aws-exports';
import { StoreProvier } from './stores/Context';
import { RootStore } from './stores/RootStore';

Amplify.configure(awsExports);
const rootStore = new RootStore();
if (['staging', 'production'].includes(currentEnv)) {
  Sentry.init({
    dsn: 'https://641ba25f08538ed86aa9417cfd3e3373@o4506272492093440.ingest.sentry.io/4506272494583808',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    environment: currentEnv,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StoreProvier value={rootStore}>
    <App />
  </StoreProvier>,
);
